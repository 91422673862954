import React, { useState } from 'react'
import AdvertiserPanel from './AdvertiserPanel';
import BootstrapTable from 'react-bootstrap-table-next';
import loadings from "../../components/Images/loading/loading.gif";
import { MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from "react-icons/md";
import paginationFactory from "react-bootstrap-table2-paginator";
import AdminDeleteModal from './Modal/AdminDeleteModal';
import AdminNavbar from './Layout/AdminNavbar';
import { useTranslation } from 'react-i18next';
import { getItemToken } from '../../Utils/localStorageUtils';
import axios from 'axios';
import { toast } from 'react-toastify';
import NoRecordTable from './Layout/NoRecordTable';
import AdminHeader from './Layout/AdminHeader';

const AdvertiserAdsAnalyticsCPC = () => {
    const { t } = useTranslation();
    const [apiData, setApiData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showOverlay, setShowOverlay] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [selectAll, setSelectAll] = useState(false);
    const [modelClose, setModelClose] = useState(false);
    const [itemToDelete, setItemToDelete] = useState([]);
    const [withOutRefresh, setWithoutRefresh] = useState(false)
    const userAccessToken = getItemToken('access_token');


    const columns = [
        {
            dataField: "",
            text: "#",
            sort: true
        },
        {
            dataField: "",
            text: "Ads Id",
            sort: true,
        },
        {
            dataField: "",
            text: "Ads Name",
            sort: true,
        },
        {
            dataField: "",
            text: "Click Count",
            sort: true,
        },
        {
            dataField: "",
            text: "Cost Per Click",
            sort: true,
        },
        {
            dataField: "",
            text: "Total Cost Per Click",
            sort: true,
        },
        {
            dataField: "ACTIONS",
            text: "Actions"
        },
    ];

    const options = {
        pageStartIndex: 1,
        paginationSize: 4,
        sizePerPage: 10,
        nextPageText: "Next",
        prePageText: "Prev",
        firstPageText: <MdKeyboardDoubleArrowLeft />,
        lastPageText: <MdKeyboardDoubleArrowRight />,
        showTotal: true,
        alwaysShowAllBtns: true,
        sizePerPageList: [{
            text: '5', value: 5
        }, {
            text: '10', value: 10
        }, {
            text: 'All', value: Number(filteredData?.length)
        }]
    };

    const selectRow = {
        mode: "checkbox",
        onSelect: (row, isSelect) => {
            if (row) {
                if (isSelect) {
                    setItemToDelete([...itemToDelete, row.id]);
                } else {
                    setItemToDelete(itemToDelete?.filter((id) => id !== row?.id));
                }
            }
        },
        style: { width: '30px' },
        selected: itemToDelete,
        selectionHeaderRenderer: ({ mode, checked }) => (
            <div>
                <input type="checkbox" checked={checked} onChange={handleAll} />
            </div>
        ),
    };

    const handleConfirmDelete = async () => {
        setShowOverlay(true);
        try {
            const formData = new FormData();
            formData.append(`${'key'}`, "[" + itemToDelete + "]");
            const response = await axios.post(`${'url'}`, formData, { headers: userAccessToken });
            const result = response?.data;
            if (result?.status) {
                setWithoutRefresh(!withOutRefresh);
                toast.success(t(result?.message));
                setShowOverlay(false);
                setItemToDelete([])
            }
            else {
                toast.error(t(result?.message));
                setShowOverlay(false);
            }
        } catch (err) {
            var errorMessage = err?.response?.data?.message;
            toast.error(t(errorMessage));
            setShowOverlay(false);
        }
    };
    const closeModal = () => {
        setModelClose(!closeModal);
        setShowModal(false);
    };
    const openModal = () => {
        setModelClose(true);
    };

    const handleDeletedata = () => {
        handleConfirmDelete();
        closeModal();
    };

    const handleAll = () => {
        setSelectAll(!selectAll);
        setItemToDelete(selectAll ? [] : filteredData?.map((item) => item?.id));
    };
    
    const handleFilterChange = (data) => {
        setFilteredData(data);
    };


  
    return (
        <AdvertiserPanel>
            {modelClose && <AdminDeleteModal modelClose={modelClose} deleteClose={closeModal} deleteAction={handleDeletedata} buttonText={itemToDelete?.length} />}
            <AdminNavbar heading="Cost per click Analytics" totalDataLength={apiData?.length} deleteCount={itemToDelete?.length} navigation={`advertiser/upload-ads`} deleteMethod={openModal} navText="Add New Ads" />
            <div className="theme-bg-color p-3 rounded-2 bootstrapTable  multi-delete-width" >
                <AdminHeader searchMethod={handleFilterChange} reduceData={filteredData} originalData={apiData} fileName={'ADS-LIST'} />
                <BootstrapTable keyField="id" pagination={paginationFactory(options)} columns={columns} data={filteredData} className="no-border-table theme-text-color " noDataIndication={<NoRecordTable result={filteredData?.length} loader={loading} />} hover headerClasses="" selectRow={selectRow} selectAll={selectAll} />
            </div>
        </AdvertiserPanel>
    )
}
export default AdvertiserAdsAnalyticsCPC;