import React from "react";
import AllHomePage from "./AllHomePage";
import BannerIndex from "./HomeSwiperComponents/VideoPlayerMultiType/BannerIndex";
import HelmetDetails from "../../components/Others/HelmetDetails";
import BannerPagination from "../../ThemeTwo/Components/BannerPagination";
import { connect } from "react-redux";
const HomePage = ({currentTheme}) => {
  return (
    <div className="paddingTop">
      {/* <HelmetDetails metaURL={window.location.href} metaTitle='' metaDescription='' metaImage='' pageTitle='' /> */}
      {currentTheme == 1 && <BannerIndex />}
      {currentTheme == 2 && <BannerPagination />}
      <AllHomePage />
    </div>
  );
};
const mapStateToProps = (state) => ({
  currentTheme: state.get_headerIndex_Reducer.currentTheme,
});
export default connect(mapStateToProps)(HomePage);

