// External Libraries and Packages
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { MdClose } from "react-icons/md";
import { toast } from "react-toastify"
// Internal Styles
// Internal Components and Images
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import ResponseLoader from "../../../components/Others/ResponseLoader";
const Contentaddlivecategory = (props) => {
  const access_token = localStorage.getItem("access_token_cpp");
  const CPPContentId = localStorage.getItem('cpp_Partner_id')
  const navigate = useNavigate();
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const [showOverlay, setShowOverlay] = useState(false);
  const [coverImageFile, setCoverImageFile] = useState(null)
  const [editUserError, setEditUserError] = useState({});
  const [in_menu, setIn_menu] = useState(false);
  const [parent_id, setParent_id] = useState([]);
  const [category, setCategory] = useState([]);
  const [editUser, setEditUser] = useState({
    name: "",
    slug: "",
  });
  const coverImageRef = useRef(null);
  const slugInputRef = useRef(null)
  const titleInputRef = useRef(null);

  //Handle Image upload
  const handleCoverImageChange = (event) => {
    const selectedFile = event.target.files;
    if (selectedFile.length > 0) {
      const newFile = selectedFile[0];
      const reader = new FileReader();
      reader.onload = () => {
        setCoverImageFile(newFile);
      };
      reader.readAsDataURL(newFile);
    } else {
    }
  };

  //Handle  Display Checkbox
  const checkHandler = () => {
    setIn_menu(!in_menu);
  };

  //Hanle input values
  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));
    setEditUserError((prevEditUser) => ({
      ...prevEditUser,
      [name]: '',
    }));
    setEditUserError((prevEditUser) => ({
      ...prevEditUser,
      slug: '',
    }));

  };

  // Handle Auto slug generate function *start*
  const handleBlurtitle = () => {
    generateSlug(editUser?.name);
  };

  const handleBlurTitleEdit = () => {
    generateSlug(editUser?.slug);
  }

  const generateSlug = (title) => {
    title = title?.trim();
    const slug = title?.toLowerCase().replace(/\s+/g, "-");
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      slug: slug,
    }));
  };

  const handleAutoFillChangetitle = (event) => {
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      slug: event.target.value,
    }));
    setEditUserError((prevEditUser) => ({
      ...prevEditUser,
      [event.target.name]: '',
    }));
  };

  const handleInputKeyDowntitle = (event) => {
    if (event.key == "Enter" || event.key == "Tab") {
      generateSlug(editUser?.name);
    }
  };

  //Handle submit form 
  const handleSave = () => {
    if (formValidation()) {
      console.log("data true go to api")
      addCategoryData()
    }
    else {
      console.log('Form validation failed ');
    }
  }

  const formValidation = () => {
    let formIsValid = true;
    if (!editUser?.name) {
      setEditUserError((prevEditUser) => ({ ...prevEditUser, name: 'Title cannot be empty.' }));
      titleInputRef.current.focus();
      formIsValid = false;
    }
    else if (!editUser?.slug) {
      setEditUserError((prevEditUser) => ({ ...prevEditUser, slug: 'Slug cannot be empty.' }));
      slugInputRef.current.focus();
      formIsValid = false;
    }
    return formIsValid;
  }

  const addCategoryData = async () => {
    setShowOverlay(true)
    const formData = new FormData();
    const imageFormData = new FormData()
    try {
      for (const key in editUser) {
        formData.append(key, editUser[key]);
      }
      formData.append("parent_id", parent_id);
      formData.append("in_menu", in_menu == true ? 1 : 0);
      formData.append("Content_Partner_id", 1);
      const response = await axios.post(`${process.env.REACT_APP_Baseurl}/CPP/Backend/live-stream-category/store`, formData, { headers: headers });
      let dataResponse = response.data;
      let resStatusId = response.data.LiveCategory.id

      imageFormData.append('liveStreamCategory_id', resStatusId)
      imageFormData.append('image', coverImageFile)
      let imageResponse;
      if (dataResponse.status) {
        try {
          imageResponse = await axios.post(`${process.env.REACT_APP_Baseurl}/CPP/Backend/live-stream-category/image`, imageFormData, { headers: headers });
          if (imageResponse.data.status) {
            setShowOverlay(false)
            toast.success(dataResponse.message)
            setTimeout(async () => {
              await navigate("/cpp/manage-live-category");
            }, 3000);
          }
        }
        catch (err) {
          console.log(err)
          setShowOverlay(false)
        }
      }
    }
    catch (err) {
      setShowOverlay(false)
      const errResponse = err.response.message
      toast.error(errResponse)
    }
  }

  //get data category
  const getData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_Baseurl}/CPP/Backend/live-stream-category/create`, { headers })
      setCategory(response?.data?.live_categories);
    }
    catch (err) {
      console.error(err)
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {showOverlay && (<ResponseLoader />)}
      <section className="container-fluid p-0  theme-bg-color-secondary  adminHeadContainer" >
        <div className="d-flex align-items-center flex-wrap p-3">
          <div className="col-sm-6 col-12 ">
            <h4 className="admin-title mb-0  theme-text-color text-md-start ">Add New Live Stream Category</h4>
          </div>
          <div className="col-sm-6 col-12 d-flex align-items-center justify-content-end" >
            <button onClick={handleSave} className="btn btn-primary admin-button-text text-white rounded-2  py-2 px-3">Save Category</button>
          </div>
        </div>
        <div className="row m-0 px-1">
          <div className="col-12 col-md-6 ">
            <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
              <h6 className="theme-text-color trial-border-bottom  pb-3">Create Category</h6>
              <div className="mt-3 text-start">
                <label className="mt-2 d-block mandatorySimple position-relative theme-text-color"> Name </label>
                <div className="mt-2">
                  <input
                    type="text"
                    className="rs-input form-control-lg  theme-bg-color-secondary   theme-text-color  border-0"
                    id="name"
                    name="name"
                    placeholder="Enter Name"
                    onChange={handleInput}
                    value={editUser?.name}
                    onBlur={handleBlurtitle}
                    onKeyDown={handleInputKeyDowntitle}
                    ref={titleInputRef}
                  />
                </div>
                {editUserError?.name && (<span className="errorred mt-2 d-block">{editUserError?.name}</span>)}
              </div>
              <div className="mt-2 text-start">
                <label className="mt-2 theme-text-color">Slug</label>
                <div className="mt-2">
                  <input
                    type="text"
                    className="rs-input form-control-lg  theme-bg-color-secondary   theme-text-color  border-0"
                    id="slug"
                    name="slug"
                    placeholder="Enter Slug"
                    value={editUser?.slug}
                    ref={slugInputRef}
                    onBlur={handleBlurTitleEdit}
                    onChange={handleAutoFillChangetitle}
                  />
                  {editUserError?.slug && (<span className="errorred mt-2 d-block">{editUserError?.slug}</span>)}
                </div>
              </div>
              <div className="mt-2 text-start">
                <label className="mt-2 theme-text-color">Category</label>
                <div className="mt-2">
                  <select
                    className="px-3 py-3 rounded-2 w-100  theme-bg-color-secondary  theme-text-color border-0"
                    name="parent_id"
                    id="parent_id"
                    onChange={(e) => setParent_id(e.target.value)}
                  >
                    <option value="0" className=" theme-bg-color-secondary  theme-text-color">Select</option>
                    {category?.map((item) => (
                      <option value={item?.parent_id} className=" theme-bg-color-secondary  theme-text-color">
                        {item?.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          {/* Second Part of Section  */}
          <div className="col-12 col-md-6 ">
            <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
              <h6 className="theme-text-color trial-border-bottom  pb-3">Thumbnails</h6>
              <div className="text-start mt-2">
                <label className="mt-2 theme-text-color opacity-75">Image</label>
                <div className="mt-2">
                  <div className="d-flex align-items-center">
                    <div className="col-6 col-sm-6 col-lg-6 p-1">
                      <div className="imagedrop position-relative" >
                        <input
                          type="file"
                          ref={coverImageRef}
                          onChange={(event) => handleCoverImageChange(event)}
                          accept="image/*"
                          className="opacity-0"
                          key={coverImageFile ? coverImageFile : "inputKey"} />
                        <button type="button" className="bg-transparent position-absolute">
                          <CameraRetroIcon />
                        </button>
                      </div>

                    </div>
                    <div className="col-6 col-sm-6 col-lg-6 p-1">
                      {coverImageFile && (
                        <div className="imagedropcopy text-end">
                          <div className="d-flex align-items-center">
                            <img
                              src={URL.createObjectURL(coverImageFile)}
                              alt="Uploaded"
                              className="thumbnails-upload-images"
                            />
                            <button onClick={() => setCoverImageFile(null)} type="button" className="bg-transparent" > <MdClose /> </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
              <h6 className="theme-text-color trial-border-bottom pb-3">Status Settings</h6>
              <div className="d-flex align-items-center justify-content-between mt-3">
                <p className="theme-text-color ">Display In Home page</p>
                <div className="d-flex align-items-center gap-2">
                  <label className="switch">
                    <input
                      name="active"
                      onChange={checkHandler}
                      className="rs-input"
                      checked={in_menu}
                      type="checkbox"
                    />
                    <span
                      className="slider round"
                      name="active"
                      onChange={checkHandler}
                    ></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contentaddlivecategory;
