import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import LazyLoadImage from '../../../Pages/HomePage/HomeSwiperComponents/LazyLoadImage';
import { episodeDetailsAction } from '../../../Redux/Actions/apiActions';
import { connect, useDispatch } from 'react-redux';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const EpisodeDetails = ({ seriesDetailsData, settings }) => {
    // console.log(seriesDetailsData)
    const { lang } = useParams();
    const user_role = localStorage.getItem("role");
    const user_id = localStorage.getItem("user_id");
    const access_token = localStorage.getItem("access_token");
    const headers = {
        "Content-Type": "application/json", Authorization: "Bearer " + access_token,
        Accept: "application/json"
    };
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const episodeApi = async (seriesSlug, episodeSlug) => {
        //console.log(seriesSlug,episodeSlug)
        if (settings && Object.keys(settings).length > 0) {
            try {
                let actionResult;
                if (!user_role && settings.access_free == 1) {
                    actionResult = await dispatch(episodeDetailsAction(null, 9, "web", seriesSlug, episodeSlug, headers));
                } else if (user_role) {
                    actionResult = await dispatch(episodeDetailsAction(user_id, user_role, "web", seriesSlug, episodeSlug, headers));
                }
                //console.log(actionResult)
                return { status: true, data: actionResult }; // Return the action result as API response
            } catch (error) {
                console.error('Error fetching episode data:', error);
                return { status: false, error: error.message }; // Return error information if there's an error
            }
        } else {
            return { status: false, error: 'Invalid settings' }; // Return error if settings are invalid
        }
    };


    const handlePlayButton = async (seriesSlug, episodeSlug) => {
        console.log(seriesSlug, episodeSlug)
        navigate(`/${lang}/episode/${seriesSlug}/${episodeSlug}`);
        
    };
    const truncateTitle = (title, maxLength) => {
        if (!title) return '';
        if (title.length <= maxLength) return title;
        return `${title.substring(0, maxLength)}...`;
    };

    const maxLength = 12; // Maximum length before truncating
   // const truncatedTitle = truncateTitle(title, maxLength);
    //console.log(truncatedTitle)
    return (
        <div style={{ "overflow": "hidden" }}>
            {seriesDetailsData?.series_season?.map((item, index) => (
                item?.episodes && item?.episodes?.length > 0 && (
                    <div key={index}>
                        <><span className='seasonText'>{t("Season")} {index + 1}</span>
                            <div className="pb-4">
                                <Swiper
                                    slidesPerView={1}
                                    spaceBetween={30}
                                    navigation={true}
                                    lazy={true}
                                    slidesPerGroup={1}
                                    breakpoints={{
                                        576: {
                                            slidesPerView: 2,
                                            spaceBetween: 30,
                                            slidesPerGroup: 2,
                                        },
                                        768: {
                                            slidesPerView: 3,
                                            spaceBetween: 30,
                                            slidesPerGroup: 3,
                                        },
                                        1200: {
                                            slidesPerView: 4,
                                            spaceBetween: 30,
                                            slidesPerGroup: 4,
                                        },
                                        1440: {
                                            slidesPerView: 4,
                                            spaceBetween: 30,
                                            slidesPerGroup: 4,
                                        }
                                    }}
                                    modules={[Navigation]}
                                    className={`homePageSwiper livestream`}>
                                    {item?.episodes.map((value, index) => (
                                        <SwiperSlide key={value?.id} className='theme-bg-color-secondary'>
                                            <Link to={`/${lang}/series/${seriesDetailsData?.slug}/${value?.slug}`} className={`text-decoration-none swiperHeading theme-text-color d-block w-100 h-100 `}
                                                onClick={() => handlePlayButton(seriesDetailsData?.slug, value?.slug)}
                                                >
                                                <LazyLoadImage key={index} src={seriesDetailsData?.Player_thumbnail} alt={value?.title} title={value?.title} className="loaderMain" imageSize="object-fit-cover rounded-2"/>
                                             
                                              <span className='seriesText d-flex justify-content-start mt-2'> {truncateTitle(t(value?.title),18)}</span>
                                            </Link>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </>
                    </div>
                )
            ))}
        </div>
    )
}

export default EpisodeDetails;