import React, { useState, useEffect } from "react";
import "../Signin/ContentHeader.css";
import logo from "../../components/Images/flicklogo.png";
import { CgProfile } from "react-icons/cg";
import { RiLogoutCircleRLine } from "react-icons/ri";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

function ContentHeader(props) {
  const MyAlert = ({ message, setShowalert, apiresponsealert }) => {
    const [visible, setVisible] = useState(true);

    useEffect(() => {
      setTimeout(() => {
        props?.props?.setShowalert(false);
        setVisible(false);
      }, 6000);
    }, [setShowalert]);

    let color = "error";
    if (props?.props?.apiresponsealert?.status == true) {
      color = "success";
    }

    const onDismiss = () => {
      setVisible(false);
      props?.props?.setShowalert(false);
    };

    return (
      <Stack sx={{ width: "100%" }} spacing={2}>
        {visible && (
          <Alert
            severity={color}
            onClose={onDismiss}
            variant="filled"
            className="liveAlert"
          >
            <strong>
              {props?.props?.apiresponsealert.status == true
                ? props?.props?.apiresponsealert?.message
                : props?.props?.apiresponsealert?.message}
              {apiresponsealert?.message}
            </strong>

            {/* <strong>
  {props?.props?.apiresponsealert.status == true
    ? props?.props?.apiresponsealert?.message
    : apiresponsealert?.message}
</strong> */}

            {/* <strong>
              {" "}
              {props?.props?.apiresponsealert?.message ?? "Field Error"}
            </strong> */}
          </Alert>
        )}
      </Stack>
    );
  };

  return (
    <section className="header1">
      <div className="container text-center p-3">
        <a href="/cpp/home">
          <img src={logo} className="p-1" alt="flogo" />
        </a>
      </div>

      <div>
        {props?.props ? (
          <div className="row spacing_bottom">
            <div className="col-12 d-flex justify-content-center">
              <div id="liveAlert">
                {props?.props && props?.props?.showalert == true && (
                  <MyAlert message={props?.props.apiresponsealert?.message} />
                )}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </section>
  );
}

export default ContentHeader;
