// External Libraries and Packages
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { FaPlay } from 'react-icons/fa'
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { useTranslation } from 'react-i18next';
// Internal Styles
import "swiper/css";
import "swiper/css/navigation";
import LazyLoadImage from './LazyLoadImage';
// Internal Components
import { URLGenerater } from '../../CommonMethods/CommonMethods';
const LanguageAll = ({ item }) => {
    const { t } = useTranslation();
    const { lang } = useParams();
    return (
        <div className='px-3 px-md-4 px-lg-4 py-4'>
            {
                item?.data?.length > 0 && (<>
                    <Link to={`/${lang}/${URLGenerater(item?.source)}`} className="blocklinetitle text-decoration-none d-inline-block  swiperHeading theme-text-color">{t(item?.header_name)} </Link>
                    <Link to={`/${lang}/${URLGenerater(item?.source)}`} className="blocklinetitle text-decoration-none  float-end  swiperHeading theme-text-color viewall-homepage">{t('View All')}</Link>
                </>)
            }
            <div className="">
                <Swiper slidesPerView={2} spaceBetween={15} navigation={true} lazy={true} slidesPerGroup={2}
                    modules={[Navigation]} className={`homePageSwiper categorySizeSmall`}
                    breakpoints={{
                        376: {
                            slidesPerView: 2,
                            spaceBetween: 15,
                            slidesPerGroup: 2,
                        },
                        576: {
                            slidesPerView: 3,
                            spaceBetween: 15,
                            slidesPerGroup: 3,
                        },
                        768: {
                            slidesPerView: 4,
                            spaceBetween: 15,
                            slidesPerGroup: 4,
                        },
                        1024: {
                            slidesPerView: 5,
                            spaceBetween: 15,
                            slidesPerGroup: 5,
                        },
                        1200: {
                            slidesPerView: 6,
                            spaceBetween: 15,
                            slidesPerGroup: 6,
                        }
                    }} >
                    {/* {item?.data?.map((value, index) => (
                        <SwiperSlide key={value?.id} className='theme-bg-color-secondary'>
                            <Link to={`/${lang}/languages/${value?.slug}`} className="text-decoration-none position-relative  swiperHeading theme-text-color w-100 h-100 languageColorAfter"  >
                                <LazyLoadImage key={index} src={value?.image_url} alt={value?.name} title={''} classNameOptional="rounded-3" imageSize="object-fit-cover loaderMain " />
                                <span className='languageText theme-text-color text-center'>{value?.name}</span>
                            </Link>
                        </SwiperSlide>
                    ))} */}
                    {item?.data?.sort((a, b) => {
                        if (!a.year) return 1;
                        if (!b.year) return -1;
                        const dateA = new Date(a.year);
                        const dateB = new Date(b.year);
                        if (isNaN(dateA)) return 1;
                        if (isNaN(dateB)) return -1;
                        return dateB - dateA;
                    }).map((value, index) => (
                        <SwiperSlide key={value?.id || index} className='theme-bg-color-secondary'>
                            <Link to={`/${lang}/languages/${value?.slug}`} className="text-decoration-none position-relative swiperHeading theme-text-color w-100 h-100 languageColorAfter">
                                <LazyLoadImage key={index} src={value?.image_url} alt={value?.name} title="" classNameOptional="rounded-3" imageSize="object-fit-cover loaderMain" />
                                <span className='languageText theme-text-color text-center'>{value?.name}</span>
                            </Link>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    )
}

export default LanguageAll