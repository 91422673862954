import React from "react";
import { FaPlay, FaRegObjectUngroup } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import MessageBox from "../../../../components/Others/MessageBox";
import LazyLoadImage from "../LazyLoadImage";
import { IconMyPlus, IconMyShare } from "../../../../Images/MyIcons";
import { RiFacebookFill, RiLinkM, RiTwitterXFill, RiWhatsappLine } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import { toast, Bounce } from "react-toastify";
const HoverCardThemeTwo = ({ currentTheme,thumbnailTitle, thumbnailAge, thumbnailPublishedYear, thumbnailDuration, thumbnailRating, thumbnailCategory, value, segMent, containerClass }) => {
    const { lang } = useParams();
    const { t } = useTranslation()
    const formatDurationFormat = (duration) => {
        if (!duration) return '';
        const parts = duration.split(':');
        const hours = parseInt(parts[0], 10);
        const minutes = parseInt(parts[1], 10);
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        return `${hours}H ${formattedMinutes}M`;
    };
    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(URL);
            toast.success(t('📝 Copied to clipboard!'), {
                hideProgressBar: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                transition: Bounce,
            });
        } catch (err) {
            console.error('Unable to copy to clipboard', err);
        }
    };
    return (
        <div className={`${containerClass} ${currentTheme == 2 && 'theme-two-active'} hover-card-mobile-none theme-bg-color-secondary`}>
            <div className="homeListImage">
                <LazyLoadImage
                    key={value.id}
                    src={value?.Player_image_url || value?.Episode_Player_thumbnail || value?.Player_thumbnail || value?.image_url}
                    alt={value?.title || value?.name || value?.episode_title}
                    title={value?.title || value?.name}
                    imageSize='object-fit-cover rounded-1'
                />
            </div>
            <div className="homeListContent">
                <div className="d-flex gap-2">
                    {thumbnailTitle === 1 &&
                        <>
                            {value?.title && (<MessageBox classname="homePageCardHeading active theme-text-color " text={value?.title}></MessageBox>)}
                            {value?.name && (<MessageBox classname="homePageCardHeading active theme-text-color " text={value?.name}></MessageBox>)}
                            {value?.episode_title && (<MessageBox classname="homePageCardHeading active theme-text-color " text={value?.episode_title}></MessageBox>)}
                            {value?.video_title && (<MessageBox classname="homePageCardHeading  active theme-text-color " text={value?.video_title}></MessageBox>)}
                        </>
                    }
                    <MessageBox classname="homePageCardHeading theme-text-color fw-light " text={`(5 Languages)`}></MessageBox>

                    <div class="dropdown p-0">
                        <button className="dropdown-toggle d-flex align-items-center gap-1 p-0 homePageCardHeading opacity-75 bg-transparent" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            <IconMyShare styled={{ width: "16px", height: "16px" }} /> <MessageBox classname="homePageCardHeading theme-text-color  fw-light " text={"Share"} />
                        </button>
                        <ul class="dropdown-menu p-0 m-0 theme-bg-color theme-text-color" aria-labelledby="dropdownMenuButton1">
                            <li><Link to={`https://twitter.com/intent/tweet?text=${encodeURIComponent(URL)}`} target="_blank" className="dropdown-item  py-2">
                                <div className='d-flex align-items-center gap-2'>
                                    <RiTwitterXFill />
                                    <span className='theme-text-color shareIconBoxText'>{t("Twitter")}</span>
                                </div>
                            </Link></li>
                            <li> <Link to={`https://web.whatsapp.com/send?text=${encodeURIComponent(URL)}`} target="_blank" className="dropdown-item  py-2">
                                <div className='d-flex align-items-center gap-2'>
                                    <RiWhatsappLine />
                                    <span className='theme-text-color shareIconBoxText'>{t("Whatsapp")}</span>
                                </div>
                            </Link></li>
                            <li>  <Link to={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(URL)}`} target="_blank" className="dropdown-item  py-2">
                                <div className='d-flex align-items-center gap-2'>
                                    <RiFacebookFill />
                                    <span className='theme-text-color shareIconBoxText'>{t("Facebook")}</span>
                                </div>
                            </Link></li>
                            <li>
                                <div className='dropdown-item theme-text-color py-2 d-flex align-items-center gap-2' onClick={copyToClipboard}>
                                    <RiLinkM />
                                    <span className='theme-text-color shareIconBoxText'>{t("Copy")}</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="d-flex align-items-center gap-2 my-3">
                    {thumbnailDuration === 1 &&
                        value?.duration ? formatDurationFormat(value?.duration) !== '' && (<span className="homePageCardSpecial d-block theme-text-color position-relative"> {formatDurationFormat(value?.duration)} </span>)
                        : value?.episode_duration ? formatDurationFormat(value?.episode_duration) !== '' && (<span className="homePageCardSpecial d-block theme-text-color position-relative"> {formatDurationFormat(value?.episode_duration)} </span>)
                            : value?.video_duration ? formatDurationFormat(value?.video_duration) !== '' && (<span className="homePageCardSpecial d-block theme-text-color position-relative"> {formatDurationFormat(value?.video_duration)} </span>) : ''}
                    {thumbnailCategory === 1 && value?.categories ? (<span className="homePageCardSpecial d-block theme-text-color position-relative"> {value?.categories}</span>) : ''}
                </div>
                <div className="d-flex align-items-center gap-2">
                    <Link onClick={() => window.location.href = `/${lang}/${segMent}/${value?.slug ? value?.slug : value?.video_slug ? value?.video_slug : value?.series_slug ? value?.series_slug : value?.artist_slug ? value?.artist_slug : value?.channel_slug}`} className="homeListButton mb-0 py-2 gap-3 bgButton d-flex align-items-center justify-content-center" >
                        <FaPlay className="homeListPlayIcon" />
                        <p className={`text-decoration-none float-end  mb-0 homeListButtonText`} ><MessageBox text="Watch Now" /></p>
                    </Link>
                    {/* <button onClick={() => null} className="px-2 py-1 rounded-2 border border-1 theme-text-color theme-border-body-color bg-transparent">
                        <IconMyPlus styled={{ width: "17px", height: "17px" }} />
                    </button> */}
                </div>
            </div>
        </div >
    );
};

const mapStateToProps = state => ({
    thumbnailTitle: state.get_allThumbnail_Reducer.thumbnailTitle,
    thumbnailAge: state.get_allThumbnail_Reducer.thumbnailAge,
    thumbnailPublishedYear: state.get_allThumbnail_Reducer.thumbnailPublishedYear,
    thumbnailDuration: state.get_allThumbnail_Reducer.thumbnailDuration,
    thumbnailRating: state.get_allThumbnail_Reducer.thumbnailRating,
    thumbnailCategory: state.get_allThumbnail_Reducer.thumbnailCategory,
    currentTheme: state.get_headerIndex_Reducer.currentTheme,
});
export default connect(mapStateToProps)(HoverCardThemeTwo);
