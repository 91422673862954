// External Libraries and Packages
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { BiError } from 'react-icons/bi';
// Internal Styles

// Internal Components
import { liveDetailsAction } from "../../../Redux/Actions/apiActions";
import Error404 from "../../../Error404/Error404";
import LoaderPage from "../../../Layout/LoaderPage";
import CommonViewDetail from "../../Others/CommonViewDetail";
import HelmetDetails from "../../Others/HelmetDetails";


const LiveDetails = ({ liveDetailsData, isLoading, error, settings, livePayment_setting }) => {
  const { slug } = useParams();
  const user_role = localStorage.getItem("role");
  const user_id = localStorage.getItem("user_id");


  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [domReload, setDomReload] = useState(false)
  const currentPageURL = window.location.href
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (settings && Object.keys(settings).length > 0) {
      !user_role && settings.access_free == 1
        ? dispatch(liveDetailsAction(null, 9, "web", slug))
        : user_role && dispatch(liveDetailsAction(user_id, user_role, "web", slug));
    }
  }, [settings, user_role, domReload])


  const handleDOM = () => {
    setDomReload(!domReload)
  }
  return (

    <>
      <div className='paddingTop'>
        {error ? <Error404 /> : isLoading ? <LoaderPage /> :
          liveDetailsData !== null ? <>
            <HelmetDetails metaURL={currentPageURL} metaTitle={liveDetailsData?.title ? liveDetailsData.title?.toUpperCase() : liveDetailsData?.name?.toUpperCase()} metaDescription={liveDetailsData?.description?.toString().substring(0, 100)} metaImage={liveDetailsData?.Player_thumbnail} pageTitle={liveDetailsData?.title ? liveDetailsData.title?.toUpperCase() : liveDetailsData?.name?.toUpperCase()} />
            <CommonViewDetail data={liveDetailsData} navigationName="Live" categoryUrl="/live-categories" playerUrl="live" reloadAction={handleDOM} codeNumber="2" />
          </> :
            <div className="py-5 text-center"><BiError style={{ fontSize: "50px" }} /><h3> NO DATA FOUND</h3></div>
        }
      </div>

    </>
  )
}
const mapStateToProps = (state) => ({
  liveDetailsData: state.get_liveDetails_Reducer.liveDetailsData,
  isLoading: state.get_liveDetails_Reducer.isLoading,
  error: state.get_liveDetails_Reducer.error,
  settings: state.get_setting_Reducer.settings,
  livePayment_setting: state.get_liveDetails_Reducer.rentpayment_setting,
});
export default connect(mapStateToProps)(LiveDetails);


