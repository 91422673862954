import React, { useEffect, useRef, useState } from 'react'
import { MdClose } from "react-icons/md";
import { IoIosArrowBack } from "react-icons/io";
import { connect, useDispatch } from 'react-redux';
import { headerDefault, musicStationImageApi, musicStationIndex, musicStationStore, userIdLocal, userRoleLocal } from '../../components/Api/MusicApi';
import axios from 'axios';
import ButtonWithLoader from './CommonComponents/ButtonWithLoader';
import { toast } from 'react-toastify';
import Select from "react-select";
import { CiImageOn } from 'react-icons/ci';
import MessageBox from '../../components/Others/MessageBox';
import { useTranslation } from 'react-i18next';
import { getMusicHomePageAction } from '../../Redux/Actions/MusicAction';

const MusicStationModal = ({ chooseStation }) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [playlistData, setPlaylistData] = useState({
        musicStationName: "",
        playlistImage: null,
        playlistStore: [],
        createResponse: null,
        stationGenre: false,
        stationArtists: false,
        artistsData: [],
        genreData: []
    })
    const [formDataError, setFormDataError] = useState({})
    const [loading, setLoading] = useState(false)
    const artistsRef = useRef(null)
    const genreRef = useRef(null)

    const handleSelectArtist = (selectedValues) => {
        setPlaylistData((prev) => ({
            ...prev,
            artistsData: selectedValues,
        }));
        setFormDataError((prevFormData) => ({
            ...prevFormData,
            artistsDataError: "",
        }));
    }

    const handleSelectGenre = (selectedValues) => {
        setPlaylistData((prev) => ({
            ...prev,
            genreData: selectedValues,
        }));
        setFormDataError((prevFormData) => ({
            ...prevFormData,
            genreDataError: "",
        }));
    }

    const handleInput = (e) => {
        const { name, value } = e.target;
        setPlaylistData((prev) => ({
            ...prev,
            [name]: value
        }))
        setFormDataError((prevFormData) => ({
            ...prevFormData,
            musicStationNameError: "",
        }));
    }

    const handleFile = (event) => {
        const selectedFile = event.target.files;
        if (selectedFile.length > 0) {
            const newFile = selectedFile[0];
            const reader = new FileReader();
            reader.onload = () => {
                setPlaylistData((prev) => ({
                    ...prev,
                    playlistImage: newFile
                }))
            };
            reader.readAsDataURL(newFile);
        } else {
            return null
        }
        setFormDataError((prevFormData) => ({
            ...prevFormData,
            artistsImageError: "",
        }));
    }

    const handleCheck = (e) => {
        const { name, checked } = e.target;
        if (checked) {
            dispatch({ type: "CHOOSE_STATION", payload: true })
        }
        const propertyToUpdate = name == 'stationArtists' ? 'stationGenre' : 'stationArtists';
        setPlaylistData((prev) => ({
            ...prev,
            [name]: checked,
            [propertyToUpdate]: false

        }));
    };

    const handleImageUpload = async (id) => {
        try {
            const formData = new FormData();
            formData.append("Login_user_type", userRoleLocal || 9)
            formData.append("Login_user_id", userIdLocal || "")
            formData.append("Login_device_type", "web");
            formData.append("Station_id", id);
            formData.append("image", playlistData?.playlistImage);
            const response = await axios.post(`${musicStationImageApi}`, formData, { headers: headerDefault });
            if (!response?.data?.status) {
                toast.error(t(response?.data?.message))
            }
        } catch (err) {
            console.error(err)
        }
    }

    const createMusicStation = async () => {
        setLoading(true);
        try {
            const formDataAdd = new FormData();
            formDataAdd.append("Login_user_type", userRoleLocal || 9)
            formDataAdd.append("Login_user_id", userIdLocal || "")
            formDataAdd.append("Login_device_type", "web");
            formDataAdd.append("source_type", 5);
            formDataAdd.append("title", playlistData?.musicStationName);
            formDataAdd.append("station_type", playlistData?.stationArtists ? "Artist" : "Genre");
            if (playlistData?.stationArtists) {
                const artists = playlistData.artistsData.map(item => item.value);
                formDataAdd.append("station_based_artists", JSON.stringify(artists));
            }
            else {
                const genres = playlistData.genreData.map(item => item.value);
                formDataAdd.append("station_based_genre", JSON.stringify(genres));
            }
            const response = await axios.post(`${musicStationStore}`, formDataAdd, { headers: headerDefault });
            const result = response?.data;
            if (result?.status) {
                if (playlistData?.playlistImage) {
                    await handleImageUpload(result?.Station_id)
                }
                dispatch(getMusicHomePageAction())
                setPlaylistData((prev) => ({
                    ...prev,
                    createResponse: response?.data,
                    playlistImage: null,
                    musicStationName: "",
                    stationGenre: false,
                    stationArtists: false,
                    artistsData: [],
                    genreData: [],

                }))
                setLoading(false);
                toast.success(t(result?.message))
                dispatch({ type: "OPEN_STATION", payload: false })
                dispatch({ type: "CHOOSE_STATION", payload: false })
            }
            else {
                setLoading(false);
                toast.error(t(result?.message))
            }
        }
        catch (err) {
            setLoading(false);
            const errorMessage = err?.response?.data?.message
            toast.error(t(errorMessage))
            console.error("error", err)
        }
    }

    const handleStore = () => {
        if (formValidation()) {
            createMusicStation();
        } else {
            console.log("Form validation failed ");
        }
    }

    const formValidation = () => {
        let formIsValid = true;
        if (playlistData?.stationArtists) {
            if (!playlistData?.musicStationName) {
                setFormDataError((prevFormData) => ({
                    ...prevFormData,
                    musicStationNameError: "Please give the music station name.",
                }));
                formIsValid = false;
            }
            if (playlistData?.artistsData.length <= 0) {
                setFormDataError((prevFormData) => ({
                    ...prevFormData,
                    artistsDataError: "Please select the artists.",
                }));
                formIsValid = false;
            }
            if (!playlistData?.playlistImage) {
                setFormDataError((prevFormData) => ({
                    ...prevFormData,
                    artistsImageError: "Please give the music station artists image",
                }));
                formIsValid = false;
            }
            return formIsValid;
        }
        else {
            if (!playlistData?.musicStationName) {
                setFormDataError((prevFormData) => ({
                    ...prevFormData,
                    musicStationNameError: "Please give the music station name.",
                }));
                formIsValid = false;
            }
            if (playlistData?.genreData.length <= 0) {
                setFormDataError((prevFormData) => ({
                    ...prevFormData,
                    genreDataError: "Please select the genre.",
                }));
                formIsValid = false;
            }
            if (!playlistData?.playlistImage) {
                setFormDataError((prevFormData) => ({
                    ...prevFormData,
                    artistsImageError: "Please give the music station image",
                }));
                formIsValid = false;
            }
            return formIsValid;
        }
    }

    const closeModal = () => {
        dispatch({ type: "OPEN_STATION", payload: false })
        dispatch({ type: "CHOOSE_STATION", payload: false })
    }

    const selectStation = () => {
        dispatch({ type: "CHOOSE_STATION", payload: !chooseStation })
    }

    useEffect(async () => {
        try {
            const formDataAdd = new FormData();
            formDataAdd.append("Login_user_type", userRoleLocal || 9)
            formDataAdd.append("Login_user_id", userIdLocal || "")
            formDataAdd.append("Login_device_type", "web");
            const response = await axios.post(`${musicStationIndex}`, formDataAdd, { headers: headerDefault });
            const result = response?.data;
            setPlaylistData((prev) => ({
                ...prev,
                playlistStore: result
            }))
        }
        catch (err) {
            console.error("error", err)
        }
    }, [playlistData?.createResponse])

    const formatOptionLabel = ({ value, label, audio_count }) => (
        <div>
            <span>{label}</span>
            <span style={{ marginLeft: '10px',fontSize:"13px"}} className='opacity-75'>({audio_count} tracks)</span>
        </div>
    );

    return (
        <div className='playlistModal w-100 h-100 d-flex align-items-center justify-content-center position-fixed'>
            <div className='theme-bg-color playlistWrapper'>
                <div className='d-flex align-items-center justify-content-between'>
                    <MessageBox classname='p-0 theme-text-color' text="Make Music Station" />
                    <button className='bg-transparent p-0 theme-text-color' type='button' onClick={closeModal}><MdClose className='playlistIcon' /></button>
                </div>

                {!chooseStation && <div className='py-4 px-5' >
                    <div className='d-flex align-items-center justify-content-between '>
                        <div className='d-flex align-items-center gap-2'>
                            <input type='radio' name="stationArtists" checked={playlistData?.stationArtists} onClick={handleCheck} className="checkBoxModal" />
                            <MessageBox classname='checkBoxStation' text="Artists" />
                        </div>
                        <div className='d-flex align-items-center gap-2'>
                            <input type='radio' name="stationGenre" checked={playlistData?.stationGenre} onClick={handleCheck} className="checkBoxModal" />
                            <MessageBox classname='checkBoxStation' text="Genre" />
                        </div>
                    </div>
                </div>}
                {chooseStation &&
                    <>
                        <input type="text" name="musicStationName" value={playlistData?.musicStationName} onChange={handleInput} placeholder={t("Station name")} className="rounded-2 playlistInput w-100 border-0 theme-bg-color-secondary theme-text-color  my-3" />
                        <MessageBox classname='text-danger-custom' text={formDataError?.musicStationNameError} />
                        {playlistData?.stationArtists
                            ? <>
                                <Select
                                    options={playlistData?.playlistStore?.Artists?.map((option) => ({
                                        value: option?.id,
                                        label: option?.artist_name,
                                        audio_count: option?.audio_count,
                                    }))}
                                    formatOptionLabel={formatOptionLabel}
                                    isMulti onChange={handleSelectArtist} value={playlistData?.artistsData} ref={artistsRef} className='my-3 theme-bg-color-secondary' />
                                <MessageBox classname='text-danger-custom' text={formDataError?.artistsDataError} />
                            </>
                            :
                            <>
                                <Select options={playlistData?.playlistStore?.AudioGenre?.map((option) => ({ value: option?.id, label: option?.name }))} isMulti onChange={handleSelectGenre} value={playlistData?.genreData} ref={genreRef} className='my-3 theme-bg-color-secondary' />
                                <MessageBox classname='text-danger-custom' text={formDataError?.genreDataError} />
                            </>}
                        <div className='position-relative musicDropZone  my-3 rounded-2 theme-border-color'>
                            <input type="file" onChange={handleFile} className="rounded-2 h-100 opacity-0 playlistInput w-100 theme-border-body-color  border-1 theme-bg-color theme-text-color " accept="image/*" />
                            <CiImageOn className='musicDropZoneIcon' />
                        </div>
                        <MessageBox classname='theme-text-color fileNameSize' text={playlistData?.playlistImage?.name} />
                        <MessageBox classname='text-danger-custom' text={formDataError?.artistsImageError} />
                        <div className='d-flex align-items-center justify-content-between'>
                            <button className='bg-transparent p-0 theme-text-color' type='button' onClick={selectStation}><IoIosArrowBack /><MessageBox text="Back" /></button>
                            <div className='d-flex align-items-center gap-2'>
                                <button className='bg-transparent py-2 rounded-2 theme-text-color' type='button' onClick={closeModal} ><MessageBox text="Close" /></button>
                                <ButtonWithLoader text="Upload" loading={loading} action={handleStore} />
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    chooseStation: state.get_Playlist_Modal.chooseStation,
    currentTrackId: state.get_current_Reducers.currentTrackId
});
export default connect(mapStateToProps)(MusicStationModal)