// External Libraries and Packages
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { useTranslation } from 'react-i18next';
// Internal Styles
import "swiper/css";
import "swiper/css/navigation";
// Internal Components'
import { URLGenerater } from '../../CommonMethods/CommonMethods';
import PriceLabel from '../../../components/Others/PriceLabel';
import CardWithHover from './Theme/CardWithHover';
import CardWithoutHover from './Theme/CardWithoutHover';
import { connect } from 'react-redux';
const LatestSeriesAll = ({ item, ThumbnailTitle, themeMode }) => {
  const { lang } = useParams();
  const { t } = useTranslation();
  const swiperConfig = {
    slidesPerView: 2,
    spaceBetween: 15,
    navigation: true,
    lazy: true,
    slidesPerGroup: 2,
    breakpoints: {
      376: { slidesPerView: 2, spaceBetween: 15, slidesPerGroup: 2 },
      576: { slidesPerView: 3, spaceBetween: 15, slidesPerGroup: 3 },
      768: { slidesPerView: 4, spaceBetween: 15, slidesPerGroup: 4 },
      1024: { slidesPerView: 5, spaceBetween: 15, slidesPerGroup: 5 },
      1200: { slidesPerView: 6, spaceBetween: 15, slidesPerGroup: 6 },
      1440: { slidesPerView: 6, spaceBetween: 15, slidesPerGroup: 6 },
    },
    modules: [Navigation],
    className: 'homePageSwiper'
  }

  return (
    <div className='px-3 px-md-4 px-lg-4 pt-1 zIndex'>
      {
        item?.data.length > 0 && (<>
          <Link to={`/${lang}/${URLGenerater(item?.source)}`} className="blocklinetitle text-decoration-none  d-inline-block swiperHeading theme-text-color">{t(item?.header_name)} </Link>
          <Link to={`/${lang}/${URLGenerater(item?.source)}`} className="blocklinetitle text-decoration-none  float-end  swiperHeading theme-text-color viewall-homepage">{t('View All')}</Link>
        </>)
      }
      <Swiper {...swiperConfig} >
        {/* {item?.data.map((value, index) => (
          <SwiperSlide key={value?.id} className='theme-bg-color-secondary' >
            {themeMode?.hoverCard ? <CardWithHover value={value} segMent="shows" lang={lang}  hoverClass="homeSwiperContainer"/> : <CardWithoutHover value={value} segMent="shows" lang={lang} />}
          </SwiperSlide>
        ))} */}
        {item?.data
          ?.sort((a, b) => {
            if (!a.year) return 1;
            if (!b.year) return -1;
            const dateA = new Date(a.year);
            const dateB = new Date(b.year);
            if (isNaN(dateA)) return 1;
            if (isNaN(dateB)) return -1;
            return dateB - dateA;
          })
          .map((value, index) => (
            <SwiperSlide key={value?.id || index} className='theme-bg-color-secondary'>
              {themeMode?.hoverCard
                ? <CardWithHover value={value} segMent="shows" lang={lang} hoverClass="homeSwiperContainer" />
                : <CardWithoutHover value={value} segMent="shows" lang={lang} />
              }
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  )
}
const mapStateToProps = (state) => ({
  themeMode: state.get_headerIndex_Reducer.themeMode,
});
export default connect(mapStateToProps)(LatestSeriesAll);
