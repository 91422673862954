// External Libraries and Packages
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
// Internal Styles
// Internal Components
import {
    languagePageListAction,
    viewAllPageIdAction,
} from "../../Redux/Actions/apiActions";
import Error404 from "../../Error404/Error404";
import PageLoader from "../Others/PageLoader";
import PageError from "../Others/PageError";
import LayoutViewAll from "./Source/LayoutViewAll";


const CategoryBasedLanguageMoreViewAll = ({ settings, isLoading, languagePageList, viewallPageId, error }) => {
    const user_role = localStorage.getItem("role");
    const user_id = localStorage.getItem("user_id");
    const access_token = localStorage.getItem("access_token");
    const headers = {
        "Content-Type": "application/json",
        headers: { "Access-Control-Allow-Origin": "*" },
        Authorization: "Bearer " + access_token,
    };

    const { slug, type } = useParams();
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        const requestApi = `${process.env.REACT_APP_Baseurl}/Front-End/Language-Page-List`;
        const languageBasedApi = `${process.env.REACT_APP_Baseurl}/Front-End/Language-depends-videos`;
        if (settings && Object.keys(settings)?.length > 0) {
            if (!user_role) {
                try {
                    dispatch(languagePageListAction(null, 9, "web", headers, requestApi)).then((data) => {
                        const filteredData = data.filter((item) => item.slug == slug);
                        const get = filteredData.flatMap((item) => item.id);
                        dispatch(viewAllPageIdAction(null, 9, "web", get[0], headers, languageBasedApi));
                    })
                        .catch((error) => {
                            console.error("Error:", error);
                        });
                } catch (error) {
                    console.error("Error:", error);
                }
            } else {
                try {
                    dispatch(languagePageListAction(user_id, user_role, "web", headers, requestApi))
                        .then((data) => {
                            const filteredData = data.filter((item) => item.slug == slug);
                            const get = filteredData.flatMap((item) => item.id);
                            dispatch(viewAllPageIdAction(user_id, user_role, "web", get[0], headers, languageBasedApi));
                        })
                        .catch((error) => {
                            console.error("Error:", error);
                        });
                } catch (error) {
                    console.error("Error:", error);
                }
            }
        }
    }, [settings, user_role, user_id, dispatch, location]);

    return (
        isLoading ? <PageLoader /> : error ? <PageError /> :
            languagePageList.some((item) => item.slug == slug) ?
                <div className="paddingTop" style={{ overflowX: "hidden" }}>
                    {type == "videos" ? viewallPageId?.videos.length !== 0 && <LayoutViewAll mapData={viewallPageId?.videos} path={type} source="Videos" headingClass='pb-4 text-center' />
                        : type == "shows" ? viewallPageId?.series.length !== 0 && <LayoutViewAll mapData={viewallPageId?.series} path={type} source="Series" headingClass='pb-4 text-center' />
                            : type == "live" ? viewallPageId?.Livestream.length !== 0 && <LayoutViewAll mapData={viewallPageId?.Livestream} path={type} source="Lives" headingClass='pb-4 text-center' />
                                : type == "audios" ? viewallPageId?.Audios.length !== 0 && <LayoutViewAll mapData={viewallPageId?.Audios} path={type} source="Audios" headingClass='pb-4 text-center' /> : ''}
                </div> :
                <Error404 />)
};

const mapStateToProps = (state) => ({
    languagePageList: state.get_languagePageList_Reducer.languagePageList,
    viewallPageId: state.get_viewAll_Id_Reducer.viewallPageId,
    isLoading: state.get_viewAll_Id_Reducer.isLoading,
    error: state.get_viewAll_Id_Reducer.error,
    settings: state.get_setting_Reducer.settings,
});
export default connect(mapStateToProps)(CategoryBasedLanguageMoreViewAll);