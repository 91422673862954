import React from 'react';
import { Link } from 'react-router-dom';

const AdInformationCard = ({ title, description, link }) => {
    return (
        <div className="ad-information-card">
            {title ? <h5>{title}</h5> : ""}
            {description ? <p>{description}</p> : ""}
            <Link to={link} target="_blank" rel="noopener noreferrer">Learn More</Link>
        </div>
    );
};

export default AdInformationCard;