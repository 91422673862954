import React from "react";
import "./Channel_Header.css";
import logo from "../../../src/components/Images/flicklogo.png";
function Channel_Header() {
  return (
    <section className="header1">
      <div className="container text-center p-3">
        <a href="/">
          <img src={logo} className="p-1" alt="flogo" />
        </a>
      </div>
    </section>
  );
}

export default Channel_Header;
