// External Libraries and Packages
import { Route, Routes } from "react-router-dom";
import React, { Suspense } from "react";
// Internal Styles
// Internal Components
import Signin from "../components/Signin/Signin.jsx";
import Signup from "../components/Signup/Signup.jsx";
import Register_Payments from "../components/Payment_Registeration/Register_Payments.js";
import PaymentCallback from "../components/Payment_Registeration/PaymentCallback.js";
import Success_Register_Payments from "../components/Payment_Registeration/Success_Register_Payments.js";
import Failed_Register_Payments from "../components/Payment_Registeration/Failed_Register_Payments.js";
import HomeCommonLayout from "../components/Layout/HomeCommonLayout.jsx";
import HomePage from "../Pages/HomePage/HomePage.js";
import Maintances from "../Error404/Maintances.js";
import Error404 from "../Error404/Error404.jsx";
import VideoSection from "../Pages/MoviesPlaySection/VideoSection.js";
import VideohlsSection from "../Pages/MoviesPlaySection/VideohlsSection.js";
import VideoPlayerCityTheme from "../Pages/MoviesPlaySection/VideoPlayerCityTheme.js";
import Multiprofile from "../components/Multiprofile/Multiprofile.jsx";
import CreateMultiProfile from "../components/Multiprofile/CreateMultiprofile.jsx";
import EditMultiprofile from "../components/Multiprofile/EditMultiprofile.jsx";
import AccountMain from "../components/AccountSection/AccountMain.js";
import ProtectedRoute from "../components/CommonProtectComponents/ProtectedRoute.jsx";
import LoaderPage from "../Layout/LoaderPage.jsx";
import Schedule from "../PlanTrack/Schedule.jsx";
import HomeProtectedRoute from "../components/CommonProtectComponents/HomeProtectedRoute.jsx";
import Channel from "../Pages/Channel_portal-page/ChannelHome.js";
// import ViewAllVideosBasedCategory from "./Pages/HomePage/ViewAllVideosBasedCategory.js";
import Wishlists from "../Layout/Header/Wishlists.js";
import Watchlater from "../Layout/Header/Watchlater.js";
import ChannelAllRoutes from "./ChannelAllRoutes.jsx";
import ContentAllRoutes from "./ContentAllRoutes.jsx";
import Transcation_List_Register_Payments from "../components/Payment_Registeration/Transcation_List_Register_Payments.js";
import Purchase_Media_Register_Payments from "../components/Payment_Registeration/Purchase_Media_Register_Payments.js";
import ViewAllPage from "../components/ViewAll/Source/ViewAllPage.js";
import CategoryBasedLanguageViewAll from "../components/ViewAll/CategoryBasedLanguageViewAll.js";
import CategoryBasedLiveViewAll from "../components/ViewAll/CategoryBasedLiveViewAll.js";
import CategoryBasedVideoViewAll from "../components/ViewAll/CategoryBasedVideoViewAll.js";
import CategoryBasedSeriesViewAll from "../components/ViewAll/CategoryBasedSeriesViewAll.js";
import AdminRoute from "../Pages/AdminRoute.js";
import CategoryBasedAudioViewAll from "../components/ViewAll/CategoryBasedAudioViewAll.js";
// import ManageTranslation from "../Layout/Header/Languages/ManageTranslation.js";
import VideoDetails from "../components/ViewAll/Slug/VideoDetails.js";
import SeriesDetails from "../components/ViewAll/Slug/SeriesDetails.js";
import LiveDetails from "../components/ViewAll/Slug/LiveDetails.js";
import ArtistDetails from "../components/ViewAll/Slug/ArtistDetails.js";
import ChannelDetails from "../components/ViewAll/Slug/ChannelDetails.js";
import PartnerDetails from "../components/ViewAll/Slug/PartnerDetails.js";
import Player from "../components/Player/Player.js";
import AdvertiserAllRoutes from "./AdvertiserAllRoutes.jsx";
import MusicAllRoutes from "./MusicAllRoutes.jsx";
import SeriesPlayer from "../components/Player/SeriesPlayer.js";
import CategoryBasedLanguageMoreViewAll from "../components/ViewAll/CategoryBasedLanguageMoreViewAll.js";
import WishListViewall from "../components/ViewAll/Source/WishListViewall.jsx";
import Videos from "../components/ViewAll/Slug/Videos.js";
import TrailerPlayer from "../components/Player/TrailerPlayer.js";
import Livestreams from "../components/ViewAll/Slug/Livestreams.js";
import Series from "../components/ViewAll/Slug/Series.js";
import Episodes from "../components/ViewAll/Slug/Episodes.js";
import SearchPage from "../Pages/Search/SearchPage.jsx";
import PurchaseModal from "../components/Player/PurchaseModal.js";
import PlanThemeTwo from "../ThemeTwo/Page/Plan/PlanThemeTwo.jsx";
import LivePurchaseModal from "../components/Player/livePurchaseModal.js";
import ProfileAllRoutes from "./ProfileAllRoutes.jsx";
import FaqHelp from "../ThemeTwo/Page/FAQ/FaqHelp.jsx";
import Contact from "../ThemeTwo/Page/Contact/Contact.jsx";
import LoginIndex from "../Pages/Auth/LoginIndex.jsx";
import ForgetPasswordThemeTwo from "../ThemeTwo/Components/Auth/ForgetPasswordThemeTwo.jsx";
import MoviePage from "../Pages/MoviePage/MoviePage.jsx";
import ShowPage from "../Pages/ShowPage/ShowPage.jsx";
import SignUpIndex from "../Pages/Auth/SignUpIndex.jsx";
import Iyzico_Failerpage from "../components/Payment_Registeration/Iyzico_Failerpage.js";
import Iyzico_Sucesspage from "../components/Payment_Registeration/Iyzico_Sucesspage.js";
import VerifyOtp from "../components/Others/VerifyOtp.jsx";
import DynamicPage from "../Layout/footer/DynamicPage.jsx";


function AllRoutes({ props }) {
  const { currentTheme } = props;
  return (
    <div>
      <Suspense fallback={<LoaderPage />}>
        <Routes>
          {/* <Route path="/:lang/video/:slug" element={<HomeProtectedRoute><PlayerVisibility /></HomeProtectedRoute>} ></Route>
          <Route path="/:lang/livestream/:slug" element={<HomeProtectedRoute><LivestreamVisibility /></HomeProtectedRoute>} ></Route>
          <Route path="/:lang/series/:series_slug/:episode_slug" element={<HomeProtectedRoute><SeriesPlayerVisibility /></HomeProtectedRoute>} ></Route> */}
          <Route path="/maintances" element={<Maintances></Maintances>}></Route>

          <Route path="/:lang" element={<HomeCommonLayout />}>
            <Route path="login" element={<LoginIndex />}></Route>
            <Route path="signup" element={<SignUpIndex />}></Route>
            <Route index element={<HomeProtectedRoute> <HomePage /> </HomeProtectedRoute>} ></Route>
            <Route path="player/:sourcetype/:slug" element={<Player />} ></Route>
            <Route path="trailer/:sourcetype/:slug" element={<TrailerPlayer />} ></Route>
            <Route path="episode/:series_slug/:episode_slug" element={<SeriesPlayer />} ></Route>
            <Route path="plan" element={currentTheme == 1 ? <Register_Payments /> : currentTheme == 2 ? <PlanThemeTwo /> : null} />
            <Route path="payment-callback" component={PaymentCallback} />
            <Route path="plan/payment/success" element={<Success_Register_Payments />} ></Route>
            <Route path="plan/payment/failed" element={<Failed_Register_Payments />} ></Route>
            <Route path="schedule" element={<Schedule />}></Route>
            <Route path="maintances" element={<Maintances />}  ></Route>
            <Route path="home" element={<HomeProtectedRoute> <HomePage /></HomeProtectedRoute>}  ></Route>

            {/* Dynamic ViewAll Router Start */}
            <Route path=":source" element={<HomeProtectedRoute><ViewAllPage /> </HomeProtectedRoute>}></Route>
            <Route path="languages/:slug" element={<HomeProtectedRoute><CategoryBasedLanguageViewAll /></HomeProtectedRoute>} ></Route>
            <Route path="languages/:slug/:type" element={<HomeProtectedRoute><CategoryBasedLanguageMoreViewAll /></HomeProtectedRoute>} ></Route>
            <Route path="live-categories/:slug" element={<HomeProtectedRoute><CategoryBasedLiveViewAll /></HomeProtectedRoute>} ></Route>
            <Route path="video-categories/:slug" element={<HomeProtectedRoute><CategoryBasedVideoViewAll /></HomeProtectedRoute>} ></Route>
            <Route path="series-categories/:slug" element={<HomeProtectedRoute><CategoryBasedSeriesViewAll /></HomeProtectedRoute>} ></Route>
            <Route path="audios-categories/:slug" element={<HomeProtectedRoute><CategoryBasedAudioViewAll /></HomeProtectedRoute>} ></Route>
            <Route path="mywishlists/:type" element={<ProtectedRoute> <WishListViewall /> </ProtectedRoute>} ></Route>
            {/* Dynamic ViewAll Router  End*/}

            {/* Dynamic ViewDetails Slug Router Start */}
            <Route path="videos/:slug" element={<HomeProtectedRoute><Videos /></HomeProtectedRoute>} ></Route>
            <Route path="live/:slug" element={<HomeProtectedRoute><Livestreams /></HomeProtectedRoute>} ></Route>
            <Route path="shows/:slug" element={<HomeProtectedRoute><Series /></HomeProtectedRoute>} ></Route>
            <Route path="series/:series_slug/:episode_slug" element={<HomeProtectedRoute><Episodes /></HomeProtectedRoute>} ></Route>
            {/* <Route path="/shows/:slug" element={<HomeProtectedRoute><SeriesDetails /></HomeProtectedRoute>} ></Route> */}
            {/* <Route path="/videos/:slug" element={<HomeProtectedRoute><VideoDetails /></HomeProtectedRoute>} ></Route> */}
            {/* <Route path="/live/:slug" element={<HomeProtectedRoute><LiveDetails /></HomeProtectedRoute>} ></Route> */}
            <Route path="artists/:slug" element={<HomeProtectedRoute><ArtistDetails /></HomeProtectedRoute>} ></Route>
            <Route path="channels/:slug" element={<HomeProtectedRoute><ChannelDetails /></HomeProtectedRoute>}  ></Route>
            <Route path="partners/:slug" element={<HomeProtectedRoute><PartnerDetails /></HomeProtectedRoute>} ></Route>
            <Route path="shows/:slug/:episode" element={<HomeProtectedRoute><PartnerDetails /></HomeProtectedRoute>} ></Route>

            {/* Dynamic ViewDetails Slug Router End */}

            {/* Music router start */}
            <Route path="music/*" element={<MusicAllRoutes />} />
            {/* Music router end */}

            {/* Movie router start */}
            <Route path="videos" element={<MoviePage />} />
            {/* Movie router end */}

            {/* Show router start */}
            <Route path="show" element={<ShowPage />} />
            {/* Show router end */}

            {/* Admin redirect router start */}
            <Route path="admin" element={<AdminRoute />} ></Route>
            {/* Admin redirect router start */}

            {/* profile  router start */}
            <Route path="myprofile" element={<ProtectedRoute> <AccountMain /> </ProtectedRoute>} ></Route>
            <Route path="myprofile/*" element={<ProtectedRoute> <ProfileAllRoutes /> </ProtectedRoute>} ></Route>
            {/* profile redirect router start */}

            {/* Multiprofile  router start */}
            <Route path="choose-profile" element={<ProtectedRoute> <Multiprofile />  </ProtectedRoute>} ></Route>
            <Route path="multi-profile/create" element={<ProtectedRoute> <EditMultiprofile /></ProtectedRoute>} ></Route>
            <Route path="multi-profile/edit/:id" element={<ProtectedRoute> <EditMultiprofile /></ProtectedRoute>} ></Route>
            {/* Multiprofile  router end */}


            <Route path="video-section" element={<ProtectedRoute>  <VideoSection />  </ProtectedRoute>}  ></Route>
            <Route path="video-hls-section" element={<ProtectedRoute> <VideohlsSection /> </ProtectedRoute>} ></Route>
            <Route path="video-player-section" element={<ProtectedRoute>  <VideoPlayerCityTheme />  </ProtectedRoute>} ></Route>
            <Route path="channel-partner/:channel-slug" element={<ProtectedRoute> <Channel />  </ProtectedRoute>} ></Route>
            <Route path="watchlater" element={<ProtectedRoute> <Watchlater /> </ProtectedRoute>} ></Route>
            <Route path="mywishlists" element={<ProtectedRoute> <Wishlists /> </ProtectedRoute>} ></Route>
            <Route path="transcation-list" element={<ProtectedRoute> <Transcation_List_Register_Payments /> </ProtectedRoute>} ></Route>
            <Route path="purchase-media" element={<ProtectedRoute>  <Purchase_Media_Register_Payments /> </ProtectedRoute>}></Route>
            <Route path="purchase/:slug" element={<PurchaseModal />} />
            <Route path="purchase-live/:slug" element={<LivePurchaseModal />} />

            <Route path="successpage" element={<Iyzico_Sucesspage />}></Route>
            <Route path="failerpage" element={<Iyzico_Failerpage />}></Route>
            <Route path="search" element={<SearchPage />}></Route>
            <Route path="help" element={<FaqHelp></FaqHelp>}></Route>
            <Route path="contact" element={<Contact></Contact>}></Route>
            <Route path="forget" element={<ForgetPasswordThemeTwo></ForgetPasswordThemeTwo>}></Route>
            <Route path="verify/:code" element={<VerifyOtp></VerifyOtp>}></Route>
            <Route path="page/:type" element={<DynamicPage />} />



            <Route path="*" element={<Error404></Error404>}></Route>

            {/* <Route path="category/:slug" element={<ProtectedRoute> <CategoryVideoDetails /> </ProtectedRoute>}></Route> 
            <Route path="/video-play/:slug" element={<ProtectedRoute><VideoPlayer /> </ProtectedRoute>} ></Route>
            <Route path="/Subscription-Plans" element={ <ProtectedRoute> <SubscriptionPlans /> </ProtectedRoute> } ></Route>
            <Route  path="/Subscriber-section" element={ <ProtectedRoute><SubscriberPage /></ProtectedRoute>}></Route> 
           */}

          </Route>
          <Route path="/:lang/channel/*" element={<ChannelAllRoutes />} />
          <Route path="/:lang/cpp/*" element={<ContentAllRoutes />} />
          <Route path="/:lang/advertiser/*" element={<AdvertiserAllRoutes />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default AllRoutes;