import React, { useEffect } from "react";
import Header from "../../Layout/Header/Header";
import { Outlet, useNavigate } from "react-router-dom";
import Footer from "../../Layout/footer/footer";
import AudioTrack from "../../Layout/AudioBar/AudioTrack";
import { connect } from "react-redux";
import MiniFloatPlayer from "../../Pages/Music/MusicPlayers/MiniFloatPlayer";
import ThemeTwoHeader from "../../ThemeTwo/Components/Layout/ThemeTwoHeader";
import ThemeTwoFooter from "../../ThemeTwo/Components/Layout/ThemeTwoFooter";

const HomeCommonLayout = ({ getMusicData, currentClose, minimize, currentTheme }) => {

  const navigate = useNavigate();
  useEffect(async () => {
    await navigate(window.location.pathname);
  }, []);

  const excludedPathsHeader = ['/trailer', '/episode'];
  const excludedPathsFooter = ['/trailer', '/episode'];

  // const isPathExcluded = (excludedPaths) => {
  //   const currentPath = window.location.pathname;
  //   return excludedPaths.some((path) => {
  //     const musicRegex = new RegExp(`^/\\w+/music`);
  //     const episodeRegex = new RegExp(`^/\\w+/episode/\\w+/\\w+`);
  //     const playerRegex = new RegExp(`^/\\w+/player/\\w+/\\w+`);
  //     const trailerRegex = new RegExp(`^/\\w+/trailer/\\w+/\\w+`);
  //     const result = currentPath.startsWith(path) ||
  //       musicRegex.test(currentPath) ||
  //       episodeRegex.test(currentPath) ||
  //       playerRegex.test(currentPath) ||
  //       trailerRegex.test(currentPath);

  //     return result;
  //   });
  // };
  const isPathExcluded = (excludedPaths) => {
    const currentPath = window.location.pathname;

    const dynamicPathsRegex = [
      /^\/\w+\/episode\/[^/]+\/[^/]+/, // Matches paths like /en/episode/series_slug/episode_slug
      /^\/\w+\/music/,                   // Matches paths like /en/music
      /^\/\w+\/player\/[^/]+\/[^/]+/,   // Matches paths like /en/player/series_slug/episode_slug
      /^\/\w+\/trailer\/[^/]+\/[^/]+/,
      /^\/\w+\/login/,
      /^\/\w+\/signup/,
      /^\/\w+\/forget/,
      /^\/\w+\/choose-profile/,
      /^\/\w+\/multi-profile\/edit/,
      /^\/\w+\/multi-profile\/create/,
      // Matches paths like /en/trailer/series_slug/episode_slug
    ];

    // Check if currentPath starts with any of the excluded paths or matches any dynamic path regex
    return excludedPaths.some((path) => currentPath.startsWith(path)) ||
      dynamicPathsRegex.some((regex) => regex.test(currentPath));
  };

  return (
    <div className="own-container position-relative overflow-hidden">
      {currentTheme == 1 && !isPathExcluded(excludedPathsHeader) && <Header />}
      {currentTheme == 2 && !isPathExcluded(excludedPathsHeader) && <ThemeTwoHeader />}
      <Outlet />
      {getMusicData?.enable_floating && currentClose && !minimize ? <AudioTrack /> : null}
      {minimize && <MiniFloatPlayer />}
      {currentTheme == 1 && !isPathExcluded(excludedPathsFooter) && <Footer />}
      {currentTheme == 2 && !isPathExcluded(excludedPathsFooter) && <ThemeTwoFooter />}
    </div>
  );
};
const mapStateToProps = state => ({
  getMusicData: state.get_Music_Settings.getMusicData,
  currentClose: state.get_current_Reducers.currentClose,
  minimize: state.get_Playlist_Modal.minimize,
  currentTheme: state.get_headerIndex_Reducer.currentTheme,
});

export default connect(mapStateToProps)(HomeCommonLayout);
