const defaultDomain = `${process.env.REACT_APP_Baseurl}`
const defaultPreFix = 'Front-End'
const finalRouteApi = `${defaultDomain}/${defaultPreFix}`
const accessToken = localStorage.getItem("access_token");
const userRoleLocal = localStorage.getItem("role");
const userIdLocal = localStorage.getItem("user_id");
const headerDefault = {
    "Content-Type": "application/json",
    headers: { "Access-Control-Allow-Origin": "*" },
    Authorization: "Bearer " + accessToken,
};


const musicHomePageApi = `${finalRouteApi}/All-AudioHomepage`;
const musicPlaylistImageApi = `${finalRouteApi}/Music-Playlist-Image-Upload`;
const musicPlaylistStore = `${finalRouteApi}/Music-Playlist-Store`;
const musicAddPlaylist = `${finalRouteApi}/User-Music-Playlist-Create`;
const musicDeletePlaylist = `${finalRouteApi}/Music-Playlist-Delete`;

const musicPlaylistPageList = `${finalRouteApi}/Playlist-Page-List`;
const musicWriterPageList = `${finalRouteApi}/Audio-based-on-Song-Writer-Page-List`;
const musicMusicianPageList = `${finalRouteApi}/Audio-based-on-Musician-Page-List`;
const musicAlbumPageList = `${finalRouteApi}/Audio-based-on-Albums-Page-List`;
const musicCategoryPageList = `${finalRouteApi}/Audio-based-Genre-Page-List`;
const musicLanguagePageList = `${finalRouteApi}/Audio-based-on-Language-Page-List`;
const musicianPageList = `${finalRouteApi}/Musician-Page-List`;
const artistPageList = `${finalRouteApi}/Artists-Page-List`;
const writerPageList = `${finalRouteApi}/Song-Writer-Page-List`;
const musicStationPageList = `${finalRouteApi}/Music-Station-Page-List`;

const musicStationIndex = `${finalRouteApi}/Music-Station-Create`;
const musicStationStore = `${finalRouteApi}/Music-Station-Store`;
const musicStationImageApi = `${finalRouteApi}/Music-Station-Image-Upload`;
const musicStationDeleteApi = `${finalRouteApi}/Music-Station-Delete`;

const musicDetails = `${finalRouteApi}/audios-details`;
const musicArtist = `${finalRouteApi}/artist-audios-details`;
const musicAlbum = `${finalRouteApi}/album-audios-details`;
const musicPlaylist = `${finalRouteApi}/playlist-play`;
const musicQueue = `${finalRouteApi}/play-audios`;
const musicStationPlay = `${finalRouteApi}/music-station-play`
const musicGenrePlay = `${finalRouteApi}/Audio-based-Genre`;


const musicViewsCount = `${finalRouteApi}/audio-content-played`;
const artistFollowUpate = `${finalRouteApi}/ArtistFollower-Update`;

const musicSearch = `${finalRouteApi}/Audio-Search`;
const musicWishList = `${finalRouteApi}/Wishlist-List`;

export { musicGenrePlay, musicWishList, musicSearch, musicHomePageApi, accessToken, userIdLocal, userRoleLocal, headerDefault, musicPlaylistImageApi, musicPlaylistStore, musicPlaylistPageList, musicAddPlaylist, musicWriterPageList, musicMusicianPageList, musicAlbumPageList, musicCategoryPageList, musicLanguagePageList, musicPlaylist, musicStationIndex, musicStationStore, musicStationImageApi, musicStationDeleteApi, musicDetails, musicQueue, musicArtist, musicAlbum, musicDeletePlaylist, musicViewsCount, writerPageList, artistPageList, musicianPageList, artistFollowUpate, musicStationPageList, musicStationPlay }