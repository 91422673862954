import React, { useState, useEffect, useRef } from "react";
import StripeCheckout from 'react-stripe-checkout';
import { toast } from 'react-toastify';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
// import stripeClient from 'stripe';

const PaymentModal = ({ videoDetailsData, liveDetailsData, seriesDetailsData, }) => {

    const { t } = useTranslation();
    const access_token = localStorage.getItem("access_token");
    const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
    };
    const submitrentbody = {
        name: videoDetailsData ? videoDetailsData?.title : seriesDetailsData ? seriesDetailsData?.title : (liveDetailsData ? liveDetailsData?.title : ''),
        id: videoDetailsData ? videoDetailsData?.id : seriesDetailsData ? seriesDetailsData?.id : (liveDetailsData ? liveDetailsData?.id : ''),
        price: videoDetailsData ? videoDetailsData?.price : seriesDetailsData ? seriesDetailsData?.price : (liveDetailsData ? liveDetailsData?.price : ''),
        product_by: videoDetailsData ? videoDetailsData?.product_by : seriesDetailsData ? seriesDetailsData?.product_by : (liveDetailsData ? liveDetailsData?.product_by : ''),
        email: videoDetailsData ? videoDetailsData?.email : seriesDetailsData ? seriesDetailsData?.email : (liveDetailsData ? liveDetailsData?.email : ''),
        token: videoDetailsData ? videoDetailsData?.token : seriesDetailsData ? seriesDetailsData?.token : (liveDetailsData ? liveDetailsData?.token?.id : ''),
    };
    // console.log(submitrentbody)
    // console.log(headers)
    const handleToken = (token) => {

        // const body = { name: title, id: id, price: price, product_by: productby, email: token.email, token: token.id, };
        ; fetch(`${process.env.REACT_APP_Baseurl}/admin/storessssssas`, {
            method: "POST", headers: headers, body: JSON.stringify(submitrentbody),
        }).then((resp) => {
            if (!resp.ok) { throw new Error("API request failed"); } return resp.json();
        }).then((data) => { toast.success(t("Payment Updated Successfully")); setTimeout(() => { window.location.reload(); }, 3500); }).catch((err) => {
            console.error(err); toast.error(t("Payment Update Failed")); setTimeout(() => { window.location.reload(); }, 3500)
        });
    };
    // price_1OkUMJG6SInHNoTW0MKhoibq
    const stripekey = 'sk_test_51Ng98aG6SInHNoTWaOwzKVMVh4TrBoAph0udCI7jx53Ho2aaVMyAqWi2jyqg2tu4MYRXunb9Gcok0FTn0LDKtYWy00aHmPk4u7';
    // const stripe = stripeClient(stripekey);
    const createPaymentLink = async () => {
        try {
            const response = await stripekey.paymentLinks.create({
                amount: videoDetailsData?.ppv_price * 100, // Amount in cents
                currency: 'usd',
                refresh_url: 'https://yourwebsite.com/refresh', // Replace with your refresh URL
                return_url: 'https://yourwebsite.com/return', // Replace with your return URL
                description: 'Pradeep',
                quantity: 1,
                customer_email: 'pradeep@gmail.com',
            });

            if (response?.active === true) {
                const paymentLinkURL = response.url;
                const newTab = window.open(paymentLinkURL, '_self');
                if (newTab) {
                    newTab.focus();
                    newTab.onbeforeunload = () => {
                        // Redirect to the success page when the popup is closed
                        // window.location.href = successUrl;
                    };
                    toast.success(t('Payment Proceed'));
                } else {
                    console.error('Popup blocked by the browser');
                    toast.error(t('Popup blocked by the browser'));
                    // You can provide a message to the user or take other actions here
                    // window.location.href = failureUrl; // Redirect to the failure URL
                }
            } else {
                console.error('Payment session status is not true');
                toast.error(t('Payment session status is not true'));
                // window.location.href = failureUrl; // Redirect to the failure URL
            }
        } catch (e) {
            console.error(e.message); // Log the error message
            toast.error(t('Payment session status is not true'));
            // window.location.href = failureUrl; // Redirect to the failure URL
        }
    };

    // const createPaymentLink = async () => {
    //     try {
    //         // const stripe = await stripePromise;
    //         const response = await stripe.paymentLinks.create({
    //             line_items: [
    //                 {
    //                     price: videoDetailsData?.ppv_price * 100, // Replace 'PRICE_ID' with your actual Price ID
    //                     quantity: 1,
    //                 },
    //             ],
    //             name: 'Pradeep',
    //             currency: 'usd',
    //             email: 'pradeep@gmail.com',
    //             mode: 'payment',
    //         });

    //         if (response?.active === true) {
    //             const paymentLinkURL = response.url;
    //             const newTab = window.open(paymentLinkURL, '_self');
    //             if (newTab) {
    //                 newTab.focus();
    //                 newTab.onbeforeunload = () => {
    //                     // Redirect to the success page when the popup is closed
    //                     // window.location.href = successUrl;
    //                 };
    //                 toast.success('Payment Proceed');
    //             } else {
    //                 console.error('Popup blocked by the browser');
    //                 toast.error('Popup blocked by the browser');
    //                 // You can provide a message to the user or take other actions here
    //                 // window.location.href = failureUrl; // Redirect to the failure URL
    //             }
    //         } else {
    //             console.error('Payment session status is not true');
    //             toast.error('Payment session status is not true');
    //             // window.location.href = failureUrl; // Redirect to the failure URL
    //         }
    //     } catch (e) {
    //         console.error(e.message); // Log the error message
    //         toast.error('Payment session status is not true');
    //         // window.location.href = failureUrl; // Redirect to the failure URL
    //     }
    // };




    return (
        <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title rent_policsh_color" id="staticBackdropLabel">Rent Now </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div>
                    <div className="modal-body">
                        <div className="col-lg-12 row">
                            <div className="col-lg-5 p-0">
                                <img className="w-100" src={videoDetailsData?.Thumbnail || liveDetailsData?.Thumbnail || seriesDetailsData?.Thumbnail} />
                            </div>
                            <div className="col-lg-7"><div>
                                <div>
                                    <h4 className="rent_policsh_color">{videoDetailsData?.title || liveDetailsData?.title || seriesDetailsData?.title}</h4>
                                </div>
                                <div>
                                    {/* <p className="rent_policsh_color mt-3">Amount {ccurrency?.symbol} {product?.ppv_price}</p> */}
                                </div>
                            </div>
                                <div className="text-start mt-3">
                                    <div>
                                        <h4 className="rent_policsh_color">Payment Method</h4>
                                    </div>
                                    <div>
                                        <p className="rent_policsh">POLICIES: Pay only once to watch this video.</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-start mt-3 label_home_radio_text">
                                    <div className="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical"><div className="" style={{ display: "flex" }}>
                                        <input type="radio" className="nav-link" id="v-pills-home-tab" name="v-pills-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" /><label htmlFor="v-pills-home-tab" className="nav-link ">Pay with Credit or Debit Card</label></div><div className="d-flex">
                                            {/* <input type="radio" className="nav-link" id="v-pills-profile-tab" name="v-pills-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" /> */}
                                            {/* <label htmlFor="v-pills-profile-tab" className="nav-link">Pay with Paypal</label> */}
                                        </div>
                                        <div className="d-flex">
                                            {/* <input type="radio" className="nav-link" id="v-pills-messages-tab" name="v-pills-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" /> */}
                                            {/* <label htmlFor="v-pills-messages-tab" className="nav-link"> Paystack</label> */}
                                        </div></div></div></div></div></div><div className="modal-footer"><div className="tab-content" id="v-pills-tabContent">
                                            <div className="tab-pane fade" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                                <StripeCheckout name={videoDetailsData?.title || liveDetailsData?.title || seriesDetailsData?.title} amount={videoDetailsData?.ppv_price || liveDetailsData?.ppv_price || seriesDetailsData?.ppv_price * 100}
                                                    // currency={ccurrency?.symbol}
                                                    token={handleToken} stripeKey="pk_test_51M4ja8SJZVJvQCKRasiMCPmmb0vjs31HU2Brf1RUKxr4ZHzjOkngy4FdbvLZX0ap0rwU1S7w0X6CFgAEnlEbjT9F00xE9neHhR">
                                                    <button className="btn btn-primary">Continue to Purchase</button>
                                                </StripeCheckout></div>
                                            <button onClick={createPaymentLink}>Generate Payment Link</button>
                                            <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                                                <button> Continue to Purchase</button>
                                            </div>
                                            <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                                                <button> Continue to Purchase</button>
                                            </div>
                                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = state => ({
    videoDetailsData: state.get_videoDetails_Reducer.videoDetailsData,
    isLoading: state.get_videoDetails_Reducer.isLoading,
    error: state.get_videoDetails_Reducer.error,


    liveDetailsData: state.get_liveDetails_Reducer.liveDetailsData,
    isLoading: state.get_liveDetails_Reducer.isLoading,
    error: state.get_liveDetails_Reducer.error,

    seriesDetailsData: state.get_seriesDetails_Reducer.seriesDetailsData,
    isLoading: state.get_seriesDetails_Reducer.isLoading,
    error: state.get_seriesDetails_Reducer.error,


    settings: state.get_setting_Reducer.settings
});

export default connect(mapStateToProps)(PaymentModal)