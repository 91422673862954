import React from 'react'
import Loader from "../../../Images/loading.gif"
const NoRecordTable = ({ result, loader }) => {
  return (
    <div className="noRecord">
      {loader ? (
        <label> <img src={Loader} width={30} height={30} alt="Animated GIF" /> Loading... </label>) :
        (result <= 0 && (<p className=" theme-text-color"> No data Available!  </p>))}
    </div>
  )
}

export default NoRecordTable