import "./AuthStyle.css"
import React from 'react'
import GoogleIcon from "../../Assets/Images/GoogleIcon.png"
import FaceBookIcon from "../../Assets/Images/FaceBookIcon.png"
import LazyLoadImage from "../../../Pages/HomePage/HomeSwiperComponents/LazyLoadImage"
import { connect } from "react-redux"
import MessageBox from "../../../components/Others/MessageBox"
import { Link } from "react-router-dom"
import { RxEyeOpen } from "react-icons/rx"
import { VscEyeClosed } from "react-icons/vsc"
import ButtonWithLoader from "../../../Pages/Music/CommonComponents/ButtonWithLoader"
import { MdClose } from "react-icons/md"
import { useTranslation } from "react-i18next"
const SignInThemeTwo = ({ themeMode, authAction, error, onchange, field, show, data, passAction, modalClose, signUpModal }) => {
    const { t } = useTranslation()
    return (
        <div className={`themeTwoBannerWrapper w-100 min-vh-100 ${data?.pop}  `}>
            {!data?.headerDisable && <header className="theme-bg-color py-3 mb-3 ">
                <LazyLoadImage src={themeMode?.logoUrl} alt={"logo"} classNameOptional="rounded-3 mx-auto signin-logo-images" imageSize="signin-logo-images loaderMain object-fit-cover mx-auto mb-0" />
            </header>}
            <div className='col-11 col-md-8 col-lg-4 mx-auto theme-bg-color-secondary p-md-5 p-2 rounded-2 position-relative '>
                <form onSubmit={authAction}>
                    {data?.headerDisable && <button className='bg-transparent p-0 theme-text-color position-absolute closeBtnModal' type='button' onClick={() => modalClose()}><MdClose className='playlistIcon' /></button>}
                    <p className='mb-md-5 mb-3 text-center d-block themeTwoHead theme-text-color-button'>Sign in</p>
                    <div className='d-flex align-items-center justify-content-center gap-5'>
                        <div className='d-flex align-items-center'>
                            <img src={GoogleIcon} className='ThemeTwoSignIcon me-1 d-block' />
                            <span>Google</span>
                        </div>
                        <div className='d-flex align-items-center'>
                            <img src={FaceBookIcon} className='ThemeTwoSignIcon me-1 d-block' />
                            <span>Facebook</span>
                        </div>
                    </div>
                    <span className="d-block my-4 text-center">OR</span>
                    <input type="email" name="email" value={field?.username} placeholder="Email" onChange={onchange} className="mb-4 d-block bg-transparent py-3 px-3 w-100 outline-0 border border-1" />
                    <MessageBox text={error?.emailerr} classname='d-block mb-2 text-danger' />
                    <div className="position-relative">
                        <input type={show ? 'password' : 'text'} name="password" value={field?.password} onChange={onchange} placeholder="Password" className="mb-4 d-block bg-transparent py-3 ps-3 pe-5 w-100 outline-0 border border-1" />
                        <button onClick={passAction} type="button" className="p-2 border-0 eyeButton bg-transparent">{show ? <VscEyeClosed className="theme-text-color eyeIcons" style={{ width: "25px", height: "25px" }} /> : <RxEyeOpen className="theme-text-color eyeIcons" style={{ width: "25px", height: "25px" }} />}</button></div>
                    <MessageBox text={error?.passworderr} classname='d-block mb-2 text-danger' />
                    <Link to={`/${data?.lang?.Language_Code}/forget`} className="d-block theme-text-color-button mb-2">Forget Password?</Link>
                    <div className='d-flex align-items-center my-md-5 my-3'>
                        <input type="checkbox" className='ThemeTwoCheckbox me-1 d-block bg-transparent' />
                        <span>Remember me?</span>
                    </div>
                    <ButtonWithLoader action={authAction} text="Sign In" type={"submit"} loading={data?.load} className='d-block p-3 rounded-2 w-100' />
                    <p className="signinLabelText mb-0 pt-3 theme-text-color">{t("Don't have an account")}? {data?.headerDisable ? <button type="button" className="border-0 bg-transparent theme-text-color" onClick={() => signUpModal()}>{t("Sign Up")}</button> : <button type="button" className="border-0 bg-transparent theme-text-color" > <Link to={`/${data?.lang?.Language_Code}/signup`}>{t("Sign Up")}</Link></button>}</p>
                </form>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => ({
    themeMode: state.get_headerIndex_Reducer.themeMode,
});

export default connect(mapStateToProps)(SignInThemeTwo);
