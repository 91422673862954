import React from 'react';

const StaticSkeletonEpisode = () => {
    return (
        <div className="row my-4 align-items-center" style={{ cursor: 'pointer' }}>
            <div className="col-12 col-lg-3 mb-2 mb-lg-0">
                <div className="loaderMain episodeImageD w-100" style={{ height: '160px', borderRadius: '8px', backgroundColor: "#2d3038" }}></div>
            </div>
            <div className="col-12 col-lg-9 d-flex flex-column justify-content-start p-3 row-custom" style={{ borderRadius: '8px' }}>
                <div className="text-decoration-none theme-text-color d-block">
                    <h3 className="episodeHeading" style={{ backgroundColor: "#2d3038", height: '25px', width: '62%', borderRadius: '4px' }}></h3>
                    <div className='d-flex gap-2 my-2'>
                        <h2 style={{ height: '25px', width: '20%', borderRadius: '4px', backgroundColor: "#2d3038" }} ></h2>
                        <h2 style={{ height: '25px', width: '20%', borderRadius: '4px', backgroundColor: "#2d3038" }} ></h2>
                        <h2 style={{ height: '25px', width: '20%', borderRadius: '4px', backgroundColor: "#2d3038" }} ></h2>
                    </div>
                    <div style={{ height: '60px', width: '100%', borderRadius: '4px', backgroundColor: "#2d3038" }} ></div>
                </div>
            </div>
        </div>
    );
};
export default StaticSkeletonEpisode;
