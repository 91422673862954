
import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux';
import Loader from "../../components/Images/loaderget.gif";
import { videoDetailsAction } from '../../Redux/Actions/apiActions';
import { useNavigate, useParams } from 'react-router-dom';
import TrailerJS from './Trailer';

const TrailerPlayer = ({ videoDetailsData, isLoading, error, settings }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user_role = localStorage.getItem("role");
  const user_id = localStorage.getItem("user_id");
  const playerType = videoDetailsData?.type;
  const embedSource = videoDetailsData?.embed_code;
  const access_token = localStorage.getItem("access_token");
  const { slug } = useParams();
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
  };
  const videoJsOptions = {
    controlBar:
    {
      subtitlesButton: false,
      remainingTimeDisplay: true,
      volumePanel:
      {
        inline: false,
        vertical: true
      },
      pictureInPictureToggle: false
    },
    bigPlayButton: false,
    autoplay: true,
    controls: true,
    responsive: true,
    breakpoints: [{
      tiny: 300,
      xsmall: 400,
      small: 500,
      medium: 600,
      large: 700,
      xlarge: 800,
      huge: 900
    }],
    textTrackSettings: false,
    fluid: true,
    sources: [{
      src: videoDetailsData?.trailer,
      type: videoDetailsData?.video_player_type
    }],
    // playbackRates: [0.5, 1, 1.25, 1.5, 1.75, 2],
    html5: {
      nativeTextTracks: false
    }

  };

  useEffect(() => {
    if (settings && Object.keys(settings).length > 0) {
      !user_role && settings.access_free == 1
        ? dispatch(videoDetailsAction(null, 9, "web", slug, headers))
        : user_role && dispatch(videoDetailsAction(user_id, user_role, "web", slug, headers));
    }
  }, [settings, user_role])
  const playerRef = React.useRef(null);

  const handlePlayerReady = (player) => {
    playerRef.current = player;
    player.on("waiting", () => {
      // videojs.log("player is waiting");
    });
    player.on("dispose", () => {
      // videojs.log("player will dispose");
    });
  };
  return (
    <>
      {
        isLoading
          ? <div className='d-flex justify-content-center align-items-center paddingTop'>
            <img src={Loader} width={50} height={50} />
          </div>
          : playerType === "embed_url|url"
            ? <iframe src={embedSource} allowfullscreen width="1360" height="594"></iframe>
            : <>
              <TrailerJS options={videoJsOptions} onReady={handlePlayerReady} videoDetailsData={videoDetailsData}
              settings={settings} />
            </>
      }</>
  )
}
const mapStateToProps = state => ({
  videoDetailsData: state.get_videoDetails_Reducer.videoDetailsData,
  isLoading: state.get_videoDetails_Reducer.isLoading,
  settings: state.get_setting_Reducer.settings
});
export default connect(mapStateToProps)(TrailerPlayer)
