import React from 'react'
import Loader from "../Images/loaderget.gif";
const PageLoader = ({ className }) => {
    return (
        <div className={`${className ? className : "d-flex justify-content-center align-items-center paddingTop min-vh-100"}`}>
            {/* <img src={Loader} width={50} height={50} loading='lazy' /> */}
            <div class="loader"></div>
        </div>
    )
}

export default PageLoader