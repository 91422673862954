// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.episodeSwiper {
}

.swiperSlideCustom {
  background-color: rgb(45, 48, 56) !important;
  border-radius: 5px;
  /* other styles */
}

/* .episodeSwiper .swiper-slide {
  background-color:rgb(45, 48, 56) !important;
  border-radius: 5px;
  
} */

.episodeSwiper .swiper-button-next {
  color: white;
  right: -7px;
}
.episodeSwiper .swiper-button-prev {
  color: white;
  left: -4px;
}
.episodeSwiper .swiper-button-prev:after, .swiper-button-next:after {
 font-size: 20px;
}
.episodeSwiper .swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
  opacity: 0;
  cursor: auto;
  pointer-events: none;
  /* right: 20px; */
  left: 3px;
}
.episodeSwiper.swiper {
  margin-left: -15px;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
  display: block;
}
.episodeSwiper .swiper-button-prev, .swiper-rtl .swiper-button-next {
  /* left: var(--swiper-navigation-sides-offset, 10px); */
  right: auto;
}
.viewEpisode {
  font-size: 16px;
  font-weight: 600;
}
`, "",{"version":3,"sources":["webpack://./src/components/ViewAll/Slug/episodeSwiper.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;EACE,4CAA4C;EAC5C,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;;;;GAIG;;AAEH;EACE,YAAY;EACZ,WAAW;AACb;AACA;EACE,YAAY;EACZ,UAAU;AACZ;AACA;CACC,eAAe;AAChB;AACA;EACE,UAAU;EACV,YAAY;EACZ,oBAAoB;EACpB,iBAAiB;EACjB,SAAS;AACX;AACA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;EAChB,UAAU;EACV,UAAU;EACV,cAAc;AAChB;AACA;EACE,uDAAuD;EACvD,WAAW;AACb;AACA;EACE,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".episodeSwiper {\n}\n\n.swiperSlideCustom {\n  background-color: rgb(45, 48, 56) !important;\n  border-radius: 5px;\n  /* other styles */\n}\n\n/* .episodeSwiper .swiper-slide {\n  background-color:rgb(45, 48, 56) !important;\n  border-radius: 5px;\n  \n} */\n\n.episodeSwiper .swiper-button-next {\n  color: white;\n  right: -7px;\n}\n.episodeSwiper .swiper-button-prev {\n  color: white;\n  left: -4px;\n}\n.episodeSwiper .swiper-button-prev:after, .swiper-button-next:after {\n font-size: 20px;\n}\n.episodeSwiper .swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {\n  opacity: 0;\n  cursor: auto;\n  pointer-events: none;\n  /* right: 20px; */\n  left: 3px;\n}\n.episodeSwiper.swiper {\n  margin-left: -15px;\n  margin-right: auto;\n  position: relative;\n  overflow: hidden;\n  list-style: none;\n  padding: 0;\n  z-index: 1;\n  display: block;\n}\n.episodeSwiper .swiper-button-prev, .swiper-rtl .swiper-button-next {\n  /* left: var(--swiper-navigation-sides-offset, 10px); */\n  right: auto;\n}\n.viewEpisode {\n  font-size: 16px;\n  font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
