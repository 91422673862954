// External Libraries and Packages
import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import HomePageNav from '../CommonComponents/HomePageNav';
import MusicBasedMusicianCard from '../CommonComponents/MusicBasedMusicianCard';
import { connect } from 'react-redux';

const MusicBasedMusician = ({ item, swiperOptions ,languageDetailsData}) => {
    return (
        <div className='mb-4'>
            <HomePageNav link={item?.artist_slug} headerName={item?.artist_name} store={item?.Musician} seg={`${languageDetailsData?.Language_Code}/music/musician/`} />
            <Swiper {...swiperOptions} className='musicHomePageSwiper'>
                {item?.Musician?.map((value, index) => (
                    <SwiperSlide key={value?.id} >
                        <MusicBasedMusicianCard store={value} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}
const mapStateToProps = state => ({
    languageDetailsData: state.get_getUserTranslateLanguage_Reducer.languageDetailsData
})

export default connect(mapStateToProps)(MusicBasedMusician)