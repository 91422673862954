import React, { useEffect, useState } from 'react'
import "./FaqHelp.css"
import axios from 'axios';
import { getItemToken } from '../../../Utils/localStorageUtils';
import LazyLoadImage from '../../../Pages/HomePage/HomeSwiperComponents/LazyLoadImage';
import { IconMySearch } from '../../../Images/MyIcons';
import PageLoader from '../../../components/Others/PageLoader';

const FaqHelp = () => {

    const [helpQuestion, setHelpQuestion] = useState([])
    const [loading, setLoading] = useState(true)
    const userAccessToken = getItemToken('access_token');

    const fetchFaq = async () => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_Baseurl}/Front-End/FAQ-Index`, { headers: userAccessToken });
            const data = res?.data
            if (data?.status) {
                setHelpQuestion(data)
                setLoading(false)
            }
        } catch (err) {
            console.log(err);
        }
    }
    useEffect(() => {
        fetchFaq()
    }, [])
    return (
        loading ? <PageLoader /> :
            <div className='pb-5 paddingTop col-lg-8 col-md-10 col-12 mx-auto themeTwoFaq px-2 px-lg-0'>
                <h1 className='small-lg-heading text-center py-5'>Help Center</h1>
                <div className='position-relative'>
                    <input type='text' className='theme-bg-color-secondary px-5 py-4 mb-5 rounded-2 w-100  d-block border-0 outline-none' placeholder='Type your question or topic' />
                    <IconMySearch styled={{ width: "20px", height: "20px", className: "faqSearchThemeTwo position-absolute " }} />
                </div>
                <div className="accordion" id="faqAccordion">
                    {helpQuestion?.GenreFAQ?.map((genre, index) => {
                        const genreFAQs = helpQuestion?.FAQ?.filter(faq => parseInt(faq.genre_id) === genre.id);
                        return (
                            <div className="accordion-item px-3 mb-md-5 mb-3 rounded-2 border border-2 theme-border-color theme-bg-color" key={genre.id}>
                                <h2 className="accordion-header border-0" id={`heading${genre.id}`}>
                                    <button
                                        className={`accordion-button genre d-flex gap-3 align-items-center px-0 ${genreFAQs.length > 0 ? "border-2 border-bottom theme-border-color" : "border-0"}  py-2 theme-bg-color`}
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target={`#collapse${genre.id}`}
                                        aria-expanded="true"
                                        aria-controls={`collapse${genre.id}`}
                                    >
                                        <LazyLoadImage key={index} src={`https://api.flicknexs.com/public/uploads/images/${genre?.image}`} alt={genre?.name} title={genre?.name?.slice(0, 1)} classNameOptional="rounded-circle theme-bg-color-secondary accorditionImage p-1" imageSize="object-fit-contain " />
                                        <span className='theme-text-color'>{genre?.name}</span>
                                    </button>
                                </h2>
                                <div
                                    id={`collapse${genre.id}`}
                                    className={`accordion-collapse collapse ${genreFAQs.length > 0 ? "show" : ""}`}
                                    aria-labelledby={`heading${genre.id}`}
                                    data-bs-parent="#faqAccordion"
                                >
                                    <div className="accordion-body border-0 theme-bg-color p-0">
                                        {genreFAQs.length > 0 ? (
                                            <div className="accordion" id={`faqAccordion${genre.id}`}>
                                                {genreFAQs.map(faq => (
                                                    <div className="accordion-item border-2 border-bottom theme-border-color border-top-0  border-start-0 border-end-0 theme-bg-color" key={faq.id}>
                                                        <h2 className="accordion-header " id={`heading${genre.id}-${faq.id}`}>
                                                            <button
                                                                className="accordion-button  p-0  collapsed bg-transparent question theme-text-color"
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target={`#collapse${genre.id}-${faq.id}`}
                                                                aria-expanded="false"
                                                                aria-controls={`collapse${genre.id}-${faq.id}`}
                                                            >
                                                                {faq.question}
                                                            </button>
                                                        </h2>
                                                        <div
                                                            id={`collapse${genre.id}-${faq.id}`}
                                                            className="accordion-collapse collapse"
                                                            aria-labelledby={`heading${genre.id}-${faq.id}`}
                                                            data-bs-parent={`#faqAccordion${genre.id}`}
                                                        >
                                                            <div className="accordion-body px-0">
                                                                <p className='theme-text-color answer'>{faq.answer}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <p>No FAQs available for this genre.</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
    )
}

export default FaqHelp