import React from "react";
import AccountPage from "./AccountPage";
import { connect } from "react-redux";

const AccountMain = ({ currentTheme }) => {
  return (
    <div className="paddingTop">
      {currentTheme == 1 && <AccountPage />}
    </div>
  );
};

const mapStateToProps = state => ({
  languageDetailsData: state.get_getUserTranslateLanguage_Reducer.languageDetailsData,
  profileDetails: state?.get_profileDetails_Reducer?.profileDetails,
  currentTheme: state.get_headerIndex_Reducer.currentTheme,
})
export default connect(mapStateToProps)(AccountMain)
