import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import "./Content_EditNewArtist.css";
import { toast } from "react-toastify";
 
import loadings from "../../../components/Images/loading/loading.gif";
import ResponseLoader from "../../../components/Others/ResponseLoader";

function Content_EditNewArtist() {
  const { id } = useParams();
  const [artisttype, setArtisttype] = useState([]);
  const [editUser, setEditUser] = useState({
    artist_name: "",
    artist_slug: "",
    artist_email: "",
    description: "",
    artist_type: "",
    image: "",
    Thumbnail: "",
  });
  const [editname, setEditname] = useState({
    artist_name: "",
  });

  const [loading, setLoading] = useState(true);


  // let access_token, access_token_cpp;

  // if (localStorage.getItem("username")) {
  //   access_token = localStorage.getItem("access_token");
  // } else {
  //   access_token_cpp = localStorage.getItem("access_token_cpp");
  // }

  // const headers = {
  //   "Content-Type": "application/json",
  //   // Authorization: "Bearer " + access_token_cpp,
  //   Accept: "application/json",
  //   "Access-Control-Allow-Origin": "*",
  // };

  // if (access_token) {
  //   headers.Authorization = "Bearer " + access_token;
  // } else if (access_token_cpp) {
  //   headers.Authorization = "Bearer " + access_token_cpp;
  // }

  const access_token_cpp = localStorage.getItem("access_token_cpp");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token_cpp,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };


  const fileInputRef1 = useRef(null);

  const [selectedFile1, setSelectedFile1] = useState(null);

  const handleFileChange = (event, fileInputRef, setSelectedFile) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDelete = (setSelectedFile1) => {
    setSelectedFile1(null);
    if (fileInputRef1?.current) {
      fileInputRef1.current.value = null;
    }
  };


  const [autoFillValuetitle, setAutoFillValuetitle] = useState("");

  const handleInputKeyDowntitle = (event) => {
    if (event.key === "Enter") {
      setAutoFillValuetitle(editUser?.artist_name);
    }
  };

  const handleAutoFillChangetitle = (event) => {
    setAutoFillValuetitle(event.target.value);
  };

  useEffect(async () => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/artist/edit/${id}`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.artist;
      setEditUser(res);
      setEditname(res);
      setLoading(false);
    };
    getUser();

    axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/artist/create`, {
        headers: headers,
      })
      .then((response) => {
        var result = response?.data?.artist_type;
        setArtisttype(result);
      })
      .catch((error) => console.log(error));
  }, [id]);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessageartist_type("");
    setIsValidationHiddenartist_type(true);
    setValidationMessagetitle("");
    setIsValidationHiddentitle(true);

    setEditUser({ ...editUser, artist_name: e.target.value });
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };

  const [validationMessagetitle, setValidationMessagetitle] = useState("");
  const [isValidationHiddentitle, setIsValidationHiddentitle] = useState(true);

  const [validationMessageartist_email, setValidationMessageartist_email] =
    useState("");
  const [isValidationHiddenartist_email, setIsValidationHiddenartist_email] =
    useState(true);

  const [validationMessageartist_type, setValidationMessageartist_type] =
    useState("");
  const [isValidationHiddenartist_type, setIsValidationHiddenartist_type] =
    useState(true);

  const titleInputRef = useRef(null);
  const artist_emailInputRef = useRef(null);
  const artist_typeInputRef = useRef(null);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const handleUpdate = async (e) => {
    let focusInputRef = null;

    if (editUser?.title === "") {
      setValidationMessagetitle("Name cannot be empty.");
      setIsValidationHiddentitle(false);
      focusInputRef = titleInputRef;
    }
    if (editUser?.artist_email === "") {
      setValidationMessageartist_email("Artist Email cannot be empty.");
      setIsValidationHiddenartist_email(false);
      focusInputRef = artist_emailInputRef;
    }
    if (editUser?.artist_type === "") {
      setValidationMessageartist_type("Artist Type cannot be empty.");
      setIsValidationHiddenartist_type(false);
      focusInputRef = artist_typeInputRef;
    }

    e.preventDefault();

    const editInputvalue = {
      artist_name: editUser?.artist_name,
      artist_slug: editUser?.artist_slug,
      description: editUser?.description,
      artist_type: editUser?.artist_type,
      artist_email: editUser?.artist_email,
      Thumbnail: editUser?.Thumbnail,
    };

    let firstApiStatus = false;
    let secondApiStatus = false;
    let resultapi = null;
    let resjson = null;
    let resultapifalse = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/artist/update/${id}`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(editInputvalue),
        }
      );

      resjson = await res.json();

      if (resjson?.status === true) {
        firstApiStatus = true;

        resultapi = resjson;

        var result = resjson?.artist_id;

        const formData = new FormData();
        formData.append("artist_id", result);
        if (selectedFile1) {
          formData.append("image", selectedFile1);
        }

        await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/Image-upload-artist`,
          formData,
          { headers: headers }
        );
        secondApiStatus = true;
      } else if (resjson?.status === false) {
        // Handle the case when the first API response status is false
        resultapifalse = resjson;
      }
    } catch (error) {
      console.error("Error:", error);
    }

    if (firstApiStatus && secondApiStatus) {
      setValidationMessagetitle("");
      setValidationMessageartist_type("");
      // props.setApiresponsealert(resultapi);
      // props.setShowalert(true);
      toast.success(resultapi?.message);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else if (resultapifalse) {
      // props.setApiresponsealert(resultapifalse);
      // props.setShowalert(true);
      toast.error(resultapifalse?.message);
      setProcessing(false);
      setShowOverlay(false);
      setIsValidationHiddentitle(false);
      setValidationMessageartist_type(false);
    }
  };

  return (
    <>
      <div className=" m-0 p-0">
        <section className="container-fluid  theme-bg-color-secondary ">
          <div className="col-lg-12 row py-3">
            <div className=" col-6 col-sm-6 col-lg-6">
              <h3 className=" theme-text-color">Edit Artist</h3>
            </div>
            <div className="col-6 col-sm-6 col-lg-6">
              <div className="text-end">
                <button onClick={handleUpdate} className="btn btn-primary">
                  Update Artist
                </button>
              </div>
            </div>
          </div>

          <div>
            {showOverlay && (<ResponseLoader />)}
          </div>

          <div className="row col-lg-12 ">
            <div className=" col-sm-6 col-md-6 col-lg-6 ">
              <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                <h5 className=" theme-text-color">{editname?.artist_name}</h5>
                <hr className=" theme-text-color"></hr>
                <div className=" text-start">
                  <label className="m-0 theme-text-color">
                    Artist Name<span className="mandatory">*</span>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      id="artist_name"
                      name="artist_name"
                      className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                      value={editUser?.artist_name}
                      onChange={handleInput}
                      ref={titleInputRef}
                    />
                  </div>
                  <span className="errorred">
                    {!isValidationHiddentitle && (
                      <p>{validationMessagetitle}</p>
                    )}
                  </span>
                </div>

                <div className="mt-2 text-start">
                  <label className="m-0 theme-text-color">Artist Slug</label>

                  <div className="mt-2">
                    <input
                      type="text"
                      className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                      id="artist_slug"
                      name="artist_slug"
                      placeholder="Enter Slug"
                      onChange={handleInput}
                      value={editUser?.artist_slug}
                    />
                  </div>
                </div>

                <div className=" text-start mt-2">
                  <label className="m-0 theme-text-color">
                    Artist Email<span className="mandatory">*</span>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      id="artist_email"
                      name="artist_email"
                      className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                      value={editUser?.artist_email}
                      onChange={handleInput}
                      ref={artist_emailInputRef}
                    />
                  </div>
                  <span className="errorred">
                    {!isValidationHiddenartist_email && (
                      <p>{validationMessageartist_email}</p>
                    )}
                  </span>
                </div>

                <div className="row mt-2 text-start">
                  <div className="col-12">
                    <label className="m-0 theme-text-color">
                      Artist Type<span className="mandatory">*</span>
                    </label>

                    <div className="mt-2">
                      <select
                        className="form-select  theme-bg-color-secondary  border-0 theme-text-color"
                        name="artist_type"
                        id="artist_type"
                        value={editUser?.artist_type}
                        ref={artist_typeInputRef}
                        onChange={handleInput}
                      >
                        <option value="0">Select the Artist Type</option>
                        {artisttype?.map((item) => (
                          <option key={item?.value} value={item?.value}>
                            {item?.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <span className="errorred">
                      {!isValidationHiddenartist_type && (
                        <p>{validationMessageartist_type}</p>
                      )}
                    </span>
                  </div>
                </div>
              </div>

              <div className="text-start mb-3">
                <button onClick={handleUpdate} className="btn btn-primary">
                  Update Artist
                </button>
              </div>
            </div>

            {/* Second Part of Section  */}

            <div className=" col-sm-6 col-md-6 col-lg-6">
              <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                <h5 className=" theme-text-color">Thumbnails</h5>
                <hr className=" theme-text-color"></hr>

                <div className=" text-start mt-2">
                  <label className="m-0  theme-text-color">Cover Image</label>

                  <div>
                    {loading ? (
                      <img src={loadings} className="thumbnails-images" />
                    ) : (
                      <img
                        src={editUser?.Thumbnail}
                        alt="Image"
                        className="thumbnails-images"
                      />
                    )}
                  </div>

                  <div className="mt-2">
                    <div className="col-lg-12 row">
                      <div className=" col-sm-6 col-lg-6">
                        <div
                          className="imagedrop"
                          onClick={() => fileInputRef1.current.click()}
                        >
                          <input
                            type="file"
                            ref={fileInputRef1}
                            onChange={(event) =>
                              handleFileChange(
                                event,
                                fileInputRef1,
                                setSelectedFile1
                              )
                            }
                            accept="image/*"
                            style={{ display: "none" }}
                          />
                          <label>
                            <CameraRetroIcon />
                          </label>
                        </div>
                      </div>
                      <div className=" col-sm-6 col-lg-6">
                        <div>
                          {selectedFile1 && (
                            <div className="imagedropcopy text-end">
                              <div className="col-lg-12 row p-0 m-0">
                                <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                  <img
                                    src={URL?.createObjectURL(selectedFile1)}
                                    alt="Uploaded"
                                    className="thumbnails-upload-images"
                                  />
                                </div>
                                <div
                                  className="col-sm-2 col-lg-2 p-0 m-0"
                                  style={{ alignSelf: "center" }}
                                >
                                  <button
                                    onClick={() =>
                                      handleDelete(setSelectedFile1)
                                    }
                                    type="button"
                                    className="btn-close theme-text-color"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                <h5 className=" theme-text-color">Descriptions</h5>
                <hr className=" theme-text-color"></hr>

                <div className="text-start">
                  <label className="m-0 theme-text-color">Artist Description</label>

                  <div className="mt-2">
                    <div className="col-lg-12">
                      <div className="panel-body">
                        <textarea
                          className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                          value={editUser?.description}
                          onChange={handleInput}
                          placeholder="Artist Description"
                          name="description"
                          id="description"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
export default Content_EditNewArtist;
