import React, { act } from 'react'
import { BsFillPauseFill, BsFillPlayFill } from 'react-icons/bs';
import { connect } from 'react-redux';
import { useMyContext } from '../../../components/Layout/CommonProvider';
import { IconMyPause, IconMyPlay } from '../../../Images/MyIcons';
const PlayButton = ({ currentAudio, currentPlaying, type, store, className, playlistData, actionType }) => {
    const { handlePlay } = useMyContext();
    return (
        <button onClick={() => handlePlay(type, store?.slug ? store?.slug : store?.artist_slug, store?.id)} className={`  ${className ? className : 'theme-button-bg-color position-absolute musicPlayButton'}  mx-auto my-0 rounded-circle d-flex align-items-center justify-content-center ${currentAudio?.id == store?.id || playlistData?.id == store?.id && type == actionType ? 'visible' : ""} `}>
            {(!currentPlaying || (type !== 'ARTIST' && type !== 'ALBUM' && type !== "PLAYLIST" && type !== "STATION" ? currentAudio?.id !== store?.id : playlistData?.id !== store?.id || type !== actionType))
                ? <IconMyPlay styled={{ width: "20px", height: "20px", className: "musicIconHome triangle" }} />
                : <IconMyPause styled={{ width: "20px", height: "20px", className: "musicIconHome doublecode " }} />}
        </button>
    )
}

const mapStateToProps = state => ({
    currentAudio: state.get_current_Reducers.currentAudio,
    currentPlaying: state.get_current_Reducers.currentPlaying,
    actionType: state.get_current_Reducers.actionType,
    playlistData: state.get_current_Reducers.playlistData,
})

export default connect(mapStateToProps)(PlayButton)