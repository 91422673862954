import React, { useState } from 'react'
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import { MdOutlineWatchLater, MdWatchLater } from "react-icons/md";
import { FiThumbsUp, FiThumbsDown, FiMinusCircle } from "react-icons/fi";
import { FaShareAlt } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import PlayImage from "../Images/play-details-btn.png"
import PlusImage from "../Images/play-details-plus.png"
import ToolTip from './ToolTip';
import SocialMediaShareBox from './SocialMediaShareBox';
import { connect } from 'react-redux';
import PaymentModal from './PaymentModal';
import { LikeAction, disLikeAction, watchLaterAction, wishListAction } from '../../Pages/CommonMethods/CommonMethods';
import MessageBox from './MessageBox';
const CommonViewDetail = ({ data, settings, categoryUrl, navigationName, playerUrl, reloadAction, codeNumber }) => {
    const access_token = localStorage.getItem("access_token");
    const user_role = localStorage.getItem("role");
    const user_id = localStorage.getItem("user_id");
    const [expanded, setExpanded] = useState(false);
    const currentPageURL = window.location.href;
    const handleLikeClick = async () => {
        try {
            let userRole = access_token ? user_role : 9;
            const likeStatus = data?.like == null || data?.like == 0 ? 1 : 0
            await LikeAction(user_id, userRole, 'web', data?.id, codeNumber, likeStatus);
            reloadAction();
        } catch (error) {
            console.error(error);
        }
    };

    const handleDisikeClick = async () => {
        try {
            let userRole = access_token ? user_role : 9;
            const likeStatus = data?.dislike == null || data?.dislike == 0 ? 1 : 0;
            await disLikeAction(user_id, userRole, 'web', data?.id, codeNumber, likeStatus);
            reloadAction();
        } catch (error) {
            console.error(error);
        }

    };

    const handleClickWishList = async () => {
        try {
            let userRole = access_token ? user_role : 9;
            await wishListAction(user_id, userRole, 'web', data?.id, codeNumber);
            reloadAction();
        } catch (error) {
            console.error(error);
        }
    }

    const handleClickWatchLater = async () => {
        try {
            let userRole = access_token ? user_role : 9;
            await watchLaterAction(user_id, userRole, 'web', data?.id, codeNumber);
            reloadAction();
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div style={{ backgroundImage: `url(${data?.Player_thumbnail})`, backgroundPosition: 'center', backgroundSize: 'cover', width: '100%', height: '100%', }} className='px-md-5 px-4 py-md-3 py-1 linearShadow '>
            <PaymentModal />
            <div className='position-relative'>
                <Breadcrumb bread1="Home" bread2={navigationName} bread3={data?.title} link1="/" link2={`${categoryUrl}`} slase />
                <div className='pageWrapper'><div className="d-flex aligns-items-center w-100 flex-wrap">
                    <div className="col-12 col-lg-6 col-md-6 pe-4">
                        <h3 className="midHeading theme-text-color mb-3 text-wrap ">
                            {data?.title?.length > 17 ? data?.title?.substring(0, 12) : data?.title}
                            {data?.name?.length > 17 ? data?.name?.substring(0, 12) : data?.name}
                        </h3>
                        {data?.categories.length > 0 ?
                            <div className='d-flex align-items-center mb-2' >
                                <p className='theme-text-color d-inline-block me-2'>Category : </p>
                                <div className="d-flex  flex-wrap">
                                    {data?.categories?.map((item) => (<label className='smallText1 beforeCircle theme-text-color-list me-4' >{item.name}</label>))}
                                </div>
                            </div> : ''}

                        {data?.Language?.length > 0 ?
                            <div className='d-flex align-items-center mb-2' >
                                <p className='theme-text-color d-inline-block me-2'>Language : </p>
                                <div className="d-flex flex-wrap">
                                    {data?.Language?.map((item) => (<label className='smallText1 beforeCircle theme-text-color-list me-4'>{item.name}</label>))}
                                </div>
                            </div> : ''}

                        {data?.artists?.length > 0 ?
                            <div className='d-flex align-items-center mb-2' >
                                <p className='theme-text-color d-inline-block me-2'>Artist : </p>
                                <div className="d-flex flex-wrap">
                                    {data?.artists?.map((item) => (<label className='smallText1 beforeCircle theme-text-color-list me-4'>{item.artist_name}</label>))}
                                </div>
                            </div> : ''}

                        {data?.duration_format ? <label className='smallText1'>{data?.duration_format}</label> : ""}
                        <div className="overview">
                            <div className='expendedHeight'>
                                {
                                    data?.description?.length > 141 ? (
                                        <p className='smallText pt-4'>
                                            {expanded ? (
                                                <div className="expandable">
                                                    <MessageBox text={data?.description.toString().substring(0, data?.description?.length)} />
                                                </div>
                                            ) : (
                                                <>
                                                    <MessageBox text={data?.description.toString().substring(0, 141)} />
                                                    <span onClick={() => setExpanded(!expanded)} className="smallText extraBold d-block mt-2"> {expanded ? ' Show Less' : ' Show More'} </span>
                                                </>
                                            )}
                                        </p>
                                    ) : (
                                        <>
                                            <MessageBox text={data?.description} />
                                        </>
                                    )
                                }
                            </div>

                            <span onClick={() => setExpanded(!expanded)} className="smallText extraBold d-block"> {expanded ? ' Show Less' : ''} </span>

                            <div className="d-inline-flex align-items-center gap-4 pt-3 position-relative">
                                <div className='shareContainer'>
                                    <FaShareAlt className="defaultIconStyle" />
                                    <SocialMediaShareBox URL={currentPageURL} />
                                </div>
                                <FiThumbsUp className={`defaultIconStyle ${data?.like == 1 ? "like-active" : ""}`}
                                    onClick={handleLikeClick} />
                                <FiThumbsDown className={`defaultIconStyle ${data?.dislike == 1 ? "dislike-active" : ""}`}
                                    onClick={handleDisikeClick} />
                                <div className='toolTipParent'>
                                    {data?.WatchLater !== null ? <MdWatchLater className='watchlaterIconStyle' onClick={handleClickWatchLater} /> : <MdOutlineWatchLater className='watchlaterIconStyle' onClick={handleClickWatchLater} />}
                                    <ToolTip bgColor="#000" text="Watchlater" top="-30px" right="-53px" className="px-3 py-2 rounded-2 position-absolute d-none " />
                                </div>
                            </div>
                            <div className="pt-4 d-flex flex-wrap gap-md-3 gap-1 justify-content-md-start">



                                {!user_role && settings.access_free == 1 ?
                                    <>
                                        <Link to={"/signup"} className="theme-button-bg-color px-3 py-2 rounded-2 d-inline-block"> Rent Now</Link>
                                        {data?.access == "ppv" || data?.access == "subscriber" || data?.access == "registered" ? <Link to={"/signup"} className="theme-button-bg-color px-3 py-2 rounded-2 d-inline-block"> Subscribe Now</Link> : ""}
                                    </>

                                    : !user_role && settings.access_free == 1 && data?.access == "guest" ?
                                        <Link to={`/${playerUrl}/${data?.slug}/player`} state={data} className='theme-button-bg-color px-3 py-2 rounded-2'>
                                            <span>Play Now</span>
                                            <img src={PlayImage} className='ms-3' style={{ width: "18px", height: "20px" }} />
                                        </Link>
                                        : settings.access_free == 1 && user_role == 2 && data?.access == "ppv" ?           ///Register
                                            <div>
                                                <button className="theme-button-bg-color px-3 py-2 rounded-2" type="button" data-bs-toggle="modal" data-bs-target="#staticBackdrop">Rent Now</button>
                                                <Link to={"/plan"} className="theme-button-bg-color px-3 py-2 rounded-2"> Subscribe Now   </Link>
                                            </div>
                                            : settings.access_free == 1 && user_role == 2 && data?.access == "subscriber" ?
                                                <Link to={"/plan"} className="theme-button-bg-color px-3 py-2 rounded-2"> Subscribe Now  </Link>
                                                : user_role == 2 && data?.access == "registered" ?           ///Register
                                                    <Link to={`/${playerUrl}/${data?.slug}/player`} state={data} className='theme-button-bg-color d-flex align-items-center justify-content-between px-3 py-2 rounded-2'>
                                                        <span>Play Now</span><img src={PlayImage} className='ms-3' style={{ width: "18px", height: "20px" }} />
                                                    </Link>
                                                    : user_role == 2 && data?.access == "guest" ?
                                                        <Link to={`/${playerUrl}/${data?.slug}/player`} state={data} className='theme-button-bg-color d-flex align-items-center justify-content-between px-3 py-2 rounded-2'>
                                                            <span>Play Now</span><img src={PlayImage} className='ms-3' style={{ width: "18px", height: "20px" }} />
                                                        </Link>                    ///Register

                                                        : user_role == 3 && data?.access == "ppv" && settings?.access_free == 1 ?          /// Subscriber
                                                            <Link to={`/${playerUrl}/${data?.slug}/player`} state={data} className="theme-button-bg-color d-flex align-items-center justify-content-between px-3 py-2 rounded-2">
                                                                <span>Play Now</span><img src={PlayImage} className='ms-3' style={{ width: "18px", height: "20px" }} />
                                                            </Link>
                                                            : user_role == 3 && data?.access == "subscriber" || "registered"
                                                                && data?.access == "guest" ? <>
                                                                <Link to={`/${playerUrl}/${data?.slug}/player`} state={data} className="theme-button-bg-color d-flex align-items-center justify-content-between px-3 py-2 rounded-2">
                                                                    <span>Play Now</span><img src={PlayImage} className='ms-3' style={{ width: "18px", height: "20px" }} />
                                                                </Link>
                                                            </>
                                                                : user_role == 3 && data?.access == "guest"
                                                                    ? <Link to={`/${playerUrl}/${data?.slug}/player`} state={data}
                                                                        className='theme-button-bg-color d-flex align-items-center justify-content-between px-3 py-2 rounded-2'><span>Play Now</span><img src={PlayImage} className='ms-3' style={{ width: "18px", height: "20px" }} />
                                                                    </Link>
                                                                    : <Link to={`/${playerUrl}/${data?.slug}/player`} state={data}
                                                                        className='theme-button-bg-color d-flex align-items-center justify-content-between px-3 py-2 rounded-2'><span>Play Now</span><img src={PlayImage} className='ms-3' style={{ width: "18px", height: "20px" }} />
                                                                    </Link>}
                                <button className="add-btn d-flex align-items-center justify-content-between px-3 py-2 rounded-2 bg-transparent border theme-border-color " type="button" onClick={handleClickWishList}>{data?.wishlist ? <> Remove To WishList  <FiMinusCircle className="ms-3" style={{ "fontSize": "24px", "fontWeight": "bold" }} /></> : <>Add To WishList<img className="ms-3" src={PlusImage} /> </>} </button>
                            </div>
                        </div>

                    </div>
                    <div className="col-12 col-lg-6 col-md-6 d-none d-md-block">
                        <img src={data?.Player_thumbnail} alt={data?.slug} className="detailsSideImage lazyload d-block mx-auto w-100" loading="lazy" />
                    </div>
                </div>
                </div>
            </div>

        </div>
    )
}
const mapStateToProps = state => ({
    settings: state.get_setting_Reducer.settings
});

export default connect(mapStateToProps)(CommonViewDetail)