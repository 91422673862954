import React, { useEffect, useState } from 'react'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'
import '../../Pages/Music/Music.css'
import MusicListPanel from '../../Pages/Music/MusicListPanel'
import MusicBannerPanelHorizontal from '../../Pages/Music/MusicBannerPanelHorizontal'
import { connect, useDispatch } from "react-redux";
import { getMusicSettingsAction } from '../../Redux/Actions/apiActions'
import MusicSideBar from '../../Pages/Music/MusicSideBar'
import { getMusicHomePageAction } from '../../Redux/Actions/MusicAction'
import PlaylistModal from '../../Pages/Music/PlaylistModal'
import MusicStationModal from '../../Pages/Music/MusicStationModal'
import { getItem, getItemToken } from '../../Utils/localStorageUtils'
import PurchaseModal from '../../Pages/Music/PurchaseModal'
import { FaBars } from "react-icons/fa";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'
import LazyLoadImage from '../../Pages/HomePage/HomeSwiperComponents/LazyLoadImage'
import MusicHeader from '../../Pages/Music/Layout/MusicHeader'
import LoginIndex from '../../Pages/Auth/LoginIndex'
import SignUpIndex from '../../Pages/Auth/SignUpIndex'

// import { useMyContext } from './CommonProvider'

const MusicLayout = ({ getMusicData, settings, playlistShow, initialModal, stationModal, purchaseModal, minimize, languageDetailsData, themeMode }) => {
    // const { AudioPlayer } = useMyContext();
    const userRoleLocal = getItem('role')
    const userIdLocal = getItem('user_id')
    const userAccessToken = getItemToken('access_token');
    const [toggleSideNav, setToggleSideNav] = useState(false)
    const [modalOpen, setModalOpen] = useState({
        signUpModal: false,
        signInModal: false,
    })
    const handleLoginPopup = () => {
        setModalOpen((prevState) => ({ ...prevState, signInModal: !prevState.signInModal }));
        // closeSideBar()
    }
    const handleSignInModal = async () => {
        setModalOpen((prevState) => ({ ...prevState, signInModal: !prevState.signInModal }));
    }

    const handleSignUpModal = async () => {
        setModalOpen((prevState) => ({ ...prevState, signUpModal: !prevState.signUpModal }));
    }

    const handleSignInSwitchModal = async () => {
        setModalOpen((prevState) => ({ ...prevState, signInModal: !prevState.signInModal, signUpModal: !prevState.signUpModal }));
    }

    const handleSignUpSwitchModal = async () => {
        setModalOpen((prevState) => ({ ...prevState, signUpModal: !prevState.signUpModal, signInModal: !prevState.signInModal, }));
    }

    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        try {
            dispatch(getMusicSettingsAction(userAccessToken));
            dispatch({ type: "GET_CLOSE_SUCCESS", isClose: true });
        }
        catch (error) {
            console.log(error)
        }
    }, [])
    useEffect(() => {
        if (settings && Object.keys(settings).length > 0) {
            if (settings.access_free == 1) {
                dispatch(getMusicHomePageAction())
            }
        }
    }, [settings, userRoleLocal])

    const toggleSideBar = () => {
        setToggleSideNav(!toggleSideNav)
    }
    const goBack = () => {
        navigate(-1);
    };
    const goForward = () => {
        navigate(1);
    };

    useEffect(() => {
        setToggleSideNav(false)
    }, [location])
    return (

        <div className='musicHeight'>
            {initialModal && <PlaylistModal />}
            {stationModal && <MusicStationModal />}
            {purchaseModal && <PurchaseModal />}
            {modalOpen?.signInModal && <LoginIndex popUpClass="position-fixed popupModal d-flex align-items-center justify-content-center" headerDisable modalClose={handleSignInModal} signUpModal={handleSignUpSwitchModal} signInModal={handleSignInSwitchModal} />}
            {modalOpen?.signUpModal && <SignUpIndex popUpClass="position-fixed popupModal d-flex align-items-center justify-content-center" headerDisable modalClose={handleSignUpModal} signUpModal={handleSignUpSwitchModal} signInModal={handleSignInSwitchModal} />}
            <MusicSideBar className={toggleSideNav ? 'activeSideBar' : ''} closeSideBar={toggleSideBar} authAction={handleLoginPopup} />
            <div className='d-flex align-items-center justify-content-between px-3 py-2 theme-bg-color-secondary position-fixed mobileSideBar  d-lg-none'>
                <FaBars onClick={toggleSideBar} />
                <Link to="/" className="py-2 position-relative homeNavbarBrand" style={{ width: "171px", height: "42px", objectFit: "contain" }}>
                    <LazyLoadImage src={themeMode?.logoUrl} alt="header-logo" imageSize="loaderMain object-fit-contain " classNameOptional="rounded-2" />
                </Link>
                <div>
                    <div className='d-flex align-items-center justify-content-between  '>
                        <button type='button' className='theme-bg-color  border-0 p-0 rounded-circle musicGoBackBtn' onClick={goBack} disabled={location.pathname === `/${languageDetailsData?.Language_Code}/music`}><MdKeyboardArrowLeft className='musicArrow' /></button>
                        <button type='button' className='theme-bg-color  border-0 p-0 rounded-circle' onClick={goForward}><MdKeyboardArrowRight className='musicArrow' /></button>
                    </div>
                </div>
            </div>
            {/* <div className={`d-flex flex-wrap rightMusicContainer  flex-md-row py-5 pt-lg-0`}>
                <div className={`${!playlistShow ? 'col-12' : 'col-lg-8 '} col-12  position-relative px-2 pt-2  `}>
                    <div className={`p-lg-2 rounded-3 musicListHeight ${getMusicData?.enable_floating && !minimize && 'activeFloat'}`}>
                        <Outlet />
                    </div>
                </div>
                {playlistShow && <div className='col-12 col-lg-4 p-2  pe-lg-2 pt-lg-2'> <MusicListPanel /> </div>}
            </div> */}

            <div className="rightMusicContainer paddingTopMusic">
                <MusicHeader toggleSideBar={toggleSideBar} authAction={handleLoginPopup} />
                <div className='d-flex flex-wrap flex-md-row'>
                    <div className={`${!playlistShow ? 'col-12' : 'col-lg-8 '} col-12  position-relative px-md-4 px-1`}>
                        <div className={`rounded-3 musicListHeight ${getMusicData?.enable_floating && !minimize && 'activeFloat'}`}>
                            <Outlet />
                        </div>
                    </div>
                    {playlistShow && <div className='col-12 col-lg-4 pe-lg-2 slide-right'> <MusicListPanel /> </div>}
                </div>
            </div>
        </div>

    )
}

const mapStateToProps = state => ({
    getMusicData: state.get_Music_Settings.getMusicData,
    settings: state.get_setting_Reducer.settings,
    playlistShow: state.get_Playlist_Modal.playlistShow,
    initialModal: state.get_Playlist_Modal.initialModal,
    stationModal: state.get_Playlist_Modal.stationModal,
    purchaseModal: state.get_Playlist_Modal.purchaseModal,
    minimize: state.get_Playlist_Modal.minimize,
    languageDetailsData: state.get_getUserTranslateLanguage_Reducer.languageDetailsData,
    themeMode: state.get_headerIndex_Reducer.themeMode,
})

export default connect(mapStateToProps)(MusicLayout)