// External Libraries and Packages
import React from "react";
import { Link } from "react-router-dom";
import { FaPlay } from "react-icons/fa";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
// Internal Styles
import "swiper/css";
import "swiper/css/navigation";
import { useTranslation } from "react-i18next";
// Internal Components'
const Purchase_Media_Register_Payments = ({ item }) => {
 const { t } = useTranslation();

  const purchase_Media_Register_Payments = [
    {
      id: 18,
      user_id: 2,
      title: "six",
      artist_email: "six",
      slug: "six",
      description:
        "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used.",
      type: "Musician",
      Thumbnail:
        "https://api.flicknexs.com/public/uploads/images/video-player-image-1705989548.webp",
      upload_on: "December 13th 2023",
    },
    {
      id: 16,
      user_id: 2,
      title: "four",
      artist_email: "four@gmail.com",
      slug: "four",
      description:
        "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used.",
      type: "Musician",
      Thumbnail:
        "https://api.flicknexs.com/public/uploads/images/video-player-image-1705989548.webp",
      upload_on: "December 13th 2023",
    },
  ];
  const purchase_Media_Register_Paymentslive = [
    {
      id: 18,
      user_id: 2,
      title: "six",
      artist_email: "six",
      slug: "six",
      description:
        "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used.",
      type: "Musician",
      Thumbnail:
        "https://api.flicknexs.com/public/uploads/images/video-player-image-1705989548.webp",
      upload_on: "December 13th 2023",
    },
    {
      id: 16,
      user_id: 2,
      title: "four",
      artist_email: "four@gmail.com",
      slug: "four",
      description:
        "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used.",
      type: "Musician",
      Thumbnail:
        "https://api.flicknexs.com/public/uploads/images/video-player-image-1705989548.webp",
      upload_on: "December 13th 2023",
    },
  ];

  return (
    <section className="container-fluid paddingTop">
      <div className="py-3 ">
        <div>
          <Link>
            <h4 className="color theme-text-color">{t("Rental Videos")}</h4>
          </Link>
        </div>
        <div className="mt-2">
          <Swiper
            slidesPerView={1}
            spaceBetween={40}
            navigation={true}
            breakpoints={{
              375: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              1024: {
                slidesPerView: 6,
                spaceBetween: 10,
              },
            }}
            modules={[Navigation]}
            className="homePageSwiper"
          >
            {purchase_Media_Register_Payments?.map((value) => (
              <SwiperSlide style={{ width: "208px", height: "280px" }} className='theme-bg-color-secondary'>
                <div
                  style={{
                    backgroundImage: `url(${value?.Thumbnail})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    width: "100%",
                    height: "100%",
                    position: "relative",
                    borderRadius: "6px",
                  }}
                ></div>
                <Link
                  to={`/shows/${item?.source.toLowerCase()}/${value?.slug}`}
                  style={{ "text-decoration": "none" }}
                >
                  <div className="homeSwiperContainer bg-image-gradian global-shadow">
                    <img
                      src={value?.Thumbnail}
                      className="homeListImage"
                      loading="lazy"
                    />
                    <div className="homeListContent p-1">
                      <button className="homeListButton d-flex align-items-center justify-content-center">
                        <FaPlay className="homeListPlayIcon" />
                        <button className="ms-2 mb-0 homeListButtonText bgButton">
                          {t("Watch now")}
                        </button>
                      </button>
                      <p className="homeListText text-start  theme-text-color">
                        {t(value?.title)}
                      </p>
                      <p className="homeListDesc text-start  theme-text-color">
                        {t(value?.description)}
                      </p>
                    </div>
                  </div>
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <div className="py-4 ">
        <div>
          <Link>
            <h4 className="color">{t("Rental Live Videos")}</h4>
          </Link>
        </div>
        <div className="mt-2">
          <Swiper
            slidesPerView={1}
            spaceBetween={40}
            navigation={true}
            breakpoints={{
              375: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              1024: {
                slidesPerView: 6,
                spaceBetween: 10,
              },
            }}
            modules={[Navigation]}
            className="homePageSwiper"
          >
            {purchase_Media_Register_Paymentslive?.map((value) => (
              <SwiperSlide style={{ width: "208px", height: "280px" }} className='theme-bg-color-secondary'>
                <div
                  style={{
                    backgroundImage: `url(${value?.Thumbnail})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    width: "100%",
                    height: "100%",
                    position: "relative",
                    borderRadius: "6px",
                  }}
                ></div>
                <Link
                  to={`/shows/${item?.source.toLowerCase()}/${value?.slug}`}
                  style={{ "text-decoration": "none" }}
                >
                  <div className="homeSwiperContainer bg-image-gradian global-shadow">
                    <img
                      src={value?.Thumbnail}
                      className="homeListImage"
                      loading="lazy"
                    />
                    <div className="homeListContent p-1">
                      <button className="homeListButton d-flex align-items-center justify-content-center">
                        <FaPlay className="homeListPlayIcon" />
                        <button className="ms-2 mb-0 homeListButtonText bgButton">
                          {t("Watch now")}
                        </button>
                      </button>
                      <p className="homeListText  theme-text-color">
                       {t(value?.title)}
                      </p>
                      <p className="homeListDesc   theme-text-color">
                        {t(value?.description)}
                      </p>
                    </div>
                  </div>
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default Purchase_Media_Register_Payments;
