import React, { useEffect, useRef } from 'react';

import videojs from 'video.js';
import 'video.js/dist/video-js.css';


const VideoPlayerCityTheme = () => {

    const videoNodeRef = useRef(null);
    let player = null;
  
    const playerOptions = {
        controls: true,
        autoplay: true,
        sources: [
          {
            src: 'https://plrjs.com/new.mp4',
            type: 'video/mp4',
          },
        ],
      };

    useEffect(() => {
      player = videojs(videoNodeRef.current, playerOptions, () => {
        console.log('Player ready');
      });
  
      return () => {
        if (player) {
          player.dispose();
        }
      };
    }, [playerOptions]);

    
  return (
    <div id='root' className='paddingTop'>
        <video id="my-player" ref={videoNodeRef}  className="video-js vjs-city-theme" ></video>
    </div>
  );
};

export default VideoPlayerCityTheme  
