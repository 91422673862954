// External Libraries and Packages
import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

// Internal Components
import { contentDetailsAction } from '../../../Redux/Actions/apiActions';
import PartnerHomepage from '../../../ThemeTwo/Components/Details/SwiperPartner/PartnerHomepage';
import { getItem } from '../../../Utils/localStorageUtils';


const PartnerDetails = ({ contentDetailsData, isLoading, error, settings, homepage, thumbnailTitle }) => {
    const dispatch = useDispatch();
    const { slug } = useParams()
    const userRoleLocal = getItem('role')
    const userIdLocal = getItem('user_id')

    const sliderData = [
        { imageURL: 'https://img1.hotstarext.com/image/upload/f_auto,t_web_m_1x/sources/r1/cms/prod/8665/908665-h' },
        { imageURL: 'https://img1.hotstarext.com/image/upload/f_auto,t_web_m_1x/sources/r1/cms/prod/6363/1714177996363-h' },
        { imageURL: 'https://jfwonline.com/wp-content/uploads/2022/01/maxresdefault.jpg' },
    ]

    useEffect(() => {
        if (settings && Object.keys(settings).length > 0) {
            !userRoleLocal && settings.access_free == 1
                ? dispatch(contentDetailsAction(null, 9, "web", slug))
                : userRoleLocal && dispatch(contentDetailsAction(userIdLocal, userRoleLocal, "web", slug));
        }
    }, [settings, userRoleLocal])

    return (<PartnerHomepage sliderData={sliderData} data={homepage} thumbnailTitle={thumbnailTitle} type="Content" />
    )
}
const mapStateToProps = state => ({
    contentDetailsData: state.get_contentDetails_Reducer.contentDetailsData,
    isLoading: state.get_contentDetails_Reducer.isLoading,
    error: state.get_contentDetails_Reducer.error,
    settings: state.get_setting_Reducer.settings,
    homepage: state.get_allHomePage_Reducer.homepage,
    thumbnailTitle: state.get_allThumbnail_Reducer.thumbnailTitle
});
export default connect(mapStateToProps)(PartnerDetails)
