import React, { useCallback, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import './episodeSwiper.css';
import { Navigation } from "swiper/modules";
import SingleSeasonDetails from './SingleSeasonDetails';
import { connect, useDispatch } from 'react-redux';
import { seasonDetailsAction } from '../../../Redux/Actions/apiActions';
import EpisodesSkeletonTabs from '../../../Pages/HomePage/HomeSwiperComponents/EpisodesSkeletonTabs';

const EpisodesTabsection = ({ seriesDetailsData, settings, isLoading, seasonDetailsData }) => {
    const [seasons, setSeasons] = useState([]);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const episodeLength = seriesDetailsData?.series_season?.length || 0;
    const generateSeasons = (length) => {
        return Array.from({ length }, (_, i) => {
            const seasonId = seriesDetailsData.series_season[i]?.id || i + 1; // Assuming seasonId is inside seriesDetailsData.series_season
            return {
                id: i + 1,
                seasonName: `Season ${i + 1}`,
                seasonId: seasonId,
                content: (
                    <SingleSeasonDetails key={seasonId} seriesDetailsData={seriesDetailsData}
                        settings={settings} seasonNumber={`S${i + 1}`} seasonId={seasonId} />
                )
            };
        });
    };

    useEffect(() => {
        const episodeLength = seriesDetailsData.series_season.length;
        const generatedSeasons = generateSeasons(episodeLength).reverse();
        setSeasons(generatedSeasons);
        setLoading(false);
    }, [seriesDetailsData]);

    useEffect(() => {
        setActiveTab(0);
        setCurrentTab(seasons[0]?.id);
    }, [seasons]);

    const user_role = localStorage.getItem("role");
    const user_id = localStorage.getItem("user_id");
    const access_token = localStorage.getItem("access_token");
    const headers = { "Content-Type": "application/json", Authorization: "Bearer " + access_token, Accept: "application/json", };
    const [activeTab, setActiveTab] = useState(0);
    const [episodes, SetEpisodes] = useState([])
    const [currentTab, setCurrentTab] = useState(seasons[0]?.id);

    const handleTabClick = (value) => {
        setCurrentTab(value?.a);
        if (settings && Object.keys(settings).length > 0) {
            !user_role && settings.access_free == 1
                ? dispatch(seasonDetailsAction(null, 9, "web", seriesDetailsData?.id, value?.b, headers))
                : user_role && dispatch(seasonDetailsAction(user_id, user_role, "web", seriesDetailsData?.id, value?.b, headers));
        }
    };

    return (
        <div>
            {loading ? (
                <EpisodesSkeletonTabs />
            ) :
                <Swiper
                    modules={[Navigation]}
                    loop={false}
                    pagination={{ clickable: true }}
                    navigation={true}
                    spaceBetween={30}
                    lazy={true}
                    slidesPerGroup={1}
                    className={`swiper-container episodeSwiper`}
                    breakpoints={{
                        576: {
                            slidesPerView: 2,
                            spaceBetween: 30,
                            slidesPerGroup: 2,
                        },
                        768: {
                            slidesPerView: 3,
                            spaceBetween: 30,
                            slidesPerGroup: 3,
                        },
                        1200: {
                            slidesPerView: 8,
                            spaceBetween: 30,
                            slidesPerGroup: 8,
                        },
                        1440: {
                            slidesPerView: 10,
                            spaceBetween: 30,
                            slidesPerGroup: 10,
                        }
                    }}>

                    {seasons?.map((season, index) => (
                        <SwiperSlide key={index} className={`swiper-slide ${index === activeTab ? 'active' : ''}`}>
                            <div
                                key={index}
                                id={season.id}
                                className={`${currentTab == season.id ? 'mb-1 tab2Text theme-button-tab-color' : 'mb-1 tab2Text'} ${index == 0 ? 'me-2' : 'me-2'}`}
                                style={{ cursor: 'pointer' }}
                                disabled={currentTab == season.id}
                                onClick={() => handleTabClick({ a: season.id, b: season?.seasonId })}
                            > {season.seasonName}
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            }
            <div className="content mb-2">
                {seasons?.map((season, i) => (
                    <div key={i}>
                        {currentTab === season.id && <div >{(season.content)}</div>}
                    </div>
                ))}
            </div>
        </div>
    );
};
const mapStateToProps = (state, ownProps) => ({
    props: ownProps,
    seasonDetailsData: state.get_seasonDetails_Reducer.seasonDetailsData,
    isLoading: state.get_seasonDetails_Reducer.isLoading,
    error: state.get_seasonDetails_Reducer.error,
    settings: state.get_setting_Reducer.settings
});
export default connect(mapStateToProps)(EpisodesTabsection)
