import React from 'react'
import LazyLoadImage from '../../HomePage/HomeSwiperComponents/LazyLoadImage'
import { Link } from 'react-router-dom'
import MessageBox from '../../../components/Others/MessageBox'
import { connect } from 'react-redux'

const MusicCategoryCard = ({ store, languageDetailsData }) => {
    return (
        <div className='rounded-2 w-100 h-100  position-relative theme-bg-color-secondary '>
            <LazyLoadImage key={store?.name} src={store?.image_url} alt={store?.name} title={store?.name} imageSize="rounded-2  overflow-hidden  object-fit-contain" classNameOptional="rounded-2 " />
            <Link to={`/${languageDetailsData?.Language_Code}/music/genre/${store?.slug}`} className='theme-text-color position-absolute musicCategoryTitle '> <MessageBox text={store?.name} /></Link>
        </div>
    )
}
const mapStateToProps = state => ({
    languageDetailsData: state.get_getUserTranslateLanguage_Reducer.languageDetailsData
})

export default connect(mapStateToProps)(MusicCategoryCard)