import React from 'react'

const AccountParental = () => {
    return (
        <section>
            <h4 className='small-md-heading mb-4'>Parental Control</h4>
            <div className='d-flex align-items-center gap-3 mb-3'>
                <button type='button' className='px-5 py-3 rounded-2 small-lg-heading'>Kids</button>
                <p>U/A 7+ and Below</p>
            </div>
            <div className=''>
                <div className='d-flex align-items-center justify-content-between mb-3 theme-border-color border-2 pb-3 border-bottom'>
                    <div className='d-flex flex-column '>
                        <p className='small-sm-heading mb-2'>Language</p>
                        <span className='small-sm-heading opacity-75'>English</span>
                    </div>
                    <button type='button' className=' small-md-heading bg-transparent'>Change</button>
                </div>
                <div className='d-flex align-items-center justify-content-between mb-3 theme-border-color border-2 pb-3 border-bottom'>
                    <div className='d-flex flex-column '>
                        <p className='small-sm-heading mb-2'>Viewing Restrictions</p>
                        <span className='small-sm-heading opacity-75'>English</span>
                    </div>
                    <button type='button' className=' small-md-heading bg-transparent'>Change</button>
                </div>
                <div className='d-flex align-items-center justify-content-between mb-3 theme-border-color border-2 pb-3 border-bottom'>
                    <div className='d-flex flex-column '>
                        <p className='small-sm-heading mb-2'>Profile Lock</p>
                        <span className='small-sm-heading opacity-75'>Off</span>
                    </div>
                    <button type='button' className=' small-md-heading bg-transparent'>Change</button>
                </div>
                <div className='d-flex align-items-center justify-content-between mb-3 theme-border-color border-2 pb-3 border-bottom'>
                    <div className='d-flex flex-column '>
                        <p className='small-sm-heading mb-2'>Viewing Activity</p>
                    </div>
                    <button type='button' className=' small-md-heading bg-transparent'>View</button>
                </div>
            </div>
        </section>
    )
}

export default AccountParental