import React from 'react'
import { useTranslation } from 'react-i18next';
import { RiFacebookFill, RiLinkM, RiTwitterXFill, RiWhatsappLine } from 'react-icons/ri'
import { Link } from 'react-router-dom';
import { Bounce, toast } from 'react-toastify';

const SocialMediaShareBox = ({ URL }) => {
    const { t } = useTranslation();
    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(URL);
            toast.success(t('📝 Copied to clipboard!'), {
                hideProgressBar: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                transition: Bounce,
            });
        } catch (err) {
            console.error('Unable to copy to clipboard', err);
        }
    };
    return (
        <div className='shareBox p-2'>
            <span className='shareHeadingText'>{t("Share with")}</span>
            <div className='d-flex align-items-center gap-2'>
                <Link to={`https://twitter.com/intent/tweet?text=${encodeURIComponent(URL)}`} target="_blank">
                    <div className='shareIconBox'>
                        <RiTwitterXFill />
                        <span className='shareIconBoxText'>{t("Twitter")}</span>
                    </div>
                </Link>
                <Link to={`https://web.whatsapp.com/send?text=${encodeURIComponent(URL)}`} target="_blank">
                    <div className='shareIconBox'>
                        <RiWhatsappLine />
                        <span className='shareIconBoxText'>{t("Whatsapp")}</span>
                    </div>
                </Link>
                <Link to={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(URL)}`} target="_blank">
                    <div className='shareIconBox'>
                        <RiFacebookFill />
                        <span className='shareIconBoxText'>{t("Facebook")}</span>
                    </div>
                </Link>
                <div className='shareIconBox' onClick={copyToClipboard}>
                    <RiLinkM />
                    <span href='' className='shareIconBoxText'>{t("Copy")}</span>
                </div>
            </div>
        </div >
    )
}

export default SocialMediaShareBox