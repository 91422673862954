import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import "./EditManageVideoCategory.css";
import { toast } from "react-toastify";
 
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import loadings from "../../../components/Images/loading/loading.gif";
import ResponseLoader from "../../../components/Others/ResponseLoader";
import { useTranslation } from "react-i18next";

const EditManageVideoCategory = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [editUser, setEditUser] = useState({
    id: "",
    user_id: "",
    name: "",
    parent_id: "",
    image: "",
    order: "",
    slug: "",
    in_home: "",
    banner: "",
    banner_image: "",
    banner_image_url: "",
    in_menu: "",
    home_genre: "",
  });
  const [editname, setEditname] = useState({
    name: "",
  });

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  // let access_token, access_token_channel;

  // if (localStorage.getItem("username")) {
  //   access_token = localStorage.getItem("access_token");
  // } else {
  //   access_token_channel = localStorage.getItem("access_token_channel");
  // }

  // const headers = {
  //   "Content-Type": "application/json",
  //   // Authorization: "Bearer " + access_token_channel,
  //   Accept: "application/json",
  //   "Access-Control-Allow-Origin": "*",
  // };

  // if (access_token) {
  //   headers.Authorization = "Bearer " + access_token;
  // } else if (access_token_channel) {
  //   headers.Authorization = "Bearer " + access_token_channel;
  // }

  const access_token_channel = localStorage.getItem("access_token_channel");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token_channel,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);

  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);

  const handleFileChange = (event, fileInputRef, setSelectedFile) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDelete = (setSelectedFile1, setSelectedFile2) => {
    setSelectedFile1(null);
    if (fileInputRef1.current) {
      fileInputRef1.current.value = null;
    }
    setSelectedFile2(null);
    if (fileInputRef2.current) {
      fileInputRef2.current.value = null;
    }
  };

  useEffect(() => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/videos-category/edit/${id}`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.VideoCategory;
      setEditUser(res);
      setEditname(res);
      setLoading(false);
    };
    getUser();

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/videos-category/create`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        var result = response?.data?.Video_Category;
        setData(result);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, [id]);

  // const handleInput = (e) => {
  //   const { name, value } = e.target;
  //   setEditUser((prevEditUser) => ({
  //     ...prevEditUser,
  //     [name]: value,
  //   }));

  //   setValidationMessageslug("");
  //   setIsValidationHiddenslug(true);
  //   setValidationMessagetitle("");
  //   setIsValidationHiddentitle(true);

  //   setEditUser({ ...editUser, [e.target.name]: e.target.value });

  //   if (e.target.checked == true) {
  //     setEditUser({ ...editUser, [e.target.name]: e.target.value });
  //     setEditUser({ ...editUser, [e.target.id]: 1 });
  //     setEditUser({
  //       ...editUser,
  //       [e.target.name]: e.target.value,
  //       [e.target.name]: 1,
  //     });
  //   } else {
  //     setEditUser({ ...editUser, [e.target.name]: e.target.value });
  //     setEditUser({ ...editUser, [e.target.id]: 0 });
  //     setEditUser({ ...editUser, [e.target.name]: e.target.value });
  //   }
  // };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessagetitle("");
    setIsValidationHiddentitle(true);
    setEditUser({ ...editUser, [e.target.name]: e.target.value });

    // if (e.target.checked == true) {
    //   setEditUser({ ...editUser, [e.target.name]: e.target.value });
    //   setEditUser({ ...editUser, [e.target.id]: 1 });
    //   setEditUser({
    //     ...editUser,
    //     [e.target.name]: e.target.value,
    //     [e.target.name]: 1,
    //   });
    // } else {
    //   setEditUser({ ...editUser, [e.target.name]: e.target.value });
    //   setEditUser({ ...editUser, [e.target.id]: 0 });
    //   setEditUser({ ...editUser, [e.target.name]: e.target.value });
    // }
  };

  const handleInputenable = (e) => {
    if (e.target.checked == true) {
      setEditUser({ ...editUser, [e.target.name]: 1 });
    } else {
      setEditUser({ ...editUser, [e.target.name]: 0 });
    }
  };

  const [validationMessagetitle, setValidationMessagetitle] = useState("");
  const [isValidationHiddentitle, setIsValidationHiddentitle] = useState(true);

  const [validationMessageslug, setValidationMessageslug] = useState("");
  const [isValidationHiddenslug, setIsValidationHiddenslug] = useState(true);

  const titleInputRef = useRef(null);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const handleUpdate = async (e) => {
    // if (editUser?.name == "") {
    //   setValidationMessagetitle("Name cannot be empty.");
    //   setIsValidationHiddentitle(false);
    //   return;
    // }

    // if (editUser?.slug == "") {
    //   setValidationMessageslug("Slug cannot be empty.");
    //   setIsValidationHiddenslug(false);
    //   return;
    // }

    let focusInputRef = null;

    if (editUser?.name == "") {
      setValidationMessagetitle("Name cannot be empty*.");
      setIsValidationHiddentitle(false);
      focusInputRef = titleInputRef;
    }

    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }

    e.preventDefault();

    const editInputvalue = {
      id: editUser?.id,
      user_id: editUser?.user_id,
      name: editUser?.name,
      slug: editUser?.slug,
      in_home: editUser?.in_home,
      banner: editUser?.banner,
      in_menu: editUser?.in_menu,
      home_genre: editUser?.home_genre,
      parent_id: editUser?.parent_id,
      uploaded_by: editUser?.uploaded_by,
    };

    let firstApiStatus = false;
    let secondApiStatus = false;
    let resultapi = null;
    let resultapifalse = null;
    let resjson = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/videos-category/update/${id}`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(editInputvalue),
        }
      );

      resjson = await res.json();

      if (resjson.status == true) {
        firstApiStatus = true;

        resultapi = resjson;

        const formData = new FormData();
        formData.append("VideoCategory_id", id);
        if (selectedFile1) {
          formData.append("image", selectedFile1);
        }
        if (selectedFile2) {
          formData.append("banner_image", selectedFile2);
        }

        await axios.post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/videos-category/image`,
          formData,
          { headers: headers }
        );
        secondApiStatus = true;
      } else if (resjson.status == false) {
        // Handle the case when the first API response status is false
        resultapifalse = resjson;
      }
    } catch (error) {
      console.error("Error:", error);
    }

    if (firstApiStatus && secondApiStatus) {
      setValidationMessagetitle("");
      setValidationMessageslug("");
      // props.setApiresponsealert(resultapi);
      // props.setShowalert(true);
      toast.success(t(resultapi?.message));
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else if (resultapifalse) {
      // Check if resultapifalse is defined and not null
      // props.setApiresponsealert(resultapifalse);
      // props.setShowalert(true);
      toast.error(t(resultapifalse?.message));
      setProcessing(false);
      setShowOverlay(false);
      setIsValidationHiddentitle(false);
      setValidationMessageslug(false);
    }
  };

  return (
    <>
      <div className="channel_video_category">
        {loading ? (
          <>
            <div className="overlay-container">
              <div className="overlay-icon">
                <img src={loadings} width={100} alt="Animated GIF" />
                Loading...
              </div>
            </div>
            <section>
              <div className=" m-0 p-0">
                <section className="container-fluid">
                  <div className="col-lg-12 row py-3">
                    <div className="col-6 col-sm-6 col-lg-6">
                      <h3 className=" theme-text-color">Edit Category</h3>
                    </div>
                    <div className="col-6 col-sm-6 col-lg-6">
                      <div className="text-end">
                        <button
                          onClick={handleUpdate}
                          className="btn btn-primary"
                        >
                          Update Category
                        </button>
                      </div>
                    </div>
                  </div>

                  <div>{showOverlay && <ResponseLoader />}</div>

                  <div className="row col-lg-12 ">
                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 ">
                      <div className="card p-3 global-shadow theme-bg-color">
                        <h5>{editname?.name}</h5>
                        <hr className=" theme-text-color"></hr>
                        <div className=" text-start">
                          <label className="m-0 theme-text-color">
                            Name<span className="mandatory">*</span>
                          </label>
                          <div className="mt-2">
                            <input
                              type="text"
                              className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                              id="name"
                              name="name"
                              placeholder="Enter Name"
                              onChange={handleInput}
                              value={editUser?.name}
                              ref={titleInputRef}
                            />
                          </div>
                          <span className="errorred">
                            {!isValidationHiddentitle && (
                              <p>{validationMessagetitle}</p>
                            )}
                          </span>
                        </div>
                        <div className="mt-2 text-start">
                          <label className="m-0 theme-text-color">Slug</label>

                          <div className="mt-2">
                            <input
                              type="text"
                              className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                              id="slug"
                              name="slug"
                              placeholder="Enter Slug"
                              onChange={handleInput}
                              value={editUser?.slug}
                            />
                          </div>
                        </div>

                        <div className="row mt-2 text-start">
                          <div className="col-12">
                            <label className="m-0 theme-text-color">
                              Category
                            </label>

                            <div className="mt-2">
                              <select
                                className="form-select form-select-full-border theme-bg-color theme-text-color"
                                name="parent_id"
                                id="parent_id"
                                value={editUser?.parent_id}
                                onChange={handleInput}
                              >
                                <option value="">Choose a Category</option>
                                {data?.map((item) => (
                                  <option value={item?.id}>{item?.name}</option>
                                ))}
                              </select>
                            </div>

                            {/* <div className="mt-2">
                    <select
                      className="form-select"
                      name="parent_id"
                      id="parent_id"
                      onChange={handleInput}
                      value={editUser?.parent_id}
                    >
                      {data?.map((item) => (
                        <option value={item?.parent_id}>{item?.name}</option>
                      ))}
                    </select>
                  </div> */}
                          </div>
                        </div>
                      </div>

                      <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                        <h5 className=" theme-text-color">Status Settings</h5>
                        <hr className=" theme-text-color"></hr>

                        <div className="col-sm-12 row">
                          <div className="col-9 col-sm-9 col-lg-9">
                            <p className="p2 theme-text-color">
                              Display In Menu
                            </p>
                          </div>
                          <div className="col-3 col-sm-3 col-lg-3">
                            <label className="switch">
                              <input
                                name="in_menu"
                                id="in_menu"
                                onChange={handleInputenable}
                                defaultChecked={
                                  editUser?.in_menu == 1 ? true : false
                                }
                                checked={editUser?.in_menu == 1 ? true : false}
                                className="rs-input"
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="in_menu"
                                id="in_menu"
                                onChange={handleInputenable}
                                value={editUser?.in_menu == 1 ? "1" : "0"}
                              ></span>
                            </label>
                          </div>
                        </div>
                        <div className="col-sm-12 row mt-2">
                          <div className="col-9 col-sm-9 col-lg-9">
                            <p className="p2 theme-text-color">
                              Display In Home
                            </p>
                          </div>
                          <div className="col-3 col-sm-3 col-lg-3">
                            <label className="switch">
                              <input
                                name="in_home"
                                id="in_home"
                                onChange={handleInputenable}
                                className="r-input"
                                defaultChecked={
                                  editUser?.in_home == 1 ? true : false
                                }
                                checked={editUser?.in_home == 1 ? true : false}
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="in_home"
                                id="in_home"
                                onChange={handleInputenable}
                                value={editUser?.in_home == 1 ? "1" : "0"}
                              ></span>
                            </label>
                          </div>
                        </div>
                        <div className="col-sm-12 row mt-2">
                          <div className="col-9 col-sm-9 col-lg-9">
                            <p className="p2 theme-text-color">
                              Display In Home Banner
                            </p>
                          </div>
                          <div className="col-3 col-sm-3 col-lg-3">
                            <label className="switch">
                              <input
                                name="banner"
                                id="banner"
                                onChange={handleInputenable}
                                className="r-input"
                                defaultChecked={
                                  editUser?.banner == 1 ? true : false
                                }
                                checked={editUser?.banner == 1 ? true : false}
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="banner"
                                id="banner"
                                onChange={handleInputenable}
                                value={editUser?.banner == 1 ? "1" : "0"}
                              ></span>
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="text-start mb-3">
                        <button
                          onClick={handleUpdate}
                          className="btn btn-primary"
                        >
                          Update Category
                        </button>
                      </div>
                    </div>

                    {/* Second Part of Section  */}

                    <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                      <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                        <h5 className=" theme-text-color">Thumbnails</h5>
                        <hr className=" theme-text-color"></hr>

                        <div className=" text-start mt-2">
                          <label className="m-0 theme-text-color">Image</label>

                          {/* <div>
                  <img src={editUser?.image_url} width="150" height="100" />
                </div> */}

                          <div>
                            {loading ? (
                              <img
                                src={loadings}
                                className="thumbnails-images"
                              />
                            ) : (
                              <img
                                src={editUser?.image_url}
                                alt="Image"
                                className="thumbnails-images"
                              />
                            )}
                          </div>

                          <div className="mt-2">
                            <div className="col-lg-12 row">
                              <div className="col-6 col-sm-6 col-lg-6">
                                <div
                                  className="imagedrop"
                                  onClick={() => fileInputRef1.current.click()}
                                >
                                  <input
                                    type="file"
                                    ref={fileInputRef1}
                                    onChange={(event) =>
                                      handleFileChange(
                                        event,
                                        fileInputRef1,
                                        setSelectedFile1
                                      )
                                    }
                                    accept="image/*"
                                    style={{ display: "none" }}
                                  />
                                  <label className=" theme-text-color">
                                    <CameraRetroIcon className=" theme-text-color" />
                                  </label>
                                </div>
                              </div>
                              <div className="col-6 col-sm-6 col-lg-6">
                                <div>
                                  {selectedFile1 && (
                                    <div className="imagedropcopy text-end">
                                      <div className="col-lg-12 row p-0 m-0">
                                        <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                          <img
                                            src={URL?.createObjectURL(
                                              selectedFile1
                                            )}
                                            alt="Uploaded"
                                            className="thumbnails-upload-images"
                                          />
                                        </div>
                                        <div
                                          className="col-sm-2 col-lg-2 p-0 m-0"
                                          style={{ alignSelf: "center" }}
                                        >
                                          <button
                                            onClick={() =>
                                              handleDelete(setSelectedFile1)
                                            }
                                            type="button"
                                            className="btn-close theme-text-color"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                          ></button>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className=" text-start mt-2">
                          <label className="m-0  theme-text-color">
                            Banner
                          </label>

                          {/* <div>
                  <img
                    src={editUser?.banner_image_url}
                    width="150"
                    height="100"
                  />
                </div> */}

                          <div>
                            {loading ? (
                              <img
                                src={loadings}
                                className="thumbnails-images"
                              />
                            ) : (
                              <img
                                src={editUser?.banner_image_url}
                                alt="Image"
                                className="thumbnails-images"
                              />
                            )}
                          </div>

                          <div className="mt-2">
                            <div className="col-lg-12 row">
                              <div className="col-6 col-sm-6 col-lg-6">
                                <div
                                  className="imagedrop"
                                  onClick={() => fileInputRef2.current.click()}
                                >
                                  <input
                                    type="file"
                                    ref={fileInputRef2}
                                    onChange={(event) =>
                                      handleFileChange(
                                        event,
                                        fileInputRef2,
                                        setSelectedFile2
                                      )
                                    }
                                    accept="image/*"
                                    style={{ display: "none" }}
                                  />
                                  <label
                                    className=" theme-text-color"
                                    onClick={() =>
                                      fileInputRef2.current.click()
                                    }
                                  >
                                    <CameraRetroIcon className=" theme-text-color" />
                                  </label>
                                </div>
                              </div>
                              <div className="col-6 col-sm-6 col-lg-6">
                                <div>
                                  {selectedFile2 && (
                                    <div className="imagedropcopy text-end">
                                      <div className="col-lg-12 row p-0 m-0">
                                        <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                          <img
                                            src={URL?.createObjectURL(
                                              selectedFile2
                                            )}
                                            alt="Uploaded"
                                            className="thumbnails-upload-images"
                                          />
                                        </div>
                                        <div
                                          className="col-sm-2 col-lg-2 p-0 m-0"
                                          style={{ alignSelf: "center" }}
                                        >
                                          <button
                                            onClick={() =>
                                              handleDelete(setSelectedFile2)
                                            }
                                            type="button"
                                            className="btn-close  theme-text-color"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                          ></button>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </section>
          </>
        ) : (
          <section>
            <div className=" m-0 p-0">
              <section className="container-fluid  theme-bg-color-secondary ">
                <div className="col-lg-12 row py-3">
                  <div className="col-6 col-sm-6 col-lg-6">
                    <h3 className=" theme-text-color">Edit Category</h3>
                  </div>
                  <div className="col-6 col-sm-6 col-lg-6">
                    <div className="text-end">
                      <button
                        onClick={handleUpdate}
                        className="btn btn-primary"
                      >
                        Update Category
                      </button>
                    </div>
                  </div>
                </div>

                <div>{showOverlay && <ResponseLoader />}</div>

                <div className="row col-lg-12 ">
                  <div className="col-6 col-sm-6 col-md-6 col-lg-6 ">
                    <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                      <h5 className=" theme-text-color">{editname?.name}</h5>
                      <hr className=" theme-text-color"></hr>
                      <div className=" text-start">
                        <label className="m-0 theme-text-color">
                          Name<span className="mandatory">*</span>
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                            id="name"
                            name="name"
                            placeholder="Enter Name"
                            onChange={handleInput}
                            value={editUser?.name}
                            ref={titleInputRef}
                          />
                        </div>
                        <span className="errorred">
                          {!isValidationHiddentitle && (
                            <p>{validationMessagetitle}</p>
                          )}
                        </span>
                      </div>
                      <div className="mt-2 text-start">
                        <label className="m-0 theme-text-color">Slug</label>

                        <div className="mt-2">
                          <input
                            type="text"
                            className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                            id="slug"
                            name="slug"
                            placeholder="Enter Slug"
                            onChange={handleInput}
                            value={editUser?.slug}
                          />
                        </div>
                      </div>

                      <div className="row mt-2 text-start">
                        <div className="col-12">
                          <label className="m-0 theme-text-color">
                            Category
                          </label>

                          <div className="mt-2">
                            <select
                              className="form-select   theme-bg-color-secondary  theme-text-color border-0"
                              name="parent_id"
                              id="parent_id"
                              value={editUser?.parent_id}
                              onChange={handleInput}
                            >
                              <option value="">Choose a Category</option>
                              {data?.map((item) => (
                                <option value={item?.id}>{item?.name}</option>
                              ))}
                            </select>
                          </div>

                          {/* <div className="mt-2">
                    <select
                      className="form-select"
                      name="parent_id"
                      id="parent_id"
                      onChange={handleInput}
                      value={editUser?.parent_id}
                    >
                      {data?.map((item) => (
                        <option value={item?.parent_id}>{item?.name}</option>
                      ))}
                    </select>
                  </div> */}
                        </div>
                      </div>
                    </div>

                    <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                      <h5 className=" theme-text-color">Status Settings</h5>
                      <hr className=" theme-text-color"></hr>

                      <div className="col-sm-12 row">
                        <div className="col-9 col-sm-9 col-lg-9">
                          <p className="p2 theme-text-color">Display In Menu</p>
                        </div>
                        <div className="col-3 col-sm-3 col-lg-3">
                          <label className="switch">
                            <input
                              name="in_menu"
                              id="in_menu"
                              onChange={handleInputenable}
                              defaultChecked={
                                editUser?.in_menu == 1 ? true : false
                              }
                              checked={editUser?.in_menu == 1 ? true : false}
                              className="rs-input"
                              type="checkbox"
                            />
                            <span
                              className="slider round"
                              name="in_menu"
                              id="in_menu"
                              onChange={handleInputenable}
                              value={editUser?.in_menu == 1 ? "1" : "0"}
                            ></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-12 row mt-2">
                        <div className="col-9 col-sm-9 col-lg-9">
                          <p className="p2 theme-text-color">Display In Home</p>
                        </div>
                        <div className="col-3 col-sm-3 col-lg-3">
                          <label className="switch">
                            <input
                              name="in_home"
                              id="in_home"
                              onChange={handleInputenable}
                              className="r-input"
                              defaultChecked={
                                editUser?.in_home == 1 ? true : false
                              }
                              checked={editUser?.in_home == 1 ? true : false}
                              type="checkbox"
                            />
                            <span
                              className="slider round"
                              name="in_home"
                              id="in_home"
                              onChange={handleInputenable}
                              value={editUser?.in_home == 1 ? "1" : "0"}
                            ></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-12 row mt-2">
                        <div className="col-9 col-sm-9 col-lg-9">
                          <p className="p2 theme-text-color">
                            Display In Home Banner
                          </p>
                        </div>
                        <div className="col-3 col-sm-3 col-lg-3">
                          <label className="switch">
                            <input
                              name="banner"
                              id="banner"
                              onChange={handleInputenable}
                              className="r-input"
                              defaultChecked={
                                editUser?.banner == 1 ? true : false
                              }
                              checked={editUser?.banner == 1 ? true : false}
                              type="checkbox"
                            />
                            <span
                              className="slider round"
                              name="banner"
                              id="banner"
                              onChange={handleInputenable}
                              value={editUser?.banner == 1 ? "1" : "0"}
                            ></span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="text-start mb-3">
                      <button
                        onClick={handleUpdate}
                        className="btn btn-primary"
                      >
                        Update Category
                      </button>
                    </div>
                  </div>

                  {/* Second Part of Section  */}

                  <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                    <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                      <h5 className=" theme-text-color">Thumbnails</h5>
                      <hr className=" theme-text-color"></hr>

                      <div className=" text-start mt-2">
                        <label className="m-0 theme-text-color">Image</label>

                        {/* <div>
                  <img src={editUser?.image_url} width="150" height="100" />
                </div> */}

                        <div>
                          {loading ? (
                            <img src={loadings} className="thumbnails-images" />
                          ) : (
                            <img
                              src={editUser?.image_url}
                              alt="Image"
                              className="thumbnails-images"
                            />
                          )}
                        </div>

                        <div className="mt-2">
                          <div className="col-lg-12 row">
                            <div className="col-6 col-sm-6 col-lg-6">
                              <div
                                className="imagedrop"
                                onClick={() => fileInputRef1.current.click()}
                              >
                                <input
                                  type="file"
                                  ref={fileInputRef1}
                                  onChange={(event) =>
                                    handleFileChange(
                                      event,
                                      fileInputRef1,
                                      setSelectedFile1
                                    )
                                  }
                                  accept="image/*"
                                  style={{ display: "none" }}
                                />
                                <label className=" theme-text-color">
                                  <CameraRetroIcon className=" theme-text-color" />
                                </label>
                              </div>
                            </div>
                            <div className="col-6 col-sm-6 col-lg-6">
                              <div>
                                {selectedFile1 && (
                                  <div className="imagedropcopy text-end">
                                    <div className="col-lg-12 row p-0 m-0">
                                      <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                        <img
                                          src={URL?.createObjectURL(
                                            selectedFile1
                                          )}
                                          alt="Uploaded"
                                          className="thumbnails-upload-images"
                                        />
                                      </div>
                                      <div
                                        className="col-sm-2 col-lg-2 p-0 m-0"
                                        style={{ alignSelf: "center" }}
                                      >
                                        <button
                                          onClick={() =>
                                            handleDelete(setSelectedFile1)
                                          }
                                          type="button"
                                          className="btn-close theme-text-color"
                                          data-bs-dismiss="modal"
                                          aria-label="Close"
                                        ></button>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className=" text-start mt-2">
                        <label className="m-0 theme-text-color">Banner</label>

                        {/* <div>
                  <img
                    src={editUser?.banner_image_url}
                    width="150"
                    height="100"
                  />
                </div> */}

                        <div>
                          {loading ? (
                            <img src={loadings} className="thumbnails-images" />
                          ) : (
                            <img
                              src={editUser?.banner_image_url}
                              alt="Image"
                              className="thumbnails-images"
                            />
                          )}
                        </div>

                        <div className="mt-2">
                          <div className="col-lg-12 row">
                            <div className="col-6 col-sm-6 col-lg-6">
                              <div
                                className="imagedrop"
                                onClick={() => fileInputRef2.current.click()}
                              >
                                <input
                                  type="file"
                                  ref={fileInputRef2}
                                  onChange={(event) =>
                                    handleFileChange(
                                      event,
                                      fileInputRef2,
                                      setSelectedFile2
                                    )
                                  }
                                  accept="image/*"
                                  style={{ display: "none" }}
                                />
                                <label
                                  className=" theme-text-color"
                                  onClick={() => fileInputRef2.current.click()}
                                >
                                  <CameraRetroIcon className=" theme-text-color" />
                                </label>
                              </div>
                            </div>
                            <div className="col-6 col-sm-6 col-lg-6">
                              <div>
                                {selectedFile2 && (
                                  <div className="imagedropcopy text-end">
                                    <div className="col-lg-12 row p-0 m-0">
                                      <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                        <img
                                          src={URL?.createObjectURL(
                                            selectedFile2
                                          )}
                                          alt="Uploaded"
                                          className="thumbnails-upload-images"
                                        />
                                      </div>
                                      <div
                                        className="col-sm-2 col-lg-2 p-0 m-0"
                                        style={{ alignSelf: "center" }}
                                      >
                                        <button
                                          onClick={() =>
                                            handleDelete(setSelectedFile2)
                                          }
                                          type="button"
                                          className="btn-close theme-text-color"
                                          data-bs-dismiss="modal"
                                          aria-label="Close"
                                        ></button>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </section>
        )}
      </div>
    </>
  );
};

export default EditManageVideoCategory;
