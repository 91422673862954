import React from 'react'

const AccountBilling = () => {
    return (
        <section>
            <h4 className='small-md-heading mb-4'>Membership & Billing</h4>
            <div className=''>
                <div className='d-flex align-items-md-center justify-content-between flex-wrap mb-3'>
                    <div className='d-flex opacity-75'>
                        <p className='small-sm-heading'>Phone number : </p>
                        <span className='small-sm-heading'>97827359</span>
                    </div>
                    <button type='button' className='p-0 small-md-heading bg-transparent'>Change number</button>
                </div>
                <div className='d-flex align-items-md-center justify-content-between flex-wrap mb-3'>
                    <div className='d-flex opacity-75'>
                        <p className='small-sm-heading'>Emaill ID : </p>
                        <span className='small-sm-heading'>mohgkajslkja@gmail.com</span>
                    </div>
                    <button type='button' className='p-0 small-md-heading bg-transparent'>Change Email</button>
                </div>
                <div className='d-flex align-items-md-center justify-content-between flex-wrap mb-3 pb-4 border-2 border-bottom theme-border-color'>
                    <div className='d-flex opacity-75  '>
                        <p className='small-sm-heading'>Password : </p>
                        <span className='small-sm-heading'>**************</span>
                    </div>
                    <button type='button' className='p-0 small-md-heading bg-transparent '>Change Password</button>
                </div>
                <div className='d-flex align-items-center justify-content-between flex-wrap mb-3'>
                    <p className='small-sm-heading opacity-75'>mohgkajslkja@icici</p>
                    <button type='button' className=' p-0 small-md-heading bg-transparent'>Manage Payment Method</button>
                </div>
                <div className='d-flex align-items-center justify-content-between flex-wrap mb-3 pb-4'>
                    <p className='small-sm-heading opacity-75'>Your next billing date is 1 May 2024</p>
                    <button type='button' className='p-0 small-md-heading bg-transparent '>Billing Detiails</button>
                </div>
            </div>
        </section>
    )
}

export default AccountBilling