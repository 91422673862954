import { useEffect } from 'react'
import { connect } from 'react-redux';

const MetaAppendScript = ({ meta_url, meta_title, meta_description, meta_image, title, headerSetting }) => {
    useEffect(() => {
        document.querySelector('meta[property="og:url"]').setAttribute('content', meta_url);
        document.querySelector('meta[property="og:title"]').setAttribute('content', meta_title);
        document.querySelector('meta[property="og:description"]').setAttribute('content', meta_description);
        document.querySelector('meta[property="og:image"]').setAttribute('content', meta_image);
        document.querySelector('link[rel="canonical"]').setAttribute('href', window.location.pathname);
        var existingFavicon = document.querySelector('link[rel="icon"]');
        existingFavicon.setAttribute('href', headerSetting?.favicon_url);
        existingFavicon.setAttribute('type', 'image/x-icon');
        existingFavicon.setAttribute('sizes', `${Number(headerSetting?.logo_height)}x${Number(headerSetting?.logo_width)}`);
        document.querySelector('meta[name="description"]').setAttribute('content', meta_description);
        document.title = title;
    }, [meta_url, meta_title, meta_description, meta_image, title]);
    return null
}

const mapStateToProps = state => ({
    meta_url: state.get_Meta_Data.meta_url,
    meta_title: state.get_Meta_Data.meta_title,
    meta_description: state.get_Meta_Data.meta_description,
    meta_image: state.get_Meta_Data.meta_image,
    title: state.get_Meta_Data.title,
    isLoading: state.get_Meta_Data.isLoading,
    error: state.get_Meta_Data.error,
    headerSetting: state.get_headerIndex_Reducer.headerSetting
});
export default connect(mapStateToProps)(MetaAppendScript)