import React from 'react'
import AdminCustomButton from './AdminCustomButton'
import MessageBox from '../MessageBox'

const AdminSaveHeader = ({ heading, saveText, saveMethod, className }) => {
    return (
        <div className={`d-flex align-items-center flex-wrap px-md-0 px-3 pb-3 ${className}`}>
            <div className="col-sm-6 col-12 ">
                <h4 className="admin-title mb-0  theme-text-color text-md-start "> <MessageBox text={heading} /></h4>
            </div>
            <div className="col-sm-6 col-12 d-flex align-items-center justify-content-end mt-md-0 mt-3">
                <AdminCustomButton saveMethod={saveMethod} saveText={saveText} />
            </div>
        </div>
    )
}

export default AdminSaveHeader