import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { MdDelete } from "react-icons/md";
import { FaCirclePlus } from "react-icons/fa6";
import "./Contentallaudiocategory.css";
import { toast } from "react-toastify";
 
import { FaEllipsisVertical } from "react-icons/fa6";
import deleteitem from "../../../components/Images/deleteitem.svg";
import edititem from "../../../components/Images/edititem.svg";
import loadings from "../../../components/Images/loading/loading.gif";
import ResponseLoader from "../../../components/Others/ResponseLoader";

function Contentallaudiocategory() {
  const [getAudioCategory, setAudioCategory] = useState([]);
  const [itemToDeleteAudioCategory, setItemToDeleteAudioCategory] = useState(
    []
  );
  const [selectAllAudioCategory, setSelectAllAudioCategory] = useState(false);
  const [modalCloseAudioCategory, setModalCloseAudioCategory] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [loading, setLoading] = useState(true);

  const access_token_cpp = localStorage.getItem("access_token_cpp");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token_cpp,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(() => {
    fetchDataaudiofile();
  }, []);

  const fetchDataaudiofile = async () => {
    try {
      const formData = new FormData();
      formData.append("Content_Partner_id", 1);
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/Audio-Genre/List`,
        formData,
        { headers: headers }
      );
      setAudioCategory(response?.data?.Audio_Genre?.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const reorderedItems = Array?.from(getAudioCategory);
    const [movedItem] = reorderedItems?.splice(result?.source?.index, 1);
    reorderedItems.splice(result?.destination?.index, 0, movedItem);
    setAudioCategory(reorderedItems);
    saveShuffleOrder(reorderedItems);
  };

  const saveShuffleOrder = async (updatedItems) => {
    setProcessing(true);
    setShowOverlay(true);

    if (!updatedItems || updatedItems?.length == 0) {
      console.error("Menu items are empty");
      return;
    }

    const positions = updatedItems?.map((menuItem) => menuItem?.id);
    const ids = updatedItems?.map((_, index) => index + 1);

    const requestData = {
      id: JSON.stringify(positions),
      position: JSON.stringify(ids),
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/Audio-Genre/Order-Audio-Genre`,
        requestData,
        { headers: headers }
      );
      const result = response.data;
      if (result?.status == true) {
        toast.success(result?.message);
        setProcessing(false);
        setShowOverlay(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);
      var errorMessage = err.response.data.message;
      toast.error({ status: false, message: errorMessage });
      setProcessing(false);
      setShowOverlay(false);
    }
  };

  const handleSelectAllaudiofile = () => {
    setSelectAllAudioCategory(!selectAllAudioCategory);
    setItemToDeleteAudioCategory(
      selectAllAudioCategory ? [] : getAudioCategory?.map((item) => item?.id)
    );
  };

  const handleMultiSelectaudiofile = (itemId) => {
    if (itemToDeleteAudioCategory?.includes(itemId)) {
      setItemToDeleteAudioCategory(
        itemToDeleteAudioCategory?.filter((id) => id !== itemId)
      );
    } else {
      setItemToDeleteAudioCategory([...itemToDeleteAudioCategory, itemId]);
    }
  };

  const singleDeleteAudioCategory = (id) => {
    setItemToDeleteAudioCategory([id]);
    setModalCloseAudioCategory(true);
    setSelectAllAudioCategory(false);
  };

  const closeAudioCategoryModal = () => {
    setModalCloseAudioCategory(!modalCloseAudioCategory);
  };

  const openModalMultiDeleteAudioCategory = () => {
    setModalCloseAudioCategory(true);
  };

  const handleDeleteAudioCategoryData = () => {
    closeAudioCategoryModal();
    audioCategoryDeleteOperation();
  };

  const audioCategoryDeleteOperation = async () => {
    setProcessing(true);
    setShowOverlay(true);
    const formData = new FormData();
    formData.append("id", "[" + itemToDeleteAudioCategory + "]");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/Audio-Genre/Multi-delete`,
        formData,
        {
          headers: headers,
        }
      );
      const result = response.data;
      if (result.status == true) {
        toast.success(result?.message);
        setProcessing(false);
        setShowOverlay(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);
      var errorMessage = err.response.data.message;
      toast.error({ status: false, message: errorMessage });
      setProcessing(false);
      setShowOverlay(false);
    }
  };

  return (
    <>
      <div className="cpp_audio_category">
        <div
          className={`modal  packageModel ${
            modalCloseAudioCategory ? "show" : ""
          }`}
          tabIndex="-1"
          style={{ display: modalCloseAudioCategory ? "block" : "none" }}
        >
          <div className="modal-dialog ">
            <div className="modal-content theme-bg-color global-shadow">
              <div className="modal-header">
                <h5 className="modal-title theme-text-color">
                  Confirm Deletion
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={closeAudioCategoryModal}
                ></button>
              </div>
              <div className="modal-body">
                {itemToDeleteAudioCategory?.length == 1 ? (
                  <p className="fs-6 theme-text-color">
                    Are you sure you want to delete this audio category?
                  </p>
                ) : (
                  <p className="fs-6 theme-text-color">
                    Are you sure you want to delete the selected audio category
                    ?
                  </p>
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={closeAudioCategoryModal}
                >
                  Close
                </button>

                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={handleDeleteAudioCategoryData}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>

        <div>
        {showOverlay && (<ResponseLoader />)}
        </div>
        <section className="section container-fluid pt-3  theme-bg-color-secondary  paddingbottomm">
          <div id="content-page" className="content-page">
            <div className="row pb-3 admin-section-title">
              <div className="col-lg-8 col-md-8 col-sm-8 col-8">
                <h4 className=" theme-text-color">
                  Audio Categories{" "}
                  <span className="fs-6">
                    (Display all {getAudioCategory?.length} Audio Category)
                  </span>
                </h4>
              </div>

              <div
                className="col-lg-4 col-md-4 col-sm-4 col-4 mt-3 mt-md-0"
                align="right"
              >
                {itemToDeleteAudioCategory?.length > 0 && (
                  <button
                    type="button"
                    className="btn btn-danger d-inline-flex  fw-bolder "
                    onClick={openModalMultiDeleteAudioCategory}
                  >
                    <MdDelete className="exportIcon " />
                    Delete
                  </button>
                )}

                <Link to="/cpp/add-audio-categories">
                  <button type="button" className="btn btn-primary fw-bolder">
                    <FaCirclePlus /> Add Categories
                  </button>
                </Link>
              </div>
            </div>
            <div className="rounded-3 border-0 mt-4 theme-bg-color ">
              <div className="bootstrapTable marginbbb p-3">
                <div className="col-12 p-0">
                  <div className="row m-0 p-0">
                    <div className="col-lg-2 col-md-2 col-sm-2 col-2 data">
                      {" "}
                      <label>
                        <input
                          type="checkbox"
                          checked={selectAllAudioCategory}
                          onChange={handleSelectAllaudiofile}
                        />
                      </label>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-3 data">
                      {" "}
                      <label className="theme-text-color  fw-bold fs-6">
                        Audio Category
                      </label>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-3 data">
                      {" "}
                      <label className="theme-text-color  fw-bold fs-6">
                        Uploaded by
                      </label>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-3 data">
                      {" "}
                      <label className="theme-text-color  fw-bold fs-6">
                        Actions
                      </label>
                    </div>
                  </div>
                  <hr className=" theme-text-color"></hr>
                  <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable
                      droppableId="menu-items"
                      className="theme-bg-color"
                    >
                      {(provided) => (
                        <ul
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          className=" theme-bg-color  p-1 m-0"
                        >
                          {loading ? (
                            <div className="noRecord">
                              {" "}
                              <img
                                src={loadings}
                                width={30}
                                height={30}
                                alt="Animated GIF"
                              />
                              Loading...
                            </div>
                          ) : getAudioCategory?.length <= 0 ? (
                            <div className="noRecord">
                              {" "}
                              <p className="theme-text-color">
                                No Audio Category Available !!!{" "}
                              </p>
                            </div>
                          ) : (
                            getAudioCategory?.map((item, index) => (
                              <Draggable
                                key={item?.id.toString()}
                                draggableId={item?.id.toString()}
                                index={index}
                              >
                                {(provided) => (
                                  <li
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className={` theme-bg-color theme-text-color bg-white orderborder ${
                                      item.order
                                    } index ${index + 1}w-100`}
                                  >
                                    <div className="row m-0 p-0 align-items-center">
                                      <div className="col-lg-2 col-md-2 col-sm-2 col-2 data_th">
                                        <input
                                          type="checkbox"
                                          checked={itemToDeleteAudioCategory?.includes(
                                            item.id
                                          )}
                                          onChange={() =>
                                            handleMultiSelectaudiofile(item?.id)
                                          }
                                        />
                                      </div>
                                      <div className="col-lg-3 col-md-3 col-sm-3 col-3 data_th">
                                        <div className="d-flex justify-content-between align-items-center">
                                          <img
                                            src={item?.image_url}
                                            className="profile"
                                          />
                                          <div className="rightVideoTitle">
                                            <p className=" theme-text-color ">
                                              {item?.name}
                                            </p>
                                            <span className="dateText theme-text-color ">
                                              {item?.upload_on}
                                            </span>
                                          </div>
                                        </div>
                                      </div>{" "}
                                      <div className="col-lg-3 col-md-3 col-sm-3 col-3 data_th">
                                        <div className=" theme-text-color ">
                                          {" "}
                                          {item?.uploaded_by == "1"
                                            ? "Admin"
                                            : item?.uploaded_by == "5"
                                            ? "Content Partner"
                                            : item?.uploaded_by == "4"
                                            ? "Channel Partner"
                                            : ""}
                                        </div>
                                      </div>
                                      <div className="col-lg-3 col-md-3 col-sm-3 col-3 data_th">
                                        <div className="editdropdown ">
                                          <span className="editdropdown-button">
                                            <span className="theme-text-color">
                                              <FaEllipsisVertical className="theme-text-color" />
                                            </span>
                                          </span>
                                          <div className="editdropdown-menu ">
                                            <Link
                                              to={
                                                "/cpp/edit-audio-categories/" +
                                                item?.id
                                              }
                                              className=" theme-bg-color"
                                            >
                                              <span>
                                                <img
                                                  src={edititem}
                                                  alt="flogo"
                                                  width={20}
                                                  height={20}
                                                />
                                                <span className="ms-2  theme-text-color ">
                                                  Edit Categories
                                                </span>
                                              </span>
                                            </Link>
                                            <div
                                              onClick={() =>
                                                singleDeleteAudioCategory(
                                                  item?.id
                                                )
                                              }
                                              className="commonActionPadding  theme-bg-color"
                                            >
                                              <img
                                                src={deleteitem}
                                                alt="flogo"
                                                width={20}
                                                height={20}
                                              />
                                              <span className="ms-2  theme-text-color ">
                                                Delete Categories
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <hr className=" theme-text-color p-0 m-0"></hr>
                                  </li>
                                )}
                              </Draggable>
                            ))
                          )}
                          {provided.placeholder}
                        </ul>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Contentallaudiocategory;
