// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --large-lg-heading: 58px;
    --large-md-heading: 52px;
    --large-sm-heading: 48px;
    --mid-lg-heading: 42px;
    --mid-md-heading: 36px;
    --mid-sm-heading: 30px;
    --small-lg-heading: 24px;
    --small-md-heading: 18px;
    --small-sm-heading: 14px;
}`, "",{"version":3,"sources":["webpack://./src/Styles/root.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,wBAAwB;IACxB,wBAAwB;IACxB,sBAAsB;IACtB,sBAAsB;IACtB,sBAAsB;IACtB,wBAAwB;IACxB,wBAAwB;IACxB,wBAAwB;AAC5B","sourcesContent":[":root {\n    --large-lg-heading: 58px;\n    --large-md-heading: 52px;\n    --large-sm-heading: 48px;\n    --mid-lg-heading: 42px;\n    --mid-md-heading: 36px;\n    --mid-sm-heading: 30px;\n    --small-lg-heading: 24px;\n    --small-md-heading: 18px;\n    --small-sm-heading: 14px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
