import React from 'react';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link, useParams } from 'react-router-dom';
import PriceLabel from './PriceLabel';
import { connect } from 'react-redux';
import CardWithHover from '../../Pages/HomePage/HomeSwiperComponents/Theme/CardWithHover';
import CardWithoutHover from '../../Pages/HomePage/HomeSwiperComponents/Theme/CardWithoutHover';

const MoreLike = ({ data, segMent, themeMode }) => {
    const { lang } = useParams();


    // const data = [
    //     {
    //         id: 1,
    //         image_url: MoreOne,
    //         Player_image_url: MoreOne
    //     },
    //     {
    //         id: 2,
    //         image_url: MoreTwo,
    //         Player_image_url: MoreTwo
    //     },
    //     {
    //         id: 3,
    //         image_url: MoreThree,
    //         Player_image_url: MoreThree
    //     },
    //     {
    //         id: 4,
    //         image_url: MoreFour,
    //         Player_image_url: MoreFour
    //     }];

    // const playerData = [
    //     {
    //       id: 1,
    //       image_url: MoreOne,
    //       Player_thumbnail: MoreOne,
    //       title: "A Debate on Korean Culture",
    //       description: "Gopinath mediates a discussion between the people who celebrate and follow Korean culture and those who oppose that influence. Don't forget to tune in.",
    //       duration: "42M",
    //       order: "S23 E350"
    //     },
    //     {
    //       id: 2,
    //       image_url: MoreTwo,
    //       Player_thumbnail: MoreTwo,
    //       title: "A Discussion on Working Women",
    //       description: "Gopinath mediates a discussion between the people who celebrate and follow Korean culture and those who oppose that influence. Don't forget to tune in.",
    //       duration: "42M",
    //       date: "30 Jun 2024",
    //       order: "S23 E349"
    //     },
    //     {
    //       id: 3,
    //       image_url: MoreThree,
    //       Player_thumbnail: MoreThree,
    //       title: "A Discussion on Working Women",
    //       description: "Gopinath mediates a discussion between the people who celebrate and follow Korean culture and those who oppose that influence. Don't forget to tune in.",
    //       duration: "42M",
    //       date: "30 Jun 2024",
    //       order: "S23 E348",
    //     },
    //     {
    //       id: 4,
    //       image_url: MoreFour,
    //       Player_thumbnail: MoreFour,
    //       title: "Maamiyar Vs Marumagal",
    //       description: "Gopinath mediates a discussion between the people who celebrate and follow Korean culture and those who oppose that influence. Don't forget to tune in.",
    //       duration: "42M",
    //       date: "30 Jun 2024",
    //       order: "S23 E347",
    //     }];


    const swiperConfig = {
        slidesPerView: 2,
        spaceBetween: 15,
        navigation: true,
        lazy: true,
        slidesPerGroup: 2,
        breakpoints: {
            376: { slidesPerView: 2, spaceBetween: 15, slidesPerGroup: 2 },
            576: { slidesPerView: 3, spaceBetween: 15, slidesPerGroup: 3 },
            768: { slidesPerView: 4, spaceBetween: 15, slidesPerGroup: 4 },
            1024: { slidesPerView: 5, spaceBetween: 15, slidesPerGroup: 5 },
            1200: { slidesPerView: 6, spaceBetween: 15, slidesPerGroup: 6 },
            1440: { slidesPerView: 6, spaceBetween: 15, slidesPerGroup: 6 },
        },
        modules: [Navigation],
        className: 'homePageSwiper'
    }

    return (
        <>
            <div className='pt-3 zIndex'>
                <Swiper {...swiperConfig}>
                    {data?.map((value, index) => (
                        <SwiperSlide key={value?.id} className='theme-bg-color-secondary'>
                            {themeMode?.hoverCard ? <CardWithHover value={value} segMent={segMent} lang={lang} hoverClass="homeSwiperContainer" /> : <CardWithoutHover value={value} segMent={segMent} lang={lang} />}
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>

        </>
    )
}
const mapStateToProps = (state) => ({
    themeMode: state.get_headerIndex_Reducer.themeMode,
});
export default connect(mapStateToProps)(MoreLike);