import React, { Suspense, useEffect, useState } from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import Error404 from "../Error404/Error404";
import ContentDashboard from "../Contentportal/Content_Dashboard/ContentDashboard";
import ContentSignin from "../Contentportal/Signin/ContentSignin";
import ContentCommonLayout from "../components/Layout/ContentCommonLayout";
import Contentallvideos from "../Contentportal/Content_All_Page/Content_Videos/Contentallvideos";
import Contentaddvideos from "../Contentportal/Content_All_Page/Content_Videos/Contentaddvideos";
import Contentvideocategory from "../Contentportal/Content_All_Page/Content_Videos/Contentvideocategory";
import Contentalllive from "../Contentportal/Content_All_Page/Content_Live/Contentalllive";
import Contentaaddlive from "../Contentportal/Content_All_Page/Content_Live/Contentaaddlive";
import Contentaddvideocategory from "../Contentportal/Content_All_Page/Content_Videos/Contentaddvideocategory";
import Contenteditvideocategory from "../Contentportal/Content_All_Page/Content_Videos/Contenteditvideocategory";
import Contenteditvideos from "../Contentportal/Content_All_Page/Content_Videos/Contenteditvideos";
import ContentEditLiveStream from "../Contentportal/Content_All_Page/Content_Live/ContentEditLiveStream";
import Contentaddlivecategory from "../Contentportal/Content_All_Page/Content_Live/Contentaddlivecategory";
import Contentliveeditcategory from "../Contentportal/Content_All_Page/Content_Live/Contentliveeditcategory";
import Contentlivecategory from "../Contentportal/Content_All_Page/Content_Live/Contentlivecategory";
import Contentaddaudio from "../Contentportal/Content_All_Page/Content_Audios/Contentaddaudio";
import Contenteditaudio from "../Contentportal/Content_All_Page/Content_Audios/Contenteditaudio";
import Contentallaudiocategory from "../Contentportal/Content_All_Page/Content_Audios/Contentallaudiocategory";
import Contentaddaudiocategory from "../Contentportal/Content_All_Page/Content_Audios/Contentaddaudiocategory";
import Contenteditaudiocategory from "../Contentportal/Content_All_Page/Content_Audios/Contenteditaudiocategory";
import Contentallaudio from "../Contentportal/Content_All_Page/Content_Audios/Contentallaudio";
import Contentallaudioalbum from "../Contentportal/Content_All_Page/Content_Audios/Contentallaudioalbum";
import Contentaddaudioalbum from "../Contentportal/Content_All_Page/Content_Audios/Contentaddaudioalbum";
import Contenteditaudioalbum from "../Contentportal/Content_All_Page/Content_Audios/Contenteditaudioalbum";
import LoaderPage from "../Layout/LoaderPage";
import Contentallseries from "../Contentportal/Content_All_Page/Content_Series_Episode/Contentallseries";
import Contentaddseries from "../Contentportal/Content_All_Page/Content_Series_Episode/Contentaddseries";
import Contentallseriescategory from "../Contentportal/Content_All_Page/Content_Series_Episode/Contentallseriescategory";
import Contentaddseriescategory from "../Contentportal/Content_All_Page/Content_Series_Episode/Contentaddseriescategory";
import Contenteditseries from "../Contentportal/Content_All_Page/Content_Series_Episode/Contenteditseries";
import Contenteditseriescategory from "../Contentportal/Content_All_Page/Content_Series_Episode/Contenteditseriescategory";
import Contentmanageepisode from "../Contentportal/Content_All_Page/Content_Series_Episode/Contentmanageepisode";
import Contenteditepisode from "../Contentportal/Content_All_Page/Content_Series_Episode/Contenteditepisode";
import Contenteditseason from "../Contentportal/Content_All_Page/Content_Series_Episode/Contenteditseason";
import Contentcppsetting from "../Contentportal/Content_All_Page/ContentSetting/Contentcppsetting";
import Contentvideoanalytics from "../Contentportal/Content_All_Page/Content_Analytics/Contentvideoanalytics";
import Contentregionanalytics from "../Contentportal/Content_All_Page/Content_Analytics/Contentregionanalytics";
import Contentpayoutanalytics from "../Contentportal/Content_All_Page/Content_Analytics/Contentpayoutanalytics";
import Content__Signup from "../Contentportal/Signup/Content_Signup";
import Content_AllArtist from "../Contentportal/Content_All_Page/Content_Artists/Content_AllArtist";
import Content_NewArtist from "../Contentportal/Content_All_Page/Content_Artists/Content_NewArtist";
import Content_EditNewArtist from "../Contentportal/Content_All_Page/Content_Artists/Content_EditNewArtist";
import Content_AllPages from "../Contentportal/Content_All_Page/Content_Pages/Content_AllPages";
import Content_AddPages from "../Contentportal/Content_All_Page/Content_Pages/Content_AddPages";
import Content_EditPages from "../Contentportal/Content_All_Page/Content_Pages/Content_EditPages";
import Contentlivepayoutanalytics from "../Contentportal/Content_All_Page/Content_Analytics/Contentlivepayoutanalytics";

function ContentAllRoutes(props) {
  const userData = localStorage.getItem("access_token_cpp");
  const { lang } = useParams()
  return (
    <>
      <div className="">
        <div>
          <Suspense fallback={<LoaderPage />}  >
            <Routes>
              <Route path="/login" element={<ContentSignin ></ContentSignin>} ></Route>
              <Route path="/signup" element={<Content__Signup ></Content__Signup>}></Route>
              <Route path="/" element={userData ? <ContentCommonLayout /> : <Navigate to={`/${lang}/cpp/login`} />}  >
                <Route index element={<ContentDashboard />}></Route>
                <Route path="/home" element={<ContentDashboard />} />
                {/* Video route start */}
                <Route path="/video-lists" element={<Contentallvideos />} />
                <Route path="/add-video" element={<Contentaddvideos />} />
                <Route path="/edit-video/:id" element={<Contenteditvideos />} />

                <Route path="/video-categories-list" element={<Contentvideocategory />} />
                <Route path="/add-video-category" element={<Contentaddvideocategory />} />
                <Route path="/edit-category/:id" element={<Contenteditvideocategory />} />
                {/* Video route end */}

                {/* Series route start */}
                <Route path="/series-lists" element={<Contentallseries />} />
                <Route path="/add-series" element={<Contentaddseries />} />
                <Route path="/edit-series/:id" element={<Contenteditseries />} />

                <Route path="/manage-series-category" element={<Contentallseriescategory ></Contentallseriescategory>}> </Route>
                {/* Series route end */}

                <Route path="/all-livevideos" element={<Contentalllive />} > </Route>
                <Route
                  path="/add-livestream"
                  element={
                    <Contentaaddlive


                    ></Contentaaddlive>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/edit-livestream/:id"
                  element={
                    <ContentEditLiveStream


                    ></ContentEditLiveStream>
                  }
                >
                  {" "}
                </Route>
                <Route path="/manage-live-category" element={<Contentlivecategory></Contentlivecategory>}>
                  {" "}
                </Route>
                <Route
                  path="/livestream-add-categories"
                  element={
                    <Contentaddlivecategory


                    ></Contentaddlivecategory>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/livestream-edit-categories/:id"
                  element={
                    <Contentliveeditcategory


                    ></Contentliveeditcategory>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/all-audio-lists"
                  element={
                    <Contentallaudio


                    ></Contentallaudio>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/add-new-audios"
                  element={
                    <Contentaddaudio


                    ></Contentaddaudio>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/edit-audio/:id"
                  element={
                    <Contenteditaudio


                    ></Contenteditaudio>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/manage-audio-category"
                  element={
                    <Contentallaudiocategory


                    ></Contentallaudiocategory>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/add-audio-categories"
                  element={
                    <Contentaddaudiocategory


                    ></Contentaddaudiocategory>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/edit-audio-categories/:id"
                  element={
                    <Contenteditaudiocategory


                    ></Contenteditaudiocategory>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/audio-manage-albums"
                  element={
                    <Contentallaudioalbum


                    ></Contentallaudioalbum>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/add-albums"
                  element={
                    <Contentaddaudioalbum


                    ></Contentaddaudioalbum>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/edit-albums/:id"
                  element={
                    <Contenteditaudioalbum


                    ></Contenteditaudioalbum>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/all-artists"
                  element={
                    <Content_AllArtist


                    ></Content_AllArtist>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/add-artists"
                  element={
                    <Content_NewArtist


                    ></Content_NewArtist>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/edit-artists/:id"
                  element={
                    <Content_EditNewArtist


                    ></Content_EditNewArtist>
                  }
                >
                  {" "}
                </Route>


                <Route
                  path="/add-genre"
                  element={
                    <Contentaddseriescategory


                    ></Contentaddseriescategory>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/edit-genre/:id"
                  element={
                    <Contenteditseriescategory


                    ></Contenteditseriescategory>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/manage-episode/:seriesid/:id"
                  element={
                    <Contentmanageepisode


                    ></Contentmanageepisode>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/edit-episode/manage/:id"
                  element={
                    <Contenteditepisode


                    ></Contenteditepisode>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/edit-season/:id"
                  element={
                    <Contenteditseason


                    ></Contenteditseason>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/all-pages"
                  element={
                    <Content_AllPages


                    ></Content_AllPages>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/add-pages"
                  element={
                    <Content_AddPages


                    ></Content_AddPages>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/edit-pages/:id"
                  element={
                    <Content_EditPages


                    ></Content_EditPages>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/mycpp-setting"
                  element={
                    <Contentcppsetting


                    ></Contentcppsetting>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/video-analytics"
                  element={
                    <Contentvideoanalytics


                    ></Contentvideoanalytics>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/view-by-region"
                  element={
                    <Contentregionanalytics


                    ></Contentregionanalytics>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/payouts"
                  element={
                    <Contentpayoutanalytics


                    ></Contentpayoutanalytics>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/live-payouts"
                  element={
                    <Contentlivepayoutanalytics


                    ></Contentlivepayoutanalytics>
                  }
                >
                  {" "}
                </Route>
                {/* <Route path="/*" element={<Error404></Error404>}></Route> */}
                {/* <Route path="/maintances" element={<Maintances></Maintances>}></Route> */}
              </Route>
            </Routes>
          </Suspense>
        </div>
      </div>
    </>
  );
}

export default ContentAllRoutes;
