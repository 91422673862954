// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cpp_audio_category .orderborder .data_th {
  white-space: normal;
  word-break: break-all;
  border: none;
  font-size: 16px;
  padding: 10px;
}`, "",{"version":3,"sources":["webpack://./src/Contentportal/Content_All_Page/Content_Audios/Contentallaudiocategory.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,qBAAqB;EACrB,YAAY;EACZ,eAAe;EACf,aAAa;AACf","sourcesContent":[".cpp_audio_category .orderborder .data_th {\n  white-space: normal;\n  word-break: break-all;\n  border: none;\n  font-size: 16px;\n  padding: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
