import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { getCurrentAction, musicDetailsUpdate, playQueueAction } from '../../Redux/Actions/MusicAction';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import OnlineStatusAlert from '../Others/OnlineStatusAlert';
const MyContext = createContext();
const MyProvider = ({ siteTheme, thumbnailFreeORCostLabel, children, currentAudio, currentTimes, currentRepeat, currentIndex, playlistAudio, currentPlaying, playlistShow, homepage, languageDetailsData, minimize, playlistData, actionType }) => {

    const dispatch = useDispatch()
    const audioRef = useRef(new Audio());
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [volume, setVolume] = useState(1);
    const [searchValue, setSearchValue] = useState('')
    const [musicSearchValue, setMusicSearchValue] = useState('')
    const navigate = useNavigate()
    const [priceData, setPriceData] = useState({
        loading: true,
        currentUser: null,
        ppvPrice: null,
        defaultCurrency: null,
        otherCurrency: null
    });
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [showOfflineAlert, setShowOfflineAlert] = useState(false);
    const prograssBarPercentage = (currentTime / 1000 / duration) * 100 || 0;

    const handlePlayPause = () => {
        if (audioRef?.current?.readyState !== null && audioRef?.current?.readyState > 2) {
            if (audioRef.current.paused) {
                audioRef.current.play();
                dispatch({ type: "GET_PLAYING_SUCCESS", isPlaying: true })
            } else {
                audioRef.current.pause();
                dispatch({ type: "GET_PLAYING_SUCCESS", isPlaying: false });
            }
        }
    };

    //custom audio action
    const playNextLoop = async () => {
        const nextIndex = (currentIndex + 1) % playlistAudio?.length;
        if (playlistAudio?.length !== currentIndex + 1 || currentRepeat) {
            const nextAudio = playlistAudio?.[nextIndex]
            if (nextAudio?.audio_access) {
                await dispatch(getCurrentAction(nextIndex, playlistAudio));
                await dispatch(musicDetailsUpdate('AUDIO', playlistAudio?.[nextIndex]?.slug))
            }
            else {
                dispatch({ type: "PURCHASE_MODAL", payload: true, upComingTitle: nextAudio })
                audioRef.current.pause();
                audioRef.current.currentTime = 0
                dispatch({ type: "GET_PLAYING_SUCCESS", isPlaying: false });
                dispatch({ type: "GET_CURRENT_TIME_INDEX", currentTime: 0 });
            }
        }
        else {
            audioRef.current.pause();
            audioRef.current.currentTime = 0
            dispatch({ type: "GET_PLAYING_SUCCESS", isPlaying: false });
            dispatch({ type: "GET_CURRENT_TIME_INDEX", currentTime: 0 });
        }
    };

    const handlePlayNext = async () => {
        const nextIndex = currentIndex + 1;
        if (nextIndex < playlistAudio?.length) {
            const nextAudio = playlistAudio?.[nextIndex]
            if (nextAudio?.audio_access) {
                if (audioRef.current) {
                    await dispatch(getCurrentAction(nextIndex, playlistAudio, 0));
                    dispatch(musicDetailsUpdate("AUDIO", playlistAudio?.[nextIndex]?.slug))
                    if (audioRef?.current?.readyState > 2) {
                        audioRef.current.play();
                    }
                    else {
                        audioRef.current.pause();
                    }
                }
            }
            else {
                dispatch({ type: "PURCHASE_MODAL", payload: true, upComingTitle: nextAudio })
            }
        }
    };

    const handlePlayPrev = () => {
        const nextIndex = currentIndex - 1;
        if (nextIndex >= 0) {
            const prevAudio = playlistAudio?.[nextIndex]
            if (prevAudio?.audio_access) {
                if (audioRef.current) {
                    dispatch(getCurrentAction(nextIndex, playlistAudio, 0));
                    dispatch(musicDetailsUpdate('AUDIO', playlistAudio?.[nextIndex]?.slug))
                    if (audioRef?.current?.readyState > 2) {
                        audioRef.current.play();
                    }
                }
            }
            else {
                dispatch({ type: "PURCHASE_MODAL", payload: true, upComingTitle: prevAudio })
            }
        }
    };

    const handleSeek = (newTime) => {
        const currentMilliSeconds = newTime * 1000;
        setCurrentTime(currentMilliSeconds);
        if (audioRef.current) {
            audioRef.current.currentTime = newTime;
        }
    };
    const repeatStates = {
        disabled: 'enabled',
        enabled: 'currentloop',
        currentloop: 'disabled'
    };

    const handleEnableLoop = () => {
        dispatch({ type: "GET_REPEAT_SUCCESS", isRepeat: repeatStates[currentRepeat] });
    };


    const handleVolume = () => {
        setVolume((prevVolume) => (prevVolume == 1 ? 0 : 1));
    };

    const handlePlay = (type, slug, id) => {
        const isSpecialType = ["PLAYLIST", "ARTIST", "ALBUM", "STATION", "GENRE"].includes(type);
        if (!isSpecialType) {
            if (currentAudio?.id !== id) {
                dispatch(playQueueAction(type, slug));
                if (!playlistShow) dispatch({ type: "OPEN_PLAYLIST", payload: true });
            } else {
                handlePlayPause();
            }
        } else {
            if (type !== actionType || playlistData?.id !== id) {
                dispatch(playQueueAction(type, slug));
                if (!playlistShow) dispatch({ type: "OPEN_PLAYLIST", payload: true });
            } else {
                handlePlayPause();
            }
        }
        dispatch({ type: "GET_ACTION_TYPE", payload: type })

    }

    //initial audio action
    const handleTimeUpdate = () => {
        const currentMilliSeconds = audioRef?.current?.currentTime * 1000;
        setCurrentTime(currentMilliSeconds);
    };

    const handleLoadedMetadata = () => {
        setDuration(audioRef.current.duration);
    };

    const onAudioEnded = () => {
        playNextLoop();
    };

    const minimizePlayer = () => {
        dispatch({ type: "MINIMIZE_PLAYER", payload: !minimize })
        dispatch({ type: "OPEN_PLAYLIST", payload: minimize })
        const regex = new RegExp(`^/\\w+/music`);
        if (!regex.test(window.location.pathname)) {
            navigate(`/${languageDetailsData?.Language_Code}/music`)
        }
    }


    useEffect(() => {
        if (currentAudio && Object.keys(currentAudio).length > 0 && !currentAudio?.audio_access) {
            dispatch({ type: "PURCHASE_MODAL", payload: true, upComingTitle: currentAudio })
        }
        if (audioRef?.current?.readyState > 2 && currentPlaying) {
            audioRef?.current?.play()
        } else {
            audioRef?.current?.pause()
        }
    }, [currentAudio?.id, audioRef?.current?.readyState]);

    useEffect(() => {
        if (currentTime !== null) {
            setCurrentTime(currentTimes);
            audioRef.current.currentTime = currentTimes / 1000
        }
    }, [])

    useEffect(() => {
        audioRef.current.volume = volume;
    }, [volume]);

    useEffect(() => {
        if (currentTime !== null) {
            dispatch({ type: "GET_LYRIC_SUCCESS", currentTimes: currentTime, });
        }
    }, [currentTime]);

    useEffect(() => {
        if (playlistShow && homepage !== "home") {
            navigate(`/${languageDetailsData?.Language_Code}/music/track/${currentAudio?.slug}`)
        }
    }, [currentAudio?.id])

    const handleSearch = (e) => {
        setSearchValue(e.target.value)
        navigate(`/${languageDetailsData?.Language_Code}/search`)
    }
    const handleSearchClear = (e) => {
        setSearchValue('')
    }
    const handleMusicSearch = (e) => {
        setMusicSearchValue(e.target.value)
        if (window.location.pathname !== `/${languageDetailsData?.Language_Code}/music/search`) {
            navigate(`/${languageDetailsData?.Language_Code}/music/search`)
        }
    }
    const handleMusicSearchClear = (e) => {
        setMusicSearchValue('')
    }

    const shufflePlaylist = () => {
        const shuffledPlaylists = [...playlistAudio];
        for (let i = shuffledPlaylists.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffledPlaylists[i], shuffledPlaylists[j]] = [shuffledPlaylists[j], shuffledPlaylists[i]];
        }
        dispatch({ type: "GET_AUDIO_SUCCESS", payload: shuffledPlaylists });
    };
    useEffect(() => {
        if (languageDetailsData?.Language_Code === 'ar') {
            document.body.setAttribute('dir', 'rtl');
            document.body.classList.add('rtl');
        } else {

            document.body.removeAttribute('dir', 'rtl');
            document.body.classList.remove('rtl');
        }
    }, [languageDetailsData])

    const fetchCurrencyData = async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_Baseurl}/Front-End/Currency-Index`);
            setPriceData(prev => ({
                ...prev,
                defaultCurrency: data?.CurrencySetting,
                otherCurrency: data?.allCurrency
            }));
            fetchUserLocation(data?.CurrencySetting?.code, data?.allCurrency);
        } catch (error) {
            console.error('Error fetching currency data:', error);
        }
    };

    const fetchUserLocation = async (currencyCode, allCurrency) => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_API_IP_Location}`);
            const selectedCurrency = allCurrency?.find(item => item?.country === data?.country)?.code;
            const selectedSymbol = allCurrency?.find(item => item?.country === data?.country)?.symbol;
            // console.log(selectedSymbol)
            setPriceData(prev => ({
                ...prev,
                currentUser: data,
                convertSymbol: selectedSymbol
            }));

            exchangePrice(data, currencyCode, selectedCurrency);
        } catch (error) {
            console.error('Error fetching location data:', error);
        }
    };

    const exchangePrice = async (userLocation, currencyCode, selectedCurrency) => {
        try {
            const { data: rateData } = await axios.get(`${process.env.REACT_APP_API_Curreny_generate}/${currencyCode}`);
            if (selectedCurrency) {
                setPriceData(prev => ({
                    ...prev,
                    ppvPrice: rateData?.rates[selectedCurrency],
                    loading: false,
                }));
            } else {
                console.error('Selected currency not found for user location.');
                setPriceData(prev => ({
                    ...prev,
                    ppvPrice: null,
                    loading: false,
                }));
            }
        } catch (error) {
            console.error('Error fetching exchange price:', error);
        }
    };

    const navigateTranslateChecker = (path) => {
        if (siteTheme?.translate_checkout) {
            return `/${languageDetailsData?.Language_Code}${path}`
        }
        else {
            return `${path}`
        }
    }

    useEffect(() => {
        if (thumbnailFreeORCostLabel) {
            fetchCurrencyData();
        }
    }, [thumbnailFreeORCostLabel]);

    useEffect(() => {
        const handleOnline = () => {
            setIsOnline(true);
            setShowOfflineAlert(true);
            setTimeout(() => {
                setShowOfflineAlert(false);
            }, 5000);
        };

        const handleOffline = () => {
            setIsOnline(false);
            setShowOfflineAlert(true);
        };

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);

    const reload = () => {
        setShowOfflineAlert(true)
        window.location.reload()
    }
    return (
        <MyContext.Provider value={{ isOnline, navigateTranslateChecker, priceData, shufflePlaylist, handleMusicSearch, handleMusicSearchClear, handleSearchClear, handlePlayPause, handlePlayNext, handlePlayPrev, handleVolume, handleSeek, handleEnableLoop, minimizePlayer, handlePlay, prograssBarPercentage, duration, currentTime, readyState: audioRef?.current?.readyState, audioReference: audioRef, volume, handleSearch, searchValue, musicSearchValue }}>
            {currentAudio ? <audio id="musicPlayerId" ref={audioRef} src={currentAudio?.audio_access ? currentAudio?.mp3_url : ""} onTimeUpdate={handleTimeUpdate} onLoadedMetadata={handleLoadedMetadata} onEnded={onAudioEnded} /> : null}
            {showOfflineAlert ? <OnlineStatusAlert reload={reload} /> : !isOnline && <OnlineStatusAlert reload={reload} />}
            {children}
        </MyContext.Provider >)
}

const mapStateToProps = state => ({
    currentAudio: state.get_current_Reducers.currentAudio,
    currentTimes: state.get_current_Reducers.currentTime,
    currentRepeat: state.get_current_Reducers.currentRepeat,
    playlistAudio: state.get_playlist_Reducer.playlistAudio,
    isLoading: state.get_playlist_Reducer.isLoading,
    error: state.get_playlist_Reducer.error,
    currentIndex: state.get_current_Reducers.currentIndex,
    currentPlaying: state.get_current_Reducers.currentPlaying,
    playlistShow: state.get_Playlist_Modal.playlistShow,
    homepage: state.get_Playlist_Modal.homepage,
    languageDetailsData: state.get_getUserTranslateLanguage_Reducer.languageDetailsData,
    minimize: state.get_Playlist_Modal.minimize,
    playlistData: state.get_current_Reducers.playlistData,
    actionType: state.get_current_Reducers.actionType,
    thumbnailFreeORCostLabel: state.get_allThumbnail_Reducer.thumbnailFreeORCostLabel,
    siteTheme: state.get_siteTheme_Reducer.siteTheme,
});
export default connect(mapStateToProps)(MyProvider);

export function useMyContext() {
    return useContext(MyContext);
}