import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, EffectFade, Navigation, EffectCoverflow } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';
import "./BannerPagination.css"
import { connect, useDispatch } from 'react-redux';
import { homeBannerAction } from '../../Redux/Actions/apiActions';
import BannerPlayer from '../../Pages/HomePage/HomeSwiperComponents/VideoPlayerMultiType/BannerPlayer';
import BannerImage from '../../Pages/HomePage/HomeSwiperComponents/VideoPlayerMultiType/BannerImage';
const BannerPagination = ({ sliderData, isLoading, error }) => {

    const [activeIndexOur, setActiveIndex] = useState(0);
    const handleSlideChange = (swiper) => {
        setActiveIndex(swiper.activeIndex)
    };
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(homeBannerAction());
    }, [dispatch]);

    const swiperConfig = {
        slidesPerView: 1,
        spaceBetween: 10,
        navigation: true,
        lazy: true,
        centeredSlides: true,
        grabCursor: true,
        loop: false,
        effect: 'fade',
        modules: [Autoplay, Navigation, Pagination, EffectFade],
        className: 'swiperThemeTwo',
        onSlideChange: { handleSlideChange },
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
        },
        pagination: {
            clickable: true,
            dynamicBullets: true,
            renderBullet: function (index, className) {
                return (
                    "<div class='" + className + " rounded-2  " + "'>" +
                    "<img src='" + sliderData?.[index]?.image_url + "' alt='rider' class=' w-100 h-100 overflow-hidden rounded-2 object-fit-cover'>" + "</img>" +
                    "</div>"
                );
            },
        },
    }

    return (
        <div className='' >
            {isLoading ? <Swiper {...swiperConfig} >
                {
                    Array.from({ length: 8 }).map((value) => (
                        <SwiperSlide key={value} className='' >
                            <div className='bannerContainer w-100 h-100 theme-bg-color-secondary'></div>
                        </SwiperSlide>))
                }
            </Swiper > : <Swiper {...swiperConfig}  >
                {sliderData?.length >= 0 && sliderData?.map((item, index) => (
                    <SwiperSlide key={index} >
                        {item?.trailer_videos_url || item?.trailer_link ? <BannerPlayer data={item} index={index} sliderCurrentIndex={activeIndexOur} /> : <BannerImage data={item} />}
                    </SwiperSlide>
                ))
                }
            </Swiper >}
        </div >
    );
}

const mapStateToProps = state => ({
    sliderData: state.get_HomeSlider_Reducer.sliderData,
    isLoading: state.get_HomeSlider_Reducer.isLoading,
    error: state.get_HomeSlider_Reducer.error,

});
export default connect(mapStateToProps)(BannerPagination)
