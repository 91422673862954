import React from 'react'
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
// Internal Styles
import "swiper/css";
import "swiper/css/navigation";
import CardWithHover from '../../HomePage/HomeSwiperComponents/Theme/CardWithHover';
import CardWithoutHover from '../../HomePage/HomeSwiperComponents/Theme/CardWithoutHover';
import MessageBox from '../../../components/Others/MessageBox';
import { URLGenerater } from '../../CommonMethods/CommonMethods';

const MovieAll = ({ item, key, themeMode }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { lang } = useParams();
    const swiperConfig = {
        slidesPerView: 2,
        spaceBetween: 15,
        navigation: true,
        lazy: true,
        slidesPerGroup: 2,
        breakpoints: {
            376: { slidesPerView: 2, spaceBetween: 15, slidesPerGroup: 2 },
            576: { slidesPerView: 3, spaceBetween: 15, slidesPerGroup: 3 },
            768: { slidesPerView: 4, spaceBetween: 15, slidesPerGroup: 4 },
            1024: { slidesPerView: 5, spaceBetween: 15, slidesPerGroup: 5 },
            1200: { slidesPerView: 6, spaceBetween: 15, slidesPerGroup: 6 },
            1440: { slidesPerView: 6, spaceBetween: 15, slidesPerGroup: 6 },
        },
        modules: [Navigation],
        className: 'homePageSwiper'
    }
    return (
        <div className=' pt-1 zIndex'>
            {
                item?.data?.length > 0 && (<>
                    <Link to={`/${lang}/${URLGenerater(item?.source)}`} className="text-decoration-none swiperHeading  theme-text-color d-inline-block">{<MessageBox text={t(item?.header_name)} />}</Link>
                    <Link to={`/${lang}/${URLGenerater(item?.source)}`} className="text-decoration-none swiperHeading theme-text-color float-end viewall-homepage" ><MessageBox text={t("View All")} /></Link>
                </>)
            }
            <Swiper {...swiperConfig}>
                {item?.data?.map((value, index) => (
                    <SwiperSlide key={value?.id} className='theme-bg-color-secondary'>
                        {themeMode?.hoverCard ? <CardWithHover value={value} segMent="videos" lang={lang} hoverClass="homeSwiperContainer" /> : <CardWithoutHover value={value} segMent="videos" lang={lang} />}
                    </SwiperSlide>
                ))}
            </Swiper>
        </div >
    )
}
const mapStateToProps = (state) => ({
    themeMode: state.get_headerIndex_Reducer.themeMode,
});
export default connect(mapStateToProps)(MovieAll);