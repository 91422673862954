import { connect } from "react-redux";

import Loader from "../Images/loaderget.gif";
import { Navigate, useParams } from "react-router-dom";
import { getItem } from "../../Utils/localStorageUtils";
import LoaderPage from "../../Layout/LoaderPage";

const HomeProtectedRoute = ({ settings, isLoading, children }) => {
  const { lang } = useParams()
  const userData = getItem("access_token");
  if (isLoading) {
    return <LoaderPage />;
  }
  else {
    if (userData || Number(settings?.access_free)) {
      return children;
    } else {

      return <Navigate to={`/${lang}/login`} />;
    }
  }
}
const mapStateToProps = state => ({
  settings: state.get_setting_Reducer.settings,
  isLoading: state.get_setting_Reducer.isLoading,
});
export default connect(mapStateToProps)(HomeProtectedRoute);;