import React, { useState, useCallback, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import "./Contenteditseason.css";
import Dropzone from "react-dropzone";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import loadings from "../../../components/Images/loading/loading.gif";
import ReactPlayer from "react-player";
import { toast } from "react-toastify";
 
import ResponseLoader from "../../../components/Others/ResponseLoader";

function Contenteditseason() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [editUser, setEditUser] = useState({
    id: "",
    user_id: "",
    series_id: "",
    trailer: "",
    image: "",
    access: "",
    ppv_price: "",
    ppv_interval: "",
    trailer_type: "",
    ios_product_id: "",
    uploaded_by: "",
    Content_Partner_id: "",
  });

  // let access_token, access_token_cpp;

  // if (localStorage.getItem("username")) {
  //   access_token = localStorage.getItem("access_token");
  // } else {
  //   access_token_cpp = localStorage.getItem("access_token_cpp");
  // }

  // const headers = {
  //   "Content-Type": "application/json",
  //   // Authorization: "Bearer " + access_token_cpp,
  //   Accept: "application/json",
  //   "Access-Control-Allow-Origin": "*",
  // };

  // if (access_token) {
  //   headers.Authorization = "Bearer " + access_token;
  // } else if (access_token_cpp) {
  //   headers.Authorization = "Bearer " + access_token_cpp;
  // }

  const access_token_cpp = localStorage.getItem("access_token_cpp");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token_cpp,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const [user_access, setUser_access] = useState([]);
  const [inapppurchase, setInappPurchase] = useState([]);

  const [selectedFile, setSelectedFile] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    // Handle file drop here
    const file = acceptedFiles[0];
    setSelectedFile(file);
  }, []);

  const handleFileChangessss = (event) => {
    // Handle file selection from file input
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);
  const fileInputRef4 = useRef(null);

  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFile3, setSelectedFile3] = useState(null);
  const [selectedFile4, setSelectedFile4] = useState(null);

  const handleFileChange = (event, fileInputRef, setSelectedFile) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDelete = (
    setSelectedFile1,
    setSelectedFile2,
    setSelectedFile3,
    setSelectedFile4
  ) => {
    setSelectedFile1(null);
    if (fileInputRef1.current) {
      fileInputRef1.current.value = null;
    }
    setSelectedFile2(null);
    if (fileInputRef2.current) {
      fileInputRef2.current.value = null;
    }
    setSelectedFile3(null);
    if (fileInputRef3.current) {
      fileInputRef3.current.value = null;
    }
    setSelectedFile4(null);
    if (fileInputRef4.current) {
      fileInputRef4.current.value = null;
    }
  };

  useEffect(() => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/series-season/edit/${id}`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.SeriesSeason[0];
      // var result = resData?.user_access;
      setEditUser(res);
      setLoading(false);
    };
    getUser();
    const create = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/series-season/create`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var result = resData?.user_access;
      var InappPurchase = resData?.InappPurchase;
      setUser_access(result);
      setInappPurchase(InappPurchase);
      var globelset = resData?.ppv_gobal_price;

      setInputValueAPI(globelset);
    };
    create();
  }, [id]);

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const handleUpdate = async (e) => {
    e.preventDefault();
    const editInputvalue = {
      Content_Partner_id: editUser?.Content_Partner_id,
      id: editUser?.id,
      user_id: editUser?.user_id,
      series_id: editUser?.series_id,
      trailer: editUser?.trailer,
      image: editUser?.image,
      access: editUser?.access,
      // ppv_price: editUser?.ppv_price,
      ppv_price: inputValue,
      ppv_interval: editUser?.ppv_interval,
      trailer_type: editUser?.trailer_type,
      ios_product_id: editUser?.ios_product_id,
      uploaded_by: editUser?.uploaded_by,
    };
    let firstApiStatus = false;
    let secondApiStatus = false;
    let seventhApiStatus = false;

    let resultapi = null;
    let resjson = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/series-season/update/${id}`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(editInputvalue),
        }
      );

      resjson = await res.json();

      if (resjson.status == true) {
        firstApiStatus = true;

        resultapi = resjson;

        const formData = new FormData();

        formData.append("season_id", id);
        formData.append("Content_Partner_id", 1);
        if (selectedFile1) {
          formData.append("image", selectedFile1);
        }

        const imageResponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/CPP/Backend/series-season/Image_upload`,
          formData,
          { headers: headers }
        );

        const formDataseo = new FormData();
        formDataseo.append("Content_Partner_id", 1);
        formDataseo.append("season_id", id);
        formDataseo.append("trailer", selectedFile);
        // formDataseo.append("trailer_type", trailer_type);

        const formDataseoresponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/CPP/Backend/series-season/Trailer_upload`,
          formDataseo,
          { headers: headers }
        );

        if (imageResponse.data.status == true) {
          secondApiStatus = true;
        }
        if (formDataseoresponse.data.status == true) {
          seventhApiStatus = true;
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }

    if (firstApiStatus && secondApiStatus && seventhApiStatus) {
      // props.setApiresponsealert(resultapi);
      // props.setShowalert(true);
      toast.success(resultapi?.message);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resjson.status == false) {
        var resultError = resjson;
        // props.setApiresponsealert(resultError);
        // props.setShowalert(true);
        // props.setApiresponsealert(resultapifalse);
        // props.setShowalert(true);
        toast.error(resultError?.message);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  };

  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputValueAPI, setInputValueAPI] = useState("");

  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
    if (!checkboxChecked) {
      setInputValue(inputValueAPI);
    } else {
      setInputValue("");
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const [allliveuseraccess, setallliveuseraccess] = useState("");
  const [storeliveuseraccess, setStoreliveuseraccess] = useState("");

  function allseasonuseraccess() {
    switch (allliveuseraccess) {
      case "guest":
        return <div></div>;
      case "subscriber":
        return <div></div>;
      case "registered":
        return <div></div>;
      case "ppv":
        return (
          <div className="row mt-3 text-start ">
            <div className="col-sm-12">
              <label className="m-0 theme-text-color">PPV Price</label>
              <div className="col-lg-12 row">
                <div className="col-8 col-lg-8">
                  <p className="p1 theme-text-color">
                    Apply PPV Price from Global Settings?{" "}
                  </p>
                </div>
                <div className="col-4 col-lg-4">
                  <label className="switch">
                    <input
                      name="ppv_price"
                      defaultChecked={checkboxChecked}
                      onChange={handleCheckboxChange}
                      type="checkbox"
                      className="rs-input"
                    />
                    <span
                      className="slider round"
                      name="ppv_price"
                      checked={checkboxChecked}
                      onChange={handleCheckboxChange}
                    ></span>
                  </label>
                </div>
              </div>

              <div className="panel-body">
                <input
                  type="number"
                  className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                  placeholder="PPV Price"
                  id="ppv_price"
                  name="ppv_price"
                  value={inputValue}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="col-sm-12 mt-2">
              <label className="m-0 theme-text-color"> IOS PPV Price</label>
              <p className="p1 theme-text-color">
                Apply IOS PPV Price from Global Settings?
              </p>
              <div className="panel-body ppv_price_ios mt-2">
                <select
                  name="ios_ppv_price"
                  id="ios_ppv_price"
                  onChange={handleInput}
                  value={editUser?.ios_ppv_price}
                  className="form-select   theme-bg-color-secondary  theme-text-color border-0"
                >
                  {inapppurchase?.map((item, key) => (
                    <option value={item.product_id}>{item.plan_price}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  const [allliveuseraccessseason, setallliveuseraccessseason] = useState("");
  const [storeliveuseraccessseason, setStoreliveuseraccessseason] =
    useState("");

  function allseasonuseraccessseason() {
    switch (allliveuseraccessseason) {
      case "guest":
        return <div></div>;
      case "subscriber":
        return <div></div>;
      case "registered":
        return <div></div>;
      case "ppv":
        return (
          <div className="row mt-3 text-start ">
            <div className="col-sm-12">
              <label className="m-0 theme-text-color">PPV Price</label>
              <div className="col-lg-12 row">
                <div className="col-8 col-lg-8">
                  <p className="p1 theme-text-color">
                    Apply PPV Price from Global Settings?{" "}
                  </p>
                </div>
                <div className="col-4 col-lg-4">
                  <label className="switch">
                    <input
                      name="ppv_price"
                      defaultChecked={checkboxChecked}
                      onChange={handleCheckboxChange}
                      type="checkbox"
                      className="rs-input"
                    />
                    <span
                      className="slider round"
                      name="ppv_price"
                      checked={checkboxChecked}
                      onChange={handleCheckboxChange}
                    ></span>
                  </label>
                </div>
              </div>

              <div className="panel-body">
                <input
                  type="text"
                  className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                  placeholder="PPV Price"
                  name="ppv_price"
                  id="price"
                  value={inputValue}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="col-sm-12 mt-2">
              <label className="m-0 theme-text-color"> IOS PPV Price</label>
              <p className="p1 theme-text-color">
                Apply IOS PPV Price from Global Settings?
              </p>
              <div className="panel-body ppv_price_ios  mt-2">
                <select
                  name="ios_product_id"
                  onChange={handleInput}
                  value={editUser?.ios_product_id}
                  id="ios_product_id"
                  className="form-select   theme-bg-color-secondary  theme-text-color border-0"
                >
                  {inapppurchase?.map((item, key) => (
                    <option value={item.product_id}>{item.plan_price}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-sm-12 mt-2">
              <label className=" theme-text-color">PPV Interval</label>
              <p className="p1 theme-text-color">
                Please Mention How Many Episodes are Free
              </p>

              <div className="panel-body ppv_price_ios  mt-2">
                <input
                  type="text"
                  id="ppv_interval"
                  name="ppv_interval"
                  onChange={handleInput}
                  value={editUser?.ppv_interval}
                  className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                />
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid  theme-bg-color-secondary ">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-lg-6">
                <h3 className=" theme-text-color">Edit Season</h3>
              </div>
              <div className="col-6 col-sm-6 col-lg-6">
                <div className="text-end">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Update Season
                  </button>
                </div>
              </div>
            </div>

            <div>{showOverlay && <ResponseLoader />}</div>

            <div className="row col-lg-12 ">
              <div className="col-6 col-sm-6 col-md-6 col-lg-6 ">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 ">
                  <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                    <h5 className=" theme-text-color">Access </h5>
                    <hr className=" theme-text-color"></hr>

                    <div className="mt-2">
                      <div className="col-sm-12">
                        <label className="m-0 theme-text-color">
                          Choose User Access
                        </label>
                        <p className="p1 theme-text-color">
                          Who Is Allowed To View This Live Stream ?
                        </p>
                        <select
                          onChange={handleInput}
                          name="access"
                          id="access"
                          className="form-select   theme-bg-color-secondary  theme-text-color border-0"
                          value={editUser?.access}
                          onClick={(event) => {
                            setallliveuseraccessseason(event.target.value);
                          }}
                        >
                          {user_access?.map((item) => (
                            <option value={item?.value}>{item?.name}</option>
                          ))}
                        </select>
                      </div>

                      <div>{allseasonuseraccessseason()}</div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                    <h5 className=" theme-text-color">Trailer</h5>
                    <hr className=" theme-text-color"></hr>

                    <div className="mediavideo mt-2">
                      <label className=" theme-text-color">
                        Season Trailer
                      </label>

                      {editUser?.trailer ? (
                        <div className=" py-2">
                          <div className="text-start">
                            <ReactPlayer
                              url={editUser?.trailer}
                              controls={true} // Enable player controls
                              width="100%"
                              height="200px"
                            />
                          </div>
                        </div>
                      ) : null}

                      <div className="dropzonestyle">
                        <Dropzone accept="video/*" onDrop={onDrop}>
                          {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              <p className="p-2 theme-text-color">
                                Drag and drop a video file here, or click to
                                select a file
                              </p>
                            </div>
                          )}
                        </Dropzone>
                        {selectedFile && (
                          <div>
                            <p className=" theme-text-color">
                              {selectedFile?.name}
                            </p>
                          </div>
                        )}
                        <input
                          type="file"
                          accept="video/*"
                          style={{ display: "none" }}
                          onChange={handleFileChangessss}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="text-start mb-3">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Update Season
                  </button>
                </div>
              </div>

              {/* Second Part of Section  */}

              <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                  <h5 className=" theme-text-color">Thumbnails</h5>
                  <hr className=" theme-text-color"></hr>

                  <div className=" text-start mt-2">
                    <label className="m-0  theme-text-color">
                      Season Thumbnail{" "}
                    </label>
                    <p className="t-1 theme-text-color">
                      (16:9 Ratio or 1080 X 1920px)
                    </p>

                    <div>
                      {loading ? (
                        <img src={loadings} className="thumbnails-images" />
                      ) : (
                        <img
                          src={editUser?.image}
                          alt="Image"
                          className="thumbnails-images"
                        />
                      )}
                    </div>

                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div
                            className="imagedropseason"
                            onClick={() => fileInputRef1.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef1}
                              onChange={(event) =>
                                handleFileChange(
                                  event,
                                  fileInputRef1,
                                  setSelectedFile1
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label className=" theme-text-color">
                              <CameraRetroIcon className=" theme-text-color" />
                            </label>
                          </div>
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div>
                            {selectedFile1 && (
                              <div className="imagedropcopyimage">
                                <img
                                  src={URL.createObjectURL(selectedFile1)}
                                  alt="Uploaded"
                                  className="thumbnails-upload-images"
                                />
                                <button
                                  onClick={() => handleDelete(setSelectedFile1)}
                                  type="button"
                                  className="btn-close theme-text-color"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Contenteditseason;
