// External Libraries and Packages
import React from 'react'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
// Internal Styles
import "swiper/css";
import "swiper/css/navigation";

// Internal Components

const StaticHomeSwiper = ({ type }) => {
    return (
        <div className={`${type == "show" || type == "movie" ? '' : "px-3 px-md-4 px-lg-4"} container-fluid  pt-3 pb-3 zIndex`}>
            <div className='d-flex align-items-center justify-content-between mb-4'>
                <Link className="blocklinetitle text-decoration-none  swiperHeading theme-text-color  d-inline-block rounded-2 overflow-hidden ">
                    <div class="skeletonStatic" style={{ width: "270px", height: "40px" }}>
                        <div class="skeleton-right " style={{ width: "270px", height: "40px" }}>
                            <div class="square " style={{ width: "270px", height: "40px" }}></div>
                        </div>
                    </div>
                </Link>
                <Link className="blocklinetitle text-decoration-none  swiperHeading theme-text-color rounded-2 overflow-hidden">
                    <div class="skeletonStatic" style={{ width: "120px", height: "40px" }}>
                        <div class="skeleton-right " style={{ width: "270px", height: "40px" }}>
                            <div class="square " style={{ width: "120px", height: "40px" }}></div>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="">
                <Swiper
                    slidesPerView={2}
                    spaceBetween={15}
                    navigation={false}
                    lazy={true}
                    slidesPerGroup={2}
                    breakpoints={{
                        376: {
                            slidesPerView: 2,
                            spaceBetween: 15,
                            slidesPerGroup: 2,
                        },
                        576: {
                            slidesPerView: 3,
                            spaceBetween: 15,
                            slidesPerGroup: 3,
                        },
                        768: {
                            slidesPerView: 4,
                            spaceBetween: 15,
                            slidesPerGroup: 4,
                        },
                        1024: {
                            slidesPerView: 5,
                            spaceBetween: 15,
                            slidesPerGroup: 5,
                        },
                        1200: {
                            slidesPerView: 6,
                            spaceBetween: 15,
                            slidesPerGroup: 6,
                        },
                        1440: {
                            slidesPerView: 6,
                            spaceBetween: 15,
                            slidesPerGroup: 6,
                        },

                    }}
                    modules={[Navigation]}
                    className={`homePageSwiper`}
                >
                    {Array.from({ length: 8 }).map((data, index) => (
                        <SwiperSlide key={index} className='theme-bg-color-secondary rounded-3 overflow-hidden'>
                            <div class="skeletonStatic w-100 h-100">
                                <div class="skeleton-right w-100 h-100">
                                    <div class="square h-100"></div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    )
}

export default StaticHomeSwiper; 
