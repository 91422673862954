import React from 'react';

const OnlineStatusAlert = ({ isOnline }) => {
    return (
        <div
            style={{
                position: 'fixed',
                inset: '30px 0 0 0',
                width: '100%',
                textAlign: 'center',
                padding: '10px',
                backgroundColor: isOnline ? 'green' : 'red',
                color: 'white',
                margin: '0 auto',
                maxWidth: '150px',
                borderRadius: '8px',
                height: '41px',
                zIndex: '9999'
            }}
            className={isOnline ? 'You are online' : 'You are offline'}
        >

            {isOnline ? 'You are online' : 'You are offline'}
        </div >
    );
};

export default OnlineStatusAlert;
