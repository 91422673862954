import React from 'react'
import LogoGifLoader from '../../Images/loading.gif'
const ResponseLoader = ({ text }) => {
    return (
        <div className="overlay-container">
            <span className="overlay-icon">
                <img src={LogoGifLoader} width={100} alt="Animated GIF" /> {text ? text : "processing"} ...
            </span>
        </div>
    )
}

export default ResponseLoader