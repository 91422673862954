import { useNavigate } from "react-router-dom";
import JoditEditor from "jodit-react";
import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Select from "react-select";
import { TagsInput } from "react-tag-input-component";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import StepContent from "@mui/material/StepContent";
import Dropzone from "react-dropzone";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
 
import Typography from "@mui/material/Typography";
import { FcOk, FcHighPriority, FcFile } from "react-icons/fc";
import { BsFiletypeXlsx } from "react-icons/bs";
import ResponseLoader from "../../../components/Others/ResponseLoader";
import AdminSaveHeader from "../../../components/Others/adminCommonComponents/AdminSaveHeader";

export default function Contentaddaudio() {

  const audioUrlUpload = `${process.env.REACT_APP_Baseurl}/CPP/Backend/Audios/Upload-URL`


  const [activeStep, setActiveStep] = React.useState(0);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const [file, setFile] = useState("");
  const [audio_id, setAudio_id] = useState("");
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [ppv_status, setPpv_status] = useState("1");
  const [ppv_price, setPpv_price] = useState("");
  const [type, setType] = useState("");
  const [access, setAccess] = useState("");
  const [rating, setRating] = useState("");
  const [details, setDetails] = useState("");
  const [description, setDescription] = useState("");
  const [active, setActive] = useState("");
  const [status, setStatus] = useState("");
  const [draft, setDraft] = useState("");
  const [featured, setFeatured] = useState("");
  const [banner, setBanner] = useState("");
  const [views, setViews] = useState("");
  const [mp3_url, setMp3_url] = useState("");
  const [mp3_live_url, setMp3_live_url] = useState("");
  const [searchTags, setSearchTags] = useState([]);
  const [ios_ppv_price, setIos_ppv_price] = useState("");
  const [uploaded_by, setUploaded_by] = useState("");
  const [album, setAlbum] = useState([]);
  const [audio_albums, setAudio_albums] = useState([]);
  const [websitetitle, setWebsitetitle] = useState("");
  const [metadescription, setMetadescription] = useState("");
  const [websiteurl, setWebsiteurl] = useState("");
  const [user_access, setUser_access] = useState([]);
  const [inapppurchase, setInappPurchase] = useState("");
  const [age_restrictdata, setAge_restrictdata] = useState([]);
  const [age_restrict, setAge_restrict] = useState([]);
  const [selectedAdsPosition, setSelectedAdsPosition] = useState(null);
  const [secondSelectOptions, setSecondSelectOptions] = useState([]);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputValueAPI, setInputValueAPI] = useState("");
  const [getSuccessLyrics, setSuccessLyrics] = useState(null);
  const [getDangerLyrics, setDangerLyrics] = useState(null);
  const [getLyrics, setLyrics] = useState(null);
  const [getExampleUrl, setExampleUrl] = useState(null);

  const [validationMessageAccess, setValidationMessageAccess] =
    useState("");
  const [isValidationHiddenAccess, setIsValidationHiddenAccess] =
    useState(true);
  const AudioRef = useRef(null);
  const accessInputRef = useRef(null);

  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
    if (!checkboxChecked) {
      setInputValue(inputValueAPI);
    } else {
      setInputValue("");
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleTagsChange = (newTags) => {
    // Convert tags to lowercase, remove duplicates, and filter out empty tags
    const cleanedTags = newTags
      .map((tag) => tag.trim().toLowerCase())
      .filter((tag) => tag !== "")
      .filter((value, index, self) => self.indexOf(value) == index);
    setSearchTags(cleanedTags);
  };

  const handleInputdata = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;
    // Remove non-digit characters from input
    const formattedInput = inputValue.replace(/\D/g, "");
    // Add colons to maintain the format HH:MM:SS
    const formattedTime = formattedInput
      .replace(/^(\d{2})(\d{2})(\d{2})$/, "$1:$2:$3")
      .substr(0, 8);

    setEditUser({
      ...editUser,
      [inputName]: formattedTime,
    });
  };

  const [inputValueyear, setInputValueyear] = useState("");
  const handleChangeyear = (event) => {
    const value = event.target.value;
    if (/^\d{0,4}$/.test(value)) {
      setInputValueyear(value);
    }
  };
  const [editUser, setEditUser] = useState({
    title: "",
    duration: "",
    album_id: "",
  });
  const navigate = useNavigate();
  const access_token_cpp = localStorage.getItem("access_token_cpp");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token_cpp,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const [autoFillValuetitle, setAutoFillValuetitle] = useState("");

  const handleInputKeyDowntitle = (event) => {
    if (event.key == "Enter" || event.key == "Tab") {
      generateSlug(editUser?.title);
    }
  };

  const handleBlurtitle = () => {
    generateSlug(editUser?.title);
  };

  const handleAutoFillChangetitle = (event) => {
    setValidationMessageslug("");
    setIsValidationHiddenslug(true);
    setAutoFillValuetitle(event.target.value);
  };

  const generateSlug = (title) => {
    title = title.trim();
    const slug = title.toLowerCase().replace(/\s+/g, "-");
    setAutoFillValuetitle(slug);
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessageslug("");
    setIsValidationHiddenslug(true);
    setValidationMessagetitle("");
    setIsValidationHiddentitle(true);
    setValidationMessagealbum_id("");
    setIsValidationHiddenalbum_id(true);
    setEditUser({ ...editUser, title: e.target.value });
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
    if (e.target.checked == true) {
      setPpv_status({ ...ppv_status, [e.target.name]: 1 });
      setBanner({ ...banner, [e.target.name]: 1 });
      setFeatured({ ...featured, [e.target.name]: 1 });
      setActive({ ...active, [e.target.name]: 1 });
      setStatus({ ...status, [e.target.name]: 1 });
      setViews({ ...views, [e.target.name]: 1 });
      setDraft({ ...draft, [e.target.name]: 1 });
    } else {
      setPpv_status({ ...ppv_status, [e.target.name]: 0 });
      setBanner({ ...banner, [e.target.name]: 0 });
      setFeatured({ ...featured, [e.target.name]: 0 });
      setActive({ ...active, [e.target.name]: 0 });
      setStatus({ ...status, [e.target.name]: 0 });
      setViews({ ...views, [e.target.name]: 0 });
      setDraft({ ...draft, [e.target.name]: 0 });
    }
  };

  const cancelFileUpload = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadCompleteddata, setUploadCompleteddata] = useState(false);

  const handleAccess = (data) => {
    setStoreliveuseraccess(data)
    setValidationMessageAccess("");
    setIsValidationHiddenAccess(true);
  }

  async function audiofilesubmit() {
    const formData = new FormData();
    formData.append("Content_Partner_id", 1);
    formData.append("type", selectedOption);
    formData.append("mp3_url", mp3_url);
    await axios.post(`${audioUrlUpload}`, formData, { headers: headers })
      .then((response) => {
        if (response.data.status) {
          var resultapi = response.data;
          setAudio_id(resultapi.Audio_id);
          setUploadCompleteddata(true);
          toast.success(resultapi?.message);
        } else {
          var resultError = response?.data?.message;
          toast.error(resultError);
        }
      })
      .catch((err) => {
        toast.error('Enter Correct Details');
      });
  }
  async function audiolivefilesubmit() {
    const formData = new FormData();
    formData.append("Channel_Partner_id", 1);
    formData.append("type", selectedOption);
    formData.append("mp3_live_url", mp3_live_url);
    await axios.post(`${process.env.REACT_APP_Baseurl}/CPP/Backend/Audios/Upload-URL`, formData, { headers: headers })
      .then((response) => {
        if (response.data.status == true) {
          var resultapi = response.data;
          var result = response.data.Audio_id;
          setAudio_id(result);
          setUploadCompleteddata(true);
          toast.success(resultapi?.message);
        } else {
          if (response.data.status == false) {
            var resultError = response?.data?.message;
            toast.error(resultError);
          }
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });
  }

  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);

  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFile3, setSelectedFile3] = useState(null);

  const handleFileChangesss = (event, fileInputRef, setSelectedFile) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDelete = (
    setSelectedFile1,
    setSelectedFile2,
    setSelectedFile3
  ) => {
    setSelectedFile1(null);
    if (fileInputRef1.current) {
      fileInputRef1.current.value = null;
    }
    setSelectedFile2(null);
    if (fileInputRef2.current) {
      fileInputRef2.current.value = null;
    }
    setSelectedFile3(null);
    if (fileInputRef3.current) {
      fileInputRef3.current.value = null;
    }
  };

  // Multiselect
  const [block_country, setBlock_Country] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [available_country, setAvailable_country] = useState([]);
  const [
    selectedOptionsAvailable_country,
    setSelectedOptionsAvailable_country,
  ] = useState([]);

  const [related_videos, setRelated_videos] = useState([]);
  const [selectedOptionsRelated_videos, setSelectedOptionsRelated_videos] =
    useState([]);

  const [language, setLanguage] = useState([]);
  const [selectedOptionslanguage, setSelectedOptionslanguage] = useState([]);

  const [artists, setArtists] = useState([]);
  const [selectedOptionsartists, setSelectedOptionsartists] = useState([]);

  const [category, setCategory] = useState([]);
  const [selectedOptionscategory, setSelectedOptionscategory] = useState([]);


  useEffect(() => {
    if (selectedAdsPosition !== "") {
      const formData = new FormData();
      formData.append("position", selectedAdsPosition?.value);
      axios.post(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/Audios/advertisement-tag-url`,
        formData,
        { headers: headers }
      )

        .then((response) => {
          const data = response.data;
          // console.log(data, "exceptioned");
          // const adsNames = data?.Advertisement?.map((position) => ({
          //   value: position?.id,
          //   label: position?.ads_name,
          // }));

          // setSecondSelectOptions(adsNames);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [selectedAdsPosition]);

  useEffect(() => {
    fetchOptions();
  }, []);

  const fetchOptions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/Audios/create`,
        { headers: headers }
      );
      setExampleUrl(response?.data?.sample_audio_lyrics);
      setBlock_Country(response.data.Block_country);
      setAvailable_country(response.data.Available_country);
      setRelated_videos(response.data.Related_videos);
      setLanguage(response.data.languages);
      // setAge_Restrict(response.data.Age_Restrict);
      setArtists(response.data.artists);
      setCategory(response.data.audio_categories);
      setInputValueAPI(response.data.ppv_global_price);
      var age_restrictdata = response.data.Age_Restrict;
      setAlbum(response?.data?.audio_albums);
      setAge_restrictdata(age_restrictdata);
      var result = response?.data?.user_access;
      var InappPurchase = response?.data?.InappPurchase;
      setUser_access(result);
      setInappPurchase(InappPurchase);
      // const adsPositionOptions = response?.data?.ads_position?.map((position) => ({
      //   value: position?.position,
      //   label: position?.name,
      // }));
      // setAdsPositions(adsPositionOptions);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectChange = (selectedValues) => {
    setSelectedOptions(selectedValues);
  };

  const handleSelectChangeRelated_videos = (selectedValues) => {
    setSelectedOptionsRelated_videos(selectedValues);
  };

  const handleSelectChangeAvailable_country = (selectedValues) => {
    setSelectedOptionsAvailable_country(selectedValues);
  };

  const handleSelectChangelanguage = (selectedValues) => {
    setValidationMessagelanguage("");
    setIsValidationHiddenlanguage(true);
    setSelectedOptionslanguage(selectedValues);
  };

  const handleSelectChangeartists = (selectedValues) => {
    setSelectedOptionsartists(selectedValues);
  };

  const handleSelectChangecategory = (selectedValues) => {
    setValidationMessagecategory("");
    setIsValidationHiddencategory(true);

    setSelectedOptionscategory(selectedValues);
  };
  // Multiselect

  const handleAdsPositionChange = (selectedValue5) => {
    setSelectedAdsPosition(selectedValue5);
    // console.log(selectedValue5); // Selected value
  };

  const contentFieldChanaged = (data) => {
    setDetails(data);
    // console.log("datasssssss", data);
  };

  const [validationMessagetitle, setValidationMessagetitle] = useState("");
  const [isValidationHiddentitle, setIsValidationHiddentitle] = useState(true);

  const [validationMessagealbum_id, setValidationMessagealbum_id] =
    useState("");
  const [isValidationHiddenalbum_id, setIsValidationHiddenalbum_id] =
    useState(true);

  const [validationMessageslug, setValidationMessageslug] = useState("");
  const [isValidationHiddenslug, setIsValidationHiddenslug] = useState(true);

  const [validationMessagecategory, setValidationMessagecategory] =
    useState("");
  const [isValidationHiddencategory, setIsValidationHiddencategory] =
    useState(true);

  const [validationMessagelanguage, setValidationMessagelanguage] =
    useState("");
  const [isValidationHiddenlanguage, setIsValidationHiddenlanguage] =
    useState(true);

  const titleInputRef = useRef(null);
  const album_idInputRef = useRef(null);
  const categoryInputRef = useRef(null);
  const languageInputRef = useRef(null);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const lyricsUpload = new FormData();
  lyricsUpload.append("lyrics", getLyrics);
  lyricsUpload.append("audio_id", audio_id);

  async function Audio() {
    let focusInputRef = null;

    if (editUser?.title == "") {
      setValidationMessagetitle("Title cannot be empty*.");
      setIsValidationHiddentitle(false);
      focusInputRef = titleInputRef;
    }
    if (editUser?.album_id == "") {
      setValidationMessagealbum_id("Album Id cannot be empty*.");
      setIsValidationHiddenalbum_id(false);
      focusInputRef = album_idInputRef;
    }

    if (selectedOptionscategory.length == 0) {
      setValidationMessagecategory("Please select at least one Category*.");
      setIsValidationHiddencategory(false);
      focusInputRef = categoryInputRef;
    }
    if (selectedOptionslanguage.length == 0) {
      setValidationMessagelanguage("Please select at least one Language*.");
      setIsValidationHiddenlanguage(false);
      focusInputRef = languageInputRef;
    }
    if (!storeliveuseraccess) {
      setValidationMessageAccess("Please select user access.");
      setIsValidationHiddenAccess(false);
      focusInputRef = accessInputRef;
    }

    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }
    if (getDangerLyrics) {
      AudioRef.current.focus();
      return;
    }

    const editInputvalue = active.active;
    var data = JSON.stringify(editInputvalue);
    var aactivedata = data;

    const editInputbanner = banner.banner;
    var databanner = JSON.stringify(editInputbanner);
    var bannerdata = databanner;

    const editInputfeatured = featured.featured;
    var datafeatured = JSON.stringify(editInputfeatured);
    var featureddata = datafeatured;

    const formData = new FormData();

    formData.append("Content_Partner_id", 1);
    formData.append("title", editUser?.title);
    formData.append("audio_id", audio_id);
    formData.append("ppv_status", ppv_status);
    // formData.append("ppv_price", inputValue);
    formData.append("slug", autoFillValuetitle);
    formData.append("type", type);
    formData.append("access", storeliveuseraccess);
    formData.append("album_id", editUser?.album);
    formData.append("audio_albums", audio_albums);
    formData.append("rating", rating);
    formData.append("details", details);
    formData.append("description", description);
    formData.append("active", aactivedata);
    formData.append("status", status);
    formData.append("draft", draft);
    formData.append("featured", featureddata);
    formData.append("banner", bannerdata);
    formData.append("duration", editUser?.duration);
    formData.append("views", views);
    formData.append("year", inputValueyear);
    formData.append("age_restrict", age_restrict);
    formData.append("mp3_url", mp3_url);
    formData.append(
      "artist_id",
      JSON.stringify(selectedOptionsartists?.map((option) => option.value))
    );
    formData.append(
      "country",
      JSON.stringify(selectedOptions?.map((option) => option.value))
    );
    formData.append(
      "available_countries_id",
      JSON.stringify(
        selectedOptionsAvailable_country?.map((option) => option.value)
      )
    );
    formData.append(
      "category_id",
      JSON.stringify(selectedOptionscategory?.map((option) => option.value))
    );
    formData.append(
      "languages_id",
      JSON.stringify(selectedOptionslanguage?.map((option) => option.value))
    );
    formData.append("search_tags", searchTags);
    formData.append("ppv_price", inputValue);
    formData.append("ios_ppv_price", ios_ppv_price);
    formData.append("uploaded_by", uploaded_by);

    formData.append("ads_position", selectedAdsPosition?.value);
    formData.append("live_ads", secondSelectOptions?.value);

    let firstApiStatus = false;
    let secondApiStatus = false;
    let seventhApiStatus = false;
    let audioApiStatus = false;

    let resultapi = null;
    let resultapifalse = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/Audios/store`,
        formData,
        { headers: headers }
      );

      if (response.data.status == true) {
        firstApiStatus = true;
        resultapi = response.data;
        const imageFormData = new FormData();
        imageFormData.append("audio_id", audio_id);
        if (selectedFile1) {
          imageFormData.append("image", selectedFile1);
        }
        if (selectedFile2) {
          imageFormData.append("player_image", selectedFile2);
        }
        if (selectedFile3) {
          imageFormData.append("tv_image", selectedFile3);
        }
        const imageResponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/CPP/Backend/Audios/Image-upload`,
          imageFormData,
          { headers: headers }
        );

        const formDataseo = new FormData();
        formDataseo.append("audio_id", audio_id);
        formDataseo.append("website_page_title", websitetitle);
        formDataseo.append("website_URL", websiteurl);
        formDataseo.append("Meta_description", metadescription);

        const formDataseoresponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/CPP/Backend/Audios/SEO`,
          formDataseo,
          { headers: headers }
        );
        const audioLyricsResponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/CPP/Backend/Audios/Lyrics-upload`,
          lyricsUpload,
          { headers }
        );

        if (imageResponse?.data?.status == true) {
          secondApiStatus = true;
        }
        if (formDataseoresponse?.data?.status == true) {
          seventhApiStatus = true;
        }
        if (audioLyricsResponse?.data?.status == true) {
          audioApiStatus = true;
        }
      } else if (response?.data?.status == false) {
        // Handle the case when the first API response status is false
        resultapifalse = response?.data;
      }
    } catch (error) { }

    if (
      firstApiStatus &&
      secondApiStatus &&
      seventhApiStatus &&
      audioApiStatus
    ) {
      setValidationMessagetitle("");
      setValidationMessageslug("");
      toast.success(resultapi?.message);
      // setTimeout(async () => {
      //   await navigate("/cpp/all-audio-lists");
      // }, 3000);
    } else if (
      firstApiStatus &&
      secondApiStatus &&
      seventhApiStatus &&
      audioApiStatus
    ) {
      toast.error(resultapifalse?.message);
      setProcessing(false);
      setShowOverlay(false);
      setIsValidationHiddentitle(false);
      setValidationMessageslug(false);
    }
  }

  const [allliveuseraccess, setallliveuseraccess] = useState("");
  const [storeliveuseraccess, setStoreliveuseraccess] = useState("");

  function allaudiouseraccess() {
    switch (allliveuseraccess) {
      case "guest":
        return <div></div>;
      case "subscriber":
        return <div></div>;
      case "registered":
        return <div></div>;
      case "ppv":
        return (
          <div className="row mt-3 text-start ">
            <div className="col-sm-12">
              <label className="m-0 theme-text-color">PPV Price</label>
              <div className="col-lg-12 row">
                <div className="col-8 col-lg-8">
                  <p className="p1 theme-text-color">
                    Apply PPV Price from Global Settings?{" "}
                  </p>
                </div>
                <div className="col-4 col-lg-4">
                  <label className="switch">
                    <input
                      name="ppv_price"
                      id="ppv_price"
                      defaultChecked={checkboxChecked}
                      onChange={handleCheckboxChange}
                      type="checkbox"
                      className="rs-input"
                    />
                    <span
                      className="slider round"
                      name="ppv_price"
                      checked={checkboxChecked}
                      onChange={handleCheckboxChange}
                    ></span>
                  </label>
                </div>
              </div>

              <div className="panel-body">
                <input
                  type="number"
                  className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                  placeholder="PPV Price"
                  name="ppv_price"
                  id="ppv_price"
                  value={inputValue}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="col-sm-12 mt-2">
              <label className="m-0 theme-text-color"> IOS PPV Price</label>
              <p className="p1 theme-text-color">
                Apply IOS PPV Price from Global Settings?
              </p>
              <div className="panel-body ppv_price_ios  mt-2">
                <select
                  onChange={(e) => setIos_ppv_price(e.target.value)}
                  className="form-select   theme-bg-color-secondary  theme-text-color border-0"
                  id="ios_ppv_price"
                  name="ios_ppv_price"
                >
                  <option value="">Choose a PPV Price</option>
                  {inapppurchase?.map((item, key) => (
                    <option value={item?.id}>{item?.plan_price}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  }
  const [enablestreamvisible, setEnablestreamvisible] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadCompleted, setUploadCompleted] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const handleUpload = async (files) => {
    const uploadUrl = `${process.env.REACT_APP_Baseurl}/CPP/Backend/Audios/Upload-file`;
    const uploaders = files.map((file) => {
      const formData = new FormData();
      formData.append("Content_Partner_id", 1);
      formData.append("file", file);
      return axios.post(uploadUrl, formData, {
        headers: headers,
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);
        },
      });
    });

    try {
      const responses = await Promise.all(uploaders);
      if (responses[0].data.status == true) {
        responses.forEach((response, index) => {
          setEditUser(response.data.Audio);
          var result = response.data.Audio_id;
          var resultdata = response.data.message;
          setAudio_id(result);
        });
        const uploadedDetails = files.map((file, index) => ({
          name: file.name,
          size: (file.size / (1024 * 1024)).toFixed(2), // Convert bytes to MB with 2 decimal places
        }));
        setUploadedFiles(uploadedDetails);
        setUploadCompleted(true);
        setUploadProgress(0);
        toast.success(responses[0]?.data?.message);
      } else {
        var resultError = responses[0].data;
        toast.error(resultError?.message);
        setUploadProgress(0);
      }
    } catch (error) {
      toast.error("Error uploading audio. Please try again.");
      setUploadProgress(0);
    }
  };

  const datadata = [
    {
      value: "add_video",
      label: "Audio Upload",
      content: (
        <div>
          <Dropzone
            accept="audio/*"
            onDrop={(acceptedFiles) => handleUpload(acceptedFiles)}
          >
            {({ getRootProps, getInputProps }) => (
              <div className="dropzone" {...getRootProps()}>
                <input {...getInputProps()} accept="audio/*" />
                <p className="draganddrop theme-text-color theme-border-color">
                  Drag and drop Audio files here, or click to select files
                </p>
              </div>
            )}
          </Dropzone>
          <div className="text-center">
            {uploadProgress > 0 && (
              <div>
                <p className=" theme-text-color">
                  Uploading... {uploadProgress}%
                </p>
                <progress value={uploadProgress} max="100" />
              </div>
            )}

            {uploadCompleted && (
              <div>
                {uploadedFiles.length > 0 && (
                  <div className="text-center">
                    <p className=" theme-text-color">Uploaded Files:</p>
                    <ul>
                      {uploadedFiles.map((file, index) => (
                        <li key={index} className=" theme-text-color">
                          {file.name} - {file.size} MB
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                <Button
                  variant="contained"
                  onClick={() => {
                    handleNext();
                  }}
                  className="btn button-color button-bg-color admin-button-text"
                >
                  Proceed to Next
                </Button>
              </div>
            )}
          </div>
        </div>
      ),
    },
    {
      value: "mp3",
      label: "Audio URL",
      content: (
        <div>
          <div className="row mt-3 text-start">
            <div className="col-sm-12 ">
              <div className="">
                <label className="mb-1 theme-text-color"> Mp3 File URL </label>
                <input
                  type="text"
                  className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                  name="mp3"
                  onChange={(e) => setMp3_url(e.target.value)}
                  placeholder="Mp3 File URL"
                />
              </div>
            </div>

            <div className="mt-4 text-center">
              <div className="">
                <Button
                  variant="contained"
                  onClick={() => {
                    audiofilesubmit();
                  }}
                  className="btn button-color button-bg-color admin-button-text"
                >
                  Submit
                </Button>
                {uploadCompleteddata && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      handleNext();
                    }}
                    className="btn button-color button-bg-color admin-button-text ms-3"
                  >
                    Proceed to Next
                  </Button>
                )}{" "}
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      value: "live_mp3",
      label: "Live Audio URL",
      content: (
        <div>
          <div className="row mt-3 text-start">
            <div className="col-sm-12 ">
              <div className="">
                <label className="mb-1 theme-text-color">
                  {" "}
                  Live Mp3 File URL{" "}
                </label>
                <input
                  type="text"
                  className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                  name="mp3_live_url"
                  placeholder="Live Mp3 File URL"
                  onChange={(e) => setMp3_live_url(e.target.value)}
                />
              </div>
            </div>
            <div className="mt-4 text-center">
              <div className="">
                <Button
                  variant="contained"
                  onClick={() => {
                    audiolivefilesubmit();
                  }}
                  className="btn button-color button-bg-color admin-button-text"
                >
                  Submit
                </Button>
                {uploadCompleteddata && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      handleNext();
                    }}
                    className="btn button-color button-bg-color admin-button-text ms-3"
                  >
                    Proceed to Next
                  </Button>
                )}{" "}
              </div>
            </div>
          </div>
        </div>
      ),
    },
  ];

  const [selectedOption, setSelectedOption] = useState(datadata[0]?.value);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleLyrics = async (event) => {
    setSuccessLyrics(null);
    setDangerLyrics(null);
    const selectedFile = event.target.files;
    if (selectedFile) {
      const newFile = selectedFile[0];
      setLyrics(newFile);
      try {
        const lyricsDatas = new FormData();
        lyricsDatas.append("lyrics", newFile);
        const response = await axios.post(
          `${process.env.REACT_APP_Baseurl}/CPP/Backend/Audios/Lyrics-validation`,
          lyricsDatas,
          { headers }
        );
        let result = response?.data;
        if (result.status == true) {
          setSuccessLyrics(result.message);
        } else {
          setSuccessLyrics(null);
          setDangerLyrics(result.message);
        }
      } catch (err) {
        var errorMessage = err.response.data.message;
        setSuccessLyrics(null);
        setDangerLyrics(errorMessage);
      }
    }
  };

  const handleClearFile = () => {
    setLyrics(null);
    setSuccessLyrics(null);
    setDangerLyrics(null);
  };

  const steps = [
    {
      label: "Select campaign settings",
      description: (
        <div>
          <div className="rounded-3 mb-4 border-0 theme-bg-color p-4 mt-3">
            <h4 className="theme-text-color">Upload Audio</h4>
            <div className="radio-options row m-0 py-4 justify-content-center ">
              {datadata?.map((option) => (
                <div key={option?.value} className={`col-md-3 col-sm-6 col-12 mb-2 text-md-center text-start  ${selectedOption == option?.value ? "active" : ""}`} >
                  <input type="radio" value={option?.value} checked={selectedOption == option.value} onChange={handleOptionChange} />
                  <label className="ms-1  admin-button-text theme-text-color"> {option.label} </label>
                </div>
              ))}
            </div>
            <div className="active-div-content"> {datadata?.find((option) => option?.value == selectedOption)?.content}</div>
          </div>
          <div onClick={(event) => { setEnablestreamvisible(event.target.value); }} ></div>
        </div>
      ),
    },
    {
      label: "Create an ad group",
      description: (
        <div className=" ">
          <section className="">
            {showOverlay && <ResponseLoader />}
            <AdminSaveHeader heading="Add Audio" saveText="Save Audio" saveMethod={Audio} />
            <div className="row m-0 ">
              <div className="col-12 col-md-6 ps-md-0">
                <div className="rounded-3 mb-4  theme-bg-color p-3">
                  <h6 className="theme-text-color theme-border-bottom-secondary  pb-3">Create Audio</h6>
                  <div className=" text-start">
                    <label className="m-0 theme-text-color">
                      Title<span className="mandatory">*</span>
                    </label>
                    <p className="t-1 theme-text-color">
                      Add the Audio title in the textbox below
                    </p>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="title"
                        name="title"
                        className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                        onChange={handleInput}
                        value={editUser?.title}
                        onBlur={handleBlurtitle}
                        onKeyDown={handleInputKeyDowntitle}
                        placeholder="Title"
                        ref={titleInputRef}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddentitle && (
                        <p>{validationMessagetitle}</p>
                      )}
                    </span>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0 theme-text-color">Slug</label>
                    <p className="t-1 theme-text-color">Add the Audio slug</p>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="slug"
                        name="slug"
                        className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                        value={autoFillValuetitle}
                        onChange={handleAutoFillChangetitle}
                        placeholder="slug"
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddenslug && (
                        <p>{validationMessageslug}</p>
                      )}
                    </span>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0 theme-text-color">
                      Short Description
                    </label>
                    <p className="t-1 theme-text-color">
                      Add a short description of the audio below
                    </p>
                    <div className="mt-2">
                      <textarea
                        className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                        id="description"
                        name="description"
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder="Description"
                      />
                    </div>
                  </div>
                  <div className="row mt-2 text-start">
                    <div className="col-12">
                      <label className="m-0 theme-text-color">
                        Audio Stream Details, Links, and Info
                      </label>
                      <div className="mt-2">
                        <JoditEditor
                          className="rs-input  theme-bg-color-secondary  theme-text-color border-0"
                          value={details}
                          onChange={(newContent) =>
                            contentFieldChanaged(newContent)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2 text-start">
                    <div className="col-12">
                      <label className="m-0 theme-text-color">Duration</label>
                      <p className="t-1 theme-text-color">
                        Enter the audio duration in the following format (Hours
                        : Minutes : Seconds)
                      </p>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="duration"
                          maxLength="8"
                          className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                          onChange={handleInputdata}
                          value={editUser?.duration}
                        // placeholder="duration"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2 text-start">
                    <div className="col-12">
                      <label className="m-0 theme-text-color">Audio Year</label>
                      <p className="t-1 theme-text-color">
                        Audio Released Year
                      </p>
                      <div className="mt-2">
                        <input
                          type="number"
                          maxLength="4"
                          name="year"
                          className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                          value={inputValueyear}
                          onChange={handleChangeyear}
                          placeholder="Year"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-12 ">
                  <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                    <h5 className=" theme-text-color">Organize</h5>
                    <hr className=" theme-text-color"></hr>

                    <div className=" text-start">
                      <label className="m-0 theme-text-color">
                        Audio Ratings
                      </label>
                      <p className="t-1 theme-text-color">
                        IMDb Ratings 10 out of 10
                      </p>
                      <div className="mt-2">
                        <select
                          className="form-select   theme-bg-color-secondary  theme-text-color border-0"
                          id="rating"
                          name="rating"
                          size="lg"
                          onChange={(e) => setRating(e.target.value)}
                        >
                          {/* <option value="0">0</option> */}
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                        </select>
                      </div>
                    </div>
                    <div className="mt-2 text-start">
                      <label className="m-0 theme-text-color">
                        Age Restrict
                      </label>
                      <p className="t-1 theme-text-color">
                        Select a Audio Age Below
                      </p>
                      <div className="mt-2">
                        <select
                          className="form-select   theme-bg-color-secondary  theme-text-color border-0"
                          id="age_restrict"
                          // name="age_restrict"
                          size="lg"
                          onChange={(e) => setAge_restrict(e.target.value)}
                        >
                          <option selected value="0">
                            Choose an Age
                          </option>
                          {age_restrictdata?.map((item) => (
                            <option value={item?.id}>{item?.slug}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="mt-2 text-start">
                      <label className="m-0 theme-text-color">
                        Album<span className="mandatory">*</span>
                      </label>
                      <p className="t-1 theme-text-color">
                        Select A Audio Album Below
                      </p>
                      <div className="mt-2">
                        <select
                          className="form-select   theme-bg-color-secondary  theme-text-color border-0"
                          id="album_id"
                          name="album_id"
                          value={editUser?.album_id}
                          onChange={handleInput}
                          ref={album_idInputRef}
                        // onChange={(e) => setAlbum_id(e.target.value)}
                        >
                          <option value="0">Choose a Album</option>
                          {album?.map((item, key) => (
                            <option value={item?.id}>{item?.albumname}</option>
                          ))}
                        </select>
                      </div>
                      <span className="errorred">
                        {!isValidationHiddenalbum_id && (
                          <p>{validationMessagealbum_id}</p>
                        )}
                      </span>
                    </div>

                    <div className=" text-start mt-2">
                      <label className="m-0 theme-text-color">
                        Cast and Crew
                      </label>
                      <p className="t-1 theme-text-color">
                        Add artists for the audio below
                      </p>

                      <div className="mt-2">
                        <Select
                          options={artists?.map((option) => ({
                            value: option?.id,
                            label: option?.artist_name,
                          }))}
                          className="  theme-bg-color-secondary  theme-text-color"
                          isMulti
                          value={selectedOptionsartists}
                          onChange={handleSelectChangeartists}
                        />
                      </div>
                    </div>
                    <div className=" text-start mt-2">
                      <label className="m-0 theme-text-color">
                        Category<span className="mandatory">*</span>
                      </label>
                      <p className="t-1 theme-text-color">
                        Select a Audio Category Below
                      </p>

                      <div className="mt-2">
                        <Select
                          options={category?.map((option) => ({
                            value: option?.id,
                            label: option?.name,
                          }))}
                          className="  theme-bg-color-secondary  theme-text-color"
                          isMulti
                          value={selectedOptionscategory}
                          onChange={handleSelectChangecategory}
                          ref={categoryInputRef}
                        />
                      </div>
                      <span className="errorred">
                        {!isValidationHiddencategory && (
                          <p>{validationMessagecategory}</p>
                        )}
                      </span>
                    </div>

                    <div className=" text-start mt-2">
                      <label className="m-0 theme-text-color">
                        Language<span className="mandatory">*</span>
                      </label>
                      <p className="t-1 theme-text-color">
                        Select a Audio Language Below
                      </p>
                      <div className="text-dark">
                        <Select
                          options={language?.map((option) => ({
                            value: option?.id,
                            label: option?.name,
                          }))}
                          className="  theme-bg-color-secondary  theme-text-color"
                          isMulti
                          value={selectedOptionslanguage}
                          onChange={handleSelectChangelanguage}
                          ref={languageInputRef}
                        />
                      </div>
                      <span className="errorred">
                        {!isValidationHiddenlanguage && (
                          <p>{validationMessagelanguage}</p>
                        )}
                      </span>
                    </div>

                    <div className=" text-start mt-2">
                      <label className="m-0 theme-text-color">Country</label>
                      <p className="t-1 theme-text-color">
                        Block the Audio for Selected Country
                      </p>
                      <div className="text-dark">
                        <Select
                          options={block_country?.map((option) => ({
                            value: option?.id,
                            label: option?.country_name,
                          }))}
                          className="  theme-bg-color-secondary  theme-text-color"
                          isMulti
                          value={selectedOptions}
                          onChange={handleSelectChange}
                        />
                      </div>
                    </div>

                    <div className=" text-start mt-2">
                      <label className="m-0 theme-text-color">
                        Available Country
                      </label>
                      <p className="t-1 theme-text-color">
                        Block the Audio for Selected Country
                      </p>
                      <div className="text-dark">
                        <Select
                          options={available_country?.map((option) => ({
                            value: option?.id,
                            label: option?.country_name,
                          }))}
                          className="  theme-bg-color-secondary  theme-text-color"
                          isMulti
                          value={selectedOptionsAvailable_country}
                          onChange={handleSelectChangeAvailable_country}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="text-start mb-3">
                  <button onClick={Audio} className="btn btn-primary">
                    Save Audio
                  </button>
                </div>
              </div>

              {/* Second Part of Section  */}

              <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                  <h5 className=" theme-text-color">Audio Lyrics</h5>
                  <hr className=" theme-text-color"></hr>

                  <label className="m-0 theme-text-color">
                    Upload Audio Lyrics
                  </label>
                  <p className="t-1 my-2 theme-text-color">
                    (Ex:xlsx{" "}
                    <a
                      className="fw-bold text-info theme-text-color"
                      href={getExampleUrl}
                      download
                    >
                      Sample Lyrics File
                    </a>
                    )
                  </p>
                  <div className="d-flex flex-wrap justify-content-between gap-2">
                    <div className="col-sm-5 col-md-12 col-lg-5 col-12 position-relative pb-2">
                      <div className="imagedrop  mt-1 p-1">
                        <input
                          className="opacity-0 h-100 p-0 m-0 "
                          type="file"
                          accept=".xlsx"
                          ref={AudioRef}
                          onChange={handleLyrics}
                          key={getLyrics ? getLyrics : "no"}
                        />
                        <label
                          type="button"
                          className="position-absolute theme-text-color"
                        >
                          <BsFiletypeXlsx className=" theme-text-color" />
                        </label>
                      </div>
                    </div>
                    {(getSuccessLyrics || getDangerLyrics) && (
                      <div className="col-sm-5 col-12 col-md-12 col-lg-5">
                        <div className="imagedrop d-flex mt-1 p-1 justify-content-between">
                          <button type="button" className="w-75 h-100">
                            <FcFile />
                          </button>
                          <button
                            type="button"
                            className="btn btn-close"
                            onClick={handleClearFile}
                          ></button>
                        </div>
                      </div>
                    )}
                  </div>
                  {getSuccessLyrics && (
                    <p className="fw-bold text-success mt-2 theme-text-color">
                      {" "}
                      <FcOk className="me-1 theme-text-color" />
                      {getSuccessLyrics}{" "}
                    </p>
                  )}
                  {getDangerLyrics && (
                    <p className="fw-bold text-danger mt-2 theme-text-color">
                      <FcHighPriority className="me-1 theme-text-color" />
                      {getDangerLyrics}
                    </p>
                  )}
                </div>
                <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                  <h5 className=" theme-text-color">Thumbnails</h5>
                  <hr className=" theme-text-color"></hr>

                  <div className=" text-start mt-2">
                    <label className="m-0 theme-text-color">
                      Audio Image Cover
                    </label>
                    <p className="t-1 theme-text-color">
                      Select the audio image ( 9:16 Ratio or 1080X1920px )
                    </p>
                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef1.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef1}
                              onChange={(event) =>
                                handleFileChangesss(
                                  event,
                                  fileInputRef1,
                                  setSelectedFile1
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label className=" theme-text-color">
                              <CameraRetroIcon className=" theme-text-color" />
                            </label>
                          </div>
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div>
                            {selectedFile1 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL.createObjectURL(selectedFile1)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() =>
                                        handleDelete(setSelectedFile1)
                                      }
                                      type="button"
                                      className="btn-close theme-text-color"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0 theme-text-color">
                      Player Image Cover
                    </label>
                    <p className="t-1 theme-text-color">
                      Select the audio image ( 16:9 Ratio or 1280X720px )
                    </p>
                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef2.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef2}
                              onChange={(event) =>
                                handleFileChangesss(
                                  event,
                                  fileInputRef2,
                                  setSelectedFile2
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label className=" theme-text-color">
                              <CameraRetroIcon className=" theme-text-color" />
                            </label>
                          </div>
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div>
                            {selectedFile2 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL.createObjectURL(selectedFile2)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() =>
                                        handleDelete(setSelectedFile2)
                                      }
                                      type="button"
                                      className="btn-close theme-text-color"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0 theme-text-color">
                      TV Thumbnail Image Cover
                    </label>
                    <p className="t-1 theme-text-color">
                      Select the audio image ( 16:9 Ratio or 1280X720px )
                    </p>
                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef3.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef3}
                              onChange={(event) =>
                                handleFileChangesss(
                                  event,
                                  fileInputRef3,
                                  setSelectedFile3
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label className=" theme-text-color">
                              <CameraRetroIcon className=" theme-text-color" />
                            </label>
                          </div>
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div>
                            {selectedFile3 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL.createObjectURL(selectedFile3)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() =>
                                        handleDelete(setSelectedFile3)
                                      }
                                      type="button"
                                      className="btn-close theme-text-color"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                    <h5 className=" theme-text-color">Access</h5>
                    <hr className=" theme-text-color"></hr>

                    <div className="col-sm-12">
                      <label className="m-0 theme-text-color">
                        User Access  <span className="mandatory">*</span>
                      </label>
                      <p className="p1 theme-text-color">
                        Who Is Allowed To View This audio ?
                      </p>
                      <select
                        onChange={(e) => handleAccess(e.target.value)}
                        className="form-select   theme-bg-color-secondary  theme-text-color border-0"
                        id="access"
                        name="access"
                        onClick={(event) => {
                          setallliveuseraccess(event.target.value);
                        }}
                        ref={accessInputRef}
                      >
                        {/* <option value={item.role}>{item.name}</option> */}
                        {user_access?.map((item, key) => (
                          <option value={item?.role} >{item?.name}</option>
                        ))}
                      </select>
                      <span className="errorred mt-2 d-block">
                        {!isValidationHiddenAccess && (
                          <p>{validationMessageAccess}</p>
                        )}
                      </span>
                    </div>

                    <div>{allaudiouseraccess()}</div>
                  </div>

                  <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                    <h5 className=" theme-text-color">Status Settings</h5>
                    <hr className=" theme-text-color"></hr>

                    <div className="col-sm-12 row">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <p className="p2 theme-text-color">
                          Is this Audio Featured
                        </p>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3">
                        <label className="switch">
                          <input
                            name="featured"
                            onChange={handleInput}
                            className="rs-input"
                            defaultChecked={
                              featured?.featured == 1 ? true : false
                            }
                            checked={featured?.featured == 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="featured"
                            onChange={handleInput}
                            value={featured?.featured == 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div>

                    <div className="col-sm-12 row mt-2">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <p className="p2 theme-text-color">
                          Is this Audio Active
                        </p>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3">
                        <label className="switch">
                          <input
                            name="active"
                            onChange={handleInput}
                            defaultChecked={active?.active == 1 ? true : false}
                            checked={active?.active == 1 ? true : false}
                            className="rs-input"
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="active"
                            onChange={handleInput}
                            value={active?.active == 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-12 row mt-2">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <p className="p2 theme-text-color">
                          Is this Audio display in Banner
                        </p>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3">
                        <label className="switch">
                          <input
                            name="banner"
                            onChange={handleInput}
                            className="r-input"
                            defaultChecked={banner?.banner == 1 ? true : false}
                            checked={banner?.banner == 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="banner"
                            onChange={handleInput}
                            value={banner?.banner == 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                    <h5 className=" theme-text-color">Search Tags</h5>
                    <hr className=" theme-text-color"></hr>

                    <div className="col-sm-12">
                      <p className="p2 theme-text-color">
                        You don't have any search keywords
                      </p>

                      <div className="mt-2">
                        <TagsInput
                          value={searchTags}
                          className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                          onChange={handleTagsChange}
                          name="search_tags"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                    <h5 className=" theme-text-color">SEO</h5>
                    <hr className=" theme-text-color"></hr>

                    <div className="col-sm-12">
                      <label className="m-0 theme-text-color">
                        Website Page Title
                      </label>

                      <div className=" mt-2">
                        <input
                          type="text"
                          className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                          onChange={(e) => setWebsitetitle(e.target.value)}
                        // placeholder="websitetitle"
                        />
                      </div>
                    </div>

                    <div className="col-sm-12 mt-2">
                      <label className="m-0 theme-text-color">
                        Website URL
                      </label>

                      <div className=" mt-2">
                        <input
                          type="text"
                          className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                          onChange={(e) => setWebsiteurl(e.target.value)}
                        // placeholder="websitetitle"
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 mt-2">
                      <label className="m-0 theme-text-color">
                        Meta Description
                      </label>

                      <div className=" mt-2">
                        <textarea
                          type="text"
                          className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                          onChange={(e) => setMetadescription(e.target.value)}
                        // placeholder="metadescription"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      ),
    },
  ];

  return (
    <div className="addaudio">
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps?.map((step, index) => (
          <Step key={step?.label}>
            <StepContent>
              <Typography>{step?.description}</Typography>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}
