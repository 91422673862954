// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.livestreamsourceerror{
  color: red;
}
.accordionImage{
  width: 25px;
}

#accordionSocialExample .accordion-button:focus
{
  box-shadow: none;
}
#accordionSocialExample button[aria-expanded="true"] {
  background-color: transparent;
}`, "",{"version":3,"sources":["webpack://./src/channelportal/Pages/ManageLiveVideo/EditNewLiveVideo.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;AACA;EACE,WAAW;AACb;;AAEA;;EAEE,gBAAgB;AAClB;AACA;EACE,6BAA6B;AAC/B","sourcesContent":[".livestreamsourceerror{\n  color: red;\n}\n.accordionImage{\n  width: 25px;\n}\n\n#accordionSocialExample .accordion-button:focus\n{\n  box-shadow: none;\n}\n#accordionSocialExample button[aria-expanded=\"true\"] {\n  background-color: transparent;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
