// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*===============
    404 page
================*/

.page_4044 {
  padding: 80px 0;
  background-color: #3d3a2d3b;
  height: 100vh;
}`, "",{"version":3,"sources":["webpack://./src/Error404/Maintances.css"],"names":[],"mappings":"AAAA;;iBAEiB;;AAEjB;EACE,eAAe;EACf,2BAA2B;EAC3B,aAAa;AACf","sourcesContent":["/*===============\n    404 page\n================*/\n\n.page_4044 {\n  padding: 80px 0;\n  background-color: #3d3a2d3b;\n  height: 100vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
