// // External Libraries and Packages
// import React, { useState } from "react";
// import StripeCheckout from "react-stripe-checkout";
// import { toast } from "react-toastify";

// // Internal Styles
// import "./Dashboard.css";


// // Internal Components
// import loadings from "../../components/Images/subscribe/test.png";

// const DashBoards = () => {
//   const [product, setProduct] = useState({
//     name: "Rent Video Pay",
//     price: 2000,
//     id: "1",
//     src: loadings,
//     product_by: "Video",
//     currency: "INR",
//     currency_symbol: "$",
//     stripeKey: "pk_test_51M4ja8SJZVJvQCKRasiMCPmmb0vjs31HU2Brf1RUKxr4ZHzjOkngy4FdbvLZX0ap0rwU1S7w0X6CFgAEnlEbjT9F00xE9neHhR",
//   });
//   const access_token = localStorage.getItem("access_token");
//   const handleToken = (token) => {
//     const body = {
//       token,
//       product,
//       name: product.name,
//       id: product.id,
//       price: product.price,
//       product_by: product.product_by,
//       email: token.email,
//       token: token.id,
//     };

//     const headers = {
//       "Content-Type": "application/json",
//       Authorization: "Bearer " + access_token,
//       Accept: "application/json",
//       "Content-Type": "application/json",
//       "Access-Control-Allow-Headers":
//         "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token",
//       "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
//       "Access-Control-Allow-Credentials": true,
//       "Access-Control-Allow-Origin": "*",
//       "X-Requested-With": "*",
//     };

//     fetch(`${process.env.REACT_APP_Baseurl}/admin/storessssssas`, {method: "POST",headers: headers,body: JSON.stringify(body)}).then((resp) => {
//         if (!resp.ok) {throw new Error("API request failed"); } return resp.json(); })
//       .then((data) => {
//         toast("Payment Updated Successfully")
//         setTimeout(() => { window.location.reload(); }, 3500);
//       })
//       .catch((err) => {
//         toast.error("Payment Update Failed")
//         setTimeout(() => { window.location.reload(); }, 3500);
//       });
//   };
//   return (
//     <section className="rent_payment_setting">
//       <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop" > Purchase Now</button>
//       <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static"
//         data-bs-keyboard="false"
//         tabindex="-1"
//         aria-labelledby="staticBackdropLabel"
//         aria-hidden="true"
//       >
//         <div className="modal-dialog">
//           <div className="modal-content">
//             <div className="modal-header">
//               <h4 className="modal-title" id="staticBackdropLabel">
//                 {product?.name}
//               </h4>
//               <button
//                 type="button"
//                 className="btn-close"
//                 data-bs-dismiss="modal"
//                 aria-label="Close"
//               ></button>
//             </div>
//             <div className="modal-body">
//               <div className="col-lg-12 row">
//                 <div className="col-lg-5 p-0">
//                   <img className="w-100" src={product?.src} />
//                 </div>
//                 <div className="col-lg-7">
//                   <div>
//                     <div>
//                       <h3>{product?.name}</h3>
//                     </div>
//                     <div>
//                       <p>
//                         Amount {product?.currency_symbol} {product?.price}
//                       </p>
//                     </div>
//                   </div>
//                   <div className="text-start mt-5">
//                     <div>
//                       <h4>Payment Method</h4>
//                     </div>
//                     <div>
//                       <p className="rent_policsh">
//                         POLICIES: Pay with your only this video to show
//                       </p>
//                     </div>
//                   </div>
//                   <div className="d-flex align-items-start mt-3">
//                     <div
//                       className="nav flex-column nav-pills me-3"
//                       id="v-pills-tab"
//                       role="tablist"
//                       aria-orientation="vertical"
//                     >
//                       <div className="d-flex">
//                         <input
//                           type="radio"
//                           className="nav-link"
//                           id="v-pills-home-tab"
//                           name="v-pills-tab"
//                           data-bs-toggle="pill"
//                           data-bs-target="#v-pills-home"
//                         />
//                         <label htmlFor="v-pills-home-tab" className="nav-link">
//                           Pay with Credit or Debit Card
//                         </label>
//                       </div>

//                       <div className="d-flex">
//                         <input
//                           type="radio"
//                           className="nav-link"
//                           id="v-pills-profile-tab"
//                           name="v-pills-tab"
//                           data-bs-toggle="pill"
//                           data-bs-target="#v-pills-profile"
//                         />
//                         <label
//                           htmlFor="v-pills-profile-tab"
//                           className="nav-link"
//                         >
//                           Pay with Paypal
//                         </label>
//                       </div>

//                       <div className="d-flex">
//                         <input
//                           type="radio"
//                           className="nav-link"
//                           id="v-pills-messages-tab"
//                           name="v-pills-tab"
//                           data-bs-toggle="pill"
//                           data-bs-target="#v-pills-messages"
//                         />
//                         <label
//                           htmlFor="v-pills-messages-tab"
//                           className="nav-link"
//                         >
//                           Paystack
//                         </label>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="modal-footer">
//               <div className="tab-content" id="v-pills-tabContent">
//                 <div
//                   className="tab-pane fade"
//                   id="v-pills-home"
//                   role="tabpanel"
//                   aria-labelledby="v-pills-home-tab"
//                 >
//                   <StripeCheckout
//                     name={product?.name}
//                     amount={product?.price * 100}
//                     currency={product?.currency}
//                     token={handleToken}
//                     stripeKey={product?.stripeKey}
//                   >
//                     <button className="btn btn-primary">
//                       Continue to Purchase
//                     </button>
//                   </StripeCheckout>
//                 </div>
//                 <div
//                   className="tab-pane fade"
//                   id="v-pills-profile"
//                   role="tabpanel"
//                   aria-labelledby="v-pills-profile-tab"
//                 >
//                   <button> Continue to Purchase</button>
//                 </div>
//                 <div
//                   className="tab-pane fade"
//                   id="v-pills-messages"
//                   role="tabpanel"
//                   aria-labelledby="v-pills-messages-tab"
//                 >
//                   <button> Continue to Purchase</button>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default DashBoards;

// import React, { useState, useRef, useEffect } from "react";
// import axios from "axios";
// import "../Dashboard/Dashboard.css";
// import { Link } from "react-router-dom";
// import sunnxt from "../../components/Images/Payment_image/sunnxt.png";
// import zee5 from "../../components/Images/Payment_image/zee5.png";
// import sonylive from "../../components/Images/Payment_image/sonylive.png";
// import fuseplus from "../../components/Images/Payment_image/fuseplus.png";
// import amazonprime from "../../components/Images/Payment_image/amazonprime.png";
// import { AiOutlineCalendar } from "react-icons/ai";
// import { Button, ButtonToolbar } from "rsuite";

// function Dashboard() {
//   const Channel_Partner_id = localStorage.getItem("Channel_Partner_id");

//   const access_token = localStorage.getItem("access_token");
//   const headers = {
//     "Content-Type": "application/json",
//     Authorization: "Bearer " + access_token,
//     "Content-Type": "application/json",
//     Accept: "application/json",
//     "Access-Control-Allow-Origin": "*",
//   };

//   const [stripeids, setStripeids] = useState([]);
//   const [paymentGateways, setPaymentGateways] = useState([]);

//   useEffect(() => {
//     fetchOptions();
//     fetchPaymentGateways();
//   }, []);

//   const fetchOptions = async () => {
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_Baseurl}/Front-End/Stripe-Subscription-Plans`,
//         { headers: headers }
//       );
//       const StripeSubscriptionPlans = response?.data?.Stripe_Subscription_Plans;
//       setStripeids(StripeSubscriptionPlans);

//       // console.log(StripeSubscriptionPlans);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   const [activeGateway, setActiveGateway] = useState(null); // Initialize activeGateway as null

//   const fetchPaymentGateways = async () => {
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_Baseurl}/Front-End/Payment-Gateway-list`,
//         { headers: headers }
//       );
//       const paymentGateways = response?.data?.Payment_Gateways;
//       setPaymentGateways(paymentGateways);
//       // Set the active gateway to the first one by default
//       setActiveGateway(paymentGateways[0]?.Payment_Gateway_name || null);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   const handleGatewayClick = (gatewayName) => {
//     setActiveGateway(gatewayName);
//   };
//   // const fetchOptionstwo = async () => {
//   //   try {
//   //     const response = await axios.get(
//   //       `${process.env.REACT_APP_Baseurl}/Front-End/Payment-Gateway-list`,
//   //       { headers: headers }
//   //     );
//   //     const PaymentGateways = response?.data?.Payment_Gateways;
//   //     setPaymentGateways(PaymentGateways);

//   //   } catch (error) {
//   //     console.error(error);
//   //   }
//   // };

//   const stripeid = [
//     {
//       id: 1,
//       src: amazonprime,
//       price: 93,
//       title: "Basic Plan",
//     },
//     {
//       id: 2,
//       src: fuseplus,
//       price: 355,
//       title: "Pro Plan",
//     },
//     {
//       id: 3,
//       src: sunnxt,
//       price: 213,
//       title: "Bussiness Plan",
//     },
//     {
//       id: 4,
//       src: sonylive,
//       price: 990,
//       title: "Super Plan",
//     },
//     {
//       id: 5,
//       src: zee5,
//       price: 5000,
//       title: "Premium Plan",
//     },
//   ];

//   // const checkout = (plan) => {
//   //   fetch(`${process.env.REACT_APP_Baseurl}/userstripe/payment/api`, {
//   //     method: "POST",
//   //     headers: {
//   //       "Content-Type": "application/json",
//   //     },
//   //     body: JSON.stringify({ plan: plan, stripeid: Channel_Partner_id }),
//   //   })
//   //     .then((response) => {
//   //       if (response.ok) return response.json();
//   //       console.log(response);
//   //       return response.json().then((json) => Promise.reject(json));
//   //     })
//   //     .then(({ session }) => {
//   //       window.location = session.url;
//   //     })
//   //     .catch((e) => {
//   //       console.log(e.error);
//   //     });
//   // };

//   // const checkout = (plan_id) => {
//   //   fetch(`${process.env.REACT_APP_Baseurl}/userstripe/payment/api`, {
//   //     method: "POST",
//   //     headers: {
//   //       "Content-Type": "application/json",
//   //     },
//   //     body: JSON.stringify({ plan_id: plan_id, user_id: user_id }),
//   //   })
//   //     .then((response) => {
//   //       if (response?.ok) return response?.json();
//   //       console.log(response);
//   //       return response?.json().then((json) => Promise.reject(json));
//   //     })
//   //     .then(({ session }) => {
//   //       if (session?.status == true) {
//   //         // Send data to another API
//   //         fetch(`${process.env.REACT_APP_Baseurl}/another/api`, {
//   //           method: "POST",
//   //           headers: {
//   //             "Content-Type": "application/json",
//   //           },
//   //           body: JSON.stringify({ id: session?.id }), // Change this as needed
//   //         })
//   //           .then((response) => {
//   //             if (response?.ok) return response?.json();
//   //             console.log(response);
//   //             return response?.json().then((json) => Promise.reject(json));
//   //           })
//   //           .then((data) => {
//   //             // Handle the response from the second API call
//   //             console.log("Data from another API:", data);
//   //           })
//   //           .catch((e) => {
//   //             console.log("Error from another API:", e.error);
//   //           });

//   //         window.location = session.url;
//   //       }
//   //     })
//   //     .catch((e) => {
//   //       console.log(e.error);
//   //     });
//   // };

//   // const checkout = (user_id, plan_id) => {
//   //   // Construct the data to send to your API
//   //   const requestData = {
//   //     user_id,
//   //     plan_id,
//   //     quantity: 1,
//   //     // Include other data from the stripeids array that you need to send
//   //     // For example, if you want to send the price from the stripeids array, you can do:
//   //     // price: stripeids.find(tab => tab.plan_id == plan_id)?.price,
//   //   };

//   //   fetch(`${process.env.REACT_APP_Baseurl}/Front-End/Stripe-Payment-Link`, {
//   //     method: "POST",
//   //     headers: {
//   //       "Content-Type": "application/json",
//   //     },
//   //     body: JSON.stringify(requestData),
//   //   })
//   //     .then((response) => {
//   //       if (response.ok) return response.json();
//   //       // console.log(response);
//   //       return response.json().then((json) => Promise.reject(json));
//   //     })
//   //     .then(({ session }) => {
//   //       if (session.status == true) {
//   //         // Send data to another API
//   //         fetch(`${process.env.REACT_APP_Baseurl}/another/api`, {
//   //           method: "POST",
//   //           headers: {
//   //             "Content-Type": "application/json",
//   //           },
//   //           body: JSON.stringify({ id: session.id }),
//   //         })
//   //           .then((response) => {
//   //             if (response.ok) return response.json();
//   //             console.log(response);
//   //             return response.json().then((json) => Promise.reject(json));
//   //           })
//   //           .then((data) => {
//   //             // Handle the response from the second API call
//   //             console.log("Data from another API:", data);
//   //           })
//   //           .catch((e) => {
//   //             console.log("Error from another API:", e.error);
//   //           });

//   //         window.location = session.url;
//   //       }
//   //     })
//   //     .catch((e) => {
//   //       console.log(e.error);
//   //     });
//   // };

//   const checkout = (user_id, plan_id) => {
//     const requestData = {
//       user_id,
//       plan_id,
//       quantity: 1,
//     };

//     fetch(`${process.env.REACT_APP_Baseurl}/Front-End/Stripe-Payment-Link`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify(requestData),
//     })
//       .then((response) => {
//         if (response.ok) return response.json();
//         return response.json().then((json) => Promise.reject(json));
//       })
//       .then((response) => {
//         if (response?.status == true) {
//           const paymentLinkURL = response?.Payment_Link_URL;
//           // console.log(paymentLinkURL , "paymentLinkURL")

//           // Open a new tab or window with the payment link URL
//           const newTab = window.open(paymentLinkURL, "_self");
//           if (newTab) {
//             newTab.focus(); // Focus on the new tab if it was successfully opened
//           } else {
//             // Handle cases where the browser blocked the popup
//             console.error("Popup blocked by the browser");
//             // You can provide a message to the user or take other actions here
//           }
//         } else {
//           console.error("Payment session status is not true");
//         }
//       })
//       .catch((e) => {
//         console.error(e.error);
//       });
//   };

//   const staticContent = {
//     Stripe: (
//       <>
//         <div>
//           <ul className="nav nav-pills mb-3 justify-content-center payment_stripe_css">
//             {stripeids?.map((tab, index) => (
//               <li
//                 className="nav-item align-items-center ms-2 payment_with_css"
//                 role="presentation"
//                 key={index}
//               >
//                 <div className="row col-lg-12">
//                   <div className="col-7 col-sm-7 col-md-7 col-lg-7 power_play_header_css mt-4">
//                     <label className="power_play" htmlFor={tab?.id + "-tab"}>
//                       {tab?.plans_name}
//                     </label>
//                     <p
//                       className="power_play_OTTs mt-2"
//                       htmlFor={tab?.id + "-tab"}
//                       dangerouslySetInnerHTML={{ __html: tab?.plan_content }}
//                       style={{ maxWidth: "100%" }}
//                     ></p>
//                     {/* <p
//                       className="power_play_OTTs mt-2"
//                       htmlFor={tab.id + "-tab"}
//                       dangerouslySetInnerHTML={{ __html: tab.content.plan_content }}
//                     >
//                       {tab.plan_content}
//                     </p> */}
//                     <p className="power_play_price mt-4">
//                       $ {tab?.price} per yr
//                     </p>
//                     <p className="power_play_price_save m-0">
//                       Save up to <span>$ {tab?.price}</span>
//                     </p>
//                   </div>
//                   <div className="col-5 col-sm-5 col-md-5 col-lg-5">
//                     <div className="row col-lg-12">
//                       <div className="col-6 col-sm-6 col-md-6 col-lg-6">
//                         <div className="free_video_plans_image">
//                           <img
//                             src={zee5}
//                             className="w-100 h-100"
//                             alt={tab?.plans_name}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-6 col-sm-6 col-md-6 col-lg-6">
//                         <div className="free_video_plans_image">
//                           <img
//                             src={sunnxt}
//                             className="w-100 h-100"
//                             alt={tab?.plans_name}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-6 col-sm-6 col-md-6 col-lg-6">
//                         <div className="free_video_plans_image">
//                           <img
//                             src={fuseplus}
//                             className="w-100 h-100"
//                             alt={tab?.plans_name}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-6 col-sm-6 col-md-6 col-lg-6">
//                         <div className="free_video_plans_image">
//                           <img
//                             src={amazonprime}
//                             className="w-100 h-100"
//                             alt={tab?.plans_name}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-6 col-sm-6 col-md-6 col-lg-6">
//                         <div className="free_video_plans_image">
//                           <img
//                             src={sonylive}
//                             className="w-100 h-100"
//                             alt={tab?.plans_name}
//                           />
//                         </div>
//                       </div>
//                       {/* Add more image elements for other images */}
//                     </div>
//                   </div>
//                 </div>

//                 <div className="row justify-content-evenly free_video_plans_button">
//                   <div className="col-5 col-sm-5 col-md-5 col-lg-5"></div>
//                   <div className="col-5 col-sm-5 col-md-5 col-lg-5">
//                     <div className="">
//                       <button
//                         className="btn btn-primary"
//                         onClick={() => checkout(tab?.user_id, tab?.plan_id)}
//                       >
//                         Start
//                       </button>
//                     </div>
//                   </div>
//                 </div>
//               </li>
//             ))}
//           </ul>

//           {/* <ul
//             className="nav nav-pills mb-3 justify-content-center payment_stripe_css"
//             id="pills-tab"
//             role="tablist"
//           >
//             {stripeids?.map((tab, index) => (
//               <li
//                 className="nav-item align-items-center ms-2 payment_with_css"
//                 role="presentation"
//                 key={index}
//               >
//                 <div className="row col-lg-12">
//                   <div className="col-7 col-sm-7 col-md-7 col-lg-7 power_play_header_css mt-4">
//                     <label className="power_play" htmlFor={tab?.id + "-tab"}>
//                       {tab?.plans_name}
//                     </label>

//                     <p
//                       className="power_play_OTTs mt-2"
//                       htmlFor={tab?.id + "-tab"}
//                     >
//                       {tab?.plans_name}
//                     </p>
//                     <p className="power_play_price mt-4">
//                       $ {tab?.price} per yr
//                     </p>
//                     <p className="power_play_price_save m-0">
//                       Save upto <span>$ {tab?.price}</span>
//                     </p>
//                   </div>
//                   <div className="col-5 col-sm-5 col-md-5 col-lg-5">
//                     <div className="row col-lg-12">
//                       <div className="col-6 col-sm-6 col-md-6 col-lg-6">
//                         <div className="free_video_plans_image">
//                           <img src={tab?.src} className="w-100" />
//                         </div>
//                       </div>
//                       <div className="col-6 col-sm-6 col-md-6 col-lg-6">
//                         <div className="free_video_plans_image">
//                           <img src={tab?.src} className="w-100" />
//                         </div>
//                       </div>
//                       <div className="col-6 col-sm-6 col-md-6 col-lg-6">
//                         <div className="free_video_plans_image">
//                           <img src={tab?.src} className="w-100" />
//                         </div>
//                       </div>
//                       <div className="col-6 col-sm-6 col-md-6 col-lg-6">
//                         <div className="free_video_plans_image">
//                           <img src={tab?.src} className="w-100" />
//                         </div>
//                       </div>
//                       <div className="col-6 col-sm-6 col-md-6 col-lg-6">
//                         <div className="free_video_plans_image">
//                           <img src={tab?.src} className="w-100" />
//                         </div>
//                       </div>
//                       <div className="col-6 col-sm-6 col-md-6 col-lg-6">
//                         <div className="free_video_plans_image">
//                           <img src={tab?.src} className="w-100" />
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>

//                 <div className="row justify-content-evenly free_video_plans_button">
//                   <div className="col-5 col-sm-5 col-md-5 col-lg-5"></div>
//                   <div className="col-5 col-sm-5 col-md-5 col-lg-5">
//                     <div className="">
//                       <button
//                         className="btn btn-primary"
//                         onClick={() => checkout(Number(tab?.price))}
//                       >
//                         Start
//                       </button>
//                     </div>
//                   </div>
//                 </div>
//               </li>
//             ))}
//           </ul> */}

//           {/* {stripeid?.map((item, index) => (
//     <div className="col-lg-12 row" key={index}>
//       <div className="col-lg-12">
//         <label>{item?.title}</label>
//       </div>
//       <div className="col-lg-4">
//         <button onClick={() => checkout(Number(item?.price))}>
//           {item?.price}
//         </button>
//       </div>
//     </div>
//   ))} */}
//         </div>
//       </>
//     ),
//     PayPal: (
//       <>
//         <div>
//           <ul
//             className="nav nav-pills mb-3 justify-content-center payment_stripe_css"
//             id="pills-tab"
//             role="tablist"
//           >
//             {stripeid?.map((tab, index) => (
//               <li
//                 className="nav-item align-items-center ms-2 payment_with_css"
//                 role="presentation"
//                 key={index}
//               >
//                 <div className="row col-lg-12">
//                   <div className="col-7 col-sm-7 col-md-7 col-lg-7 power_play_header_css mt-4">
//                     <label className="power_play" htmlFor={tab?.id + "-tab"}>
//                       {tab?.title}
//                     </label>

//                     <p
//                       className="power_play_OTTs mt-2"
//                       htmlFor={tab?.id + "-tab"}
//                     >
//                       {tab?.title}
//                     </p>
//                     <p className="power_play_price mt-4">
//                       $ {tab?.price} per yr
//                     </p>
//                     <p className="power_play_price_save m-0">
//                       Save upto <span>$ {tab?.price}</span>
//                     </p>
//                   </div>
//                   <div className="col-5 col-sm-5 col-md-5 col-lg-5">
//                     <div className="row col-lg-12">
//                       <div className="col-6 col-sm-6 col-md-6 col-lg-6">
//                         <div className="free_video_plans_image">
//                           <img src={tab?.src} className="w-100" />
//                         </div>
//                       </div>
//                       <div className="col-6 col-sm-6 col-md-6 col-lg-6">
//                         <div className="free_video_plans_image">
//                           <img src={tab?.src} className="w-100" />
//                         </div>
//                       </div>
//                       <div className="col-6 col-sm-6 col-md-6 col-lg-6">
//                         <div className="free_video_plans_image">
//                           <img src={tab?.src} className="w-100" />
//                         </div>
//                       </div>
//                       <div className="col-6 col-sm-6 col-md-6 col-lg-6">
//                         <div className="free_video_plans_image">
//                           <img src={tab?.src} className="w-100" />
//                         </div>
//                       </div>
//                       <div className="col-6 col-sm-6 col-md-6 col-lg-6">
//                         <div className="free_video_plans_image">
//                           <img src={tab?.src} className="w-100" />
//                         </div>
//                       </div>
//                       <div className="col-6 col-sm-6 col-md-6 col-lg-6">
//                         <div className="free_video_plans_image">
//                           <img src={tab?.src} className="w-100" />
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>

//                 <div className="row justify-content-evenly free_video_plans_button">
//                   <div className="col-5 col-sm-5 col-md-5 col-lg-5"></div>
//                   <div className="col-5 col-sm-5 col-md-5 col-lg-5">
//                     <div className="">
//                       <button
//                         className="btn btn-primary"
//                         onClick={() => checkout(Number(tab?.price))}
//                       >
//                         Start
//                       </button>
//                     </div>
//                   </div>
//                 </div>

//                 {/* <div className="text-center">
//                     <img src={tab?.src} className="w-50" />
//                   </div> */}
//                 {/* <div className="text-center mt-2">
//                   <label htmlFor={tab?.id + "-tab"}>{tab?.title}</label>
//                 </div> */}

//                 {/* <div className="text-center mt-2">
//                   <button
//                     className="btn btn-primary"
//                     onClick={() => checkout(Number(tab?.price))}
//                   >
//                     Price {tab?.price}
//                   </button>
//                 </div> */}
//               </li>
//             ))}
//           </ul>

//           {/* {stripeid?.map((item, index) => (
//     <div className="col-lg-12 row" key={index}>
//       <div className="col-lg-12">
//         <label>{item?.title}</label>
//       </div>
//       <div className="col-lg-4">
//         <button onClick={() => checkout(Number(item?.price))}>
//           {item?.price}
//         </button>
//       </div>
//     </div>
//   ))} */}
//         </div>
//       </>
//     ),
//     Razorpay: (
//       <>
//         <div>Razorpay welcomes</div>
//       </>
//     ),
//   };

//   // const staticContent = {
//   //   Stripe: (
//   //     <>
//   //       <div>
//   //         {stripeid?.map((item, index) => (
//   //           <div className="col-lg-12 row" key={index}>
//   //             <label>{item?.title}</label>
//   //             <div className="col-lg-4">
//   //               <button onClick={() => checkout(Number(item?.price))}>
//   //                 {item?.price}
//   //               </button>
//   //             </div>
//   //           </div>
//   //         ))}
//   //       </div>
//   //     </>
//   //   ),
//   //   COD: "Static content for COD goes here.",
//   //   Paypal: "Static content for Paypal goes here.",
//   // };

//   return (
//     <section className="container">
//       <div className="row">
//         <div className="col-6 col-md-6 col-lg-6 offset-md-4">
//           <ul className="nav nav-pills p-3" id="pills-tab" role="tablist">
//             {paymentGateways?.map((gateway, index) => (
//               <li
//                 className="nav-item d-flex align-items-center ms-2"
//                 role="presentation"
//                 key={index}
//               >
//                 <input
//                   type="radio"
//                   className="nav-link"
//                   id={`pills-${gateway?.Payment_Gateway_name}-tab`}
//                   name="tab-radio"
//                   value={gateway?.Payment_Gateway_name}
//                   checked={activeGateway == gateway?.Payment_Gateway_name}
//                   onChange={() =>
//                     handleGatewayClick(gateway?.Payment_Gateway_name)
//                   }
//                 />
//                 <label
//                   className="ms-2"
//                   htmlFor={`pills-${gateway?.Payment_Gateway_name}-tab`}
//                 >
//                   {gateway?.Payment_Gateway_name}
//                 </label>
//               </li>
//             ))}
//           </ul>
//         </div>
//       </div>

//       <div className="row">
//         <div className="col-12 col-md-12 col-lg-12 offset-md-0">
//           <div className="tab-content text-start" id="pills-tabContent">
//             {paymentGateways?.map((gateway, index) => (
//               <div
//                 className={`tab-pane ${
//                   activeGateway == gateway?.Payment_Gateway_name
//                     ? "show active"
//                     : "fade"
//                 }`}
//                 id={`pills-${gateway?.Payment_Gateway_name}`}
//                 role="tabpanel"
//                 key={index}
//               >
//                 <p>{staticContent[gateway?.Payment_Gateway_name]}</p>
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>
//     </section>

//     // <section className="container">
//     //   <div className="row">
//     //     <div className="col-6 col-md-6 col-lg-6 offset-md-4">
//     //       <ul className="nav nav-pills p-3 " id="pills-tab" role="tablist">
//     //         {Listoftabheading?.map((tab, index) => (
//     //           <li
//     //             className="nav-item d-flex align-items-center ms-2"
//     //             role="presentation"
//     //             key={index}
//     //           >
//     //             <input
//     //               type="radio"
//     //               className="nav-link"
//     //               id={tab?.id + "-tab"}
//     //               name="tab-radio"
//     //               value={tab?.id}
//     //               checked={activeTab == tab.id}
//     //               onChange={() => handleTabClick(tab?.id)}
//     //             />
//     //             <label className="ms-2" htmlFor={tab?.id + "-tab"}>
//     //               {tab?.label}
//     //             </label>
//     //           </li>
//     //         ))}
//     //       </ul>
//     //     </div>
//     //   </div>

//     //   <div className="row">
//     //     <div className="col-12 col-md-12 col-lg-12 offset-md-0">
//     //       <div className="tab-content text-start " id="pills-tabContent">
//     //         {Listoftabheading?.map((tab, index) => (
//     //           <div
//     //             className={`tab-pane ${
//     //               activeTab == tab.id ? "show active" : "fade"
//     //             } `}
//     //             id={tab?.id}
//     //             role="tabpanel"
//     //             key={index}
//     //           >
//     //             <p>{staticContent[tab?.label]}</p>
//     //           </div>
//     //         ))}
//     //       </div>
//     //     </div>
//     //   </div>

//     //   {/* <div>
//     //     <div className="tab-content text-start" id="pills-tabContent">
//     //       {Listoftabheading?.map((tab, index) => (
//     //         <div
//     //           className={`tab-pane ${
//     //             activeTab == tab.id ? "show active" : "fade"
//     //           } `}
//     //           id={tab?.id}
//     //           role="tabpanel"
//     //           key={index}
//     //         >
//     //           <p>{staticContent[tab?.label]}</p>
//     //         </div>
//     //       ))}
//     //     </div>
//     //   </div> */}
//     // </section>
//   );
// }

// export default Dashboard;

import React, { useState } from "react";
import "../Dashboard/Dashboard.css";
import { Link } from "react-router-dom";
import { AiOutlineCalendar } from "react-icons/ai";
import { Button, ButtonToolbar } from "rsuite";
function Dashboard() {


  let access_token, access_token_channel;

  if (localStorage.getItem("username")) {
    access_token = localStorage.getItem("access_token");
  } else {
    access_token_channel = localStorage.getItem("access_token_channel");
  }

  let username, channel_name;

  if (localStorage.getItem("username")) {
    username = localStorage.getItem("username");
  } else {
    channel_name = localStorage.getItem("channel_name");
  }

  const headers = {
    "Content-Type": "application/json",
    // Authorization: "Bearer " + access_token_channel,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  if (access_token) {
    headers.Authorization = "Bearer " + access_token;
  } else if (access_token_channel) {
    headers.Authorization = "Bearer " + access_token_channel;
  }


  const capitalizeFirstLetter = (str) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  };

  // Check if username is available, if not, use channel_name
  const capitalizedUsername = username ? capitalizeFirstLetter(username) : capitalizeFirstLetter(channel_name);

  // Use displayName in your component

  return (
      <section className="">
        <div className="section dashboard ">
          <div className="admin-list-table">
            <h4 className="admin-title mb-0  theme-text-color text-md-start ">Good Afternoon, {capitalizedUsername}!</h4>
            <p className="admin-sub-title theme-text-color mb-0">Welcome to Flicknexs</p>
          </div>
          <div className="row mt-4 mx-0">
            <div className="col-lg-4">
              <div className="card info-card user-webnexs theme-bg-color">
                <div className="card-body">
                  <h5 className="card-title fw-bold text-center theme-text-color">
                    User's Of Webnexs
                  </h5>
                  <div className="d-flex align-items-center p-3">
                    <div className="col-lg-2 text-center">
                      {" "}
                      <span className="small pt-1 fs-6 fw-bold theme-text-color">375</span>
                    </div>
                    <div className="col-lg-2 text-center">
                      {" "}
                      <span className="theme-text-color"> | </span>{" "}
                    </div>
                    <div className="col-lg-8">
                      {" "}
                      <span className="text-muted small fs-6 pt-2 ps-1 theme-text-color">
                        Total Subscriber's
                      </span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center p-3">
                    <div className="col-lg-2 text-center">
                      {" "}
                      <span className="small pt-1 fs-6 fw-bold theme-text-color">5</span>
                    </div>
                    <div className="col-lg-2 text-center">
                      {" "}
                      <span className="theme-text-color"> | </span>{" "}
                    </div>
                    <div className="col-lg-8">
                      {" "}
                      <span className="text-muted small fs-6 pt-2 ps-1 theme-text-color">
                        New Subscriber's
                      </span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center p-3">
                    <div className="col-lg-2 text-center">
                      {" "}
                      <span className="small pt-1 fs-6 fw-bold theme-text-color">75</span>
                    </div>
                    <div className="col-lg-2 text-center">
                      {" "}
                      <span className="theme-text-color"> | </span>{" "}
                    </div>
                    <div className="col-lg-8">
                      {" "}
                      <span className="text-muted small fs-6 pt-2 ps-1 theme-text-color">
                        Total Video's
                      </span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center p-3">
                    <div className="col-lg-2 text-center">
                      {" "}
                      <span className="small pt-1 fs-6 fw-bold theme-text-color">37</span>
                    </div>
                    <div className="col-lg-2 text-center">
                      {" "}
                      <span className="theme-text-color"> | </span>{" "}
                    </div>
                    <div className="col-lg-8">
                      {" "}
                      <span className="text-muted small fs-6 pt-2 ps-1 theme-text-color">
                        Total Audio's
                      </span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center p-3">
                    <div className="col-lg-2 text-center">
                      {" "}
                      <span className="small pt-1 fs-6 fw-bold theme-text-color">75</span>
                    </div>
                    <div className="col-lg-2 text-center">
                      {" "}
                      <span className="theme-text-color"> | </span>{" "}
                    </div>
                    <div className="col-lg-8">
                      {" "}
                      <span className="text-muted small fs-6 pt-2 ps-1 theme-text-color">
                        Total Visitor's
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="card p-4  theme-bg-color ">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-6">
                      <h5 className="card-title theme-text-color">Get Started</h5>
                      <p className=" theme-text-color">You Are 3 Steps Away From Completion</p>
                    </div>
                    <div className="col-lg-6">
                      <p className=" theme-text-color">Subscribed To Business</p>
                      <p className=" theme-text-color">Package Ends On 2023-Apr-21</p>
                    </div>
                  </div>
                  <hr className=" theme-text-color"></hr>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="d-flex align-items-start">
                        <div
                          className="nav flex-column nav-pills me-3"
                          id="v-pills-tab"
                          role="tablist"
                          aria-orientation="vertical"
                        >
                          {/* <button
                              className="nav-link active"
                              id="v-pills-home-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#v-pills-home"
                              type="button"
                              role="tab"
                              aria-controls="v-pills-home"
                              aria-selected="true"
                            >
                              Upload your content
                            </button> */}

                          <Button
                            className="nav-link rounded-2 mb-2  theme-bg-color-secondary  mb-1 theme-text-color"
                            id="v-pills-home-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-home"
                            type="button"
                            role="tab"
                            aria-controls="v-pills-home"
                            aria-selected="true"
                          >
                            Upload your content
                          </Button>
                          <Button
                            className="nav-link  rounded-2 mb-2  theme-bg-color-secondary   mb-1 theme-text-color"
                            id="v-pills-profile-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-profile"
                            type="button"
                            role="tab"
                            aria-controls="v-pills-profile"
                            aria-selected="false"
                          >
                            Setup Website Logo,
                            <br /> Name, Social Links,
                            <br /> Payment Types, Etc
                          </Button>
                          {/* <button
                              className="nav-link"
                              id="v-pills-profile-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#v-pills-profile"
                              type="button"
                              role="tab"
                              aria-controls="v-pills-profile"
                              aria-selected="false"
                            >
                              Setup Website Logo, Name, Social Links, Payment
                              Types, Etc
                            </button> */}

                          <Button
                            className="nav-link rounded-2 mb-1  theme-bg-color-secondary  theme-text-color"
                            id="v-pills-messages-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-messages"
                            type="button"
                            role="tab"
                            aria-controls="v-pills-messages"
                            aria-selected="false"
                          >
                            Payment Plans
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="tab-content" id="v-pills-tabContent">
                        <div
                          className="tab-pane fade show active upload-video  theme-bg-color-secondary "
                          id="v-pills-home"
                          role="tabpanel"
                          aria-labelledby="v-pills-home-tab"
                        >
                          <h5 className="theme-text-color mb-2">First things first, you need Video.</h5>
                          <div className="theme-text-color mb-2">Upload A Video To Get Started.</div>
                          <Link to="/admin/addnewvideo">
                            Upload Videos Now
                          </Link>
                        </div>
                        <div
                          className="tab-pane fade upload-video theme-bg-color  theme-text-color global-shadow"
                          id="v-pills-profile"
                          role="tabpanel"
                          aria-labelledby="v-pills-profile-tab"
                        >
                          <div className="theme-text-color ">
                            Setup Website Logo, Name, Social Links, Payment
                            Types, Etc.
                          </div>
                          <div className="mt-3 theme-text-color ">
                            <Link to="">Go to Storefront Settings</Link>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade upload-video  theme-bg-color  theme-text-color global-shadow"
                          id="v-pills-messages"
                          role="tabpanel"
                          aria-labelledby="v-pills-messages-tab"
                        >
                          <div className=" theme-text-color">Setup Subscription Plans</div>
                          <div className="mt-3 theme-text-color">
                            <Link to="">Add Subscription Plans</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row p-0 m-0">
            <div className="col-lg-3">
              <div className=" iq-card-block iq-card-stretch iq-card-height">
                <div className="iq-card-body1 p-3 theme-bg-color rounded-3 ">
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="iq-cart-text text-center text-capitalize">
                      <p className="mb-0 fw-bold theme-text-color">view</p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mt-3">
                    <h4 className=" mb-0 theme-text-color">3.724k</h4>
                    <h6 className="mb-0 theme-text-color">
                      35%
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className=" iq-card-block iq-card-stretch iq-card-height">
                <div className="iq-card-body1 p-3  theme-bg-color  rounded-3 ">
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="iq-cart-text text-center text-capitalize">
                      <p className="mb-0 fw-bold theme-text-color">Visitors</p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mt-3">
                    <h4 className=" mb-0 theme-text-color">10</h4>
                    <h6 className="mb-0 text-primary  theme-text-color">
                      100%
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className=" iq-card-block iq-card-stretch iq-card-height">
                <div className="iq-card-body1 p-3 theme-bg-color  rounded-3 ">
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="iq-cart-text text-center text-capitalize">
                      <p className="mb-0  fw-bold theme-text-color">view</p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mt-3">
                    <h4 className=" mb-0 theme-text-color">3.724k</h4>
                    <h6 className="mb-0 text-primary theme-text-color">
                      45%
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="iq-card-block iq-card-stretch iq-card-height">
                <div className="iq-card-body1 p-3 theme-bg-color  rounded-3 ">
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="iq-cart-text text-center text-capitalize">
                      <p className="mb-0  fw-bold theme-text-color">view</p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mt-3">
                    <h4 className=" mb-0 theme-text-color">3.724k</h4>
                    <h6 className="mb-0 text-primary theme-text-color">
                      95%
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row p-3 m-0">
              <div className="col-md-12">
                <div className="d-flex align-items-center mb-3">
                  <h3>Overview</h3>
                  <div className="days-cards ms-2">
                    <p className="mb-0">
                      <AiOutlineCalendar /> Last 7 Days
                    </p>
                  </div>
                </div>
              </div>
            </div> */}

          <div className="row p-0 m-0 pb-3">
            <div className="col-lg-6">
              <div className="row m-0">
                <div className="col-6 px-0 pe-3">
                  <div className="p-3 iq-cards theme-bg-color rounded-2  mt-3">
                    <p className="theme-text-color">Revenue</p>
                    <h4 className="theme-text-color">USD 0</h4>
                    <div className="progress-bar-vertical">
                      <div className="progress-now-vertical"></div>
                    </div>
                  </div>
                </div>
                <div className="col-6 px-0 ps-3">
                  <div className="p-3 iq-cards theme-bg-color rounded-3  mt-3">
                    <p className=" theme-text-color">ARPU</p>
                    <h4 className=" theme-text-color">USD 0</h4>
                    <div className="progress-bar-vertical">
                      <div className="progress-now-vertical"></div>
                    </div>
                  </div>
                </div>
                <div className="col-6 px-0 pe-3">
                  <div className="p-3 iq-cards theme-bg-color rounded-3  mt-3">
                    <p className=" theme-text-color">Views</p>
                    <h4 className=" theme-text-color">0</h4>
                    <div className="progress-bar-vertical">
                      <div className="progress-now-vertical"></div>
                    </div>
                  </div>
                </div>
                <div className="col-6 px-0 ps-3">
                  <div className="p-3 iq-cards theme-bg-color rounded-3  mt-3">
                    <p className=" theme-text-color">Watch Duration</p>
                    <h4 className=" theme-text-color">00h 00m 00s</h4>
                    <div className="progress-bar-vertical">
                      <div className="progress-now-vertical"></div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div className="col-lg-6">
              <div className=" iq-card-block iq-card-stretch iq-card-height">
                <div className="section-con">
                  <div className="iq-card-body1 p-3 theme-bg-color  rounded-3  mt-3">
                    <div className="row">
                      <div className="col-md-7">
                        <div>
                          <h5 className=" theme-text-color">Trending Content</h5>
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div className="d-flex align-items-center justifiy-content-center">
                          <h5 className=" theme-text-color">By</h5>
                          <div>
                            <div className="dropdown ms-2">
                              <Link
                                className="btn btn-outline-secondary dropdown-toggle  theme-text-color"
                                to="#"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                Views
                              </Link>

                              <ul className="dropdown-menu  theme-bg-color  global-shadow rounded-3  ">
                                <li>
                                  <Link className="dropdown-item  theme-text-color" >
                                    Action
                                  </Link>
                                </li>
                                <li>
                                  <Link className="dropdown-item  theme-text-color" >
                                    Another action
                                  </Link>
                                </li>
                                <li>
                                  <Link className="dropdown-item  theme-text-color">
                                    Something else here
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  );
}

export default Dashboard;
