import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import { toast } from "react-toastify";
 import ResponseLoader from "../../../components/Others/ResponseLoader";
import AdminSaveHeader from "../../../components/Others/adminCommonComponents/AdminSaveHeader";
import { IoClose } from "react-icons/io5";
import { useTranslation } from "react-i18next";

function AddCategory() {
  const { t } = useTranslation();
  const [parent_id, setParent_id] = useState("");
  const [user, setUser] = useState([]);
  const [showOverlay, setShowOverlay] = useState(false);
  const [editUser, setEditUser] = useState({
    title: "",
    slug: "",
  });

  const navigate = useNavigate();
  const access_token_channel = localStorage.getItem("access_token_channel");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token_channel,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const fileInputRef1 = useRef(null);
  const [selectedFile1, setSelectedFile1] = useState(null);
  const handleFileChange = (event, fileInputRef, setSelectedFile) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDelete = (setSelectedFile1) => {
    setSelectedFile1(null);
    if (fileInputRef1.current) {
      fileInputRef1.current.value = null;
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessageslug("");
    setIsValidationHiddenslug(true);
    setValidationMessagetitle("");
    setIsValidationHiddentitle(true);
    setEditUser({ ...editUser, title: e.target.value });
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };

  const [autoFillValuetitle, setAutoFillValuetitle] = useState("");

  const handleInputKeyDowntitle = (event) => {
    if (event.key == "Enter" || event.key == "Tab") {
      generateSlug(editUser?.title);
    }
  };
  const handleBlurtitle = () => {
    generateSlug(editUser?.title);
  };

  const handleAutoFillChangetitle = (event) => {
    setAutoFillValuetitle(event.target.value);
  };

  const generateSlug = (title) => {
    title = title.trim();
    const slug = title.toLowerCase().replace(/\s+/g, "-");
    setAutoFillValuetitle(slug);
  };

  const [validationMessagetitle, setValidationMessagetitle] = useState("");
  const [isValidationHiddentitle, setIsValidationHiddentitle] = useState(true);

  const [validationMessageslug, setValidationMessageslug] = useState("");
  const [isValidationHiddenslug, setIsValidationHiddenslug] = useState(true);

  const titleInputRef = useRef(null);
  async function submitcategory() {
    let focusInputRef = null;

    if (editUser?.title == "") {
      setValidationMessagetitle("Title cannot be empty*.");
      setIsValidationHiddentitle(false);
      focusInputRef = titleInputRef;
    }
    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }

    const formData = new FormData();
    formData.append("name", editUser?.title);
    formData.append("parent_id", parent_id);
    formData.append("slug", autoFillValuetitle);

    let firstApiStatus = false;
    let secondApiStatus = false;
    let resultapi = null;
    let resultapifalse = null;
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/Audio-Genre/store`,
        formData,
        { headers: headers }
      );
      if (response.data.status == true) {
        firstApiStatus = true;
        resultapi = response.data;
        var resultss = response.data.Audio_Genre_id;
        const imageFormData = new FormData();
        imageFormData.append("audio_genre_id", resultss);
        if (selectedFile1) {
          imageFormData.append("image", selectedFile1);
        }
        const imageResponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/Audio-Genre/Image-upload`,
          imageFormData,
          { headers: headers }
        );
        if (imageResponse.data.status == true) {
          secondApiStatus = true;
        }
      } else if (response.data.status == false) {
        // Handle the case when the first API response status is false
        resultapifalse = response.data;
      }
    } catch (error) { }
    if (firstApiStatus && secondApiStatus) {
      setValidationMessagetitle("");
      setValidationMessageslug("");
      toast.success(t(resultapi?.message));
      setTimeout(async () => {
        await navigate("/channel/manage-audio-category");
      }, 3000);
      setShowOverlay(false);

    } else if (resultapifalse) {
      toast.error(t(resultapifalse?.message));
      setShowOverlay(false);
      setIsValidationHiddentitle(false);
      setValidationMessageslug(false);
    }
  }

  useEffect(async () => {
    await axios.get(`${process.env.REACT_APP_Baseurl}/Channel/Backend/Audio-Genre/create`, { headers: headers, })
      .then((response) => {
        var result = response?.data?.AudioGenre;
        setUser(result);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <div>
      {showOverlay && (<ResponseLoader />)}
      <AdminSaveHeader heading="Add New Category" saveText="Save Audio Category" saveMethod={submitcategory} />
      <div className="row m-0 ">
        <div className="col-12 col-md-6 ps-md-0">
          <div className="rounded-3 mb-4 theme-bg-color p-3">
            <h5 className="theme-text-color theme-border-bottom-secondary pb-3">Create Category</h5>
            <div className="mt-3 text-start">
              <label className="mt-2 d-block mandatorySimple position-relative theme-text-color admin-input-title"> Name </label>
              <div className="mt-2">
                <input
                  type="text"
                  className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder theme-text-color border-0"
                  id="title"
                  name="title"
                  placeholder="Enter Name"
                  onChange={handleInput}
                  value={editUser?.title}
                  onBlur={handleBlurtitle}
                  onKeyDown={handleInputKeyDowntitle}
                  ref={titleInputRef}
                />
              </div>
              <span className="errorred d-block mt-2">
                {!isValidationHiddentitle && <p>{validationMessagetitle}</p>}
              </span>
            </div>
            <div className="mt-2 text-start">
              <label className="mt-2 admin-input-title theme-text-color">Slug</label>
              <div className="mt-2">
                <input
                  type="text"
                  className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder theme-text-color border-0"
                  id="slug"
                  name="slug"
                  placeholder="Enter Slug"
                  value={autoFillValuetitle}
                  onChange={handleAutoFillChangetitle}
                />
              </div>
            </div>
            <div className="mt-2 text-start">
              <label className="mt-2 admin-input-title theme-text-color">Category</label>
              <div className="mt-2">
                <select
                  className="form-select theme-bg-color-secondary custom-placeholder border-0 theme-text-color"
                  name="parent_id"
                  id="parent_id"
                  onChange={(e) => setParent_id(e.target.value)}
                >
                  <option value="0" className="theme-text-color theme-bg-color">Choose a Category </option>
                  {user?.map((item) => (
                    <option value={item?.order} className="theme-text-color theme-bg-color"> {item?.name}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 ">
          <div className="rounded-3 mb-4 theme-bg-color p-3">
            <h5 className=" theme-text-color pb-2 theme-border-bottom-secondary">Thumbnails</h5>
            <div className=" text-start mt-2">
              <label className="my-2 admin-input-title theme-text-color">Image</label>
              <div className="mt-2">
                <div className="row mx-0 flex-wrap" >
                  <div className="col-6 ps-0">
                    <div className="imagedrop theme-border-color" onClick={() => fileInputRef1.current.click()}  >
                      <input type="file" ref={fileInputRef1} onChange={(event) => handleFileChange(event, fileInputRef1, setSelectedFile1)} accept="image/*" style={{ display: "none" }} />
                      <label className=" theme-text-color">
                        <CameraRetroIcon className=" theme-text-color" />
                      </label>
                    </div>
                  </div>
                  <div className="col-6 ">
                    <div>
                      {selectedFile1 && (
                        <div className="imagedropcopy theme-border-color text-end">
                          <div className="row m-0">
                            <div className="col-10 ps-0">
                              <img
                                src={URL.createObjectURL(selectedFile1)}
                                alt="Uploaded"
                                className="thumbnails-upload-images"
                              />
                            </div>
                            <div
                              className="col-2 p-0 m-0"
                              style={{ alignSelf: "center" }}
                            >
                              <button
                                onClick={() => handleDelete(setSelectedFile1)}
                                type="button"
                                className=" theme-text-color bg-transparent "
                              >
                                <IoClose className="custom-close-btn" />
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AddCategory;