import React, { useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

const PaymentCallback = () => {
  const location = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const token = query.get('token');

    if (token) {
      verifyPayment(token);
    }
  }, [location]);

  const verifyPayment = async (token) => {
    try {
      const response = await axios.post('https://sandbox-api.iyzipay.com/payment/detail', {
        locale: 'en',
        conversationId: '123456789',
        token: token,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Basic ${Buffer.from(`SZ25Itc2ixQVkkDrvUo4DitiRU5gP42V:PwIySmhFsKkrQ4QPUT4JwZVccW66WRyw`).toString('base64')}`
        },
      });

      if (response.data.status === 'success') {
        console.log('Payment successful:', response.data);
      } else {
        console.error('Payment failed:', response.data);
      }
    } catch (error) {
      console.error('Error verifying payment:', error);
    }
  };

  return (
    <div>
      <h1>Processing Payment...</h1>
    </div>
  );
};

export default PaymentCallback;
