import React from 'react'
import { Link } from 'react-router-dom'
// import Maintances from "../../Images/maintances.svg"
import { useTranslation } from 'react-i18next';
const PageError = ({ error, text }) => {
    const { t } = useTranslation();
    return (
        <div className="d-flex align-items-center justify-content-center flex-column theme-text-color min-vh-100">
            {/* <img src={Maintances} alt="maintances logo" className='maintancesLogo mb-4 h-auto' /> */}
            <h2 className="theme-text-color text-break">{error ? t(error) : t("Under Maintenance")}</h2>
            {!error && <p className="theme-text-color py-3">{t("We'll Be Back Soon")}.</p>}
            {!error && <Link to={'/'} className="theme-text-color px-5 py-2  rounded-3 theme-button-bg-color mt-3">{t("Home")}</Link>}
        </div>
    )
}

export default PageError