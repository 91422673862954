import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import styled from 'styled-components';


const CustomSwiperSlide = styled(SwiperSlide)`
  background-color: rgb(45, 48, 56) !important;
  border-radius: 5px;
  /* other styles */
`;

const EpisodesSkeletonTabs = () => {
  return (
    <Swiper
      loop={false}
      pagination={{ clickable: true }}
      spaceBetween={30}
      lazy={true}
      slidesPerGroup={1}
      className={`swiper-container`}
      breakpoints={{
        576: {
          slidesPerView: 2,
          spaceBetween: 30,
          slidesPerGroup: 2,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 30,
          slidesPerGroup: 3,
        },
        1200: {
          slidesPerView: 8,
          spaceBetween: 30,
          slidesPerGroup: 8,
        },
        1440: {
          slidesPerView: 10,
          spaceBetween: 30,
          slidesPerGroup: 10,
        }
      }}
    >
      {Array.from({ length: 10 }).map((_, index) => (
        <CustomSwiperSlide key={index} className="swiper-slide">
          <div className="tabs-container2">
            <div className="tabs2">
              <div
                className="mb-1 tab2Text"
                style={{
                  backgroundColor: '#f0f0f0',
                  height: '18px',
                  width: '20%',
                  borderRadius: '8px'
                }}
              ></div>
            </div>
          </div>
        </CustomSwiperSlide>
      ))}
    </Swiper>
  );
};

export default EpisodesSkeletonTabs;
