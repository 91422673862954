// External Libraries and Packages
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
// Internal Styles

// Internal Components
import { audioCategoriesPageListAction, viewAllPageCategoryIdAction, } from "../../Redux/Actions/apiActions";
import PageLoader from "../Others/PageLoader";
import PageError from "../Others/PageError";
import LayoutViewAll from "./Source/LayoutViewAll";

const CategoryBasedAudioViewAll = ({ viewallPageCatId, isLoading, error, settings, }) => {
  const { source, slug } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const user_role = localStorage.getItem("role");
  const user_id = localStorage.getItem("user_id");
  const access_token = localStorage.getItem("access_token");
  const headers = {
    "Content-Type": "application/json",
    headers: { "Access-Control-Allow-Origin": "*" },
    Authorization: "Bearer " + access_token,
  };
  const [videoCategoriesData, setVideoCategoriesData] = useState([]);

  useEffect(() => {
    const requestApi = `${process.env.REACT_APP_Baseurl}/Front-End/Audio-Genre-Page-List`;
    const AudioBasedApi = `${process.env.REACT_APP_Baseurl}/Front-End/Audio-based-Genre-Page-List`;
    if (settings && Object.keys(settings)?.length > 0) {
      if (!user_role) {
        try {
          dispatch(
            audioCategoriesPageListAction(null, 9, "web", headers, requestApi)
          )
            .then((data) => {
              const filteredData = data.filter((item) => item.slug == slug);
              const get = filteredData.flatMap((item) => item.slug);
              dispatch(viewAllPageCategoryIdAction(null, 9, "web", get[0], headers, AudioBasedApi));
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        } catch (error) {
          console.error("Error:", error);
          console.log("not found");
        }
      } else {
        try {
          dispatch(audioCategoriesPageListAction(user_id, user_role, "web", headers, requestApi))
            .then((data) => {
              const filteredData = data.filter((item) => item.slug == slug);
              const get = filteredData.flatMap((item) => item.slug);
              dispatch(viewAllPageCategoryIdAction(user_id, user_role, "web", get[0], headers, AudioBasedApi));
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        } catch (error) {
          console.error("Error:", error);
          console.log("not found");
        }
      }
    }
  }, [settings, user_role, dispatch, user_id, location]);

  useEffect(() => {
    setVideoCategoriesData((viewallPageCatId?.data || []).flatMap((item) => item.category__audios || []));
  }, [viewallPageCatId]);


  function capitalizeText(text) {
    return text?.charAt(0).toUpperCase() + text?.slice(1);
  }
  return isLoading ? <PageLoader /> : error ? <PageError /> : <div className="paddingTop">
    <LayoutViewAll mapData={videoCategoriesData} source={`Audios Category ${capitalizeText(slug)}`} path='music/track' headingClass='pb-4 text-center' />
  </div>
};
const mapStateToProps = (state) => ({
  audioCatPageList: state.get_audioCatPageList_Reducer.audioCatPageList,
  viewallPageCatId: state.get_viewAll_CatId_Reducer.viewallPageCatId,
  isLoading: state.get_viewAll_CatId_Reducer.isLoading,
  error: state.get_viewAll_CatId_Reducer.error,
  settings: state.get_setting_Reducer.settings,
});

export default connect(mapStateToProps)(CategoryBasedAudioViewAll);
