import React from 'react';

const ToggleButton = ({ onClick }) => {
  return (
    <button onClick={onClick} className="toggle-button tog">
      Toggle Next Videos
    </button>
  );
};

export default ToggleButton;
