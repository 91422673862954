// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MdOutlineCancelcolor{
  color: red;
}`, "",{"version":3,"sources":["webpack://./src/components/Payment_Registeration/Iyzico_Failerpage.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ","sourcesContent":[".MdOutlineCancelcolor{\n  color: red;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
