 
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from "react-icons/md";
import { BsThreeDotsVertical } from "react-icons/bs";
import { toast } from "react-toastify";
import { CiShare1 } from "react-icons/ci";
import defaultImage from "../../../components/Images/loading/default_image.png";
import ResponseLoader from "../../../components/Others/ResponseLoader";
import MessageBox from "../../../components/Others/MessageBox";
import AdminDeleteModal from "../../../components/Others/adminCommonComponents/AdminDeleteModal";
import AdminNavbar from "../../../components/Others/adminCommonComponents/AdminNavbar";
import AdminHeader from "../../../components/Others/adminCommonComponents/AdminHeader";
import NoRecordTable from "../../../components/Others/adminCommonComponents/NoRecordTable";

function ManageAlbums() {
  const [getAlbum, setAlblum] = useState([]);
  const [filteredAlbumData, setFilteredAlbumData] = useState([]);
  const [itemToDeleteAlbum, setItemToDeleteAlbum] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [modalCloseAlbum, setModalCloseAlbum] = useState(false);
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const access_token_channel = localStorage.getItem("access_token_channel");
  
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token_channel,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/Audio-Album/List`,
        { headers: headers }
      );
      const albumData = response?.data?.audio_album_list;
      const FilterHelp = albumData?.map((item) => {
        const uploadBy =
          item?.uploaded_by == "1"
            ? "Admin"
            : item?.uploaded_by == "5"
              ? "Content Partner"
              : item?.uploaded_by == "4"
                ? "Channel Partner"
                : "";

        return { ...item, uploaded_by: uploadBy };
      });
      setAlblum(FilterHelp);
      setFilteredAlbumData(FilterHelp);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    nextPageText: "Next",
    prePageText: "Prev",
    firstPageText: <MdKeyboardDoubleArrowLeft />,
    lastPageText: <MdKeyboardDoubleArrowRight />,
    showTotal: true,
    alwaysShowAllBtns: true,
    sizePerPageList: [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "All", value: filteredAlbumData?.length },
    ],
  });

  const selectRow = {
    mode: "checkbox",
    onSelect: (row, isSelect) => {
      if (row) {
        if (isSelect) {
          setItemToDeleteAlbum([...itemToDeleteAlbum, row.id]);
        } else {
          setItemToDeleteAlbum(
            itemToDeleteAlbum.filter((id) => id !== row?.id)
          );
        }
      }
    },
    selected: itemToDeleteAlbum,
    selectionHeaderRenderer: ({ mode, checked, indeterminate }) => (
      <div>
        <input
          type="checkbox"
          checked={checked}
          indeterminate={indeterminate}
          onChange={handleAll}
        />
      </div>
    ),
  };

  const imageFormatter = (data, row) => {
    return (
      <div className="d-flex justify-content-between align-items-center ">
        <img src={row.image_url ? row.image_url : defaultImage} className="profile" alt="image_all" />
        <div className="rightVideoTitle">
          <Link className="theme-text-color table-heading d-flex  align-items-center" target="_blank" to={`/videos/${row?.slug}`}> <span title={`${row.slug}`}>{`${data.slice(0, 21)}...`} </span><CiShare1 style={{ width: "15px", height: "15px", strokeWidth: '1px', marginLeft: "6px" }} /> </Link>
          <span className="me-1 text-dark dateText theme-text-color table-subheading">
            {row?.duration}
          </span>
          <span className="dateText table-subheading theme-text-color"> {row?.upload_on}</span>
        </div>
      </div>
    );
  };

  const actionFormatter = (data, row) => {
    return (
      <div className="editdropdown">
        <span className="editdropdown-button">
          <span>
            <BsThreeDotsVertical className=" theme-text-color" />
          </span>
        </span>
        <div className="editdropdown-menu theme-bg-color ">
          <Link to={`/channel/edit-audio-albums/${row.id}`} className=" theme-bg-color theme-text-color ">
            <span>
              <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <g>
                  <path class="svgIcon" d="M10.3,18.5l-4.74.64A.49.49,0,0,1,5,18.59l.64-4.74a.42.42,0,0,1,.14-.28L17.31,2a.59.59,0,0,1,.85,0L22.11,6a.6.6,0,0,1,0,.84L10.58,18.36A.53.53,0,0,1,10.3,18.5Z" />
                  <line class="svgIcon" x1="1.01" y1="21.58" x2="18.89" y2="21.58" />
                </g>
              </svg>
              <MessageBox text="Edit" classname='ms-2 theme-text-color'></MessageBox>
            </span>
          </Link>

          <div onClick={() => openModalSingleDeleteAlbum(row.id)} className="commonActionPadding theme-bg-color"   >
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <g>
                <rect class="svgIcon" x="4.38" y="5.54" width="14.49" height="17.71" />
                <line class="svgIcon" x1="1.16" y1="5.54" x2="22.09" y2="5.54" />
                <g>
                  <line class="svgIcon" x1="9.21" y1="9.56" x2="9.21" y2="19.06" />
                  <line class="svgIcon" x1="14.04" y1="9.56" x2="14.04" y2="19.06" />
                </g>
                <path class="svgIcon" d="M7.45,5.22a2.72,2.72,0,0,1,0-.28,4.19,4.19,0,1,1,8.37,0c0,.09,0,.19,0,.29" />
              </g>
            </svg>
            <MessageBox text="Delete" classname='ms-2 theme-text-color'></MessageBox>
          </div>
        </div>
      </div>
    );
  };

  const columns = [
    {
      dataField: "albumname",
      text: "Album",
      formatter: imageFormatter,
      sort: true,
    },
    {
      dataField: "uploaded_by",
      text: "Uploaded By",
      sort: true,
    },
    {
      dataField: "ACTIONS",
      text: "Actions",
      formatter: actionFormatter,
    },
  ];

  const handleFilterChangeAlbum = (data) => {
    setFilteredAlbumData(data);
  };


  const handleAll = () => {
    setSelectAll(!selectAll);
    setItemToDeleteAlbum(
      selectAll ? [] : filteredAlbumData.map((item) => item.id)
    );
  };

  const closeAlbumModal = () => {
    setModalCloseAlbum(!modalCloseAlbum);
  };

  const openModalSingleDeleteAlbum = (id) => {
    setItemToDeleteAlbum([id]);
    setModalCloseAlbum(true);
  };

  const openModalMultiDeleteAlbum = () => {
    setModalCloseAlbum(true);
  };

  const handleDeleteAlbumData = () => {
    closeAlbumModal();
    albumdeleteOperation();
  };

  const albumdeleteOperation = async () => {
    setProcessing(true);
    setShowOverlay(true);
    const formData = new FormData();
    formData.append("id", "[" + itemToDeleteAlbum + "]");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/Audio-Album/multi-delete`,
        formData,
        {
          headers: headers,
        }
      );
      const result = response.data;
      if (result.status == true) {
        toast.success(result?.message);
        setProcessing(false);
        setShowOverlay(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      var errorMessage = err.response.data.message;
      toast.error({ status: false, message: errorMessage });
      setProcessing(false);
      setShowOverlay(false);
    }
  };

  return (
    <div className="">
      {showOverlay && <ResponseLoader />}
      {modalCloseAlbum && <AdminDeleteModal modelClose={modalCloseAlbum} deleteClose={closeAlbumModal} deleteAction={handleDeleteAlbumData} buttonText={itemToDeleteAlbum?.length} />}
      <AdminNavbar heading="Audio Album" totalDataLength={getAlbum?.length} deleteCount={itemToDeleteAlbum?.length} navigation="/channel/add-audio-albums" deleteMethod={openModalMultiDeleteAlbum} navText=" Add Album" />
      <div className="theme-bg-color p-3 rounded-2 bootstrapTable  multi-delete-width" >
        <AdminHeader  searchMethod={handleFilterChangeAlbum}   reduceData={filteredAlbumData} originalData={getAlbum} fileName="ALBUM-LIST" />
        <BootstrapTable keyField="id" columns={columns} data={filteredAlbumData} pagination={pagination} className="no-border-table theme-text-color" noDataIndication={<NoRecordTable result={filteredAlbumData?.length} loader={loading} />} hover headerClasses="videoWidth" selectRow={selectRow} selectAll={selectAll} />
      </div>
    </div >
  );
}
export default ManageAlbums;