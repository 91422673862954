// External Libraries and Packages
import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { useParams, useNavigate, Link, useLocation } from 'react-router-dom';
import { BiError } from 'react-icons/bi';
// Internal Styles
import { toast } from "react-toastify";
import "../../../Pages/HomePage/Video_details.css"
import "./Details.css"
// Internal Components
import { episodeDetailsAction, seriesDetailsAction } from '../../../Redux/Actions/apiActions';
import Error404 from '../../../Error404/Error404';
import LoaderPage from '../../../Layout/LoaderPage';
import HelmetDetails from '../../Others/HelmetDetails';
import Breadcrumb from '../../Breadcrumb/Breadcrumb';
import { LikeAction, disLikeAction, watchLaterAction, wishListAction } from '../../../Pages/CommonMethods/CommonMethods';
import { useTranslation } from 'react-i18next';
import EpisodesTabsection from './EpisodesTabsection';
import MoreLike from '../../Others/MoreLike';
import MessageBox from '../../Others/MessageBox';
import usePpvGlobalImageConfig from "../../Api/Rentppv_global_change";
import axios from 'axios';

const Series = ({ seriesDetailsData, seriesDetailsData1, isLoading, error, settings, profileDetails }) => {

    const { lang, slug } = useParams();
    const { t } = useTranslation();
    const user_role = localStorage.getItem("role");
    const user_id = localStorage.getItem("user_id");
    const access_token = localStorage.getItem("access_token");
    const headers = {
        "Content-Type": "application/json", Authorization: "Bearer " + access_token,
        Accept: "application/json",
    };
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [domReload, setDomReload] = useState(false)
    const currentPageURL = window.location.href
    const [expanded, setExpanded] = useState(false);
    const location = useLocation();

    useEffect(() => {
        if (settings && Object.keys(settings).length > 0) {
            !user_role && settings.access_free == 1
                ? dispatch(seriesDetailsAction(null, 9, "web", slug, headers))
                : user_role && dispatch(seriesDetailsAction(user_id, user_role, "web", slug, headers));
        }
    }, [settings, user_role, domReload, location])

    const handleDOM = () => {
        setDomReload(!domReload)
    }
    const codeNumber = "3";
    const handleLikeClick1 = async () => {
        try {
            let userRole = access_token ? user_role : 9;
            if (userRole === 9) {
                navigate(`/${lang}/login`);
                return;
            }
            const likeStatus = seriesDetailsData?.like == null || seriesDetailsData?.like == 0 ? 1 : 0;
            const response = await LikeAction(user_id, userRole, 'web', seriesDetailsData?.id, codeNumber, likeStatus);
            handleDOM();
            toast.success(t(response?.message));
        } catch (error) {
            console.error(error);
        }
    };
    const handleDislikeClick1 = async () => {
        try {
            let userRole = access_token ? user_role : 9;
            if (userRole === 9) {
                navigate(`/${lang}/login`);
                return;
            }
            const likeStatus = seriesDetailsData?.dislike == null || seriesDetailsData?.dislike == 0 ? 1 : 0;
            const response = await disLikeAction(user_id, userRole, 'web', seriesDetailsData?.id, codeNumber, likeStatus);
            handleDOM();
            toast.success(t(response?.message));
        } catch (error) {
            console.error(error);
        }
    };

    const handleClickWishList1 = async () => {
        try {
            let userRole = access_token ? user_role : 9;
            if (userRole === 9) {
                navigate(`/${lang}/login`);
                return;
            }
            await wishListAction(user_id, userRole, 'web', seriesDetailsData?.id, codeNumber);
            handleDOM();
        } catch (error) {
            console.error(error);
        }
    };

    const handleClickWatchLater1 = async () => {
        try {
            let userRole = access_token ? user_role : 9;
            if (userRole === 9) {
                navigate(`/${lang}/login`);
                return;
            }
            await watchLaterAction(user_id, userRole, 'web', seriesDetailsData?.id, codeNumber);
            handleDOM();
        } catch (error) {
            console.error(error);
        }
    };

    const episodeApi = async (episodeSlug) => {
        if (settings && Object.keys(settings).length > 0) {
            try {
                let actionResult;
                if (!user_role && settings?.access_free == 1) {
                    actionResult = await dispatch(episodeDetailsAction(null, 9, "web", slug, episodeSlug, headers));
                } else if (user_role) {
                    actionResult = await dispatch(episodeDetailsAction(user_id, user_role, "web", episodeSlug, slug, headers));
                }
                return { status: true, data: actionResult };
            } catch (error) {
                console.error('Error fetching episode data:', error);
                return { status: false, error: error.message };
            }
        } else {
            return { status: false, error: 'Invalid settings' };
        }
    };
    const handlePlayButton = async () => {
        if (seriesDetailsData && seriesDetailsData.series_season && seriesDetailsData.series_season.length > 0) {
            const lastSeason = seriesDetailsData.series_season[seriesDetailsData.series_season.length - 1];
            if (lastSeason.episodes && lastSeason.episodes.length > 0) {
                const lastEpisode = lastSeason?.episodes[lastSeason.episodes.length - 1];
                const episodeSlug = lastEpisode?.slug;
                try {
                    const response = await episodeApi(episodeSlug);
                    if (response?.status) {
                        navigate(`/${lang}/series/${seriesDetailsData?.slug}/${episodeSlug}`);
                    } else {
                        // navigate(`/${lang}/series/${seriesDetailsData?.slug}/${episodeSlug}`);;
                    }
                } catch (error) {
                    console.error('Error calling episodeApi:', error);
                }
            } else {
                console.error('No episodes found in the last season.');
            }
        } else {
            console.error('No series season data found.');
        }
    };
    const htmlString = seriesDetailsData?.details;
    const theObj = { __html: htmlString };
    const description = t(seriesDetailsData?.description);

    const tabs = [
        {
            id: 1,
            tabTitle: "Tab 1",
            title: t("Episodes"),
            content: <><EpisodesTabsection seriesDetailsData={seriesDetailsData} settings={settings} /></>
        },
        seriesDetailsData?.related_videos?.length > 0
            ? {
                id: 2,
                tabTitle: "Tab 2",
                title: t("More Like This"),
                content: <MoreLike data={seriesDetailsData?.related_videos} />
            } : {},
        {
            id: 2,
            tabTitle: "Tab 2",
            title: t("Details"),
            content: <>
                <div dangerouslySetInnerHTML={theObj} className='my-2'></div>
            </>
        }
    ];

    const [currentTab, setCurrentTab] = useState(tabs[0]?.id);
    const handleTabClick = (tabId) => {
        setCurrentTab(tabId);
    };
    const formatDate1 = (date) => {
        const d = new Date(date);
        const day = String(d.getDate()).padStart(2, '0');
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const year = d.getFullYear();

        return `${day}/${month}/${year}`;
    };
    const date = seriesDetailsData?.year;
    const formattedDate = formatDate1(date);
    const continueWatchingExists = seriesDetailsData?.continue_watching_exists?.Episode;
    const [seasons, setSeasons] = useState([]);
    const [currentSeason, setCurrentSeason] = useState(null);
    const [currentEpisode, setCurrentEpisode] = useState(null);
    const episodeLength = seriesDetailsData?.series_season?.length || 0;
    useEffect(() => {
        const generateSeasons = (length) => {
            return Array.from({ length }, (_, i) => {
                const seasonData = seriesDetailsData.series_season[i] || {};
                const seasonId = seriesDetailsData.series_season[i]?.id || i + 1;
                const Episodes = seriesDetailsData?.series_season[i]?.episodes || [];
                return {
                    id: i + 1,
                    seasonNo: `S${i + 1}`,
                    seasonId: seasonId,
                    episodes: Episodes,
                };
            });
        };
        const seasonsGenerated = generateSeasons(episodeLength);
        setSeasons(seasonsGenerated);
        const currentSeason = seasonsGenerated.find(
            (season) => season.seasonId === continueWatchingExists?.season_id
        );
        setCurrentSeason(currentSeason);
    }, [seriesDetailsData, episodeLength, continueWatchingExists]);


    const episodesData = currentSeason?.episodes || [];

    const generateEpisodeNumbers = (episodesData) => {
        if (!episodesData) {
            return [];
        }
        return episodesData.map((episode, index) => ({
            ...episode,
            episodeNo: `E${index + 1}`,
        }));
    };

    const episodesWithNumbers = generateEpisodeNumbers(episodesData);
    useEffect(() => {
        if (continueWatchingExists && seasons.length > 0 && episodesWithNumbers.length > 0) {
            const continueWatchingSeason = seasons.find((season) =>
                season.seasonId.toString() == continueWatchingExists.season_id.toString());
            if (continueWatchingSeason && continueWatchingSeason.seasonId !== currentSeason?.seasonId) {
                setCurrentSeason(continueWatchingSeason);
            }
            const continueWatchingEpisode = episodesWithNumbers.find((episode) => episode.id.toString() === continueWatchingExists.id.toString());
            if (continueWatchingEpisode && continueWatchingEpisode.id !== currentEpisode?.id) {
                setCurrentEpisode(continueWatchingEpisode);
            }
        }
    }, [continueWatchingExists, seasons, episodesWithNumbers, currentSeason?.seasonId, currentEpisode?.id]);


    const renderButtons = () => {
        const showWatchNowButton = ((seriesDetailsData?.access == "guest" && !user_role)
            || (seriesDetailsData?.access == "guest" && user_role == 1)
            || (seriesDetailsData?.access == "subscriber" && user_role == 1)
            || (seriesDetailsData?.access == "ppv" && user_role == 1)
            || (seriesDetailsData?.access == "registered" && user_role == 1)
            || (seriesDetailsData?.access == "guest" && user_role == 2)
            || (seriesDetailsData?.access == "registered" && user_role == 2)
            || (seriesDetailsData?.access == "guest" && user_role == 3)
            || (seriesDetailsData?.access == "registered" && user_role == 3)
            || (seriesDetailsData?.access == "subscriber" && user_role == 3)
            || (seriesDetailsData?.access == "ppv" && user_role == 3 && settings?.enable_ppv_rent == 1)
            || (seriesDetailsData?.access == "ppv" && user_role == 3 && seriesDetailsData1?.PPV_exists == true)
            || (seriesDetailsData?.access == "ppv" && user_role == 2 && seriesDetailsData1?.PPV_exists == true));
        const showRegisterNowButton = ((!user_role && seriesDetailsData?.access == "registered")
            || (!user_role && seriesDetailsData?.access == "ppv")
            || (!user_role && seriesDetailsData?.access == "subscriber"));
        const showBecomeSubscriberButton = ((seriesDetailsData?.access == "subscriber" && user_role == 2));
        const showRentNowButton = ((seriesDetailsData?.access == "ppv" && user_role == 2)
            || (seriesDetailsData?.access == "ppv" && user_role == 3 && settings?.enable_ppv_rent == 0)
            || (seriesDetailsData?.access == "subscriber" && user_role == 3 && settings?.enable_ppv_rent == 0));
        return (
            <>
                {showWatchNowButton && (<button className="theme-button-bg-color d-flex align-items-center justify-content-between px-3 py-2 rounded-2" onClick={handlePlayButton}><span>{t("Watch Now")}</span></button>)}
                {showRegisterNowButton && (<button className="theme-button-bg-color px-3 py-2 rounded-2 d-inline-block"><Link className="theme-button-text-color" to={`/${lang}/signup`}><span>{t("Register Now")}</span></Link></button>)}
                {showBecomeSubscriberButton && (<button className="theme-button-bg-color px-3 py-2 rounded-2 d-inline-block"><Link className="theme-button-text-color" to={`/${lang}/${!user_role ? 'signup' : 'plan'}`}><span>{t("Subscribe to Entire Library")}</span> </Link></button>)}
                {showRentNowButton && (<button className="theme-button-bg-color d-flex align-items-center justify-content-between px-3 py-2 rounded-2" onClick={initializeCheckout}>{currency?.code ? seriesDetailsData?.ContentPriceRate ? `Rent Now ${seriesDetailsData?.ContentPriceRate?.Symbol} ${seriesDetailsData?.ContentPriceRate?.ChangedPrice}` : `Rent Now ${currencySymbol} ${convertedPrice !== null ? convertedPrice : 'Loading...'}` : `Rent Now ${seriesDetailsData?.ppv_price !== null ? seriesDetailsData?.ppv_price : 'Loading...'}`} </button>)}
            </>
        );
    };

    //   geo ip location integration 
    //   geo ip location integration 
    const { currency, currencySymbol, userCurrency, locationResponselocationResponse } = usePpvGlobalImageConfig();
    const [convertedPrice, setConvertedPrice] = useState(null);
    useEffect(() => {
        const fetchConversionRate = async () => {
            if (!userCurrency || !currency?.code || currency?.enable_multi_currency !== 1) return;

            try {
                const response = await axios.get(`${process.env.REACT_APP_API_Curreny_generate}/${currency?.code}`);
                const data = response.data;
                const conversionRate = data.rates[userCurrency];
                if (conversionRate) {
                    const convertedPPVPrice = seriesDetailsData?.ppv_price * conversionRate;
                    setConvertedPrice(convertedPPVPrice);
                } else {
                    console.error('Conversion rate not found for currency:', userCurrency);
                }
            } catch (error) {
                console.error('Error fetching conversion rate:', error);
            } finally {
                // setLoading(false);
            }
        };

        fetchConversionRate();
    }, [userCurrency, currency?.code, currency?.enable_multi_currency, seriesDetailsData?.ppv_price]);

    //   geo ip location integration 
    //   geo ip location integration 
    //    Iyzico payment integration 
    //    Iyzico payment integration 
    const successpage = window.location.href;
    const ppv_priceppv_price = seriesDetailsData?.ppv_price
    const [formDataiyzicopayment, setFormDataiyzicopayment] = useState({
        locale: '',
        conversationId: '',
        price: ppv_priceppv_price, // Initialize with an empty value
        paidPrice: ppv_priceppv_price, // Initialize with an empty value
        successpage: successpage, // Initialize with an empty value
        currency: '',
        basketId: '',
        paymentGroup: 'PRODUCT',
        callbackUrl: '',
        enabledInstallments: [2, 3, 6, 9],
        buyer: {
            id: '',
            name: '',
            surname: '',
            gsmNumber: '',
            email: '',
            identityNumber: '',
            lastLoginDate: '',
            registrationDate: '',
            registrationAddress: '',
            ip: '',
            city: '',
            country: '',
            zipCode: ''
        },
        shippingAddress: {
            contactName: 'Jane Doe',
            city: 'Istanbul',
            country: 'Turkey',
            address: 'Nidakule Göztepe, Merdivenköy Mah. Bora Sok. No:1',
            zipCode: '34742'
        },
        billingAddress: {
            contactName: 'Jane Doe',
            city: 'Istanbul',
            country: 'Turkey',
            address: 'Nidakule Göztepe, Merdivenköy Mah. Bora Sok. No:1',
            zipCode: '34742'
        },
        basketItems: [
            {
                id: 'BI101',
                name: 'Binocular',
                category1: 'Collectibles',
                category2: 'Accessories',
                itemType: 'PHYSICAL',
                price: ppv_priceppv_price,
            }
        ]
    });

    useEffect(() => {
        let value;
        if (currency?.code === null || currency?.code === "") {
            value = seriesDetailsData?.ppv_price !== null ? seriesDetailsData?.ppv_price : '';
        } else {
            if (settings?.PPV_Individual_Contents === "0" || settings?.PPV_Individual_Contents === 0) {
                value = convertedPrice !== null ? convertedPrice : '';
            } else {
                value = seriesDetailsData?.ContentPriceRate !== null
                    ? seriesDetailsData?.ContentPriceRate?.ChangedPrice
                    : convertedPrice !== null
                        ? convertedPrice
                        : '';
            }
        }

        const getCurrentDateTime = () => {
            const now = new Date();
            const year = now.getFullYear();
            const month = String(now.getMonth() + 1).padStart(2, '0');
            const day = String(now.getDate()).padStart(2, '0');
            const hours = String(now.getHours()).padStart(2, '0');
            const minutes = String(now.getMinutes()).padStart(2, '0');
            const seconds = String(now.getSeconds()).padStart(2, '0');
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        };

        const currentDateTime = getCurrentDateTime();
        const locale = lang === "tr" ? "tr" : "en";
        const conversationId = user_id;
        const currencyfinal = currency?.code;
        const basketId = profileDetails?.Profile_details?.id;
        const gsmNumber = "+" + profileDetails?.Profile_details?.payment_mobile_number;
        // const successpage = window.location.href;
        const successpage = `${process.env.REACT_APP_API_PATHss}/` + lang + "/successpage";
        const failerpage = `${process.env.REACT_APP_API_PATHss}/` + lang + "/failer";
        const callbackUrl = `${process.env.REACT_APP_API_IYZICO_PAYMENT}/callback`;
        const usernames = profileDetails?.Profile_details?.username;
        const last_name = profileDetails?.Profile_details?.last_name;
        const payment_mobile_number = "+" + profileDetails?.Profile_details?.payment_mobile_number;
        const user_ids = user_id;
        const emailemail = profileDetails?.Profile_details?.email;
        const city = locationResponselocationResponse?.city;
        const country = locationResponselocationResponse?.country;
        const zipCode = locationResponselocationResponse?.asn;
        const ip = locationResponselocationResponse?.ip;
        const basketItemsname = seriesDetailsData?.title || seriesDetailsData?.name;
        setFormDataiyzicopayment(prevState => ({
            ...prevState,
            locale: locale,
            price: ppv_priceppv_price,
            conversationId: conversationId,
            Login_user_id: user_id,
            Login_device_type: "web",
            source_type: 3,
            source_id: seriesDetailsData?.id,
            regionname: country,
            countryname: country,
            cityname: city,
            successpage: successpage,
            failerpage: failerpage,
            paidPrice: ppv_priceppv_price,
            currency: currencyfinal,
            basketId: basketId,
            callbackUrl: callbackUrl,
            buyer: {
                id: user_ids,
                name: usernames,
                surname: last_name,
                gsmNumber: payment_mobile_number,
                email: emailemail,
                identityNumber: gsmNumber,
                lastLoginDate: currentDateTime,
                registrationDate: currentDateTime,
                registrationAddress: country,
                ip: ip,
                city: city,
                country: country,
                zipCode: zipCode
            },
            shippingAddress: {
                contactName: usernames,
                city: city,
                country: country,
                address: country,
                zipCode: zipCode
            },
            billingAddress: {
                contactName: usernames,
                city: city,
                country: country,
                address: country,
                zipCode: zipCode
            },
            basketItems: [
                {
                    id: user_ids,
                    name: basketItemsname,
                    category1: 'Videos',
                    itemType: 'PHYSICAL',
                    price: ppv_priceppv_price
                }
            ]
        }));



    }, [seriesDetailsData, currency, settings, convertedPrice]);

    const initializeCheckout = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_IYZICO_PAYMENT}/initialize-checkout`, formDataiyzicopayment);
            if (response.data.status === "success") {
                const paymentPageUrl = response?.data?.paymentPageUrl;
                window.location.href = paymentPageUrl;
            }
            else if (response.data.status === "failure") {
                toast.error(`${response.data.errorMessage}`)
            }
        } catch (error) {
            console.error('Error initializing checkout:', error);
        }
    };

    // useEffect(() => {
    //     let showAlert = true;

    //     const retrievePaymentDetails = async () => {
    //         try {
    //             const response = await axios.get(`${process.env.REACT_APP_API_IYZICO_PAYMENT}/retrieve-payment-details`);

    //             if (response.data.status === 'success' && showAlert) {
    //                 showAlert = false;

    //                 const result = response.data;
    //                 const user_id = localStorage.getItem("user_id");
    //                 const seriesDetailsDataslug = seriesDetailsData?.slug;
    //                 const seriesDetailsDataid = seriesDetailsData?.id;

    //                 await axios.post('http://localhost:7001/payment/details', {
    //                     ...result,
    //                     user_id,
    //                     seriesDetailsDataslug,
    //                     seriesDetailsDataid,
    //                 });
    //             }
    //         } catch (error) {
    //             console.error('Error retrieving payment details:', error);
    //         }
    //     };

    //     retrievePaymentDetails();
    // }, []);
    //    Iyzico payment integration 
    //    Iyzico payment integration 
    return (
        <> <div className='paddingTopone'>
            {error ? <Error404 /> : isLoading ? <LoaderPage /> :
                seriesDetailsData !== null ? <>
                    <HelmetDetails metaURL={currentPageURL} metaTitle={seriesDetailsData?.title ? seriesDetailsData.title?.toUpperCase() : seriesDetailsData?.name?.toUpperCase()} metaDescription={seriesDetailsData?.description?.toString().substring(0, 100)}
                        metaImage={seriesDetailsData?.Player_thumbnail} pageTitle={seriesDetailsData?.title ? seriesDetailsData.title?.toUpperCase() : seriesDetailsData?.name?.toUpperCase()} />
                    <div style={{ backgroundImage: `url(${seriesDetailsData?.Player_thumbnail})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundAttachment: "fixed" }} className='px-md-5 px-3 py-md-3 py-3 linearShadow w-100 videoDetailsWrapper '>
                        <div className='position-relative'>
                            <Breadcrumb bread1={t("Home")} bread2={t("Series")} bread3={t(seriesDetailsData?.title)} link1="/" link2={`/${lang}/latest-series`} slase />
                            <div className='pageWrapper2'>
                                <div className="col-12 col-lg-10">
                                    <h3 className="midHeading2 mb-3 text-break">{t(seriesDetailsData?.title)} {t(seriesDetailsData?.name)} </h3>
                                    {description ? <MessageBox text={description} classname={`descript d-block text-wrap ${expanded ? 'h-auto' : description.length > 200 ? 'hide' : ''}`} /> : null}
                                    {description.length > 200 ? <button className='bg-transparent p-0' onClick={() => setExpanded(!expanded)}><MessageBox text={expanded ? 'Show Less' : 'Show More'} classname='theme-text-color descript mt-2 d-block' /></button> : null}

                                    {seriesDetailsData?.year ?
                                        <div className='d-flex align-items-center flex-wrap my-4'>
                                            {seriesDetailsData?.year ? <label className='smallText1 beforeCircle text-break'>{t(formattedDate)}</label> : ""}
                                        </div> : null}

                                    <div className="d-flex align-items-center gap-3 flex-wrap pt-3 position-relative">
                                        {renderButtons()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='px-md-5 px-3 py-3'>
                        <ul className="d-flex align-items-center  gap-4 flex-wrap m-0 list-unstyled">
                            {tabs?.map((tab, index) => (
                                <li key={index} className={`${currentTab === tab?.id ? 'highlightTabColor position-relative' : ''} tabHeading mb-3`}
                                    disabled={currentTab === tab.id} onClick={() => handleTabClick(tab.id)}> {t(tab.title)} </li>
                            ))}
                        </ul>
                        <div className="content pt-2">
                            {tabs?.map((tab, index) => (currentTab === tab?.id && (tab?.content)))}
                        </div>
                    </div>
                </>
                    : <div className="py-5 text-center"><BiError style={{ fontSize: "50px" }} /><h3>{t("NO DATA FOUND")}</h3></div>
            }
        </div>

        </>
    )
}
const mapStateToProps = state => ({
    seriesDetailsData: state.get_seriesDetails_Reducer.seriesDetailsData,
    seriesDetailsData1: state.get_seriesDetails_Reducer.seriesDetailsData1,
    isLoading: state.get_seriesDetails_Reducer.isLoading,
    error: state.get_seriesDetails_Reducer.error,
    profileDetails: state.get_profileDetails_Reducer.profileDetails,
    settings: state.get_setting_Reducer.settings
});
export default connect(mapStateToProps)(Series)