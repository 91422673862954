import { useState, useEffect } from "react";
import axios from "axios";

const usePpvGlobalImageConfig = () => {
    const access_token = localStorage.getItem("access_token");
    const adminApiHeader = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
    };

    const [currency, setCurrency] = useState({ code: '', country: '', symbol: '', enable_multi_currency: '' });
    const [currencySymbol, setCurrencySymbol] = useState('');
    const [locationResponselocationResponse, setLocationResponselocationResponse] = useState('');
    const [userCurrency, setUserCurrency] = useState('');


    const fetchCurrencyData = async () => {
        try {
            const currencyResponse = await axios.get(`${process.env.REACT_APP_Baseurl}/Front-End/Currency-Index`);
            const currencyData = currencyResponse?.data;
            setCurrency(currencyData?.CurrencySetting);
        } catch (error) {
            console.error('Error fetching currency data:', error);
        }
    };


    const fetchLocationData = async () => {
        try {
            const locationResponse = await axios.get(`${process.env.REACT_APP_API_IP_Location}`);
            const country = locationResponse?.data?.country;
            const country_code = locationResponse?.data?.country_code;
            const locationResponselocationResponse = locationResponse?.data;
            setLocationResponselocationResponse(locationResponselocationResponse);
            
            if (country) {
                const currencyResponse = await axios.get(`${process.env.REACT_APP_country_code}/${country_code}`);
                const symbol = currencyResponse?.data[0]?.currencies[Object.keys(currencyResponse?.data[0]?.currencies)[0]].symbol;
                setCurrencySymbol(symbol);
                setUserCurrency(Object.keys(currencyResponse?.data[0]?.currencies)[0]);
            } else {
                console.error('Country code is undefined.');
            }
        } catch (error) {
            console.error('Error fetching location data:', error);
        } finally {
            // setLoading(false);
        }
    };


    useEffect(() => {
        fetchLocationData();
        fetchCurrencyData();
    }, []);

    return { currency, currencySymbol, userCurrency, locationResponselocationResponse };
};

export default usePpvGlobalImageConfig;
