import React from 'react'
import LazyLoadImage from '../../HomePage/HomeSwiperComponents/LazyLoadImage'
import MessageBox from '../../../components/Others/MessageBox'
import { connect, useDispatch } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { FiPlusCircle } from 'react-icons/fi'
import FollowButton from './FollowButton'
import PriceLabel from '../../../components/Others/PriceLabel'
import { formatDuration } from '../../CommonMethods/CommonMethods'
import PlayButton from './PlayButton'

const ListLayout = ({ store, languageDetailsData, currentAudio, playlistShow, currentPlaying, restrictUI }) => {
    const { source } = useParams()

    const dispatch = useDispatch()

    const sourceTypeMap = {
        "playlist": "PLAYLIST",
        "musician": "ARTIST",
        "song-writer": "ARTIST",
        "artists": "ARTIST",
        "audio-albums": "ALBUM"
    };

    const sourceNavigationMap = {
        "playlist": "playlist",
        "musician": "artist",
        "song-writer": "artist",
        "artists": "artist",
        "audio-albums": "album"
    };

    const navigateTrack = sourceNavigationMap[source] || "track";
    const propsType = sourceTypeMap[source] || "AUDIO";
    const restrictArtist = restrictUI(["playlist", "musician", "artists", "song-writer"])

    const handlePlaylistModal = (id) => {
        if (currentAudio?.id) {
            dispatch({ type: "OPEN_INITIAL_MODAL", payload: true })
            dispatch({ type: "GET_PLAYLIST_STORE_ID", payload: id })
        }
    };

    const formatter = new Intl.NumberFormat('en', {
        notation: 'compact',
        compactDisplay: 'short'
    });

    return (
        <div className={`${playlistShow ? 'col-12' : 'col-12'} p-2 theme-bg-color rounded-2 listChip`}>
            <div className='d-flex align-items-center flex-wrap justify-content-between'>
                <div className="col-2 col-md-1 ">
                    <div className='audioImage position-relative'>
                        <LazyLoadImage key={store?.title ? store?.title : store.name ? store?.name : store?.albumname ? store?.albumname : store?.artist_name} src={store?.Player_image_url ? store?.Player_image_url : store?.image_url} alt={store?.title ? store?.title : store.name ? store?.name : store?.albumname ? store?.albumname : store?.artist_name} title={store?.title ? store?.title.slice(0, 1) : store.name ? store?.name.slice(0, 1) : store?.albumname ? store?.albumname.slice(0, 1) : store?.artist_name.slice(0, 1)} imageSize={`${!restrictArtist ? 'rounded-2' : 'rounded-circle'} overflow-hidden object-fit-cover audioImage`} classNameOptional="rounded-2 audioImage" />
                        <div className={`position-absolute ${!restrictArtist ? 'rounded-2' : 'rounded-circle'} layoutPlay ${currentAudio?.id == store?.id && 'd-flex'}`} >
                        <PlayButton type={propsType} store={store} className="bg-transparent position-relative" />
                        </div>
                        {/* <img src={ActiveGif} className={` ${currentAudio?.id == store?.id ? "d-block" : "d-none"} playIconForActive`} /> */}
                    </div>

                </div>
                <div className={` ${!restrictArtist ? 'col-8' : 'col-10'} col-md-6 p-2 p-sm-0`}>
                    <Link to={`/${languageDetailsData?.Language_Code}/music/${navigateTrack}/${store?.slug ? store?.slug : store?.artist_slug}`}><MessageBox classname={`playCardTitle  ${currentAudio?.id == store?.id ? 'highlighted' : "theme-text-color "} `} text={store?.title ? store?.title : store?.name ? store?.name : store?.albumname ? store?.albumname : store?.artist_name} /></Link>
                    <MessageBox classname={`playCardDescription theme-text-color  ${currentAudio?.id == store?.id ? "active-description" : "opacity-50 "}`} text={store?.description} />
                </div>
                <>
                    {!restrictArtist ?
                        <div className='col-2 col-md-5 d-flex justify-content-end flex-wrap'>
                            <div className='d-flex align-items-center justify-content-end layoutRight flex-wrap'>
                                <span className='d-none '>{store?.id}</span>
                                <FiPlusCircle className="theme-text-color" onClick={() => handlePlaylistModal(store?.id)} />
                                <span className='othersTime mx-3'>{formatDuration(store?.duration)}</span>
                                <span className='priceLabelViewPage text-center'>
                                    {/* {store?.access == "subscriber" ? <FaCrown className='goldIcon p-1 text-center' style={{width:"25px",height:"25px"}} /> : store?.access == "ppv" ? <IoMdPricetag className='goldIcon'  style={{width:"25px",height:"25px"}}/> : <div className='text-center theme-button-bg-color p-1 rounded-2'>Free</div>} */}
                                    <PriceLabel item={store} className="rounded-2 position-relative text-center bg-transparent" />
                                </span>
                            </div>
                        </div>
                        :
                        <div className='col-12 col-md-5 d-flex justify-content-end flex-wrap'>
                            <div className='d-flex align-items-center gap-4 flex-wrap '>
                                <MessageBox text={formatter.format(store?.artist_followers_count)} classname="theme-text-color" />
                                <FollowButton artistDetails={store} pageType={source == "artists" ? "ARTIST" : source == "song-writer" ? "WRITER" : "MUSICIAN"} />
                            </div>
                        </div>
                    }
                </>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    languageDetailsData: state.get_getUserTranslateLanguage_Reducer.languageDetailsData,
    currentAudio: state.get_current_Reducers.currentAudio,
    playlistShow: state.get_Playlist_Modal.playlistShow,
    currentPlaying: state.get_current_Reducers.currentPlaying,
})
export default connect(mapStateToProps)(ListLayout)
