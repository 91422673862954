// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --primary-color: #03142a;
    --secondary-color: #868686;
    --active-button-color: #FAB818;
    --bg-color: rgb(31, 34, 42);
    --bg-opacity-color: rgb(21, 24, 32);
    --btn-color: #0d6efd;
}`, "",{"version":3,"sources":["webpack://./src/Styles/Default-theme.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,0BAA0B;IAC1B,8BAA8B;IAC9B,2BAA2B;IAC3B,mCAAmC;IACnC,oBAAoB;AACxB","sourcesContent":[":root {\n    --primary-color: #03142a;\n    --secondary-color: #868686;\n    --active-button-color: #FAB818;\n    --bg-color: rgb(31, 34, 42);\n    --bg-opacity-color: rgb(21, 24, 32);\n    --btn-color: #0d6efd;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
