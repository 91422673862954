// External Libraries and Packages
import { Link } from 'react-router-dom'
import { BiWorld } from 'react-icons/bi'
import { FaRegUser } from 'react-icons/fa'
import { MdContentPaste, MdOutlineLiveTv } from 'react-icons/md'
import { RxHamburgerMenu } from 'react-icons/rx';
// Internal Styles
// Internal Componentsimport React from 'react'
import { LogOut, capsFirstLetter } from '../../../Pages/CommonMethods/CommonMethods'

const AdvertiserHeader = ({ toggleAction }) => {
  let userName, advertiserName;
  if (localStorage.getItem("username")) {
    userName = localStorage.getItem("username");
  } else {
    advertiserName = localStorage.getItem("advertiser-name");
  }
  const capitalizedUsername = userName ? capsFirstLetter(userName) : capsFirstLetter(advertiserName);

  const toggleAdvertiseNavbar = () => {
    toggleAction()
  }
  return (
    <nav className="theme-bg-color  container-fluid py-2 py-sm-3 advertiserHeader">
      <ul className="d-flex align-items-center justify-content-between p-0 m-0 list-unstyled">
        <li>
          <button type="button" onClick={toggleAdvertiseNavbar} className="bg-transparent d-inline-flex p-2 toggleAdminButton">
            <RxHamburgerMenu className="adminIcon" />
          </button>
        </li>
        <li>
          <div className="d-flex align-items-center justify-content-center gap-3">
            <Link className="btn btn-primary  rounded-2 border-0 d-flex px-4 px-sm-3  py-1 py-sm-2" to="/channel"> <MdOutlineLiveTv className="adminIcon me-2" ></MdOutlineLiveTv> <span className="d-none d-sm-block">Channel Portal</span></Link>
            <Link className="btn btn-primary  rounded-2 border-0 d-flex px-4 px-sm-3  py-1 py-sm-2" to="/cpp"> <MdContentPaste className="adminIcon me-2" ></MdContentPaste> <span className="d-none d-sm-block">CPP Portal</span></Link>
            <Link className="btn btn-primary rounded-2 border-0 d-flex px-4 px-sm-3  py-1 py-sm-2" to="/"> <BiWorld className="adminIcon me-2" /> <span className="d-none d-sm-block">Website</span></Link>
          </div>
        </li>
        <li className="position-relative">
          <div className="d-flex align-items-center  py-2 px-3 global-shadow channelSideMenu">
            <FaRegUser fill='currentColor' className="z-index-for-channel position-relative" />
            <span className="ms-2 theme-text-color d-none d-sm-block z-index-for-channel position-relative">{capitalizedUsername}</span>
            <ul className="list-unstyled d-none channelDropDown theme-bg-color py-2 pt-4">
              <li ><Link to="/advertiser/my-profile" className="p-2 mb-2 theme-text-color channelLink w-100"> My Profile</Link></li>
              <li><button type="button" className="bg-transparent p-2  theme-text-color channelLink w-100" onClick={() => LogOut('/advertiser/signin')}>Logout</button></li>
            </ul>
          </div>
        </li>
      </ul>
    </nav>
  )
}

export default AdvertiserHeader