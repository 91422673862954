import React, { useEffect, useState } from 'react'
import BannerPlayer from './BannerPlayer'
import { connect, useDispatch } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, EffectCoverflow } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-cube';
import "swiper/css/effect-coverflow";
import "./Banner.css"
import { homeBannerAction } from '../../../../Redux/Actions/apiActions';
import BannerImage from './BannerImage';
const BannerIndex = ({ sliderData, isLoading, error }) => {
    const [activeIndexOur, setActiveIndex] = useState(0);
    const handleSlideChange = (swiper) => {
        setActiveIndex(swiper.activeIndex)
    };
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(homeBannerAction());
    }, [dispatch]);

    const swiperConfig = {
        slidesPerView: 1,
        spaceBetween: 10,
        navigation: true,
        lazy: true,
        centeredSlides: true,
        effect: 'coverflow',
        grabCursor: true,
        loop: false,
        // autoplay:false,
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
        },
        coverflowEffect: {
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 2.5
        },
        breakpoints: {
            769: { slidesPerView: 2, spaceBetween: 10 },
        },
        modules: [Autoplay, Navigation, EffectCoverflow],
        className: 'homePageBannerSwiperMulti',
        onSlideChange: { handleSlideChange }
    }

    return (
        <div className=''>
            {isLoading ? <Swiper {...swiperConfig} >
                {Array.from({ length: 8 }).map((value) => (
                    <SwiperSlide key={value} className='' >
                        <div className='bannerContainer w-100 h-100 theme-bg-color-secondary'></div>
                    </SwiperSlide>))}
            </Swiper> : <Swiper {...swiperConfig}  >
                {sliderData?.length >= 0 && sliderData?.map((item, index) => (
                    <SwiperSlide key={index} >
                        {item.trailer_videos_url || item?.trailer_link ? <BannerPlayer data={item} index={index} sliderCurrentIndex={activeIndexOur} page="home" /> : <BannerImage data={item} page="home" />}
                    </SwiperSlide>
                ))
                }
            </Swiper >}
        </div >
    )
}

const mapStateToProps = state => ({
    sliderData: state.get_HomeSlider_Reducer.sliderData,
    isLoading: state.get_HomeSlider_Reducer.isLoading,
    error: state.get_HomeSlider_Reducer.error,

});

export default connect(mapStateToProps)(BannerIndex)