import React from 'react'
import MessageBox from '../../../components/Others/MessageBox'


const AdminCustomButton = ({ saveMethod, saveText, className }) => {
    return (
        <button onClick={saveMethod} className={`${className ? className : ""} btn button-color button-bg-color admin-button-text text-white rounded-2  py-2 px-3 `}><MessageBox text={saveText} /></button>
    )
}

export default AdminCustomButton