// External Libraries and Packages
import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
// Internal Styles
import "swiper/css";
import "swiper/css/navigation";
import HomePageNav from '../CommonComponents/HomePageNav';
import MusicWithPlayCard from '../CommonComponents/MusicWithPlayCard';
import { connect } from 'react-redux';

const MusicListFormat = ({ item, swiperOptions ,languageDetailsData}) => {
    return (
        <div className='mb-4'>
            <HomePageNav link={item?.source} headerName={item?.header_name} store={item?.data} seg={`${languageDetailsData?.Language_Code}/music/`} />
            <Swiper {...swiperOptions} className='musicHomePageSwiper'>
                {item?.data?.map((value, index) => (
                    <SwiperSlide key={value?.id} >
                        <MusicWithPlayCard store={value} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}
const mapStateToProps = state => ({
    languageDetailsData: state.get_getUserTranslateLanguage_Reducer.languageDetailsData
})
export default connect(mapStateToProps)(MusicListFormat)