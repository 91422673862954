import React, { useEffect } from 'react'
import LazyLoadImage from '../../Pages/HomePage/HomeSwiperComponents/LazyLoadImage';
import { IconMyPencil, IconMyPlus } from '../../Images/MyIcons';
import { useState } from 'react';
import MessageBox from '../Others/MessageBox';
import { connect } from 'react-redux';
import axios from 'axios';
import { Link, useNavigate, useParams } from "react-router-dom";
import { getItem, getItemToken } from '../../Utils/localStorageUtils';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import PageLoader from '../Others/PageLoader';
import { Message } from 'rsuite';

const AccountProfiles = ({ languageDetailsData, currentTheme, profileDetails }) => {
    const { lang, id } = useParams()
    const { t } = useTranslation();

    const userRoleLocal = getItem('role')
    const userIdLocal = getItem('user_id')
    const userAccessToken = getItemToken('access_token');

    const [SubcriberUser, setSubcriberUser] = useState({
        limit: 0,
        userDetails: {},
        multiProfile: {}
    });
    const [loading, setLoading] = useState(true)
    const [domReload, setDomReload] = useState(false)
    const navigate = useNavigate();


    const fetchProfileData = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`${process.env.REACT_APP_Baseurl}/Multi-Profile/index`, { headers: getItemToken("access_token") });
            const result = response?.data
            if (result?.status) {
                setSubcriberUser((prev) => ({
                    ...prev,
                    limit: result?.Multi_User_Limit,
                    userDetails: result?.subcriber_user,
                    multiProfile: result?.Multi_users
                }))
                setLoading(false)
            }
        } catch (error) {
            console.error("Error fetching data:", error);

        }
    };

    const chooseProfile = (data) => {
        localStorage.setItem("MultiProfile", true);
        localStorage.setItem("MultiProfileId", data);
        navigate("/");
    }
    const handleCreateProfile = async () => {
        const formData = new FormData();
        formData.append("user_id", userIdLocal);

        try {
            const res = await axios.post(`${process.env.REACT_APP_Baseurl}/Multi-Profile/store`, formData, {
                headers: userAccessToken
            });

            if (res?.data?.status) {
                setDomReload(!domReload);
            }
        } catch (err) {
            toast.error(err?.response?.data?.message || "Error occurred while creating profile");
        }
    };

    useEffect(() => {
        fetchProfileData();
    }, [domReload]);
    return (
        <section>
            <h4 className='small-md-heading mb-2'>User Profile</h4>
            {loading ? <PageLoader className="d-flex justify-content-center align-items-center py-5 my-5" /> : <>
                {SubcriberUser?.multiProfile?.map((item, index) => {
                    return (

                        <div className='d-flex align-items-center justify-content-between border-3 border-bottom theme-border-color '>
                            <div className='d-flex align-items-center gap-2 my-3'>
                                <LazyLoadImage src={item?.image_url} alt="header-logo" imageSize=" object-fit-cover " classNameOptional=" rounded-circle themeTwoProfileAvatar" />
                                {item?.user_name && <p>{item?.user_name}</p>}
                            </div>
                            <Link to={`/${lang}/myprofile/multi-profile/edit/${item?.id}`} className='p-2 rounded-circle theme-bg-color-secondary theme-text-color d-flex align-items-center justify-content-center'>
                                <IconMyPencil styled={{ width: "25px", height: "25px", className: "theme-text-color" }} />
                            </Link>
                        </div>)

                })}
                {Number(SubcriberUser?.limit) >= Number(SubcriberUser?.multiProfile?.length) && <Link to={`/${lang}/myprofile/multi-profile/create`}>
                    <div className="d-flex align-items-center justify-content-between border-3 border-bottom theme-border-color" >
                        <div className="d-flex align-items-center gap-2 my-3">
                            <button type='button' className='themeTwoProfileAvatar rounded-circle' > <IconMyPlus styled={{ width: "25px", height: "25px", className: "" }} /></button>
                            <MessageBox text={t('Add Profile')} classname="theme-text-color mt-2 mb-0 text-center" />
                        </div>
                    </div>
                </Link>}
                {/* {Number(SubcriberUser?.limit) < Number(SubcriberUser?.multiProfile?.length) && <MessageBox text={`You can only create up to ${Number(SubcriberUser?.limit)} profiles.You have reached the profile limit. You can edit a profile's category to watch either kids' content or default profile videos.`} />} */}
            </>}
        </section>
    )
}
const mapStateToProps = state => ({
    languageDetailsData: state.get_getUserTranslateLanguage_Reducer.languageDetailsData,
    profileDetails: state?.get_profileDetails_Reducer?.profileDetails,
    currentTheme: state.get_headerIndex_Reducer.currentTheme,
})
export default connect(mapStateToProps)(AccountProfiles)

