// External Libraries and Packages
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { FaPlay } from 'react-icons/fa'
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
// Internal Styles
import "swiper/css";
import "swiper/css/navigation";
import LazyLoadImage from './LazyLoadImage';
import { useTranslation } from 'react-i18next';
// Internal Components
import { URLGenerater } from '../../CommonMethods/CommonMethods';
import { connect } from 'react-redux';
const AudioAlbumAll = ({ item, languageDetailsData }) => {
    const { t } = useTranslation();
    const { lang } = useParams();
    return (
        <div className='px-3 px-md-4 px-lg-4 pt-3 zIndex'>
            {
                item?.data.length > 0 && (<>
                    <Link to={`/${lang}/${URLGenerater(item?.source)}`} className="blocklinetitle text-decoration-none  d-inline-block  swiperHeading theme-text-color">{t(item?.header_name?.charAt(0).toUpperCase() + item?.header_name?.slice(1))}  </Link>
                    <Link to={`/${lang}/${URLGenerater(item?.source)}`} className="blocklinetitle text-decoration-none float-end  swiperHeading theme-text-color  viewall-homepage ">{t('View All')} </Link>
                </>)
            }
            <div className="">
                <Swiper
                    slidesPerView={2}
                    spaceBetween={15}
                    navigation={true}
                    lazy={true}
                    slidesPerGroup={2}
                    breakpoints={{
                        376: {
                            slidesPerView: 2,
                            spaceBetween: 15,
                            slidesPerGroup: 2,
                        },
                        576: {
                            slidesPerView: 3,
                            spaceBetween: 15,
                            slidesPerGroup: 3,
                        },
                        768: {
                            slidesPerView: 4,
                            spaceBetween: 15,
                            slidesPerGroup: 4,
                        },
                        1024: {
                            slidesPerView: 5,
                            spaceBetween: 15,
                            slidesPerGroup: 5,
                        },
                        1200: {
                            slidesPerView: 6,
                            spaceBetween: 15,
                            slidesPerGroup: 6,
                        }

                    }}
                    modules={[Navigation]}
                    className={`homePageSwiper categorySizeSmall `} >
                    {item?.data?.map((value, index) => (
                        <SwiperSlide key={value?.id} className='theme-bg-color-secondary'>
                            <Link to={`/${lang}/music/${item?.source == "audios"? "track" : "album"}/${value?.slug}`} className="text-decoration-none  swiperHeading theme-text-color  categoryHover   d-block h-100 w-100" >
                                {/* <img src={value?.image_url} loading="lazy" className={` loading  d-block w-100 objectFitCover h-100 rounded-3`} /> */}
                                <LazyLoadImage key={value?.id} src={value?.image_url} alt={value?.title ? value?.title : value.albumname} title={value?.title ? value?.title : value.albumname} imageSize="loaderMain object-fit-cover" classNameOptional="rounded-3" />
                                {/* <div className="homeSwiperContainer">
                                    <img src={value.image_url} className="homeListImage" loading="lazy" />
                                    <div className="homeListContent p-3">
                                        <button className="homeListButton d-flex align-items-center justify-content-center">
                                            <FaPlay className="homeListPlayIcon" />
                                            <p className="ms-2 mb-0 homeListButtonText"> Watch now</p>
                                        </button>
                                        <p className="homeListText text-start">{value.albumname}</p>
                                        <p className="homeListDesc text-start">
                                            In publishing and graphic design, Lorem ipsum is a
                                            placeholder text commonly used.
                                        </p>
                                    </div>
                                </div> */}
                            </Link>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    )
}
const mapStateToProps = state => ({
    languageDetailsData: state.get_getUserTranslateLanguage_Reducer.languageDetailsData,
})

export default connect(mapStateToProps)(AudioAlbumAll)