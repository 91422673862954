import React from 'react';
// import './NextVideosSlider.css';

const NextVideosSlider = ({ videos, onVideoSelect }) => {
  return (
    <div className="next-videos-slider">
      {videos.map((video) => (
        <div key={video.id} className="video-item" onClick={() => onVideoSelect(video)}>
          <img src={video.thumbnail} alt={video.title} className="video-thumbnail" />
          <div className="video-title">{video.title}</div>
        </div>
      ))}
    </div>
  );
};

export default NextVideosSlider;
