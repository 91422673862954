// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-container{
    position: relative;
}
.image-container:hover{
    transform: scale(1.38);
    transition: transform 0.5s;
    position: relative;
}


.image-container:hover .overlay{
    display: block;
    position: absolute;
    transform: scale(1.32);
    top: 0%;
    color: white;

}
.image-container .overlay{
    display: none;
}

.block-desc .text-white{
    font-size: 10px;
}
.hover-image{
height: 15px;
}

.hover-image img{
    object-fit: contain;
    width: 100%;
}

`, "",{"version":3,"sources":["webpack://./src/Pages/Channel_portal-page/Menu.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;AACA;IACI,sBAAsB;IACtB,0BAA0B;IAC1B,kBAAkB;AACtB;;;AAGA;IACI,cAAc;IACd,kBAAkB;IAClB,sBAAsB;IACtB,OAAO;IACP,YAAY;;AAEhB;AACA;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;AACnB;AACA;AACA,YAAY;AACZ;;AAEA;IACI,mBAAmB;IACnB,WAAW;AACf","sourcesContent":[".image-container{\n    position: relative;\n}\n.image-container:hover{\n    transform: scale(1.38);\n    transition: transform 0.5s;\n    position: relative;\n}\n\n\n.image-container:hover .overlay{\n    display: block;\n    position: absolute;\n    transform: scale(1.32);\n    top: 0%;\n    color: white;\n\n}\n.image-container .overlay{\n    display: none;\n}\n\n.block-desc .text-white{\n    font-size: 10px;\n}\n.hover-image{\nheight: 15px;\n}\n\n.hover-image img{\n    object-fit: contain;\n    width: 100%;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
