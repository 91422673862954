import React from "react";
import OwnSchedule from "./OwnSchedule"
function Schedule() {
  return (
    <div className="px-3 px-lg-5 paddingTop">
      <OwnSchedule />
    </div>
  );
}

export default Schedule;
