// External Libraries and Packages
import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { BiError } from 'react-icons/bi';
// Internal Styles
import "../../../Pages/HomePage/Video_details.css"
import "./Details.css"
// Internal Components
import { videoDetailsAction } from '../../../Redux/Actions/apiActions';
import Error404 from '../../../Error404/Error404';
import CommonViewDetail from '../../Others/CommonViewDetail';
import LoaderPage from '../../../Layout/LoaderPage';
import HelmetDetails from '../../Others/HelmetDetails';
const VideosDetails = ({ videoDetailsData, isLoading, error, settings }) => {
  const { slug } = useParams()
  const user_role = localStorage.getItem("role");
  const user_id = localStorage.getItem("user_id");
  const access_token = localStorage.getItem("access_token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [domReload, setDomReload] = useState(false)
  const currentPageURL = window.location.href
  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    if (settings && Object.keys(settings).length > 0) {
      !user_role && settings.access_free == 1
        ? dispatch(videoDetailsAction(null, 9, "web", slug, headers))
        : user_role && dispatch(videoDetailsAction(user_id, user_role, "web", slug, headers));
    }
  }, [settings, user_role, domReload])

  const handleDOM = () => {
    setDomReload(!domReload)
  }
  

 return (
    <>
      <div className='paddingTop'>
        {error ? <Error404 /> : isLoading ? <LoaderPage /> :
          videoDetailsData !== null ? <>
            <HelmetDetails metaURL={currentPageURL} metaTitle={videoDetailsData?.title ? videoDetailsData.title?.toUpperCase() : videoDetailsData?.name?.toUpperCase()} metaDescription={videoDetailsData?.description?.toString().substring(0, 100)} metaImage={videoDetailsData?.Player_thumbnail} pageTitle={videoDetailsData?.title ? videoDetailsData.title?.toUpperCase() : videoDetailsData?.name?.toUpperCase()} />
            <CommonViewDetail data={videoDetailsData} navigationName="Videos" categoryUrl="/video-categories" playerUrl="video" reloadAction={handleDOM} codeNumber="1" />
          </>
          : <div className="py-5 text-center"><BiError style={{ fontSize: "50px" }} /><h3> NO DATA FOUND</h3></div>
        }
      </div>
    </>
  )
}
const mapStateToProps = state => ({
  videoDetailsData: state.get_videoDetails_Reducer.videoDetailsData,
  isLoading: state.get_videoDetails_Reducer.isLoading,
  error: state.get_videoDetails_Reducer.error,
  settings: state.get_setting_Reducer.settings
});
export default connect(mapStateToProps)(VideosDetails)