import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx";
import { MdContentPaste } from "react-icons/md";
import { BiWorld } from "react-icons/bi";
import { FaRegUser } from "react-icons/fa6";
import "./ContentHeader.css";
import { LogOut, capsFirstLetter } from "../../Pages/CommonMethods/CommonMethods";
import axios from "axios";
import MessageBox from "../../components/Others/MessageBox";
import { CgProfile } from "react-icons/cg";
import { RiLogoutCircleRLine } from "react-icons/ri";

function ContentHeader({ toggleAction }) {
  // const access_token = localStorage.getItem("access_token");
  let userName, contentName;
  if (localStorage.getItem("username")) {
    userName = localStorage.getItem("username");
  } else {
    contentName = localStorage.getItem("cpp_name");
  }

  const capitalizedUsername = userName ? capsFirstLetter(userName) : capsFirstLetter(contentName);

  const [editcppchannel, setEditcppchannel] = useState({
    cpp_portal: 1,
    channel_portal: 1,
  });

  const [inputFieldchannel, setinputFieldchannel] = useState({
    email: "admin@webnexs.in",
    password: "Webnexs123!@#",
  });

  const handleChangechannel = () => {
    if (localStorage.getItem("email") !== inputFieldchannel?.email) {
      window.location.href = "/channel/login";
      return;
    } else {
      loginchannel();
    }
  }

  // Function to perform login
  async function loginchannel() {
    axios
      .post(
        `${process.env.REACT_APP_Baseurl}/channel-auth/sign-in`,
        { email: inputFieldchannel?.email, password: inputFieldchannel?.password },
        { cors: "no-cors" },
        { credentials: "include" },
        { credentials: "same-origin" },
      )
      .then((res) => {
        let access_token = res.data.access_token;
        let expires_in = res.data.expires_in;
        let channel_name = res.data.Channel_user.channel_name;
        let user_id = res.data.Channel_user.id;
        // let role = res.data.Channel_user.role;
        let channel_image = res.data.Channel_user.channel_image;
        localStorage.setItem("access_token_channel", access_token);
        localStorage.setItem("Channel_Partner_id", user_id);
        // localStorage.setItem("role", role);
        localStorage.setItem("channel_name", channel_name);
        localStorage.setItem("channel_image", channel_image);
        localStorage.setItem("channel_expires_in", expires_in);
        // localStorage.setItem("user", inputField)
        setTimeout(() => {
          window.location.href = "/channel";
        }, 1000);
      })
      .catch((err) => {
        window.location.href = "/channel/login";
        // console.log(err);
      });
  }


  return (
    <nav className="theme-bg-color  container-fluid px-2 px-sm-3  contentHeader">
      <ul className="d-flex align-items-center justify-content-between p-0 m-0 list-unstyled">
        <li>
          <button type="button" onClick={() => toggleAction()} className="bg-transparent d-inline-flex p-2 toggleAdminButton">
            <RxHamburgerMenu className="adminIcon" />
          </button>
        </li>
        <li>
          <div className="d-flex align-items-center justify-content-center gap-3">
            {editcppchannel?.channel_portal === 1 ? (
              <Link className="btn btn-primary  rounded-2 border-0 d-flex px-4 px-sm-3  py-1 py-sm-2" onClick={handleChangechannel}> <MdContentPaste className="adminIcon me-2" ></MdContentPaste> <span className="d-none d-sm-block">Channel Portal</span></Link>
            ) : null}
            <Link className="btn btn-primary rounded-2 border-0 d-flex px-4 px-sm-3  py-1 py-sm-2" to="/"> <BiWorld className="adminIcon me-2" /> <span className="d-none d-sm-block">Website</span></Link>
          </div>
        </li>
        <li className="position-relative adminDropDownContainer py-3 ">
          <button className="d-flex align-items-center px-3 py-2 bg-transparent shadow rightSideButton">
            <FaRegUser style={{ fill: "currentColor", width: "20px", height: "20px" }} />
            <p className="mb-0 userText"><MessageBox text={`${capitalizedUsername}`} /></p>
          </button>
          <ul className="adminSideDropDown list-unstyled">
            <li>
              <Link className="d-flex align-items-center px-3 py-3 border-bottom theme-text-color" to="/cpp/my-profile" >
                <CgProfile className="exportIcon me-2" />My Profile
              </Link>
            </li>
            <li>
              <button type="button" className="bg-transparent border-0  theme-text-color d-flex align-items-center px-3 py-3" to="/" onClick={() => LogOut('/cpp/login')}>
                <RiLogoutCircleRLine className="exportIcon me-2" /> Logout
              </button>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  );
}

export default ContentHeader;