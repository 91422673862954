// import React, { useEffect, useState } from "react";
// import { FaCrown } from "react-icons/fa6";
// import { BsCurrencyDollar } from "react-icons/bs";
// import { connect } from 'react-redux';
// import { useTranslation } from 'react-i18next';
// import axios from 'axios';

// const PriceLabel = ({ item, thumbnailFreeORCostLabel }) => {
//     const { t } = useTranslation();

//     const [userCurrency, setUserCurrency] = useState('');
//     const [convertedPrice, setConvertedPrice] = useState(null);
//     const [currency, setcurrency] = useState({ code: 'USD' }); // Assuming item is already available


//     const allCurrency = [
//         {
//             code: "USD",
//             country: "United States",
//             symbol: "$",
//         },
//         {
//             code: "INR",
//             country: "India",
//             symbol: "₹",
//         },
//         // Add more currencies as needed
//     ];


//     useEffect(() => {
//         // Fetch IP-based location
//         const fetchIPLocation = async () => {
//             try {
//                 // Simulated location data
//                 const locationData = {
//                     country: "India",
//                     // You can get the country name from the IP API response
//                 };

//                 // Find the currency code for the country
//                 const currencyData = allCurrency?.find(currency => currency?.country === locationData?.country);
//                 if (currencyData) {
//                     setUserCurrency(currencyData?.code);
//                 } else {
//                     console.error('Currency data not found for the country:', locationData?.country);
//                 }
//             } catch (error) {
//                 console.error('Error fetching IP location:', error);
//             }
//         };

//         fetchIPLocation();
//     }, []);

//     useEffect(() => {
//         // Calculate converted price
//         const convertPrice = () => {
//             if (userCurrency && currency?.code !== userCurrency) {
//                 // Fetch conversion rate or use hardcoded rates
//                 // For simplicity, I'm hardcoding conversion rates here
//                 const conversionRates = {
//                     'USD': 1,
//                     'INR': 75,
//                     // Add conversion rates for other currencies here
//                 };

//                 const conversionRate = conversionRates[userCurrency];
//                 if (conversionRate) {
//                     const convertedAmount = item?.ppv_price * conversionRate;
//                     setConvertedPrice(convertedAmount);
//                 } else {
//                     console.error('Conversion rate not found for currency:', userCurrency);
//                 }
//             } else {
//                 setConvertedPrice(item?.ppv_price);
//             }
//         };

//         convertPrice();
//     }, [userCurrency, item]);

//     return (
//         <>
//             {thumbnailFreeORCostLabel ?
//                 <div className=" theme-text-color  theme-button-bg-color  position-absolute priceLabel ">
//                     <div>
//                         {convertedPrice !== null ? (
//                             <div>
//                                 <p>Price in your currency: {convertedPrice} {userCurrency}</p>
//                             </div>
//                         ) : (
//                             <p>Loading currency?...</p>
//                         )}
//                     </div>

//                     {item?.access === "subscriber" ?
//                         (<p className="priceText d-flex align-items-center"> <FaCrown style={{ width: "18px", height: "18px", color: "gold" }} /></p>)
//                         : item?.ppv_price !== null && item?.ppv_price !== '' ?
//                             (<p className="priceText d-flex align-items-center"><BsCurrencyDollar />{item?.ppv_price}</p>)
//                             : item?.global_ppv !== null && (item?.global_ppv !== null && item?.ppv_price === null)
//                                 ? (<p className="priceText d-flex align-items-center"><BsCurrencyDollar />{item?.global_ppv}</p>)
//                                 : item?.global_ppv === null && item?.ppv_price === null ?
//                                     (<p className="priceText d-flex align-items-center">{t("Free")}</p>) : (
//                                         <p className="priceText d-flex align-items-center">{t("Free")}</p>)
//                     }
//                 </div> : ""}
//         </>
//     )
// }
// const mapStateToProps = state => ({
//     thumbnailFreeORCostLabel: state.get_allThumbnail_Reducer.thumbnailFreeORCostLabel
// });
// export default connect(mapStateToProps)(PriceLabel)

// import React, { useEffect, useState } from "react";
// import { FaCrown } from "react-icons/fa";
// import { BsCurrencyDollar } from "react-icons/bs";
// import { connect } from 'react-redux';
// import { useTranslation } from 'react-i18next';
// import axios from 'axios';

// const PriceLabel = ({ thumbnailFreeORCostLabel }) => {
//     const { t } = useTranslation();

//     const [userCurrency, setUserCurrency] = useState('');
//     const [convertedPrice, setConvertedPrice] = useState(null);
//     const [currency, setCurrency] = useState({ code: 'Japan' }); // Assuming item is already available
//     const [item, setItem] = useState({ ppv_price: 12, global_ppv: 34 }); // Assuming item is already available


//   const [locationData, setLocationData] = useState(null);

//     const allCurrency = [
//         {
//             code: "USD",
//             country: "United States",
//             symbol: "$",
//         },
//         {
//             code: "INR",
//             country: "India",
//             symbol: "₹",
//         },
//         {
//             country: "Albania",
//             code: "ALL",
//             symbol: "Lek",
//         },
//         {
//             country: "Argentina",
//             code: "ARS",
//             symbol: "$",
//         },
//         {
//             country: "Azerbaijan",
//             code: "AZN",
//             symbol: "ман",
//         },
//         {
//             country: "Japan",
//             code: "JPY",
//             symbol: "¥",
//         },
//         // Add more currencies as needed
//     ];

//     useEffect(() => {
//         // Fetch IP-based location

//         const fetchIPLocationdata = async () => {
//             try {
//               const response = await axios.get('http://ip-api.com/json/');
//               const { city, regionName: region, country, lat, lon: longitude, query: ip } = response.data;

//               setLocationData({ city, region, country, latitude: lat, longitude, ip });
//             } catch (error) {
//               console.error('Error fetching IP location:', error);
//             }
//           };

//           fetchIPLocationdata();

//         const fetchIPLocation = async () => {
//             try {

//                 // Find the currency code for the country
//                 const currencyData = allCurrency?.find(currency => currency?.country === locationData?.country);
//                 if (currencyData) {
//                     setUserCurrency(currencyData?.symbol);
//                 } else {
//                     console.error('Currency data not found for the country:', locationData?.country);
//                 }
//             } catch (error) {
//                 console.error('Error fetching IP location:', error);
//             }
//         };

//         fetchIPLocation();
//     }, []);

//     useEffect(() => {
//         // Fetch conversion rate when userCurrency changes
//         const fetchConversionRate = async () => {
//             try {
//                 const response = await axios.get(`https://api.exchangerate-api.com/v4/latest/${currency?.code}`);
//                 const data = response.data;
//                 if (data && data?.rates && data?.rates[userCurrency]) {
//                     const conversionRate = data?.rates[userCurrency];
//                     const convertedPPVPrice = item?.ppv_price ? item?.ppv_price * conversionRate : null;
//                     const convertedGlobalPPV = item?.global_ppv ? item?.global_ppv * conversionRate : null;
//                     setConvertedPrice({ ppv_price: convertedPPVPrice, global_ppv: convertedGlobalPPV });
//                 } else {
//                     console.error('Conversion rate not found for currency:', userCurrency);
//                 }
//             } catch (error) {
//                 console.error('Error fetching conversion rate:', error);
//             }
//         };

//         if (userCurrency && currency?.code !== userCurrency) {
//             fetchConversionRate();
//         } else {
//             setConvertedPrice(item); // Set default prices if userCurrency is not set or unchanged
//         }
//     }, [userCurrency, item]);

//     return (
//         <>
//             {thumbnailFreeORCostLabel ?
//                 <div className="theme-text-color theme-button-bg-color position-absolute priceLabel">
//                     <div>
//                         {convertedPrice !== null ? (
//                             <div>
//                                 <p>{userCurrency} {convertedPrice?.ppv_price !== null ? Number(convertedPrice?.ppv_price).toFixed(2) : "N/A"} </p>
//                             </div>
//                         ) : (
//                             <p>Loading currency?...</p>
//                         )}
//                     </div>

//                     {item?.access === "subscriber" ?
//                         (<p className="priceText d-flex align-items-center"> <FaCrown style={{ width: "18px", height: "18px", color: "gold" }} /></p>)
//                         : convertedPrice && convertedPrice?.ppv_price !== null && convertedPrice?.ppv_price !== '' ?
//                             (<p className="priceText d-flex align-items-center"><BsCurrencyDollar />{convertedPrice?.ppv_price}</p>)
//                             : convertedPrice && convertedPrice?.global_ppv !== null && (convertedPrice?.global_ppv !== null && convertedPrice?.ppv_price === null)
//                                 ? (<p className="priceText d-flex align-items-center"><BsCurrencyDollar />{convertedPrice?.global_ppv}</p>)
//                                 : convertedPrice && convertedPrice?.global_ppv === null && convertedPrice?.ppv_price === null ?
//                                     (<p className="priceText d-flex align-items-center">{t("Free")}</p>) : (
//                                         <p className="priceText d-flex align-items-center">{t("Free")}</p>)
//                     }
//                 </div> : ""}
//         </>
//     )
// }
// const mapStateToProps = state => ({
//     thumbnailFreeORCostLabel: state.get_allThumbnail_Reducer.thumbnailFreeORCostLabel
// });
// export default connect(mapStateToProps)(PriceLabel);


// import React, { useEffect, useState } from "react";
// import { FaCrown } from "react-icons/fa";
// import { BsCurrencyDollar } from "react-icons/bs";
// import { connect, useDispatch } from 'react-redux';
// import { useTranslation } from 'react-i18next';
// import axios from 'axios';

// const PriceLabel = ({ thumbnailFreeORCostLabel }) => {
//     const { t } = useTranslation();
//     const dispatch = useDispatch()

//     const [userCurrency, setUserCurrency] = useState('');
//     const [convertedPrice, setConvertedPrice] = useState(null);
//     const [currency, setCurrency] = useState({ code: 'USD' }); // Assuming item is already available
//     const [item, setItem] = useState({ ppv_price: 12, global_ppv: 34 }); // Assuming item is already available
//     const [locationData, setLocationData] = useState(null);

//     useEffect(() => {
//         dispatch({
//             type: "INITIAL_PRICE", payload: {
//                 ppv_price: "234",
//                 global_price: "23"
//             }
//         })
//     }, [])


//     const allCurrency = [
//         {
//             code: "USD",
//             country: "United States",
//             symbol: "$",
//         },
//         {
//             code: "INR",
//             country: "India",
//             symbol: "₹",
//         },
//         {
//             country: "Albania",
//             code: "ALL",
//             symbol: "Lek",
//         },
//         {
//             country: "Argentina",
//             code: "ARS",
//             symbol: "$",
//         },
//         {
//             country: "Azerbaijan",
//             code: "AZN",
//             symbol: "ман",
//         },
//         {
//             country: "Japan",
//             code: "JPY",
//             symbol: "¥",
//         },
//         // Add more currencies as needed
//     ];

//     useEffect(() => {
//         // Fetch IP-based location
//         const fetchIPLocationData = async () => {
//             try {
//                 const response = await axios.get('http://ip-api.com/json/');
//                 // dispatch({ type: "IP_PRICE", payload: response.data ,currentPrice:"78"})
//                 const { city, regionName: region, country, lat, lon: longitude, query: ip } = response.data;
//                 setLocationData({ city, region, country, latitude: lat, longitude, ip });
//             } catch (error) {
//                 console.error('Error fetching IP location:', error);
//             }
//         };

//         fetchIPLocationData();
//     }, []);

//     useEffect(() => {
//         const fetchIPLocation = async () => {
//             if (locationData) {
//                 try {
//                     // Find the currency code for the country
//                     const currencyData = allCurrency?.find(currency => currency?.country === locationData?.country);
//                     if (currencyData) {
//                         setUserCurrency(currencyData?.code);  // Set the currency code
//                         console.log(currencyData?.code);
//                     } else {
//                         console.error('Currency data not found for the country:', locationData?.country);
//                     }
//                 } catch (error) {
//                     console.error('Error fetching IP location:', error);
//                 }
//             }
//         };

//         fetchIPLocation();
//     }, [locationData]);

//     useEffect(() => {
//         // Fetch conversion rate when userCurrency changes
//         const fetchConversionRate = async () => {
//             try {
//                 const response = await axios.get(`https://api.exchangerate-api.com/v4/latest/${currency?.code}`);
//                 const data = response.data;
//                 console.log(response);
//                 console.log(response.data);
//                 if (data && data?.rates && data?.rates[userCurrency]) {
//                     const conversionRate = data?.rates[userCurrency];
//                     const convertedPPVPrice = item?.ppv_price ? item?.ppv_price * conversionRate : null;
//                     const convertedGlobalPPV = item?.global_ppv ? item?.global_ppv * conversionRate : null;
//                     setConvertedPrice({ ppv_price: convertedPPVPrice, global_ppv: convertedGlobalPPV });
//                 } else {
//                     console.error('Conversion rate not found for currency:', userCurrency);
//                 }
//             } catch (error) {
//                 console.error('Error fetching conversion rate:', error);
//             }
//         };

//         if (userCurrency) {
//             fetchConversionRate();
//         } else {
//             setConvertedPrice(item); // Set default prices if userCurrency is not set or unchanged
//         }
//     }, [userCurrency, item]);

//     return (
//         <>

//             {thumbnailFreeORCostLabel ?
//                 <div className="theme-text-color theme-button-bg-color position-absolute priceLabel">
//                     <div>
//                         {convertedPrice !== null ? (
//                             <div>
//                                 <p>{allCurrency?.find(currency => currency?.code === userCurrency)?.symbol} {convertedPrice?.ppv_price !== null ? Number(convertedPrice?.ppv_price).toFixed(2) : "N/A"} </p>
//                             </div>
//                         ) : (
//                             <p>Loading currency?...</p>
//                         )}
//                     </div>

//                     {item?.access === "subscriber" ?
//                         (<p className="priceText d-flex align-items-center"> <FaCrown style={{ width: "18px", height: "18px", color: "gold" }} /></p>)
//                         : convertedPrice && convertedPrice?.ppv_price !== null && convertedPrice?.ppv_price !== '' ?
//                             (<p className="priceText d-flex align-items-center"><BsCurrencyDollar />{convertedPrice?.ppv_price}</p>)
//                             : convertedPrice && convertedPrice?.global_ppv !== null && (convertedPrice?.global_ppv !== null && convertedPrice?.ppv_price === null)
//                                 ? (<p className="priceText d-flex align-items-center"><BsCurrencyDollar />{convertedPrice?.global_ppv}</p>)
//                                 : convertedPrice && convertedPrice?.global_ppv === null && convertedPrice?.ppv_price === null ?
//                                     (<p className="priceText d-flex align-items-center">{t("Free")}</p>) : (
//                                         <p className="priceText d-flex align-items-center">{t("Free")}</p>)
//                     }
//                 </div> : ""}
//         </>
//     )
// }
// const mapStateToProps = state => ({
//     thumbnailFreeORCostLabel: state.get_allThumbnail_Reducer.thumbnailFreeORCostLabel
// });
// export default connect(mapStateToProps)(PriceLabel);


































// import React, { useEffect, useState } from "react";
// import { FaCrown } from "react-icons/fa";
// import { BsCurrencyDollar } from "react-icons/bs";
// import { connect, useDispatch } from 'react-redux';
// import { useTranslation } from 'react-i18next';
// import axios from 'axios';
// import Ipgeolocation from 'react-ipgeolocation';

// const PriceLabel = ({ item, thumbnailFreeORCostLabel }) => {
//     const { t } = useTranslation();
//     const dispatch = useDispatch();

//     const [userCurrency, setUserCurrency] = useState('');
//     const [convertedPrice, setConvertedPrice] = useState({ ppv_price: null, global_ppv: null });
//     const [currency, setCurrency] = useState({ code: 'USD' });
//     // const [item, setItem] = useState({ ppv_price: 67, global_ppv: null, access: "guest" });
//     // const [locationData, setLocationData] = useState({ code: "AZN", country: "Azerbaijan", symbol: "ман" });

//     // const [locationData, setLocationData] = useState(null);

//     useEffect(() => {
//         dispatch({
//             type: "INITIAL_PRICE",
//             payload: {
//                 ppv_price: "234",
//                 global_price: "23"
//             }
//         });
//     }, [dispatch]);

//     const allCurrency = [
//         { code: "USD", country: "United States", symbol: "$" },
//         { code: "INR", country: "India", symbol: "₹" },
//         { code: "ALL", country: "Albania", symbol: "Lek" },
//         { code: "ARS", country: "Argentina", symbol: "$" },
//         { code: "AZN", country: "Azerbaijan", symbol: "ман" },
//         { code: "JPY", country: "Japan", symbol: "¥" },
//         // Add more currencies as needed
//     ];


//     const [locationData, setLocationData] = useState({
//         city: '',
//         region: '',
//         country: '',
//         latitude: '',
//         longitude: '',
//         ip: ''
//     });

//     const Token_get = "ca42191d019393"

//     console.log(locationData)

//     useEffect(() => {
//         const fetchIPLocationData = async () => {
//             try {
//                 const ipResponse = await axios.get(`${process.env.REACT_APP_API_IP_Location}`);
//                 const { ip } = ipResponse?.data;
//                 const locationResponse = await axios.get(`${process.env.REACT_APP_API_IP_Response_Location}/${ip}/json?token=${Token_get}`);
//                 const { city, region, country, loc } = locationResponse?.data;
//                 const [latitude, longitude] = loc.split(',');

//                 setLocationData({ city, region, country, latitude, longitude, ip });
//             } catch (error) {
//                 console.error('Error fetching IP location:', error);
//             }
//         };

//         fetchIPLocationData();
//     }, []);

//     // useEffect(() => {
//     //     const fetchIPLocationData = async () => {
//     //         try {
//     //             const response = await axios.get(`https://ip-api.com/json`);
//     //             const { city, regionName: region, country, lat, lon: longitude, query: ip } = response.data;
//     //             setLocationData({ city, region, country, latitude: lat, longitude, ip });
//     //         } catch (error) {
//     //             console.error('Error fetching IP location:', error);
//     //         }
//     //     };

//     //     fetchIPLocationData();
//     // }, []);

//     // const [loading, setLoading] = useState(true);
//     // const [error, setError] = useState(null);

//     // useEffect(() => {
//     //     const fetchLocationData = async () => {
//     //         try {
//     //             navigator.geolocation.getCurrentPosition(async (position) => {
//     //                 const { latitude, longitude } = position.coords;
//     //                 const locationResponse = await axios.get(`http://ip-api.com/json/${latitude},${longitude}`);
//     //                 const { country, city, regionName: region, query: ip } = locationResponse?.data;
//     //                 console.log(locationResponse?.data)
//     //                 console.log(latitude)
//     //                 console.log(longitude)
//     //                 // setLocationData({ latitude, longitude, country, city, region, ip });
//     //                 setLoading(false);
//     //             });
//     //         } catch (error) {
//     //             console.error('Error fetching location:', error);
//     //             setError('Failed to fetch location data');
//     //             setLoading(false);
//     //         }
//     //     };

//     //     fetchLocationData();
//     // }, []);

//     // useEffect(() => {
//     //     if (locationData && locationData?.country) {
//     //         // Fetch currency data or perform other operations based on the country
//     //         console.log('Country:', locationData?.country);
//     //     }
//     // }, [locationData]);

//     useEffect(() => {
//         if (locationData) {
//             const currencyData = allCurrency?.find(currency => currency?.country === locationData?.country);
//             if (currencyData) {
//                 setUserCurrency(currencyData?.code);
//             } else {
//                 console.error('Currency data not found for the country:', locationData?.country);
//             }
//         }
//     }, [locationData]);

//     useEffect(() => {
//         if (item?.access === "guest") {
//             setCurrency({ code: 'INR' });
//         }
//     }, [item?.access]);

//     useEffect(() => {
//         const fetchConversionRate = async () => {
//             if (!userCurrency) return;
//             try {
//                 const response = await axios.get(`${process.env.REACT_APP_API_Curreny_generate}/${currency?.code}`);
//                 const data = response.data;
//                 if (data && data?.rates && data?.rates[userCurrency]) {
//                     const conversionRate = data?.rates[userCurrency];
//                     const convertedPPVPrice = item?.ppv_price ? item?.ppv_price * conversionRate : null;
//                     const convertedGlobalPPV = item?.global_ppv ? item?.global_ppv * conversionRate : null;
//                     setConvertedPrice({ ppv_price: convertedPPVPrice, global_ppv: convertedGlobalPPV });
//                 } else {
//                     console.error('Conversion rate not found for currency:', userCurrency);
//                 }
//             } catch (error) {
//                 console.error('Error fetching conversion rate:', error);
//             }
//         };

//         fetchConversionRate();
//     }, [userCurrency, item, currency?.code]);

//     const renderPriceLabel = () => {
//         if (item?.access === "subscriber") {
//             return <p className="priceText d-flex align-items-center"><FaCrown style={{ width: "18px", height: "18px", color: "gold" }} /></p>;
//         } else if (convertedPrice) {
//             const symbol = allCurrency?.find(curr => curr.code === userCurrency)?.symbol || '';
//             if (convertedPrice?.ppv_price !== null) {
//                 return <p className="priceText d-flex align-items-center">{symbol} {Number(convertedPrice?.ppv_price).toFixed(2)}</p>;
//             } else if (convertedPrice?.global_ppv !== null) {
//                 return <p className="priceText d-flex align-items-center">{symbol} {Number(convertedPrice?.global_ppv).toFixed(2)}</p>;
//             }
//         }
//         return <p className="priceText d-flex align-items-center">{t("Free")}</p>;
//     };

//     return (
//         <>
//             {thumbnailFreeORCostLabel ? (
//                 <div className="theme-text-color theme-button-bg-color position-absolute priceLabel">
//                     {renderPriceLabel()}
//                 </div>
//             ) : ""}
//         </>
//     );
// };

// const mapStateToProps = state => ({
//     thumbnailFreeORCostLabel: state.get_allThumbnail_Reducer.thumbnailFreeORCostLabel
// });

// export default connect(mapStateToProps)(PriceLabel);

import React, { useEffect, useState } from "react";
import { FaCrown } from "react-icons/fa";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const PriceLabel = ({ item, thumbnailFreeORCostLabel, className }) => {
    const { t } = useTranslation();

    const [userCurrency, setUserCurrency] = useState('');
    const [convertedPrice, setConvertedPrice] = useState({ ppv_price: null, global_ppv: null, video_ppv_price: null });
    const [currency, setCurrency] = useState({ code: '', country: '', symbol: '', enable_multi_currency: '' });
    const [locationData, setLocationData] = useState({
        city: '',
        region: '',
        country: '',
        latitude: '',
        longitude: '',
        ip: '',
        symbol: '',
        code: '',
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchCurrencyData = async () => {
            try {
                const currencyResponse = await axios.get(`${process.env.REACT_APP_Baseurl}/Front-End/Currency-Index`);
                const currencyData = currencyResponse?.data;
                setCurrency(currencyData?.CurrencySetting);
            } catch (error) {
                console.error('Error fetching currency data:', error);
            }
        };

        const fetchLocationData = async () => {
            try {
                const locationResponse = await axios.get(`${process.env.REACT_APP_API_IP_Location}`);
                // const { city, region, country_code: country, country_code, IPv4: ip, latitude, longitude, state, postal } = locationResponse?.data;
                const city = locationResponse?.data?.city
                const region = locationResponse?.data?.region
                const continent_code = locationResponse?.data?.continent_code
                const country = locationResponse?.data?.country
                const country_code = locationResponse?.data?.country_code
                const country_code3 = locationResponse?.data?.country_code3
                const ip  = locationResponse?.data?.ip 
                const latitude  = locationResponse?.data?.latitude 
                const longitude  = locationResponse?.data?.longitude 
                const timezone  = locationResponse?.data?.timezone 
                const organization  = locationResponse?.data?.organization 
                // console.log(city, region, country, ip, latitude, longitude, state, postal, " city, region, country_code: country, ip, latitude, longitude ")

                // Ensure country code is defined before making the API request
                // console.log(country, city, region,continent_code,country_code,country_code3,ip, latitude,longitude,timezone,organization,   "country")
                // console.log(locationResponse?.data,   "locationResponse?.data")

                if (country) {
                    const currencyResponse = await axios.get(`${process.env.REACT_APP_country_code}/${country_code}`);
                    const symbol = currencyResponse?.data[0]?.currencies[Object.keys(currencyResponse?.data[0]?.currencies)[0]].symbol;
                    const code = Object.keys(currencyResponse?.data[0]?.currencies)[0];
                    setLocationData({ city, region, country, latitude, longitude, ip, symbol, code });
                    // console.log(city, region, country, latitude, longitude, ip, symbol, code, "{ city, region, country, latitude, longitude, ip, symbol, code }");
                    setUserCurrency(code);
                } else {
                    console.error('Country code is undefined.');
                }
            } catch (error) {
                console.error('Error fetching location data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchCurrencyData();
        fetchLocationData();
    }, []);

    useEffect(() => {
        const fetchConversionRate = async () => {
            if (!userCurrency || !currency?.code || currency?.enable_multi_currency !== 1) return;

            try {
                const response = await axios.get(`${process.env.REACT_APP_API_Curreny_generate}/${currency?.code}`);
                const data = response.data;
                const conversionRate = data.rates[userCurrency];
                if (conversionRate) {
                    const convertedPPVPrice = item?.ppv_price ? item?.ppv_price * conversionRate : null;
                    const convertedGlobalPPV = item?.global_ppv ? item?.global_ppv * conversionRate : null;
                    const convertedVideoPPVPrice = item?.video_ppv_price ? item?.video_ppv_price * conversionRate : null;
                    setConvertedPrice({ ppv_price: convertedPPVPrice, global_ppv: convertedGlobalPPV, video_ppv_price: convertedVideoPPVPrice });
                } else {
                    console.error('Conversion rate not found for currency:', userCurrency);
                }
            } catch (error) {
                console.error('Error fetching conversion rate:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchConversionRate();
    }, [userCurrency, currency?.code, currency?.enable_multi_currency, item?.ppv_price, item?.global_ppv]);

    const renderPriceLabel = () => {
        if (loading) {
            return <p className="priceText d-flex align-items-center">{t("Loading...")}</p>;
        }

        if (item?.access === "guest") {
            return <p className="priceText d-flex align-items-center">{t("Free")}</p>;
        } else if (item?.access === "subscriber") {
            return <p className="priceText d-flex align-items-center"><FaCrown style={{ width: "18px", height: "18px", color: "gold" }} /></p>;
        } else if (item?.access === "registered") {
            return <p className="priceText d-flex align-items-center">{t("Registered")}</p>;
        } else if (currency?.enable_multi_currency === 1 && convertedPrice) {
            // const foundCurrency = allcurrency?.find(curr => curr.code === userCurrency);
            const symbol = locationData?.symbol || '';
            if (convertedPrice?.ppv_price !== null) {
                return <p className="priceText d-flex align-items-center">{symbol} {Number(convertedPrice?.ppv_price).toFixed(2)}</p>;
            } else if (convertedPrice?.global_ppv !== null) {
                return <p className="priceText d-flex align-items-center">{symbol} {Number(convertedPrice?.global_ppv).toFixed(2)}</p>;
            } else if (convertedPrice?.video_ppv_price !== null) {
                return <p className="priceText d-flex align-items-center">{symbol} {Number(convertedPrice?.video_ppv_price).toFixed(2)}</p>;
            }
        } else if (currency?.enable_multi_currency === 0) {
            const symbol = currency?.symbol;
            if (item?.ppv_price !== null) {
                return <p className="priceText d-flex align-items-center">{symbol} {Number(item?.ppv_price).toFixed(2)}</p>;
            } else if (item?.global_ppv !== null) {
                return <p className="priceText d-flex align-items-center">{symbol} {Number(item?.global_ppv).toFixed(2)}</p>;
            } else if (item?.video_ppv_price !== null) {
                return <p className="priceText d-flex align-items-center">{symbol} {Number(item?.video_ppv_price).toFixed(2)}</p>;
            }
        }
    };

    return (
        <>
            {thumbnailFreeORCostLabel ? (
                <div className={`${className ? className : 'position-absolute priceLabelRadius theme-button-bg-color theme-button-text-color '} priceLabel`} >
                    {renderPriceLabel()}
                </div>
            ) : ""}
        </>
    );
};

const mapStateToProps = state => ({
    thumbnailFreeORCostLabel: state.get_allThumbnail_Reducer.thumbnailFreeORCostLabel
});

export default connect(mapStateToProps)(PriceLabel);
