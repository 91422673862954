import React, { useState, useCallback, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import JoditEditor from "jodit-react";
import Dropzone from "react-dropzone";
import Select from "react-select";
import { TagsInput } from "react-tag-input-component";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import loadings from "../../../components/Images/loading/loading.gif";
import "./EditNewSeries.css";
import { toast } from "react-toastify";
 
import ResponseLoader from "../../../components/Others/ResponseLoader";
import AdminSaveHeader from "../../../components/Others/adminCommonComponents/AdminSaveHeader";
import FormContainer from "../../CommonComponents/FormContainer";
import RightWrapper from "../../CommonComponents/RightWrapper";
import Wrapper from "../../CommonComponents/Wrapper";
import MessageHeading from "../../CommonComponents/MessageHeading";
import MessageLabel from "../../CommonComponents/MessageLabel";
import MessageSubHeading from "../../CommonComponents/MessageSubHeading";
import MessageBox from "../../../components/Others/MessageBox";
import LeftWrapper from "../../CommonComponents/LeftWrapper";
import { IoClose } from "react-icons/io5";
import AdminCustomButton from "../../../components/Others/adminCommonComponents/AdminCustomButton";
import AdminDeleteModal from "../../../components/Others/adminCommonComponents/AdminDeleteModal";

function EditNewSeries() {
  const { id } = useParams();
  var seriesid = id;
  const navigate = useNavigate();
  const [editUser, setEditUser] = useState({
    title: "",
    slug: "",
    id: "",
    genre_id: "",
    user_id: "",
    type: "",
    access: "",
    active: "",
    ppv_status: "",
    ios_ppv_price: "",
    details: "",
    description: "",
    featured: "",
    duration: "",
    views: "",
    rating: "",
    image: "",
    player_image: "",
    tv_image: "",
    embed_code: "",
    mp4_url: "",
    webm_url: "",
    ogg_url: "",
    language: "",
    year: "",
    trailer: "",
    banner: "",
    search_tag: "",
    series_trailer: "",
    season_trailer: "",
    uploaded_by: "",
    url: "",
    Thumbnail: "",
    deleted_at: "",
    Player_thumbnail: "",
    TV_Thumbnail: "",
    image_url: "",
    website_page_title: "",
    website_URL: "",
    Meta_description: "",
  });
  const [editname, setEditname] = useState({
    title: "",
  });

  const access_token_channel = localStorage.getItem("access_token_channel");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token_channel,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  const [manageseason, setManageseason] = useState([]);
  const [editseason, setEditseason] = useState([]);

  const [user_access, setUser_access] = useState([]);
  const [ios_ppv_pricename, setIos_ppv_pricename] = useState([]);
  const [livestream_source, setLivestream_source] = useState([]);
  const [useraccess, setUserAccess] = useState([]);
  const [useraccessseason, setUserAccessseason] = useState([]);
  const [inapppurchase, setInappPurchase] = useState([]);
  const [age_restrictdata, setAge_restrictdata] = useState([]);
  // console.log(age_restrictdata)
  const [age_restrict, setAge_restrict] = useState([]);
  const [adsPositions, setAdsPositions] = useState([]);
  const [selectedAdsPosition, setSelectedAdsPosition] = useState(null);
  const [secondSelectOptions, setSecondSelectOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tags, setTags] = useState([]);
  const [newTag, setNewTag] = useState("");
  const [modalClose, setModalClose] = useState(false);
  const [modalDeleteClose, setModalDeleteClose] = useState(false);
  const [deleteId, setDeleteId] = useState("")
  const [domLoader, setDomLoader] = useState(false)
  const handleTagsChange = (newTags) => {
    setTags(newTags);
  };

  const [publishType, setPublishType] = useState("publish_now");
  const [publishTime, setPublishTime] = useState("");
  const [formDatapublishedit, setFormDatapublishedit] = useState(
    new FormData()
  );

  const handlePublishTypeChange = (event) => {
    const selectedValue = event.target.value;
    setPublishType(selectedValue);

    if (selectedValue === "publish_now") {
      setPublishTime(""); // Reset the publish time when switching to "Publish Now"
      formDatapublishedit?.delete("publish_time"); // Remove publish_time from FormData
    }
  };

  const handlePublishTimeChange = (event) => {
    const timeValue = event.target.value;
    setPublishTime(timeValue);
    formDatapublishedit?.set("publish_time", timeValue);
    // formDatapublishedit.set('publish_status', 'your_publish_status'); // Replace with your actual value
    formDatapublishedit?.set("publish_type", publishType);
  };

  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputValueAPI, setInputValueAPI] = useState("");

  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
    if (!checkboxChecked) {
      setInputValue(inputValueAPI);
    } else {
      setInputValue("");
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    // Handle file drop here
    const file = acceptedFiles[0];
    setSelectedFile(file);
  }, []);

  const handleFileChangessss = (event) => {
    // Handle file selection from file input
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleChangeyear = (event) => {
    const value = event.target.value;
    if (/^\d{0,4}$/.test(value)) {
      setEditUser({ ...editUser, year: value });
    }
  };

  const handleInputdata = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;

    // Remove non-digit characters from input
    const formattedInput = inputValue.replace(/\D/g, "");

    // Add colons to maintain the format HH:MM:SS
    const formattedTime = formattedInput
      .replace(/^(\d{2})(\d{2})(\d{2})$/, "$1:$2:$3")
      .substr(0, 8);

    setEditUser({
      ...editUser,
      [inputName]: formattedTime,
    });
  };
  const [ios_ppv_price, setIos_ppv_price] = useState("");
  const handleChangeFirst = (e) => {
    setEditUser({ ...editUser, details: e });
    // console.log(e, "onchnageddddd");
  };

  // MULTI SELECT NEW
  const [options, setOptions] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);

  const [optionsrelated_video, setOptionsrelated_video] = useState([]);
  const [selectedValuesrelated_video, setSelectedValuesrelated_video] =
    useState([]);

  const [optionsartists, setOptionsartists] = useState([]);
  const [selectedValuesartists, setSelectedValuesartists] = useState([]);

  const [optionscategories, setOptionscategories] = useState([]);
  const [selectedValuescategories, setSelectedValuescategories] = useState([]);

  const [optionslanguages, setOptionslanguages] = useState([]);
  const [selectedValueslanguages, setSelectedValueslanguages] = useState([]);

  const [optionsavailable_country, setOptionsAvailable_country] = useState([]);
  const [selectedValuesavailable_country, setSelectedValuesAvailable_country] =
    useState([]);

  const [optionsAge_Restrict, setOptionsAge_Restrict] = useState([]);
  const [selectedValuesAge_Restrict, setSelectedValuesAge_Restrict] = useState(
    []
  );

  const [formData, setFormData] = useState({
    // block_country: [],
    // Available_country: [],
    // related_video: [],
    // artists: [],
    // categories: [],
    // languages: [],
    // Age_Restrict: [],
    // ...editInputvalue,
    // other form data properties...
  });

  useEffect(() => {
    fetchData();
  }, [domLoader]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/series/edit/${id}`,
        { headers: headers }
      );

      const data = response?.data?.Series[0]?.block_country_id;
      const dataavailable_country =
        response?.data?.Series[0]?.available_countries;
      const dataartists = response?.data?.Series[0]?.Seriesartist;
      const datacategories = response?.data?.Series[0]?.SeriesCategory;
      const datalanguages = response?.data?.Series[0]?.SeriesLanguage;
      const dataAge_Restrict = response?.data?.Series[0]?.selected_Age_Restrict;
      const selectedData = data;
      const formattedOptions = selectedData?.map((item) => ({
        value: item.id,
        label: item.country_name,
      }));

      const formattedOptionsavailable_country = dataavailable_country?.map(
        (item) => ({
          value: item.id,
          label: item.country_name,
        })
      );
      const formattedOptionsartists = dataartists?.map((item) => ({
        value: item.artist_id,
        label: item.name,
      }));
      const formattedOptionscategories = datacategories?.map((item) => ({
        value: item.category_id,
        label: item.name,
      }));
      const formattedOptionslanguages = datalanguages?.map((item) => ({
        value: item.language_id,
        label: item.name,
      }));
      const formattedOptionsAge_Restrict = dataAge_Restrict?.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      // const formattedOptionsrelated_video = datarelated_video?.map((item) => ({
      //   value: item.id,
      //   label: item.id,
      // }));

      // Set the initial selected values
      setSelectedValues(formattedOptions);
      setOptions(formattedOptions);

      setSelectedValuesAvailable_country(formattedOptionsavailable_country);
      setOptionsAvailable_country(formattedOptionsavailable_country);

      setSelectedValuesartists(formattedOptionsartists);
      setOptionsartists(formattedOptionsartists);

      setSelectedValuescategories(formattedOptionscategories);
      setOptionscategories(formattedOptionscategories);

      setSelectedValueslanguages(formattedOptionslanguages);
      setOptionslanguages(formattedOptionslanguages);

      setSelectedValuesAge_Restrict(formattedOptionsAge_Restrict);
      setOptionsAge_Restrict(formattedOptionsAge_Restrict);
      setLoading(false);
      setTags(
        response?.data?.Series[0]?.search_tag
          ?.split(",")
          ?.map((tag) => tag?.trim())
      );
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/series/create`,
        { headers: headers }
      );
      const data = response?.data?.Block_Country;
      const dataavailable_country = response?.data?.Available_country;
      const dataartists = response?.data?.artists;
      const datacategories = response?.data?.series_categories;
      const datalanguages = response?.data?.languages;
      const dataAge_Restrict = response?.data?.Age_Restrict;
      // setSelectedLanguages(data)
      // console.log(data);
      var result = response?.data?.user_access;
      var InappPurchase = response?.data?.InappPurchase;
      setUser_access(result);
      setInappPurchase(InappPurchase);
      setIos_ppv_pricename(InappPurchase);
      setUserAccess(result);
      // console.log(InappPurchase)
      var age_restrictdata = response?.data?.Age_Restrict;
      setAge_restrictdata(age_restrictdata);
      const globelset = response?.data?.ppv_gobal_price;
      setInputValueAPI(globelset);
      setLivestream_source(response?.data?.Livestream_source);
      // console.log(data);
      // console.log(data);
      // Extract the selected data from the response
      const selectedData = data;
      const formattedOptions = selectedData?.map((item) => ({
        value: item?.id,
        label: item?.country_name,
      }));
      const formattedOptionsavailable_country = dataavailable_country?.map(
        (item) => ({
          value: item?.id,
          label: item?.country_name,
        })
      );
      const formattedOptionsartists = dataartists?.map((item) => ({
        value: item?.id,
        label: item?.artist_name,
      }));
      const formattedOptionscategories = datacategories?.map((item) => ({
        value: item?.id,
        label: item?.name,
      }));
      const formattedOptionslanguages = datalanguages?.map((item) => ({
        value: item?.id,
        label: item?.name,
      }));
      const formattedOptionsAge_Restrict = dataAge_Restrict?.map((item) => ({
        value: item?.id,
        label: item?.id,
      }));
      // const formattedOptionsrelated_video = datarelated_video.map((item) => ({
      //   value: item.id,
      //   label: item.id,
      // }));

      // Set the options for the multi-select component
      setOptions(formattedOptions);
      setOptionsAvailable_country(formattedOptionsavailable_country);
      setOptionsartists(formattedOptionsartists);
      setOptionscategories(formattedOptionscategories);
      setOptionslanguages(formattedOptionslanguages);
      setOptionsAge_Restrict(formattedOptionsAge_Restrict)
    } catch (error) {
      console.error(error);
    }
  };
  // const handleLanguageChange = (event) => {
  //   const selectedOptions = Array.from(event.target.selectedOptions, option => option.value);
  //   setSelectedLanguages(selectedOptions);
  // };

  const handleSelectChange = (selectedOptions) => {
    const block_country = selectedOptions?.map((option) => option.value);

    setFormData({
      ...formData,
      // ...editInputvalue,
      block_country: block_country,
    });

    setSelectedValues(selectedOptions);
  };

  const handleSelectChangeavailable_country = (
    selectedOptionsavailable_country
  ) => {
    const available_country = selectedOptionsavailable_country?.map(
      (option) => option.value
    );

    setFormData({
      ...formData,
      // ...editInputvalue,
      available_country: available_country,
    });

    setSelectedValuesAvailable_country(selectedOptionsavailable_country);
  };

  const handleSelectChangeartists = (selectedOptionsartists) => {
    const artists = selectedOptionsartists?.map((option) => option.value);

    setFormData({
      ...formData,
      // ...editInputvalue,
      artists: artists,
    });

    setSelectedValuesartists(selectedOptionsartists);
  };

  const handleSelectChangecategories = (selectedOptionscategories) => {
    const categories = selectedOptionscategories?.map((option) => option.value);

    setFormData({
      ...formData,
      // ...editInputvalue,
      categories: categories,
    });

    setIsValidationHiddencategory(true);
    setSelectedValuescategories(selectedOptionscategories);
  };

  const handleSelectChangerelated_video = (selectedOptionsrelated_video) => {
    const related_video = selectedOptionsrelated_video?.map(
      (option) => option.value
    );

    setFormData({
      ...formData,
      // ...editInputvalue,
      related_video: related_video,
    });

    setSelectedValuesrelated_video(selectedOptionsrelated_video);
  };

  const handleSelectChangelanguages = (selectedOptionslanguages) => {
    const languages = selectedOptionslanguages?.map((option) => option.value);

    setFormData({
      ...formData,
      // ...editInputvalue,
      languages: languages,
    });

    setIsValidationHiddenlanguages(true);
    setSelectedValueslanguages(selectedOptionslanguages);
  };

  const handleSelectChangeAge_Restrict = (selectedOptionsAge_Restrict) => {
    const Age_Restrict = selectedOptionsAge_Restrict?.map(
      (option) => option.value
    );

    setFormData({
      ...formData,
      // ...editInputvalue,
      Age_Restrict: Age_Restrict,
    });

    setSelectedValueslanguages(selectedOptionsAge_Restrict);
  };

  // MULTI SELECT NEW
  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);
  const fileInputRef4 = useRef(null);
  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFile3, setSelectedFile3] = useState(null);
  const [selectedFile4, setSelectedFile4] = useState(null);
  const handleFileChange = (event, fileInputRef, setSelectedFile) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDelete = (
    setSelectedFile1,
    setSelectedFile2,
    setSelectedFile3,
    setSelectedFile4
  ) => {
    setSelectedFile1(null);
    if (fileInputRef1.current) {
      fileInputRef1.current.value = null;
    }
    setSelectedFile2(null);
    if (fileInputRef2.current) {
      fileInputRef2.current.value = null;
    }
    setSelectedFile3(null);
    if (fileInputRef3.current) {
      fileInputRef3.current.value = null;
    }
    setSelectedFile4(null);
    if (fileInputRef4.current) {
      fileInputRef4.current.value = null;
    }
  };

  const handleAdsPositionChange = (selectedValue5) => {
    setSelectedAdsPosition(selectedValue5);
    // console.log(selectedValue5); // Selected value
  };

  useEffect(() => {
    fetchAdsPositions();
    indexseason();
    getUser();
    index();
  }, [seriesid, domLoader]);

  const indexseason = async () => {
    const reqData = await fetch(
      `${process.env.REACT_APP_Baseurl}/Channel/Backend/series-season/create`,
      { headers: headers }
    );
    const resData = await reqData?.json();
    var result = resData?.user_access;
    setUserAccessseason(result);
  };
  const index = async () => {
    const reqData = await fetch(
      `${process.env.REACT_APP_Baseurl}/Channel/Backend/unique-series-season/${id}`,
      { headers: headers }
    );
    const resData = await reqData?.json();
    var result = resData?.SeriesSeason;
    setEditseason(result);
    setManageseason(result);
  };
  const getUser = async () => {
    const reqData = await fetch(
      `${process.env.REACT_APP_Baseurl}/Channel/Backend/series/edit/${seriesid}`,
      { headers: headers }
    );
    const resData = await reqData?.json();
    var res = resData?.Series[0];
    setEditUser(res);
    setEditname(res);
  };
  const fetchAdsPositions = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/series/create`,
        {
          headers: headers,
        }
      );

      if (response.ok) {
        const data = await response?.json();
      } else {
        throw new Error("Request failed with status: " + response?.status);
      }
    } catch (error) {
      console.log("Error fetching ads positions:", error);
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessageslug("");
    setIsValidationHiddenslug(true);
    setValidationMessagetitle("");
    setIsValidationHiddentitle(true);

    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };

  const handleInputenable = (e) => {
    if (e.target.checked == true) {
      setEditUser({ ...editUser, [e.target.name]: 1 });
    } else {
      setEditUser({ ...editUser, [e.target.name]: 0 });
    }
  };

  const [validationMessagetitle, setValidationMessagetitle] = useState("");
  const [isValidationHiddentitle, setIsValidationHiddentitle] = useState(true);

  const [validationMessageslug, setValidationMessageslug] = useState("");
  const [isValidationHiddenslug, setIsValidationHiddenslug] = useState(true);

  const [validationMessagecategory, setValidationMessagecategory] =
    useState("");
  const [isValidationHiddencategory, setIsValidationHiddencategory] =
    useState(true);

  const [validationMessagelanguages, setValidationMessagelanguages] =
    useState("");
  const [isValidationHiddenlanguages, setIsValidationHiddenlanguages] =
    useState(true);

  const titleInputRef = useRef(null);
  const categoryInputRef = useRef(null);
  const languageInputRef = useRef(null);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const handleUpdate = async (e) => {
    e.preventDefault();

    let focusInputRef = null;

    if (editUser?.title == "") {
      setValidationMessagetitle("Title cannot be empty.");
      setIsValidationHiddentitle(false);
      focusInputRef = titleInputRef;
    }
    if (!selectedValuescategories || selectedValuescategories?.length == 0) {
      setValidationMessagecategory("Please select at least one Category.");
      setIsValidationHiddencategory(false);
      focusInputRef = categoryInputRef;
    }
    if (!selectedValueslanguages || selectedValueslanguages?.length == 0) {
      setValidationMessagelanguages("Please select at least one Language.");
      setIsValidationHiddenlanguages(false);
      focusInputRef = languageInputRef;
    }

    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }
    const updatedTagsString = tags?.join(", ");

    const editInputvalue = {
      title: editUser?.title,
      Channel_Partner_id: 4,
      type: editUser?.type,
      slug: editUser?.slug,
      id: editUser?.id,
      genre_id: editUser?.genre_id,
      user_id: editUser?.user_id,
      access: editUser?.access,
      details: editUser?.details,
      description: editUser?.description,
      active: editUser?.active,
      ppv_status: editUser?.ppv_status,
      featured: editUser?.featured,
      duration: editUser?.duration,
      views: editUser?.views,
      rating: editUser?.rating,
      image: editUser?.image,
      player_image: editUser?.player_image,
      tv_image: editUser?.tv_image,
      embed_code: editUser?.embed_code,
      mp4_url: editUser?.mp4_url,
      webm_url: editUser?.webm_url,
      ogg_url: editUser?.ogg_url,
      language: editUser?.language,
      year: editUser?.year,
      trailer: editUser?.trailer,
      banner: editUser?.banner,
      ppv_price: inputValue,
      search_tag: updatedTagsString,
      series_trailer: editUser?.series_trailer,
      season_trailer: editUser?.season_trailer,
      uploaded_by: editUser?.uploaded_by,
      url: editUser?.url,
      publish_status: editUser?.publish_status,
      publish_type: publishType,
      publish_time: publishTime,
      deleted_at: editUser?.deleted_at,
      image_url: editUser?.image_url,
      ads_position: editUser?.ads_position,
      languages_id: selectedValueslanguages?.map((option) => option?.value),
      artists: selectedValuesartists?.map((option) => option?.value),
      block_country_id: selectedValues?.map((option) => option?.value),
      genre_id: selectedValuescategories?.map((option) => option?.value),
      available_countries_id: selectedValuesavailable_country?.map(
        (option) => option?.value
      ),
      ...formData,
    };

    let firstApiStatus = false;
    let secondApiStatus = false;
    let seventhApiStatus = false;
    let resultapi = null;
    let resjson = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/series/update/${id}`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(editInputvalue),
        }
      );

      resjson = await res.json();

      if (resjson.status == true) {
        firstApiStatus = true;

        resultapi = resjson;

        const formData = new FormData();

        formData.append("series_id", id);
        if (selectedFile1) {
          formData.append("image", selectedFile1);
        }
        if (selectedFile2) {
          formData.append("player_image", selectedFile2);
        }
        if (selectedFile3) {
          formData.append("tv_image", selectedFile3);
        }

        const imageResponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/series/Image_upload`,
          formData,
          { headers: headers }
        );

        const formDataseo = new FormData();
        formDataseo.append("series_id", id);
        formDataseo.append("website_page_title", editUser?.website_page_title);
        formDataseo.append("website_URL", editUser?.website_URL);
        formDataseo.append("Meta_description", editUser?.Meta_description);

        const formDataseoresponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/series/SEO`,
          formDataseo,
          { headers: headers }
        );

        if (imageResponse.data.status == true) {
          secondApiStatus = true;
        }

        if (formDataseoresponse.data.status == true) {
          seventhApiStatus = true;
        }
        // secondApiStatus = true;
      }
    } catch (error) {
      console.error("Error:", error);
    }

    if (firstApiStatus && secondApiStatus && seventhApiStatus) {
      setValidationMessagetitle("");
      setValidationMessageslug("");
      // props.setApiresponsealert(resultapi);
      // props.setShowalert(true);
      toast.success(resultapi?.message);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resjson.status == false) {
        var resultError = resjson;
        // props.setApiresponsealert(resultapifalse);
        // props.setShowalert(true);
        toast.error(resultError?.message);
        setIsValidationHiddentitle(false);
        setValidationMessageslug(false);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  };

  const [access, setAccess] = useState("");
  const [ppv_price, setPpv_price] = useState("");
  const [ppv_interval, setPpv_interval] = useState("");
  const [trailer_type, setTrailer_type] = useState("");
  const [ios_product_id, setIos_product_id] = useState("");
  const [trailer, setTrailer] = useState("");
  const [trailerimage, setTrailerImage] = useState("");

  async function series_season() {
    const formData = new FormData();
    formData.append("series_id", seriesid);
    formData.append("ppv_price", inputValue);
    formData.append("access", storeliveuseraccessseason);
    formData.append("ppv_interval", ppv_interval);
    formData.append("ios_product_id", ios_product_id);
    let firstApiStatus = false;
    let secondApiStatus = false;
    let seventhApiStatus = false;
    let resultapi = null;
    setShowOverlay(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/series-season/store`,
        formData,
        { headers: headers }
      );

      if (response.data.status) {
        firstApiStatus = true;
        resultapi = response.data;
        var result = response.data.SeriesSeason_id;
        const imageFormData = new FormData();
        imageFormData.append("season_id", result);
        if (selectedFile4) {
          imageFormData.append("image", selectedFile4);
        }
        const imageResponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/series-season/Image_upload`,
          imageFormData,
          { headers: headers }
        );
        const formDataseo = new FormData();
        formDataseo.append("season_id", result);
        formDataseo.append("trailer", selectedFile);
        formDataseo.append("trailer_type", trailer_type);
        const formDataseoresponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/series-season/Trailer_upload`,
          formDataseo,
          { headers: headers }
        );
        if (imageResponse.data.status == true) {
          secondApiStatus = true;
        }
        if (formDataseoresponse.data.status == true) {
          seventhApiStatus = true;
        }
      }
      else {
        setShowOverlay(false);
        toast.error(response?.data?.message);
      }
    } catch (err) {
      var errorMessage = err.response.data.message;
      setShowOverlay(false);
      toast.error(errorMessage);
    }

    if (firstApiStatus && secondApiStatus && seventhApiStatus) {
      setValidationMessagetitle("");
      setValidationMessageslug("");
      toast.success(resultapi?.message);
      setShowOverlay(false);
      setDomLoader(!domLoader)
      setModalClose(!modalClose)

    } else {
      if (resultapi == false) {
        var resultError = resultapi;
        toast.error(resultError?.message);
        setIsValidationHiddentitle(false);
        setValidationMessageslug(false);
        setShowOverlay(false);
      }
    }
  }

  const deleteOperation = () => {
    setShowOverlay(true)
    axios.delete(`${process.env.REACT_APP_Baseurl}/Channel/Backend/series-season/delete/${deleteId}`, { headers: headers })
      .then((response) => {
        if (response.data.status == true) {
          toast.success(response?.data?.message);
          setDomLoader(!domLoader)
          setShowOverlay(false)
          setModalDeleteClose(false)
        } else {
          toast.error(response?.data?.message);
          setShowOverlay(false)
        }
      }).catch((err) => {
        var errorMessage = err.response.data.message;
        setShowOverlay(false);
        toast.error(errorMessage);
      });
  };

  useEffect(() => {
    if (selectedAdsPosition !== "") {
      const formData = new FormData();
      formData.append("position", selectedAdsPosition?.value);
      axios.post(
        `${process.env.REACT_APP_Baseurl}/Channel/advertisement-tag-url`,
        formData,
        { headers: headers }
      )
        .then((response) => {
          const data = response?.data;
          const adsNames = data?.Advertisement?.map((position) => ({
            value: position?.id,
            label: position?.ads_name,
          }));

          setSecondSelectOptions(adsNames);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [selectedAdsPosition]);

  const [allliveuseraccess, setallliveuseraccess] = useState("");
  const [storeliveuseraccess, setStoreliveuseraccess] = useState("");

  function allseriesuseraccess() {
    switch (allliveuseraccess) {
      case "guest":
        return <div></div>;
      case "subscriber":
        return <div></div>;
      case "registered":
        return <div></div>;
      case "ppv":
        return (
          <div className="">
            <div className="row mx-0">
              <MessageLabel text="PPV Price" className="ps-0" />
              <div className="col-8 ps-0">
                <MessageSubHeading text="Apply PPV Price from Global Settings?" />
              </div>
              <div className="col-4 text-end pe-0">
                <label className="switch">
                  <input
                    name="ppv_price"
                    defaultChecked={checkboxChecked}
                    onChange={handleCheckboxChange}
                    type="checkbox"
                    className="rs-input"
                  />
                  <span
                    className="slider round"
                    name="ppv_price"
                    checked={checkboxChecked}
                    onChange={handleCheckboxChange}
                  ></span>
                </label>
              </div>
            </div>
            <div className="panel-body">
              <input
                type="number"
                className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder theme-text-color border-0 mb-3"
                placeholder="PPV Price"
                id="ppv_price"
                name="ppv_price"
                value={inputValue}
                onChange={handleInputChange}
              />
            </div>
            <MessageLabel text="IOS PPV Price" />
            <MessageSubHeading text="Apply IOS PPV Price from Global Settings?" />
            <div className="panel-body ppv_price_ios mt-2">
              <select
                name="ios_ppv_price"
                id="ios_ppv_price"
                onChange={handleInput}
                value={editUser?.ios_ppv_price}
                className="form-select theme-bg-color-secondary custom-placeholder theme-text-color border-0 mb-3"
              >
                {inapppurchase?.map((item, key) => (
                  <option value={item.product_id} className="theme-bg-color theme-text-color">{item.plan_price}</option>
                ))}
              </select>
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  const [allliveuseraccessseason, setallliveuseraccessseason] = useState("");
  const [storeliveuseraccessseason, setStoreliveuseraccessseason] =
    useState("");

  function allseriesuseraccessseason() {
    switch (allliveuseraccessseason) {
      case "guest":
        return <div></div>;
      case "subscriber":
        return <div></div>;
      case "registered":
        return <div></div>;
      case "ppv":
        return (
          <div className=" ">
            <div className="row  mx-0">
              <MessageLabel text="PPV Price" className="ps-0" />
              <div className="col-8 ps-0">
                <MessageSubHeading text="Apply PPV Price from Global Settings?" />
              </div>
              <div className="col-4 text-end">
                <label className="switch">
                  <input
                    name="ppv_price"
                    defaultChecked={checkboxChecked}
                    onChange={handleCheckboxChange}
                    type="checkbox"
                    className="rs-input"
                  />
                  <span
                    className="slider round"
                    name="ppv_price"
                    checked={checkboxChecked}
                    onChange={handleCheckboxChange}
                  ></span>
                </label>
              </div>
            </div>
            <div className="panel-body">
              <input
                type="text"
                className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder theme-text-color border-0 mb-3"
                placeholder="PPV Price"
                name="ppv_price"
                id="price"
                value={inputValue}
                onChange={handleInputChange}
              />
            </div>
            <MessageLabel text="IOS PPV Price" />
            <MessageSubHeading text="Apply IOS PPV Price from Global Settings?" />
            <div className="panel-body ppv_price_ios  mt-2">
              <select
                className="form-select theme-bg-color-secondary custom-placeholder theme-text-color border-0 mb-3"
                id="ios_ppv_price"
                name="ios_ppv_price"
                onChange={(e) => setIos_product_id(e.target.value)}
              >
                <option value="" className="theme-bg-color theme-text-color">Choose an IOS PPV Price</option>
                {ios_ppv_pricename?.map((item) => (
                  <option value={item?.product_id} className="theme-bg-color theme-text-color">{item?.plan_price}</option>
                ))}
              </select>
              <MessageLabel text="PPV Interval" />
              <MessageSubHeading text="Please Mention How Many Episodes are Free" />
              <div className="panel-body ppv_price_ios  mt-2">
                <input
                  type="text"
                  id="ppv_interval"
                  name="ppv_interval"
                  onChange={(e) => setPpv_interval(e.target.value)}
                  className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder theme-text-color border-0 "
                />
              </div>
            </div>
          </div >
        );
      default:
        return null;
    }
  }

  const [enable_restreampublic, setEnable_restreampublic] = useState("");
  const [enablestreamvisiblepublic, setEnablestreamvisiblepublic] =
    useState("");

  function proceednextpublic() {
    switch (enable_restreampublic) {
      case "publish_now":
        return <div></div>;
      case "publish_later":
        return (
          <div className="mb-3">
            <label className="m-0 theme-text-color">Publish Time</label>
            <input
              type="datetime-local"
              className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
              id="publish_time"
              name="publish_time"
            // onChange={(e) => setPublish_time(e.target.value)}
            />
          </div>
        );
      default:
        return null;
    }
  }

  const handleModal = () => {
    setModalClose(!modalClose)
  }
  const handleDeleteModal = (id) => {
    setModalDeleteClose(!modalDeleteClose)
    setDeleteId(id)
  }
  return (
    <>
      {showOverlay && <ResponseLoader />}
      <AdminSaveHeader heading="Edit New Series" saveText="Update Series" saveMethod={handleUpdate} />
      {modalDeleteClose && <AdminDeleteModal modelClose={modalDeleteClose} deleteClose={handleDeleteModal} deleteAction={deleteOperation} buttonText='custom' message="Are you sure you want to delete the channel series season and all episodes of the selected series season" />}
      <FormContainer>
        <LeftWrapper>
          <Wrapper>
            <MessageHeading text={editname?.title} />
            <MessageLabel text="Title" className='mandatorySimple' />
            <MessageSubHeading text="Add the series title in the textbox below" />
            <input
              type="text"
              id="title"
              name="title"
              className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder theme-text-color border-0 mb-3"
              value={editUser?.title}
              onChange={handleInput}
              placeholder="Series Title"
              ref={titleInputRef}
            />
            {!isValidationHiddentitle && (<MessageBox text={`${validationMessagetitle}`} classname='errorred mb-2 d-block' />)}
            <MessageLabel text="Slug" className='' />
            <MessageSubHeading text="Add a URL Slug" />
            <input
              type="text"
              id="slug"
              name="slug"
              className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder theme-text-color border-0 mb-3"
              value={editUser?.slug}
              onChange={handleInput}
              placeholder="Series Slug"
            />
            {!isValidationHiddenslug && (<MessageBox text={`${validationMessageslug}`} classname='errorred mb-2 d-block' />)}
            <MessageLabel text="Short Description" className='' />
            <MessageSubHeading text="Add a short description of the series below" />
            <textarea
              className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder theme-text-color border-0 mb-3"
              id="description"
              name="description"
              onChange={handleInput}
              value={editUser?.description}
              placeholder="Description"
            />
            <MessageLabel text="Series Details, Links, and Info" />
            <JoditEditor
              className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder theme-text-color border-0 mb-3"
              value={editUser?.details}
              onChange={(e) => {
                handleChangeFirst(e);
              }}
            />
            <MessageLabel text="Duration" />
            <MessageSubHeading text=" Enter The Series Duration In (HH : MM : SS)" />
            <input
              type="text"
              name="duration"
              className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder theme-text-color border-0 mb-3"
              value={editUser?.duration}
              maxLength="8"
              // placeholder="duration"
              onChange={handleInputdata}
            />
            <MessageLabel text="Year" />
            <MessageSubHeading text="Enter The Series Year" />
            <input
              type="number"
              name="number"
              value={editUser?.year}
              onChange={handleChangeyear}
              className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder theme-text-color border-0 "
              placeholder="Year"
            />
          </Wrapper>
          <Wrapper>
            <MessageHeading text='Organize' />
            <MessageLabel text="Series Ratings" />
            <MessageSubHeading text=" IMDb Ratings 10 out of 10" />
            <select
              className="form-select theme-bg-color-secondary custom-placeholder theme-text-color border-0 mb-3"
              id="rating"
              name="rating"
              size="lg"
              onChange={handleInput}
              value={editUser?.rating}
            >
              <option value="0" className="theme-bg-color theme-text-color">0</option>
              <option value="1" className="theme-bg-color theme-text-color">1</option>
              <option value="2" className="theme-bg-color theme-text-color">2</option>
              <option value="3" className="theme-bg-color theme-text-color">3</option>
              <option value="4" className="theme-bg-color theme-text-color">4</option>
              <option value="5" className="theme-bg-color theme-text-color">5</option>
              <option value="6" className="theme-bg-color theme-text-color">6</option>
              <option value="7" className="theme-bg-color theme-text-color">7</option>
              <option value="8" className="theme-bg-color theme-text-color">8</option>
              <option value="9" className="theme-bg-color theme-text-color">9</option>
              <option value="10" className="theme-bg-color theme-text-color">10</option>
            </select>
            <MessageLabel text="Cast and Crew" />
            <MessageSubHeading text="Select A Series Artist Below" />
            <Select
              options={optionsartists}
              isMulti
              className="theme-bg-color-secondary mb-3"
              onChange={handleSelectChangeartists}
              value={selectedValuesartists}
            />
            <MessageLabel text="Category" className='mandatorySimple' />
            <MessageSubHeading text="Select a Video Category Below" />
            <Select
              options={optionscategories}
              isMulti
              className="theme-bg-color-secondary mb-3"
              onChange={handleSelectChangecategories}
              value={selectedValuescategories}
              ref={categoryInputRef}
            />
            {!isValidationHiddencategory && (<MessageBox text={`${validationMessagecategory}`} classname='errorred mb-2 d-block' />)}
            <MessageLabel text="Language" className='mandatorySimple' />
            <MessageSubHeading text="Select a Video Language Below" />
            <Select
              options={optionslanguages}
              isMulti
              className="theme-bg-color-secondary mb-3"
              onChange={handleSelectChangelanguages}
              value={selectedValueslanguages}
              ref={languageInputRef}
            />
            {!isValidationHiddenlanguages && (<MessageBox text={`${validationMessagelanguages}`} classname='errorred mb-2 d-block' />)}
            <MessageLabel text="Block Country" />
            <MessageSubHeading text="Select A Series country Below" />
            <Select
              options={options}
              isMulti
              className="theme-bg-color-secondary mb-3"
              onChange={handleSelectChange}
              value={selectedValues}
            />
            <MessageLabel text="Available Country" />
            <MessageSubHeading text="Available the Video for Selected Country" />
            <Select
              options={optionsavailable_country}
              isMulti
              className="theme-bg-color-secondary mb-3"
              onChange={handleSelectChangeavailable_country}
              value={selectedValuesavailable_country}
            />
          </Wrapper>
          <Wrapper>
            <MessageHeading text='Search Tags' />
            <MessageLabel text="You don't have any search keywords." />
            <TagsInput
              value={tags}
              onChange={handleTagsChange}
              inputValue={newTag}
              onChangeInput={(tag) => setNewTag(tag)}
            />
          </Wrapper>
        </LeftWrapper>
        <RightWrapper>
          <Wrapper>
            <MessageHeading text='Thumbnails' />
            <MessageLabel text=" Series Image Cover" />
            <MessageSubHeading text="Select the Series image( 16:9 Ratio or 1280X720px )" />
            <div>
              {loading ? (
                <img src={loadings} className="thumbnails-images" />
              ) : (
                <img src={editUser?.image_url} alt="Image" className="thumbnails-images" />
              )}
            </div>
            <div className="row mx-0 my-3">
              <div className="col-6 ps-0">
                <div
                  className="imagedrop theme-border-color"
                  onClick={() => fileInputRef1.current.click()}>
                  <input
                    type="file"
                    ref={fileInputRef1}
                    onChange={(event) => handleFileChange(event, fileInputRef1, setSelectedFile1)
                    }
                    accept="image/*"
                    style={{ display: "none" }}
                  />
                  <label className="theme-text-color">
                    <CameraRetroIcon className=" theme-text-color" />
                  </label>
                </div>
              </div>
              <div className="col-6 pe-0">
                <div>
                  {selectedFile1 && (
                    <div className="imagedropcopy theme-border-color text-end">
                      <div className="row mx-0">
                        <div className="col-10 p-0">
                          <img
                            src={URL?.createObjectURL(selectedFile1)}
                            alt="Uploaded"
                            className="thumbnails-upload-images"
                          />
                        </div>
                        <div
                          className="col-2 p-0 "
                          style={{ alignSelf: "center" }}
                        >
                          <button
                            onClick={() =>
                              handleDelete(setSelectedFile1)
                            }
                            type="button"
                            className=" theme-text-color bg-transparent "
                          ><IoClose className="custom-close-btn" /></button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <MessageLabel text="Player Image Cover" />
            <MessageSubHeading text="Select the Series image( 16:9 Ratio or 1280X720px )" />
            <div>
              {loading ? (
                <img src={loadings} className="thumbnails-images" />
              ) : (
                <img
                  src={editUser?.player_image}
                  alt="Image"
                  className="thumbnails-images"
                />
              )}
            </div>
            <div className="row mx-0 my-3">
              <div className="col-6 ps-0">
                <div
                  className="imagedrop theme-border-color"
                  onClick={() => fileInputRef2.current.click()}
                >
                  <input
                    type="file"
                    ref={fileInputRef2}
                    onChange={(event) =>
                      handleFileChange(
                        event,
                        fileInputRef2,
                        setSelectedFile2
                      )
                    }
                    accept="image/*"
                    style={{ display: "none" }}
                  />
                  <label className=" theme-text-color">
                    <CameraRetroIcon className=" theme-text-color" />
                  </label>
                </div>
              </div>
              <div className="col-6 pe-0">
                <div>
                  {selectedFile2 && (
                    <div className="imagedropcopy theme-border-color text-end">
                      <div className="row pmx-0">
                        <div className="col-10 p-0">
                          <img
                            src={URL?.createObjectURL(selectedFile2)}
                            alt="Uploaded"
                            className="thumbnails-upload-images"
                          />
                        </div>
                        <div
                          className="col-2 p-0"
                          style={{ alignSelf: "center" }}
                        >
                          <button
                            onClick={() =>
                              handleDelete(setSelectedFile2)
                            }
                            type="button"
                            className=" theme-text-color bg-transparent "
                          ><IoClose className="custom-close-btn" /></button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <MessageLabel text="TV Image Cover" />
            <MessageSubHeading text="Select the Series image( 16:9 Ratio or 1280X720px )" />
            <div>
              {loading ? (
                <img src={loadings} className="thumbnails-images" />
              ) : (
                <img
                  src={editUser?.tv_image}
                  alt="Image"
                  className="thumbnails-images"
                />
              )}
            </div>
            <div className="mx-0 mt-3 row">
              <div className="col-6 ps-0">
                <div
                  className="imagedrop theme-border-color"
                  onClick={() => fileInputRef3.current.click()}
                >
                  <input
                    type="file"
                    ref={fileInputRef3}
                    onChange={(event) =>
                      handleFileChange(
                        event,
                        fileInputRef3,
                        setSelectedFile3
                      )
                    }
                    accept="image/*"
                    style={{ display: "none" }}
                  />
                  <label className=" theme-text-color">
                    <CameraRetroIcon className=" theme-text-color" />
                  </label>
                </div>
              </div>
              <div className="col-6 pe-0">
                <div>
                  {selectedFile3 && (
                    <div className="imagedropcopy theme-border-color text-end">
                      <div className="row mx-0">
                        <div className=" col-10 p-0 ">
                          <img
                            src={URL?.createObjectURL(selectedFile3)}
                            alt="Uploaded"
                            className="thumbnails-upload-images"
                          />
                        </div>
                        <div
                          className="col-2 p-0 "
                          style={{ alignSelf: "center" }}
                        >
                          <button
                            onClick={() =>
                              handleDelete(setSelectedFile3)
                            }
                            type="button"
                            className=" theme-text-color bg-transparent "
                          ><IoClose className="custom-close-btn" /></button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Wrapper>
          <Wrapper>
            <MessageHeading text='Access' />
            <MessageLabel text='User Access' />
            <MessageSubHeading text="Who Is Allowed To View This Series ?" />
            <select
              onChange={handleInput}
              value={editUser?.access}
              name="access"
              id="access"
              className="form-select theme-bg-color-secondary custom-placeholder theme-text-color border-0 mb-3"
              onClick={(event) => {
                setallliveuseraccess(event.target.value);
              }}
            >
              {user_access?.map((item, key) => (
                <option value={item?.role} className="theme-bg-color theme-text-color">{item?.name}</option>
              ))}
            </select>
            <div>{allseriesuseraccess()}</div>
          </Wrapper>
          <Wrapper>
            <MessageHeading text="Status Settings" />
            <div className="row mx-0">
              <div className="col-9 ps-0">
                <MessageLabel text="Is this series Featured" />
              </div>
              <div className="col-3 text-end">
                <label className="switch">
                  <input
                    name="featured"
                    id="featured"
                    onChange={handleInputenable}
                    // value={editUser?.featured}
                    className="rs-input"
                    defaultChecked={
                      editUser?.featured == 1 ? true : false
                    }
                    checked={editUser?.featured == 1 ? true : false}
                    type="checkbox"
                  />
                  <span
                    className="slider round"
                    name="featured"
                    id="featured"
                    onChange={handleInputenable}
                    value={editUser?.featured == 1 ? "1" : "0"}
                  ></span>
                </label>
              </div>
            </div>
            <div className="row mx-0">
              <div className="col-9 ps-0">
                <MessageLabel text="Is this series Active" />
              </div>
              <div className="col-3 text-end">
                <label className="switch">
                  <input
                    name="active"
                    id="active"
                    onChange={handleInputenable}
                    // value={editUser?.active}
                    defaultChecked={
                      editUser?.active == 1 ? true : false
                    }
                    checked={editUser?.active == 1 ? true : false}
                    className="rs-input"
                    type="checkbox"
                  />
                  <span
                    className="slider round"
                    name="active"
                    id="active"
                    onChange={handleInputenable}
                    value={editUser?.active == 1 ? "1" : "0"}
                  ></span>
                </label>
              </div>
            </div>
            <div className="row mx-0">
              <div className="col-9 ps-0">
                <MessageLabel text="Enable this series as Slider" />
              </div>
              <div className="col-3 text-end">
                <label className="switch">
                  <input
                    name="banner"
                    onChange={handleInputenable}
                    className="r-input"
                    id="banner"
                    // value={editUser?.banner}
                    defaultChecked={
                      editUser?.banner == 1 ? true : false
                    }
                    checked={editUser?.banner == 1 ? true : false}
                    type="checkbox"
                  />
                  <span
                    className="slider round"
                    name="banner"
                    onChange={handleInputenable}
                    id="banner"
                    value={editUser?.banner == 1 ? "1" : "0"}
                  ></span>
                </label>
              </div>
            </div>
            <div className="row mx-0">
              <div className="col-9 ps-0">
                <MessageLabel text="Season Trailer" />
              </div>
              <div className="col-3 text-end">
                <label className="switch">
                  <input
                    name="series_trailer"
                    id="series_trailer"
                    onChange={handleInputenable}
                    className="r-input"
                    // value={editUser?.series_trailer}
                    defaultChecked={
                      editUser?.series_trailer == 1 ? true : false
                    }
                    checked={
                      editUser?.series_trailer == 1 ? true : false
                    }
                    type="checkbox"
                  />
                  <span
                    className="slider round"
                    name="series_trailer"
                    id="series_trailer"
                    onChange={handleInputenable}
                    value={editUser?.series_trailer == 1 ? "1" : "0"}
                  ></span>
                </label>
              </div>
            </div>
          </Wrapper>
          <Wrapper>
            <MessageHeading text="SEO" />
            <MessageLabel text="Website Page Title" />
            <input
              type="text"
              value={editUser?.website_page_title}
              name="website_page_title"
              className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder theme-text-color border-0 mb-3"
              onChange={handleInput}
              placeholder="website title"
            />
            <MessageLabel text="Website URL" />
            <input
              type="text"
              value={editUser?.website_URL}
              name="website_URL"
              className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder theme-text-color border-0 mb-3"
              onChange={handleInput}
              placeholder="website url"
            />
            <MessageLabel text="Meta Description" />
            <textarea
              type="text"
              value={editUser?.Meta_description}
              name="Meta_description"
              className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder theme-text-color border-0 "
              onChange={handleInput}
              placeholder="meta description"
            ></textarea>
          </Wrapper>
        </RightWrapper>
        <AdminCustomButton saveMethod={handleUpdate} saveText="Update Series" className="ms-auto" />
      </FormContainer>
      <div className="mt-5 p-md-0 p-3 ">
        <div className="p-3 rounded-3 theme-bg-color">
          <AdminSaveHeader heading="Manage Season &amp; Episodes" saveText="Create Season" saveMethod={handleModal} className="px-0" />
          <div class={`modal  packageModel fade ${modalClose && 'd-block show'}`} id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
              <div className="modal-content  theme-bg-color ">
                <div className="modal-header theme-border-bottom-secondary">
                  <MessageBox text="Add Season" classname="modal-title theme-text-color admin-input-title" />
                  <button type="button" className='bg-transparent' onClick={() => handleModal()} ><IoClose fill='currentColor' style={{ width: "25px", height: "25px" }} /></button>
                </div>
                <div className="modal-body">
                  <MessageBox text="Season Thumbnail" classname="theme-text-color admin-input-title" />
                  <MessageBox text="(16:9 Ratio or 1280X720px)" classname='d-block mt-2 theme-text-color opacity-75 admin-input-description' />
                  <div className="row my-3 mx-0">
                    <div className="col-6 ps-0">
                      <div
                        className="imagedrop theme-border-color"
                        onClick={() =>
                          fileInputRef4.current.click()
                        }>
                        <input
                          type="file"
                          ref={fileInputRef4}
                          onChange={(event) =>
                            handleFileChange(
                              event,
                              fileInputRef4,
                              setSelectedFile4
                            )
                          }
                          accept="image/*"
                          style={{ display: "none" }}
                        />
                        <label className=" theme-text-color">
                          <CameraRetroIcon className=" theme-text-color" />
                        </label>
                      </div>
                    </div>
                    <div className="col-6">
                      <div>
                        {selectedFile4 && (
                          <div className="imagedropcopy theme-border-color text-end">
                            <div className="row p-0 m-0">
                              <div className="col-10 p-0 m-0">
                                <img
                                  src={URL?.createObjectURL(
                                    selectedFile4
                                  )}
                                  alt="Uploaded"
                                  className="thumbnails-upload-images"
                                />
                              </div>
                              <div className="col-2 p-0 m-0" style={{ alignSelf: "center" }} >
                                <button onClick={() => handleDelete(setSelectedFile4)}
                                  type="button"
                                  className=" theme-text-color bg-transparent "
                                ><IoClose className="custom-close-btn" /></button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mt-2 mb-3 ">
                    <MessageBox text="Season Trailer" classname="d-block mb-2 theme-text-color admin-input-title" />
                    <div className="dropzoneStyle">
                      <Dropzone accept="video/*" onDrop={onDrop}>
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <MessageBox text="Drag and drop a video file here, or click
                              to select a file" classname="p-4 admin-input-description opacity-75 d-block" />
                          </div>
                        )}
                      </Dropzone>
                      {selectedFile && (
                        <div>
                          <MessageLabel text={selectedFile?.name}> </MessageLabel>
                        </div>
                      )}
                      <input
                        type="file"
                        accept="video/*"
                        style={{ display: "none" }}
                        onChange={handleFileChangessss}
                      />
                    </div>
                  </div>
                  <MessageLabel text="Choose User Access" />
                  <MessageSubHeading text="Who Is Allowed To View This Live Stream ?" />
                  <select onChange={(e) => setStoreliveuseraccessseason(e.target.value)}
                    name="access"
                    id="access"
                    className="form-select theme-bg-color-secondary custom-placeholder theme-text-color border-0 mb-3"
                    onClick={(event) => {
                      setallliveuseraccessseason(
                        event.target.value
                      );
                    }}
                  >
                    {useraccessseason?.map((item) => (
                      <option value={item?.value} className="theme-bg-color theme-text-color">
                        {item?.name}
                      </option>
                    ))}
                  </select>
                  <div>{allseriesuseraccessseason()}</div>
                </div>
                <div className="modal-footer  border-0">
                  <button type="button" className="bg-transparent btn theme-text-color sliderBtnText" onClick={() => handleModal()} >Cancel </button>
                  <button type="button" className=" btn btn-primary px-3 py-1" onClick={() => series_season()}   >Done</button>
                </div>
              </div>
            </div>
          </div>
          <div className="w-100 episodeContainer">
            <div className="episodeList">
              <div className="d-flex flex-wrap theme-border-bottom-secondary  ">
                <div className="col-3">
                  <MessageBox classname="py-3 d-block m-0 fw-bold theme-text-color" text="Seasons" />
                </div>
                <div className="col-3">
                  <MessageBox classname="py-3 d-block m-0 fw-bold theme-text-color" text="Operation" />
                </div>
                <div className="col-3">
                  <MessageBox classname="py-3 d-block m-0 fw-bold theme-text-color" text="Episodes" />
                </div>
                <div className="col-3">
                  <MessageBox classname="py-3 d-block m-0 fw-bold theme-text-color" text="Operation" />
                </div>
              </div>
              {editseason?.map((edit) => (
                <div className="d-flex  flex-wrap theme-border-bottom-secondary">
                  <div className="col-3 py-3">
                    <MessageBox text={`Season ${edit?.id}`} classname="theme-text-color" />
                  </div>
                  <div className="col-3 py-3">
                    <div className="d-flex align-items-center flex-wrap gap-2">
                      <Link to={`/channel/edit-season/${edit?.id}`} className="theme-text-color theme-bg-color">
                        <span>
                          <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24">
                            <g>
                              <path class="svgIcon" d="M10.3,18.5l-4.74.64A.49.49,0,0,1,5,18.59l.64-4.74a.42.42,0,0,1,.14-.28L17.31,2a.59.59,0,0,1,.85,0L22.11,6a.6.6,0,0,1,0,.84L10.58,18.36A.53.53,0,0,1,10.3,18.5Z" />
                              <line class="svgIcon" x1="1.01" y1="21.58" x2="18.89" y2="21.58" />
                            </g>
                          </svg>
                          <MessageBox text="Edit" classname='ms-2  theme-text-color'></MessageBox>
                        </span>
                      </Link>
                      <div onClick={() => handleDeleteModal(edit?.id)} className="d-flex align-items-center">
                        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24">
                          <g>
                            <rect class="svgIcon" x="4.38" y="5.54" width="14.49" height="17.71" />
                            <line class="svgIcon" x1="1.16" y1="5.54" x2="22.09" y2="5.54" />
                            <g>
                              <line class="svgIcon" x1="9.21" y1="9.56" x2="9.21" y2="19.06" />
                              <line class="svgIcon" x1="14.04" y1="9.56" x2="14.04" y2="19.06" />
                            </g>
                            <path class="svgIcon" d="M7.45,5.22a2.72,2.72,0,0,1,0-.28,4.19,4.19,0,1,1,8.37,0c0,.09,0,.19,0,.29" />
                          </g>
                        </svg>
                        <MessageBox text="Delete" classname='ms-2 theme-text-color'></MessageBox>
                      </div>
                    </div>
                  </div>
                  <div className="col-3 py-3">
                    <MessageBox text={`Episodes ${edit?.id}`} classname="theme-text-color " />
                  </div>
                  <div className="col-3 py-3">
                    <div className="d-flex align-items-center">
                      <Link to={`/channel/manage-episode/${seriesid}/${edit?.id}`}  >
                        <span>
                          <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24">
                            <g>
                              <path class="svgIcon" d="M10.3,18.5l-4.74.64A.49.49,0,0,1,5,18.59l.64-4.74a.42.42,0,0,1,.14-.28L17.31,2a.59.59,0,0,1,.85,0L22.11,6a.6.6,0,0,1,0,.84L10.58,18.36A.53.53,0,0,1,10.3,18.5Z" />
                              <line class="svgIcon" x1="1.01" y1="21.58" x2="18.89" y2="21.58" />
                            </g>
                          </svg>
                          <MessageBox text="Manage Episodes" classname='ms-2 theme-text-color'></MessageBox>
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div >
    </>
  );
}

export default EditNewSeries;
