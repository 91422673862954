import React, { useEffect, useRef, useState } from 'react'
import MusicBannerPanelHorizontal from './MusicBannerPanelHorizontal'
import { songDetailsAction } from '../../Redux/Actions/MusicAction'
import { connect } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Navigation } from "swiper/modules";
import MusicSuggestion from './MusicSuggestion'
import PageLoader from '../../components/Others/PageLoader'
import PageError from '../../components/Others/PageError'
const MusicPlaylistDetails = ({ isLoading, playlistShow, error }) => {
    const { songname } = useParams()
    const dispatch = useDispatch()
    const containerRef = useRef(null);
    const location = useLocation();

    const [containerWidth, setContainerWidth] = useState(992);
    const [domReload, setDomReLoad] = useState(false)

    const calculateSlidesPerView = () => {
        if (containerWidth < 320) { return 1; }
        else if (containerWidth < 450) { return 2; }
        else if (containerWidth < 650) { return 3; }
        else if (containerWidth < 820) { return 4; }
        else if (containerWidth < 992) { return 5; }
        else if (containerWidth < 1400) { return 7; }
        else { return 7; }
    };

    const swiperConfig = {
        slidesPerView: calculateSlidesPerView(),
        spaceBetween: 15,
        navigation: true,
        lazy: true,
        slidesPerGroup: calculateSlidesPerView(),
        modules: [Navigation],
    }
    const handleResize = () => {
        if (containerRef.current) {
            setContainerWidth(containerRef.current.clientWidth);
        }
    };

    useEffect(() => {
        handleResize();
        calculateSlidesPerView()
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [playlistShow, containerRef?.current?.clientWidth]);

    useEffect(() => {
        calculateSlidesPerView();
    });


    useEffect(() => {
        dispatch(songDetailsAction('PLAYLIST', songname))
    }, [location, domReload])

    return (
        isLoading ? <PageLoader /> : error ? <PageError error="Not Found Playlist" /> : <div ref={containerRef}>
            <MusicBannerPanelHorizontal propsType="PLAYLIST" />
            <MusicSuggestion swiperOptions={swiperConfig} PropsType="PLAYLIST" />
        </div>

    )
}
const mapStateToProps = state => ({
    playlistShow: state.get_Playlist_Modal.playlistShow,
    isLoading: state.get_songDetails_Reducer.isLoading,
    error: state.get_songDetails_Reducer.error,
})
export default connect(mapStateToProps)(MusicPlaylistDetails)