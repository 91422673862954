const MetaReducer = (state = {
    meta_url: '',
    meta_title: '',
    meta_description: '',
    meta_image: '',
    title: '',
    error: null,
    isLoading: false,
}, action) => {
    switch (action.type) {
        case "GET_META_REQUEST":
            return {
                ...state,
                isLoading: true,
            };
        case "META_ACTION": return {
            ...state,
            meta_url: action.payload.meta_url,
            meta_title: action.payload.meta_title,
            meta_description: action.payload.meta_description,
            meta_image: action.payload.meta_image,
            title: action.payload.title,
            isLoading: false,
        };
        case "META_FAILED":
            return {
                ...state,
                error: action.payload,
                isLoading: false,
            };
        default:
            return state;
    }
};

export { MetaReducer }