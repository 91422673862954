import React from "react";
import ReelsVideoPage from "./ReelsVideoPage";

export default function ReelsMainPage(props) {
    //console.log(props?.videoDetailsData?.Video_reels_video[0].reels_videos_url)
    const data = [
        {
            channel: "aaa",
            song: "song-1",
            url: props?.videoDetailsData?.Video_reels_video[0].reels_videos_url,
            likes: "32",
            comment: "2",
            shares: "23",
        },


    ];

    return (
        <div>
            <center>
                <div className="d-flex"
                //className="video-container" id="video-container"
                >
                    {data?.map((list, i) => (
                        <ReelsVideoPage
                            key={i}
                            channel={list.channel}
                            song={list.song}
                            url={list.url}
                            likes={list.likes}
                            comment={list.comment}
                            shares={list.shares}
                        />
                    ))}

                    {/*  */}
                </div>
            </center>
        </div>
    );
}
