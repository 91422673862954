// External Libraries and Packages
import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
// Internal Styles
import "swiper/css";
import "swiper/css/navigation";
import { URLDashRemover } from '../../../Pages/CommonMethods/CommonMethods';
import { Link, useParams } from 'react-router-dom';
import PriceLabel from '../../Others/PriceLabel';
import { useTranslation } from 'react-i18next';
import CardWithHover from '../../../Pages/HomePage/HomeSwiperComponents/Theme/CardWithHover';
import CardWithoutHover from '../../../Pages/HomePage/HomeSwiperComponents/Theme/CardWithoutHover';
// Internal Components
const LayoutLanguageSwiper = ({ mapData, heading, sectionClass, path, hoverCard }) => {
    const { lang, slug } = useParams();
    const { t } = useTranslation();
    const swiperConfig = {
        slidesPerView: 2,
        spaceBetween: 15,
        navigation: true,
        lazy: true,
        slidesPerGroup: 2,
        breakpoints: {
            376: { slidesPerView: 2, spaceBetween: 15, slidesPerGroup: 2 },
            576: { slidesPerView: 3, spaceBetween: 15, slidesPerGroup: 3 },
            768: { slidesPerView: 4, spaceBetween: 15, slidesPerGroup: 4 },
            1024: { slidesPerView: 5, spaceBetween: 15, slidesPerGroup: 5 },
            1200: { slidesPerView: 6, spaceBetween: 15, slidesPerGroup: 6 },
            1440: { slidesPerView: 6, spaceBetween: 15, slidesPerGroup: 6 },
        },
        modules: [Navigation],
        className: 'homePageSwiper'
    }
    return (
        <div className={`${sectionClass} zIndex `}>
            <Link to={`/${lang}/languages/${slug}/${path}`} className="text-decoration-none swiperHeading theme-text-color">{t(URLDashRemover(heading))}</Link>
            <Link to={`/${lang}/languages/${slug}/${path}`} className="text-decoration-none swiperHeading theme-text-color float-end viewall-homepage">{t("View All")}</Link>
            <Swiper {...swiperConfig}>
                {mapData?.map((value, index) => (
                    <SwiperSlide key={value?.id} className='theme-bg-color-secondary'>
                        {hoverCard ? <CardWithHover value={value} segMent={path} lang={lang}  hoverClass="homeSwiperContainer" /> : <CardWithoutHover value={value} segMent={path} lang={lang} />}
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>

    )
}

export default LayoutLanguageSwiper