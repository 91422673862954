import React, { useEffect } from 'react'
import AccountSideBar from './AccountSideBar'
import { Outlet, useNavigate } from 'react-router-dom'
import { getItem, getItemToken } from '../../Utils/localStorageUtils'
import { connect } from 'react-redux'
const AccountLayout = ({ languageDetailsData, currentTheme }) => {
    const userRoleLocal = getItem('role')
    const userIdLocal = getItem('user_id')
    const userAccessToken = getItemToken('access_token');
    const navigate = useNavigate()

    useEffect(() => {
        if (currentTheme === 2 && window.location.pathname === `/${languageDetailsData?.Language_Code}/myprofile`) {
            navigate(`/${languageDetailsData?.Language_Code}/myprofile/info`);
        }
    }, [currentTheme, languageDetailsData, navigate]);

    return (
        <section className='paddingTop'>

            <div className='d-flex flex-wrap  p-lg-5 p-3'>
                <div className="col-md-4 col-lg-3 col-12">
                    <AccountSideBar lang={languageDetailsData} role={userRoleLocal} />
                </div>
                <div className="col-md-8 col-lg-9 col-12  ps-0 ps-md-3 ps-lg-5">
                    <Outlet />
                </div>
            </div>
        </section>
    )
}
const mapStateToProps = (state) => ({
    languageDetailsData: state.get_getUserTranslateLanguage_Reducer.languageDetailsData,
    currentTheme: state.get_headerIndex_Reducer.currentTheme,
});
export default connect(mapStateToProps)(AccountLayout);
