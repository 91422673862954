// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.episode_video media-outlet {
    width: 50%;
  }`, "",{"version":3,"sources":["webpack://./src/Contentportal/Content_All_Page/Content_Series_Episode/Contenteditepisode.css"],"names":[],"mappings":"AAAA;IACI,UAAU;EACZ","sourcesContent":[".episode_video media-outlet {\n    width: 50%;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
