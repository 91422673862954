
import { jsPDF } from "jspdf";
import * as XLSX from "xlsx";

const CSVColumn = [
    { label: "ID", key: "id" },
    { label: "TITLE", key: "title" },
    { label: "UPLOADED BY", key: "uploaded_by" },
    { label: "STATUS", key: "active" },
    { label: "BANNER", key: "banner" },
    { label: "UPLOAD ON", key: "upload_on" },
];

const PDFGenerator = (data, fileName) => {
    const doc = new jsPDF();
    const PDFColumns = ["INDEX", "ID", "TITLE", "UPLOADED BY", "STATUS", "BANNER", "UPLOAD ON"];
    const database = data?.map((item, index) => [
        index + 1,
        item?.id,
        item?.title,
        item?.uploaded_by,
        item?.active,
        item?.banner,
        item?.upload_on
    ]);
    doc.autoTable({
        startY: 10,
        startX: 10,
        head: [PDFColumns],
        body: database,
        margin: { top: 5, right: 5, bottom: 5, left: 5 },
        styles: {
            cellPadding: 3,
            fontSize: 10,
            lineColor: [240, 240, 240],
            lineWidth: 0.1,
        }
    });
    doc.save(`${fileName}.pdf`);
};

const XLSXGenerator = (data, fileName) => {
    const extractedData = data.map((item, index) => {
        return {
            Index: index + 1,
            ID: item?.id,
            TITLE: item?.title,
            UPLOADED_BY: item?.uploaded_by,
            STATUS: item?.active,
            BANNER: item?.banner,
            UPLOAD_ON: item?.upload_on
        };
    });
    var wb = XLSX.utils.book_new(), ws = XLSX.utils.json_to_sheet(extractedData);
    XLSX.utils.book_append_sheet(wb, ws, "MySheet");
    XLSX.writeFile(wb, `${fileName}.xlsx`);
};
const albumCSVColumn = [
    { label: "ID", key: "id" },
    { label: "TITLE", key: "albumname" },
    { label: "UPLOADED BY", key: "uploaded_by" },
    { label: "UPLOAD ON", key: "upload_on" },
];
const albumPDFGenerator = (data, fileName) => {
    const doc = new jsPDF();
    const PDFColumns = ["INDEX", "ID", "TITLE", "UPLOADED BY", "UPLOAD ON"];
    const database = data?.map((item, index) => [
        index + 1,
        item?.id,
        item?.albumname,
        item?.uploaded_by,
        item?.upload_on
    ]);
    doc.autoTable({
        startY: 10,
        startX: 10,
        head: [PDFColumns],
        body: database,
        margin: { top: 5, right: 5, bottom: 5, left: 5 },
        styles: {
            cellPadding: 3,
            fontSize: 10,
            lineColor: [240, 240, 240],
            lineWidth: 0.1,
        }
    });
    doc.save(`${fileName}.pdf`);
};
const albumXLSXGenerator = (data, fileName) => {
    const extractedData = data.map((item, index) => {
        return {
            Index: index + 1,
            ID: item?.id,
            TITLE: item?.albumname,
            UPLOADED_BY: item?.uploaded_by,
            UPLOAD_ON: item?.upload_on
        };
    });
    var wb = XLSX.utils.book_new(), ws = XLSX.utils.json_to_sheet(extractedData);
    XLSX.utils.book_append_sheet(wb, ws, "MySheet");
    XLSX.writeFile(wb, `${fileName}.xlsx`);
};

const artistCSVColumn = [
    { label: "ID", key: "id" },
    { label: "NAME", key: "artist_name" },
    { label: "ARTISTS TYPE", key: "artist_type" },
    { label: "UPLOADED BY", key: "uploaded_by" },
    { label: "UPLOAD ON", key: "upload_on" },
];

const artistPDFGenerator = (data, fileName) => {
    const doc = new jsPDF();
    const PDFColumns = ["INDEX", "ID", "ARTISTS", "ARTISTS TYPE", "UPLOADED BY", "UPLOAD ON"];
    const database = data?.map((item, index) => [
        index + 1,
        item?.id,
        item?.artist_name,
        item?.artist_type,
        item?.uploaded_by,
        item?.upload_on
    ]);
    doc.autoTable({
        startY: 10,
        startX: 10,
        head: [PDFColumns],
        body: database,
        margin: { top: 5, right: 5, bottom: 5, left: 5 },
        styles: {
            cellPadding: 3,
            fontSize: 10,
            lineColor: [240, 240, 240],
            lineWidth: 0.1,
        }
    });
    doc.save(`${fileName}.pdf`);
};
const artistXLSXGenerator = (data, fileName) => {
    const extractedData = data.map((item, index) => {
        return {
            Index: index + 1,
            ID: item?.id,
            NAME: item?.artist_name,
            TYPE: item?.artist_type,
            UPLOADED_BY: item?.uploaded_by,
            UPLOAD_ON: item?.upload_on
        };
    });
    var wb = XLSX.utils.book_new(), ws = XLSX.utils.json_to_sheet(extractedData);
    XLSX.utils.book_append_sheet(wb, ws, "MySheet");
    XLSX.writeFile(wb, `${fileName}.xlsx`);
};
export { CSVColumn, PDFGenerator, XLSXGenerator, artistXLSXGenerator, artistPDFGenerator, artistCSVColumn, albumXLSXGenerator, albumPDFGenerator, albumCSVColumn } 