import { URLGenerater } from "../../Pages/CommonMethods/CommonMethods";

const getMusicHomePageReducer = (state = { musicHomePage: [], getSource: [], getSourceType: [], error: null, isLoading: true }, action) => {
    switch (action.type) {
        case "GET_MUSIC_HOMEPAGE":
            return {
                ...state,
                musicHomePage: action.payload,
                getSource: (action.payload || []).map(item => URLGenerater(item.source) || []),
                getSourceType: (action.payload || []).map(item => URLGenerater(item.source_type) || []),
                isLoading: false,
            };
        case "GET_MUSIC_HOMEPAGE_FAILED":
            return {
                ...state,
                error: action.payload,
                isLoading: false
            };

        default:
            return state;
    }
};

const MusicViewAllPageReducer = (state = { viewAllData: [], error: null, isLoading: true }, action) => {
    switch (action.type) {
        case "GET_MUSIC_VIEWALLPAGE_ACTION":
            return {
                ...state,
                viewAllData: action.payload,
                isLoading: false
            };
        case "GET_MUSIC_VIEWALLPAGE_FAILED":
            return {
                ...state,
                error: action.payload,
                isLoading: false
            };
        case "CLEAR_MUSIC_VIEWALLPAGE":
            return {
                ...state,
                viewAllData: [],
            };
        default:
            return state;
    }
};

const PlaylistModalReducers = (state = { initialModal: false, createModal: false, playlistShow: false, stationModal: false, chooseStation: false, next: false, purchaseModal: false, homepage: "home", upComingTitle: "", minimize: false, layout: "grid" }, action) => {
    switch (action.type) {
        case "OPEN_INITIAL_MODAL":
            return {
                ...state,
                initialModal: action.payload,
            };
        case "OPEN_CREATE_MODAL":
            return {
                ...state,
                createModal: action.payload,
            };
        case "OPEN_PLAYLIST":
            return {
                ...state,
                playlistShow: action.payload,
            };
        case "OPEN_STATION":
            return {
                ...state,
                stationModal: action.payload,
            };
        case "CHOOSE_STATION":
            return {
                ...state,
                chooseStation: action.payload,
            };
        case "PURCHASE_MODAL":
            return {
                ...state,
                purchaseModal: action.payload,
                upComingTitle: action.upComingTitle
            };
        case "MINIMIZE_PLAYER":
            return {
                ...state,
                minimize: action.payload,
            };
        case "LAYOUT_SWITCHER":
            return {
                ...state,
                layout: action.payload,
            };

        case "NEXT":
            return {
                ...state,
                next: action.payload,
            };
        case "HOME_PAGE_MUSIC":
            return {
                ...state,
                homepage: action.payload,
            };
        default:
            return state;
    }
};

const playlistReducers = (state = { playlistAudio: [], isLoading: true }, action) => {
    switch (action.type) {
        case 'GET_AUDIO_SUCCESS': return {
            isLoading: false,
            playlistAudio: action.payload
        }
        case 'GET_AUDIO_FAILED': return {
            isLoading: false,
            error: action.payload
        }

        default:
            return state
    }
}

const currentReducers = (
    state = { currentAudio: [], currentIndex: 0, currentToggle: false, currentPlaying: true, currentRepeat: 'disabled', currentTrackId: null, currentTime: null, currentDuration: 0, currentloadings: true, currentPage: "audio", currentClose: true, currentUserInteract: null, playlistMusicId: null, error: null, actionType: null }, action) => {
    switch (action.type) {
        case "GET_CURRENT_SUCCESS":
            return {
                ...state,
                currentloadings: false,
                currentAudio: action.payload,
                currentPage: "audio",
            };
        case "GET_CURRENT_PAGE":
            return {
                ...state,
                currentPage: action.inPage,
            };
        case "GET_TOGGLE_SUCCESS": {
            return {
                ...state,
                currentToggle: action.toggle,
            };
        }
        case "GET_CLOSE_SUCCESS": {
            return {
                ...state,
                currentClose: action.isClose,
            };
        }

        case "GET_PLAYING_SUCCESS": {
            return {
                ...state,
                currentPlaying: action.isPlaying,
            };
        }
        case "GET_REPEAT_SUCCESS": {
            return {
                ...state,
                currentRepeat: action.isRepeat,
            };
        }
        case "GET_LYRIC_SUCCESS": {
            return {
                ...state,
                currentTime: action.currentTimes,
            };
        }
        case "GET_CURRENT_TIME_INDEX":
            return {
                ...state,
                currentTime: action.currentTime,
            };
        case "GET_CURRENT_INDEX":
            return {
                ...state,
                currentIndex: action.currentIndex,
            };

        case "GET_INTERACT_DATA": {
            return {
                ...state,
                currentUserInteract: action.payload
            }
        }
        case "GET_CURRENT_DURATION":
            return {
                ...state,
                currentDuration: action.isDuration,
            };
        case "GET_PLAYLIST_STORE_ID":
            return {
                ...state,
                playlistMusicId: action.payload,
            };
        case "GET_CURRENT_PLAYLIST_OR_ALBUM":
            return {
                ...state,
                playlistData: action.payload,
            };
        case "GET_CURRENT_FAILED":
            return {
                currentToggle: false,
                currentloadings: false,
                currentPlaying: false,
                currenterror: action.payload,
                error: action.payload
            };
        case "GET_ACTION_TYPE":
            return {
                ...state,
                actionType: action.payload,
            };



        default:
            return state;
    }
};
const songDetailsReducer = (state = { songDetailsData: [], topArtists: null, otherAudios: null, otherPlaylist: null, settings: null, thumbnailSettings: null, currency: null, error: null, isLoading: true }, action) => {
    switch (action.type) {
        case "GET_SONG_DETAILS_ACTION":
            return {
                ...state,
                songDetailsData: action.payload,
                topArtists: action.TopArtist,
                otherAudios: action.otherAudios,
                otherPlaylist: action.otherPlaylist,
                settings: action.payload.setting,
                thumbnailSettings: action.payload.ThumbnailSetting,
                currency: action.payload.currency,
                isLoading: false
            };
        case "GET_SONG_DETAILS_ACTION_FAILED":
            return {
                ...state,
                error: action.payload,
                isLoading: false
            };
        default:
            return state;
    }
};
export { getMusicHomePageReducer, PlaylistModalReducers, MusicViewAllPageReducer, playlistReducers, currentReducers, songDetailsReducer }