import React from 'react'

const HeaderLoader = () => {
    return (
        <header className={`homePageNavbar  d-flex fixed-top justify-content-between align-items-center px-2 px-md-4 px-lg-2 }`}>
            <div className="col-3">
                <div style={{ width: "180px", height: "35px" }} className="my-3">
                    <div className={`skeletonStatic w-100 h-100 rounded-2 overflow-hidden `} >
                        <div className="skeleton-right w-100 h-100">
                            <div className="square d-flex align-items-center justify-content-center p-3 h-100">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-6 d-none d-md-block">
                <ul className="list-unstyled d-flex align-items-center m-0">
                    {true ? Array.from({ length: 5 }).map(() =>
                        <li className="homeNavListLoader p-2">
                            <div className={`skeletonStatic w-100 h-100 rounded-2 overflow-hidden `} >
                                <div className="skeleton-right w-100 h-100">
                                    <div className="square d-flex align-items-center justify-content-center p-3 h-100">
                                    </div>
                                </div>
                            </div>
                        </li>
                    ) : null}
                </ul>
            </div>
            <div className="col-3">
                <div className="d-flex align-items-center justify-content-between gap-3">
                    <div style={{ width: "35px", height: "35px" }} className="my-3">
                        <div className={`skeletonStatic w-100 h-100 rounded-2 overflow-hidden `} >
                            <div className="skeleton-right w-100 h-100">
                                <div className="square d-flex align-items-center justify-content-center p-3 h-100">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ width: "150px", height: "35px" }} className="my-3">
                        <div className={`skeletonStatic w-100 h-100 rounded-2 overflow-hidden `} >
                            <div className="skeleton-right w-100 h-100">
                                <div className="square d-flex align-items-center justify-content-center p-3 h-100">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ width: "150px", height: "35px" }} className="my-3">
                        <div className={`skeletonStatic w-100 h-100 rounded-2 overflow-hidden `} >
                            <div className="skeleton-right w-100 h-100">
                                <div className="square d-flex align-items-center justify-content-center p-3 h-100">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default HeaderLoader