import React, { useEffect, useRef } from 'react';
import Plyr from 'plyr';
import 'plyr/dist/plyr.css';
// import "./VideoSection.css"

const VideoSection = () => {
  const playerRef = useRef(null);

  useEffect(() => {
    const player = new Plyr(playerRef.current);
    return () => {
      player.destroy();
    };
  }, []);

  return (
    <div id='root' className='paddingTop'>
      <video ref={playerRef}>
        <source src="https://plrjs.com/new.mp4" type="video/mp4" />
      </video>
    </div>
  );
};

export default VideoSection;  
