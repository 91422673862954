// External Libraries and Packages
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
// Internal Styles
import "swiper/css";
import "swiper/css/navigation";
import LazyLoadImage from './LazyLoadImage';
import { useTranslation } from 'react-i18next';
// Internal Components'
import { URLGenerater } from '../../CommonMethods/CommonMethods';
const ContentPartnerAll = ({ item }) => {
  const { t } = useTranslation();
  const { lang } = useParams();
  return (
    <div className='px-3 px-md-4 px-lg-4 pt-1 zIndex'>
      { item?.data?.length > 0 && (<>
          <Link to={`/${lang}/${URLGenerater(item?.source)}`}
            className="blocklinetitle text-decoration-none d-inline-block swiperHeading theme-text-color"
          >{t(item?.header_name)}
          </Link>
          <Link to={`/${lang}/${URLGenerater(item?.source)}`}
            className="blocklinetitle text-decoration-none float-end swiperHeading theme-text-color viewall-homepage"
          >{t("View All")}
          </Link>
        </>)
      }
      <div className="">
        <Swiper
          slidesPerView={2}
          spaceBetween={15}
          navigation={true}
          lazy={true}
          slidesPerGroup={2}
          breakpoints={{
            376: {
              slidesPerView: 2,
              spaceBetween: 15,
              slidesPerGroup: 2,
            },
            576: {
              slidesPerView: 3,
              spaceBetween: 15,
              slidesPerGroup: 3,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 15,
              slidesPerGroup: 4,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 15,
              slidesPerGroup: 5,
            },
            1200: {
              slidesPerView: 6,
              spaceBetween: 15,
              slidesPerGroup: 6,
            }

          }}
          modules={[Navigation]}
          className={`homePageSwiper categorySizeSmall `}
        >
          {item?.data?.map((value, index) => (
            <SwiperSlide key={value?.id} className='theme-bg-color-secondary'>
              <Link to={`/${lang}/partners/${value?.slug}`} className="text-decoration-none  swiperHeading categoryHover theme-text-color  d-block h-100 w-100" >
                <LazyLoadImage key={value?.id} src={value?.picture} alt={value?.username} title={value.username} imageSize="loaderMain object-fit-cover" classNameOptional="rounded-3" />
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}

export default ContentPartnerAll