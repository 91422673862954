import { connect } from "react-redux";
const MiniPrograssBar = ({ handleTime, progressValue, duration, currentloadings, readyState }) => {

    return (

        <>
            {readyState < 1 ?
                <div className="square" style={{ height: "2px", width: "100%" }}></div> :
                <div className="progress-container w-100 position-relative" >
                    <span className="progress-filled-circle bgButton" style={{ [document.body.getAttribute('dir') === 'rtl' ? 'right' : "left"]: `${progressValue}%` }}></span>
                    <div className={`progress`} onClick={(e) => handleTime((e.nativeEvent.offsetX / e.target.offsetWidth) * duration)} >
                        <div className="progress-filled bgButton" style={{ width: `${progressValue}%` }} ></div>
                    </div>
                </div>
            }
        </>
    )
}

const mapStateToProps = state => ({
    currentloadings: state.get_current_Reducers.currentloadings,
});
export default connect(mapStateToProps)(MiniPrograssBar)