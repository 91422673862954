import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import JoditEditor from "jodit-react";
import Select from "react-select";
import { TagsInput } from "react-tag-input-component";
import { toast } from "react-toastify";

import { useNavigate } from "react-router-dom";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import ResponseLoader from "../../../components/Others/ResponseLoader";
import AdminSaveHeader from "../../../components/Others/adminCommonComponents/AdminSaveHeader";
import Wrapper from "../../CommonComponents/Wrapper";
import MessageHeading from "../../CommonComponents/MessageHeading";
import MessageLabel from "../../CommonComponents/MessageLabel";
import MessageSubHeading from "../../CommonComponents/MessageSubHeading";
import { IoClose } from "react-icons/io5";
import FormContainer from "../../CommonComponents/FormContainer";
import RightWrapper from "../../CommonComponents/RightWrapper";
import LeftWrapper from "../../CommonComponents/LeftWrapper";
import MessageBox from "../../../components/Others/MessageBox";
import AdminCustomButton from "../../../components/Others/adminCommonComponents/AdminCustomButton";

function AddNewSeries() {
  const [genre_id, setGenre_id] = useState("");
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [type, setUrl_type] = useState("");
  const [access, setAccess] = useState("");
  const [details, setDetails] = useState("");
  const [description, setDescription] = useState("");
  const [active, setActive] = useState("");
  const [ppv_status, setPpv_status] = useState("");
  const [featured, setFeatured] = useState("");
  const [views, setViews] = useState("");
  const [rating, setRating] = useState("");
  const [searchTags, setSearchTags] = useState([]);
  const [image, setImage] = useState("");
  const [player_image, setPlayer_image] = useState("");
  const [tv_image, setTv_image] = useState("");
  const [embed_code, setEmbed_code] = useState("");
  const [mp4_url, setMp4_url] = useState("");
  const [webm_url, setWebm_url] = useState("");
  const [ogg_url, setOgg_url] = useState("");
  const [year, setYear] = useState("");
  const [trailer, setTrailer] = useState("");
  const [banner, setBanner] = useState("");
  const [deleted_at, setDeleted_at] = useState("");
  const [series_trailer, setSeries_trailer] = useState("");
  const [season_trailer, setSeason_trailer] = useState("");
  const [status, setStatus] = useState("");
  const [season, setSeason] = useState("");
  const [uploaded_by, setUploaded_by] = useState("");
  const [url, setUrl] = useState("");

  const [websitetitle, setWebsitetitle] = useState("");
  const [metadescription, setMetadescription] = useState("");
  const [websiteurl, setWebsiteurl] = useState("");

  const [Thumbnail, setThumbnail] = useState("");
  const [Player_thumbnail, setPlayer_thumbnail] = useState("");
  const [TV_Thumbnail, setTV_Thumbnail] = useState("");
  const [series_id, setSeries_id] = useState("");

  const [user_access, setUser_access] = useState([]);
  const [livestream_source, setLivestream_source] = useState([]);
  const [inapppurchase, setInappPurchase] = useState("");
  const [age_restrictdata, setAge_restrictdata] = useState([]);
  const [age_restrict, setAge_restrict] = useState([]);
  const [publish_time, setPublish_time] = useState("");

  const [publish_type, setPublish_type] = useState("");
  const [adsPositions, setAdsPositions] = useState([]);
  const [selectedAdsPosition, setSelectedAdsPosition] = useState(null);
  const [secondSelectOptions, setSecondSelectOptions] = useState([]);

  const [autoFillValuetitle, setAutoFillValuetitle] = useState("");

  const access_token_channel = localStorage.getItem("access_token_channel");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token_channel,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const handleInputKeyDowntitle = (event) => {
    if (event.key == "Enter" || event.key == "Tab") {
      generateSlug(editUser?.title);
    }
  };
  const handleBlurtitle = () => {
    generateSlug(editUser?.title);
  };

  const handleAutoFillChangetitle = (event) => {
    setValidationMessageslug("");
    setIsValidationHiddenslug(true);
    setAutoFillValuetitle(event.target.value);
  };

  const generateSlug = (title) => {
    title = title.trim();
    const slug = title.toLowerCase().replace(/\s+/g, "-");
    setAutoFillValuetitle(slug);
  };

  const contentFieldChanaged = (data) => {
    setDetails(data);
    // console.log("datasssssss", data);
  };

  // console.log(inputValue, "checkboxChecked");

  const [ios_ppv_price, setIos_ppv_price] = useState("");
  const [userAccesses, setUserAccesses] = useState([]);
  const [userAccessesValue, setUserAccessesValue] = useState([]);
  const handleAccessChange = (e) => {
    setUserAccessesValue(e);
  };
  // console.log(userAccessesValue, "userAccess");

  const handleAdsPositionChange = (selectedValue5) => {
    setSelectedAdsPosition(selectedValue5);
    // console.log(selectedValue5); // Selected value
  };

  const [editUser, setEditUser] = useState({
    title: "",
    slug: "",
    duration: "",
  });

  const navigate = useNavigate();

  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputValueAPI, setInputValueAPI] = useState("");

  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
    if (!checkboxChecked) {
      setInputValue(inputValueAPI);
    } else {
      setInputValue("");
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleTagsChange = (newTags) => {
    // Convert tags to lowercase, remove duplicates, and filter out empty tags
    const cleanedTags = newTags
      .map((tag) => tag.trim().toLowerCase())
      .filter((tag) => tag !== "")
      .filter((value, index, self) => self.indexOf(value) == index);
    setSearchTags(cleanedTags);
  };

  const [publishType, setPublishType] = useState("publish_now");
  const [publishTime, setPublishTime] = useState("");
  // const [formDatastore, setFormData] = useState(new FormData());

  const handlePublishTypeChange = (event) => {
    const selectedValue = event.target.value;
    setPublishType(selectedValue);

    if (selectedValue == "publish_later") {
      setPublishTime(""); // Reset the publish time when switching to "Publish Later"
    }
  };

  const handlePublishTimeChange = (event) => {
    const timeValue = event.target.value;
    setPublishTime(timeValue);
  };

  const [inputValueyear, setInputValueyear] = useState("");

  const handleChangeyear = (event) => {
    const value = event.target.value;
    if (/^\d{0,4}$/.test(value)) {
      setInputValueyear(value);
    }
  };

  const handleInputdata = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;

    // Remove non-digit characters from input
    const formattedInput = inputValue.replace(/\D/g, "");

    // Add colons to maintain the format HH:MM:SS
    const formattedTime = formattedInput
      .replace(/^(\d{2})(\d{2})(\d{2})$/, "$1:$2:$3")
      .substr(0, 8);

    setEditUser({
      ...editUser,
      [inputName]: formattedTime,
    });
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessageslug("");
    setIsValidationHiddenslug(true);
    setValidationMessagetitle("");
    setIsValidationHiddentitle(true);

    setEditUser({ ...editUser, title: e.target.value });
    setEditUser({ ...editUser, [e.target.name]: e.target.value });

    setActive({ [e.target.name]: e.target.value });

    setFeatured({ ...featured, [e.target.name]: e.target.value });
    setStatus({ ...status, [e.target.name]: e.target.value });
    setPpv_status({ ...ppv_status, [e.target.name]: e.target.value });
    setBanner({ ...banner, [e.target.name]: e.target.value });
    // setActive({ ...active, [e.target.name]: e.target.value });
    setSeason_trailer({ ...season_trailer, [e.target.name]: e.target.value });
    setSeries_trailer({ ...series_trailer, [e.target.name]: e.target.value });
    setViews({ ...views, [e.target.name]: e.target.value });
    setTrailer({ ...trailer, [e.target.name]: e.target.value });

    if (e.target.checked == true) {
      setPpv_status({ ...ppv_status, [e.target.name]: 1 });
      setFeatured({ ...featured, [e.target.name]: 1 });
      setStatus({ ...status, [e.target.name]: 1 });
      setBanner({ ...banner, [e.target.name]: 1 });
      setActive({ ...active, [e.target.name]: 1 });
      setSeason_trailer({ ...season_trailer, [e.target.name]: 1 });
      setSeries_trailer({ ...series_trailer, [e.target.name]: 1 });
      setViews({ ...views, [e.target.name]: 1 });
      setTrailer({ ...trailer, [e.target.name]: 1 });
    } else {
      setPpv_status({ ...ppv_status, [e.target.name]: 0 });
      setFeatured({ ...featured, [e.target.name]: 0 });
      setBanner({ ...banner, [e.target.name]: 0 });
      setActive({ ...active, [e.target.name]: 0 });
      setSeason_trailer({ ...season_trailer, [e.target.name]: 0 });
      setSeries_trailer({ ...series_trailer, [e.target.name]: 0 });
      setViews({ ...views, [e.target.name]: 0 });
      setTrailer({ ...trailer, [e.target.name]: 0 });
    }
  };

  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);

  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFile3, setSelectedFile3] = useState(null);

  const handleFileChange = (event, fileInputRef, setSelectedFile) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDelete = (
    setSelectedFile1,
    setSelectedFile2,
    setSelectedFile3
  ) => {
    setSelectedFile1(null);
    if (fileInputRef1.current) {
      fileInputRef1.current.value = null;
    }
    setSelectedFile2(null);
    if (fileInputRef2.current) {
      fileInputRef2.current.value = null;
    }
    setSelectedFile3(null);
    if (fileInputRef3.current) {
      fileInputRef3.current.value = null;
    }
  };

  // Multiselect
  const [block_country, setBlock_Country] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [available_country, setAvailable_country] = useState([]);
  const [
    selectedOptionsAvailable_country,
    setSelectedOptionsAvailable_country,
  ] = useState([]);

  const [language, setLanguage] = useState([]);
  const [selectedOptionslanguage, setSelectedOptionslanguage] = useState([]);

  const [artists, setArtists] = useState([]);
  const [selectedOptionsartists, setSelectedOptionsartists] = useState([]);

  const [category, setCategory] = useState([]);
  const [selectedOptionscategory, setSelectedOptionscategory] = useState([]);

  const [audio_albums, setAudio_albums] = useState([]);
  const [selectedOptionsaudio_albums, setSelectedOptionsaudio_albums] =
    useState([]);

  useEffect(() => {
    fetchOptions();
  }, []);

  const fetchOptions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/series/create`,
        { headers: headers }
      );
      setBlock_Country(response?.data?.Block_Country);
      setAvailable_country(response?.data?.Available_country);
      setLanguage(response?.data?.languages);
      setArtists(response?.data?.artists);
      setUser_access(response?.data?.user_access);
      setInappPurchase(response?.data?.InappPurchase);
      // setLivestream_source(response?.data?.Livestream_source);
      setCategory(response?.data?.series_categories);

      const globelset = response?.data?.ppv_gobal_price;
      setInputValueAPI(globelset);

      // console.log(response?.data?.user_access);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectChange = (selectedValues) => {
    setSelectedOptions(selectedValues);
  };

  const handleSelectChangeAvailable_country = (selectedValues) => {
    setSelectedOptionsAvailable_country(selectedValues);
  };

  const handleSelectChangeaudio_albums = (selectedValues) => {
    setSelectedOptionsaudio_albums(selectedValues);
  };

  const handleSelectChangelanguage = (selectedValues) => {
    setValidationMessagelanguage("");
    setIsValidationHiddenlanguage(true);
    setSelectedOptionslanguage(selectedValues);
  };

  const handleSelectChangeartists = (selectedValues) => {
    setSelectedOptionsartists(selectedValues);
  };

  const handleSelectChangecategory = (selectedValues) => {
    setValidationMessagecategory("");
    setIsValidationHiddencategory(true);
    setSelectedOptionscategory(selectedValues);
  };

  // Multiselect

  const [validationMessagetitle, setValidationMessagetitle] = useState("");
  const [isValidationHiddentitle, setIsValidationHiddentitle] = useState(true);

  const [validationMessageslug, setValidationMessageslug] = useState("");
  const [isValidationHiddenslug, setIsValidationHiddenslug] = useState(true);

  const [validationMessagecategory, setValidationMessagecategory] =
    useState("");
  const [isValidationHiddencategory, setIsValidationHiddencategory] =
    useState(true);

  const [validationMessagelanguage, setValidationMessagelanguage] =
    useState("");
  const [isValidationHiddenlanguage, setIsValidationHiddenlanguage] =
    useState(true);

  const [validationMessageAccess, setValidationMessageAccess] =
    useState("");
  const [isValidationHiddenAccess, setIsValidationHiddenAccess] =
    useState(true);

  const accessInputRef = useRef(null);

  const titleInputRef = useRef(null);
  const categoryInputRef = useRef(null);
  const languageInputRef = useRef(null);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  async function series() {
    let focusInputRef = null;

    if (editUser?.title == "") {
      setValidationMessagetitle("Title cannot be empty.");
      setIsValidationHiddentitle(false);
      focusInputRef = titleInputRef;
    }
    if (selectedOptionscategory.length == 0) {
      setValidationMessagecategory("Please select at least one Category.");
      setIsValidationHiddencategory(false);
      focusInputRef = categoryInputRef;
    }
    if (selectedOptionslanguage.length == 0) {
      setValidationMessagelanguage("Please select at least one Language.");
      setIsValidationHiddenlanguage(false);
      focusInputRef = languageInputRef;
    }
    if (!storeseriesuseraccess) {
      setValidationMessageAccess("Please select user access.");
      setIsValidationHiddenAccess(false);
      focusInputRef = accessInputRef;
    }

    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }

    const editInputfeatured = featured?.featured;
    var datafeatured = JSON.stringify(editInputfeatured);
    var featureddata = datafeatured;

    const editInputbanner = banner?.banner;
    var databanner = JSON.stringify(editInputbanner);
    var bannerdata = databanner;

    const editInputvalue = active?.active;
    var data = JSON.stringify(editInputvalue);
    var aactivedata = data;

    const editInputvalueseason_trailer = season_trailer?.season_trailer;
    var dataseason = JSON.stringify(editInputvalueseason_trailer);
    var season_trailerdata = dataseason;

    const editInputvalueviews = views?.views;
    var dataviews = JSON.stringify(editInputvalueviews);
    var viewsdata = dataviews;

    const editInputvalueseries_trailer = series_trailer?.series_trailer;
    var dataseries = JSON.stringify(editInputvalueseries_trailer);
    var series_trailerdata = dataseries;

    // const editInputvalueppvstatus = ppvstatus.ppvstatus;
    // var data = JSON.stringify(editInputvalueppvstatus);
    // var ppvstatusdata = data;

    const formData = new FormData();

    formData.append("title", editUser?.title);
    formData.append("genre_id", 1);
    formData.append("mp4_url", mp4_url);
    formData.append("slug", autoFillValuetitle);
    formData.append("type", type);
    formData.append("access", storeseriesuseraccess);
    formData.append("details", details);
    formData.append("description", description);

    const aactivedata2 = Number.isNaN(parseInt(aactivedata, 10)) ? 0 : parseInt(aactivedata, 10);
    formData.append("active", aactivedata2 ? parseInt(aactivedata2, 10) : 0);

    const enableppv_status = Number.isNaN(parseInt(inputValue, 10)) ? 0 : parseInt(inputValue, 10);
    formData.append("ppv_status", enableppv_status);

    // formData.append("ppv_status", ppv_status ? parseInt(ppv_status, 10) : 0);
    formData.append("status", status ? parseInt(status, 10) : 1);
    formData.append("draft", status ? parseInt(status, 10) : 1);
    formData.append("featured", featureddata ? parseInt(featureddata, 10) : 0);
    formData.append("duration", editUser?.duration);
    formData.append("views", viewsdata ? parseInt(viewsdata, 10) : 0);
    formData.append("rating", rating ? parseInt(rating, 10) : 0);
    formData.append("search_tag", searchTags);
    formData.append("ppv_price", inputValue);
    formData.append("ios_ppv_price", ios_ppv_price?.value);

    formData.append(
      "artists",
      JSON.stringify(selectedOptionsartists?.map((option) => option?.value))
    );
    formData.append(
      "block_country_id",
      JSON.stringify(selectedOptions?.map((option) => option?.value))
    );
    formData.append(
      "genre_id",
      JSON.stringify(selectedOptionscategory?.map((option) => option?.value))
    );
    formData.append(
      "languages_id",
      JSON.stringify(selectedOptionslanguage?.map((option) => option?.value))
    );
    formData.append(
      "available_countries_id",
      JSON.stringify(
        selectedOptionsAvailable_country?.map((option) => option?.value)
      )
    );
    formData.append("embed_code", embed_code);
    formData.append("mp4_url", mp4_url);
    formData.append("webm_url", webm_url);
    formData.append("ogg_url", ogg_url);
    formData.append("year", inputValueyear);
    formData.append("trailer", trailer);
    formData.append("banner", bannerdata ? parseInt(bannerdata, 10) : 0);
    formData.append("series_trailer", series_trailerdata ? parseInt(series_trailerdata, 10) : 0);
    formData.append("season_trailer", season_trailerdata ? parseInt(season_trailerdata, 10) : 0);
    formData.append("uploaded_by", uploaded_by);
    formData.append("url", url);
    formData.append("deleted_at", deleted_at);
    formData.append("Thumbnail", Thumbnail);
    formData.append("Player_thumbnail", Player_thumbnail);
    formData.append("TV_Thumbnail", TV_Thumbnail);

    formData.append("ads_position", selectedAdsPosition?.value);
    formData.append("live_ads", secondSelectOptions?.value);

    let firstApiStatus = false;
    let secondApiStatus = false;
    let seventhApiStatus = false;

    let resultapi = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/series/store`,
        formData,
        { headers: headers }
      );

      if (response.data.status) {
        firstApiStatus = true;

        resultapi = response.data;
        var series_id = response.data.Series_id;
        setSeries_id(series_id);

        const imageFormData = new FormData();
        imageFormData.append("series_id", series_id);
        if (selectedFile1) {
          imageFormData.append("image", selectedFile1);
        }
        if (selectedFile2) {
          imageFormData.append("player_image", selectedFile2);
        }
        if (selectedFile3) {
          imageFormData.append("tv_image", selectedFile3);
        }

        const imageResponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/series/Image_upload`,
          imageFormData,
          { headers: headers }
        );

        const formDataseo = new FormData();
        formDataseo.append("series_id", series_id);
        formDataseo.append("website_page_title", websitetitle);
        formDataseo.append("website_URL", websiteurl);
        formDataseo.append("Meta_description", metadescription);

        const formDataseoresponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/series/SEO`,
          formDataseo,
          { headers: headers }
        );

        if (imageResponse.data.status == true) {
          secondApiStatus = true;
        }
        if (formDataseoresponse.data.status == true) {
          seventhApiStatus = true;
        }
      }
      else {
        toast.error(response?.data?.message);
        setShowOverlay(false);
      }
    } catch (error) { }

    if (firstApiStatus && secondApiStatus && seventhApiStatus) {
      setValidationMessagetitle("");
      setValidationMessageslug("");
      toast.success(resultapi?.message);
      setTimeout(async () => {
        await navigate(`/channel/edit-series/${series_id}`);
      }, 3000);
    } else {
      if (resultapi == false) {
        var resultError = resultapi;
        toast.error(resultError?.message);
        setIsValidationHiddentitle(false);
        setValidationMessageslug(false);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  }

  useEffect(async () => {
    const fetchAdsPositions = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/series/create`,
          {
            headers: headers,
          }
        );

        if (response.ok) {
          const data = await response?.json();
          // console.log(data);

          const adsPositionOptions = data?.ads_position?.map((position) => ({
            value: position?.position,
            label: position?.name,
          }));
          // console.log(adsPositionOptions);
          setAdsPositions(adsPositionOptions);
        } else {
          throw new Error("Request failed with status: " + response?.status);
        }
      } catch (error) {
        console.log("Error fetching ads positions:", error);
      }
    };
    fetchAdsPositions();
  }, []);

  useEffect(() => {
    if (selectedAdsPosition !== "") {
      // console.log(selectedAdsPosition, "adsssssssssss");
      const formData = new FormData();
      formData.append("position", selectedAdsPosition?.value);

      axios

        .post(
          `${process.env.REACT_APP_Baseurl}/Channel/advertisement-tag-url`,
          formData,
          { headers: headers }
        )

        .then((response) => {
          const data = response.data;
          // console.log(data, "exceptioned");
          const adsNames = data?.Advertisement?.map((position) => ({
            value: position?.id,
            label: position?.ads_name,
          }));

          setSecondSelectOptions(adsNames);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [selectedAdsPosition]);

  const [enable_restreampublic, setEnable_restreampublic] = useState("");
  const [enablestreamvisiblepublic, setEnablestreamvisiblepublic] =
    useState("");

  function proceednextpublic() {
    switch (enable_restreampublic) {
      case "publish_now":
        return <div></div>;
      case "publish_later":
        return (
          <div className="mb-3">
            <MessageLabel text="Publish Time" />
            <input
              type="datetime-local"
              className="rs-input theme-bg-color theme-text-color mb-3 form-control-lg"
              id="publish_time"
              name="publish_time"
              onChange={(e) => setPublish_time(e.target.value)}
            />
          </div>
        );
      default:
        return null;
    }
  }
  const [allseriesuseraccess, setallseriesuseraccess] = useState("");
  const [storeseriesuseraccess, setStoreseriesuseraccess] = useState("");
  function allseriesuseruseraccess() {
    switch (allseriesuseraccess) {
      case "guest":
        return <div></div>;
      case "subscriber":
        return <div></div>;
      case "registered":
        return <div></div>;
      case "ppv":
        return (
          <div className="">
            <div className="row m-0">
              <MessageLabel text="PPV Price" className="ps-0" />
              <div className="col-8 ps-0">
                <MessageSubHeading text="Apply PPV Price from Global Settings?" />
              </div>
              <div className="col-4  text-end">
                <label className="switch">
                  <input
                    name="ppv_price"
                    defaultChecked={checkboxChecked}
                    onChange={handleCheckboxChange}
                    type="checkbox"
                    className="rs-input"
                  />
                  <span
                    className="slider round"
                    name="ppv_price"
                    checked={checkboxChecked}
                    onChange={handleCheckboxChange}
                  ></span>
                </label>
              </div>
            </div>
            <input
              type="number"
              className="rs-input form-control-lg theme-bg-color-secondary  custom-placeholder theme-text-color border-0 mb-3"
              placeholder="PPV Price"
              name="ppv_price"
              id="price"
              value={inputValue}
              onChange={handleInputChange}
            />
            <MessageLabel text="IOS PPV Price" />
            <MessageSubHeading text="Apply IOS PPV Price from Global Settings?" />
            <div className="panel-body ppv_price_ios  mt-2">
              <select
                onChange={(e) => setIos_ppv_price(e.target.value)}
                className="form-select border-0 theme-bg-color-secondary theme-text-color mb-3"
                id="ios_ppv_price"
                name="ios_ppv_price"
              >
                <option value="" className="theme-text-color theme-bg-color">Choose a PPV Price</option>
                {inapppurchase?.map((item, key) => (
                  <option value={item?.id} className="theme-text-color theme-bg-color">{item?.plan_price}</option>
                ))}
              </select>
            </div>
          </div>
        );
      default:
        return null;
    }
  }
  return (
    <>
      {showOverlay && <ResponseLoader />}
      <AdminSaveHeader heading="Add New Series" saveText="Save Series" saveMethod={series} />
      <FormContainer>
        <LeftWrapper>
          <Wrapper>
            <MessageHeading text="Create Series" />
            <MessageLabel text="Title" className='mandatorySimple' />
            <MessageSubHeading text="Add the series title in the textbox below" />
            <input
              type="text"
              id="title"
              name="title"
              className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder theme-text-color border-0 mb-3"
              onChange={handleInput}
              value={editUser?.title}
              onBlur={handleBlurtitle}
              onKeyDown={handleInputKeyDowntitle}
              placeholder="Title"
              ref={titleInputRef}
            />
            {!isValidationHiddentitle && (<MessageBox text={`${validationMessagetitle}`} classname='errorred mb-2 d-block' />)}
            <MessageLabel text="Slug" />
            <MessageSubHeading text="Add a URL Slug" />
            <input
              type="text"
              id="slug"
              name="slug"
              className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder  theme-text-color  border-0 mb-3"
              value={autoFillValuetitle}
              onChange={handleAutoFillChangetitle}
              placeholder="slug"
            />
            {!isValidationHiddenslug && (<MessageBox text={`${validationMessageslug}`} classname='errorred mb-2 d-block' />)}
            <MessageLabel text="Short Description" />
            <MessageSubHeading text="Add a short description of the series below" />
            <textarea
              className="rs-input  short-description theme-bg-color-secondary custom-placeholder theme-text-color border-0  mb-3"
              id="description"
              name="description"
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Description"
            />
            <MessageLabel text="Series Details, Links, and Info" />
            <MessageSubHeading text="Add a short description of the series below" />
            <JoditEditor
              className="rs-input theme-bg-color-secondary theme-text-color border-0 mb-3"
              value={details}
              onChange={(newContent) =>
                contentFieldChanaged(newContent)
              }
            />
            <MessageLabel text="Duration" />
            <MessageSubHeading text="Enter The Series Duration In (HH : MM : SS)" />
            <input
              type="text"
              name="duration"
              maxLength="8"
              className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder theme-text-color border-0 mb-3"
              onChange={handleInputdata}
              value={editUser?.duration}
            // placeholder="duration"
            />
            <MessageLabel text="Year" />
            <MessageSubHeading text="Enter The Series Year" />
            <input
              type="number"
              name="number"
              value={inputValueyear}
              onChange={handleChangeyear}
              className="rs-input form-control-lg theme-text-color  theme-bg-color-secondary custom-placeholder border-0 mb-3"
              placeholder="Year"
            />
          </Wrapper>
          <Wrapper>
            <MessageHeading text="Organize" />
            <MessageLabel text="Series Ratings" />
            <MessageSubHeading text="IMDb Ratings 10 out of 10" />
            <select
              className="form-select rounded-2 w-100 theme-bg-color-secondary custom-placeholder theme-text-color border-0 mb-3"
              id="rating"
              name="rating"
              size="lg"
              onChange={(e) => setRating(e.target.value)}
            >
              <option value="1" className="theme-bg-color theme-text-color">1</option>
              <option value="2" className="theme-bg-color theme-text-color">2</option>
              <option value="3" className="theme-bg-color theme-text-color">3</option>
              <option value="4" className="theme-bg-color theme-text-color">4</option>
              <option value="5" className="theme-bg-color theme-text-color">5</option>
              <option value="6" className="theme-bg-color theme-text-color">6</option>
              <option value="7" className="theme-bg-color theme-text-color">7</option>
              <option value="8" className="theme-bg-color theme-text-color">8</option>
              <option value="9" className="theme-bg-color theme-text-color">9</option>
              <option value="10" className="theme-bg-color theme-text-color">10</option>
            </select>
            <MessageLabel text="Cast and Crew" />
            <MessageSubHeading text="Select A Series Artist Below" />
            <Select
              options={artists?.map((option) => ({
                value: option?.id,
                label: option?.artist_name,
              }))}
              className="theme-bg-color-secondary theme-text-color custom-placeholder mb-3"
              isMulti
              value={selectedOptionsartists}
              onChange={handleSelectChangeartists}
            />
            <label className="mt-2 d-block mandatorySimple position-relative theme-text-color admin-input-title">
              Category
            </label>
            <MessageSubHeading text="Select A Series Category Below" />
            <Select
              options={category?.map((option) => ({
                value: option?.id,
                label: option?.name,
              }))}
              className="theme-bg-color-secondary custom-placeholder theme-text-color mb-3"
              isMulti
              value={selectedOptionscategory}
              onChange={handleSelectChangecategory}
              ref={categoryInputRef}
            />
            {!isValidationHiddencategory && (<MessageBox text={`${validationMessagecategory}`} classname='errorred mb-2 d-block' />)}
            <label className="mt-2 d-block mandatorySimple position-relative theme-text-color admin-input-title">
              Language
            </label>
            <MessageSubHeading text="Select A Series Language Below" />
            <Select
              options={language?.map((option) => ({
                value: option?.id,
                label: option?.name,
              }))}
              className="theme-bg-color-secondary  theme-text-color custom-placeholder mb-3"
              isMulti
              value={selectedOptionslanguage}
              onChange={handleSelectChangelanguage}
              ref={languageInputRef}
            />
            {!isValidationHiddenlanguage && (<MessageBox text={`${validationMessagelanguage}`} classname='errorred mb-2 d-block' />)}
            <MessageLabel text="Block Country" />
            <MessageSubHeading text="Select A Series country Below" />
            <Select
              options={block_country?.map((option) => ({
                value: option?.id,
                label: option?.name,
              }))}
              className="theme-bg-color-secondary  custom-placeholder  theme-text-color"
              isMulti
              value={selectedOptions}
              onChange={handleSelectChange}
            />
            <MessageLabel text="Available Country" />
            <MessageSubHeading text="Block the Audio for Selected Country" />
            <Select
              options={available_country?.map((option) => ({
                value: option?.id,
                label: option?.country_name,
              }))}
              className="theme-bg-color-secondary custom-placeholder theme-text-color mb-3"
              isMulti
              value={selectedOptionsAvailable_country}
              onChange={handleSelectChangeAvailable_country}
            />
          </Wrapper>
          <Wrapper>
            <MessageHeading text="Search Tags" />
            <MessageLabel text="You don't have any search keywords." />
            <TagsInput
              value={searchTags}
              className="rs-input form-control-lg theme-bg-color-secondary theme-text-color border-0"
              onChange={handleTagsChange}
              name="search_tags"
            />
          </Wrapper>
        </LeftWrapper>
        <RightWrapper>
          <Wrapper>
            <MessageHeading text="Thumbnails" />
            <MessageLabel text="Series Image Cover" />
            <MessageSubHeading text="Select the Series image( 16:9 Ratio or 1280X720px )" />
            <div className="mx-0 row mb-3">
              <div className="col-6  ps-0">
                <div className="imagedrop theme-border-color" onClick={() => fileInputRef1.current.click()} >
                  <input
                    type="file"
                    ref={fileInputRef1}
                    onChange={(event) =>
                      handleFileChange(
                        event,
                        fileInputRef1,
                        setSelectedFile1
                      )
                    }
                    accept="image/*"
                    style={{ display: "none" }}
                  />
                  <label className="theme-text-color">
                    <CameraRetroIcon className="theme-text-color" />
                  </label>
                </div>
              </div>
              <div className="col-6 ">
                <div>
                  {selectedFile1 && (
                    <div className="imagedropcopy theme-border-color text-end">
                      <div className="mx-0 row ">
                        <div className=" col-sm-10 col-lg-10 p-0 ">
                          <img
                            src={URL.createObjectURL(selectedFile1)}
                            alt="Uploaded"
                            className="thumbnails-upload-images"
                          />
                        </div>
                        <div
                          className="col-sm-2 col-lg-2 p-0 m-0"
                          style={{ alignSelf: "center" }}
                        >
                          <button
                            onClick={() =>
                              handleDelete(setSelectedFile1)
                            }
                            type="button"
                            className=" theme-text-color bg-transparent "
                          > <IoClose className="custom-close-btn" /></button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <MessageLabel text="Player Image Cover" />
            <MessageSubHeading text="Select the Series image( 16:9 Ratio or 1280X720px )" />
            <div className="mx-0 mb-3 row">
              <div className="col-6  ps-0">
                <div
                  className="imagedrop theme-border-color"
                  onClick={() => fileInputRef2.current.click()}
                >
                  <input
                    type="file"
                    ref={fileInputRef2}
                    onChange={(event) =>
                      handleFileChange(
                        event,
                        fileInputRef2,
                        setSelectedFile2
                      )
                    }
                    accept="image/*"
                    style={{ display: "none" }}
                  />
                  <label className=" theme-text-color">
                    <CameraRetroIcon className=" theme-text-color" />
                  </label>
                </div>
              </div>
              <div className="col-6 ">
                <div>
                  {selectedFile2 && (
                    <div className="imagedropcopy theme-border-color text-end">
                      <div className="row m-0">
                        <div className=" col-sm-10 col-lg-10 p-0 ">
                          <img
                            src={URL.createObjectURL(selectedFile2)}
                            alt="Uploaded"
                            className="thumbnails-upload-images"
                          />
                        </div>
                        <div
                          className="col-sm-2 col-lg-2 p-0 m-0"
                          style={{ alignSelf: "center" }}
                        >
                          <button
                            onClick={() =>
                              handleDelete(setSelectedFile2)
                            }
                            type="button"
                            className=" theme-text-color bg-transparent "
                          >   <IoClose className="custom-close-btn" /></button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <MessageLabel text="TV Image Cover" />
            <MessageSubHeading text="Select the Series image( 16:9 Ratio or 1280X720px )" />
            <div className="mx-0 row">
              <div className="col-6  ps-0">
                <div
                  className="imagedrop theme-border-color"
                  onClick={() => fileInputRef3.current.click()}
                >
                  <input
                    type="file"
                    ref={fileInputRef3}
                    onChange={(event) =>
                      handleFileChange(
                        event,
                        fileInputRef3,
                        setSelectedFile3
                      )
                    }
                    accept="image/*"
                    style={{ display: "none" }}
                  />
                  <label className=" theme-text-color">
                    <CameraRetroIcon className=" theme-text-color" />
                  </label>
                </div>
              </div>
              <div className="col-6 ">
                <div>
                  {selectedFile3 && (
                    <div className="imagedropcopy theme-border-color text-end">
                      <div className="row m-0">
                        <div className="col-10 p-0">
                          <img
                            src={URL.createObjectURL(selectedFile3)}
                            alt="Uploaded"
                            className="thumbnails-upload-images"
                          />
                        </div>
                        <div
                          className="col-2 p-0 "
                          style={{ alignSelf: "center" }}
                        >
                          <button
                            onClick={() =>
                              handleDelete(setSelectedFile3)
                            }
                            type="button"
                            className=" theme-text-color bg-transparent "
                          >  <IoClose className="custom-close-btn" /></button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Wrapper>
          <Wrapper>
            <MessageHeading text="Access" />
            <label className="mt-2 theme-text-color  mandatorySimple admin-input-title">
              User Access
            </label>
            <MessageSubHeading text="Who Is Allowed To View This Series ?" />
            <select
              onChange={(e) => setStoreseriesuseraccess(e.target.value)}
              className="form-select  theme-bg-color-secondary theme-text-color border-0 mb-3"
              id="access"
              name="access"
              onClick={(event) => { setallseriesuseraccess(event.target.value); }}

              ref={accessInputRef}
            >
              {user_access?.map((item, key) => (
                <option value={item?.role}>{item?.name}</option>
              ))}
            </select>

            <div>{allseriesuseruseraccess()}</div>
            <span className="errorred mt-2 d-block">
              {!isValidationHiddenAccess && (
                <p>{validationMessageAccess}</p>
              )}
            </span>
          </Wrapper>
          <Wrapper>
            <MessageHeading text="Status Settings" />
            <div className="row mx-0">
              <div className="col-9 ps-0  ">
                <MessageLabel text="Is this series Featured" />
              </div>
              <div className="col-3 text-end">
                <label className="switch">
                  <input
                    name="featured"
                    onChange={handleInput}
                    className="rs-input"
                    defaultChecked={
                      featured?.featured == 1 ? true : false
                    }
                    checked={featured?.featured == 1 ? true : false}
                    type="checkbox"
                  />
                  <span
                    className="slider round"
                    name="featured"
                    onChange={handleInput}
                    value={featured?.featured == 1 ? "1" : "0"}
                  ></span>
                </label>
              </div>
            </div>
            <div className="row mx-0">
              <div className="col-9 ps-0 ">
                <MessageLabel text="Is this series Active" />
              </div>
              <div className="col-3 text-end">
                <label className="switch">
                  <input
                    name="active"
                    onChange={handleInput}
                    defaultChecked={active?.active == 1 ? true : false}
                    checked={active?.active == 1 ? true : false}
                    className="rs-input"
                    type="checkbox"
                  />
                  <span
                    className="slider round"
                    name="active"
                    onChange={handleInput}
                    value={active?.active == 1 ? "1" : "0"}
                  ></span>
                </label>
              </div>
            </div>
            <div className="row mx-0">
              <div className="col-9 ps-0 ">
                <MessageLabel text="Enable this series as Slider" />
              </div>
              <div className="col-3 text-end">
                <label className="switch">
                  <input
                    name="banner"
                    onChange={handleInput}
                    className="r-input"
                    defaultChecked={banner?.banner == 1 ? true : false}
                    checked={banner?.banner == 1 ? true : false}
                    type="checkbox"
                  />
                  <span
                    className="slider round"
                    name="banner"
                    onChange={handleInput}
                    value={banner?.banner == 1 ? "1" : "0"}
                  ></span>
                </label>
              </div>
            </div>
            <div className="row mx-0">
              <div className="col-9 ps-0 ">
                <MessageLabel text="Season Trailer" />
              </div>
              <div className="col-3 text-end">
                <label className="switch">
                  <input
                    name="series_trailer"
                    id="series_trailer"
                    onChange={handleInput}
                    className="r-input"
                    defaultChecked={
                      series_trailer?.series_trailer == 1 ? true : false
                    }
                    checked={
                      series_trailer?.series_trailer == 1 ? true : false
                    }
                    type="checkbox"
                  />
                  <span
                    className="slider round"
                    name="series_trailer"
                    id="series_trailer"
                    onChange={handleInput}
                    value={
                      series_trailer?.series_trailer == 1 ? "1" : "0"
                    }
                  ></span>
                </label>
              </div>
            </div>
          </Wrapper>
          <Wrapper>
            <MessageHeading text="SEO" />
            <MessageLabel text="Website Page Title" />
            <div className="mt-2">
              <input
                type="text"
                className="rs-input form-control-lg theme-bg-color-secondary theme-text-color border-0 mb-3"
                onChange={(e) => setWebsitetitle(e.target.value)}
                placeholder="websitetitle"
              />
            </div>
            <MessageLabel text="Website URL" />
            <div className=" mt-2">
              <input
                type="text"
                className="rs-input form-control-lg theme-bg-color-secondary theme-text-color border-0 mb-3"
                onChange={(e) => setWebsiteurl(e.target.value)}
                placeholder="websitetitle"
              />
            </div>
            <MessageLabel text="Meta Description" />
            <div className=" mt-2">
              <textarea
                type="text"
                className="rs-input form-control-lg theme-bg-color-secondary theme-text-color border-0"
                onChange={(e) => setMetadescription(e.target.value)}
                placeholder="metadescription"
              ></textarea>
            </div>
          </Wrapper>
        </RightWrapper>
        <AdminCustomButton saveMethod={series} saveText="Save Series" className="ms-auto" />
      </FormContainer >
    </>
  );
}
export default AddNewSeries;