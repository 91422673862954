import React, { useEffect, useRef, useState } from "react";
import videojs from 'video.js';
import { useNavigate, useParams } from "react-router-dom";
import "video.js/dist/video-js.css";
import "./videoPlayer.css";
import "videojs-contrib-quality-levels";
import { BiArrowBack } from "react-icons/bi";
import { FaPlay, FaPause } from 'react-icons/fa';
import { GrForwardTen, GrBackTen } from "react-icons/gr";
import { useTranslation } from "react-i18next";
import { AiOutlineClose } from "react-icons/ai";
import { toast } from "react-toastify";

export const TrailerJS = ({ videoDetailsData, options, onReady, settings }) => {
  const user_role = localStorage.getItem("role");
  const user_id = localStorage.getItem("user_id");
  const playerType = videoDetailsData?.type;
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const { t } = useTranslation();
  const { lang } = useParams();
  // useEffect(() => {
  //   if (!playerRef.current) {
  //     const videoElement = document.createElement("video-js");
  //     videoElement.classList.add("vjs-big-play-centered");
  //     videoElement.classList.add("vjs-theme-city");
  //     videoElement.classList.add("my-video-dimensions");
  //     videoRef.current.appendChild(videoElement);
  //     const player = (playerRef.current = videojs(videoElement, options, () => {
  //       player.loadingSpinner.removeClass("vjs-loading-spinner");
  //       player.loadingSpinner.addClass("vjs-loading-spinner-myvideo");
  //       onReady && onReady(player);
  //       player.on('play', () => setIsPlaying(true));
  //       player.on('pause', () => setIsPlaying(false));
  //       // Add event listener for userinactive event
  //       player.on('userinactive', () => {
  //         const playPauseButton = document.querySelector('.custom-play-pause-button');
  //         if (playPauseButton) {
  //           playPauseButton.style.display = 'none'; // Hide the custom play/pause button
  //         }
  //       });
  //       // Add event listener for useractive event
  //       player.on('useractive', () => {
  //         const playPauseButton = document.querySelector('.custom-play-pause-button');
  //         if (playPauseButton) {
  //           playPauseButton.style.display = 'block'; // Show the custom play/pause button
  //         }
  //       });
  //       // Add event listener for userinactive event
  //       player.on('userinactive', () => {
  //         // Hide the skip forward and backward buttons when the user becomes inactive
  //         const skipForwardButton = document.querySelector('.custom-skip-forward-button');
  //         const skipBackwardButton = document.querySelector('.custom-skip-backward-button');
  //         if (skipForwardButton && skipBackwardButton) {
  //           skipForwardButton.style.display = 'none';
  //           skipBackwardButton.style.display = 'none';
  //         }
  //       });

  //       // Add event listener for useractive event
  //       player.on('useractive', () => {
  //         // Show the skip forward and backward buttons when the user becomes active
  //         const skipForwardButton = document.querySelector('.custom-skip-forward-button');
  //         const skipBackwardButton = document.querySelector('.custom-skip-backward-button');
  //         if (skipForwardButton && skipBackwardButton) {
  //           skipForwardButton.style.display = 'block';
  //           skipBackwardButton.style.display = 'block';
  //         }
  //       });


  //     }));

  //     player.src(options.sources);

  //   } else {
  //     const player = playerRef.current;
  //     player.autoplay(options.autoplay);
  //     player.src(options.sources);

  //   }
  // }, [options, videoRef]);

  useEffect(() => {
    if (!playerRef.current) {
      // Create video element and initialize player
      const videoElement = document.createElement("video");
      videoElement.classList.add("video-js", "vjs-big-play-centered", "vjs-theme-city", "my-video-dimensions");
      videoRef.current.appendChild(videoElement);

      const player = (playerRef.current = videojs(videoElement, options, () => {
        player.loadingSpinner.removeClass("vjs-loading-spinner");
        player.loadingSpinner.addClass("vjs-loading-spinner-myvideo");

        onReady && onReady(player);

        player.on('play', () => setIsPlaying(true));
        player.on('pause', () => setIsPlaying(false));

        // Event listeners for hiding/showing custom buttons
        player.on('userinactive', () => {
          const playPauseButton = document.querySelector('.custom-play-pause-button');
          const skipForwardButton = document.querySelector('.custom-skip-forward-button');
          const skipBackwardButton = document.querySelector('.custom-skip-backward-button');

          if (playPauseButton) {
            playPauseButton.style.display = 'none'; // Hide the custom play/pause button
          }

          if (skipForwardButton && skipBackwardButton) {
            skipForwardButton.style.display = 'none'; // Hide skip buttons
            skipBackwardButton.style.display = 'none';
          }
        });

        player.on('useractive', () => {
          const playPauseButton = document.querySelector('.custom-play-pause-button');
          const skipForwardButton = document.querySelector('.custom-skip-forward-button');
          const skipBackwardButton = document.querySelector('.custom-skip-backward-button');

          if (playPauseButton) {
            playPauseButton.style.display = 'block'; // Show the custom play/pause button
          }

          if (skipForwardButton && skipBackwardButton) {
            skipForwardButton.style.display = 'block'; // Show skip buttons
            skipBackwardButton.style.display = 'block';
          }
        });
         // Prevent default context menu on video element
         videoElement.addEventListener('contextmenu', (event) => {
          event.preventDefault();
        });


      }));
      

      player.src(options.sources);

    } else {
      const player = playerRef.current;
      player.autoplay(options.autoplay);
      player.src(options.sources);
    }
  }, [options]);



  React.useEffect(() => {
    const player = playerRef.current;
    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  const navigate = useNavigate();

  // const backButton = () => {
  //   const { access_free } = settings;
  //   const isGuest = !localStorage.getItem("role");
  //   const { access, slug } = videoDetailsData || {};
  //   (isGuest && access === "guest" && access_free === 0) ? navigate(`/`) : navigate(`/${lang}/videos/${slug}`);
  // };
  const backButton = () => {
    const { access_free } = settings;
    const isGuest = !localStorage.getItem("role");
    const { access, slug } = videoDetailsData || {};
    if (window.history.state && window.history.state.idx > 0) {
      window.history.back();
    } else {
      if (isGuest && access === "guest" && access_free === 0) {
        navigate('/');
      } else {
        navigate(`/${lang}/videos/${slug}`);
      }
    }
  };

  const togglePlayPause = () => {
    const player = playerRef.current;
    if (player) {
      if (player.paused()) {
        setTimeout(() => {
          player.play().catch((error) => {
            //alert("Video is loading...please wait")
           // toast.error('The download was unable to complete');
            console.error('Error playing video:', error);
          });
        }, 100); // 100ms delay
      } else {
        player.pause();
      }
    }
  };

  // const togglePlayPause = () => {
  //   const player = playerRef.current;
  //   if (player.paused()) {
  //     player.play();
  //   } else {
  //     player.pause();
  //   }
  // };
  const [isPlaying, setIsPlaying] = useState(false);
  const skipForward = (duration) => {
    const player = playerRef.current;
    player.currentTime(player.currentTime() + duration);
  };
  const skipBackward = (duration) => {
    const player = playerRef.current;
    player.currentTime(player.currentTime() - duration);
  };

  return (
    <>
      <div data-vjs-player className="position-relative" style={{ "overflow": "hidden" }}>
        <button className="backbutton bg-transparent pt-3"><AiOutlineClose style={{ "fontSize": "35px", "fontWeight": "bold", "fill": "white" }} onClick={backButton} /></button>
        <button className="titlebutton bg-transparent text-white pt-3" onClick={backButton}>{t(videoDetailsData?.title)}</button>
        <button className="custom-play-pause-button bg-transparent" onClick={togglePlayPause}>
          {isPlaying ? <FaPause style={{ fontSize: "40px", fill: "white" }} /> : <FaPlay style={{ fontSize: "40px", fill: 'white' }} />}
        </button>
        <button className="custom-skip-forward-button bg-transparent" onClick={() => skipForward(10)}>
          <GrForwardTen style={{ fontSize: "45px", color: 'white' }} />
        </button>
        <button className="custom-skip-backward-button bg-transparent" onClick={() => skipBackward(10)}>
          <GrBackTen style={{ fontSize: "45px", color: 'white' }} />
        </button>
        <div ref={videoRef} />
      </div>
    </>
  );
};

export default TrailerJS;
