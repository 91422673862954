import React from 'react'
import AccountLayout from '../components/AccountSection/AccountLayout'
import { Route, Routes } from 'react-router-dom'
import Error404 from '../Error404/Error404'
import AccountPage from '../components/AccountSection/AccountPage'
import AccountInfo from '../components/AccountSection/AccountInfo'
import AccountPlan from '../components/AccountSection/AccountPlan'
import AccountDevice from '../components/AccountSection/AccountDevice'
import AccountProfiles from '../components/AccountSection/AccountProfiles'
import AccountBilling from '../components/AccountSection/AccountBilling'
import AccountParental from '../components/AccountSection/AccountParental'
import EditMultiprofile from '../components/Multiprofile/EditMultiprofile'

const ProfileAllRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<AccountLayout />}>
                <Route path="/info" element={<AccountInfo />} />
                <Route path="/activeplan" element={<AccountPlan />} />
                <Route path="/activedevice" element={<AccountDevice />} />
                <Route path="/profiles" element={<AccountProfiles />} />
                <Route path="/multi-profile/edit/:id" element={<EditMultiprofile />} />
                <Route path="/multi-profile/create" element={<EditMultiprofile />} />
                <Route path="/billing" element={<AccountBilling />} />
                <Route path="/parentalcontrol" element={<AccountParental />} />
                <Route path="*" element={<Error404 />} />
            </Route>
        </Routes>
    )
}

export default ProfileAllRoutes