// External Libraries and Packages
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify"
// Internal Styles
// Internal Components and Images
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import ResponseLoader from "../../../components/Others/ResponseLoader";
import AdminSaveHeader from "../../../components/Others/adminCommonComponents/AdminSaveHeader";
import FormContainer from "../../CommonComponents/FormContainer";
import LeftWrapper from "../../CommonComponents/LeftWrapper";
import RightWrapper from "../../CommonComponents/RightWrapper";
import Wrapper from "../../CommonComponents/Wrapper";
import MessageHeading from "../../CommonComponents/MessageHeading";
import MessageLabel from "../../CommonComponents/MessageLabel";
import { IoClose } from "react-icons/io5";
import MessageBox from "../../../components/Others/MessageBox";

const AddManageCategories = () => {
  const access_token = localStorage.getItem("access_token_channel");
  const navigate = useNavigate();
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const [showOverlay, setShowOverlay] = useState(false);
  const [coverImageFile, setCoverImageFile] = useState(null)
  const [editUserError, setEditUserError] = useState({});
  const [in_menu, setIn_menu] = useState(false);
  const [parent_id, setParent_id] = useState([]);
  const [category, setCategory] = useState([]);
  const [editUser, setEditUser] = useState({
    name: "",
    slug: "",
  });
  const coverImageRef = useRef(null);
  const slugInputRef = useRef(null)
  const titleInputRef = useRef(null);

  //Handle Image upload
  const handleCoverImageChange = (event) => {
    const selectedFile = event.target.files;
    if (selectedFile.length > 0) {
      const newFile = selectedFile[0];
      const reader = new FileReader();
      reader.onload = () => {
        setCoverImageFile(newFile);
      };
      reader.readAsDataURL(newFile);
    } else {
    }
  };

  //Handle  Display Checkbox
  const checkHandler = () => {
    setIn_menu(!in_menu);
  };

  //Hanle input values
  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));
    setEditUserError((prevEditUser) => ({
      ...prevEditUser,
      [name]: '',
    }));
    setEditUserError((prevEditUser) => ({
      ...prevEditUser,
      slug: '',
    }));

  };

  // Handle Auto slug generate function *start*
  const handleBlurtitle = () => {
    generateSlug(editUser?.name);
  };

  const handleBlurTitleEdit = () => {
    generateSlug(editUser?.slug);
  }

  const generateSlug = (title) => {
    title = title?.trim();
    const slug = title?.toLowerCase().replace(/\s+/g, "-");
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      slug: slug,
    }));
  };

  const handleAutoFillChangetitle = (event) => {
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      slug: event.target.value,
    }));
    setEditUserError((prevEditUser) => ({
      ...prevEditUser,
      [event.target.name]: '',
    }));
  };

  const handleInputKeyDowntitle = (event) => {
    if (event.key == "Enter" || event.key == "Tab") {
      generateSlug(editUser?.name);
    }
  };

  //Handle submit form 
  const handleSave = () => {
    if (formValidation()) {
      console.log("data true go to api")
      addCategoryData()
    }
    else {
      console.log('Form validation failed ');
    }
  }

  const formValidation = () => {
    let formIsValid = true;
    if (!editUser?.name) {
      setEditUserError((prevEditUser) => ({ ...prevEditUser, name: 'Title cannot be empty.' }));
      titleInputRef.current.focus();
      formIsValid = false;
    }
    else if (!editUser?.slug) {
      setEditUserError((prevEditUser) => ({ ...prevEditUser, slug: 'Slug cannot be empty.' }));
      slugInputRef.current.focus();
      formIsValid = false;
    }
    return formIsValid;
  }

  const addCategoryData = async () => {
    setShowOverlay(true)
    const formData = new FormData();
    const imageFormData = new FormData()
    try {
      for (const key in editUser) {
        formData.append(key, editUser[key]);
      }
      formData.append("parent_id", parent_id == true ? 1 : 0);
      formData.append("in_menu", in_menu == true ? 1 : 0);
      const response = await axios.post(`${process.env.REACT_APP_Baseurl}/Channel/Backend/live-stream-category/store`, formData, { headers: headers });
      let dataResponse = response.data;
      let resStatusId = response.data.LiveCategory.id

      imageFormData.append('id', resStatusId)
      imageFormData.append('image', coverImageFile)
      let imageResponse;
      if (dataResponse.status) {
        try {
          imageResponse = await axios.post(`${process.env.REACT_APP_Baseurl}/Channel/Backend/live-stream-category/image`, imageFormData, { headers: headers });
          if (imageResponse.data.status) {
            setShowOverlay(false)
            toast.success(dataResponse.message)
            setTimeout(async () => {
              await navigate("/channel/manage-live-category");
            }, 3000);
          }
        }
        catch (err) {
          console.log(err)
          setShowOverlay(false)
        }
      }
    }
    catch (err) {
      setShowOverlay(false)
      const errResponse = err.response.data.message
      toast.error(errResponse)
    }
  }

  //get data category
  const getData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_Baseurl}/Channel/Backend/live-stream-category/create`, { headers })
      setCategory(response?.data?.live_categories);
    }
    catch (err) {
      console.error(err)
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {showOverlay && (<ResponseLoader />)}
      <AdminSaveHeader heading="Add live stream category" saveText="Save category" saveMethod={handleSave} />
      <FormContainer>
        <LeftWrapper>
          <Wrapper>
            <MessageHeading text="Create Category" />
            <MessageLabel text="Name" className="mandatorySimple" />
            <input
              type="text"
              className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder theme-text-color border-0 mb-3"
              id="name"
              name="name"
              placeholder="Enter Name"
              onChange={handleInput}
              value={editUser?.name}
              onBlur={handleBlurtitle}
              onKeyDown={handleInputKeyDowntitle}
              ref={titleInputRef}
            />
            {editUserError?.name && (<MessageBox text={`${editUserError?.name}`} classname='errorred mb-2 d-block' />)}
            <MessageLabel text="Slug" />
            <input
              type="text"
              className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder theme-text-color border-0 mb-3"
              id="slug"
              name="slug"
              placeholder="Enter Slug"
              value={editUser?.slug}
              ref={slugInputRef}
              onBlur={handleBlurTitleEdit}
              onChange={handleAutoFillChangetitle}
            />
            {editUserError?.slug && (<MessageBox text={`${editUserError?.slug}`} classname='errorred mb-2 d-block' />)}
            <MessageLabel text="Category" />
            <select
              className="form-select theme-bg-color-secondary custom-placeholder theme-text-color border-0"
              name="parent_id"
              id="parent_id"
              onChange={(e) => setParent_id(e.target.value)}
            >
              <option value="0" className="theme-bg-color theme-text-color">Select</option>
              {category?.map((item) => (
                <option value={item?.parent_id} className="theme-bg-color theme-text-color">
                  {item?.name}
                </option>
              ))}
            </select>
          </Wrapper>
        </LeftWrapper>
        <RightWrapper>
          <Wrapper>
            <MessageHeading text="Thumbnails" />
            <MessageLabel text="Image" />
            <div className="mx-0 my-2 row">
              <div className="col-6 ps-0">
                <div className="imagedrop position-relative theme-border-color" >
                  <input
                    type="file"
                    ref={coverImageRef}
                    onChange={(event) => handleCoverImageChange(event)}
                    accept="image/*"
                    className="opacity-0"
                    key={coverImageFile ? coverImageFile : "inputKey"} />
                  <button type="button" className="bg-transparent position-absolute">
                    <CameraRetroIcon />
                  </button>
                </div>
              </div>
              <div className="col-6 pe-0">
                {coverImageFile && (
                  <div className="imagedropcopy theme-border-color text-end">
                    <div className="d-flex align-items-center">
                      <img
                        src={URL.createObjectURL(coverImageFile)}
                        alt="Uploaded"
                        className="thumbnails-upload-images"
                      />
                      <button onClick={() => setCoverImageFile(null)} type="button" className="bg-transparent theme-text-color"><IoClose className="custom-close-btn" /></button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Wrapper>
          <Wrapper>
            <MessageHeading text="Status Settings" />
            <div className="mx-0 mb-2 row">
              <div className="col-9 ps-0">
                <MessageLabel text="Display in home page" />
              </div>
              <div className="col-3 pe-0 text-end">
                <label className="switch">
                  <input
                    name="active"
                    onChange={checkHandler}
                    className="rs-input"
                    checked={in_menu}
                    type="checkbox"
                  />
                  <span
                    className="slider round"
                    name="active"
                    onChange={checkHandler}
                  ></span>
                </label>
              </div>
            </div>
          </Wrapper>
        </RightWrapper >
      </FormContainer >
    </>
  );
};
export default AddManageCategories;