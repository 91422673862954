import { useState } from "react";
import { Navigate, useParams } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem("access_token"));
  const { lang } = useParams()
  if (!isLoggedIn) {
    return <Navigate to={`/${lang}/login`} />;
  }

  return children;
};

export default ProtectedRoute