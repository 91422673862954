import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import "./Success_Register_Payments.css";
import { Link } from "react-router-dom";
import sunnxt from "../../components/Images/Payment_image/sunnxt.png";
import zee5 from "../../components/Images/Payment_image/zee5.png";
import sonylive from "../../components/Images/Payment_image/sonylive.png";
import fuseplus from "../../components/Images/Payment_image/fuseplus.png";
import amazonprime from "../../components/Images/Payment_image/amazonprime.png";
import { AiOutlineCalendar } from "react-icons/ai";
import { Button, ButtonToolbar } from "rsuite";

function Success_Register_Payments() {
  // const Channel_Partner_id = localStorage.getItem("Channel_Partner_id");

  // const access_token = localStorage.getItem("access_token");
  // const headers = {
  //   "Content-Type": "application/json",
  //   Authorization: "Bearer " + access_token,
  //   "Content-Type": "application/json",
  //   Accept: "application/json",
  //   "Access-Control-Allow-Origin": "*",
  // };

  // const [stripeids, setStripeids] = useState([]);
  // const [paymentGateways, setPaymentGateways] = useState([]);

  // useEffect(() => {
  //   fetchOptions();
  //   fetchPaymentGateways();
  // }, []);

  // const fetchOptions = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_Baseurl}/Front-End/Stripe-Subscription-Plans`,
  //       { headers: headers }
  //     );
  //     const StripeSubscriptionPlans = response?.data?.Stripe_Subscription_Plans;
  //     setStripeids(StripeSubscriptionPlans);

  //     // console.log(StripeSubscriptionPlans);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // const [activeGateway, setActiveGateway] = useState(null); // Initialize activeGateway as null

  // const fetchPaymentGateways = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_Baseurl}/Front-End/Payment-Gateway-list`,
  //       { headers: headers }
  //     );
  //     const paymentGateways = response?.data?.Payment_Gateways;
  //     setPaymentGateways(paymentGateways);
  //     // Set the active gateway to the first one by default
  //     setActiveGateway(paymentGateways[0]?.Payment_Gateway_name || null);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // const handleGatewayClick = (gatewayName) => {
  //   setActiveGateway(gatewayName);
  // };
  // // const fetchOptionstwo = async () => {
  // //   try {
  // //     const response = await axios.get(
  // //       `${process.env.REACT_APP_Baseurl}/Front-End/Payment-Gateway-list`,
  // //       { headers: headers }
  // //     );
  // //     const PaymentGateways = response?.data?.Payment_Gateways;
  // //     setPaymentGateways(PaymentGateways);

  // //   } catch (error) {
  // //     console.error(error);
  // //   }
  // // };

  // const stripeid = [
  //   {
  //     id: 1,
  //     src: amazonprime,
  //     price: 93,
  //     title: "Basic Plan",
  //   },
  //   {
  //     id: 2,
  //     src: fuseplus,
  //     price: 355,
  //     title: "Pro Plan",
  //   },
  //   {
  //     id: 3,
  //     src: sunnxt,
  //     price: 213,
  //     title: "Bussiness Plan",
  //   },
  //   {
  //     id: 4,
  //     src: sonylive,
  //     price: 990,
  //     title: "Super Plan",
  //   },
  //   {
  //     id: 5,
  //     src: zee5,
  //     price: 5000,
  //     title: "Premium Plan",
  //   },
  // ];

  // // const checkout = (plan) => {
  // //   fetch(`${process.env.REACT_APP_Baseurl}/userstripe/payment/api`, {
  // //     method: "POST",
  // //     headers: {
  // //       "Content-Type": "application/json",
  // //     },
  // //     body: JSON.stringify({ plan: plan, stripeid: Channel_Partner_id }),
  // //   })
  // //     .then((response) => {
  // //       if (response.ok) return response.json();
  // //       console.log(response);
  // //       return response.json().then((json) => Promise.reject(json));
  // //     })
  // //     .then(({ session }) => {
  // //       window.location = session.url;
  // //     })
  // //     .catch((e) => {
  // //       console.log(e.error);
  // //     });
  // // };

  // // const checkout = (plan_id) => {
  // //   fetch(`${process.env.REACT_APP_Baseurl}/userstripe/payment/api`, {
  // //     method: "POST",
  // //     headers: {
  // //       "Content-Type": "application/json",
  // //     },
  // //     body: JSON.stringify({ plan_id: plan_id, user_id: user_id }),
  // //   })
  // //     .then((response) => {
  // //       if (response?.ok) return response?.json();
  // //       console.log(response);
  // //       return response?.json().then((json) => Promise.reject(json));
  // //     })
  // //     .then(({ session }) => {
  // //       if (session?.status == true) {
  // //         // Send data to another API
  // //         fetch(`${process.env.REACT_APP_Baseurl}/another/api`, {
  // //           method: "POST",
  // //           headers: {
  // //             "Content-Type": "application/json",
  // //           },
  // //           body: JSON.stringify({ id: session?.id }), // Change this as needed
  // //         })
  // //           .then((response) => {
  // //             if (response?.ok) return response?.json();
  // //             console.log(response);
  // //             return response?.json().then((json) => Promise.reject(json));
  // //           })
  // //           .then((data) => {
  // //             // Handle the response from the second API call
  // //             console.log("Data from another API:", data);
  // //           })
  // //           .catch((e) => {
  // //             console.log("Error from another API:", e.error);
  // //           });

  // //         window.location = session.url;
  // //       }
  // //     })
  // //     .catch((e) => {
  // //       console.log(e.error);
  // //     });
  // // };

  // // const checkout = (user_id, plan_id) => {
  // //   // Construct the data to send to your API
  // //   const requestData = {
  // //     user_id,
  // //     plan_id,
  // //     quantity: 1,
  // //     // Include other data from the stripeids array that you need to send
  // //     // For example, if you want to send the price from the stripeids array, you can do:
  // //     // price: stripeids.find(tab => tab.plan_id == plan_id)?.price,
  // //   };

  // //   fetch(`${process.env.REACT_APP_Baseurl}/Front-End/Stripe-Payment-Link`, {
  // //     method: "POST",
  // //     headers: {
  // //       "Content-Type": "application/json",
  // //     },
  // //     body: JSON.stringify(requestData),
  // //   })
  // //     .then((response) => {
  // //       if (response.ok) return response.json();
  // //       // console.log(response);
  // //       return response.json().then((json) => Promise.reject(json));
  // //     })
  // //     .then(({ session }) => {
  // //       if (session.status == true) {
  // //         // Send data to another API
  // //         fetch(`${process.env.REACT_APP_Baseurl}/another/api`, {
  // //           method: "POST",
  // //           headers: {
  // //             "Content-Type": "application/json",
  // //           },
  // //           body: JSON.stringify({ id: session.id }),
  // //         })
  // //           .then((response) => {
  // //             if (response.ok) return response.json();
  // //             console.log(response);
  // //             return response.json().then((json) => Promise.reject(json));
  // //           })
  // //           .then((data) => {
  // //             // Handle the response from the second API call
  // //             console.log("Data from another API:", data);
  // //           })
  // //           .catch((e) => {
  // //             console.log("Error from another API:", e.error);
  // //           });

  // //         window.location = session.url;
  // //       }
  // //     })
  // //     .catch((e) => {
  // //       console.log(e.error);
  // //     });
  // // };

  // const checkout = (user_id, plan_id) => {
  //   const requestData = {
  //     user_id,
  //     plan_id,
  //     quantity: 1,
  //   };

  //   fetch(`${process.env.REACT_APP_Baseurl}/Front-End/Stripe-Payment-Link`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify(requestData),
  //   })
  //     .then((response) => {
  //       if (response.ok) return response.json();
  //       return response.json().then((json) => Promise.reject(json));
  //     })
  //     .then((response) => {
  //       if (response?.status == true) {
  //         const paymentLinkURL = response?.Payment_Link_URL;
  //         // console.log(paymentLinkURL , "paymentLinkURL")

  //         // Open a new tab or window with the payment link URL
  //         const newTab = window.open(paymentLinkURL, "_self");
  //         if (newTab) {
  //           newTab.focus(); // Focus on the new tab if it was successfully opened
  //         } else {
  //           // Handle cases where the browser blocked the popup
  //           console.error("Popup blocked by the browser");
  //           // You can provide a message to the user or take other actions here
  //         }
  //       } else {
  //         console.error("Payment session status is not true");
  //       }
  //     })
  //     .catch((e) => {
  //       console.error(e.error);
  //     });
  // };

  // const staticContent = {
  //   Stripe: (
  //     <>
  //       <div>
  //         <ul className="nav nav-pills mb-3 justify-content-center payment_stripe_css">
  //           {stripeids?.map((tab, index) => (
  //             <li
  //               className="nav-item align-items-center ms-2 payment_with_css"
  //               role="presentation"
  //               key={index}
  //             >
  //               <div className="row col-lg-12">
  //                 <div className="col-7 col-sm-7 col-md-7 col-lg-7 power_play_header_css mt-4">
  //                   <label className="power_play" htmlFor={tab?.id + "-tab"}>
  //                     {tab?.plans_name}
  //                   </label>
  //                   <p
  //                     className="power_play_OTTs mt-2"
  //                     htmlFor={tab?.id + "-tab"}
  //                     dangerouslySetInnerHTML={{ __html: tab?.plan_content }}
  //                     style={{ maxWidth: "100%" }}
  //                   ></p>
  //                   {/* <p
  //                     className="power_play_OTTs mt-2"
  //                     htmlFor={tab.id + "-tab"}
  //                     dangerouslySetInnerHTML={{ __html: tab.content.plan_content }} 
  //                   >
  //                     {tab.plan_content}
  //                   </p> */}
  //                   <p className="power_play_price mt-4">
  //                     $ {tab?.price} per yr
  //                   </p>
  //                   <p className="power_play_price_save m-0">
  //                     Save up to <span>$ {tab?.price}</span>
  //                   </p>
  //                 </div>
  //                 <div className="col-5 col-sm-5 col-md-5 col-lg-5">
  //                   <div className="row col-lg-12">
  //                     <div className="col-6 col-sm-6 col-md-6 col-lg-6">
  //                       <div className="free_video_plans_image">
  //                         <img
  //                           src={zee5}
  //                           className="w-100 h-100"
  //                           alt={tab?.plans_name}
  //                         />
  //                       </div>
  //                     </div>
  //                     <div className="col-6 col-sm-6 col-md-6 col-lg-6">
  //                       <div className="free_video_plans_image">
  //                         <img
  //                           src={sunnxt}
  //                           className="w-100 h-100"
  //                           alt={tab?.plans_name}
  //                         />
  //                       </div>
  //                     </div>
  //                     <div className="col-6 col-sm-6 col-md-6 col-lg-6">
  //                       <div className="free_video_plans_image">
  //                         <img
  //                           src={fuseplus}
  //                           className="w-100 h-100"
  //                           alt={tab?.plans_name}
  //                         />
  //                       </div>
  //                     </div>
  //                     <div className="col-6 col-sm-6 col-md-6 col-lg-6">
  //                       <div className="free_video_plans_image">
  //                         <img
  //                           src={amazonprime}
  //                           className="w-100 h-100"
  //                           alt={tab?.plans_name}
  //                         />
  //                       </div>
  //                     </div>
  //                     <div className="col-6 col-sm-6 col-md-6 col-lg-6">
  //                       <div className="free_video_plans_image">
  //                         <img
  //                           src={sonylive}
  //                           className="w-100 h-100"
  //                           alt={tab?.plans_name}
  //                         />
  //                       </div>
  //                     </div>
  //                     {/* Add more image elements for other images */}
  //                   </div>
  //                 </div>
  //               </div>

  //               <div className="row justify-content-evenly free_video_plans_button">
  //                 <div className="col-5 col-sm-5 col-md-5 col-lg-5"></div>
  //                 <div className="col-5 col-sm-5 col-md-5 col-lg-5">
  //                   <div className="">
  //                     <button
  //                       className="btn btn-primary"
  //                       onClick={() => checkout(tab?.user_id, tab?.plan_id)}
  //                     >
  //                       Start
  //                     </button>
  //                   </div>
  //                 </div>
  //               </div>
  //             </li>
  //           ))}
  //         </ul>

  //         {/* <ul
  //           className="nav nav-pills mb-3 justify-content-center payment_stripe_css"
  //           id="pills-tab"
  //           role="tablist"
  //         >
  //           {stripeids?.map((tab, index) => (
  //             <li
  //               className="nav-item align-items-center ms-2 payment_with_css"
  //               role="presentation"
  //               key={index}
  //             >
  //               <div className="row col-lg-12">
  //                 <div className="col-7 col-sm-7 col-md-7 col-lg-7 power_play_header_css mt-4">
  //                   <label className="power_play" htmlFor={tab?.id + "-tab"}>
  //                     {tab?.plans_name}
  //                   </label>

  //                   <p
  //                     className="power_play_OTTs mt-2"
  //                     htmlFor={tab?.id + "-tab"}
  //                   >
  //                     {tab?.plans_name}
  //                   </p>
  //                   <p className="power_play_price mt-4">
  //                     $ {tab?.price} per yr
  //                   </p>
  //                   <p className="power_play_price_save m-0">
  //                     Save upto <span>$ {tab?.price}</span>
  //                   </p>
  //                 </div>
  //                 <div className="col-5 col-sm-5 col-md-5 col-lg-5">
  //                   <div className="row col-lg-12">
  //                     <div className="col-6 col-sm-6 col-md-6 col-lg-6">
  //                       <div className="free_video_plans_image">
  //                         <img src={tab?.src} className="w-100" />
  //                       </div>
  //                     </div>
  //                     <div className="col-6 col-sm-6 col-md-6 col-lg-6">
  //                       <div className="free_video_plans_image">
  //                         <img src={tab?.src} className="w-100" />
  //                       </div>
  //                     </div>
  //                     <div className="col-6 col-sm-6 col-md-6 col-lg-6">
  //                       <div className="free_video_plans_image">
  //                         <img src={tab?.src} className="w-100" />
  //                       </div>
  //                     </div>
  //                     <div className="col-6 col-sm-6 col-md-6 col-lg-6">
  //                       <div className="free_video_plans_image">
  //                         <img src={tab?.src} className="w-100" />
  //                       </div>
  //                     </div>
  //                     <div className="col-6 col-sm-6 col-md-6 col-lg-6">
  //                       <div className="free_video_plans_image">
  //                         <img src={tab?.src} className="w-100" />
  //                       </div>
  //                     </div>
  //                     <div className="col-6 col-sm-6 col-md-6 col-lg-6">
  //                       <div className="free_video_plans_image">
  //                         <img src={tab?.src} className="w-100" />
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>

  //               <div className="row justify-content-evenly free_video_plans_button">
  //                 <div className="col-5 col-sm-5 col-md-5 col-lg-5"></div>
  //                 <div className="col-5 col-sm-5 col-md-5 col-lg-5">
  //                   <div className="">
  //                     <button
  //                       className="btn btn-primary"
  //                       onClick={() => checkout(Number(tab?.price))}
  //                     >
  //                       Start
  //                     </button>
  //                   </div>
  //                 </div>
  //               </div>
  //             </li>
  //           ))}
  //         </ul> */}

  //         {/* {stripeid?.map((item, index) => (
  //   <div className="col-lg-12 row" key={index}>
  //     <div className="col-lg-12">
  //       <label>{item?.title}</label>
  //     </div>
  //     <div className="col-lg-4">
  //       <button onClick={() => checkout(Number(item?.price))}>
  //         {item?.price}
  //       </button>
  //     </div>
  //   </div>
  // ))} */}
  //       </div>
  //     </>
  //   ),
  //   PayPal: (
  //     <>
  //       <div>
  //         <ul
  //           className="nav nav-pills mb-3 justify-content-center payment_stripe_css"
  //           id="pills-tab"
  //           role="tablist"
  //         >
  //           {stripeid?.map((tab, index) => (
  //             <li
  //               className="nav-item align-items-center ms-2 payment_with_css"
  //               role="presentation"
  //               key={index}
  //             >
  //               <div className="row col-lg-12">
  //                 <div className="col-7 col-sm-7 col-md-7 col-lg-7 power_play_header_css mt-4">
  //                   <label className="power_play" htmlFor={tab?.id + "-tab"}>
  //                     {tab?.title}
  //                   </label>

  //                   <p
  //                     className="power_play_OTTs mt-2"
  //                     htmlFor={tab?.id + "-tab"}
  //                   >
  //                     {tab?.title}
  //                   </p>
  //                   <p className="power_play_price mt-4">
  //                     $ {tab?.price} per yr
  //                   </p>
  //                   <p className="power_play_price_save m-0">
  //                     Save upto <span>$ {tab?.price}</span>
  //                   </p>
  //                 </div>
  //                 <div className="col-5 col-sm-5 col-md-5 col-lg-5">
  //                   <div className="row col-lg-12">
  //                     <div className="col-6 col-sm-6 col-md-6 col-lg-6">
  //                       <div className="free_video_plans_image">
  //                         <img src={tab?.src} className="w-100" />
  //                       </div>
  //                     </div>
  //                     <div className="col-6 col-sm-6 col-md-6 col-lg-6">
  //                       <div className="free_video_plans_image">
  //                         <img src={tab?.src} className="w-100" />
  //                       </div>
  //                     </div>
  //                     <div className="col-6 col-sm-6 col-md-6 col-lg-6">
  //                       <div className="free_video_plans_image">
  //                         <img src={tab?.src} className="w-100" />
  //                       </div>
  //                     </div>
  //                     <div className="col-6 col-sm-6 col-md-6 col-lg-6">
  //                       <div className="free_video_plans_image">
  //                         <img src={tab?.src} className="w-100" />
  //                       </div>
  //                     </div>
  //                     <div className="col-6 col-sm-6 col-md-6 col-lg-6">
  //                       <div className="free_video_plans_image">
  //                         <img src={tab?.src} className="w-100" />
  //                       </div>
  //                     </div>
  //                     <div className="col-6 col-sm-6 col-md-6 col-lg-6">
  //                       <div className="free_video_plans_image">
  //                         <img src={tab?.src} className="w-100" />
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>

  //               <div className="row justify-content-evenly free_video_plans_button">
  //                 <div className="col-5 col-sm-5 col-md-5 col-lg-5"></div>
  //                 <div className="col-5 col-sm-5 col-md-5 col-lg-5">
  //                   <div className="">
  //                     <button
  //                       className="btn btn-primary"
  //                       onClick={() => checkout(Number(tab?.price))}
  //                     >
  //                       Start
  //                     </button>
  //                   </div>
  //                 </div>
  //               </div>

  //               {/* <div className="text-center">
  //                   <img src={tab?.src} className="w-50" />
  //                 </div> */}
  //               {/* <div className="text-center mt-2">
  //                 <label htmlFor={tab?.id + "-tab"}>{tab?.title}</label>
  //               </div> */}

  //               {/* <div className="text-center mt-2">
  //                 <button
  //                   className="btn btn-primary"
  //                   onClick={() => checkout(Number(tab?.price))}
  //                 >
  //                   Price {tab?.price}
  //                 </button>
  //               </div> */}
  //             </li>
  //           ))}
  //         </ul>

  //         {/* {stripeid?.map((item, index) => (
  //   <div className="col-lg-12 row" key={index}>
  //     <div className="col-lg-12">
  //       <label>{item?.title}</label>
  //     </div>
  //     <div className="col-lg-4">
  //       <button onClick={() => checkout(Number(item?.price))}>
  //         {item?.price}
  //       </button>
  //     </div>
  //   </div>
  // ))} */}
  //       </div>
  //     </>
  //   ),
  //   Razorpay: (
  //     <>
  //       <div>Razorpay welcomes</div>
  //     </>
  //   ),
  // };

  // // const staticContent = {
  // //   Stripe: (
  // //     <>
  // //       <div>
  // //         {stripeid?.map((item, index) => (
  // //           <div className="col-lg-12 row" key={index}>
  // //             <label>{item?.title}</label>
  // //             <div className="col-lg-4">
  // //               <button onClick={() => checkout(Number(item?.price))}>
  // //                 {item?.price}
  // //               </button>
  // //             </div>
  // //           </div>
  // //         ))}
  // //       </div>
  // //     </>
  // //   ),
  // //   COD: "Static content for COD goes here.",
  // //   Paypal: "Static content for Paypal goes here.",
  // // };

  return (
    <section>
      <div>Payment Successfully Paid</div>
    </section>
    // <section className="register_Payment">
    //   <div className="container register_Payment">
    //     <div className="row paymenttababoveheading">
    //       <div className="col-8">
    //         <Link>
    //           <p className="mt-3">Home - Subcription Plan </p>
    //         </Link>
    //       </div>
    //       <div className="col-8">
    //         <h2>
    //           Introducing bundled subscription plans at special introductory
    //           prices
    //         </h2>
    //       </div>
    //     </div>
    //     <div className="row">
    //       <div className="col-6 col-md-6 col-lg-6 offset-md-4">
    //         <ul className="nav nav-pills p-3" id="pills-tab" role="tablist">
    //           {paymentGateways?.map((gateway, index) => (
    //             <li
    //               className="nav-item d-flex align-items-center ms-2 paymenttabheading"
    //               role="presentation"
    //               key={index}
    //             >
    //               <input
    //                 type="radio"
    //                 className="nav-link"
    //                 id={`pills-${gateway?.Payment_Gateway_name}-tab`}
    //                 name="tab-radio"
    //                 value={gateway?.Payment_Gateway_name}
    //                 checked={activeGateway == gateway?.Payment_Gateway_name}
    //                 onChange={() =>
    //                   handleGatewayClick(gateway?.Payment_Gateway_name)
    //                 }
    //               />
    //               <label
    //                 className="ms-2"
    //                 htmlFor={`pills-${gateway?.Payment_Gateway_name}-tab`}
    //               >
    //                 {gateway?.Payment_Gateway_name}
    //               </label>
    //             </li>
    //           ))}
    //         </ul>
    //       </div>
    //     </div>

    //     <div className="row">
    //       <div className="col-12 col-md-12 col-lg-12 offset-md-0">
    //         <div className="tab-content text-start" id="pills-tabContent">
    //           {paymentGateways?.map((gateway, index) => (
    //             <div
    //               className={`tab-pane ${
    //                 activeGateway == gateway?.Payment_Gateway_name
    //                   ? "show active"
    //                   : "fade"
    //               }`}
    //               id={`pills-${gateway?.Payment_Gateway_name}`}
    //               role="tabpanel"
    //               key={index}
    //             >
    //               <p>{staticContent[gateway?.Payment_Gateway_name]}</p>
    //             </div>
    //           ))}
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </section>

    // <section className="container">
    //   <div className="row">
    //     <div className="col-6 col-md-6 col-lg-6 offset-md-4">
    //       <ul className="nav nav-pills p-3 " id="pills-tab" role="tablist">
    //         {Listoftabheading?.map((tab, index) => (
    //           <li
    //             className="nav-item d-flex align-items-center ms-2"
    //             role="presentation"
    //             key={index}
    //           >
    //             <input
    //               type="radio"
    //               className="nav-link"
    //               id={tab?.id + "-tab"}
    //               name="tab-radio"
    //               value={tab?.id}
    //               checked={activeTab == tab.id}
    //               onChange={() => handleTabClick(tab?.id)}
    //             />
    //             <label className="ms-2" htmlFor={tab?.id + "-tab"}>
    //               {tab?.label}
    //             </label>
    //           </li>
    //         ))}
    //       </ul>
    //     </div>
    //   </div>

    //   <div className="row">
    //     <div className="col-12 col-md-12 col-lg-12 offset-md-0">
    //       <div className="tab-content text-start " id="pills-tabContent">
    //         {Listoftabheading?.map((tab, index) => (
    //           <div
    //             className={`tab-pane ${
    //               activeTab == tab.id ? "show active" : "fade"
    //             } `}
    //             id={tab?.id}
    //             role="tabpanel"
    //             key={index}
    //           >
    //             <p>{staticContent[tab?.label]}</p>
    //           </div>
    //         ))}
    //       </div>
    //     </div>
    //   </div>

    //   {/* <div>
    //     <div className="tab-content text-start" id="pills-tabContent">
    //       {Listoftabheading?.map((tab, index) => (
    //         <div
    //           className={`tab-pane ${
    //             activeTab == tab.id ? "show active" : "fade"
    //           } `}
    //           id={tab?.id}
    //           role="tabpanel"
    //           key={index}
    //         >
    //           <p>{staticContent[tab?.label]}</p>
    //         </div>
    //       ))}
    //     </div>
    //   </div> */}
    // </section>
  );
}

export default Success_Register_Payments;
