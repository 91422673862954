import React from 'react'
import LazyLoadImage from '../../HomePage/HomeSwiperComponents/LazyLoadImage'
import { connect } from 'react-redux';
import MessageBox from '../../../components/Others/MessageBox';
import { Link } from 'react-router-dom';
import PlayButton from './PlayButton';

const MusicArtistCard = ({ store, languageDetailsData, playlistData, currentPlaying }) => {
    return (
        <div className='p-2 rounded-2 w-100 h-100 theme-border-secondary position-relative '>
            <LazyLoadImage key={store?.artist_name} src={store?.image_url} alt={store?.artist_name} title={store?.artist_name} imageSize="rounded-2  overflow-hidden musicImage object-fit-cover" classNameOptional="rounded-2 musicImageHeight" />
            <Link to={`/${languageDetailsData?.Language_Code}/music/artist/${store?.artist_slug}`}><MessageBox classname='playCardTitle py-2 theme-text-color' text={store?.artist_name} /></Link>
            <MessageBox classname='playCardDescription theme-text-color opacity-75' text={store?.description} />
            <PlayButton type="ARTIST" store={store} />
        </div>
    )
}
const mapStateToProps = state => ({
    currentToggle: state.get_current_Reducers.currentToggle,
    languageDetailsData: state.get_getUserTranslateLanguage_Reducer.languageDetailsData,
    playlistData: state.get_current_Reducers.playlistData,
    currentPlaying: state.get_current_Reducers.currentPlaying,
})

export default connect(mapStateToProps)(MusicArtistCard)