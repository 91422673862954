import React from 'react';
import Image1 from '../Images/comments/Ellipse1.png';
import Image2 from '../Images/comments/Ellipse2.png';
import Image3 from '../Images/comments/Ellipse3.png';
import Image4 from '../Images/comments/Ellipse4.png';

const Comments = () => {

  const commentData = [
    {
      id: "1",
      name: "Marcus Curtis",
      comments: "Lorem ipsum dolor sit amet consectetur. Eget massa risus eu at vestibulum. Sem morbi aliquam dictumst lacus enim enim. Integer quam amet aliquet est pretium ultrices in proin. Urna hendrerit in ipsum lacus dui feugiat in ultrices adipiscing. Lorem ipsum dolor sit amet consectetur. Eget massa risus eu at vestibulum. Sem morbi aliquam dictumst lacus enim enim. Integer quam amet aliquet est pretium ultrices in proin. Urna hendrerit in ipsum lacus dui feugiat in ultrices adipiscing.",
      avatar: Image1
    },
    {
      id: "2",
      name: "Tiana Rhiel Madsen",
      comments: "Lorem ipsum dolor sit amet consectetur. Eget massa risus eu at vestibulum. Sem morbi aliquam dictumst lacus enim enim. Integer quam amet aliquet est pretium ultrices",
      avatar: Image2
    }, {
      id: "3",
      name: "Livia Gouse",
      comments: "Lorem ipsum dolor sit amet consectetur. Eget massa risus eu at vestibulum. Sem morbi aliquam dictumst lacus enim enim. Integer quam amet aliquet est pretium ultrices in proin. Urna hendrerit in ipsum lacus dui feugiat in ultrices adipiscing. Lorem ipsum dolor sit amet consectetur.",
      avatar: Image3
    }, {
      id: "4",
      name: "Phillip Press",
      comments: "Lorem ipsum dolor sit amet consectetur. Eget massa risus eu at vestibulum. Sem morbi aliquam dictumst lacus enim enim. Integer quam amet aliquet est pretium ultrices in proin. Urna hendrerit in ipsum lacus dui feugiat in ultrices adipiscing. Lorem ipsum dolor sit amet consectetur.",
      avatar: Image4
    }
  ]
  return (
    <div className='m-2 pt-2'>
      <textarea className="theme-bg-color rs-input" name="comments" placeholder="Leave a comment here.." />
      <div className="comment-list">
        {commentData.map(comment => (
          <div key={comment.id} className="comment-item">
            <div className="comment-header">
              <img src={comment.avatar} alt={comment.name} className="comment-avatar" />
              <h4 className="comment-name">{comment.name}</h4>
            </div>
            <p className="comment-text">{comment.comments}</p>
          </div>
        ))}
      </div>
    </div>
  )
}
export default Comments