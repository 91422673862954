import React, { useState, useEffect } from 'react';
import HelmetDetails from '../Others/HelmetDetails';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { liveDetailsAction, videoDetailsAction } from '../../Redux/Actions/apiActions';
import { useTranslation } from 'react-i18next';
import { FaArrowLeft } from "react-icons/fa";
import { BiPlayCircle } from "react-icons/bi";
import LazyLoadImage from '../../Pages/HomePage/HomeSwiperComponents/LazyLoadImage';
import axios from 'axios';
import { toast } from "react-toastify";
import usePpvGlobalImageConfig from "../Api/Rentppv_global_change";

function LivePurchaseModal({ liveDetailsData, settings, profileDetails }) {
    const { lang, slug } = useParams();
    const user_role = localStorage.getItem("role");
    const user_id = localStorage.getItem("user_id");
    const username = localStorage.getItem("username");
    const access_token = localStorage.getItem("access_token");
    const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
        Accept: "application/json",
    };
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [domReload, setDomReload] = useState(false)
    const currentPageURL = window.location.href;
    const location = useLocation();
    const [expanded, setExpanded] = useState(false);
    const { t } = useTranslation();
    useEffect(() => {
        if (settings && Object.keys(settings).length > 0) {
            !user_role && settings.access_free == 1
                ? dispatch(liveDetailsAction(null, 9, "web", slug, headers))
                : user_role && dispatch(liveDetailsAction(user_id, user_role, "web", slug, headers));
        }
    }, [settings, user_role, domReload, location])

    //console.log(liveDetailsData, "video")
    const closeButton = () => {
        navigate(`/${lang}/live/${slug}`);
    }







    //   geo ip location integration 
    //   geo ip location integration 
    const { currency, currencySymbol, userCurrency, locationResponselocationResponse } = usePpvGlobalImageConfig();
    const [convertedPrice, setConvertedPrice] = useState(null);


    useEffect(() => {

        const fetchConversionRate = async () => {
            if (!userCurrency || !currency?.code || currency?.enable_multi_currency !== 1) return;

            try {
                const response = await axios.get(`${process.env.REACT_APP_API_Curreny_generate}/${currency?.code}`);
                const data = response.data;
                const conversionRate = data.rates[userCurrency];
                if (conversionRate) {
                    const convertedPPVPrice = liveDetailsData?.ppv_price * conversionRate;
                    setConvertedPrice(convertedPPVPrice);
                } else {
                    console.error('Conversion rate not found for currency:', userCurrency);
                }
            } catch (error) {
                console.error('Error fetching conversion rate:', error);
            } finally {
                // setLoading(false);
            }
        };

        fetchConversionRate();
    }, [userCurrency, currency?.code, currency?.enable_multi_currency, liveDetailsData?.ppv_price]);

    //   geo ip location integration 
    //   geo ip location integration 

    //    Iyzico payment integration 
    //    Iyzico payment integration 




    //    Iyzico payment integration 
    //    Iyzico payment integration 
    const successpage = window.location.href;
    const ppv_priceppv_price = liveDetailsData?.ppv_price
    const [formDataiyzicopayment, setFormDataiyzicopayment] = useState({
        locale: '',
        conversationId: '',
        price: ppv_priceppv_price, // Initialize with an empty value
        paidPrice: ppv_priceppv_price, // Initialize with an empty value
        successpage: successpage, // Initialize with an empty value
        currency: '',
        basketId: '',
        paymentGroup: 'PRODUCT',
        callbackUrl: '',
        enabledInstallments: [2, 3, 6, 9],
        buyer: {
            id: '',
            name: '',
            surname: '',
            gsmNumber: '',
            email: '',
            identityNumber: '',
            lastLoginDate: '',
            registrationDate: '',
            registrationAddress: '',
            ip: '',
            city: '',
            country: '',
            zipCode: ''
        },
        shippingAddress: {
            contactName: 'Jane Doe',
            city: 'Istanbul',
            country: 'Turkey',
            address: 'Nidakule Göztepe, Merdivenköy Mah. Bora Sok. No:1',
            zipCode: '34742'
        },
        billingAddress: {
            contactName: 'Jane Doe',
            city: 'Istanbul',
            country: 'Turkey',
            address: 'Nidakule Göztepe, Merdivenköy Mah. Bora Sok. No:1',
            zipCode: '34742'
        },
        basketItems: [
            {
                id: 'BI101',
                name: 'Binocular',
                category1: 'Collectibles',
                category2: 'Accessories',
                itemType: 'PHYSICAL',
                price: ppv_priceppv_price,
            }
        ]
    });


    useEffect(() => {
        let value;
        if (currency?.code === null || currency?.code === "") {
            value = liveDetailsData?.ppv_price !== null ? liveDetailsData?.ppv_price : '';
        } else {
            if (settings?.PPV_Individual_Contents === "0" || settings?.PPV_Individual_Contents === 0) {
                value = convertedPrice !== null ? convertedPrice : '';
            } else {
                value = liveDetailsData?.ContentPriceRate !== null
                    ? liveDetailsData?.ContentPriceRate?.ChangedPrice
                    : convertedPrice !== null
                        ? convertedPrice
                        : '';
            }
        }

        const getCurrentDateTime = () => {
            const now = new Date();
            const year = now.getFullYear();
            const month = String(now.getMonth() + 1).padStart(2, '0');
            const day = String(now.getDate()).padStart(2, '0');
            const hours = String(now.getHours()).padStart(2, '0');
            const minutes = String(now.getMinutes()).padStart(2, '0');
            const seconds = String(now.getSeconds()).padStart(2, '0');
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        };

        const currentDateTime = getCurrentDateTime();
        const locale = lang === "tr" ? "tr" : "en";
        const conversationId = user_id;
        const currencyfinal = currency?.code;
        const basketId = profileDetails?.Profile_details?.id;
        const gsmNumber = "+" + profileDetails?.Profile_details?.payment_mobile_number;
        // const successpage = window.location.href;
        const failerpage = `${process.env.REACT_APP_API_PATHss}/` + lang + "/failerpage";
        const successpage = `${process.env.REACT_APP_API_PATHss}/` + lang + "/successpage";
        const pageredirectionslug = `${process.env.REACT_APP_API_PATHss}/` + lang + "/" + liveDetailsData?.slug;
        const callbackUrl = `${process.env.REACT_APP_API_IYZICO_PAYMENT}/callback`;
        const usernames = profileDetails?.Profile_details?.username;
        const last_name = profileDetails?.Profile_details?.last_name;
        const payment_mobile_number = "+" + profileDetails?.Profile_details?.payment_mobile_number;
        const user_ids = user_id;
        const emailemail = profileDetails?.Profile_details?.email;
        const city = locationResponselocationResponse?.city;
        const country = locationResponselocationResponse?.country;
        const zipCode = locationResponselocationResponse?.asn;
        const ip = locationResponselocationResponse?.ip;
        const basketItemsname = liveDetailsData?.title || liveDetailsData?.name;
        setFormDataiyzicopayment(prevState => ({
            ...prevState,
            locale: locale,
            price: ppv_priceppv_price,
            conversationId: conversationId,
            Login_user_id: user_id,
            Login_device_type: "web",
            source_type: 2,
            source_id: liveDetailsData?.id,
            regionname: country,
            countryname: country,
            cityname: city,
            successpage: successpage,
            pageredirectionslug: pageredirectionslug,
            failerpage: failerpage,
            paidPrice: ppv_priceppv_price,
            currency: currencyfinal,
            basketId: basketId,
            callbackUrl: callbackUrl,
            buyer: {
                id: user_ids,
                name: usernames,
                surname: last_name,
                gsmNumber: payment_mobile_number,
                email: emailemail,
                identityNumber: gsmNumber,
                lastLoginDate: currentDateTime,
                registrationDate: currentDateTime,
                registrationAddress: country,
                ip: ip,
                city: city,
                country: country,
                zipCode: zipCode
            },
            shippingAddress: {
                contactName: usernames,
                city: city,
                country: country,
                district: city,
                address: country,
                zipCode: zipCode
            },
            billingAddress: {
                contactName: usernames,
                city: city,
                country: country,
                address: country,
                zipCode: zipCode
            },
            basketItems: [
                {
                    id: user_ids,
                    name: basketItemsname,
                    category1: 'Videos',
                    itemType: 'PHYSICAL',
                    price: ppv_priceppv_price
                }
            ]
        }));



    }, [liveDetailsData, currency, settings, convertedPrice]);

    const initializeCheckout = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_IYZICO_PAYMENT}/initialize-checkout`, formDataiyzicopayment);
            if (response.data.status === "success") {
                const paymentPageUrl = response?.data?.paymentPageUrl;
                window.location.href = paymentPageUrl;
            }
            else if (response.data.status === "failure") {
                toast.error(`${response.data.errorMessage}`)
            }
        } catch (error) {
            console.error('Error initializing checkout:', error);
        }
    };


    // useEffect(() => {
    //     let showAlert = true;

    //     const retrievePaymentDetails = async () => {
    //         try {
    //             const response = await axios.get(`${process.env.REACT_APP_API_IYZICO_PAYMENT}/retrieve-payment-details`);

    //             if (response.data.status === 'success' && showAlert) {
    //                 showAlert = false;

    //                 const result = response.data;
    //                 const user_id = localStorage.getItem("user_id");
    //                 const liveDetailsDataslug = liveDetailsData?.slug;
    //                 const liveDetailsDataid = liveDetailsData?.id;

    //                 await axios.post('http://localhost:7001/payment/details', {
    //                     ...result,
    //                     user_id,
    //                     liveDetailsDataslug,
    //                     liveDetailsDataid,
    //                 });
    //             }
    //         } catch (error) {
    //             console.error('Error retrieving payment details:', error);
    //         }
    //     };

    //     retrievePaymentDetails();
    // }, []);

    //    Iyzico payment integration 
    //    Iyzico payment integration 



    return (
        <div className='paddingTopone'>
            <HelmetDetails metaURL={currentPageURL} metaTitle={liveDetailsData?.title ? liveDetailsData?.title?.toUpperCase() : liveDetailsData?.name?.toUpperCase()} metaDescription={liveDetailsData?.description?.toString().substring(0, 100)} metaImage={liveDetailsData?.Player_thumbnail} pageTitle={liveDetailsData?.title ? liveDetailsData?.title?.toUpperCase() : liveDetailsData?.name?.toUpperCase()} />
            <div style={{
                backgroundImage: `url(${liveDetailsData?.Player_thumbnail})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover', width: '100%', height: '100%',
            }} className='px-md-5 px-4 py-md-3 py-1 linearShadow'>
                <div className='position-relative'>
                    <div className='pageWrapper2'>

                        <div className="row w-100">

                            <div className="col-sm-4 col-lg-4 col-md-4 d-flex justify-content-around">
                                <div>
                                    <button className='bg-transparent' onClick={closeButton}><FaArrowLeft style={{ "fontSize": "25px" }} className='m-3' /></button>
                                </div>
                                <LazyLoadImage
                                    imageSize="loaderMain object-fit-cover" classNameOptional="rounded-3 w-100 livepurchase"
                                    loading="lazy"
                                    src={liveDetailsData?.Player_thumbnail} alt={liveDetailsData?.slug}
                                />

                            </div>
                            <div className="col-sm-8 col-lg-4 col-md-8">
                                <div>
                                    <h3 className="midHeadingText mb-2 text-break">{t(liveDetailsData?.title)} {t(liveDetailsData?.name)} </h3>
                                </div>
                                <div className="py-3">
                                    {/* <h5 className='midHeadingMessage'>
                    {t(liveDetailsData?.users_video_visibility_status_message)}</h5> */}
                                    <h5 className='midHeadingMessage'>
                                        {t(liveDetailsData?.users_free_duration_status_message)}</h5>
                                </div>
                                <button className="theme-button-bg-color px-4 py-3 rounded-2 d-inline-block" onClick={() => initializeCheckout()}>
                                    <span className='midHeadingbtn'><BiPlayCircle style={{ "fontSize": "24px" }} />
                                        {/* {t(`Purchase Now`)} */}
                                        {currency?.code === null || currency?.code === ""
                                            ? t(`Rent Now ${liveDetailsData?.ppv_price !== null ? liveDetailsData?.ppv_price : 'Loading...'}`)
                                            : (settings?.PPV_Individual_Contents === "0" || settings?.PPV_Individual_Contents === 0
                                                ? t(`Rent Now ${currencySymbol} ${convertedPrice !== null ? convertedPrice : 'Loading...'}`)
                                                : (liveDetailsData?.ContentPriceRate !== null
                                                    ? t(`Rent Now ${liveDetailsData?.ContentPriceRate?.Symbol} ${liveDetailsData?.ContentPriceRate?.ChangedPrice}`)
                                                    : t(`Rent Now ${currencySymbol} ${convertedPrice !== null ? convertedPrice : 'Loading...'}`)
                                                )
                                            )}
                                        {/* {convertedPrice && currency?.code === null || currency?.code === ""
                                                                                ? `Rent Now ${liveDetailsData?.ppv_price !== null ? liveDetailsData?.ppv_price : 'Loading...'}`
                                                                                : (settings?.PPV_Individual_Contents === "0" || settings?.PPV_Individual_Contents === 0
                                                                                    ? `Rent Now ${currencySymbol} ${convertedPrice !== null ? convertedPrice : 'Loading...'}`
                                                                                    : (liveDetailsData?.ContentPriceRate !== null
                                                                                        ? `Rent Now ${liveDetailsData?.ContentPriceRate?.Symbol} ${liveDetailsData?.ContentPriceRate?.ChangedPrice}`
                                                                                        : `Rent Now ${currencySymbol} ${convertedPrice !== null ? convertedPrice : 'Loading...'}`
                                                                                    )
                                                                                )} */}</span>

                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
const mapStateToProps = state => ({
    liveDetailsData: state.get_liveDetails_Reducer.liveDetailsData,
    isLoading: state.get_liveDetails_Reducer.isLoading,
    error: state.get_liveDetails_Reducer.error,
    settings: state.get_setting_Reducer.settings,
    profileDetails: state.get_profileDetails_Reducer.profileDetails,
});
export default connect(mapStateToProps)(LivePurchaseModal)