import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import "./AboutMyChannel.css";
import ResponseLoader from "../../../components/Others/ResponseLoader";

const AboutMyChannel = () => {
  const [order, setOrder] = useState({});
  const [editUser, setEditUser] = useState({
    id: "",
    channel_name: "",
    channel_slug: "",
    email: "",
    password: "",
    ccode: "",
    intro_video_url: "",
    mobile_number: "",
    channel_image: "",
    channel_banner: "",
    channel_about: "",
    channel_logo: "",
    intro_video: "",
    channel_banner_url: "",
    status: "",
    unhased_password: "",
    activation_code: "",
    bank_name: "",
    branch_name: "",
    account_number: "",
    IFSC_Code: "",
    cancelled_cheque: "",
    upi_id: "",
    channel_logo_url: "",
    unhased_password: "",
    upi_mobile_number: "",
    channel_image_url: "",
    cancelled_cheque_url: "",
  });
  const [message, setMessage] = useState("");
  const [channel_banner, setChannel_banner] = useState("");
  const [channel_image, setChannel_image] = useState("");
  const [channel_logo, setChannel_logo] = useState("");
  const [intro_video, setIntro_video] = useState("");

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");
  const [Channel_Partner_id, setChannel_Partner_id] = useState("1");

  // console.log(access_token)

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  // console.log(headers)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setProcessing(true);
        setShowOverlay(true);

        const response = await axios.post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/About`,
          { Channel_Partner_id: Channel_Partner_id },
          {
            headers: headers,
          }
        );

        var result = response?.data?.Channel;
        setOrder(result);
        setEditUser(result);

        setProcessing(false);
        setShowOverlay(false);
      } catch (error) {
        // console.error(error);
        setProcessing(false);
        setShowOverlay(false);
      }
    };

    fetchData(); // Call the async function
  }, []);

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    const editInputvalue = {
      id: editUser.id,
      channel_name: editUser.channel_name,
      channel_slug: editUser.channel_slug,
      email: editUser.email,
      password: editUser.password,
      ccode: editUser.ccode,
      mobile_number: editUser.mobile_number,
      channel_image: editUser.channel_image,
      channel_about: editUser.channel_about,
      status: editUser.status,
      activation_code: editUser.activation_code,
      bank_name: editUser.bank_name,
      branch_name: editUser.branch_name,
      account_number: editUser.account_number,
      IFSC_Code: editUser.IFSC_Code,
      cancelled_cheque: editUser.cancelled_cheque,
      upi_id: editUser.upi_id,
      upi_mobile_number: editUser.upi_mobile_number,
    };
    // console.log(editInputvalue);
    let res = await fetch(
      `${process.env.REACT_APP_Baseurl}/Channel/Backend/About-update`,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(editInputvalue),
      }
    );

    let resjson = await res.json();
    // console.log(resjson)
    if (res.status == 200) {
      const formData1 = new FormData();

      formData1.append("Channel_Partner_id", Channel_Partner_id);
      formData1.append("intro_video", intro_video);

      axios
        .post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/Intro-video-update`,
          formData1,
          { headers: headers }
        )
        .then((response) => {
          // console.log('api' , response);
          if (response.data.status == true) {
            var result = response.data;
            // console.log('result', response);
            // navigate('/admin/all-artist')
            alert("Added successfully  !");
          }
        });

      const formDatas = new FormData();

      formDatas.append("Channel_Partner_id", Channel_Partner_id);
      formDatas.append("channel_image", channel_image);
      formDatas.append("channel_banner", channel_banner);
      formDatas.append("channel_logo", channel_logo);

      axios
        .post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/Images-update`,
          formDatas,
          { headers: headers }
        )
        .then((response) => {
          // console.log('api' , response);
          if (response.data.status == true) {
          }
        });
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }
  };

  return (
    <div>
      <div>{showOverlay && <ResponseLoader />}</div>

      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div id="content-page" className="content-page">
              <div className="">
                <div className="iq-card">
                  <div id="moderator-container">
                    <div className="moderator-section-title">
                      <h4>
                        <i className="entypo-globe"></i> My Channel
                      </h4>
                    </div>
                    <div className="clear"></div>
                    <hr />
                    <form
                      method="POST"
                      action=""
                      accept-charset="UTF-8"
                      file="1"
                      enctype="multipart/form-data"
                      id="Moderator_form"
                    >
                      <div className="row justify-content-between p-3">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              for="name"
                              className=" col-form-label text-md-right"
                            >
                              Channel Name:
                            </label>
                            <input
                              id="name"
                              type="text"
                              className="form-control"
                              name="channel_name"
                              value={editUser?.channel_name}
                              onChange={handleInput}
                              autocomplete="channel_name"
                              autofocus
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              for="email"
                              className=" col-form-label text-md-right"
                            >
                              Channel About:
                            </label>
                            <input
                              id="channel_about"
                              type="text"
                              className="form-control "
                              name="channel_about"
                              value={editUser?.channel_about}
                              onChange={handleInput}
                              autocomplete="channel_about"
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              for="mobile_number"
                              className=" col-form-label text-md-right"
                            >
                              Phone Number:
                            </label>
                            <input
                              id="mobile_number"
                              type="number"
                              className="form-control"
                              name="mobile_number"
                              value={editUser?.mobile_number}
                              onChange={handleInput}
                              autocomplete="mobile_number"
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              for="email"
                              className=" col-form-label text-md-right"
                            >
                              Email:
                            </label>
                            <input
                              id="email"
                              type="email"
                              className="form-control "
                              name="email"
                              value={editUser?.email}
                              onChange={handleInput}
                              autocomplete="email"
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              for="picture"
                              className=" col-form-label text-md-right"
                            >
                              Channel Logo :
                            </label>
                            <div>
                              <img
                                src={editUser?.channel_logo_url}
                                width={200}
                              />
                            </div>
                            <input
                              id="channel_logo"
                              type="file"
                              className="form-control"
                              name="channel_logo"
                              onChange={(e) =>
                                setChannel_logo(e.target.files[0])
                              }
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              for="picture"
                              className=" col-form-label text-md-right"
                            >
                              Channel Intro Video:
                            </label>
                            <div className="channel_about">
                              <media-player
                                title={editUser?.title}
                                src={editUser?.intro_video_url}
                                poster=""
                                controls
                                width="300"
                                height="300"
                              >
                                <media-outlet className="channel_about"></media-outlet>
                              </media-player>
                            </div>
                            <input
                              id="intro_video"
                              type="file"
                              accept="video/mp4"
                              className="form-control"
                              name="intro_video"
                              onChange={(e) =>
                                setIntro_video(e.target.files[0])
                              }
                            />
                            <p className="text" id="intro_video">
                              {" "}
                            </p>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              for="channel_banner"
                              className=" col-form-label text-md-right"
                            >
                              Channel Banner:
                            </label>
                            <div>
                              <img
                                src={editUser?.channel_banner_url}
                                width={200}
                              />
                            </div>
                            <input
                              id="channel_banner"
                              type="file"
                              className="form-control"
                              name="channel_banner"
                              onChange={(e) =>
                                setChannel_banner(e.target.files[0])
                              }
                            />
                            <p className="text" id="channel_banner">
                              {" "}
                            </p>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              for="channel_image"
                              className=" col-form-label text-md-right"
                            >
                              Profile Picture:
                            </label>
                            <div>
                              <img
                                src={editUser?.channel_image_url}
                                width={200}
                              />
                            </div>
                            <input
                              id="channel_image"
                              type="file"
                              className="form-control"
                              name="channel_image"
                              onChange={(e) =>
                                setChannel_image(e.target.files[0])
                              }
                            />
                            <p className="text" id="channel_image">
                              {" "}
                            </p>
                          </div>
                        </div>

                        <div className="form-group row mb-0 text-end">
                          <div className="col-md-12 ">
                            <button
                              type="submit"
                              className="btn btn-primary"
                              onClick={handleUpdate}
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default AboutMyChannel;
