import React, { useEffect, useRef, useState } from "react";
import videojs from 'video.js';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "video.js/dist/video-js.css";
import "./videoPlayer.css";
import { FaPlay, FaPause } from 'react-icons/fa';
import { GrForwardTen, GrBackTen } from "react-icons/gr";
import { VscDebugRestart } from "react-icons/vsc";
import AdInformationCard from "./AdInformationCard";
import { useTranslation } from "react-i18next";
import { BsArrowsFullscreen, BsFullscreenExit } from "react-icons/bs";
import RelatedVideosSwiper from "./ReletedVideosSwiper";
import axios from "axios";
import { AiOutlineClose } from "react-icons/ai";
import "videojs-contrib-ads";
import "videojs-ima";

function convertTimeToSeconds(time) {
  const [hours, minutes, seconds] = time.split(':').map(Number);
  return hours * 3600 + minutes * 60 + seconds;
}
export const VideosJs = ({ videoDetailsData, options, onReady, settings, imaOptions, audioTracks }) => {
  let freeDurationInSeconds = null;
  if (videoDetailsData?.free_duration_status == 1) {
    freeDurationInSeconds = convertTimeToSeconds(videoDetailsData?.free_duration_time);
  }
  const [hasReachedTimeLimit, setHasReachedTimeLimit] = useState(false);
  const [showPurchasePage, setShowPurchasePage] = useState(false);
  const getLastViewedVideo = videoDetailsData?.continue_watching_exists?.current_time;
  const { t } = useTranslation();
  const { lang, slug } = useParams();
  const navigate = useNavigate();
  const user_role = localStorage.getItem("role");
  const user_id = localStorage.getItem("user_id");
  const [isPlaying, setIsPlaying] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const [duration, setDuration] = useState(0);
  const [watchPercentage, setWatchPercentage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showButton, setShowButton] = useState({ skipButton: false, recapButton: false, cardButton: false, relatedVideo: false });
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const TimeConverter = (data) => {
    const [hours, minutes, seconds] = data?.split(":").map(Number);
    const totalSeconds = hours * 3600 + minutes * 60 + seconds;
    return totalSeconds;
  }
  const handleRecap = () => {
    const currentTime = playerRef.current.currentTime();
    const backTime = currentTime - TimeConverter(videoDetailsData?.recap_start_session);
    playerRef.current.currentTime(backTime);
    setShowButton((prevState) => ({ ...prevState, recapButton: false, }));
  };
  const handleSkip = () => {
    const currentTime = playerRef.current.currentTime();
    const backTime = currentTime + TimeConverter(videoDetailsData?.skip_start_session);
    playerRef.current.currentTime(backTime);
    setShowButton((prevState) => ({ ...prevState, skipButton: false, }));
  }
  const handleFullscreen = () => {
    const player = playerRef.current;
    if (player) {
      if (player.isFullscreen()) {
        player.exitFullscreen();
      } else {
        player.requestFullscreen();
      }
    }
  };
  useEffect(() => {
    if (videoRef.current && videoDetailsData) {
      const player = videojs(videoRef.current, options, () => {
        playerRef.current = player;
        videoDetailsData?.Subtitle?.forEach((subtitle) => {
          player.addRemoteTextTrack({
            kind: 'captions',
            label: subtitle?.subtitle_language,
            srclang: subtitle?.shortcode,
            src: subtitle?.subtitle_url,
          });
        });
        player.loadingSpinner.removeClass('vjs-loading-spinner');
        player.loadingSpinner.addClass('vjs-loading-spinner-myvideo');
        const handleContextMenu = (e) => {
          e.preventDefault();
        };
        videoRef.current.addEventListener('contextmenu', handleContextMenu);
        // const playerAudioTracks = player.audioTracks();
        // if (playerAudioTracks && audioTracks.length > 0) {
        //   audioTracks.forEach((track, index) => {
        //     playerAudioTracks.addTrack(track);
        //   });
        //   audioTracks.forEach((track, index) => {
        //     if (track.enabled) {
        //       playerAudioTracks[index].enabled = true;
        //     }
        //   });
        // }
        const toggleButtons = () => {
          const currentTime = player.currentTime();
          const startShowTimeSkip = TimeConverter(videoDetailsData?.skip_start_time);
          const endShowTimeSkip = TimeConverter(videoDetailsData?.skip_end_time);
          const startShowTimeRecap = TimeConverter(videoDetailsData?.recap_start_time);
          const endShowTimeRecap = TimeConverter(videoDetailsData?.recap_end_time);
          const startShowTimeCard = TimeConverter(videoDetailsData?.url_linktym);
          const endShowTimeCard = TimeConverter(videoDetailsData?.urlEnd_linksec);

          setShowButton((prevState) => ({
            ...prevState,
            skipButton: currentTime >= startShowTimeSkip && currentTime <= endShowTimeSkip,
            recapButton: currentTime >= startShowTimeRecap && currentTime <= endShowTimeRecap,
            cardButton: currentTime >= startShowTimeCard && currentTime <= endShowTimeCard,
            relatedVideo: false,
          }));
        };
        onReady && onReady(player);
        player.on('fullscreenchange', () => setIsFullscreen(player.isFullscreen()));
        player.on('play', () => setIsPlaying(true));
        player.on('pause', () => setIsPlaying(false));
        player.on('userinactive', () => {
          const playPauseButton = document.querySelector('.custom-play-pause-button');
          if (playPauseButton) {
            playPauseButton.style.display = 'none';
          }
        });
        player.on('useractive', () => {
          const playPauseButton = document.querySelector('.custom-play-pause-button');
          if (playPauseButton) {
            playPauseButton.style.display = 'block';
          }
        });
        player.on('userinactive', () => {
          const skipForwardButton = document.querySelector('.custom-skip-forward-button');
          const skipBackwardButton = document.querySelector('.custom-skip-backward-button');
          if (skipForwardButton && skipBackwardButton) {
            skipForwardButton.style.display = 'none';
            skipBackwardButton.style.display = 'none';
          }
        });
        player.on('useractive', () => {
          const skipForwardButton = document.querySelector('.custom-skip-forward-button');
          const skipBackwardButton = document.querySelector('.custom-skip-backward-button');
          if (skipForwardButton && skipBackwardButton) {
            skipForwardButton.style.display = 'block';
            skipBackwardButton.style.display = 'block';
          }
        });
        player.on('timeupdate', toggleButtons);
        player.on('ended', () => {
          setShowButton((prevState) => ({ ...prevState, relatedVideo: true }));
        });
        player.on('timeupdate', () => {
          setCurrentTime(player.currentTime());
          setPlayedSeconds(player.currentTime());

          if (player.currentTime() >= freeDurationInSeconds && !hasReachedTimeLimit) {
            setHasReachedTimeLimit(true);
            setShowPurchasePage(true);
          }
        });
        player.on('loadedmetadata', () => {
          setDuration(player.duration());
          if (videoDetailsData?.users_free_duration_status == false) {
            if (getLastViewedVideo) {
              player.currentTime(getLastViewedVideo);
            }
            const updateProgressControl = () => {
              const duration = player.duration();
              const watchedPercentage = (getLastViewedVideo / duration) * 100;
              const playProgress = player.controlBar.progressControl.el().querySelector('.vjs-play-progress');
              const sliderBar = player.controlBar.progressControl.el().querySelector('.vjs-slider-bar');
              if (playProgress && sliderBar) {
                playProgress.style.width = `${watchedPercentage}%`;
                sliderBar.style.width = `${watchedPercentage}%`;
              }
            };
            updateProgressControl();
          }
        });
        // audioTracks.forEach((track) => {
        //   player.audioTracks().addTrack(track);
        // });
        return () => {
          videoRef.current.removeEventListener('contextmenu', handleContextMenu);
          player.dispose();
        };
      });
    }
  }, [videoDetailsData, hasReachedTimeLimit, options, audioTracks]);
  // useEffect(() => {
  //   if (videoRef.current && videoDetailsData) {
  //     const player = videojs(videoRef.current, options, () => {
  //       playerRef.current = player;

  //       // Adding subtitles
  //       videoDetailsData?.Subtitle?.forEach((subtitle) => {
  //         player.addRemoteTextTrack({
  //           kind: 'captions',
  //           label: subtitle?.subtitle_language,
  //           srclang: subtitle?.shortcode,
  //           src: subtitle?.subtitle_url,
  //         });
  //       });

  //       // Adding audio tracks as separate sources
  //       audioTracks.forEach((track, index) => {
  //         const audioTrack = new videojs.AudioTrack({
  //           kind: 'alternative',
  //           label: track.label,
  //           language: track.language,
  //           enabled: track.enabled || false,
  //         });
  //         player.audioTracks().addTrack(audioTrack);
  //       });

  //       // Function to switch the audio track by updating the player's source
  //       const switchAudioTrack = (audioUrl) => {
  //         const currentTime = player.currentTime(); // Get the current time to continue playback
  //         const videoSource = player.src(); // Get the current video source

  //         // Update player source to the same video with new audio track
  //         player.src({
  //           src: videoSource.src, // Keep the current video source
  //           type: videoSource.type,
  //           audioTracks: [{
  //             kind: 'alternative',
  //             label: 'Selected Audio',
  //             src: audioUrl, // New audio track URL
  //             language: 'es', // Example: Spanish audio
  //           }],
  //         });

  //         // Resume playback when user interacts with the player
  //         player.ready(() => {
  //           if (player.paused()) {
  //             // Only play if the video is paused (prevents auto-play error)
  //             player.currentTime(currentTime); // Set the video to the previous time
  //             player.play().catch(error => {
  //               console.log('Error playing video:', error.message);
  //             });
  //           }
  //         });
  //       };

  //       // Event listener for language change
  //       player.on('languagechange', () => {
  //         const newLanguage = player.language();
  //         // Find the corresponding audio URL for the selected language
  //         const selectedAudioTrack = audioTracks.find(track => track.language === newLanguage);
  //         if (selectedAudioTrack) {
  //           switchAudioTrack(selectedAudioTrack.src); // Switch to the new audio track source
  //         }
  //       });

  //       // Manual trigger example (replace with your language switch logic)
  //       const handleLanguageChange = (newLanguage) => {
  //         player.language(newLanguage); // Change the language
  //         const selectedAudioTrack = audioTracks.find(track => track.language === newLanguage);
  //         if (selectedAudioTrack) {
  //           switchAudioTrack(selectedAudioTrack.src); // Switch to the new audio track source
  //         }
  //       };

  //       // Example: Switch to Spanish (for testing, replace with your logic)
  //       handleLanguageChange('es'); // 'es' for Spanish

  //       return () => {
  //         player.dispose();
  //       };
  //     });
  //   }
  // }, [videoDetailsData, audioTracks]);

  //FreeDuration
  useEffect(() => {
    if (videoDetailsData?.users_free_duration_status === true &&
      videoDetailsData?.users_video_visibility_status == true && hasReachedTimeLimit) {
      setShowPurchasePage(true)
      navigate(`/${lang}/purchase/${slug}`);
    }
  }, [videoDetailsData, hasReachedTimeLimit, lang, slug]);

  const newCurrentTime = Math.floor(currentTime);
  const location = useLocation();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [locationData, setLocationData] = useState({ city: '', region: '', country: '', latitude: '', longitude: '', ip: '', });
  useEffect(() => {
    if (duration > 0) {
      const percentage = (playedSeconds / duration) * 100;
      setWatchPercentage(percentage);
    }
  }, [playedSeconds, duration]);
  const calculatePercentage = watchPercentage.toFixed(2);
  const userRole = user_role ? user_role : '9';
  const SaveLastViewedVideo = async () => {
    try {
      const data = {
        Login_user_type: userRole,
        Login_device_type: 'web',
        Login_user_id: user_id,
        source_type_id: 1,  //For Videos
        source_id: videoDetailsData?.id,
        source_type: 1,
        current_time: newCurrentTime,
        watch_percentage: `${calculatePercentage}%`,
        skip_time: '',
        country_name: locationData?.country,
        region_name: locationData?.region,
        city_name: locationData?.city
      }
      const response = await axios.post(`${process.env.REACT_APP_Baseurl}/Front-End/continue-watching-recent-view-store-update`, data);
      setData(response.data);
      //console.log(response?.data)
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   let myButtonForward, myButtonBackWard;
  //   const player = videojs(videoRef.current, options, () => {
  //     const setupCustomButton = () => {
  //       myButtonForward = player.bigPlayButton.addChild("button", {}, 0);
  //       myButtonForward.addClass("kk");
  //       myButtonForward.el().innerHTML = `<img style="height:35px;width:35px;" src=${forward} alt="Forward">`;
  //       myButtonForward.controlText("Skip Forward 10 Seconds");
  //       myButtonForward.el().onclick = function () {
  //         var currentTime = player.currentTime();
  //         var skipTime = currentTime + 10;
  //         player.currentTime(skipTime);
  //       };

  //       myButtonBackWard = player.bigPlayButton.addChild("button", {}, 0);
  //       myButtonBackWard.addClass("kkk");
  //       myButtonBackWard.el().innerHTML = `<img style="height:35px;width:35px;" src=${backward} alt="Backward">`;
  //       myButtonBackWard.controlText("Skip Back 10 Seconds");
  //       myButtonBackWard.el().onclick = function () {
  //         var currentTime = player.currentTime();
  //         var skipTime = currentTime - 10;
  //         player.currentTime(skipTime);
  //       };
  //     }
  //     setupCustomButton();
  //   })
  // }, [])

  useEffect(() => {
    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
      }
    };
  }, []);

  useEffect(() => {
    const player = videojs(videoRef.current, options, () => {
      if (player) {
        // player.hlsQualitySelector({ displayCurrentQuality: true });
        player.hlsQualitySelector();
        const timeout = setTimeout(() => setShowButton((prevState) => ({ ...prevState, skipButton: false, })), 10000);
        return () => clearTimeout(timeout);
      }
    })
  }, []);

  const togglePlayPause = () => {
    const player = playerRef.current;
    if (player) {
      if (player.paused()) {
        setTimeout(() => {
          player.play().catch((error) => {
            console.error('Error playing video:', error);
          });
        }, 100);
      } else {
        player.pause();
      }
    }
  };

  const closeButton = () => {
    try {
      SaveLastViewedVideo();
      const { access_free } = settings;
      const isGuest = !localStorage.getItem("role");
      const { access, slug } = videoDetailsData || {};
      if (window.history.state && window.history.state.idx > 0) {
        window.history.back();
      } else {
        if (isGuest && access == "guest" && access_free == 0) {
          navigate('/');
        } else {
          navigate(`/${lang}/videos/${slug}`);
        }
      }
    } catch (error) {
      console.error('Error in backButton:', error);
    }
  };
  const skipForward = (duration) => {
    const player = playerRef.current;
    player.currentTime(player.currentTime() + duration);
  };
  const skipBackward = (duration) => {
    const player = playerRef.current;
    player.currentTime(player.currentTime() - duration);
  };
  const [showSlider, setShowSlider] = useState(false);
  const toggleSlider = () => {
    setShowSlider(prevShowSlider => !prevShowSlider);
    setShowButton({
      relatedVideo: false
    })
    const swiperContainer = document.querySelector('.swiper-container');
    if (swiperContainer) {
      swiperContainer.style.display = showSlider ? 'block' : 'none';
    }
  };

  return (
    <div>
      <div data-vjs-player className="position-relative" style={{ "overflow": "hidden" }}>
        <div className="d-flex align-items-center position-relative px-3 py-2 gap-3">
          <AiOutlineClose style={{ "fontSize": "35px", "fontWeight": "bold", "fill": "white", "cursor": "pointer" }} onClick={closeButton} />
          <h3 className="theme-text-color">{t(videoDetailsData?.title)}</h3>
        </div>
        <button className="custom-play-pause-button bg-transparent" onClick={togglePlayPause}>
          {isPlaying ? <FaPause style={{ fontSize: "35px" }} /> : <FaPlay style={{ fontSize: "35px" }} />}
        </button>
        <button className="custom-skip-forward-button bg-transparent" onClick={() => skipForward(10)}>
          <GrForwardTen style={{ fontSize: "38px" }} />
        </button>
        <button className="custom-skip-backward-button bg-transparent" onClick={() => skipBackward(10)}>
          <GrBackTen style={{ fontSize: "38px" }} />
        </button>
        <button className="custom-fullscreen-button bg-transparent" onClick={handleFullscreen}>
          {isFullscreen ? <BsFullscreenExit style={{ fontSize: "28px" }} /> : <BsArrowsFullscreen style={{ fontSize: "28px" }} />}
        </button>
        <div className="d-flex align-items-center position-absolute skipRecapWrapper gap-3">
          {showButton?.skipButton && (<button onClick={handleSkip} className="skipBtn bg-transparent rounded-2 border border-light px-5 py-3" > <span className="theme-text-color skipText">Skip Intro </span> </button>)}
          {/* {showButton?.recapButton && (<button onClick={handleRecap} className="skipBtn bg-transparent  rounded-2 border border-light px-3 py-2" > <VscDebugRestart className="theme-text-color" style={{ fontSize: "30px" }} /> </button>)} */}
        </div>
        {showButton?.cardButton && <AdInformationCard title={videoDetailsData?.title} link={videoDetailsData?.url_link} />}
        {/* {showButton?.relatedVideo && <EndCard relatedVideo={videoDetailsData?.title} />} */}
        <video data-vjs-player ref={videoRef} className="vjs-big-play-centered vjs-theme-city my-video-dimensions video-js vjs-play-control customVideoPlayer" />
        {/* {showButton?.relatedVideo && <RelatedVideosSwiper relatedVideo={videoDetailsData?.title} onClick={toggleSlider} />} */}
      </div>

    </div>
  );
};

export default VideosJs;