// External Libraries and Packages
import React, { useEffect, useState } from "react";
import { ToastContainer } from 'react-toastify';
import { connect, useDispatch } from "react-redux";
import { GlobalStyles } from "./GlobalStyles";
import { ThemeProvider } from "styled-components";
import { useLocation, useNavigate } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import "rsuite/dist/rsuite.min.css";
import "react-toastify/dist/ReactToastify.css";

// Internal Styles
import "./App.css";
import "./Styles/ChannelAllRoutes.css";
import "./Styles/ContentAllRoutes.css";
import "./Styles/CommonAdmin.css"
import "./Styles/root.css"
import "./Styles/response.css"
// import "./components/poppin_font/stylesheet.css";

// Internal Components
import AllRoutes from "./Routes/AllRoutes";
import MetaAppendScript from './components/Others/MetaAppendScript'
import { allHomePageAction, allThumbnailAction, getUserTranslateLanguageAction, headerIndexAction, settingAction, profileDetailsAction, getFieldEnable } from "./Redux/Actions/apiActions";
import HelmetDetails from "./components/Others/HelmetDetails";
import MyProvider from "./components/Layout/CommonProvider.js";
import OnlineStatusAlert from "./components/Others/OnlineStatusAlert.jsx";
import OnlineStatus from "./components/Others/OnlineStatus.jsx";

function App({ settings, themeMode, headerSetting, languageDetailsData, currentTheme }) {
  // console.log(languageDetailsData?.Language_Code,"app")
  const userData = localStorage.getItem("access_token")
  const user_role = localStorage.getItem("role");
  const user_id = localStorage.getItem("user_id");


  const headers = {
    "Content-Type": "application/json",
    headers: { "Access-Control-Allow-Origin": "*" },
    Authorization: "Bearer " + userData,
  };

  const dispatch = useDispatch()
  const location = useLocation();
  const navigation = useNavigate()

  useEffect(() => {
    const fetch = async () => {
      try {
        dispatch(settingAction());
        dispatch(allThumbnailAction(headers))
        dispatch(getFieldEnable())
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetch();
  }, []);


  useEffect(() => {
    if (settings && Object.keys(settings).length > 0) {
      !user_role && settings.access_free == 1
        ? dispatch(allHomePageAction(null, 9, "web", headers))
        : user_role && dispatch(allHomePageAction(user_id, user_role, "web", headers));
    }
  }, [settings, user_role, location])

  useEffect(() => {
    dispatch(getUserTranslateLanguageAction(navigation));
  }, [user_role]);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (languageDetailsData?.Language_Code && location.pathname == '/') {
      navigation(`/${languageDetailsData?.Language_Code}`)
    }
  }, [location, languageDetailsData]);


  const fetchData = async () => {
    await dispatch(headerIndexAction(user_id, user_role, "web", headers));
  };

  useEffect(() => {
    fetchData()
  }, [user_role]);



  // const [locationwithip, setLocationwithip] = useState(null);

  // console.log(locationwithip?.ip);

  // useEffect(() => {
  //   // Fetch IP-based location
  //   const fetchIPLocation = async () => {
  //     try {
  //       const response = await axios.get('http://ip-api.com/json/');
  //       const { city, regionName: region, country, lat, lon: longitude, query: ip } = response.data;

  //       setLocationwithip({ city, region, country, latitude: lat, longitude, ip });
  //     } catch (error) {
  //       console.error('Error fetching IP location:', error);
  //     }
  //   };

  //   fetchIPLocation();
  // }, []);

  // useEffect(() => {
  //   // Fetch geolocation if permission is granted
  //   const fetchGeolocation = () => {
  //     if (navigator.geolocation) {
  //       navigator.geolocation.getCurrentPosition(
  //         (position) => {
  //           const { latitude, longitude } = position.coords;
  //           setLocationwithip((prevLocation) => ({
  //             ...prevLocation,
  //             latitude,
  //             longitude,
  //           }));
  //         },
  //         (error) => {
  //           console.error('Error fetching geolocation:', error);
  //         }
  //       );
  //     }
  //   };

  //   fetchGeolocation();
  // }, []);
  const [isOnline, setIsOnline] = useState(OnlineStatus());
  useEffect(() => {
    if (isOnline) {
      const timer = setTimeout(() => {
        setIsOnline(false);
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [isOnline]);

  useEffect(() => {
    if (user_role) {
      dispatch(profileDetailsAction());
    }
  }, [settings, user_role])

  return (
    <>
      {/* <OnlineStatusAlert isOnline={isOnline} /> */}
      <HelmetDetails pageTitle={headerSetting?.website_name.toUpperCase()} metaTitle={headerSetting?.website_name.toUpperCase()} metaDescription={headerSetting?.website_description?.toString().substring(0, 100)} metaImage={themeMode?.logoUrl} />
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
      <ThemeProvider theme={themeMode}>
        <GlobalStyles />
        <MyProvider><AllRoutes props={{ currentTheme: currentTheme }} /></MyProvider>
        {/* <MetaAppendScript /> */}
      </ThemeProvider>
    </>
  );
}
const mapStateToProps = state => ({
  isLoading: state.get_setting_Reducer.isLoading,
  settings: state.get_setting_Reducer.settings,
  themeMode: state.get_headerIndex_Reducer.themeMode,
  headerSetting: state.get_headerIndex_Reducer.headerSetting,
  languageDetailsData: state.get_getUserTranslateLanguage_Reducer.languageDetailsData,
  currentTheme: state.get_headerIndex_Reducer.currentTheme,

});
export default connect(mapStateToProps)(App)