// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.season_episode_header {
    padding: 0px 15px 15px 25px;
  }
  
  .season_episode_header th {
    padding: 0.5rem 0.5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  }
  .dropzoneStyle{
    border: 2px dashed#cccccc;
    border-radius: 10px;
    height: 100px;
    align-items: center;
    text-align: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }`, "",{"version":3,"sources":["webpack://./src/Contentportal/Content_All_Page/Content_Series_Episode/Contenteditseason.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;EAC7B;;EAEA;IACE,sBAAsB;IACtB,oCAAoC;IACpC,wBAAwB;IACxB,wDAAwD;EAC1D;EACA;IACE,yBAAyB;IACzB,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;EACjB","sourcesContent":[".season_episode_header {\n    padding: 0px 15px 15px 25px;\n  }\n  \n  .season_episode_header th {\n    padding: 0.5rem 0.5rem;\n    background-color: var(--bs-table-bg);\n    border-bottom-width: 1px;\n    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);\n  }\n  .dropzoneStyle{\n    border: 2px dashed#cccccc;\n    border-radius: 10px;\n    height: 100px;\n    align-items: center;\n    text-align: center;\n    justify-content: center;\n    align-items: center;\n    cursor: pointer;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
