// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lyrics-line {
  font-size: 26px;
  margin: 10px 0;
  font-weight: 500;
}

.lyrics-line:hover {
 opacity: 1;
}

.highlighted {
  font-size: 26px;
  font-weight: 600 !important;
}

.highlighted~div {
  opacity: 0.6;
}

.lyrics-container-not-result {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  font-family: 'Poppins';
}

.lyrics-container {
  text-align: left;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 768px) {
  .lyrics-line {
    font-size: 14px;
    margin: 10px 0;
  }

  .highlighted {
    font-size: 16px;
  }

}`, "",{"version":3,"sources":["webpack://./src/Pages/Audio/lyrics/lyrics.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,cAAc;EACd,gBAAgB;AAClB;;AAEA;CACC,UAAU;AACX;;AAEA;EACE,eAAe;EACf,2BAA2B;AAC7B;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE;IACE,eAAe;IACf,cAAc;EAChB;;EAEA;IACE,eAAe;EACjB;;AAEF","sourcesContent":[".lyrics-line {\n  font-size: 26px;\n  margin: 10px 0;\n  font-weight: 500;\n}\n\n.lyrics-line:hover {\n opacity: 1;\n}\n\n.highlighted {\n  font-size: 26px;\n  font-weight: 600 !important;\n}\n\n.highlighted~div {\n  opacity: 0.6;\n}\n\n.lyrics-container-not-result {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-size: 25px;\n  font-family: 'Poppins';\n}\n\n.lyrics-container {\n  text-align: left;\n  position: relative;\n  z-index: 1;\n}\n\n@media screen and (max-width: 768px) {\n  .lyrics-line {\n    font-size: 14px;\n    margin: 10px 0;\n  }\n\n  .highlighted {\n    font-size: 16px;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
