import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { MdControlPoint, MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import loadings from "../../../components/Images/loading/loading.gif";
import { FaEllipsisVertical } from "react-icons/fa6";
import ResponseLoader from "../../../components/Others/ResponseLoader";

function Contentlivecategory() {
  const access_token = localStorage.getItem("access_token_cpp");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const [getLiveCategory, setLiveCategory] = useState([]);
  const [itemToDeleteLiveCategory, setItemToDeleteLiveCategory] = useState([]);
  const [selectAllLiveCategory, setSelectAllLiveCategory] = useState(false);
  const [modalCloseLiveCategory, setModalCloseLiveCategory] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    fetchDatalivevideo();
  }, []);

  const fetchDatalivevideo = async () => {
    try {


      const formData = new FormData();
      formData.append("Content_Partner_id", 1);

      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/live-stream-category/List`, formData,
        { headers: headers }
      );
      setLiveCategory(response?.data?.LiveCategory?.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const reorderedItems = Array.from(getLiveCategory);
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);
    setLiveCategory(reorderedItems);
    saveShuffleOrder(reorderedItems);
  };

  const saveShuffleOrder = async (updatedItems) => {
    setShowOverlay(true);
    if (!updatedItems || updatedItems?.length == 0) {
      console.error("Menu items are empty");
      return;
    }

    const positions = updatedItems.map((menuItem) => menuItem.id);
    const ids = updatedItems.map((_, index) => index + 1);

    const requestData = {
      id: JSON.stringify(positions),
      position: JSON.stringify(ids),
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/CPP/live-stream-category/order-live-category`,
        requestData,
        { headers: headers }
      );

      const result = response.data;
      if (result.status == true) {
        toast.success(result);
        setShowOverlay(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);
      var errorMessage = err.response.data.message;
      toast.error(errorMessage);
      setShowOverlay(false);
    }
  };

  const handleSelectAllLiveCategory = () => {
    setSelectAllLiveCategory(!selectAllLiveCategory);
    setItemToDeleteLiveCategory(
      selectAllLiveCategory ? [] : getLiveCategory.map((item) => item.id)
    );
  };

  const handleMultiSelectLiveCategory = (itemId) => {
    if (itemToDeleteLiveCategory.includes(itemId)) {
      setItemToDeleteLiveCategory(
        itemToDeleteLiveCategory.filter((id) => id !== itemId)
      );
    } else {
      setItemToDeleteLiveCategory([...itemToDeleteLiveCategory, itemId]);
    }
  };

  const singleDeleteLiveCategory = (id) => {
    setItemToDeleteLiveCategory([id]);
    setModalCloseLiveCategory(true);
    setSelectAllLiveCategory(false);
  };

  const closeLiveCategoryModal = () => {
    setModalCloseLiveCategory(!modalCloseLiveCategory);
  };

  const openModalMultiDeleteLiveCategory = () => {
    setModalCloseLiveCategory(true);
  };

  const handleDeleteLiveCategoryData = () => {
    closeLiveCategoryModal();
    liveCategoryDeleteOperation();
  };

  const liveCategoryDeleteOperation = async () => {
    setShowOverlay(true);
    const formData = new FormData();
    formData.append("id", "[" + itemToDeleteLiveCategory + "]");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/live-stream-category/multi-delete
        `,
        formData,
        {
          headers: headers,
        }
      );
      const result = response.data;
      if (result.status == true) {
        toast.success(result);
        setShowOverlay(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);
      var errorMessage = err.response.data.message;
      toast.error(errorMessage);
      setShowOverlay(false);
    }
  };

  return (
    <>
      <div
        className={`modal  packageModel ${modalCloseLiveCategory ? "show" : ""
          }`}
        tabIndex="-1"
        style={{ display: modalCloseLiveCategory ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Confirm Deletion</h5>
              <button
                type="button"
                className="btn-close"
                onClick={closeLiveCategoryModal}
              ></button>
            </div>
            <div className="modal-body">
              {itemToDeleteLiveCategory?.length == 1 ? (
                <p className="fs-6">
                  Are you sure you want to delete this live stream category?
                </p>
              ) : (
                <p className="fs-6">
                  Are you sure you want to delete the selected live stream
                  category ?
                </p>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={closeLiveCategoryModal}
              >
                Close
              </button>

              <button
                type="button"
                className="btn btn-danger"
                onClick={handleDeleteLiveCategoryData}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
      {showOverlay && <ResponseLoader />}
      <section className="container-fluid p-0  theme-bg-color-secondary ">
        <div className="d-flex align-items-center flex-wrap p-3 ">
          <div className="col-md-6 col-12 ">
            <h4 className="admin-title mb-0  theme-text-color text-md-start ">  Live Categories <span className="admin-sub-title theme-text-color">(Display all {getLiveCategory?.length} Live Categories)</span></h4>
          </div>
          <div className="col-md-6 col-12 d-flex align-items-center justify-content-end mt-3 mt-sm-0" >
            {itemToDeleteLiveCategory?.length > 0 && (
              <button className="theme-button-bg-color p-2 rounded-2 d-inline-flex align-items-center " onClick={openModalMultiDeleteLiveCategory}>
                <MdDelete className="exportIcon me-2" fill="currentColor" />
                <span className=" admin-button-text ">Delete</span>
              </button>
            )}
            <Link to="/cpp/livestream-add-categories" className="admin-title ms-3 rounded-2 d-inline-flex align-items-center bg-primary py-2 px-3"> <MdControlPoint className="me-2 exportIcon" style={{ width: "22px", height: "22px" }} /> <span className=" admin-button-text "> Add Categories</span></Link>
          </div>
        </div>
        <div className="p-3 adminHeadContainer">
          <div className="p-3 rounded-3 theme-bg-color ">
            <div className="bootstrapTable pb-5">
              <div className="col-12 p-0">
                <div className="row m-0 p-0 drag-border-bottom">
                  <div className="col-lg-2 col-4 data">
                    <label>
                      <input
                        type="checkbox"
                        checked={selectAllLiveCategory}
                        onChange={handleSelectAllLiveCategory}
                      />
                    </label>
                  </div>
                  <div className="col-lg-4 col-4 data"> <label className="pb-3">Live Stream Categories</label> </div>
                  <div className="col-lg-3 col-4 data"> <label className="pb-3">Uploaded by</label> </div>
                  <div className="col-lg-3 col-4 data"> <label className="pb-3">Actions</label> </div>
                </div>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="menu-items">
                    {(provided) => (
                      <ul
                        className="p-0"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {loading ? (
                          <div className="noRecord">
                            {" "}
                            <img
                              src={loadings}
                              width={30}
                              height={30}
                              alt="Animated GIF"
                            />
                            Loading...
                          </div>
                        ) : getLiveCategory?.length <= 0 ? (
                          <div className="noRecord">
                            {" "}
                            <p>No Live Streams Available. </p>
                          </div>
                        ) : (
                          getLiveCategory?.map((item, index) => (
                            <Draggable
                              key={item?.id.toString()}
                              draggableId={item?.id.toString()}
                              index={index}
                            >
                              {(provided) => (
                                <li
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className={`bg-white orderborder ${item.order
                                    } index ${index + 1}w-100`}
                                >
                                  <div className="row m-0 p-0 align-items-center theme-bg-color  py-3 drag-border-bottom-item">
                                    <div className="col-lg-2 col-4 data_th">
                                      <input
                                        type="checkbox"
                                        checked={itemToDeleteLiveCategory.includes(
                                          item.id
                                        )}
                                        onChange={() =>
                                          handleMultiSelectLiveCategory(item.id)
                                        }
                                      />
                                    </div>
                                    <div className="col-lg-4 col-4 data_th">
                                      <div className="d-flex justify-content-between align-items-center theme-bg-color  py-3">
                                        <img
                                          src={item.image_url}
                                          className="profile liveStreamImage"
                                        />
                                        <div className="rightVideoTitle">
                                          <p className="theme-text-color">{item.name}</p>
                                          <span className="dateText">
                                            {item?.upload_on}
                                          </span>
                                        </div>
                                      </div>
                                    </div>{" "}
                                    <div className="col-lg-3 col-4 data_th">
                                      <div>
                                        {" "}
                                        {item?.uploaded_by == "1"
                                          ? "Admin"
                                          : item?.uploaded_by == "5"
                                            ? "Content Partner"
                                            : item?.uploaded_by == "4"
                                              ? "Channel Partner"
                                              : ""}
                                      </div>
                                    </div>
                                    <div className="col-lg-3 col-4 data_th">
                                      <div className="editdropdown rounded-2 ">
                                        <span className="editdropdown-button">
                                          <FaEllipsisVertical className="theme-text-color" />
                                        </span>
                                        <div className="editdropdown-menu  theme-bg-color-secondary ">
                                          <Link
                                            to={
                                              "/cpp/livestream-edit-categories/" +
                                              item?.id
                                            }
                                          >
                                            <span className="theme-text-color">
                                              <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                <g>
                                                  <path class="cls-1" style={{ fill: 'none', stroke: "currentColor", strokeLinecap: 'round', strokeLinejoin: "round", strokeWidth: "0.75px" }} d="M10.3,18.5l-4.74.64A.49.49,0,0,1,5,18.59l.64-4.74a.42.42,0,0,1,.14-.28L17.31,2a.59.59,0,0,1,.85,0L22.11,6a.6.6,0,0,1,0,.84L10.58,18.36A.53.53,0,0,1,10.3,18.5Z" />
                                                  <line class="cls-1" style={{ fill: 'none', stroke: "currentColor", strokeLinecap: 'round', strokeLinejoin: "round", strokeWidth: "0.75px" }} x1="1.01" y1="21.58" x2="18.89" y2="21.58" />
                                                </g>
                                              </svg>
                                              <span className="ms-2 theme-text-color">Edit Categories</span>
                                            </span>
                                          </Link>
                                          <div
                                            onClick={() =>
                                              singleDeleteLiveCategory(item?.id)
                                            }
                                            className="commonActionPadding"
                                          >
                                            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                              <g>
                                                <rect class="cls-1" style={{ fill: 'none', stroke: "currentColor", strokeLinecap: 'round', strokeLinejoin: "round", strokeWidth: "0.75px" }} x="4.38" y="5.54" width="14.49" height="17.71" />
                                                <line class="cls-1" style={{ fill: 'none', stroke: "currentColor", strokeLinecap: 'round', strokeLinejoin: "round", strokeWidth: "0.75px" }} x1="1.16" y1="5.54" x2="22.09" y2="5.54" />
                                                <g>
                                                  <line class="cls-1" style={{ fill: 'none', stroke: "currentColor", strokeLinecap: 'round', strokeLinejoin: "round", strokeWidth: "0.75px" }} x1="9.21" y1="9.56" x2="9.21" y2="19.06" />
                                                  <line class="cls-1" style={{ fill: 'none', stroke: "currentColor", strokeLinecap: 'round', strokeLinejoin: "round", strokeWidth: "0.75px" }} x1="14.04" y1="9.56" x2="14.04" y2="19.06" />
                                                </g>
                                                <path class="cls-1" style={{ fill: 'none', stroke: "currentColor", strokeLinecap: 'round', strokeLinejoin: "round", strokeWidth: "0.75px" }} d="M7.45,5.22a2.72,2.72,0,0,1,0-.28,4.19,4.19,0,1,1,8.37,0c0,.09,0,.19,0,.29" />
                                              </g>
                                            </svg>
                                            <span className="ms-2 theme-text-color">
                                              Delete Categories
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              )}
                            </Draggable>
                          ))
                        )}
                        {provided.placeholder}
                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contentlivecategory;
