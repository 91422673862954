import React from 'react'
import { Link } from 'react-router-dom';

const EndCard = ({ relatedVideo }) => {
    const array = Array.from({ length: 12 });
    return (
        <div className='endCard px-4'>
            <div className='d-flex align-items-center flex-wrap justify-content-center'>
                {
                    array?.map((data, index) => {
                        return (
                            <div className='col-3 p-3'>
                                <div className='endCardBanner rounded-3'>
                                    <Link to="/">
                                        <img src='https://static1.cbrimages.com/wordpress/wp-content/uploads/2023/12/mal-and-alina-in-shadow-and-bone-season-2.jpg' className='w-100 h-100 endCardImage ' />
                                    </Link>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default EndCard