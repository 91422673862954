import React from 'react';
import '../Signin/Header.css'
import logo from '../../components/Images/flicklogo.png'
function Header() {
  return (

    <section className="header1">
      <div className="container text-center p-3">
      <a href="/">
      <img src={logo} className="p-1" alt='flogo' />
      </a>
      </div>
    </section>
  );
}

export default Header;


// import React, { useState, useEffect } from 'react';

// function Header() {

//     const [posts, setPosts] = useState([]);
//     const access_token = localStorage.getItem('access_token');
//     const headers = {
//       'Content-Type': 'application/json',
//       Authorization: 'Bearer ' + access_token,
//     };

//     useEffect(() => {
//         fetch(`${process.env.REACT_APP_Baseurl}/admin/compress_image`, {mode:'no-cors'}, {headers})
//             .then((response) => response.json())
//             .then((data) => {
//                 console.log(data);
//                 setPosts(data);
//             })
//             .catch((err) => {
//                 console.log(err.message);
//             });
//     }, []);
  
//     return (
//         <>
//             {Object.values(posts).map((value, index) => {
//                 return (
//                     <div key={index}>
//                         <div>{posts.status}</div>
//                     </div>
//                 );
//             })}
//         </>
//     );
// }



// export default Header
