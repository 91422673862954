import React, { useEffect, useRef } from 'react';
import Plyr from 'plyr';
import 'plyr/dist/plyr.css';
import Hls from 'hls.js';



const VideohlsSection = () => {
    const playerRef = useRef(null);
  
    useEffect(() => {
      const videoElement = playerRef.current;
  
      if (Hls.isSupported()) {
        const hls = new Hls();
        hls.loadSource('https://cph-p2p-msl.akamaized.net/hls/live/2000341/test/master.m3u8');
        hls.attachMedia(videoElement);
      } else if (videoElement.canPlayType('application/vnd.apple.mpegurl')) {
        videoElement.src = 'https://cph-p2p-msl.akamaized.net/hls/live/2000341/test/master.m3u8';
      }
  
      const player = new Plyr(videoElement);
  
      return () => {
        player.destroy();
      };
    }, []);
  
    return (
      <div className='paddingTop'>
        <video ref={playerRef} controls>
          Your browser does not support HTML5 video.
        </video>
      </div>
    );
  };
  

export default VideohlsSection  
