import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { IconMyClock, IconMyPhone, IconMyWeb } from '../../Images/MyIcons'
import LazyLoadImage from '../../Pages/HomePage/HomeSwiperComponents/LazyLoadImage'
import axios from "axios"
import { getItem, getItemToken } from '../../Utils/localStorageUtils'
import AdminDeleteModal from '../Others/adminCommonComponents/AdminDeleteModal'
import ButtonWithLoader from '../../Pages/Music/CommonComponents/ButtonWithLoader'
import { toast } from 'react-toastify'
const AccountDevice = () => {

    const [data, setData] = useState({
        devices: [],
        deviceLoad: true,
        deviceDeleteLoad: false,
        deviceDeleteId: null
    })
    const [modalOpen, setModalOpen] = useState(false)

    const handleCloseModal = (id) => {
        setModalOpen(!modalOpen)
        setData((data) => ({
            ...data,
            deviceDeleteId: id,
        }))
    }

    const fetchDevicesData = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_Baseurl}/Front-End/logged-users`, {
                Login_user_type: parseInt(getItem('role'), 10) || 9,
                Login_device_type: "web",
                Login_user_id: parseInt(getItem('user_id'), 10) || null,
            }, { headers: getItemToken('access_token') || null })
            const result = response?.data
            if (result?.status) {
                setData((data) => ({
                    ...data,
                    devices: result?.data,
                    deviceLoad: false
                }))
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const RemoveDevice = async () => {
        setData((prev) => ({
            ...prev,
            deviceDeleteLoad: true,
        }))
        try {
            const response = await axios.delete(`${process.env.REACT_APP_Baseurl}/Front-End/delete-logged-user/${data?.deviceDeleteId}`, { headers: getItemToken('access_token') || null })
            const result = response?.data
            if (result?.status) {
                setData((data) => ({
                    ...data,
                    deviceDeleteLoad: false,
                }))
                setModalOpen(!modalOpen)
                toast.success(result?.message)
                fetchDevicesData()
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setData((data) => ({
                ...data,
                deviceDeleteLoad: false,
            }))
            setModalOpen(!modalOpen)
        }
    };

    useEffect(() => {
        fetchDevicesData()
    }, [])

    const devices = [
        {
            deviceType: "web",
            deviceName: "Lenova",
            deviceTime: "17/04/24, 13AM GMT",
            userName: "Yang Yuanqing",
            currentDevice: true,
            avatar: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT2ew_DTprcZkV6wPUyQyRm_XbUzzYhT_9do8D6AhJvC-7YEvRnLTCKpYFNPrSq6FqdRGU&usqp=CAU"
        },
        {
            deviceType: "mobile",
            deviceName: "Redmi",
            deviceTime: "17/04/24, 13AM GMT",
            userName: "Lei Jun",
            currentDevice: false,
            avatar: "https://i.pinimg.com/originals/62/e4/d1/62e4d1ec3f4345f5a946d055b22b3f69.png"
        },
        {
            deviceType: "web",
            deviceName: "Iphone",
            deviceTime: "17/04/24, 13AM GMT",
            userName: "Tim Cook",
            currentDevice: false,
            avatar: "https://akm-img-a-in.tosshub.com/businesstoday/images/story/202310/xdgeaos7tvpf7dcvt7fjyzb3ji-sixteen_nine.jpg?size=948:533"
        },
        {
            deviceType: "mobile",
            deviceName: "Redmi",
            deviceTime: "17/04/24, 13AM GMT",
            userName: "Lei Jun",
            currentDevice: false,
            avatar: "https://i.pinimg.com/originals/62/e4/d1/62e4d1ec3f4345f5a946d055b22b3f69.png"
        },
        {
            deviceType: "web",
            deviceName: "Iphone",
            deviceTime: "17/04/24, 13AM GMT",
            userName: "Tim Cook",
            currentDevice: false,
            avatar: "https://akm-img-a-in.tosshub.com/businesstoday/images/story/202310/xdgeaos7tvpf7dcvt7fjyzb3ji-sixteen_nine.jpg?size=948:533"
        },
    ]
    const formatDate = (timestamp) => {
        const date = new Date(timestamp);

        const dateOptions = {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        };

        const timeOptions = {
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true // AM/PM format
        };

        const formattedDate = date.toLocaleDateString('en-US', dateOptions);
        const formattedTime = date.toLocaleTimeString('en-US', timeOptions);

        return (
            <>  {formattedDate} {formattedTime} </>
        );
    }
    return (
        <section>
            {modalOpen && <AdminDeleteModal modelClose={modalOpen} deleteClose={handleCloseModal} deleteAction={RemoveDevice} buttonText={'Delete Profile'} loading={data?.deviceDeleteLoad} />
            }
            <h4 className='small-md-heading mb-2 px-md-3 px-0'>Manage Access and Device</h4>
            <p className='opacity-75 mb-4 px-md-3 px-0'>These signed-in devices have recently been active on this account. You can sign out any unfamiliar devices or <Link to="/"> change your password </Link>for added security.</p>
            <div className='d-flex align-items-center flex-wrap'>
                {data?.devices?.map((item, index) => {
                    const isFirstOrLastInRow = index % 3 === 0 || (index + 1) % 3 === 0;
                    return (
                        <div className={`deviceLogin ${isFirstOrLastInRow && "active"} p-3 col-lg-6 col-xl-4 col-md-12 col-12 `}>
                            <div className='theme-bg-color-secondary  rounded-2 p-3'>
                                <div className='d-flex align-items-center justify-content-between'>
                                    <div className='m-0 theme-text-color d-flex align-items-center gap-2 '>
                                        {item?.device_name == "web" ? <IconMyWeb styled={{ width: "30px", height: "30px", className: "" }} /> : <IconMyPhone styled={{ width: "30px", height: "30px", className: "" }} />}
                                        <p className='m-0'>{item?.device_name}</p>
                                    </div>
                                    {item?.currentDevice ? <button type='button' className='rounded-2'>Current Device</button> : <button onClick={() => handleCloseModal(item?.id)} className="bg-transparent px-2 py-1 border border-2 primary rounded-2 theme-border-body-color" type="button" >Sign out</button>}
                                </div>
                                <div className='d-flex align-items-center gap-2 my-3'>
                                    <LazyLoadImage src={item?.avatar} alt="header-logo" imageSize=" object-fit-cover " classNameOptional=" rounded-circle themeTwoDeviceAvatar" />
                                    {item?.userName ? <p>{item?.userName}</p> : null}
                                </div>
                                <div className='d-flex align-items-center gap-2 mt-3'>
                                    <IconMyClock styled={{ width: "30px", height: "30px", className: "" }} />
                                    {item?.created_at ? <p>{formatDate(item?.created_at)}</p> : null}
                                </div>

                            </div>
                        </div>)
                })}
            </div>
        </section>
    )
}

export default AccountDevice