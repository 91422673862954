import React from 'react'
import MessageBox from '../Others/MessageBox'

const AccountPlan = () => {
    return (
        <section>
            <h4 className='small-md-heading mb-4'>Current Plan</h4>
            <MessageBox text="Basic" classname='theme-text-color-button' /><span className='p-1 rounded-2 ms-2 theme-bg-color-secondary'>HD</span>
            <p className='mt-4'>Good video quality in HD (720p). Watch ad-free on any phone, tablet, computer or TV. Download on 1 device.</p>
            <button type='button' className='px-4 mt-4 py-2 rounded-2 fw-bold'>Change Plan</button>
        </section>
    )
}

export default AccountPlan