import React from 'react'
import "./PlanThemeTwo.css"
import LazyLoadImage from '../../../Pages/HomePage/HomeSwiperComponents/LazyLoadImage';
import { connect } from 'react-redux';
import { IconMyCheck, IconMyCheckBold } from '../../../Images/MyIcons';


const PlanThemeTwo = ({ themeMode }) => {
    return (
        <div className='themeTwoBannerWrappers w-100 plan pb-5'>
            <header className="theme-bg-color py-3 ">
                <LazyLoadImage src={themeMode?.logoUrl} alt={"logo"} classNameOptional="rounded-3 mx-auto signin-logo-images" imageSize="signin-logo-images loaderMain object-fit-cover mx-auto mb-0" />
            </header>
            <h3 className='py-5 my-5 text-center theme-text-color priceTableBigHead'>Choose Your Plan</h3>
            <div className='d-flex align-items-center my-5 col-5 mx-auto borderRadius theme-bg-color-secondary '>
                <button className='planDuration d-flex align-items-center bg-transparent theme-text-color px-5 py-3 '>
                    <span className='me-2 d-block theme-text-color'>Quarterly</span>
                    <div className='theme-button-bg-color tickBtn  d-flex align-items-center justify-content-center theme-button-text-color rounded-circle'><IconMyCheckBold styled={{ width: "16px", height: "16px" }} /></div>
                </button>
                <button className='planDuration bg-transparent theme-text-color px-5 py-3'><span className='me-2 d-block theme-text-color'>Yearly</span></button>
                <button className='planDuration bg-transparent theme-text-color px-5 py-3'><span className='me-2 d-block theme-text-color'>Monthly</span></button>
            </div>

            <div className='d-flex align-items-center gap-3 justify-content-center pt-4 mb-5'>
                <button className='planChoose border-2 theme-border-button-color rounded-3 d-flex align-items-start bg-transparent flex-column px-3 py-3 '>
                    <h3 className='mb-2 d-block theme-text-color-button planChooseHead'>Mobile</h3>
                    <h5 className='theme-text-color'>$132<span className='monthCalculate'> / 3 Months</span></h5>
                </button>
                <button className='planChoose border-2 border theme-border-color  rounded-3 d-flex align-items-start bg-transparent flex-column px-3 py-3 '>
                    <h3 className='mb-2 d-block theme-text-color-button planChooseHead'>Preminum HD</h3>
                    <h5 className='theme-text-color'>$332<span className='monthCalculate'> / 3 Months</span></h5>
                </button>
                <button className='planChoose border-2 border theme-border-color  rounded-3 d-flex align-items-start bg-transparent flex-column px-3 py-3 '>
                    <h3 className='mb-2 d-block theme-text-color-button planChooseHead'>Preminum 4k</h3>
                    <h5 className='theme-text-color'>$772<span className='monthCalculate'> / 3 Months</span></h5>
                </button>
            </div>

            <div className='d-flex align-items-center justify-content-between col-8 mx-auto  rounded-3 p-4 theme-bg-color-secondary'>
                <div className='col-6 pe-3'>
                    <select className='px-3 py-4 theme-bg-color border-0 rounded-3 w-100' >
                        <option className='planChooseHead'>Select Payment Method</option>
                        {Array.from({ length: 40 }).map((item, index) => <option className='planChooseHead'>{index}</option>)}
                    </select>
                </div>
                <div className='col-6 ps-3'>
                    <button type='button' className='rounded-2 w-100 p-3 planChooseHead'>Buy Plan</button>
                </div>
            </div>
            <div className='p-3 p-md-0'>
                <div className='planWrapper theme-bg-color-secondary rounded-2 p-5 col-8  mx-auto'>
                    <table className='planTable w-100'>
                        <thead className='mb-4  '>
                            <tr>
                                <th colSpan="4"></th>
                                <th className='text-center'><span className='d-block pb-5 planDevice'>Mobile</span></th>
                                <th className='text-center'><span className='d-block pb-5 planDevice'>Premium HD</span></th>
                                <th className='text-center'><span className='d-block pb-5 planDevice'>Premium 4K</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className='border-bottom '>
                                <td colSpan="4">
                                    <h2 className='priceTableHead mb-2'> Premium Content</h2>
                                    <span className='priceTableSub pb-3 d-block opacity-75'>Movies, Web series , TV shows, download</span>
                                </td>
                                <td className='text-center'><IconMyCheck styled={{ width: "30px", height: "30px" }} /></td>
                                <td className='text-center'><IconMyCheck styled={{ width: "30px", height: "30px" }} /></td>
                                <td className='text-center'> <IconMyCheck styled={{ width: "30px", height: "30px" }} /></td>
                            </tr>
                            <tr className='border-bottom '>
                                <td colSpan="4">
                                    <h2 className='priceTableHead mb-2 mt-3'>Music</h2>
                                    <span className='priceTableSub pb-3 d-block opacity-75'>Unlimited Downloads</span>
                                </td>
                                <td className='text-center'><IconMyCheck styled={{ width: "30px", height: "30px" }} /></td>
                                <td className='text-center'><IconMyCheck styled={{ width: "30px", height: "30px" }} /></td>
                                <td className='text-center'> <IconMyCheck styled={{ width: "30px", height: "30px" }} /></td>
                            </tr>
                            <tr className='border-bottom '>
                                <td colSpan="4">
                                    <h2 className='priceTableHead my-4'>Live TV</h2>
                                </td>
                                <td className='text-center'><IconMyCheck styled={{ width: "30px", height: "30px" }} /></td>
                                <td className='text-center'><IconMyCheck styled={{ width: "30px", height: "30px" }} /></td>
                                <td className='text-center'> <IconMyCheck styled={{ width: "30px", height: "30px" }} /></td>
                            </tr>
                            <tr className='border-bottom '>
                                <td colSpan="4">
                                    <h2 className='priceTableHead my-4'>Ad Free</h2>
                                </td>
                                <td className='text-center'> <span className='priceTableSub py-3 d-block opacity-75 text-center'>For Premium Content</span></td>
                                <td className='text-center'> <span className='priceTableSub py-3 d-block opacity-75 text-center'>For Premium Content</span></td>
                                <td className='text-center'> <span className='priceTableSub py-3 d-block opacity-75 text-center'>All Content</span></td>
                            </tr>
                            <tr className='border-bottom '>
                                <td colSpan="4">
                                    <h2 className='priceTableHead mb-2 mt-3'>Device</h2>
                                    <span className='priceTableSub pb-3 d-block opacity-75'>TV, Laptop</span>
                                </td>
                                <td className='text-center'> <span className='priceTableSub py-3 d-block opacity-75 text-center'>2</span></td>
                                <td className='text-center'> <span className='priceTableSub py-3 d-block opacity-75 text-center'>0</span></td>
                                <td className='text-center'> <span className='priceTableSub py-3 d-block opacity-75 text-center'>4</span></td>
                            </tr>
                            <tr className='border-bottom '>
                                <td colSpan="4">
                                    <h2 className='priceTableHead my-4'>No of screens</h2>
                                </td>
                                <td className='text-center'><IconMyCheck styled={{ width: "30px", height: "30px" }} /></td>
                                <td className='text-center'><IconMyCheck styled={{ width: "30px", height: "30px" }} /></td>
                                <td className='text-center'> <IconMyCheck styled={{ width: "30px", height: "30px" }} /></td>
                            </tr>
                            <tr className='border-bottom '>
                                <td colSpan="4">
                                    <h2 className='priceTableHead my-4'>Max Video Qualtiy</h2>
                                </td>
                                <td className='text-center'> <span className='priceTableSub py-3 d-block opacity-75 text-center'>FHD (1080 p)</span></td>
                                <td className='text-center'> <span className='priceTableSub py-3 d-block opacity-75 text-center'>FHD (1080 p)</span></td>
                                <td className='text-center'> <span className='priceTableSub py-3 d-block opacity-75 text-center'>FHD (1080 p)</span></td>
                            </tr>
                            <tr className=''>
                                <td colSpan="4">
                                    <h2 className='priceTableHead my-4'>Max Audio Qualtiy</h2>
                                </td>
                                <td className='text-center'> <span className='priceTableSub py-3 d-block opacity-75 text-center'>Dolby 5.1</span></td>
                                <td className='text-center'> <span className='priceTableSub py-3 d-block opacity-75 text-center'>Dolby 5.1</span></td>
                                <td className='text-center'> <span className='priceTableSub py-3 d-block opacity-75 text-center'>Dolby 5.1</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>


            <div className='pb-5 mb-5'></div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    themeMode: state.get_headerIndex_Reducer.themeMode,
});

export default connect(mapStateToProps)(PlanThemeTwo);