
import React, { useState, useEffect, useRef } from "react";
import "./Content_signup.css";
import Header from "./Content_Header";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { FaFacebookF, FaGoogle } from "react-icons/fa";
import { VscEyeClosed } from "react-icons/vsc";
import google from "../../components/Images/Googl.png";
import fb from "../../components/Images/fb.png";
import { RxEyeOpen } from "react-icons/rx";
import logo from "../../../src/components/Images/flicklogo.png";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

function Content_Signup({ themeMode }) {
  // const [phone, setPhone] = useState("");
  // const navigate = useNavigate();
  // const [input, setInput] = useState({
  //   email: "",
  //   username: "",
  //   password: "",
  //   number: "",
  //   ccode: "",
  //   confirmPassword: "",
  //   check: false,
  // });

  // const [processing, setProcessing] = useState(false);
  // const [showOverlay, setShowOverlay] = useState(false);

  // const [error, setError] = useState({
  //   emailerr: "",
  //   usernameerr: "",
  //   passworderr: "",
  //   numbererr: "",
  //   ccodeerr: "",
  //   confirmPassworderr: "",
  //   checkerr: "",
  // });

  // const onInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setInput((prev) => ({
  //     ...prev,
  //     [name]: value,
  //   }));
  //   validateInput(e);
  // };

  // const [country, setCountry] = useState([]);

  // useEffect(() => {
  //   fetchOptions();
  // }, []);

  // const fetchOptions = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_Baseurl}/users/signup-index`
  //     );
  //     setCountry(response.data.country_code);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // const [loading, setLoading] = useState(false);
  // async function saveFormData() {
  //   setLoading(true);

  //   const formData = new FormData();

  //   formData.append("username", input?.username);
  //   formData.append("email", input?.email);
  //   formData.append("password", input?.password);
  //   formData.append("ccode", input?.ccode);
  //   formData.append("mobile_number", input?.number);
  //   formData.append("confirm_password", input?.confirmPassword);

  //   let firstApiStatus = false;
  //   let resultapi = null;
  //   let resultapifalse = null;
  //   setProcessing(true);
  //   setShowOverlay(true);

  //   try {
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_Baseurl}/CPP-auth/sign-up`,
  //       formData
  //     );

  //     if (response.data.status == true) {
  //       firstApiStatus = true;
  //       resultapi = response.data;
  //     } else if (response.data.status == false) {
  //       resultapifalse = response.data;
  //     }
  //   } catch (error) {}

  //   if (firstApiStatus) {
  //     props.setApiresponsealert(resultapi);
  //     props.setShowalert(true);
  //     setLoading(false);
  //     setTimeout(() => {
  //       window.location.href = "/cpp/login";
  //     }, 3000);
  //   } else {
  //     props.setApiresponsealert(resultapifalse);
  //     props.setShowalert(true);
  //     setLoading(false);
  //     setProcessing(false);
  //     setShowOverlay(false);
  //   }
  // }

  // // const saveFormData = () => {
  // //   // console.log(input);

  // //   axios
  // //     .post(`${process.env.REACT_APP_Baseurl}/CPP-auth/sign-up`, {
  // //       username: input?.username,
  // //       email: input?.email,
  // //       password: input?.password,
  // //       ccode: input?.ccode,
  // //       mobile_number: input?.number,
  // //       confirm_password: input?.confirmPassword,
  // //     })
  // //     .then((res) => {
  // //       window.location.href = "/cpp/login";
  // //       // navigate("/signin");
  // //     })
  // //     .catch((err) => {
  // //       console.log("Error");
  // //     });
  // // };

  // const validateInput = (e) => {
  //   const { name, value } = e.target;

  //   const validateEmail = (email) => {
  //     return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  //   };

  //   // const validateNumber = (number) => {
  //   //   return /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/i.test(number);
  //   // };

  //   const validateNumber = (number) => {
  //     // console.log(number)
  //     return true; // Always return true to disable validation
  //   };

  //   setError({
  //     emailerr: "",
  //     usernameerr: "",
  //     passworderr: "",
  //     numbererr: "",
  //     ccodeerr: "",
  //     confirmPassworderr: "",
  //     checkerr: "",
  //   });

  //   let validated = true;

  //   if (input?.username == "") {
  //     validated = false;
  //     setError((prevState) => ({
  //       ...prevState,
  //       usernameerr: "Please Enter Username*",
  //     }));
  //   }

  //   if (input?.email == "") {
  //     validated = false;
  //     setError((prevState) => ({
  //       ...prevState,
  //       emailerr: "Please Enter Email*",
  //     }));
  //   } else if (!validateEmail(input?.email)) {
  //     validated = false;
  //     setError((prevState) => ({
  //       ...prevState,
  //       emailerr: "Please Enter Valid Email ID*",
  //     }));
  //   }

  //   if (input?.ccode == "") {
  //     validated = false;
  //     setError((prevState) => ({
  //       ...prevState,
  //       ccodeerr: "Choose Code*",
  //     }));
  //   }

  //   if (input?.number == "") {
  //     validated = false;
  //     setError((prevState) => ({
  //       ...prevState,
  //       numbererr: "Please Enter Number*",
  //     }));
  //   } else if (!validateNumber(input?.number)) {
  //     validated = false;
  //     setError((prevState) => ({
  //       ...prevState,
  //       numbererr: "Please Enter Valid Number*",
  //     }));
  //   }

  //   if (input?.password == "") {
  //     validated = false;
  //     setError((prevState) => ({
  //       ...prevState,
  //       passworderr: "Please Enter Password*",
  //     }));
  //   } else if (input?.confirmPassword !== input?.password) {
  //     validated = false;
  //     setError((prevState) => ({
  //       ...prevState,
  //       confirmPassworderr: "Password and Confirm Password do not match*",
  //     }));
  //   }

  //   if (!input?.check) {
  //     validated = false;
  //     setError((prevState) => ({
  //       ...prevState,
  //       checkerr: "Please Tick CheckBox*",
  //     }));
  //   }

  //   if (validated) {
  //     saveFormData();
  //   }
  // };

  // const handleCheckboxChange = (e) => {
  //   const { name, checked } = e.target;
  //   setInput((prev) => ({
  //     ...prev,
  //     [name]: checked,
  //   }));
  // };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   validateInput(e);
  // };

  // // password eye
  // const [passwordType, setPasswordType] = useState("password");
  // const [passwordInput, setPasswordInput] = useState("");
  // const handlePasswordChange = (evnt) => {
  //   setPasswordInput(evnt.target.value);
  // };
  // const togglePassword = () => {
  //   if (passwordType == "password") {
  //     setPasswordType("text");
  //     return;
  //   }
  //   setPasswordType("password");
  // };

  // const [passwordType1, setPasswordType1] = useState("password");
  // const [passwordInput1, setPasswordInput1] = useState("");
  // const handlePasswordChange1 = (evnt) => {
  //   setPasswordInput1(evnt.target.value);
  // };
  // const togglePassword1 = () => {
  //   if (passwordType1 == "password") {
  //     setPasswordType1("text");
  //     return;
  //   }
  //   setPasswordType1("password");
  // };
  // // password eye

  // const handlePhoneChange = (phone) => {
  //   console.log(phone);
  //   setInput({
  //     ...input,
  //     number: phone,
  //   });
  // };
















  const { t } = useTranslation();

  const [inputField, setInputField] = useState({
    email: "", channel_name: "", password: "", mobile_number: "",
    ccode: "", confirmPassword: "", check: true
  });
  const [errField, setErrField] = useState({
    emailerr: "", channel_nameerr: "", passworderr: "",
    numbererr: "", ccodeerr: "", confirmPassworderr: "", checkerr: ""
  });
  function handleChange(e) {
    setInputField({ ...inputField, [e.target.name]: e.target.value });
    //console.log(e.target.value)
  }
  const [country, setCountry] = useState([]);
  useEffect(() => {
    fetchOptions();
  }, []);
  const fetchOptions = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_Baseurl}/users/signup-index`);
      setCountry(response.data.country_code);
    } catch (error) { console.error(error); }
  };


  async function signup() {
    const inputFieldccode = Number.isNaN(parseInt(inputField?.ccode, 10)) ? 0 : parseInt(inputField?.ccode, 10);

    try {
      const res = await axios.post(`${process.env.REACT_APP_Baseurl}/CPP-auth/sign-up`, {
        username: inputField?.channel_name,
        email: inputField?.email,
        password: inputField?.password,
        ccode: inputFieldccode,
        mobile_number: mobile_number,
        confirm_password: inputField?.confirmPassword,
      });

      const result = res.data;
      const signupStatus = result?.status;

      if (signupStatus === true) {
        // const content_name = result?.User?.content_name;
        // const user_id = result?.User?.id;
        // const email = result?.User?.email;
        // const role = result?.User?.role;

        // toast.success(result?.message);
        // localStorage.setItem("content_signup_user_id", user_id);
        // localStorage.setItem("content_signup_email", email);
        // localStorage.setItem("content_signup_role", role);
        // localStorage.setItem("content_signup_channel_name", content_name);

        window.location.href = "/cpp/login";
      } else {
        toast.error(t(result?.message));
      }
    } catch (error) {
      console.error(error);
      toast.error(t(error?.message));
    }
  }



  const [mobile_number, setmobile_number] = useState('');
  //console.log(mobile_number)
  const [isError, setIsError] = useState(false);

  const re = /^[0-9\b]+$/;

  const pattern = new RegExp(/^\d{1,10}$/);

  const handleNumberChange = (e) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/[^0-9]|e/gi, '');
    if (inputValue.length > 12) {
      inputValue = inputValue.slice(0, 12);
    }
    setmobile_number(inputValue);
  };


  const existingEmails = ["example1@example.com", "example2@example.com"];
  const validForm = (e) => {
    e.preventDefault();
    const validateEmail = (email) => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };
    let formIsValid = true;
    setErrField({
      channel_nameerr: "",
      emailerr: "",
      passworderr: "",
      confirmPassworderr: "",
      numbererr: "",
      ccodeerr: "",
      checkerr: ""
    });
    if (inputField?.channel_name == "") {
      formIsValid = false;
      setErrField((prevState) => ({
        ...prevState,
        channel_nameerr: "Please Enter channel_name*",
      }));
    }
    if (inputField?.email == "") {
      formIsValid = false;
      setErrField((prevState) => ({
        ...prevState,
        emailerr: "Please Enter Email*",
      }));
    } else if (!validateEmail(inputField?.email)) {
      formIsValid = false;
      setErrField((prevState) => ({
        ...prevState,
        emailerr: "Please Enter Valid Email ID*",
      }));
    }
    const validateNumber = (number) => {
      return /^\d{6,12}$/.test(number);
    };
    if (inputField?.ccode == "") {
      formIsValid = false;
      setErrField((prevState) => ({
        ...prevState,
        ccodeerr: "Choose Code*",
      }));
    }

    if (inputField?.number == "") {
      formIsValid = false;
      setErrField((prevState) => ({
        ...prevState,
        numbererr: "Please Enter Number*",
      }));
    }
    // else if (!validateNumber(inputField?.number)) {
    //   formIsValid = false;
    //   setErrField((prevState) => ({
    //     ...prevState,
    //     numbererr: "Please Enter Valid Number (6-12 digits)*",
    //   }));

    // }
    else if (mobile_number?.length > 12 || mobile_number?.length < 12) {
      formIsValid = false;
      setErrField((prevState) => ({
        ...prevState,
        numbererr: "Please Enter Valid Number (6-12 digits)*",
      }));
    }
    if (inputField?.password == "") {
      formIsValid = false;
      setErrField((prevState) => ({
        ...prevState,
        passworderr: "Please Enter Password*",
      }));
    } else if (inputField?.confirmPassword !== inputField?.password) {
      formIsValid = false;
      setErrField((prevState) => ({
        ...prevState,
        confirmPassworderr: "Password and Confirm Password do not match*",
      }));
    }
    if (!inputField?.check) {
      console.log(!inputField.check)
      formIsValid = false;
      setErrField((prevState) => ({
        ...prevState,
        checkerr: "Please Tick CheckBox*",
      }));
    }
    if (inputField?.channel_name !== "" && inputField?.email !== "" && inputField?.ccode !== ""
      && inputField?.number !== "" && inputField?.password !== "" && inputField?.confirmPassword !== "" && inputField?.check === true) {
      signup();
    }
    return formIsValid;
  };
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setInputField((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");
  const handlePasswordChange = (evnt) => {
    setPasswordInput(evnt.target.value);
  };
  const togglePassword = () => {
    if (passwordType == "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const [passwordType1, setPasswordType1] = useState("password");
  const [passwordInput1, setPasswordInput1] = useState("");
  const handlePasswordChange1 = (evnt) => {
    setPasswordInput1(evnt.target.value);
  };
  const togglePassword1 = () => {
    if (passwordType1 == "password") {
      setPasswordType1("text");
      return;
    }
    setPasswordType1("password");
  };


  const handlePhoneChange = (phone) => {
    console.log(phone);
    setInputField({
      ...inputField,
      number: phone,
    });
  };

  return (
    <div>
      {/* <Header /> */}

      <section className="signup mt-3">
        <form onSubmit={validForm}>
          <div className="container">
            <div className="d-flex justify-content-center text-center">
              <div className="box-signupp">
                <div className=" mb-2">

                  <img src={themeMode?.logoUrl} alt="SignInLogo" className="d-block mx-auto signin-logo-images w-50 object-fit-contain" />
                </div>
                <div className=" border-bottom mb-2">
                  <input
                    type="text"
                    id="channel_name"
                    name="channel_name"
                    autoComplete="off"
                    className=" p-2 signin-input border-0 theme-text-color"
                    value={inputField?.channel_name}
                    onChange={handleChange}
                    placeholder="Enter your Name*"

                  />

                  {errField?.channel_nameerr !== "" && (
                    <span className="siguperrorr">{errField?.channel_nameerr}</span>
                  )}
                </div>

                <div className=" border-bottom mb-2">
                  <input
                    className=" p-2 signin-input border-0 theme-text-color"
                    id="email"
                    type="email"
                    name="email"
                    value={inputField?.email}
                    placeholder="Enter your Email*"
                    onChange={handleChange}
                  />
                  {errField?.emailerr !== "" && (
                    <span className="siguperrorr">{errField?.emailerr}</span>
                  )}
                </div>

                <div className="mt-3 containter mb-2">
                  <div className="row">
                    <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                      <div className=" border-bottom">
                        <select
                          className="signin-input border-0"
                          name="ccode"
                          id="ccode"
                          value={inputField?.ccode}
                          onChange={handleChange}
                        >
                          <option value="0" className="theme-bg-color theme-text-color">Choose Code</option>
                          {country?.map((item) => (
                            <option className="theme-bg-color theme-text-color"
                              key={item?.id}
                              value={item?.country_name + item?.phonecode}
                            >
                              {item?.country_name} ({item?.phonecode})
                            </option>
                          ))}
                        </select>
                      </div>

                      {errField?.ccodeerr !== "" && (
                        <span className="siguperrorr">{errField?.ccodeerr}</span>
                      )}
                    </div>
                    <div className="col-8 col-sm-8 col-md-8 col-lg-8">
                      <div className="inputt  border-bottom mb-2">
                        <input
                          id="number"
                          type="tel"
                          className="signin-input border-0"
                          name="number"
                          placeholder="Enter your Number*"
                          value={mobile_number}
                          max="12"
                          onChange={handleNumberChange}

                        />
                      </div>
                      {/* {isError ? "Invalid" : ''} */}
                      {errField?.numbererr !== "" && (
                        <div className="siguperrorr">{errField?.numbererr}</div>
                      )}

                    </div>
                  </div>
                </div>
                <div className=" mb-2">
                  <span
                    className="eyepasswordsignup"
                    aria-hidden="true"
                    onClick={togglePassword1}
                  >
                    {" "}
                    {passwordType1 == "password" ? (
                      <VscEyeClosed />
                    ) : (
                      <RxEyeOpen />
                    )}
                  </span>

                  <input
                    id="password"
                    name="password"
                    value={inputField?.password}
                    type={passwordType1}
                    onChange={handleChange}
                    className="p-2 signin-input border-0 theme-text-color border-bottom"
                    placeholder="Enter Your Password*"

                  />
                  {errField?.passworderr !== "" && (
                    <span className="siguperrorr">{errField?.passworderr}</span>
                  )}
                </div>

                <div className=" mb-2">
                  <span
                    className="eyepasswordsignup "
                    aria-hidden="true"
                    onClick={togglePassword}
                  >
                    {" "}
                    {passwordType == "password" ? (
                      <VscEyeClosed />
                    ) : (
                      <RxEyeOpen />
                    )}
                  </span>
                  <input
                    className="p-2 signin-input border-0 theme-text-color border-bottom "
                    type={passwordType}
                    placeholder="Enter your Confirm Password*"
                    name="confirmPassword"
                    id="confirmPassword"
                    value={inputField?.confirmPassword}
                    onChange={handleChange}
                  />
                </div>

                {errField?.confirmPassworderr !== "" && (
                  <label className="siguperrorr">{errField?.confirmPassworderr}</label>
                )}

                <div className="Read_Policy text-start mt-3">
                  <input
                    type="checkbox"
                    name="check" checked={inputField?.check}
                    onChange={handleCheckboxChange}
                  />

                  <label
                  // data-bs-toggle="modal"
                  // data-bs-target="#staticBackdrop"
                  >
                    Yes , I Agree to Terms and Conditions
                  </label>
                </div>



                {errField?.checkerr !== "" && (
                  <label className="siguperrorr">{errField?.checkerr}</label>
                )}

                <button className="w-100 mt-3" type="submit" onClick={validForm}>
                  Sign Up
                </button>
                <h4 className="signinLabelText py-3 theme-text-color">Login with using</h4>
                <div className="d-flex justify-content-center gap-3">
                  <img src={google} alt="google" style={{ width: "39px", height: "40px" }} />
                  <img src={fb} alt="facebook" style={{ width: "43px", height: "43px" }} />
                </div>
                <h6 className="accountsignn">
                  <Link to="/cpp/login" className="text-decoration-none">
                    Already have an account? <span>Sign in</span>
                  </Link>
                </h6>
              </div>
            </div>
          </div>

        </form>
      </section>
    </div>
  );
}
const mapStateToProps = (state, ownProps) => ({
  settings: state.get_setting_Reducer.settings,
  isLoading: state.get_setting_Reducer.isLoading,
  props: ownProps,
  themeMode: state.get_headerIndex_Reducer.themeMode,
  footerlink: state.get_Footer_Reducer.footerlink,
  sociallink: state.get_Footer_Reducer.sociallink,
  companyname: state.get_Footer_Reducer.companyname,
  activeyear: state.get_Footer_Reducer.activeyear,
  error: state.get_Footer_Reducer.error,
});
export default connect(mapStateToProps)(Content_Signup);
