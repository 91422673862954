const videoContentURL = [
    {
        url: '/cpp/video-lists',
        message: 'All Video'
    }, {
        url: '/cpp/add-video',
        message: 'Add Video'
    },
    {
        url: '/cpp/video-categories-list',
        message: 'Manage Video Categories'
    }
]

const seriesContentURL = [
    {
        url: '/cpp/series-lists',
        message: 'Series List'
    }, {
        url: '/cpp/add-series',
        message: 'Add Series'
    },
    {
        url: '/cpp/manage-series-category',
        message: 'Manage Series Genre'
    }
]
const audioContentURL = [
    {
        url: '/cpp/all-audio-lists',
        message: 'Audio List'
    }, {
        url: '/cpp/add-new-audios',
        message: 'Add New Audio'
    },
    {
        url: '/cpp/manage-audio-category',
        message: 'Manage Audio Categories'
    },
    {
        url: '/cpp/audio-manage-albums',
        message: 'Manage Albums'
    }
]
const artistContentURL = [
    {
        url: '/cpp/all-artists',
        message: 'Artist List'
    }, {
        url: '/cpp/add-artists',
        message: 'Add New Artist'
    }
]

const liveStreamContentURL = [
    {
        url: '/cpp/all-livevideos',
        message: 'All Live Stream'
    }, {
        url: '/cpp/add-livestream',
        message: 'Add New Live Stream'
    },
    {
        url: '/cpp/manage-live-category',
        message: 'Manage Live Video Categories'
    },
    // {
    //     url: '/cpp/manage-live-event',
    //     message: 'Live Event Create'
    // }
]
const settingsContentURL = [
    {
        url: '/cpp/mycpp-setting',
        message: 'My Cpp Setting'
    },
    //  {
    //     url: '/cpp/about-my-cpp',
    //     message: 'About My Cpp'
    // }
]
const analyticsContentURL = [
    {
        url: '/cpp/video-analytics',
        message: 'Video Analytics'
    }, {
        url: '/cpp/view-by-region',
        message: 'Video View By Region'
    },
    {
        url: '/channel/payouts',
        message: 'Payouts'
    },
    {
        url: '/cpp/live-payouts',
        message: 'Livestream Payouts'
    }
]

const contentPagesURL = [
    {
        url: '/cpp/all-pages',
        message: 'All Pages'
    },
    {
        url: '/cpp/add-pages',
        message: 'Add Pages'
    }
]


export { videoContentURL, seriesContentURL, audioContentURL, artistContentURL, liveStreamContentURL, settingsContentURL, analyticsContentURL, contentPagesURL }