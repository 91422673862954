import React from 'react'
import './Menu.css'
// import Slider from "react-slick";
import img1 from '../../components/Images/subscribe-slide/edqq56fq 1.png'
import img2 from '../../components/Images/subscribe-slide/image2.png'
import img3 from '../../components/Images/subscribe-slide/kjfck83o 1.png'

var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const videos = [
    {image:img1,title:'video1',description: "Started 10 min ago"},
    {image:img2,title:'video1',description: "Started 10 min ago"},
    {image:img3,title:'video1',description: "Started 10 min ago"},
    {image:img1,title:'video1',description: "Started 10 min ago"},
    {image:img2,title:'video1',description: "Started 10 min ago"},
    {image:img3,title:'video1',description: "Started 10 min ago"},
  ]


const Menu = () => {
  return (
    <div className="channel_home container-fluid mt-4">
    <section id="iq-favorites">
      <div className="container-fluid overflow-hidden">
        <div className="row">
          <div className="col-lg-12">
            <div className="iq-main-header d-flex align-items-center justify-content-between">
              <h4 className="main-title">
                <a href=" ">Latest Videos</a>
              </h4>
              <a className="seeall" href="">
                See all
              </a>
            </div>
            <div className="favorites-contens">
              <ul className="favorites-slider list-inline row p-0 mb-0 slick-initialized slick-slider">
              {/* <Slider {...settings}>
                  {videos?.map((image, index) => (
                        <div className="image-container" key={index}>
                              <img src={image?.image} alt="Image"  />
                              <div className="overlay">
                                  <div className="hover-card">
                                      <div className="hover-image">
                                          <img src={image?.image} alt="image" />
                                      </div>
                                      <div className="content">
                                          <div className="row">
                                              <div className="col-12">
                                                  <div className="d-flex align-items-center justify-content-between watch-add">
                                                      <span className="col-10" style={{paddingRight:'5px'}}>
                                                          <button >Watch Now</button>
                                                      </span>
                                                      <span className="col-2">
                                                          <button >
                                                          <i className="fa fa-plus" aria-hidden="true"></i>
                                                          </button>
                                                      </span>

                                                  </div>
                                                  <ul className="d-flex justify-content-between p-0 mt-2 year-season">
                                                      <span>2023</span>
                                                      <li>Season 1</li>
                                                      <li>2 Languages</li>
                                                  </ul>
                                                  <span className="hover-description">
                                                      <p>This three-part series and produces a compelling.</p>
                                                  </span>

                                              </div>

                                          </div>

                                      </div>

                                  </div>
                              </div>
                          </div>
                          ))}
              </Slider> */}

              </ul>

            </div>
          </div>
        </div>
      </div>
    </section>
   
  </div>
  )
}

export default Menu