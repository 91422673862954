import React from 'react'
import { Link } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai";

const ManageLiveEvent = () => {

    const datas = [
        {
          // Image: ,
          title: "Zootopia",
          name: "Admin",
          type: "Paid",
          access: "ppv",
          stream: "Live Stream Video",
        },
      ];

  return (
    <div>
        <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid">
            <div className="iq-card mt-4">
              <div id="content-page" className="content-page">
                <div className="container-fluid">
                  <div className="admin-section-title">
                    <div className="">
                      <div className="row">
                        <div className="col-md-6">
                          <h4>
                            <i className="entypo-archive"></i> Live Event Artist
                          </h4>
                        </div>
                        <div className="col-md-6" align="right">
                          <Link to="/channel/add-manage-live-event">
                            <button
                              type="button"
                              className="btn btn-primary"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal"
                            >
                              <i className="fa fa-plus-circle"></i> Add New
                            </button>
                          </Link>
                        </div>
                      </div>

                      {/* <div className="d-flex justify-content-between p-3">
                        <div className="d-flex">
                          <p>Show 10</p>
                          <p>&nbsp; &nbsp; entries</p>
                        </div>
                        <div className="d-flex">
                          <p>Search:</p>
                          <input
                            type="search"
                            id="gsearch"
                            name="gsearch"
                            className="border-0 search-rev"
                          />
                        </div>
                      </div> */}

                      <div className="row mt-4">
                        <div className="col-md-12">
                          <table className="table" id="user_tabledss">
                            <thead>
                              <tr className="r1">
                                <th>ID</th>
                                <th>Image</th>
                                <th>Title</th>
                                <th>User Name</th>
                                <th> Video Type </th>
                                <th>Video Access</th>
                                <th>Status</th>
                                <th>Stream Type</th>
                                <th>Slider</th>
                                <th>ACTION</th>
                              </tr>
                            </thead>
                            {/* {datas.map((item, key) => (
                              <thead>
                                <tr className="r1">
                                  <td key={key}> {key + 1} </td>
                                  <td>{item.Image}</td>
                                  <td>{item.title}</td>
                                  <td>{item.name}</td>
                                  <td>{item.type} </td>
                                  <td>{item.access}</td>
                                  <td>
                                    <button className="btn btn-primary">
                                      approved
                                    </button>
                                  </td>
                                  <td>{item.stream}</td>
                                  <td>
                                    <div className="mt-2 d-flex align-items-center col-md-4">
                                      <label className="switch">
                                        <input type="checkbox" />
                                        <span className="slider slid-on round"></span>
                                      </label>
                                    </div>
                                  </td>

                                  <td className="table-content">
                                    <Link
                                      to={"/admin/payment/payPerView-view"}
                                      className="edit ms-1"
                                    >
                                      <AiOutlineEye />
                                    </Link>
                                    <Link
                                      to={"/admin/live-event-artist-edit/:id"}
                                    >
                                      <i
                                        className="fa fa-pencil-square"
                                        aria-hidden="true"
                                      ></i>
                                    </Link>
                                    <Link>
                                      <i
                                        className="fa fa-trash"
                                        aria-hidden="true"
                                      ></i>
                                    </Link>
                                  </td>
                                </tr>
                              </thead>
                            ))} */}
                            <tbody></tbody>
                          </table>
                        </div>
                        <div className="col-md-4"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default ManageLiveEvent


// const access_token = localStorage.getItem("access_token");
//   const headers = {
//     "Content-Type": "application/json",
//     Authorization: "Bearer " + access_token,
//     "Content-Type": "application/json",
//     Accept: "application/json",
//     "Access-Control-Allow-Origin": "*",
//   };

//   useEffect(() => {
//     fetchOptions();
//   }, []);

//   const fetchOptions = async () => {
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_Baseurl}/admin/videos-create`,
//         {
//           headers: headers,
//         }
//       );
//       setOptions(response.data.Available_country);
//       console.log(response.data.Available_country)
//     } catch (error) {




// ok code for post method formdata final
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import Select from 'react-select';

// function ManageLiveEvent() {
//   const [options, setOptions] = useState([]);
//   const [selectedOptions, setSelectedOptions] = useState([]);


//   const access_token = localStorage.getItem("access_token");
//   const headers = {
//     "Content-Type": "application/json",
//     Authorization: "Bearer " + access_token,
//     "Content-Type": "application/json",
//     Accept: "application/json",
//     "Access-Control-Allow-Origin": "*",
//   };

//   useEffect(() => {
//     fetchOptions();
//   }, []);

//   const fetchOptions = async () => {
//     try {
//       const response = await axios.get(`${process.env.REACT_APP_Baseurl}/admin/videos-create`, {headers:headers});
//       setOptions(response.data.Available_country);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   const handleSelectChange = (selectedValues) => {
//     setSelectedOptions(selectedValues);
//   };

//   const handleSubmit = async () => {
//     try {
//       const formData = new FormData();
//       formData.append('selectedOptions', JSON.stringify(selectedOptions.map(option => option.value)));

//       const response = await axios.post('API_URL', formData);
//       console.log(response.data);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   return (
//     <div>
//       <h2>Select Options:</h2>
//       <Select
//         options={options.map((option) => ({ value: option.id, label: option.name }))}
//         isMulti
//         value={selectedOptions}
//         onChange={handleSelectChange}
//       />
//       <button onClick={handleSubmit}>Submit</button>
//     </div>
//   );
// }

// export default 

// above ManageLiveEvent; ok code for post method formdata final












// import React, { useState, useEffect } from 'react';
// import Select from 'react-select';
// import axios from 'axios';

// function ManageLiveEvent() {
//   const [options, setOptions] = useState([]);
//   const [selectedValues, setSelectedValues] = useState([]);


//   const access_token = localStorage.getItem("access_token");
//   const headers = {
//     "Content-Type": "application/json",
//     Authorization: "Bearer " + access_token,
//     "Content-Type": "application/json",
//     Accept: "application/json",
//     "Access-Control-Allow-Origin": "*",
//   };


//   useEffect(() => {
//     axios.get(`${process.env.REACT_APP_Baseurl}/admin/videos-create`, {headers:headers})
//       .then(response => {
//         const apiOptions = response.data.Available_country.map(item => ({
//           value: item.id,
//           label: item.country_name
//         }));
//         setOptions(apiOptions);
//       })
//       .catch(error => {
//         console.error('Error fetching data:', error);
//       });
//   }, []);

//   const handleSelectChange = selectedOptions => {
//     setSelectedValues(selectedOptions);
//   };

//   const handleSubmit = () => {
//     const selectedValuesArray = selectedValues.map(option => option.value);
//     axios.post('your-api-url', { codes: selectedValuesArray })
//       .then(response => {
//         console.log('POST request successful:', response.data);
//       })
//       .catch(error => {
//         console.error('Error updating code array:', error);
//       });
//   };

//   return (
//     <div>
//       <Select
//         options={options}
//         isMulti
//         onChange={handleSelectChange}
//         value={selectedValues}
//       />
//       <button onClick={handleSubmit}>Submit</button>
//     </div>
//   );
// }

// export default ManageLiveEvent;


