import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { MusicViewAllPageAction } from '../../../../Redux/Actions/MusicAction';
import { headerDefault, musicAlbumPageList, userIdLocal, userRoleLocal } from '../../../../components/Api/MusicApi';
import EmptyLoaderStatic from '../../CommonComponents/EmptyLoaderStatic';
import PageError from '../../../../components/Others/PageError';
import MusicLayoutViewAll from '../../CommonComponents/MusicLayoutViewAll';

const MusicBasedAlbumViewAll = ({ settings, viewAllData, isLoading, error }) => {
    const { slug } = useParams();
    const location = useLocation();
    const dispatch = useDispatch();
    useEffect(() => {
        if (settings && Object.keys(settings).length > 0) {
            dispatch(MusicViewAllPageAction("ALBUM", musicAlbumPageList, slug));
          }
    }, [settings, userRoleLocal, userIdLocal, location]);
    return isLoading ? <EmptyLoaderStatic /> : error ? <PageError /> : <div className='px-2 px-md-4'><MusicLayoutViewAll mapData={viewAllData?.[0]?.audios} source={slug} path='music' headingClass='pb-4 text-center' basedCard /></div>

}
const mapStateToProps = (state) => ({
    viewAllData: state.get_Music_ViewAll_Reducer.viewAllData,
    isLoading: state.get_Music_ViewAll_Reducer.isLoading,
    error: state.get_Music_ViewAll_Reducer.error,
    settings: state.get_setting_Reducer.settings,
});


export default connect(mapStateToProps)(MusicBasedAlbumViewAll)