import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Sidebar from "../../channelportal/Sidebar/Sidebar";
import ChannelHeader from "../../channelportal/ChannelHeader/ChannelHeader";
import ChannelFooter from "../../channelportal/Footer/Footer";
const ChannelCommonLayout = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const location = useLocation()

  useEffect(() => {
    setIsOpen(false)
  }, [location])
  return (
    <>
      <Sidebar toggleClassName={isOpen} />
      <div className="adminSideBarRight theme-bg-color-secondary">
        <ChannelHeader toggleAction={toggle} />
        <div className="p-md-4 p-sm-2 p-0 adminHeadContainer">
          <Outlet />
        </div>
        <ChannelFooter />
      </div>
    </>
  );
};

export default ChannelCommonLayout;