import React from 'react'
import BannerOverlay from './BannerOverlay'
import BannerOverlayThemeTwo from './BannerOverlayThemeTwo'
import { connect } from 'react-redux'
const BannerImage = ({ data, currentTheme, page }) => {
    return (
        <div className={`${currentTheme == 1 || page == "movie" ? "bannerContainer" : "bannerWrapper"} w-100 h-100 ${data.id} trailerNot  `} key={data.id} style={{ backgroundImage: `linear-gradient(90deg, rgba(0,0,0,0.5) 50%, transparent) , url(${data?.slider_image ? data?.image_url : data?.Player_image_url})` }} >
            {currentTheme == 1 && <BannerOverlay data={data} type="image" page={page} />}
            {currentTheme == 2 && <BannerOverlayThemeTwo data={data} type="image" page={page} className={page == "movie" ? "px-2 px-md-3 px-lg-5 py-2 py-md-3 py-lg-5 " : ""} />}
        </div>
    )
}

const mapStateToProps = (state) => ({
    currentTheme: state.get_headerIndex_Reducer.currentTheme,
});
export default connect(mapStateToProps)(BannerImage);
