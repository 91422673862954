import React, { useEffect, useRef, useState } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import 'videojs-contrib-ads';
import 'videojs-ima';
import VolumnOn from "../../../../components/Images/volumn-on.png"
import VolumnOff from "../../../../components/Images/volumn-off.png"
import BannerOverlay from './BannerOverlay';
import { RxReload } from "react-icons/rx";
import BannerOverlayThemeTwo from './BannerOverlayThemeTwo';
import { connect } from 'react-redux';

//page props ->  movie,show,home

const BannerPlayer = ({ data, index, sliderCurrentIndex, currentTheme, page }) => {
    const [isMuted, setIsMuted] = useState(true);
    const videoRef = useRef(null);
    const playerRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isRetry, setIsRetry] = useState(false);

    useEffect(() => {
        const player = videojs(videoRef.current, {
            controls: false,
            autoplay: false,
            muted: true,
            playsInline: true,
            sources: [
                {
                    src: data?.trailer_videos_url || data?.trailer_link,
                    type: data?.trailer_video_player_type || 'video/mp4',
                },
            ],
            poster: data?.Player_image_url,

        });
        // player.loadingSpinner.hide();
        playerRef.current = player;
        return () => {
            if (playerRef.current) {
                playerRef.current.dispose();
            }
        };

    }, [data]);

    useEffect(() => {
        const player = playerRef.current;
        if (player) {
            if (sliderCurrentIndex === index) {
                // player.play().then(() => {
                //     player.muted = true;
                // }).catch((error) => {
                //     console.error('Error playing video:', error);
                // });

                //console.log('slider match');
            } else {
                player.currentTime(0);
                // player.pause(); 
                // player.muted = false;
                // console.log('slider not match');
            }
        }
    }, [sliderCurrentIndex, index]);

    const handleMuteToggle = () => {
        const player = videoRef.current;
        if (player) {
            player.muted = !player.muted;
            setIsMuted(!isMuted);
        }
    };
    const handleMouseEnter = () => {
        const player = videoRef.current;
        if (player) {
            player.play().then(() => {
                player.muted = false;
                setIsMuted(false);
                setIsPlaying(true);
                setIsRetry(false)
            }).catch((error) => {
                console.error('Error playing video:', error);
            });
        }
    };

    const handleMouseLeave = () => {
        const player = videoRef.current;
        if (player) {
            player.pause();
            player.muted = true;
            setIsMuted(true);
            setIsPlaying(false);
        }
    };

    const handleVideoEnd = () => {
        const player = playerRef.current;
        setIsRetry(true)
        player.currentTime(0);
        player.pause();
    };
    const handleRetry = () => {
        const player = playerRef.current;
        if (player) {
            player.play().then(() => {
                setIsRetry(false)
            }).catch((error) => {
                console.error('Error retrying video play:', error);
            });
        }
    };

    const handleContextMenu = (e) => {
        e.preventDefault();
    };
    const handleKeyDown = (e) => {
        if (e.key === 'PrintScreen' || (e.ctrlKey && e.key === 'S')) {
            e.preventDefault();
        }
    };

    // videoRef?.current?.addEventListener('keydown', handleKeyDown);
    // videoRef?.current?.addEventListener('contextmenu', handleContextMenu);

    return (
        <>
            <div className={`bannerContainer w-100 h-100 ${data?.id}`} key={data?.id} onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave} >
                <video ref={videoRef} className={`vjs-theme-fantasy w-100 h-100 ${isPlaying ? 'hide-poster' : 'vicky'} `} onEnded={handleVideoEnd} ></video>
                <button className="border-0 bg-transparent p-0 mb-3 d-inline-block volumnButtonImage" style={{ width: "15px", height: "15px" }} onClick={() => handleMuteToggle()}>
                    {isMuted
                        ? <img src={VolumnOff} style={{ width: "15px", height: "15px" }} />
                        : <img src={VolumnOn} style={{ width: "15px", height: "15px" }} />
                    }
                </button>
                {currentTheme == 1 && <BannerOverlay data={data} type="video" page={page} />}
                {currentTheme == 2 && <BannerOverlayThemeTwo data={data} type="video" className={`${page == "movie" ? "px-2 px-md-3 px-lg-5 py-2 py-md-3 py-lg-5 " : ""} position-absolute overlayAbsoluteVideo`} page={page} />}
                {isRetry && <div className='retryButton position-absolute  d-flex align-items-center justify-content-center'><button className='theme-button-bg-color p-2 rounded-circle retryButtonSize' onClick={handleRetry}><RxReload className='theme-text-color' style={{ width: "15px", height: "15px" }} /></button></div>}
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    currentTheme: state.get_headerIndex_Reducer.currentTheme,
});
export default connect(mapStateToProps)(BannerPlayer);
