// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.episodeContainer {
  overflow-x: scroll;
}

.episodeList {
  width: 1000px;
}

.episodeContainer::-webkit-scrollbar {
  display: none;
}

.season_episode_header th {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.dropzoneStyle {
  border: 2px dashed#cccccc;
  border-radius: 10px;
  height: 100px;
  align-items: center;
  text-align: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.allserieschannel .modal-dialog {
  top: 20%;
}`, "",{"version":3,"sources":["webpack://./src/channelportal/Pages/Series&Episode/EditNewSeries.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,sBAAsB;EACtB,oCAAoC;EACpC,wBAAwB;EACxB,wDAAwD;AAC1D;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,QAAQ;AACV","sourcesContent":[".episodeContainer {\n  overflow-x: scroll;\n}\n\n.episodeList {\n  width: 1000px;\n}\n\n.episodeContainer::-webkit-scrollbar {\n  display: none;\n}\n\n.season_episode_header th {\n  padding: 0.5rem 0.5rem;\n  background-color: var(--bs-table-bg);\n  border-bottom-width: 1px;\n  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);\n}\n\n.dropzoneStyle {\n  border: 2px dashed#cccccc;\n  border-radius: 10px;\n  height: 100px;\n  align-items: center;\n  text-align: center;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n}\n\n.allserieschannel .modal-dialog {\n  top: 20%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
