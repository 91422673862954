
import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux';
import Loader from "../../components/Images/loaderget.gif";
import { episodeDetailsAction, videoDetailsAction } from '../../Redux/Actions/apiActions';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import SeriesJs from './SeriesJs';
import { useTranslation } from 'react-i18next';

const SeriesPlayer = ({ episodeDetailsData, isLoading, error, settings }) => {
    // console.log(episodeDetailsData?.Episode_details)
    const { series_slug, episode_slug } = useParams();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user_role = localStorage.getItem("role");
    const user_id = localStorage.getItem("user_id");
    const playerType = episodeDetailsData?.type;
    const embedSource = episodeDetailsData?.embed_code;
    const seriesJsOptions = {
        controlBar:
        {
            //captionsButton: true,
            subtitlesButton: false,
            remainingTimeDisplay: true,
            volumePanel:
            {
                inline: false,
                vertical: true
            },
            pictureInPictureToggle: true
        },
        bigPlayButton: false,
        autoplay: true,
        controls: true,
        responsive: true,
        breakpoints: [{
            tiny: 300,
            xsmall: 400,
            small: 500,
            medium: 600,
            large: 700,
            xlarge: 800,
            huge: 900
        }],
        textTrackSettings: false,
        fluid: true,
        sources: [{
            src: episodeDetailsData?.Episode_details?.episode_url,
            type: episodeDetailsData?.Episode_details?.episode_player_type
        }],
        playbackRates: [0.5, 1, 1.25, 1.5, 1.75, 2],
        html5: {
            nativeTextTracks: false
        }
    };

    useEffect(() => {
        if (settings && Object.keys(settings).length > 0) {
            !user_role && settings.access_free == 1
                ? dispatch(episodeDetailsAction(null, 9, "web", series_slug, episode_slug))
                : user_role && dispatch(episodeDetailsAction(user_id, user_role, "web", series_slug, episode_slug));
        }
    }, [settings, user_role])
    const playerRef = React.useRef(null);

    const handlePlayerReady = (player) => {
        playerRef.current = player;
        player.on("waiting", () => {
        });
        player.on("dispose", () => {
        });
    };

//    const location = useLocation();

    // useEffect(() => {
    //     const handlePopState = (event) => {
    //         // Custom logic to handle the back button press
    //         const confirmLeave = window.confirm("Are you sure you want to leave this page?");
    //         if (confirmLeave) {
    //             alert('dhbdh')
    //             // navigate(location.pathname); // Stay on the same page
    //         } else {
    //             alert("scgh")
    //             //navigate(-1); // Go back
    //         }
    //     };

    //     window.addEventListener('popstate', handlePopState);

    //     return () => {
    //         window.removeEventListener('popstate', handlePopState);
    //     };
    // }, [navigate, location.pathname])

    return (
        <>
            {
                isLoading
                    ? <div className='d-flex justify-content-center align-items-center paddingTop'>
                        <img src={Loader} width={50} height={50} />
                    </div>
                    : episodeDetailsData?.Episode_details?.episode_url && episodeDetailsData?.Episode_details?.episode_player_type
                        ?
                        <SeriesJs options={seriesJsOptions} onReady={handlePlayerReady} episodeDetailsData={episodeDetailsData}
                            settings={settings} />


                        : <div className='d-flex justify-content-center align-items-center paddingTop'>
                            <h3>{t("Invalid Episode src and type")}</h3>
                        </div>
            }</>
    )
}
const mapStateToProps = state => ({
    episodeDetailsData: state.get_episodeDetails_Reducer.episodeDetailsData,
    isLoading: state.get_episodeDetails_Reducer.isLoading,
    settings: state.get_setting_Reducer.settings
});
export default connect(mapStateToProps)(SeriesPlayer)
