import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import axios from "axios";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  MdClose,
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import BootstrapTable from "react-bootstrap-table-next";

import ExportIcon from "../../../Images/Export_Icon_01.png";
import loadings from "../../../components/Images/loading/loading.gif";
import ResponseLoader from "../../../components/Others/ResponseLoader";

const Contentpayoutanalytics = () => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
  const [showOverlay, setShowOverlay] = useState(false);
  const [filter, setFilter] = useState("");
  const [filteredLiveData, setFilteredLiveData] = useState([]);
  const [withOutRefresh, setWithOutRefresh] = useState(false);
  const [getLiveData, setLiveData] = useState([]);
  const [loading, setLoading] = useState(true);

  // let access_token, access_token_cpp;

  // if (localStorage.getItem("username")) {
  //   access_token = localStorage.getItem("access_token");
  // } else {
  //   access_token_cpp = localStorage.getItem("access_token_cpp");
  // }

  // const headers = {
  //   "Content-Type": "application/json",
  //   // Authorization: "Bearer " + access_token_cpp,
  //   Accept: "application/json",
  //   "Access-Control-Allow-Origin": "*",
  // };

  // if (access_token) {
  //   headers.Authorization = "Bearer " + access_token;
  // } else if (access_token_cpp) {
  //   headers.Authorization = "Bearer " + access_token_cpp;
  // }

  const access_token_cpp = localStorage.getItem("access_token_cpp");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token_cpp,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const columns = [
    {
      dataField: "Email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "Uploader Name",
      text: "Uploader Name",
      sort: true,
    },
    {
      dataField: "Commission Paid",
      text: "Commission Paid",
    },
    {
      dataField: "Commission Pending",
      text: "Commission Pending",
    },
    {
      dataField: "Payment Type",
      text: "Payment Type",
    },
    {
      dataField: "Invoice",
      text: "Invoice",
    },
  ];

  useEffect(() => {
    fetchData();
  }, [withOutRefresh]);
  const fetchData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_Baseurl}/*`, {
        headers: headers,
      });
      const liveData = response?.data;
      const filterHelp = liveData;
      setLiveData(liveData);
      setFilteredLiveData(filterHelp);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  const exportHeadersLive = [
    { label: "ID", key: "id" },
    { label: "VIDEOS NAME", key: "title" },
    { label: "EMAIL", key: "uploaded_by" },
    { label: "CHANNEL NAME", key: "active" },
    { label: "TOTAL VIEWS", key: "banner" },
    { label: "UPLOAD ON", key: "upload_on" },
  ];

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    nextPageText: "Next",
    prePageText: "Prev",
    firstPageText: <MdKeyboardDoubleArrowLeft />,
    lastPageText: <MdKeyboardDoubleArrowRight />,
    showTotal: true,
    alwaysShowAllBtns: true,
    sizePerPageList: [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "All", value: filteredLiveData?.length },
    ],
  });
  const conditionalGeneratePDFLive = (get) => {
    const doc = new jsPDF();
    doc.text("ALL LIVE LIST ", 10, 10);
    const columns = [
      "Index",
      "ID",
      "VIDEOS NAME",
      "EMAIL",
      "CHANNEL NAME",
      "TOTAL VIEWS",
      "UPLOAD ON",
    ];
    const allDataPDF = getLiveData?.map((item, index) => [
      index + 1,
      item.id,
      item.title,
      item.email,
      item.channel_name,
      item.total_views,
      item.upload_on,
    ]);
    const filterPDF = filteredLiveData?.map((item, index) => [
      index + 1,
      item.id,
      item.title,
      item.email,
      item.channel_name,
      item.total_views,
      item.upload_on,
    ]);

    doc.autoTable({
      startY: 20,
      head: [columns],
      body: get == 1 ? allDataPDF : filterPDF,
    });
    doc.save("ALL-LIVE-LIST.pdf");
  };

  const generateXLSXLIVE = (get) => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(get == 1 ? getLiveData : filteredLiveData);
    XLSX.utils.book_append_sheet(wb, ws, "MySheet");
    XLSX.writeFile(wb, "ALL-LIVE-LIST.xlsx");
  };

  const handleFilterChangeLive = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setFilter(searchTerm);
    const filteredResults = getLiveData.filter((item) =>
      ["id", "title", "uploaded_by", "active", "upload_on"].some(
        (property) =>
          typeof item[property] == "string" &&
          item[property].toLowerCase().includes(searchTerm)
      )
    );
    setFilteredLiveData(filteredResults);
  };

  const handleClear = () => {
    fetchData();
    setFilter("");
  };

  const datas = [
    {
      name: "Flicknexs",
      uploader: "Subscribed User",
      commission: "20",
      admin: "30",
      moderator: "50",
      views: "10",
      date: "12-02-20023",
    },
  ];

  const options = {
    type: "line",
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
  };

  const labels = ["March", "February", "January"];

  const data = {
    labels,
    datasets: [
      {
        label: "Content Partners Payout Analytics",

        data: [4, 2, 7, 0],
        borderColor: "rgb(53, 162, 235)",
        lineTension: 0.4,
      },
    ],
  };

  return (
    <>
      {showOverlay && <ResponseLoader />}

      <section className="ontainer-fluid p-0  theme-bg-color-secondary  adminHeadContainer pb-5">
        <div className="d-flex align-items-center flex-wrap p-3">
          <div className="col-sm-6 col-12">
            <h4 className="admin-title mb-0  theme-text-color text-md-start">
              Content Partners Payouts Analytics
            </h4>
          </div>
        </div>
        <div className=" border-0 px-3">
          <div className="row p-3 theme-bg-color  m-0 rounded-3">
            <div className="col-12 col-md-6 col-lg-6">
              <div className="d-flex align-items-center ">
                <label className="me-3 theme-text-color">Start Date : </label>{" "}
                <input
                  type="date"
                  className=" w-50 rs-input mt-2 form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                />
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6">
              <div className="d-flex align-items-center ">
                <label className="me-3 theme-text-color">End Date : </label>{" "}
                <input
                  type="date"
                  className=" w-50 rs-input mt-2 form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                />
              </div>
            </div>
            {/* <div className="col-12 col-md-6 col-lg-4">
              <button type="button" className="btn btn-primary float-end">
                Download CSV
              </button>
            </div> */}

            <div className="col-12 mt-2">
              <h4 className="theme-text-color">Payouts View Through Graph </h4>
              <Line options={options} data={data} />
              <p className="text-center theme-text-color">Content Partners Payout Analytics</p>
            </div>

            <div className="col-12 mt-2">
              <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 gap-3">
                <div className="filterContainer position-relative">
                  <input
                    type="text"
                    placeholder="Filter all.."
                    className="filter form-control position-relative"
                    value={filter}
                    onChange={handleFilterChangeLive}
                  />
                  {filter?.length > 0 && (
                    <button
                      type="button"
                      className="closeBtn"
                      onClick={handleClear}
                    >
                      <MdClose fill="currentColor" />
                    </button>
                  )}
                </div>
                <div className="btn-group">
                  <div className="dropdown d-block p-2" key={"2"}>
                    <button
                      className="btn btn-success dropdown-toggle d-flex align-items-center"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span className="admin-button-text">Export </span>
                      <img src={ExportIcon} className="ms-2 exportIcon" />
                    </button>
                    <ul
                      className="dropdown-menu  theme-bg-color-secondary "
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li className="dropdown-item text-light">
                        <CSVLink
                          headers={exportHeadersLive}
                          data={filteredLiveData}
                          className="text-light d-block"
                          filename="LIVE-FILTERED-LIST.csv"
                        >
                          CSV
                        </CSVLink>
                      </li>
                      <li
                        className="dropdown-item text-light"
                        onClick={() => {
                          conditionalGeneratePDFLive(0);
                        }}
                      >
                        PDF
                      </li>
                      <li
                        className="dropdown-item text-light"
                        onClick={() => {
                          generateXLSXLIVE(0);
                        }}
                      >
                        XLSX
                      </li>
                    </ul>
                  </div>

                  <div className="dropdown d-block p-2">
                    <button
                      className="btn btn-success dropdown-toggle d-flex align-items-center"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span className="admin-button-text">Export All</span>
                      <img src={ExportIcon} className="ms-2 exportIcon" />
                    </button>
                    <ul
                      className="dropdown-menu  theme-bg-color-secondary "
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li className="dropdown-item">
                        <CSVLink
                          headers={exportHeadersLive}
                          data={getLiveData}
                          className="text-light d-block "
                          filename="ALL-LIVES-LIST.csv"
                        >
                          CSV
                        </CSVLink>
                      </li>
                      <li
                        className="dropdown-item text-light"
                        onClick={() => {
                          conditionalGeneratePDFLive(1);
                        }}
                      >
                        PDF
                      </li>
                      <li
                        className="dropdown-item text-light"
                        onClick={() => {
                          generateXLSXLIVE(1);
                        }}
                      >
                        XLSX
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="bootstrapTable theme-bg-color">
                <BootstrapTable
                  keyField="id"
                  columns={columns}
                  data={filteredLiveData}
                  pagination={pagination}
                  className="no-border-table theme-text-color"
                  noDataIndication={
                    <div className="noRecord">
                      {loading ? (
                        <label>
                          {" "}
                          <img
                            src={loadings}
                            width={30}
                            height={30}
                            alt="Animated GIF"
                          />
                          Loading...
                        </label>
                      ) : (
                        filteredLiveData?.length <= 0 && (
                          <p className=" theme-text-color">No Payouts data Available. !!!</p>
                        )
                      )}
                    </div>
                  }
                  hover
                  headerClasses="videoWidth"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contentpayoutanalytics;
