// External Libraries and Packages
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import { TagsInput } from "react-tag-input-component";
import JoditEditor from "jodit-react";
import { MdClose } from "react-icons/md";
import { toast } from "react-toastify"

// Internal Styles
import "./Contentaaddlive.css";

// Internal Components
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import facebook from "../../../components/Images/liveicon/facebook.png";
import twitter from "../../../components/Images/liveicon/twitter.png";
import youtube from "../../../components/Images/liveicon/youtube.png";
import ResponseLoader from "../../../components/Others/ResponseLoader";

const Contentaaddlive = () => {
  const access_token = localStorage.getItem("access_token_cpp");
  const CPPID = localStorage.getItem(1)
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  const navigate = useNavigate();

  const [user_access, setUser_access] = useState([]);
  const [livestream_source, setLivestream_source] = useState([]);
  const [inapppurchase, setInappPurchase] = useState("");
  const [age_restrictdata, setAge_restrictdata] = useState([]);
  const [live_stream_video, setLive_stream_video] = useState("");
  const [free_duration_status, setfree_duration_status] = useState("");
  const [alllivestream_source, setalllivestream_source] = useState("");
  const [allliveuseraccess, setallliveuseraccess] = useState("");
  const [language, setLanguage] = useState([]);
  const [artists, setArtists] = useState([]);
  const [category, setCategory] = useState([]);
  const [block_country, setBlock_Country] = useState([]);
  const [coverImageFile, setCoverImageFile] = useState(null)
  const [playerImageFile, setPlayerImageFile] = useState(null)
  const [tvImageFile, setTvImageFile] = useState(null)
  const [ppvPrice, setPPVPrice] = useState(null)
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [enableRestream, setEnableRestream] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [artistData, setArtistsData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [languageData, setLanguageData] = useState([]);
  const [editUserError, setEditUserError] = useState({});
  const [availableCountryData, setAvailableCountryData] = useState([])
  const [blockCountryData, setBlockCountryData] = useState([])
  const [seoData, setSeoData] = useState({})
  const [available_country, setAvailable_country] = useState([]);
  const [editUser, setEditUser] = useState({
    title: '',
    slug: '',
    url_type: '',
    mp4_url: '',
    embed_url: '',
    m3u_url: '',
    live_stream_video: '',
    description: '',
    details: '',
    rating: '',
    year: '',
    duration: '',
    access: '',
    publish_type: 'publish_now',
    enable_restream: '',
    featured: 0,
    banner: 0,
    active: 0,
    search_tags: '',
    ppv_price: '',
    age_restrict: '',
    enable_Tips: 0,
    enable_chats: 0,
    ios_ppv_price: '',
    block_country_id: [],
    ads_position: '',
    live_ads: '',
  })

  //Handle search #tags
  const handleTagsChange = (newTags) => {
    const cleanedTags = newTags
      .map((tag) => tag.trim().toLowerCase())
      .filter((tag) => tag !== "")
      .filter((value, index, self) => self.indexOf(value) == index);
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      'search_tags': cleanedTags,
    }));
  };

  //Handle Publistype
  const handlePublishTypeChange = (event) => {
    const selectedValue = event.target.value;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      publish_type: selectedValue,
    }));
    if (selectedValue == "publish_later") {
      setEditUser((prevEditUser) => ({
        ...prevEditUser,
        publish_time: '',
      }));// Reset the publish time when switching to "Publish Later"
    }
  };

  //Handle publish time
  const handlePublishTimeChange = (event) => {
    const timeValue = event.target.value;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      publish_time: timeValue,
    }));
  };

  //Handle year
  const handleChangeyear = (event) => {
    const name = event.target.name
    const value = event.target.value;
    if (/^\d{0,4}$/.test(value)) {
      setEditUser((prevEditUser) => ({
        ...prevEditUser,
        [name]: value,
      }));
    }
  };

  const handleInputdata = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;
    console.log(inputName, inputValue)
    // Remove non-digit characters from input
    const formattedInput = inputValue?.replace(/\D/g, "");
    // Add colons to maintain the format HH:MM:SS
    const formattedTime = formattedInput
      .replace(/^(\d{2})(\d{2})(\d{2})$/, "$1:$2:$3")
      .substr(0, 8);
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [inputName]: formattedTime,
    }));
  };

  //Handle Blocked country
  const handleSelectChangeBlockedCountry = (selectedValues) => {
    setBlockCountryData(selectedValues)
  };

  //Handle Available country
  const handleSelectChangeAvailable_country = (selectedValues) => {
    setAvailableCountryData(selectedValues)
  };

  //Handle acccess videos
  const handleAccessVideo = (e) => {
    let { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));
  }

  //Handle IOS price 
  const handleIOSPrice = (e) => {
    const { name, value } = e.target
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));
  }

  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
    if (!checkboxChecked) {
      setEditUser((prevEditUser) => ({
        ...prevEditUser,
        'ppv_price': ppvPrice,
      }));
    } else {
      setEditUser((prevEditUser) => ({
        ...prevEditUser,
        'ppv_price': '',
      }));
    }
  };

  //Handle stream funcition
  const handleCheckbox = () => {
    setEnableRestream(!enableRestream);
    const enableRestreamValue = enableRestream ? "0" : "1";
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      enable_restream: enableRestreamValue,
    }));
  };

  //input ref 
  const titleInputRef = useRef(null);
  const categoryInputRef = useRef(null);
  const languageInputRef = useRef(null);
  const slugInputRef = useRef(null)
  const coverImageRef = useRef(null);
  const playerImageRef = useRef(null);
  const tvImageRef = useRef(null);

  //Handle input field
  const handleInput = (e, data) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));
    setEditUserError((prevEditUser) => ({
      ...prevEditUser,
      [name]: '',
    }));
    setEditUserError((prevEditUser) => ({
      ...prevEditUser,
      slug: '',
    }));

    if (e.target.checked == true) {
      console.log(e.target.checked)
      setEditUser((prevEditUser) => ({
        ...prevEditUser,
        [name]: editUser.active == 1 ? 0 : 1,
      }));
      setEditUser((prevEditUser) => ({
        ...prevEditUser,
        [name]: editUser.enable_chats == 1 ? 0 : 1,
      }));
      setEditUser((prevEditUser) => ({
        ...prevEditUser,
        [name]: editUser.banner == 1 ? 0 : 1,
      }));
      setEditUser((prevEditUser) => ({
        ...prevEditUser,
        [name]: editUser?.featured == 1 ? 0 : 1,
      }));
      setEditUser((prevEditUser) => ({
        ...prevEditUser,
        [name]: editUser.enable_Tips == 1 ? 0 : 1,
      }));
    }
  };

  //Handle Auto slug generate function *start*
  const handleBlurtitle = () => {
    generateSlug(editUser?.title);
  };
  const handleBlurTitleEdit = () => {
    generateSlug(editUser?.slug);
  }
  const generateSlug = (title) => {
    title = title?.trim();
    const slug = title?.toLowerCase().replace(/\s+/g, "-");
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      slug: slug,
    }));
  };
  const handleAutoFillChangetitle = (event) => {
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      slug: event.target.value,
    }));
    setEditUserError((prevEditUser) => ({
      ...prevEditUser,
      [event.target.name]: '',
    }));
  };
  const handleInputKeyDowntitle = (event) => {
    if (event.key == "Enter" || event.key == "Tab") {
      generateSlug(editUser?.title);
    }
  };

  //Hanle seo input
  const handleSeoInput = (e) => {
    const { name, value } = e.target;
    setSeoData((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));
  }
  //Handle Image upload
  const handleCoverImageChange = (event) => {
    const selectedFile = event.target.files;
    if (selectedFile.length > 0) {
      const newFile = selectedFile[0];
      const reader = new FileReader();
      reader.onload = () => {
        setCoverImageFile(newFile);
      };
      reader.readAsDataURL(newFile);
    } else {
    }
  };
  const handlePlayerImageChange = (event) => {
    const selectedFile = event.target.files;
    if (selectedFile.length > 0) {
      const newFile = selectedFile[0];
      const reader = new FileReader();
      reader.onload = () => {
        setPlayerImageFile(newFile);
      };
      reader.readAsDataURL(newFile);
    } else {
    }
  };
  const handleTvImageChange = (event) => {
    const selectedFile = event.target.files;
    if (selectedFile.length > 0) {
      const newFile = selectedFile[0];
      const reader = new FileReader();
      reader.onload = () => {
        setTvImageFile(newFile);
      };
      reader.readAsDataURL(newFile);
    } else {
    }
  };

  //Handle jodit edit 
  const handleJodiEditor = (value) => {
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      details: value,
    }));
  }
  //Handle Donate
  const handleDonate = (e) => {
    let { name, value } = e.target;
    const sanitizedInput = value.replace(/\D/g, "");
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: sanitizedInput,
    }));
  };


  const fetchOptions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/Livestream-Create`,
        { headers: headers }
      );
      setBlock_Country(response?.data?.Block_Country);
      setAvailable_country(response?.data?.Available_country);
      setLanguage(response?.data?.livestream_language);
      setArtists(response?.data?.livestream_artist);
      setUser_access(response?.data?.user_access);
      setInappPurchase(response?.data?.InappPurchase);
      setLivestream_source(response?.data?.Livestream_source);
      setCategory(response?.data?.livestream_category);
      setAge_restrictdata(response?.data?.Age_Restrict);
      setPPVPrice(response?.data?.ppv_gobal_price);
    } catch (error) {
      console.error(error);
    }
  };

  //Handle Lanuage
  const handleSelectChangelanguage = (selectedValues) => {
    setLanguageData(selectedValues)
    setEditUserError((prevEditUser) => ({
      ...prevEditUser,
      languageerror: '',
    }));
  };
  //Handle Artists 
  const handleSelectChangeartists = (selectedValues) => {
    setArtistsData(selectedValues);
  };
  //Handle category
  const handleSelectChangecategory = (selectedValues) => {
    setCategoryData(selectedValues);
    setEditUserError((prevEditUser) => ({
      ...prevEditUser,
      categoryerror: '',
    }));
  };

  //Handle submit form 
  const handleLiveSubmit = async () => {
    if (formValidation()) {
      console.log("data true go to api")
      addLiveData()
    }
    else {
      console.log('Form validation failed ');
    }
  }
  const formValidation = () => {
    let formIsValid = true;
    if (!editUser?.title) {
      setEditUserError((prevEditUser) => ({ ...prevEditUser, title: 'Title cannot be empty.' }));
      titleInputRef.current.focus();
      formIsValid = false;
    }
    else if (!editUser?.slug) {
      setEditUserError((prevEditUser) => ({ ...prevEditUser, slug: 'Slug cannot be empty.' }));
      slugInputRef.current.focus();
      formIsValid = false;
    }

    if (categoryData?.length <= 0) {
      setEditUserError((prevEditUser) => ({ ...prevEditUser, categoryerror: 'Please select at least one Category' }));
      categoryInputRef.current.focus();
      formIsValid = false;
    }
    if (languageData.length <= 0) {
      setEditUserError((prevEditUser) => ({ ...prevEditUser, languageerror: 'Please select at least one Language' }));
      languageInputRef.current.focus();
      formIsValid = false;
    }
    return formIsValid;
  }

  const addLiveData = async () => {
    const formData = new FormData();
    const videoFormData = new FormData()
    const imageFormData = new FormData()
    const seoFormData = new FormData()
    try {
      setShowOverlay(true)
      for (const key in editUser) {
        formData.append(key, editUser[key]);
      }

      formData.append("artist_id", JSON.stringify(artistData?.map((option) => option.value)));
      formData.append("category_id", JSON.stringify(categoryData?.map((option) => option.value)));
      formData.append("available_countries_id", JSON.stringify(availableCountryData?.map((option) => option.value)));
      formData.append("block_country_id", JSON.stringify(blockCountryData?.map((option) => option.value)));
      formData.append(
        "languages_id",
        JSON.stringify(languageData?.map((option) => option?.value))
      );

      const response = await axios.post(`${process.env.REACT_APP_Baseurl}/CPP/Backend/Livestream-Store`, formData, { headers });
      let dataResponse = response.data.message
      let resStatusId = response.data.LiveStream_id

      for (const key in seoData) { formData.append(key, seoData[key]); }
      seoFormData.append('livestream_id', resStatusId)

      videoFormData.append('free_duration_status', free_duration_status)
      videoFormData.append('livestream_id', resStatusId)
      videoFormData.append('live_stream_video ', live_stream_video)

      imageFormData.append('livestream_id', resStatusId)
      imageFormData.append('image', coverImageFile)
      imageFormData.append('player_image', playerImageFile)
      imageFormData.append('TV_image', tvImageFile)

      let seoResponse, videoResponse, imageResponse;
      if (dataResponse) {
        try {
          // seoResponse = await axios.post(`${process.env.REACT_APP_Baseurl}/CPP/Backend/Livestream-SEO`, seoFormData, { headers });
          // videoResponse = await axios.post(`${process.env.REACT_APP_Baseurl}/Channel/Backend/Livestream-video-upload`, videoFormData, { headers: headers });
          imageResponse = await axios.post(`${process.env.REACT_APP_Baseurl}/CPP/Backend/Livestream-image-upload/${resStatusId}`, imageFormData, { headers });
          // videoResponse.data.status
          if (imageResponse.data.status) {
            setShowOverlay(false)
            toast.success(dataResponse)
            setTimeout(async () => {
              await navigate("/cpp/all-livevideos");
            }, 3000);
          }
        }
        catch (err) {
          console.log(err)
        }
      }
    }
    catch (err) {
      setShowOverlay(false)
      console.log(err)
    }
  }

  function alllive_streamuseraccess() {
    switch (allliveuseraccess) {
      case "guest":
        return <div></div>;
      case "subscriber":
        return <div></div>;
      case "registered":
        return <div></div>;
      case "ppv":
        return (
          <div className="row ">
            <div className="mt-2">
              <label className="mt-2 theme-text-color">PPV Price</label>
              <div className="d-flex align-items-center justify-content-between">
                <p className="theme-text-color mt-2">Apply PPV Price from Global Settings? </p>
                <label className="switch">
                  <input
                    name="ppv_price"
                    defaultChecked={checkboxChecked}
                    onChange={handleInput}
                    type="checkbox"
                    className="rs-input"
                  />
                  <span
                    className="slider round"
                    name="ppv_price"
                    checked={checkboxChecked}
                    onChange={handleCheckboxChange}
                  ></span>
                </label>
              </div>
              <input
                type="number"
                className="rs-input mt-2  theme-bg-color-secondary  theme-text-color border-0"
                placeholder="PPV Price"
                name="ppv_price"
                id="price"
                readOnly
                value={editUser?.ppv_price}
              />
            </div>

            <div className="mt-3 ">
              <label className="mt-2 theme-text-color"> IOS PPV Price</label>
              <p className="mt-2 theme-text-color">Apply IOS PPV Price from Global Settings?</p>
              <div className="mt-2">
                <select
                  onChange={handleIOSPrice}
                  className="form-select  theme-bg-color-secondary  theme-text-color border-0"
                  id="ios_ppv_price"
                  name="ios_ppv_price"
                >
                  <option value="" className="theme-text-color">Choose a PPV Price</option>
                  {inapppurchase?.map((item, key) => (
                    <option value={item.id}>{item.plan_price}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  useEffect(() => {
    fetchOptions();
  }, []);
  return (
    <>
      {showOverlay && (<ResponseLoader />)}
      <section className="container-fluid p-0  theme-bg-color-secondary  ">
        <div className="d-flex align-items-center flex-wrap p-3">
          <div className="col-sm-6 col-12">
            <h4 className="admin-title mb-0  theme-text-color text-md-start " >Add LiveStream</h4>
          </div>
          <div className="col-sm-6 col-12 d-flex align-items-center justify-content-end">
            <div className="text-end">
              <button onClick={handleLiveSubmit} className="btn btn-primary admin-button-text text-white rounded-2  py-2 px-3">
                Save LiveStream
              </button>
            </div>
          </div>
        </div>
        <div className="row m-0 px-1">
          <div className="col-12 col-md-6">
            <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
              <h6 className="theme-text-color trial-border-bottom  pb-3">Create LiveStream</h6>
              <div className="mt-3 text-start">
                <label className="mt-2 d-block mandatorySimple position-relative theme-text-color">
                  Title
                </label>
                <p className="mt-2 theme-text-color opacity-75">
                  Add the Live stream title in the textbox below
                </p>
                <div className="mt-2">
                  <input
                    type="text"
                    id="title"
                    name="title"
                    className="rs-input form-control-lg  theme-bg-color-secondary   theme-text-color  border-0"
                    onChange={handleInput}
                    value={editUser?.title}
                    onBlur={handleBlurtitle}
                    onKeyDown={handleInputKeyDowntitle}
                    placeholder="Title"
                    ref={titleInputRef}
                  />
                </div>
                {editUserError?.title && (<span className="errorred mt-2 d-block">{editUserError?.title}</span>)}
              </div>
              <div className="mt-2 text-start">
                <label className="mt-2 theme-text-color">Slug</label>
                <p className="mt-2 theme-text-color opacity-75">
                  Add the Live stream slug in the textbox below
                </p>
                <div className="mt-2">
                  <input
                    type="text"
                    id="slug"
                    name="slug"
                    className="rs-input form-control-lg  theme-bg-color-secondary   theme-text-color  border-0"
                    value={editUser?.slug}
                    onChange={handleAutoFillChangetitle}
                    placeholder="slug"
                    ref={slugInputRef}
                    onBlur={handleBlurTitleEdit}
                  />
                </div>
                {editUserError?.slug && (<span className="errorred mt-2 d-block">{editUserError?.slug}</span>)}
              </div>
              <div className="mt-2 text-start">
                <label className="mt-2 theme-text-color">Short Description</label>
                <p className="mt-2 theme-text-color opacity-75">
                  Add a short description of the Livestream below
                </p>
                <div className="mt-2 ">
                  <textarea
                    className="rs-input short-description  theme-bg-color-secondary  theme-text-color border-0"
                    id="description"
                    name="description"
                    onChange={handleInput}
                    placeholder="Description"
                    value={editUser?.description}
                  />
                </div>
              </div>
              <div className="mt-2 text-start">
                <label className="mt-2"> Live Stream Details, Links, and Info</label>
                <div className="mt-2">
                  <JoditEditor
                    name="details"
                    className="rs-input theme-text-color  theme-bg-color-secondary  border-0"
                    value={editUser?.details}
                    onChange={(value) => { handleJodiEditor(value) }}
                  />
                </div>
              </div>
              <div className="mt-2 text-start">
                <label className="mt-2 theme-text-color">Duration</label>
                <p className="mt-2 theme-text-color opacity-75"> Enter The Live Stream Duration In (HH : MM : SS)</p>
                <div className="mt-2">
                  <input
                    type="text"
                    name="duration"
                    maxLength="6"
                    className="rs-input form-control-lg theme-text-color  theme-bg-color-secondary  border-0"
                    onChange={handleInputdata}
                    value={editUser?.duration}
                    placeholder="duration"
                  />
                </div>
              </div>
              <div className="mt-2 text-start">
                <label className="mt-2 theme-text-color">Year</label>
                <p className="mt-2 theme-text-color opacity-75">Enter The Live Stream Year</p>
                <div className="mt-2">
                  <input
                    type="number"
                    name="year"
                    value={editUser?.year}
                    onChange={handleChangeyear}
                    className="rs-input form-control-lg theme-text-color  theme-bg-color-secondary  border-0"
                    placeholder="Year"
                  />
                </div>
              </div>
            </div>
            <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3 ">
              <h6 className="theme-text-color trial-border-bottom  pb-3">Organize</h6>
              <div className="mt-3 text-start">
                <label className="mt-2 theme-text-color">Livestream Ratings</label>
                <p className="mt-2 theme-text-color opacity-75">IMDb Ratings 10 out of 10</p>
                <div className="mt-2">
                  <select
                    className="px-3 py-3 rounded-2 w-100  theme-bg-color-secondary  theme-text-color border-0"
                    id="rating"
                    name="rating"
                    size="lg"
                    onChange={handleInput}
                    value={editUser?.rating}
                  >
                    <option value="1" className=" theme-bg-color-secondary  theme-text-color">1</option>
                    <option value="2" className=" theme-bg-color-secondary  theme-text-color">2</option>
                    <option value="3" className=" theme-bg-color-secondary  theme-text-color">3</option>
                    <option value="4" className=" theme-bg-color-secondary  theme-text-color">4</option>
                    <option value="5" className=" theme-bg-color-secondary  theme-text-color">5</option>
                    <option value="6" className=" theme-bg-color-secondary  theme-text-color">6</option>
                    <option value="7" className=" theme-bg-color-secondary  theme-text-color">7</option>
                    <option value="8" className=" theme-bg-color-secondary  theme-text-color">8</option>
                    <option value="9" className=" theme-bg-color-secondary  theme-text-color">9</option>
                    <option value="10" className=" theme-bg-color-secondary  theme-text-color">10</option>
                  </select>
                </div>
              </div>
              <div className="mt-2 text-start">
                <label className="mt-2 theme-text-color">Age Restrict</label>
                <p className="mt-2 theme-text-color opacity-75">Select A Live Stream Age Below</p>
                <div className="mt-2">
                  <select
                    className="px-3 py-3 rounded-2 w-100  theme-bg-color-secondary  theme-text-color border-0"
                    id="age_restrict"
                    name="age_restrict"
                    size="lg"
                    onChange={handleInput}
                    value={editUser?.age_restrict}
                  >
                    <option selected value='' className=" theme-bg-color-secondary  theme-text-color">
                      Choose an Age
                    </option>
                    {age_restrictdata?.map((item) => (
                      <option value={item?.id} className=" theme-bg-color-secondary  theme-text-color">{item?.slug}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className=" text-start mt-2">
                <label className="mt-2 theme-text-color">Artists</label>
                <p className="mt-2 theme-text-color opacity-75">Select A Live Stream Artist Below</p>
                <div className="mt-2">
                  <Select
                    options={artists?.map((option) => ({
                      value: option?.id,
                      label: option?.artist_name,
                    }))}
                    className=" theme-bg-color-secondary  theme-text-color"
                    isMulti
                    value={artistData}
                    onChange={handleSelectChangeartists}
                  />
                </div>
              </div>
              <div className="text-start mt-2">
                <label className="mt-2 d-block mandatorySimple position-relative theme-text-color">
                  Category
                </label>
                <p className="mt-2 theme-text-color opacity-75">Select A Live Stream Category Below</p>
                <div className="mt-2">
                  <Select
                    options={category?.map((option) => ({
                      value: option?.id,
                      label: option?.name,
                    }))}
                    className=" theme-bg-color-secondary  theme-text-color"
                    isMulti
                    value={categoryData}
                    onChange={handleSelectChangecategory}
                    ref={categoryInputRef}
                  />
                </div>
                {editUserError?.categoryerror && (<span className="errorred mt-2 d-block">{editUserError?.categoryerror}</span>)}
              </div>
              <div className="text-start mt-2">
                <label className="mt-2 d-block mandatorySimple position-relative theme-text-color">
                  Language
                </label>
                <p className="mt-2 theme-text-color opacity-75">Select A Live Stream Language Below</p>
                <div className="mt-2">
                  <Select
                    options={language?.map((option) => ({
                      value: option?.id,
                      label: option?.name,
                    }))}
                    className=" theme-bg-color-secondary  theme-text-color"
                    isMulti
                    value={languageData}
                    onChange={handleSelectChangelanguage}
                    ref={languageInputRef}
                  />
                </div>
                {editUserError?.languageerror && (<span className="errorred mt-2 d-block">{editUserError?.languageerror}</span>)}
              </div>
              <div className=" text-start mt-2">
                <label className="mt-2 theme-text-color">Block Country</label>
                <p className="mt-2 theme-text-color opacity-75">Select A Live Stream country Below</p>
                <div className="mt-2">
                  <Select
                    options={block_country?.map((option) => ({
                      value: option?.id,
                      label: option?.country_name,
                    }))}
                    className=" theme-bg-color-secondary  theme-text-color"
                    isMulti
                    value={blockCountryData}
                    onChange={handleSelectChangeBlockedCountry}
                  />
                </div>
              </div>

              <div className="text-start mt-2">
                <label className="mt-2 theme-text-color">Available Country</label>
                <p className="mt-2 theme-text-color opacity-75">
                  Block the Audio for Selected Country
                </p>
                <div className="mt-2">
                  <Select
                    options={available_country?.map((option) => ({
                      value: option?.id,
                      label: option?.country_name,
                    }))}
                    className=" theme-bg-color-secondary  theme-text-color"
                    isMulti
                    value={availableCountryData}
                    onChange={handleSelectChangeAvailable_country}
                  />
                </div>
              </div>
            </div>
            <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
              <h6 className="theme-text-color trial-border-bottom  pb-3">SEO</h6>
              <div className="mt-3 text-start">
                <label className="mt-2 theme-text-color">Website Page Title</label>
                <div className=" mt-2">
                  <input
                    type="text"
                    name="website_page_title"
                    className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                    onChange={handleSeoInput}
                  />
                </div>
              </div>
              <div className="mt-2 text-start">
                <label className="mt-2 theme-text-color">Website URL</label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="website_URL"
                    onChange={handleSeoInput}
                    className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                  />
                </div>
              </div>
              <div className="mt-2 text-start">
                <label className="mt-2 theme-text-color">Meta Description</label>
                <div className=" mt-2">
                  <textarea
                    type="text"
                    name="Meta_description"
                    onChange={handleSeoInput}
                    className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
              <h6 className="theme-text-color trial-border-bottom  pb-3">Thumbnails</h6>
              <div className=" text-start mt-2">
                <label className="mt-3 theme-text-color">Live Stream Image Cover</label>
                <p className="mt-2 theme-text-color opacity-75">Select the Live stream image( 16:9 Ratio or 1280X720px )</p>
                <div className="mt-2">
                  <div className="d-flex align-items-center">
                    <div className="col-6 col-sm-6 col-lg-6 p-1">
                      <div className="imagedrop" >
                        <input
                          type="file"
                          ref={coverImageRef}
                          onChange={(event) => handleCoverImageChange(event)}
                          accept="image/*"
                          className="opacity-0"
                          key={coverImageFile ? coverImageFile : "inputKey"} />
                        <button type="button" className="bg-transparent position-absolute">
                          <CameraRetroIcon />
                        </button>
                      </div>
                    </div>
                    <div className="col-6 col-sm-6 col-lg-6 p-1">
                      {coverImageFile && (
                        <div className="imagedropcopy text-end">
                          <div className="d-flex align-items-center">
                            <div className=" col-sm-10 col-lg-10 p-0 m-0">
                              <img
                                src={URL.createObjectURL(coverImageFile)}
                                alt="Uploaded"
                                className="thumbnails-upload-images"
                              />
                              <button onClick={() => setCoverImageFile(null)} type="button" className="bg-transparent" > <MdClose /> </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className=" text-start mt-2">
                <label className="mt-3 theme-text-color">Player Image Cover</label>
                <p className="mt-2 theme-text-color opacity-75">
                  Select the Live stream image( 16:9 Ratio or 1280X720px )
                </p>
                <div className="mt-2">
                  <div className="d-flex align-items-center">
                    <div className="col-6 col-sm-6 col-lg-6 p-1">
                      <div
                        className="imagedrop"
                      >
                        <input
                          type="file"
                          ref={playerImageRef}
                          onChange={(event) => handlePlayerImageChange(event)}
                          accept="image/*"
                          className="opacity-0"
                          key={playerImageFile ? playerImageFile : "inputKey"}
                        />
                        <button type="button" className="bg-transparent position-absolute">
                          <CameraRetroIcon />
                        </button>
                      </div>
                    </div>
                    <div className="col-6 col-sm-6 col-lg-6 p-1">
                      <div>
                        {playerImageFile && (
                          <div className="imagedropcopy text-end">
                            <div className="d-flex align-items-center">
                              <img
                                src={URL.createObjectURL(playerImageFile)}
                                alt="Uploaded"
                                className="thumbnails-upload-images"
                              />
                              <button onClick={() => setPlayerImageFile(null)} type="button" className="bg-transparent" ><MdClose /> </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className=" text-start mt-2">
                <label className="mt-3 theme-text-color">TV Image Cover</label>
                <p className="mt-2 theme-text-color opacity-75">
                  Select the Live stream image( 16:9 Ratio or 1280X720px )
                </p>
                <div className="mt-2">
                  <div className="d-flex align-items-center">
                    <div className="col-6 col-sm-6 col-lg-6 p-1">
                      <div className="imagedrop" >
                        <input
                          type="file"
                          ref={tvImageRef}
                          onChange={(event) => handleTvImageChange(event)}
                          accept="image/*"
                          className="opacity-0 "
                          key={tvImageFile ? tvImageFile : "inputKey"}
                        />
                        <button type="button" className="bg-transparent position-absolute">
                          <CameraRetroIcon />
                        </button>
                      </div>
                    </div>
                    <div className="col-6 col-sm-6 col-lg-6 p-1">
                      <div>
                        {tvImageFile && (
                          <div className="imagedropcopy text-end">
                            <div className="d-flex align-items-center">
                              <img
                                src={URL.createObjectURL(tvImageFile)}
                                alt="Uploaded"
                                className="thumbnails-upload-images"
                              />
                              <button onClick={() => setTvImageFile(null)} type="button" className="bg-transparent"><MdClose /></button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
              <h6 className="theme-text-color trial-border-bottom pb-3">Live Stream Source</h6>
              <div className="mt-3 text-start">
                <p className="mt-2 theme-text-color">Select the Live Stream Source</p>
                <div className="mt-2">
                  <select
                    onChange={handleInput}
                    className="px-3 py-3 rounded-2 w-100  theme-bg-color-secondary  theme-text-color border-0"
                    id="url_type"
                    name="url_type"
                    value={editUser.url_type}
                    onClick={(event) => {
                      setalllivestream_source(event.target.value);
                    }}
                  >
                    <option value="0" className="theme-text-color  theme-bg-color-secondary ">Choose Live Source </option>
                    {livestream_source?.map((item, key) => (
                      <option value={item.value} className="theme-text-color  theme-bg-color-secondary ">{item.name}</option>
                    ))}
                  </select>
                </div>
                {
                  alllivestream_source == "mp4" && <div className="mt-2">
                    <label className="theme-text-color  py-2">Mp4/M3U8 URL</label>
                    <input
                      type="text"
                      name="mp4_url"
                      className="rs-input mt-2 form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                      onChange={(e) => handleInput(e, 'mp4_url')}
                      value={editUser?.mp4_url}
                      placeholder="Mp4_url"
                    />
                  </div>
                }
                {
                  alllivestream_source == 'embed' && <div className="mt-2">
                    <label className="theme-text-color py-2">Embed Url</label>
                    <input
                      type="text"
                      name="embed_url"
                      value={editUser?.embed_url}
                      className="rs-input mt-2 form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                      onChange={(e) => handleInput(e, 'embed_url')} placeholder="Embed_url"
                    />
                  </div>
                }
                {
                  alllivestream_source == 'live_stream_video' &&
                  <div className="mt-2">
                    <label className=" theme-text-color py-2 ">Live Stream Video</label>
                    <input
                      type="file"
                      accept="video/mp4"
                      className="rs-input mt-2 form-control-lg   theme-bg-color-secondary  theme-text-color border-0"
                      onChange={(e) => setLive_stream_video(e.target.files[0])}
                      placeholder="live_stream_video"
                    />
                  </div>
                }
                {
                  alllivestream_source == 'm3u_url' && <div className="mt-2">
                    <label className=" theme-text-color py-2 ">M3u_url</label>
                    <input
                      type="text"
                      name="m3u_url"
                      className="rs-input mt-2 form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                      onChange={(e) => handleInput(e, 'm3u_url')}
                      value={editUser?.m3u_url}
                      placeholder="m3u_url"
                    />
                  </div>
                }
                {
                  alllivestream_source == 'Encode_stream_video' &&
                  <div className="mt-2">
                    <label className=" theme-text-color py-2 ">Encode_stream_video</label>
                    <input
                      type="text"
                      name="encode_stream_video"
                      className="rs-input mt-2 form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                      onChange={(e) => handleInput(e, 'encode_stream_video')}
                      placeholder="Encode_stream_video"
                      value={editUser?.encode_stream_video}
                    />
                  </div>
                }
              </div>
            </div>
            <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
              <h6 className="theme-text-color trial-border-bottom pb-3">ReStream</h6>
              <div className="text-start mt-3">
                <div className="row m-0">
                  <div className="col-9">
                    <label className="mt-2 theme-text-color">Enable ReStream</label>
                  </div>
                  <div className="col-3 text-end">
                    <label className="switch">
                      <input
                        name="featured"
                        checked={enableRestream}
                        onChange={handleCheckbox}
                        type="checkbox"
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
                {editUser?.enable_restream && editUser?.enable_restream == 1 && (
                  <>
                    <div
                      className="accordion mt-3"
                      id="accordionSocialExample"
                    >
                      <div className="accordion-item border border-light">
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className="accordion-button collapsed p-3  theme-bg-color-secondary "
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            <img
                              src={youtube}
                              className="accordionImage"
                              alt="Animated GIF"
                            />
                            <strong className="lh-1 ms-1 theme-text-color">YouTube</strong>
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          className="accordion-collapse collapse "
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionSocialExample"
                        >
                          <div className="accordion-body py-3 px-0  theme-bg-color-secondary ">
                            <div className="row text-start m-0 p-0">
                              <div className="col-12 mb-2">
                                <div className="">
                                  <label className="mb-1 theme-text-color">
                                    YouTube Stream <small>(RTMP URL)</small>
                                  </label>
                                  <input
                                    type="text"
                                    name="youtube_restream_url"
                                    value={editUser?.youtube_restream_url}
                                    className="rs-input form-control-lg theme-bg-color  theme-text-color border-0"
                                    onChange={handleInput}
                                  />
                                </div>
                              </div>
                              <div className="col-12 ">
                                <div className="" id="">
                                  <div>
                                    <label className="mb-1 theme-text-color">
                                      YouTube Stream Key
                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    name="youtube_streamkey"
                                    value={editUser?.youtube_streamkey}
                                    className="rs-input form-control-lg theme-bg-color  theme-text-color border-0"
                                    onChange={handleInput}
                                  // placeholder="youtube_streamkey"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item border border-light">
                        <h2 className="accordion-header" id="headingTwo">
                          <button
                            className="accordion-button collapsed p-3  theme-bg-color-secondary "
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          > <img
                              src={facebook}
                              className="accordionImage"
                              alt="Animated GIF"
                            />
                            <strong className="lh-1 ms-1 theme-text-color"> Facebook</strong>
                          </button>
                        </h2>
                        <div
                          id="collapseTwo"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingTwo"
                          data-bs-parent="#accordionSocialExample"
                        >
                          <div className="accordion-body py-3 px-0  theme-bg-color-secondary  ">
                            <div className="row  text-start p-0 m-0">
                              <div className="col-12 mb-2 ">
                                <div className="">
                                  <label className="mb-1 theme-text-color">
                                    FaceBook Stream{" "}
                                    <small>(RTMP URL)</small>
                                  </label>
                                  <input
                                    type="text"
                                    name="fb_restream_url"
                                    value={editUser?.fb_restream_url}
                                    className="rs-input form-control-lg theme-bg-color  theme-text-color border-0"
                                    onChange={handleInput}
                                  // placeholder="fb_restream_url"
                                  />
                                </div>
                              </div>
                              <div className="col-12 ">
                                <div className="" id="">
                                  <div>
                                    <label className="mb-1 theme-text-color">
                                      FaceBook Stream Key
                                    </label>
                                  </div>{" "}
                                  <input
                                    type="text"
                                    name="fb_streamkey"
                                    className="rs-input form-control-lg mt-2 theme-text-color border-0  theme-bg-color "
                                    onChange={handleInput}
                                  // placeholder="fb_streamkey"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item border border-light">
                        <h2 className="accordion-header" id="headingThree">
                          <button
                            className="accordion-button collapsed p-3  theme-bg-color-secondary "
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            <img
                              src={twitter}
                              className="accordionImage"
                              alt="Animated GIF"
                            />
                            <strong className="lh-1 ms-1 theme-text-color"> Twitter</strong>
                          </button>
                        </h2>
                        <div
                          id="collapseThree"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingThree"
                          data-bs-parent="#accordionSocialExample"
                        >
                          <div className="accordion-body py-3 px-0  theme-bg-color-secondary ">
                            <div className="row text-start m-0 p-0">
                              <div className="col-12 mb-2">
                                <div className="">
                                  <label className="mb-1 theme-text-color">
                                    Twitter Stream <small>(RTMP URL)</small>
                                  </label>
                                  <input
                                    type="text"
                                    name="twitter_restream_url"
                                    value={editUser?.twitter_restream_url}
                                    className="rs-input form-control-lg theme-bg-color  theme-text-color border-0"
                                    onChange={handleInput}
                                  // placeholder="twitter_restream_url"
                                  />
                                </div>
                              </div>

                              <div className="col-12">
                                <div className="mt-2">
                                  <label className="mb-1 theme-text-color">
                                    {" "}
                                    Twitter Stream Key{" "}
                                  </label>

                                  <input
                                    type="text"
                                    name="twitter_streamkey"
                                    className="rs-input form-control-lg theme-bg-color  theme-text-color border-0"
                                    onChange={handleInput}
                                    value={editUser?.twitter_streamkey}
                                  // placeholder="twitter_streamkey"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
              <h6 className="theme-text-color trial-border-bottom pb-3">Visibility</h6>
              <div className="text-start mt-3">
                <label className="mt-2 theme-text-color">Publish Type</label>
                <div className="mt-2">
                  <input
                    type="radio"
                    id="publish_now"
                    name="publish_type"
                    value="publish_now"
                    checked={editUser?.publish_type == "publish_now"}
                    onChange={handlePublishTypeChange}
                    className=" theme-bg-color-secondary  theme-text-color border-0 "
                  />
                  <label htmlFor="publish_now" className="mt-2 ms-1 theme-text-color">
                    Publish Now
                  </label>
                </div>
                <div className="mt-2">
                  <input
                    type="radio"
                    id="publish_later"
                    name="publish_type"
                    value="publish_later"
                    checked={editUser?.publish_type == "publish_later"}
                    onChange={handlePublishTypeChange}
                  />
                  <label htmlFor="publish_later" className="mt-2 ms-1">
                    Publish Later
                  </label>
                  {editUser?.publish_type == "publish_later" && (
                    <div className="mb-3">
                      <label className="mt-3 theme-text-color d-block">Publish Time</label>
                      <input
                        type="datetime-local"
                        className="mt-2 w-100 theme-text-color  theme-bg-color-secondary  border-0 p-2 rounded-2"
                        id="publish_time"
                        name="publish_time"
                        value={editUser?.publish_time}
                        onChange={handlePublishTimeChange}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
              <h6 className="theme-text-color trial-border-bottom pb-3">Access</h6>
              <div className="mt-3">
                <label className="mt-2 theme-text-color">User Access</label>
                <p className="mt-2 theme-text-color opacity-75">
                  Who Is Allowed To View This Live Stream ?
                </p>
                <select
                  onChange={handleAccessVideo}
                  className="px-3 py-3 mt-2 rounded-2 w-100  theme-bg-color-secondary  theme-text-color border-0"
                  id="access"
                  name="access"
                  onClick={(event) => {
                    setallliveuseraccess(event.target.value);
                  }}
                >
                  {user_access?.map((item, key) => (
                    <option value={item?.value} className="theme-text-color">{item?.name}</option>
                  ))}
                </select>
              </div>

              <div>{alllive_streamuseraccess()}</div>
            </div>
            <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
              <h6 className="theme-text-color trial-border-bottom pb-3">Status Settings</h6>
              <div className="d-flex align-items-center justify-content-between mt-3">
                <p className="theme-text-color">Is this Live stream Featured</p>
                <label className="switch">
                  <input
                    name="featured"
                    onChange={handleInput}
                    className="rs-input"
                    value={editUser?.featured}
                    defaultChecked={
                      editUser?.featured == 1 ? true : false
                    }
                    checked={editUser?.featured == 1 ? true : false}
                    type="checkbox"
                  />
                  <span
                    className="slider round"
                    name="featured"
                    onChange={handleInput}

                  // value={editUser?.featured == 1 ? "1" : "0"}
                  ></span>
                </label>
              </div>
              <div className="d-flex align-items-center justify-content-between mt-3">
                <p className="theme-text-color">Is this Live stream Active</p>
                <label className="switch">
                  <input
                    name="active"
                    onChange={handleInput}
                    defaultChecked={editUser?.active == 1 ? true : false}
                    checked={editUser?.active == 1 ? true : false}
                    className="rs-input"
                    type="checkbox"
                  />
                  <span
                    className="slider round"
                    name="active"
                    onChange={handleInput}

                  ></span>
                </label>
              </div>
              <div className="d-flex align-items-center justify-content-between mt-3">

                <p className="theme-text-color">
                  Is this Live stream display in Banner
                </p>


                <label className="switch">
                  <input
                    name="banner"
                    onChange={handleInput}
                    className="r-input"
                    defaultChecked={editUser?.banner == 1 ? true : false}
                    checked={editUser?.banner == 1 ? true : false}
                    type="checkbox"
                  />
                  <span
                    className="slider round"
                    name="banner"
                    onChange={handleInput}
                  // value={editUser?.banner == 1 ? "1" : "0"}
                  ></span>
                </label>
              </div>
              <div className="d-flex align-items-center justify-content-between mt-3">

                <p className="theme-text-color">
                  Is this Live stream in Enable Chats
                </p>


                <label className="switch">
                  <input
                    name="enable_chats"
                    onChange={handleInput}
                    className="r-input"
                    defaultChecked={
                      editUser?.enable_chats == 1 ? true : false
                    }
                    checked={
                      editUser?.enable_chats == 1 ? true : false
                    }
                    type="checkbox"
                  />
                  <span
                    className="slider round"
                    name="enable_chats"
                    onChange={handleInput}
                  // value={editUser?.enable_chats == 1 ? "1" : "0"}
                  ></span>
                </label>
              </div>
            </div>

            <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
              <h6 className="theme-text-color trial-border-bottom pb-3">Live Event Artist</h6>
              <div className="d-flex align-items-center justify-content-between mt-2">
                <p className="theme-text-color">Is this Live stream in Enable Tips</p>
                <label className="switch">
                  <input
                    name="enable_Tips"
                    onChange={handleInput}
                    className="r-input"
                    defaultChecked={
                      editUser?.enable_Tips == 1 ? true : false
                    }
                    checked={
                      editUser?.enable_Tips == 1 ? true : false
                    }
                    type="checkbox"
                  />
                  <span
                    className="slider round"
                    name="enable_Tips"
                    onChange={handleInput}
                  // value={editUser?.enable_Tips == 1 ? "1" : "0"}
                  ></span>
                </label>
              </div>

              <div className="mt-2">

                <label className="mt-2 theme-text-color">Donations</label>

                <div className="mt-2">
                  <input
                    type="number"
                    name="donations_label"
                    id="donations_label"
                    value={editUser?.donations_label}
                    className="rs-input form-control-lg theme-text-color  theme-bg-color-secondary  border-0"
                    onChange={handleDonate}
                    placeholder=""
                  />
                </div>
              </div>
            </div>
            <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
              <h6 className="theme-text-color trial-border-bottom pb-3">Search Tags</h6>
              <div className="mt-2">
                <p className="theme-text-color mt-2">You don't have any search keywords.</p>
                <div className="mt-2">
                  <TagsInput
                    value={editUser?.search_tags}
                    className="rs-input theme-text-color  theme-bg-color-secondary  border-0"
                    onChange={handleTagsChange}
                    name="search_tags"
                  />
                </div>
              </div>
            </div>
          </div>


          <div className="text-end mb-3">
            <button onClick={handleLiveSubmit} className="btn btn-primary">
              Save LiveStream
            </button>
          </div>
        </div>
      </section >
    </>
  );
};
export default Contentaaddlive;