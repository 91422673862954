import React from 'react'
import { IconMyCredit, IconMyDevice, IconMyFile, IconMyLogOut, IconMyMember, IconMyProfile, IconMyUsers } from '../../Images/MyIcons';
import { NavLink } from 'react-router-dom';
import MessageBox from '../Others/MessageBox';
const AccountSideBar = ({ lang, role }) => {
    return (

        role ? <div className='text-center list-unstyled m-0 profilePageThemeTwo'>
            <NavLink to={`/${lang?.Language_Code}/myprofile/info`} className='d-flex align-items-center gap-3 profileThemeTwo text-decoration-none position-relative rounded-2 mb-3' ><IconMyFile styled={{ width: "20px", height: "20px", opacity: "0.5" }} /><MessageBox classname='popupDetailLink position-relative ' text='Account Info'></MessageBox></ NavLink>
            <NavLink to={`/${lang?.Language_Code}/myprofile/activeplan`} className='d-flex align-items-center gap-3 profileThemeTwo text-decoration-none position-relative rounded-2 mb-3'><IconMyCredit styled={{ width: "20px", height: "20px", opacity: "0.5" }} /><MessageBox classname='popupDetailLink position-relative ' text='Plan Details' ></MessageBox></NavLink>
            <NavLink to={`/${lang?.Language_Code}/myprofile/activedevice`} className='d-flex align-items-center gap-3 profileThemeTwo text-decoration-none position-relative rounded-2 mb-3'><IconMyDevice styled={{ width: "20px", height: "20px", opacity: "0.5" }} /><MessageBox classname='popupDetailLink position-relative ' text='Device Management'></MessageBox></NavLink>
            <NavLink to={`/${lang?.Language_Code}/myprofile/profiles`} className='d-flex align-items-center gap-3 profileThemeTwo text-decoration-none position-relative rounded-2 mb-3'><IconMyUsers styled={{ width: "20px", height: "20px", opacity: "0.5" }} /><MessageBox classname='popupDetailLink position-relative ' text='User Profiles'></MessageBox></NavLink>
            <NavLink to={`/${lang?.Language_Code}/myprofile/billing`} className='d-flex align-items-center gap-3 profileThemeTwo text-decoration-none position-relative rounded-2 mb-3'><IconMyMember styled={{ width: "20px", height: "20px", opacity: "0.5" }} /><MessageBox classname='popupDetailLink position-relative ' text='Membership & Billing'></MessageBox></NavLink>
            <NavLink to={`/${lang?.Language_Code}/myprofile/parentalcontrol`} className='d-flex align-items-center gap-3 profileThemeTwo text-decoration-none position-relative rounded-2 mb-3'><IconMyProfile styled={{ width: "20px", height: "20px", opacity: "0.5" }} /><MessageBox classname='popupDetailLink position-relative ' text='Profile & Parental Control'></MessageBox></NavLink>
            {/* <div className='d-flex align-items-center gap-3 profileThemeTwo text-decoration-none position-relative rounded-2 mb-3' onClick={handleLogout}><IconMyLogOut styled={{ width: "20px", height: "20px", opacity: "0.5" }} /><MessageBox to={`/`} classname='popupDetailLink position-relative ' text='Log out'></MessageBox></div> */}
        </div > : null


    )
}

export default AccountSideBar