import React, { useState } from "react";
import "./ChannelHome.css";
import { Link } from "react-router-dom";
import img1 from '../../components/Images/subscribe-slide/edqq56fq 1.png'
import img2 from '../../components/Images/subscribe-slide/image2.png'
import img3 from '../../components/Images/subscribe-slide/kjfck83o 1.png'
import Menu from './Menu'
import { useTranslation } from "react-i18next";


const ChannelHome = () => {

  const { t } = useTranslation();

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const videos = [
    { image: img1, title: 'video1', description: "Started 10 min ago" },
    { image: img2, title: 'video1', description: "Started 10 min ago" },
    { image: img3, title: 'video1', description: "Started 10 min ago" },
    { image: img1, title: 'video1', description: "Started 10 min ago" },
    { image: img2, title: 'video1', description: "Started 10 min ago" },
    { image: img3, title: 'video1', description: "Started 10 min ago" },
  ]

  const [activeTab, setActiveTab] = useState('home');

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <>
      <div id="channelhome" className="paddingTop">
        <div className="banner-image">
          <div className="main-image">
            <img src={img1} alt="banner" />
          </div>
          <div className="container-fluid">
            <div className="position-relative">
              <div className="channel-image">
                <img src={img1} alt="channelImage" width="150" />
              </div>
            </div>
          </div>
        </div>

        <div className="mt-5 mb-5">
          <div className="container-fluid">
            <div className="row">
              <div className="col-4 col-lg-4">
                <ul className="list-inline p-0 mt-4 share-icons music-play-lists">
                  <li className="share">
                    <span>
                      <i className="fa fa-share-alt" aria-hidden="true"></i>
                    </span>
                    <div className="share-box">
                      <div className="d-flex align-items-center justify-content-between">
                        <a to="" className="share-ico">
                          <i className="fa fa-facebook" aria-hidden="true"></i>
                        </a>
                        <a to="" className="share-ico">
                          <i className="fa fa-twitter" aria-hidden="true"></i>
                        </a>
                        <a to="" className="share-ico">
                          <i className="fa fa-link" aria-hidden="true"></i>
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-4 col-lg-4 videomodel">
                <a data-video="https://dev.nemisatv.co.za/public/uploads/channel/9JBrpwTtJKCX5bix.mp4" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                  <span className="text-white">
                    <i className="fa fa-play" aria-hidden="true"></i>{t("About Channel Partner")}
                  </span>
                </a>
                <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                  <div className="modal-dialog" style={{ maxWidth: "800px" }}>
                    <div className="modal-content" style={{ backgroundColor: "transparent", border: "none" }}>

                      <button type="button" className="close" style={{ color: "red" }} data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                      <div className="modal-body">
                        ...
                      </div>
                      <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{t("Close")}</button>
                        <button type="button" className="btn btn-primary">{t("Understood")}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="channel_nav">
          <div className="container-fluid">
            <ul className="nav nav-tabs container-fluid" id="myTab" role="tablist">
              <li className={`nav-item Allnav ${activeTab == 'home' && 'active'}`}>
                <a
                  className="nav-link"
                  onClick={() => handleTabClick('home')}
                  href="#home"
                  role="tab"
                  aria-controls="home"
                  aria-selected={activeTab == 'home'}
                >
                  All
                </a>
              </li>
              <li className={`nav-item videonav ${activeTab == 'profile' && 'active'}`}>
                <a
                  className="nav-link"
                  onClick={() => handleTabClick('profile')}
                  href="#profile"
                  role="tab"
                  aria-controls="profile"
                  aria-selected={activeTab == 'profile'}
                >
                  Videos
                </a>
              </li>
              <li className={`nav-item livenav ${activeTab == 'live' && 'active'}`}>
                <a
                  className="nav-link"
                  onClick={() => handleTabClick('live')}
                  href="#live"
                  role="tab"
                  aria-controls="live"
                  aria-selected={activeTab == 'live'}
                >
                  Live Stream
                </a>
              </li>
              <li className={`nav-item seriesnav ${activeTab == 'contact' && 'active'}`}>
                <a
                  className="nav-link"
                  onClick={() => handleTabClick('contact')}
                  href="#contact"
                  role="tab"
                  aria-controls="contact"
                  aria-selected={activeTab == 'contact'}
                >
                  Series
                </a>
              </li>
              <li className={`nav-item audionav ${activeTab == 'Audios' && 'active'}`}>
                <a
                  className="nav-link"
                  onClick={() => handleTabClick('Audios')}
                  href="#Audios"
                  role="tab"
                  aria-controls="Audios"
                  aria-selected={activeTab == 'Audios'}
                >
                  Audios
                </a>
              </li>
            </ul>


            <div className="hrtag">
              <hr />
            </div>

            <div className="tab-content container-fluid" id="myTabContent">
              <div className="tab-content sub-categories-contents">
                <div className={`tab-pane fade ${activeTab == 'home' && 'show active'}`} id="home" role="tabpanel">
                  {/* Content for All tab */}
                </div>
                <div className={` d-flex tab-pane fade ${activeTab == 'profile' && 'show active'}`} id="profile" role="tabpanel">
                  {/* Content for Videos tab */}
                  <a
                    className="nav-link"
                    href="#"
                    role="tab"
                    aria-controls="Gaming"
                    aria-selected={activeTab == 'Gaming'}
                  >
                    Gaming
                  </a>
                  <a
                    className="nav-link"
                    href="#"
                    role="tab"
                    aria-controls="Sports"
                    aria-selected={activeTab == 'Sports'}
                  >
                    Sports
                  </a>
                  <a
                    className="nav-link"
                    href="#"
                    role="tab"
                    aria-controls="Movies"
                    aria-selected={activeTab == 'Movies'}
                  >
                    Movies
                  </a>
                </div>
                <div className={`tab-pane d-flex fade ${activeTab == 'live' && 'show active'}`} id="live" role="tabpanel">
                  {/* Content for Live Stream tab */}
                  <a
                    className="nav-link"
                    href="#"
                    role="tab"
                    aria-controls="LiveTV"
                    aria-selected={activeTab == 'LiveTV'}
                  >
                    Live TV
                  </a>
                  <a
                    className="nav-link"
                    href="#"
                    role="tab"
                    aria-controls="Radio"
                    aria-selected={activeTab == 'Radio'}
                  >
                    Live Radio
                  </a>
                </div>
                <div className={`tab-pane d-flex fade ${activeTab == 'contact' && 'show active'}`} id="contact" role="tabpanel">
                  {/* Content for Series tab */}
                  <a
                    className="nav-link"
                    href="#"
                    role="tab"
                    aria-controls="Gaming"
                    aria-selected={activeTab == 'Gaming'}
                  >
                    Live TV
                  </a>
                  <a
                    className="nav-link"
                    href="#"
                    role="tab"
                    aria-controls="Sports"
                    aria-selected={activeTab == 'Sports'}
                  >
                    Live Radio
                  </a>
                </div>
                <div className={`tab-pane d-flex fade ${activeTab == 'Audios' && 'show active'}`} id="Audios" role="tabpanel">
                  {/* Content for Audios tab */}
                </div>
              </div>

            </div>
          </div>
        </section>

        {/* <Menu /> */}
        {/* <div className="channel_home container-fluid mt-4">
          <section id="iq-favorites">
            <div className="container-fluid overflow-hidden">
              <div className="row">
                <div className="col-lg-12">
                  <div className="iq-main-header d-flex align-items-center justify-content-between">
                    <h4 className="main-title">
                      <a href=" ">Latest Videos</a>
                    </h4>
                    <a className="seeall" href="">
                      See all
                    </a>
                  </div>
                  <div className="favorites-contens">
                    <ul className="favorites-slider list-inline row p-0 mb-0 slick-initialized slick-slider">
                    <Slider {...settings}>
                        {videos?.map((image, index) => (
                                <div className="image-container" key={index}>
                                    <img src={image?.image} alt="Image"  />
                                    <div className="overlay">
                                        <div className="hover-card">
                                            <div className="hover-image">
                                                <img src={image?.image} alt="image" />
                                            </div>
                                            <div className="content">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="d-flex align-items-center justify-content-between watch-add">
                                                            <span className="col-10" style={{paddingRight:'5px'}}>
                                                                <button >Watch Now</button>
                                                            </span>
                                                            <span className="col-2">
                                                                <button >
                                                                <i className="fa fa-plus" aria-hidden="true"></i>
                                                                </button>
                                                            </span>

                                                        </div>
                                                        <ul className="d-flex justify-content-between p-0 mt-2 year-season">
                                                            <span>2023</span>
                                                            <li>Season 1</li>
                                                            <li>2 Languages</li>
                                                        </ul>
                                                        <span className="hover-description">
                                                            <p>This three-part series and produces a compelling.</p>
                                                        </span>

                                                    </div>

                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                ))}
                    </Slider>

                    </ul>

                  </div>
                </div>
              </div>
            </div>
          </section>
         
        </div> */}
      </div>
    </>
  );
};

export default ChannelHome;
