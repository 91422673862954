import React from 'react';
import { Link } from 'react-router-dom';
import MessageBox from '../../../components/Others/MessageBox';

const AdvertiserFooter = () => {
  return (
    <div className='d-flex flex-wrap'>
      <div className="col-6 px-4 py-3">
        <div className="d-flex align-items-center justify-content-start gap-3">
          <Link><MessageBox classname='copyrights1Link' text='Privacy Policy' /></Link>
          <Link><MessageBox classname='copyrights1Link' text='Terms and Conditions' /></Link>
        </div>
      </div>
      <div className="col-6 px-4 py-3">
        <div className='d-flex align-items-center justify-content-end'>
          <div className='d-flex'>
            <MessageBox classname='d-block theme-text-color' text='Copyright 2024' />
            <Link to={`${process.env.REACT_APP_API_PATHss}`} target="_blank" className="wesite ms-1"> Webnexs</Link>
          </div>
          <div>
            <MessageBox classname='d-block theme-text-color' text='All Rights Reserved' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdvertiserFooter