// External Libraries and Packages
import React, { useEffect} from "react";
import { connect, useDispatch } from "react-redux";
import {useLocation, useParams } from "react-router-dom";
import './viewAll.css';
import { viewAllPageAction } from "../../../Redux/Actions/apiActions";
import {URLGenerater,} from "../../../Pages/CommonMethods/CommonMethods";
import PageError from "../../Others/PageError";
import PageLoader from "../../Others/PageLoader";
import LayoutViewAll from "./LayoutViewAll";

const SeriesViewAll = ({homepage,viewAllData,isLoading,error,settings}) => {
  const { source } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const user_role = localStorage.getItem("role");
  const user_id = localStorage.getItem("user_id");
  const access_token = localStorage.getItem("access_token");
  const headers = {
    "Content-Type": "application/json",
    headers: { "Access-Control-Allow-Origin": "*" },
    Authorization: "Bearer " + access_token,
  };
  useEffect(() => {
    const videoDataList = homepage.find(
      (item) => URLGenerater(item?.source) === source
    );
    if (videoDataList) {
      const requestApi = videoDataList.Redirection_PageList_URL;
      if (settings && Object.keys(settings).length > 0) {
        !user_role
          ? dispatch(viewAllPageAction(null, 9, "web", headers, requestApi))
          : dispatch(
            viewAllPageAction(user_id, user_role, "web", headers, requestApi)
          );
      }
    } else {
      console.log(`Object with source '${source}' not found.`);
    }
  }, [settings, user_role, homepage, user_id, location]);

  return ( error ? <PageError /> : isLoading ? <PageLoader /> : <LayoutViewAll mapData={viewAllData?.data} source={source} path='shows'headingClass='pb-4 text-center'/>);
};
const mapStateToProps = (state) => ({
  homepage: state.get_allHomePage_Reducer.homepage,
  viewAllData: state.get_viewall_Reducer.viewAllData,
  isLoading: state.get_viewall_Reducer.isLoading,
  error: state.get_viewall_Reducer.error,
  settings: state.get_setting_Reducer.settings,
});
export default connect(mapStateToProps)(SeriesViewAll);