import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import { toast } from "react-toastify";
 
import loadings from "../../../components/Images/loading/loading.gif";
import ResponseLoader from "../../../components/Others/ResponseLoader";

function Content_NewArtist() {
  const [description, setDescription] = useState("");
  const [artisttype, setArtisttype] = useState([]);

  const [editUser, setEditUser] = useState({
    title: "",
    slug: "",
    artist_type: "",
    artist_email: "",
  });

  const navigate = useNavigate();

  // let access_token, access_token_cpp;

  // if (localStorage.getItem("username")) {
  //   access_token = localStorage.getItem("access_token");
  // } else {
  //   access_token_cpp = localStorage.getItem("access_token_cpp");
  // }

  // const headers = {
  //   "Content-Type": "application/json",
  //   // Authorization: "Bearer " + access_token_cpp,
  //   Accept: "application/json",
  //   "Access-Control-Allow-Origin": "*",
  // };

  // if (access_token) {
  //   headers.Authorization = "Bearer " + access_token;
  // } else if (access_token_cpp) {
  //   headers.Authorization = "Bearer " + access_token_cpp;
  // }

  const access_token_cpp = localStorage.getItem("access_token_cpp");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token_cpp,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };


  const fileInputRef1 = useRef(null);

  const [selectedFile1, setSelectedFile1] = useState(null);

  const handleFileChange = (event, fileInputRef, setSelectedFile) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDelete = (setSelectedFile1) => {
    setSelectedFile1(null);
    if (fileInputRef1.current) {
      fileInputRef1.current.value = null;
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessageartist_type("");
    setIsValidationHiddenartist_type(true);
    setValidationMessagetitle("");
    setIsValidationHiddentitle(true);
  };

  const [autoFillValuetitle, setAutoFillValuetitle] = useState("");

  const handleInputKeyDowntitle = (event) => {
    if (event.key === "Enter" || event.key === "Tab") {
      generateSlug(editUser?.title);
    }
  };

  const handleAutoFillChangetitle = (event) => {
    setAutoFillValuetitle(event.target.value);
  };

  const handleBlurtitle = () => {
    generateSlug(editUser?.title);
  };

  const generateSlug = (title) => {
    title = title.trim();
    const slug = title.toLowerCase().replace(/\s+/g, "-");
    setAutoFillValuetitle(slug);
  };

  const [validationMessagetitle, setValidationMessagetitle] = useState("");
  const [isValidationHiddentitle, setIsValidationHiddentitle] = useState(true);

  const [validationMessageartist_email, setValidationMessageartist_email] =
    useState("");
  const [isValidationHiddenartist_email, setIsValidationHiddenartist_email] =
    useState(true);

  const [validationMessageartist_type, setValidationMessageartist_type] =
    useState("");
  const [isValidationHiddenartist_type, setIsValidationHiddenartist_type] =
    useState(true);

  const titleInputRef = useRef(null);
  const artist_emailInputRef = useRef(null);
  const artist_typeInputRef = useRef(null);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  async function artist() {
    let focusInputRef = null;

    if (editUser?.title === "") {
      setValidationMessagetitle("Name cannot be empty.");
      setIsValidationHiddentitle(false);
      focusInputRef = titleInputRef;
    }
    if (editUser?.artist_email === "") {
      setValidationMessageartist_email("Artist Email cannot be empty.");
      setIsValidationHiddenartist_email(false);
      focusInputRef = artist_emailInputRef;
    }
    if (editUser?.artist_type === "") {
      setValidationMessageartist_type("Artist Type cannot be empty.");
      setIsValidationHiddenartist_type(false);
      focusInputRef = artist_typeInputRef;
    }

    if (focusInputRef) {
      focusInputRef?.current?.focus();
      return;
    }

    const formData = new FormData();

    // formData.append("user_id", user_id);
    formData.append("artist_name", editUser?.title);
    formData.append("artist_email", editUser?.artist_email);
    formData.append("artist_slug", autoFillValuetitle);
    formData.append("description", description);
    formData.append("artist_type", editUser?.artist_type);

    let firstApiStatus = false;
    let secondApiStatus = false;
    let resultapi = null;
    let resultapifalse = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/artist/store`,
        formData,
        { headers: headers }
      );

      if (response?.data?.status === true) {
        firstApiStatus = true;

        resultapi = response?.data;

        var result = response?.data?.artist.id;

        const imageFormData = new FormData();
        imageFormData.append("artist_id", result);

        if (selectedFile1) {
          imageFormData.append("image", selectedFile1);
        }

        const imageResponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/Image-upload-artist`,
          imageFormData,
          { headers: headers }
        );

        if (imageResponse?.data?.status === true) {
          secondApiStatus = true;
        }
      } else if (response?.data?.status === false) {
        // Handle the case when the first API response status is false
        resultapifalse = response?.data;
      }
    } catch (error) { }

    if (firstApiStatus && secondApiStatus) {
      setValidationMessagetitle("");
      setValidationMessageartist_type("");
      // props.setApiresponsealert(resultapi);
      // props.setShowalert(true);
      toast.success(resultapi?.message);
      setTimeout(() => {
        navigate("/all-artist");
      }, 3000);
    } else if (resultapifalse) {
      // props.setApiresponsealert(resultapifalse);
      // props.setShowalert(true);
      toast.error(resultapifalse?.message);
      setProcessing(false);
      setShowOverlay(false);
      setIsValidationHiddentitle(false);
      setValidationMessageartist_type(false);
    }
  }

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/artist/create`, {
        headers: headers,
      })
      .then((response) => {
        var result = response?.data?.artist_type;
        setArtisttype(result);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <>
      <div className=" m-0 p-0">
        <section className="container-fluid  theme-bg-color-secondary ">
          <div className="col-lg-12 row py-3">
            <div className="col-6 col-sm-6 col-lg-6">
              <h3 className=" theme-text-color">Add Artists</h3>
            </div>
            <div className="col-6 col-sm-6 col-lg-6">
              <div className="text-end">
                <button onClick={artist} className="btn btn-primary">
                  Save Artists
                </button>
              </div>
            </div>
          </div>
          <div>
            {showOverlay && (<ResponseLoader />)}
          </div>

          <div className="row col-lg-12 ">
            <div className=" col-sm-6 col-md-6 col-lg-6 ">
              <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                <h5 className=" theme-text-color">Create Artist</h5>
                <hr className=" theme-text-color"></hr>
                <div className=" text-start">
                  <label className="m-0  theme-text-color">
                    Artist Name<span className="mandatory">*</span>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                      id="title"
                      name="title"
                      placeholder=""
                      onChange={handleInput}
                      value={editUser?.title}
                      onKeyDown={handleInputKeyDowntitle}
                      onBlur={handleBlurtitle} // Add onBlur event handler
                      ref={titleInputRef}
                    />
                  </div>
                  <span className="errorred">
                    {!isValidationHiddentitle && (
                      <p>{validationMessagetitle}</p>
                    )}
                  </span>
                </div>

                <div className="mt-2 text-start">
                  <label className="m-0 theme-text-color">Artist Slug</label>

                  <div className="mt-2">
                    <input
                      type="text"
                      className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                      id="artist_slug"
                      name="artist_slug"
                      placeholder=""
                      value={autoFillValuetitle}
                      onChange={handleAutoFillChangetitle}
                    />
                  </div>
                </div>

                <div className=" text-start mt-2">
                  <label className="m-0 theme-text-color">
                    Artist Email<span className="mandatory">*</span>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                      id="artist_email"
                      name="artist_email"
                      placeholder=""
                      onChange={handleInput}
                      value={editUser?.artist_email}
                      ref={artist_emailInputRef}
                    />
                  </div>
                  <span className="errorred">
                    {!isValidationHiddenartist_email && (
                      <p>{validationMessageartist_email}</p>
                    )}
                  </span>
                </div>

                <div className="row mt-2 text-start">
                  <div className="col-12">
                    <label className="m-0 theme-text-color">
                      Artist Type<span className="mandatory">*</span>
                    </label>

                    <div className="mt-2">
                      <select
                        className="form-select  theme-bg-color-secondary  border-0 theme-text-color"
                        name="artist_type"
                        id="artist_type"
                        value={editUser?.artist_type}
                        ref={artist_typeInputRef}
                        onChange={handleInput}
                      >
                        <option value="0">Select the Artist Type</option>
                        {artisttype?.map((item) => (
                          <option key={item?.value} value={item?.value}>
                            {item?.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <span className="errorred">
                      {!isValidationHiddenartist_type && (
                        <p>{validationMessageartist_type}</p>
                      )}
                    </span>
                  </div>
                </div>
              </div>

              <div className="text-start mb-3">
                <button onClick={artist} className="btn btn-primary">
                  Save Artists
                </button>
              </div>
            </div>

            {/* Second Part of Section  */}

            <div className=" col-sm-6 col-md-6 col-lg-6">
              <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                <h5 className=" theme-text-color">Thumbnails</h5>
                <hr className=" theme-text-color"></hr>

                <div className=" text-start mt-2">
                  <label className="m-0 theme-text-color">Cover Image</label>

                  <div className="mt-2">
                    <div className="col-lg-12 row">
                      <div className=" col-sm-6 col-lg-6">
                        <div
                          className="imagedrop"
                          onClick={() => fileInputRef1.current.click()}
                        >
                          <input
                            type="file"
                            ref={fileInputRef1}
                            onChange={(event) =>
                              handleFileChange(
                                event,
                                fileInputRef1,
                                setSelectedFile1
                              )
                            }
                            accept="image/*"
                            style={{ display: "none" }}
                          />
                          <label>
                            <CameraRetroIcon />
                          </label>
                        </div>
                      </div>
                      <div className=" col-sm-6 col-lg-6">
                        <div>
                          {selectedFile1 && (
                            <div className="imagedropcopy text-end">
                              <div className="col-lg-12 row p-0 m-0">
                                <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                  <img
                                    src={URL.createObjectURL(selectedFile1)}
                                    alt="Uploaded"
                                    className="thumbnails-upload-images"
                                  />
                                </div>
                                <div
                                  className="col-sm-2 col-lg-2 p-0 m-0"
                                  style={{ alignSelf: "center" }}
                                >
                                  <button
                                    onClick={() =>
                                      handleDelete(setSelectedFile1)
                                    }
                                    type="button"
                                    className="btn-close theme-text-color"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                <h5 className=" theme-text-color">Descriptions</h5>
                <hr className=" theme-text-color"></hr>

                <div className="text-start">
                  <label className="m-0 theme-text-color">Artist Description</label>

                  <div className="mt-2">
                    <div className="col-lg-12">
                      <div className="panel-body">
                        <textarea
                          className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                          onChange={(e) => setDescription(e.target.value)}
                          placeholder="Artist Description"
                          name="description"
                          id="description"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>


      </div>

    </>
  );
}
export default Content_NewArtist;
