import axios from "axios";
import { artistPageList, musicAlbum, musicArtist, musicDetails, musicGenrePlay, musicHomePageApi, musicPlaylist, musicQueue, musicStationPlay, musicianPageList, writerPageList } from "../../components/Api/MusicApi";
import { getItem, getItemToken } from "../../Utils/localStorageUtils";
const apiGenerate = (type, chooseobject) => {

    let resultObject;
    switch (type) {
        case 'AUDIO':
            resultObject = {
                slug: "audio_slug",
                details: "audios_details",
                playlistdetails: "audios",
                artists: "TopArtist",
                api: musicDetails,
                playapi: musicQueue
            };
            break;

        case 'ARTIST':
            resultObject = {
                slug: "artist_slug",
                details: "artist_details",
                playlistdetails: "artist_audios",
                artists: "TopArtist",
                api: musicArtist,
                playapi: musicArtist,
                pagelistApi: artistPageList

            };
            break;

        case 'PLAYLIST':
            resultObject = {
                slug: "playlist_slug",
                details: "playlist",
                playlistdetails: "data",
                artists: "TopArtist",
                api: musicPlaylist,
                playapi: musicPlaylist
            };
            break;

        case 'ALBUM':
            resultObject = {
                slug: "album_slug",
                details: "album_details",
                playlistdetails: "album_audios",
                artists: "TopAudioAlbum",
                api: musicAlbum,
                playapi: musicAlbum
            };
            break;
        case 'STATION':
            resultObject = {
                slug: "station_slug",
                details: "MusicStation",
                playlistdetails: "data",
                artists: "",
                api: musicStationPlay,
                playapi: musicStationPlay
            };
            break;


        case 'WRITER':
            resultObject = {
                slug: "writer_slug",
                pagelistApi: writerPageList
            };
            break;
        case 'CATEGORY':
            resultObject = {
                slug: "category_slug",
            };
            break;
        case 'MUSICIAN':
            resultObject = {
                slug: "musician_slug",
                pagelistApi: musicianPageList
            };
            break;

        case 'LANGUAGE':
            resultObject = {
                slug: "language_slug",
            };
            break;
        case 'GENRE':
            resultObject = {
                slug: "audio_genre_slug",
                details: "AudioGenre",
                playlistdetails: "category__audios",
                artists: "",
                api: musicGenrePlay,
                playapi: musicGenrePlay
            };
            break;
        default:
            throw new Error('Invalid type');
    }
    return resultObject[chooseobject];
}

const getMusicHomePageAction = () => async (dispatch) => {
    try {
        const response = await axios.post(`${musicHomePageApi}`, { Login_user_type: parseInt(getItem('role'), 10) || 9, Login_device_type: "web", Login_user_id: parseInt(getItem('user_id'), 10) || null }, { headers: getItemToken('access_token') || null })
        const ListRes = response?.data?.Home_page;
        dispatch({ type: "GET_MUSIC_HOMEPAGE", payload: ListRes });

    }
    catch (error) {
        dispatch({ type: "GET_MUSIC_HOMEPAGE_FAILED", payload: error });
    };
}

const MusicViewAllPageAction = (type, api, slug) => async (dispatch) => {
    try {
        const response = await axios.post(`${api}`, {
            Login_user_type: parseInt(getItem('role'), 10) || 9,
            Login_device_type: "web",
            Login_user_id: parseInt(getItem('user_id'), 10) || null,
            [apiGenerate(type, "slug")]: slug,
        }, { headers: getItemToken('access_token') || null })
        const ListRes = response?.data?.data
        dispatch({ type: "GET_MUSIC_VIEWALLPAGE_ACTION", payload: ListRes });
    }
    catch (error) {
        dispatch({ type: "GET_MUSIC_VIEWALLPAGE_FAILED", payload: error });
    };
}

const playQueueAction = (type, slug) => async (dispatch) => {
    try {
        const response = await axios.post(`${apiGenerate(type, 'playapi')}`, {
            Login_user_type: parseInt(getItem('role'), 10) || 9,
            Login_device_type: "web",
            Login_user_id: parseInt(getItem('user_id'), 10) || null,
            [apiGenerate(type, 'slug')]: slug,
        }, { headers: getItemToken('access_token') || null })

        const liveResponse = type == "GENRE" ? response?.data?.[apiGenerate(type, 'details')][0] : response?.data;
        console.log("curent",)
        if (liveResponse[apiGenerate(type, 'playlistdetails')].length > 0 && liveResponse[apiGenerate(type, 'playlistdetails')][0]?.mp3_url) {
            dispatch({ type: "GET_AUDIO_SUCCESS", payload: liveResponse?.[apiGenerate(type, 'playlistdetails')] });
            dispatch({ type: "GET_CURRENT_SUCCESS", payload: liveResponse?.[apiGenerate(type, 'playlistdetails')][0] })
            dispatch({ type: "GET_CURRENT_PLAYLIST_OR_ALBUM", payload: type == "GENRE" ? liveResponse : liveResponse?.[apiGenerate(type, 'details')] })
            dispatch({ type: "GET_PLAYING_SUCCESS", isPlaying: true })
            dispatch({ type: "GET_CURRENT_INDEX", currentIndex: 0 })
            dispatch({ type: "GET_CURRENT_DURATION", isDuration: liveResponse?.[apiGenerate(type, 'playlistdetails')][0]?.duration })
            dispatch({ type: "MINIMIZE_PLAYER", payload: false })
            // dispatch({ type: "GET_INTERACT_DATA", payload: liveResponse[apiGenerate(type, 'playlistdetails')][0] });
            dispatch(musicDetailsUpdate("AUDIO", liveResponse?.[apiGenerate(type, 'playlistdetails')][0]?.slug))
        }
    }
    catch (error) {
        console.error("Uh...! , An unexpected error occurred");
    };
}

const musicDetailsUpdate = (type, slug) => async (dispatch) => {
    try {
        const response = await axios.post(`${apiGenerate(type, 'api')}`, {
            Login_user_type: parseInt(getItem('role'), 10) || 9,
            Login_device_type: "web",
            Login_user_id: parseInt(getItem('user_id'), 10) || null,
            [apiGenerate(type, 'slug')]: slug,
        }, { headers: getItemToken('access_token') || null })
        const ListRes = response?.data?.[apiGenerate(type, 'details')]
        dispatch({ type: "GET_INTERACT_DATA", payload: ListRes });
    }
    catch (error) {
        console.log(error)
    };
}

const getCurrentAction = (nextIndex, playlistAudio, currentTime) => async (dispatch, getState) => {
    let getPrevState = getState();
    if (getPrevState.id !== playlistAudio?.[nextIndex]?.id) {
        dispatch({
            type: "GET_CURRENT_SUCCESS",
            payload: playlistAudio?.[nextIndex],
        });
        dispatch({
            type: "GET_PLAYING_SUCCESS",
            isPlaying: true,
        });
        dispatch({
            type: "GET_CURRENT_INDEX",
            currentIndex: nextIndex,
        });
        dispatch({
            type: "GET_LYRIC_SUCCESS",
            currentTimes: currentTime || 0,
        });
    }
    else {
        console.log("same id")
    }
};

const songDetailsAction = (type, slug) => async (dispatch) => {
    try {
        const response = await axios.post(`${apiGenerate(type, 'api')}`, {
            Login_user_type: parseInt(getItem('role'), 10) || 9,
            Login_device_type: "web",
            Login_user_id: parseInt(getItem('user_id'), 10) || null,
            [apiGenerate(type, 'slug')]: slug,
        }, { headers: getItemToken('access_token') || null })
        const liveResponse = response?.data;
        dispatch({
            type: "GET_SONG_DETAILS_ACTION",
            payload: type == "GENRE" ? liveResponse?.[apiGenerate(type, 'details')][0] : liveResponse?.[apiGenerate(type, 'details')],
            TopArtist: liveResponse?.[apiGenerate(type, 'artists')],
            otherAudios: liveResponse?.other_audios ? liveResponse?.other_audios : liveResponse?.data ? liveResponse?.data : liveResponse?.artist_audios ? liveResponse?.artist_audios : liveResponse?.album_audios ? liveResponse?.album_audios : liveResponse?.AudioGenre[0]?.category__audios,
            otherPlaylist: liveResponse?.other_playlist ? liveResponse?.other_playlist : liveResponse?.TopAudioAlbum ? liveResponse?.TopAudioAlbum : liveResponse?.other_music_station ? liveResponse?.other_music_station : liveResponse?.Other_AudioGenre
        });
    }
    catch (error) {
        dispatch({ type: "GET_SONG_DETAILS_ACTION_FAILED", payload: error });
    };
}



export { getMusicHomePageAction, MusicViewAllPageAction, playQueueAction, getCurrentAction, songDetailsAction, musicDetailsUpdate, apiGenerate }