import React from 'react'
import { Link } from 'react-router-dom'
import PriceLabel from '../../../../components/Others/PriceLabel'
import LazyLoadImage from '../LazyLoadImage'
import HoverCart from '../HoverCart'
import HoverCardThemeTwo from './HoverCardThemeTwo'
import { connect } from 'react-redux'

const CardWithHover = ({ value, segMent, lang, hoverClass ,currentTheme}) => {
    return (
        <>

            <Link onClick={() => window.location.href = `/${lang}/${segMent}/${value?.slug ? value?.slug : value?.video_slug ? value?.video_slug : value?.series_slug ? value?.series_slug : value?.artist_slug ? value?.artist_slug : value?.channel_slug}`} className='w-100 h-100 d-block d-md-none absolute-mobile-link' />
            <PriceLabel item={value} />
            <LazyLoadImage key={value?.title} src={value?.image_url} alt={value?.title ? value?.title : value?.name ? value?.name : value?.episode_title ? value?.episode_title : value?.video_title} title={value?.title ? value?.title : value?.name ? value?.name : value?.episode_title ? value?.episode_title : value?.video_title} imageSize="loaderMain object-fit-cover " classNameOptional="rounded-3 w-100 " />
            {currentTheme == 1 && <HoverCart value={value} segMent={segMent} containerClass={hoverClass} />}
            {currentTheme == 2 && <HoverCardThemeTwo value={value} segMent={segMent} containerClass={`${hoverClass}`} />}

        </>
    )
}

const mapStateToProps = (state) => ({
    currentTheme: state.get_headerIndex_Reducer.currentTheme,
});
export default connect(mapStateToProps)(CardWithHover);
