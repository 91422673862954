import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from "../../../Images/flicklogo.png";
import { connect } from 'react-redux';
import { IoIosArrowDown } from 'react-icons/io';
import MessageBox from '../../../components/Others/MessageBox';
import { Message } from 'rsuite';

const AdvertiserSideMenu = ({ toggleClassName, languageDetailsData }) => {

  return (
    <nav className={` ${toggleClassName ? "advertiser-active" : ""} px-2 adminSideBarLeft theme-bg-color`}>
      <NavLink className="adminImageLink theme-bg-color d-block" to={`/${languageDetailsData?.Language_Code}/advertiser`}>
        <img src={logo} alt="flogo" className="mx-auto d-block py-3 " style={{ width: "200px", height: "80px", objectFit: "contain" }} />
      </NavLink>

      <ul className="p-0 list-unstyled">
        <li className="nav-item mb-2">
          <NavLink className="d-flex align-items-center sideMenu position-relative" target="_block" to={`${process.env.REACT_APP_API_PATHss}`}>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" width="30" height="30" data-name="Layer 1" viewBox="0 0 40 40">
                <ellipse class="cls-1" cx="19" cy="20.5" rx="5" ry="4.5" style={{ fill: "none", stroke: "currentcolor", strokeMiterlimit: "10" }} />
                <rect class="cls-2" x="3" y="5" width="34" height="27" rx="2.37" style={{ fill: "none", stroke: "currentcolor", strokeMiterlimit: "10" }} />
                <line class="cls-2" x1="3.5" y1="11.5" x2="36.5" y2="11.5" style={{ fill: "none", stroke: "currentcolor", strokeMiterlimit: "10" }} />
                <circle class="cls-2" cx="19.5" cy="20.5" r="5" style={{ fill: "none", stroke: "currentcolor", strokeMiterlimit: "10" }} />
                <line class="cls-2" x1="23.25" y1="23.8" x2="27.5" y2="27.5" style={{ fill: "none", stroke: "currentcolor", strokeMiterlimit: "10" }} />
                <circle cx="7" cy="8" r="1" style={{ fill: "none", stroke: "currentcolor", strokeMiterlimit: "10" }} />
                <circle cx="10" cy="8" r="1" style={{ fill: "none", stroke: "currentcolor", strokeMiterlimit: "10" }} />
                <circle cx="13" cy="8" r="1" style={{ fill: "none", stroke: "currentcolor", strokeMiterlimit: "10" }} />
              </svg>
            </div>
            <span className="ms-2 theme-text-color text-break">Visit Site</span>
          </NavLink>
        </li>

        <li className="nav-item mb-2">
          <NavLink className="d-flex align-items-center sideMenu position-relative" to={`/${languageDetailsData?.Language_Code}/advertiser`}>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" id="Layer_1" viewBox="0 0 70 70" >
                <g>
                  <path class="st0" style={{ fill: "none", stroke: "currentcolor", strokeMiterlimit: "10" }} d="M54.8,49.9H3.3v1.4c0,2.1,3.6,3.9,8.1,3.9h50c4.5,0,6.1-1.3,6.1-3.5v-1.8" />
                  <path class="st0" style={{ fill: "none", stroke: "currentcolor", strokeMiterlimit: "10" }} d="M21.2,27.5c0,0,2.3,1,1.1,3.3c-1.2,2.3-2.6,4.3-2.6,6.2s-0.3,3.8-0.5,4.3s-1.3,2.6-3.1,1.1s-1.3-1.4-1.4-3.6   c-0.1-2.2,1.9-8.1,2.7-9.4c0.8-1.2,2.2-2.5,2.9-2.3C21,27.5,21.2,27.5,21.2,27.5z" />
                  <path class="st0" style={{ fill: "none", stroke: "currentcolor", strokeMiterlimit: "10" }} d="M33,20c0,0,6.3-0.8,8.8,0.3c2.5,1,11,5.1,12.7,10.8s2.9,9,1.8,10c-1.1,1-2.1,2.4-3.6,1.1   c-1.4-1.2-0.7-4.7-2-7.3c-1.3-2.5-5.7-10.7-13.1-10.8s-5.8,0.6-6.4-1.4C30.6,20.8,33,20,33,20z" />
                  <g>
                    <path class="st1" style={{ fill: "none", stroke: "currentcolor", strokeMiterlimit: "10" }} d="M8.1,46.4v-29c0-2.1,1.6-3.9,3.5-3.9h47.9c1.9,0,3.5,1.7,3.5,3.9v29" />
                    <path class="st1" style={{ fill: "none", stroke: "currentcolor", strokeMiterlimit: "10" }} d="M67.7,50.9c0,2.5-2.1,4.4-4.6,4.4H7.9c-2.5,0-4.6-2-4.6-4.4v-1h64.4L67.7,50.9L67.7,50.9z" />
                    <path class="st1" style={{ fill: "none", stroke: "currentcolor", strokeMiterlimit: "10" }} d="M20.8,27c-0.7,0-1.3,0.3-1.7,0.8c-2.8,3.7-4.3,8-4.3,12.6c0,1.2,1,2.2,2.2,2.2s2.2-1,2.2-2.2    c0-3.6,1.2-7,3.3-9.9c0.6-0.8,0.6-1.8,0.1-2.6C22.2,27.4,21.5,27,20.8,27L20.8,27z" />
                    <path class="st1" style={{ fill: "none", stroke: "currentcolor", strokeMiterlimit: "10" }} d="M35.5,24.2c8.9-0.4,16.3,7.3,16.3,16.3c0,1.2,1,2.2,2.2,2.2s2.2-1,2.2-2.2c0-11.4-9.3-20.7-20.7-20.7    c-0.8,0-1.7,0-2.5,0.1s-1.4,0.6-1.8,1.3c-0.3,0.7-0.3,1.5,0.2,2.2c0.4,0.7,1.3,1,2.1,0.9c0.6-0.1,1.6-0.1,2.2-0.1L35.5,24.2z" />
                    <path class="st1" style={{ fill: "none", stroke: "currentcolor", strokeMiterlimit: "10" }} d="M35.5,34.6c-0.2,0-0.4,0-0.6,0.1L26,21.1c-0.5-0.8-1.5-1-2.3-0.5s-1,1.5-0.5,2.3l8.9,13.6    c-0.4,0.6-0.6,1.3-0.6,2.1c0,2.2,1.8,4,4,4s4-1.8,4-4C39.5,36.4,37.7,34.6,35.5,34.6L35.5,34.6z" />
                  </g>
                </g>
              </svg>
            </div>
            <span className="ms-2 theme-text-color text-break">Dashboard</span>
          </NavLink>
        </li>

        <li className="nav-item mb-2">
          <div className="nav-link  d-flex align-items-center justify-content-between sideMenu position-relative collapsed" data-bs-target="#All-Advertisements" data-bs-toggle="collapse" to="#">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 70" id="Layer_1" data-name="Layer 1" width="30" height="30">
                <g>
                  <g>
                    <ellipse style={{ fill: "currentcolor" }} class="st0" cx="28.5" cy="21.9" rx="7.9" ry="8.2" />
                    <path style={{ fill: "currentcolor" }} class="st0" d="M44.7,54.8H12.9c-0.6,0-1.1-0.5-1.1-1.1v-3.4c0-8.8,7.2-15.9,15.9-15.9l0,0c9.7,0,17.6,7.9,17.6,17.5v2.2    C45.4,54.5,45,54.8,44.7,54.8z" />
                  </g>
                  <g>
                    <path style={{ fill: "none", stroke: "currentcolor", strokeLinecap: "round", strokeLinejoin: "round", strokeMiterlimit: "10" }} class="st1" d="M28.6,30.1c4.6,0,8.3-3.7,8.3-8.3s-3.7-8.3-8.3-8.3s-8.3,3.7-8.3,8.3C20.3,26.4,24,30.1,28.6,30.1z" />
                    <path style={{ fill: "none", stroke: "currentcolor", strokeLinecap: "round", strokeLinejoin: "round", strokeMiterlimit: "10" }} class="st1" d="M45.2,54.7H12V51c0-4.4,1.7-8.6,4.9-11.7c3.1-3.1,7.3-4.9,11.7-4.9s8.6,1.7,11.7,4.9s4.9,7.3,4.9,11.7V54.7z" />
                    <path style={{ fill: "none", stroke: "currentcolor", strokeLinecap: "round", strokeLinejoin: "round", strokeMiterlimit: "10" }} class="st1" d="M41.4,13.5c2.2,0,4.3,0.9,5.9,2.4c1.6,1.6,2.4,3.7,2.4,5.9s-0.9,4.3-2.4,5.9c-1.6,1.6-3.7,2.4-5.9,2.4" />
                    <path style={{ fill: "none", stroke: "currentcolor", strokeLinecap: "round", strokeLinejoin: "round", strokeMiterlimit: "10" }} class="st1" d="M47.3,35.2c3.1,1.2,5.8,3.3,7.8,6.1c1.9,2.8,2.9,6,2.9,9.4v4.1h-5.5" />
                  </g>
                </g>
              </svg>
              <MessageBox text={'Advertisements'} classname="ms-2 theme-text-color text-break" />
            </div>
            <IoIosArrowDown />
          </div>

          <ul id="All-Advertisements" className="nav-content collapse p-0 mt-2 mb-0" data-bs-parent="#sidebar-nav"  >
            <li>
              <NavLink className="d-flex align-items-center sideMenu position-relative py-3" to={`/${languageDetailsData?.Language_Code}/advertiser/ads-list`}>
                <MessageBox classname="theme-text-color text-break" text='All Advertisement' />
              </NavLink>
            </li>
            <li>
              <NavLink className="d-flex align-items-center sideMenu position-relative py-3" to={`/${languageDetailsData?.Language_Code}/advertiser/upload-ads`}>
                <i className="bi bi-circle"></i>
                <MessageBox classname=" theme-text-color text-break" text='Add New Advertisement' />
              </NavLink>
            </li>
            <li>
              <NavLink className="d-flex align-items-center sideMenu position-relative py-3" to={`/${languageDetailsData?.Language_Code}/advertiser/schedule-ads`}>
                <i className="bi bi-circle"></i>
                <MessageBox classname=" theme-text-color text-break" text='Schedule Advertisement' />
              </NavLink>
            </li>
          </ul>
        </li>

        <li className="nav-item mb-2">
          <div className="nav-link  d-flex align-items-center justify-content-between sideMenu position-relative collapsed" data-bs-target="#Ad-Analytics" data-bs-toggle="collapse" to="#">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 40 40" width="30" height="30">
                <path class="cls-1" d="M19.5,18.5V6.5s-10,1-12,7,.6,13.12,1.8,14.06,6.2,6.94,15.2,3.94A15.23,15.23,0,0,0,28,29a20.64,20.64,0,0,0,3.46-4.41,12.87,12.87,0,0,0,1-4.91l-13-.18Z" style={{ fill: "currentcolor" }} />
                <path class="cls-2" d="M32.5,19.5a13,13,0,1,1-13-13" style={{ strokeLinecap: "round", stroke: "currentcolor", fill: "none" }} />
                <polyline class="cls-2" points="19.5 6.5 19.5 19.5 32.5 19.5" style={{ strokeLinecap: "round", stroke: "currentcolor", fill: "none" }} />
                <path class="cls-3" d="M35,26.64a17.35,17.35,0,0,1-1.92,3.18" style={{ strokeLinecap: "round", stroke: "currentcolor" }} />
                <path class="cls-3" d="M36.44,21.47a16.66,16.66,0,0,1-.89,3.81" style={{ strokeLinecap: "round", stroke: "currentcolor" }} />
                <path class="cls-2" d="M31.91,31.18a17,17,0,1,1,2.18-20.5" style={{ strokeLinecap: "round", stroke: "currentcolor", fill: "none" }} />
              </svg>
              <MessageBox classname="ms-2 theme-text-color text-break" text={'Ad Analytics'} />
            </div>
            <IoIosArrowDown />
          </div>
          <ul id="Ad-Analytics" className="nav-content collapse p-0 mt-2" data-bs-parent="#sidebar-nav" >
            <li>
              <NavLink className="d-flex align-items-center sideMenu position-relative py-3" to={`/${languageDetailsData?.Language_Code}/advertiser/cpc`}>
                <i className="bi bi-circle"></i>
                <span className="theme-text-color text-break">CPC</span>
              </NavLink>
            </li>
            <li>
              <NavLink className="d-flex align-items-center sideMenu position-relative py-3" to={`/${languageDetailsData?.Language_Code}/advertiser/cpv`}>
                <i className="bi bi-circle"></i>
                <span className=" theme-text-color text-break">CPV</span>
              </NavLink>
            </li>

          </ul>
        </li>

        <li className="nav-item mb-2">
          <div className="nav-link  d-flex align-items-center justify-content-between sideMenu position-relative collapsed" data-bs-target="#History" data-bs-toggle="collapse" to="#">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1"
                viewBox="0 0 40 40" width="30" height="30">
                <circle class="cls-1" cx="31" cy="33" r="6" style={{ fill: "currentcolor" }} />
                <polygon class="cls-1" points="24.5 2.5 24.5 9.5 31.5 9.5 30.18 7.6 24.5 2.5" style={{ fill: "currentcolor" }} />
                <path class="cls-2" d="M32.31,27.31V9.74L24.5,1.93H8.87A2.91,2.91,0,0,0,6,4.84V33.18a2.91,2.91,0,0,0,2.91,2.91H26.46" style={{ fill: "none", stroke: "currentcolor", strokeMiterlimit: "10" }} />
                <circle class="cls-2" cx="31.34" cy="33.16" r="5.86" style={{ fill: "none", stroke: "currentcolor", strokeMiterlimit: "10" }} />
                <polyline class="cls-2" points="31.34 30.26 31.34 34.16 33.29 35.14" style={{ fill: "none", stroke: "currentcolor", strokeMiterlimit: "10" }} />
                <line class="cls-2" x1="11.82" y1="17.55" x2="26.46" y2="17.55" style={{ fill: "none", stroke: "currentcolor", strokeMiterlimit: "10" }} />
                <line class="cls-2" x1="11.82" y1="12.52" x2="24" y2="12.52" style={{ fill: "none", stroke: "currentcolor", strokeMiterlimit: "10" }} />
                <line class="cls-2" x1="11.82" y1="23.4" x2="26.46" y2="23.4" style={{ fill: "none", stroke: "currentcolor", strokeMiterlimit: "10" }} />
                <path class="cls-2" d="M24,1.93V8.36A1.14,1.14,0,0,0,25.14,9.5h7.17" style={{ fill: "none", stroke: "currentcolor", strokeMiterlimit: "10" }} />
              </svg>
              <MessageBox classname="ms-2 theme-text-color text-break" text={'History'} />
            </div>
            <IoIosArrowDown />
          </div>
          <ul id="History" className="nav-content collapse p-0 mt-2" data-bs-parent="#sidebar-nav" >
            <li>
              <NavLink className="d-flex align-items-center sideMenu position-relative py-3" to={`/${languageDetailsData?.Language_Code}/advertiser/featured-history`}>
                <i className="bi bi-circle"></i>
                <span className="theme-text-color text-break">Featured Ad History</span>
              </NavLink>
            </li>
            <li>
              <NavLink className="d-flex align-items-center sideMenu position-relative py-3" to={`/${languageDetailsData?.Language_Code}/advertiser/plans-history`}>
                <i className="bi bi-circle"></i>
                <span className=" theme-text-color text-break">Plans History</span>
              </NavLink>
            </li>

          </ul>
        </li>

        <li className="nav-item mb-2">
          <NavLink className="d-flex align-items-center justify-content-between sideMenu position-relative" to={`/${languageDetailsData?.Language_Code}/advertiser/signin`}>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1"
                viewBox="0 0 40 40" width="30" height="30">

                <path class="cls-1" d="M27.77,24.18V34.37a4.1,4.1,0,0,1-4.1,4.09H6.59A4.09,4.09,0,0,1,2.5,34.37V5.63A4.09,4.09,0,0,1,6.59,1.54H23.67a4.1,4.1,0,0,1,4.1,4.09V16.05" style={{ fill: "none" }} />
                <path class="cls-2" d="M27.77,24.18V34.37a4.1,4.1,0,0,1-4.1,4.09H6.59A4.09,4.09,0,0,1,2.5,34.37V5.63A4.09,4.09,0,0,1,6.59,1.54H23.67a4.1,4.1,0,0,1,4.1,4.09V16.05" style={{ fill: "none", stroke: "currentcolor", strokeLinecap: "round", strokeLinejoin: "round", strokeMiterlimit: "10" }} />
                <line class="cls-2" x1="12.7" y1="19.51" x2="37" y2="19.51" style={{ fill: "none", stroke: "currentcolor", strokeLinecap: "round", strokeLinejoin: "round", strokeMiterlimit: "10" }} />
                <polyline class="cls-2" points="32.14 14.65 37 19.51 32.14 24.37" style={{ fill: "none", stroke: "currentcolor", strokeLinecap: "round", strokeLinejoin: "round", strokeMiterlimit: "10" }} />
              </svg>
              <MessageBox classname="ms-2 theme-text-color text-break" text={'Logout'} />
            </div>
            {/* <IoIosArrowDown /> */}
          </NavLink>
        </li>
      </ul>
    </nav>

  )
}

const mapStateToProps = state => ({
  languageDetailsData: state.get_getUserTranslateLanguage_Reducer.languageDetailsData
})
export default connect(mapStateToProps)(AdvertiserSideMenu)