import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./AddSeriesCategory.css";
import { toast } from "react-toastify";
 
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import ResponseLoader from "../../../components/Others/ResponseLoader";
import AdminSaveHeader from "../../../components/Others/adminCommonComponents/AdminSaveHeader";
import FormContainer from "../../CommonComponents/FormContainer";
import LeftWrapper from "../../CommonComponents/LeftWrapper";
import RightWrapper from "../../CommonComponents/RightWrapper";
import Wrapper from "../../CommonComponents/Wrapper";
import AdminCustomButton from "../../../components/Others/adminCommonComponents/AdminCustomButton";
import MessageLabel from "../../CommonComponents/MessageLabel";
import MessageHeading from "../../CommonComponents/MessageHeading";
import MessageBox from "../../../components/Others/MessageBox";
import { IoClose } from "react-icons/io5";

function AddSeriesCategory() {
  const [parent_id, setParent_id] = useState("");
  const [in_home, setIn_home] = useState("");
  const [footer, setFooter] = useState("");
  const [in_menu, setIn_menu] = useState("");
  const [uploaded_by, setUploaded_by] = useState("");
  const [data, setData] = useState([]);

  const [editUser, setEditUser] = useState({
    title: "",
    slug: "",
  });
  const navigate = useNavigate();
  const access_token_channel = localStorage.getItem("access_token_channel");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token_channel,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessageslug("");
    setIsValidationHiddenslug(true);
    setValidationMessagetitle("");
    setIsValidationHiddentitle(true);

    setEditUser({ ...editUser, title: e.target.value });
    setEditUser({ ...editUser, [e.target.name]: e.target.value });

    if (e.target.checked == true) {
      setIn_home({ ...in_home, [e.target.name]: 1 });
      setFooter({ ...footer, [e.target.name]: 1 });
      setIn_menu({ ...in_menu, [e.target.name]: 1 });
    } else {
      setIn_home({ ...in_home, [e.target.name]: 0 });
      setFooter({ ...footer, [e.target.name]: 0 });
      setIn_menu({ ...in_menu, [e.target.name]: 0 });
    }
  };

  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);

  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);

  const handleFileChange = (event, fileInputRef, setSelectedFile) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDeleteOne = (setSelectedFile1) => {
    setSelectedFile1(null);
    if (fileInputRef1.current) {
      fileInputRef1.current.value = null;
    }

  };

  const handleDeleteTwo = (setSelectedFile2) => {
    setSelectedFile2(null);
    if (fileInputRef2.current) {
      fileInputRef2.current.value = null;
    }
  };
  const [autoFillValuetitle, setAutoFillValuetitle] = useState("");

  const handleInputKeyDowntitle = (event) => {
    if (event.key == "Enter" || event.key == "Tab") {
      generateSlug(editUser?.title);
    }
  };
  const handleBlurtitle = () => {
    generateSlug(editUser?.title);
  };

  const handleAutoFillChangetitle = (event) => {
    setAutoFillValuetitle(event.target.value);
  };

  const generateSlug = (title) => {
    title = title.trim();
    const slug = title.toLowerCase().replace(/\s+/g, "-");
    setAutoFillValuetitle(slug);
  };

  const [validationMessagetitle, setValidationMessagetitle] = useState("");
  const [isValidationHiddentitle, setIsValidationHiddentitle] = useState(true);

  const [validationMessageslug, setValidationMessageslug] = useState("");
  const [isValidationHiddenslug, setIsValidationHiddenslug] = useState(true);

  const titleInputRef = useRef(null);
  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  async function series_genre() {
    let focusInputRef = null;

    if (editUser?.title == "") {
      setValidationMessagetitle("Title cannot be empty*.");
      setIsValidationHiddentitle(false);
      focusInputRef = titleInputRef;
    }
    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }

    const editInputin_home = in_home.in_home;
    const in_homedata = JSON.stringify(editInputin_home);

    const editInputfooter = footer.footer;
    const footerdata = JSON.stringify(editInputfooter);

    const editInputin_menu = in_menu.in_menu;
    const in_menudata = JSON.stringify(editInputin_menu);

    const formData = new FormData();

    formData.append("name", editUser?.title);
    formData.append("slug", autoFillValuetitle);
    formData.append("uploaded_by", uploaded_by);    
    formData.append("in_home", in_homedata ? parseInt(in_homedata, 10) : 0);
    formData.append("in_menu", in_menudata ? parseInt(in_menudata, 10) : 0);
    formData.append("parent_id", parent_id ? parseInt(parent_id, 10) : 0);
    formData.append("footer", footerdata ? parseInt(footerdata, 10) : 0);

    let firstApiStatus = false;
    let secondApiStatus = false;
    let resultapi = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/series-category/store`,
        formData,
        { headers: headers }
      );

      if (response.data.status == true) {
        firstApiStatus = true;

        resultapi = response.data;
        const SeriesGenre_id = response.data.SeriesGenre_id;

        const imageFormData = new FormData();
        imageFormData.append("series_genre_id", SeriesGenre_id);
        if (selectedFile1) {
          imageFormData.append("image", selectedFile1);
        }
        if (selectedFile2) {
          imageFormData.append("banner_image", selectedFile2);
        }

        const imageResponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/series-category/Image-upload`,
          imageFormData,
          { headers: headers }
        );

        if (imageResponse.data.status == true) {
          secondApiStatus = true;
        }
      }
    } catch (error) { }

    if (firstApiStatus && secondApiStatus) {
      setValidationMessagetitle("");
      setValidationMessageslug("");
      toast.success(resultapi?.message);
      setTimeout(async () => {
        await navigate("/channel/series-categories-list");
      }, 3000);
    } else {
      if (resultapi == false) {
        var resultError = resultapi;
        toast.error(resultError?.message);
        setIsValidationHiddentitle(false);
        setValidationMessageslug(false);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  }

  useEffect(async () => {
    await axios
      .get(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/series-category/create`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        var result = response?.data?.Series_Genre;
        setData(result);
        console.log("result", result);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <>
      {showOverlay && (<ResponseLoader />)}
      <AdminSaveHeader heading="Add series genre" saveText="Save Category" saveMethod={series_genre} />
      <FormContainer>
        <LeftWrapper>
          <Wrapper>
            <MessageHeading text="Create Category" />
            <MessageLabel text="Name" className='mandatorySimple' />
            <input
              type="text"
              className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder mb-3 theme-text-color border-0"
              id="title"
              name="title"
              placeholder="Enter Name"
              onChange={handleInput}
              value={editUser?.title}
              onBlur={handleBlurtitle}
              onKeyDown={handleInputKeyDowntitle}
              ref={titleInputRef}
            />
            {!isValidationHiddentitle && (<MessageBox text={`${validationMessagetitle}`} classname='errorred mb-2 d-block' />)}
            <MessageLabel text="Slug" />
            <input
              type="text"
              className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder mb-3 theme-text-color border-0"
              id="slug"
              name="slug"
              placeholder="Enter Slug"
              value={autoFillValuetitle}
              onChange={handleAutoFillChangetitle}
            />
            <MessageLabel text="Category" />
            <select
              className="form-select theme-bg-color-secondary custom-placeholder  border-0 theme-text-color"
              name="parent_id"
              id="parent_id"
              onChange={(e) => setParent_id(e.target.value)}
            >
              <option value="0" className="theme-bg-color theme-text-color">Choose a Category</option>
              {data?.map((item) => (
                <option value={item?.order} className="theme-bg-color theme-text-color">{item?.name}</option>
              ))}
            </select>
          </Wrapper>
          <Wrapper >
            <MessageHeading text="Status Settings" />
            <div className="mx-0 mb-2 row">
              <div className="col-9 ps-0">
                <MessageLabel text="Display In Menu" />
              </div>
              <div className="col-3 pe-0 text-end">
                <label className="switch">
                  <input
                    name="in_menu"
                    onChange={handleInput}
                    defaultChecked={in_menu?.in_menu == 1 ? true : false}
                    checked={in_menu?.in_menu == 1 ? true : false}
                    className="rs-input"
                    type="checkbox"
                  />
                  <span
                    className="slider round"
                    name="in_menu"
                    onChange={handleInput}
                    value={in_menu?.in_menu == 1 ? "1" : "0"}
                  ></span>
                </label>
              </div>
            </div>
            <div className="mx-0  mb-2 row ">
              <div className="col-9 ps-0">
                <MessageLabel text="Display In Home" />
              </div>
              <div className="col-3 pe-0 text-end">
                <label className="switch">
                  <input
                    name="in_home"
                    onChange={handleInput}
                    className="r-input"
                    defaultChecked={in_home?.in_home == 1 ? true : false}
                    checked={in_home?.in_home == 1 ? true : false}
                    type="checkbox"
                  />
                  <span
                    className="slider round"
                    name="in_home"
                    onChange={handleInput}
                    value={in_home?.in_home == 1 ? "1" : "0"}
                  ></span>
                </label>
              </div>
            </div>
            <div className="mx-0  row">
              <div className="col-9 ps-0">
                <MessageLabel text="Is this episode display in Footer" />
              </div>
              <div className="col-3 pe-0 text-end">
                <label className="switch">
                  <input
                    name="footer"
                    id="footer"
                    onChange={handleInput}
                    className="r-input"
                    defaultChecked={footer?.footer == 1 ? true : false}
                    checked={footer?.footer == 1 ? true : false}
                    type="checkbox"
                  />
                  <span
                    className="slider round"
                    name="footer"
                    id="footer"
                    onChange={handleInput}
                    value={footer?.footer == 1 ? "1" : "0"}
                  ></span>
                </label>
              </div>
            </div>
          </Wrapper>
        </LeftWrapper>
        <RightWrapper>
          <Wrapper >
            <MessageHeading text="Thumbnails" />
            <MessageLabel text="Image" />
            <div className="mx-0 my-2 row">
              <div className="col-6 ps-0">
                <div
                  className="imagedrop theme-border-color"
                  onClick={() => fileInputRef1.current.click()}
                >
                  <input
                    type="file"
                    ref={fileInputRef1}
                    onChange={(event) =>
                      handleFileChange(
                        event,
                        fileInputRef1,
                        setSelectedFile1
                      )
                    }
                    accept="image/*"
                    style={{ display: "none" }}
                  />
                  <label className=" theme-text-color">
                    <CameraRetroIcon className=" theme-text-color" />
                  </label>
                </div>
              </div>
              <div className="col-6 pe-0">
                <div>
                  {selectedFile1 && (
                    <div className="imagedropcopy theme-border-color text-end">
                      <div className="mx-0 row">
                        <div className="col-10 ps-0">
                          <img
                            src={URL.createObjectURL(selectedFile1)}
                            alt="Uploaded"
                            className="thumbnails-upload-images"
                          />
                        </div>
                        <div
                          className="col-2 ps-0"
                          style={{ alignSelf: "center" }}
                        >
                          <button
                            onClick={() =>
                              handleDeleteOne(setSelectedFile1)
                            }
                            type="button"
                            className="bg-transparent theme-text-color"
                          ><IoClose className="custom-close-btn" /></button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <MessageLabel text="Banner" />
            <div className="mx-0 mt-2 row">
              <div className="col-6 ps-0">
                <div
                  className="imagedrop theme-border-color"
                  onClick={() => fileInputRef2.current.click()}
                >
                  <input
                    type="file"
                    ref={fileInputRef2}
                    onChange={(event) =>
                      handleFileChange(
                        event,
                        fileInputRef2,
                        setSelectedFile2
                      )
                    }
                    accept="image/*"
                    style={{ display: "none" }}
                  />
                  <label className=" theme-text-color">
                    <CameraRetroIcon className=" theme-text-color" />
                  </label>
                </div>
              </div>
              <div className="col-6 pe-0">
                <div>
                  {selectedFile2 && (
                    <div className="imagedropcopy theme-border-color text-end">
                      <div className="row mx-0">
                        <div className=" col-10 ps-0">
                          <img
                            src={URL.createObjectURL(selectedFile2)}
                            alt="Uploaded"
                            className="thumbnails-upload-images"
                          />
                        </div>
                        <div
                          className="col-2 ps-0 text-center"
                          style={{ alignSelf: "center" }}
                        >
                          <button
                            onClick={() =>
                              handleDeleteTwo(setSelectedFile2)
                            }
                            type="button"
                            className="bg-transparent theme-text-color"
                          ><IoClose className="custom-close-btn" /></button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Wrapper>
        </RightWrapper>
        <AdminCustomButton saveMethod={series_genre} saveText="Save Category" className="ms-auto" />
      </FormContainer >
    </>
  );
}

export default AddSeriesCategory;
