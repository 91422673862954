import React from 'react'
import { IoClose } from "react-icons/io5";
import AdminCustomButton from '../Button/AdminCustomButton';
import MessageBox from '../../../components/Others/MessageBox';

const AdminSliderModal = ({ modelClose, sliderAction, sliderClose, buttonText }) => {
    return (
        <div class={`modal  packageModel fade ${modelClose && 'd-block show'}`} id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog theme-bg-color rounded-3" role="document">
                <div className="modal-content theme-bg-color">
                    <div className="modal-header theme-border-bottom-secondary">
                        <h5 className="modal-title theme-text-color ">Slider </h5>
                        <button type="button" className='bg-transparent' onClick={() => sliderClose()} ><IoClose fill='currentColor' style={{ width: "25px", height: "25px" }} /></button>
                    </div>
                    <div className='modal-body'>
                        <MessageBox classname="theme-text-color" text="Are you sure you want to Switch the Slider status? "></MessageBox>
                    </div>
                    <div className="modal-footer  border-0">
                        <button type="button" className="bg-transparent btn theme-text-color m-0 sliderBtnText" onClick={() => sliderClose()} >Cancel </button>
                        <AdminCustomButton saveMethod={sliderAction} saveText={buttonText === 1 ? 'Disabled' : 'Enabled'} className="m-0" ></AdminCustomButton>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminSliderModal