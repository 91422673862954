// External Libraries and Packages
import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { channelDetailsAction } from '../../../Redux/Actions/apiActions';
import { getItem } from '../../../Utils/localStorageUtils';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';
import PartnerHomepage from '../../../ThemeTwo/Components/Details/SwiperPartner/PartnerHomepage';
const ChannelDetails = ({ channelDetailsData, isLoading, error, settings, homepage, thumbnailTitle }) => {
    const dispatch = useDispatch();
    const { slug } = useParams()
    const userRoleLocal = getItem('role')
    const userIdLocal = getItem('user_id')

    const sliderData = [
        { imageURL: 'https://img1.hotstarext.com/image/upload/f_auto,t_web_m_1x/sources/r1/cms/prod/8665/908665-h' },
        { imageURL: 'https://img1.hotstarext.com/image/upload/f_auto,t_web_m_1x/sources/r1/cms/prod/6363/1714177996363-h' },
        { imageURL: 'https://jfwonline.com/wp-content/uploads/2022/01/maxresdefault.jpg' },
    ]


    useEffect(() => {
        if (settings && Object.keys(settings).length > 0) {
            !userRoleLocal && settings.access_free == 1
                ? dispatch(channelDetailsAction(null, 9, "web", slug))
                : userRoleLocal && dispatch(channelDetailsAction(userIdLocal, userRoleLocal, "web", slug));
        }
    }, [settings, userRoleLocal])




    return (<PartnerHomepage sliderData={sliderData} data={homepage} thumbnailTitle={thumbnailTitle} type="Channel"/>)
}
const mapStateToProps = state => ({
    channelDetailsData: state.get_channelDetails_Reducer.channelDetailsData,
    isLoading: state.get_channelDetails_Reducer.isLoading,
    error: state.get_channelDetails_Reducer.error,
    settings: state.get_setting_Reducer.settings,
    homepage: state.get_allHomePage_Reducer.homepage,
    thumbnailTitle: state.get_allThumbnail_Reducer.thumbnailTitle
});
export default connect(mapStateToProps)(ChannelDetails)
