import { connect } from 'react-redux';
import LazyLoadImage from '../../HomePage/HomeSwiperComponents/LazyLoadImage'
import MessageBox from '../../../components/Others/MessageBox';
import { useMyContext } from '../../../components/Layout/CommonProvider';
import { BsFillPauseFill, BsFillPlayFill } from 'react-icons/bs';
import defaultImage from '../../../Images/default-music.png'
import { useEffect, useState } from 'react';
import { MdOutlineFullscreen } from "react-icons/md";
import PlayButton from '../CommonComponents/PlayButton';


const MiniFloatPlayer = ({ currentAudio, currentIndex, playlistAudio, currentPlaying }) => {
    const { handlePlayPause, handlePlayNext, handlePlayPrev, readyState, minimizePlayer } = useMyContext();

    const [position, setPosition] = useState({ x: 100, y: 100 });
    const [dragging, setDragging] = useState(false);
    const [rel, setRel] = useState({ x: 0, y: 0 });

    const handleMouseDown = (e) => {
        if (e.button !== 0) return;

        const pos = e.target.getBoundingClientRect();
        setRel({
            x: e.pageX - pos.left,
            y: e.pageY - pos.top
        });
        setDragging(true);
        e.stopPropagation();
        e.preventDefault();
    };

    const handleMouseUp = (e) => {
        setDragging(false);
        e.stopPropagation();
        e.preventDefault();
    };

    const handleMouseMove = (e) => {
        if (!dragging) return;

        const newX = e.pageX - rel.x;
        const newY = e.pageY - rel.y;

        const minX = 0;
        const minY = 0;
        const maxX = window.innerWidth - e.target.offsetWidth;
        const maxY = window.innerHeight - e.target.offsetHeight;

        setPosition({
            x: Math.min(Math.max(newX, minX), maxX),
            y: Math.min(Math.max(newY, minY), maxY)
        });

        e.stopPropagation();
        e.preventDefault();
    };

    useEffect(() => {
        const handleWindowMouseMove = (e) => handleMouseMove(e);
        const handleWindowMouseUp = (e) => handleMouseUp(e);

        if (dragging) {
            window.addEventListener('mousemove', handleWindowMouseMove);
            window.addEventListener('mouseup', handleWindowMouseUp);
        } else {
            window.removeEventListener('mousemove', handleWindowMouseMove);
            window.removeEventListener('mouseup', handleWindowMouseUp);
        }

        return () => {
            window.removeEventListener('mousemove', handleWindowMouseMove);
            window.removeEventListener('mouseup', handleWindowMouseUp);
        };
    }, [dragging]);

    return (
        <div className='position-fixed musicFloatPlayer p-2 rounded-2 custom-shadow bg-white' style={{ left: `${position.x}px`, top: `${position.y}px`, cursor: "grab" }}>
            <div className="d-flex " onMouseDown={handleMouseDown}>
                <div className='position-relative'>
                    <LazyLoadImage src={currentAudio?.image_url ? currentAudio?.image_url : currentAudio?.Thumbnail ? currentAudio?.Thumbnail : defaultImage} alt={currentAudio?.title} title={currentAudio?.title?.slice(0, 1)} imageSize="rounded-2 overflow-hidden miniPlayerImage object-fit-cover" classNameOptional="rounded-2 miniPlayerImage" />
                    <div className='musicFloatPlayerHeight' >
                        <PlayButton className="position-relative bg-transparent" type="AUDIO" store={currentAudio} />
                    </div>
                </div>
                <div className='d-flex align-items-center justify-content-between flex-column '>
                    <MessageBox text={currentAudio?.title} classname="text-start text-dark musicFloatPlayerTitle ps-2" />
                    <MdOutlineFullscreen className='text-dark align-self-end miniFloatPlayerMinimize' onClick={minimizePlayer} />
                    {/* <button className="playButton theme-text-color p-0" onClick={handlePlayPrev} disabled={currentIndex == 0} >
                            <svg width="25" height="25" viewBox="0 0 24 25" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.4" d="M22 8.58983V15.9098C22 17.4098 20.37 18.3498 19.07 17.5998L15.9 15.7698L12.73 13.9398L12.24 13.6598V10.8398L12.73 10.5598L15.9 8.72984L19.07 6.89983C20.37 6.14983 22 7.08983 22 8.58983Z" fill="currentColor" />
                                <path d="M12.2399 8.58983V15.9098C12.2399 17.4098 10.6099 18.3498 9.31995 17.5998L6.13995 15.7698L2.96994 13.9398C1.67994 13.1898 1.67994 11.3098 2.96994 10.5598L6.13995 8.72984L9.31995 6.89983C10.6099 6.14983 12.2399 7.08983 12.2399 8.58983Z" fill="currentColor" />
                            </svg>
                        </button> */}

                    {/* <button className="playButton theme-text-color p-0" onClick={handlePlayNext} disabled={currentIndex + 1 >= playlistAudio?.length}>
                            <svg width="25" height="25" viewBox="0 0 25 25" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.4" d="M2.6875 8.58983V15.9098C2.6875 17.4098 4.31749 18.3498 5.61749 17.5998L8.78751 15.7698L11.9575 13.9398L12.4475 13.6598V10.8398L11.9575 10.5598L8.78751 8.72984L5.61749 6.89983C4.31749 6.14983 2.6875 7.08983 2.6875 8.58983Z" fill="currentColor" />
                                <path d="M12.4475 8.58983V15.9098C12.4475 17.4098 14.0775 18.3498 15.3675 17.5998L18.5475 15.7698L21.7175 13.9398C23.0075 13.1898 23.0075 11.3098 21.7175 10.5598L18.5475 8.72984L15.3675 6.89983C14.0775 6.14983 12.4475 7.08983 12.4475 8.58983Z" fill="currentColor" />
                            </svg>
                        </button> */}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    currentAudio: state.get_current_Reducers.currentAudio,
    currentIndex: state.get_current_Reducers.currentIndex,
    playlistAudio: state.get_playlist_Reducer.playlistAudio,
    currentPlaying: state.get_current_Reducers.currentPlaying
});
export default connect(mapStateToProps)(MiniFloatPlayer)