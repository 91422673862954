const currencyReducers = (state = { initialPrice: {},userIP:null,currentPrice:null ,allCurrency: [
    {
        code: "USD",
        country: "United States",
        symbol: "$",
    },
    {
        code: "INR",
        country: "India",
        symbol: "₹",
    },
    {
        country: "Albania",
        code: "ALL",
        symbol: "Lek",
    },
    {
        country: "Argentina",
        code: "ARS",
        symbol: "$",
    },
    {
        country: "Azerbaijan",
        code: "AZN",
        symbol: "ман",
    },
    {
        country: "Japan",
        code: "JPY",
        symbol: "¥",
    },
    // Add more currencies as needed
]}, action) => {
    switch (action.type) {
        case "INITIAL_PRICE":
            return {
                ...state,
                initialPrice: action.payload,
            };
            case "IP_PRICE":
            return {
                ...state,
                userIP: action.payload,
                currentPrice:action.currentPrice
                
            };
        default:
            return state;
    }
};

export {currencyReducers}