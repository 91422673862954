import React, { useState,useEffect } from 'react';
import Navbar from "../../../channelportal/Signin/Header";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

const AdvertiserSignIn = () => {
  const navigate = useNavigate();
  const [inputField, setInputField] = useState({
    email: "",
    password: "",
  });
  const [errField, setErrField] = useState({
    emailerr: "",
    passworderr: "",
  });
  const [loading, setLoading] = useState(false);
  function clicked(e) {
    e.preventDefault();
  }
  function handleChange(e) {
    setInputField({ ...inputField, [e.target.name]: e.target.value });
  }
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  async function login() {
    // console.log(inputField);
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_Baseurl}/Advertisement_signin/sign-in`,
        { email: inputField?.email, password: inputField?.password },
        { cors: "no-cors" },
        { credentials: "include" },
        { credentials: "same-origin" },
        { headers }
      )
      .then((res) => {
        // console.log(res)
        let access_token = res.data.access_token;
        let expires_in = res.data.expires_in;
        let channel_name = res.data.Channel_user.channel_name;
        let user_id = res.data.Channel_user.id;
        // let role = res.data.Channel_user.role;
        let channel_image = res.data.Channel_user.channel_image;
        localStorage.setItem("access_token_channel", access_token);
        localStorage.setItem("Channel_Partner_id", user_id);
        // localStorage.setItem("role", role);
        localStorage.setItem("channel_name", channel_name);
        localStorage.setItem("channel_image", channel_image);
        localStorage.setItem("channel_expires_in", expires_in);
        // localStorage.setItem("user", inputField)
        setLoading(false);
        // setTimeout(() => {
        //   window.location.href = "/channel";
        // }, 1000);
      })
      .catch((err) => {
        setLoading(false);
        alert("Please Enter Correct Details");
        console.log(err);
      });
  }

  const validForm = (e) => {
    e.preventDefault();

    const validateEmail = (email) => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    let formIsValid = true;
    setErrField({
      emailerr: "",
      passworderr: "",
    });
    if (inputField?.email == "") {
      formIsValid = false;
      setErrField((prevState) => ({
        ...prevState,
        emailerr: "Please Enter Email*",
      }));
    } else if (!validateEmail(inputField?.email)) {
      formIsValid = false;
      setErrField((prevState) => ({
        ...prevState,
        emailerr: "Please Enter Valid Email ID",
      }));
    }

    if (inputField?.password == "") {
      formIsValid = false;
      setErrField((prevState) => ({
        ...prevState,
        passworderr: "Please Enter Password*",
      }));
    }

    if (inputField?.email !== "" && inputField?.password !== "") {
      login();
    }
    return formIsValid;
  };

  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");
  const handlePasswordChange = (evnt) => {
    setPasswordInput(evnt.target.value);
  };
  const togglePassword = () => {
    if (passwordType == "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  return (
    <div>
      <Navbar />
      <form onSubmit={validForm}>
        <section className="signin h-100 p-5">
          <div className="container">
            <div className="d-flex justify-content-center text-center">
              <div className="box-signin p-5">
                <h1 className="text-center mt-3">Advertiser Sign In</h1>
                <div>
                  <input
                    id="email"
                    name="email"
                    className="mt-4 w-100 p-2"
                    value={inputField?.email}
                    placeholder="Enter your Email"
                    type="email"
                    onChange={handleChange}
                    autoComplete="off"
                  />
                  {errField.emailerr !== "" && (
                    <span className="siginerror">{errField.emailerr}</span>
                  )}
                </div>
                <div>
                  <span
                    className="eyepasswordsignin"
                    aria-hidden="true"
                    onClick={togglePassword}
                  >
                    {" "}
                    {passwordType == "password" ? (
                      <i className="fa fa-eye-slash"></i>
                    ) : (
                      <i className="fa fa-eye"></i>
                    )}
                  </span>

                  <input
                    id="password"
                    name="password"
                    value={inputField?.password}
                    type={passwordType}
                    onChange={handleChange}
                    className="mt-4 w-100 p-2"
                    placeholder="Enter Your Password"
                    autoComplete="off"
                  />
                  {errField.passworderr !== "" && (
                    <span className="siginerror">{errField.passworderr}</span>
                  )}
                </div>

                <button
                  type="submit"
                  className="btn btn mt-4 w-100 button"
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Sign In"}
                  {/* Sign In */}
                </button>

                <Link to="">
                  <h6 className="forgot mt-1">Forgot Password?</h6>
                </Link>
                <h6 className="social-login mt-3">Login with using:</h6>
                <Link to="https://www.gmail.com">
                  <i className="fa fa-google rounded" aria-hidden="true"></i>
                </Link>
                <Link to="https://www.facebook.com">
                  <i className="fa fa-facebook rounded" aria-hidden="true"></i>
                </Link>

                <h6 className="accountsign mb-5">
                  Don't have an account?{" "}
                  <Link to="/channel/signup" className="text-decoration-none">
                    <span>Sign Up</span>
                  </Link>
                </h6>
              </div>
            </div>
          </div>
        </section>
      </form>
    </div>

  )
}

export default AdvertiserSignIn