import "./signup.css";
import { VscEyeClosed } from "react-icons/vsc";
import { RxEyeOpen } from "react-icons/rx";
import { Link, useParams } from "react-router-dom";
import google from "../../components/Images/Googl.png";
import fb from "../../components/Images/fb.png";
import { connect } from "react-redux";
import LazyLoadImage from "../../Pages/HomePage/HomeSwiperComponents/LazyLoadImage.js";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useTranslation } from "react-i18next";
import { MdClose } from "react-icons/md";
import { FcOk } from "react-icons/fc";
import { useEffect, useState } from "react";
import MessageBox from "../Others/MessageBox.jsx";
import ButtonWithLoader from "../../Pages/Music/CommonComponents/ButtonWithLoader.jsx";
import { IconMyCamera, IconMyUpload } from "../../Images/MyIcons.jsx";
import {
  CitySelect,
  CountrySelect,
  StateSelect,
  LanguageSelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import PageLoader from "../Others/PageLoader.jsx";

function Signup({ themeMode, authAction, error, onchange, field, show, data, passAction, modalClose, signInModal, showTwo, passTwoAction, ipConfig, numberValue, checkChange, phoneChange, imageOnChange, handleState, handleCountry, handleCity, stateid, cityid, countryid }) {
  const { t } = useTranslation();
  const { lang } = useParams()



  return (
    <div className={`w-100 ${data?.pop} min-vh-100 `}>
      {!data?.verify && <form onSubmit={authAction} autoComplete="off" className={`col-11 col-md-8 col-lg-4 mx-auto ${data?.headerDisable ? "popup-active signUpActive" : ""}  signInMenu theme-bg-color p-3 rounded-3 position-relative`}>
        {data?.headerDisable && <button className='bg-transparent p-0 theme-text-color position-absolute closeBtnModal' type='button' onClick={() => modalClose()}><MdClose className='playlistIcon' /></button>}
        <LazyLoadImage src={themeMode?.logoUrl} alt={"logo"} classNameOptional="rounded-3 mx-auto mb-3 signin-logo-images" imageSize="signin-logo-images loaderMain object-fit-cover mx-auto mb-3" />
        {data?.fieldEnable?.avatar ? <div className='position-relative profileImageUpdateMulti mx-auto  border border-2 theme-border-color rounded-circle p-2 mb-2' >
          <div key={'logo'} style={{ backgroundImage: `url(${data?.previewImage ? URL.createObjectURL(data?.previewImage) : field?.image_url})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }} className="rounded-circle profileImageUpdateMultiImage">
            <span className='profileImageLens position-absolute theme-bg-color-secondary p-2 rounded-circle d-flex align-items-center justify-content-center' >
              <IconMyCamera styled={{ width: "20px", height: "20px", className: "" }} />
            </span>
            <input type="file" onChange={(event) => imageOnChange(event)} className='profileInput w-100 h-100 position-absolute' accept='image/*' />
            <IconMyUpload styled={{ width: "20px", height: "20px", className: `${data?.previewImage ? '' : data?.previewImage ? '' : "d-block"} position-absolute uploadIcon` }} />
          </div>
        </div> : ""}

        {data?.fieldEnable?.username ?
          <>
            <div className="position-relative pb-4">
              <div className="border-bottom">
                <input
                  className="p-2 signin-input border-0 theme-text-color"
                  type="text"
                  id="username"
                  name="username"
                  autoComplete="off"
                  value={field?.username}
                  onChange={onchange}
                  placeholder={`${t('First name')} *`}
                />
              </div>
              {error?.usernameerr !== "" && (
                <span className="d-flex text-danger signin-error py-2 position-absolute">{t(error?.usernameerr)}</span>
              )}
            </div>
            <div className="position-relative pb-4">
              <div className="border-bottom">
                <input
                  className="p-2 signin-input border-0 theme-text-color"
                  type="text"
                  id="lastname"
                  name="lastname"
                  autoComplete="off"
                  value={field?.lastname}
                  onChange={onchange}
                  placeholder={`${t('Last name')} *`}
                />
              </div>
              {error?.lastnameerr !== "" && (
                <span className="d-flex text-danger signin-error py-2 position-absolute">{t(error?.lastnameerr)}</span>
              )}
            </div>
          </> : ""}

        {data?.fieldEnable?.email ?
          <>
            <div className="position-relative pb-4">
              <div className="border-bottom">
                <input
                  className="p-2 signin-input border-0 theme-text-color"
                  id="signupemail"
                  type="email"
                  name="email"
                  value={field?.email}
                  placeholder={`${t('Email')} *`}
                  autoComplete="off"
                  onChange={onchange}
                />
              </div>
              {error?.emailerr !== "" && (
                <span className="d-flex text-danger signin-error py-2 position-absolute">{t(error?.emailerr)}</span>
              )}
            </div>
          </> : ""}

        {data?.fieldEnable?.dob ? <div className="position-relative pb-4">
          <div className="border-bottom">
            <input
              className="p-2 signin-input border-0 theme-text-color"
              type="text"
              id="date"
              name="date"
              autoComplete="off"
              value={field?.date}
              onChange={onchange}
              placeholder={`${t('Date of Birth')} *`}
            />
          </div>
          {error?.dateerr !== "" && (
            <span className="d-flex text-danger signin-error py-2 position-absolute">{t(error?.dateerr)}</span>
          )}
        </div> : ""}

        {data?.fieldEnable?.country ? <CountrySelect
          onChange={(e) => {
            handleCountry(e.id);
          }}
          placeHolder="Select Country"
          inputClassName="bg-transparent theme-text-color border-0 country-input"
          containerClassName="custom-country"
        /> : ""}

        {data?.fieldEnable?.state ? <StateSelect
          countryid={countryid}
          onChange={(e) => {
            handleState(e.id);
          }}
          placeHolder="Select State"
          inputClassName="bg-transparent theme-text-color border-0 country-input"
          containerClassName="custom-country"
        /> : ""}
        {data?.fieldEnable?.city ? <CitySelect
          countryid={countryid}
          stateid={stateid}
          onChange={(e) => {
            handleCity(e.id)
          }}
          placeHolder="Select City"
          inputClassName="bg-transparent theme-text-color border-0 country-input"
          containerClassName="custom-country"
        /> : ""}

        {data?.fieldEnable?.mobile ?
          <div className="position-relative">
            <PhoneInput country={ipConfig?.country_code?.toLowerCase()} value={numberValue} onChange={phoneChange} inputStyle={{ width: '100%' }} countryCodeEditable={false} disableCountryCode={false} disableSearchIcon enableSearch={true} placeholder="Enter your phone number" inputClass="bg-transparent border-bottom border-0 py-4 theme-text-color  rounded-0 ownInputFlag" containerClass="mb-4" dropdownClass="theme-bg-color theme-text-color ownInputDropDown" buttonClass="bg-transparent border-0 ownButtonClass" />
            {error?.numbererr !== "" && (
              <div className="d-flex text-danger signin-error py-2 position-absolute">{t(error?.numbererr)}</div>
            )}
          </div> : ""}

        {data?.fieldEnable?.password ? <div className="position-relative pb-4">
          <div className="position-relative">
            <input
              id="signuppassword"
              name="password"
              value={field?.password}
              type={show ? 'password' : 'text'}
              onChange={onchange}
              autoComplete="off"
              className="p-2 signin-input border-0 theme-text-color border-bottom"
              placeholder={`${t('Enter your Password')} *`}
            />
            <button onClick={passAction} type="button" className="p-2  border-0 eyeButton">{show ? <VscEyeClosed className="theme-text-color eyeIcons" style={{ width: "25px", height: "25px" }} /> : <RxEyeOpen className="theme-text-color eyeIcons" style={{ width: "25px", height: "25px" }} />}</button>
          </div>
          {error?.passworderr !== "" && (
            <span className="d-flex text-danger signin-error py-2 position-absolute">{t(error?.passworderr)}</span>
          )}
        </div> : ""}

        {data?.fieldEnable?.password_confirm ?
          <div className="position-relative pb-4">
            <div className="position-relative">
              <input
                className="p-2 signin-input border-0 theme-text-color border-bottom "
                type={showTwo ? 'password' : 'text'}
                placeholder={`${t('Enter your confirm Password')} *`}
                name="confirmPassword"
                id="confirmPassword"
                autoComplete="off"
                value={field?.confirmPassword}
                onChange={onchange}
              />
              <button onClick={passTwoAction} type="button" className="p-2  border-0 eyeButton">{showTwo ? <VscEyeClosed className="theme-text-color eyeIcons" style={{ width: "25px", height: "25px" }} /> : <RxEyeOpen className="theme-text-color eyeIcons" style={{ width: "25px", height: "25px" }} />}</button>
            </div>
            {error?.confirmPassworderr !== "" && (
              <label className="d-flex text-danger signin-error py-2 position-absolute">{t(error?.confirmPassworderr)}</label>
            )}
          </div>
          : ""}

        <div className="d-flex align-items-center position-relative gap-3 checkboxHeight">
          <input
            type="checkbox"
            name="check" checked={field?.check}
            className="d-block"
            autoComplete="off"
            onChange={checkChange}
          />
          <label className="theme-text-color ">{t("Yes , I Agree to Terms and Conditions")}</label>
          {error?.checkerr !== "" && (
            <label className="d-flex text-danger signin-error py-2 position-absolute">{t(error?.checkerr)}</label>
          )}
        </div>

        <div className="d-flex align-items-center justify-content-around flex-wrap-reverse  my-3 ">
          <div className="col-sm-2 col-12 p-0 pe-2 d-flex justify-content-sm-between justify-content-center align-items-center gap-2">
            <img src={google} alt="google" style={{ width: "39px", height: "40px" }} />
            <img src={fb} alt="facebook" style={{ width: "43px", height: "43px" }} />
          </div>
          <div className="col-5 d-none d-sm-flex  justify-content-center" >
            <span className="code"></span>
          </div>
          <div className="col-sm-5 mb-3 mb-sm-0 col-12  position-relative d-flex justify-content-sm-end justify-content-center">
            <ButtonWithLoader action={authAction} text="Sign Up" type={"submit"} loading={data?.load} className='signinbutton before d-block text-uppercase px-4 py-1 rounded-2 theme-text-color' />
          </div>
        </div>
        <p className="signinLabelText mb-0 pt-2 theme-text-color">{t("Already have an account")} ? {data?.headerDisable ? <button type="button" className="border-0 bg-transparent theme-text-color" onClick={() => signInModal()}>{t("Sign In")}</button> : <Link to={`/${lang}/login`} className="border-0 bg-transparent theme-text-color">{t("Sign In")}</Link>}</p>
      </form>}
      {data?.verify && <div className='rounded-3 theme-bg-color-secondary p-5 col-11 col-md-8 col-lg-4 mx-auto position-relative'>
        {data?.headerDisable && <button className='bg-transparent p-0 theme-text-color position-absolute closeBtnModal' type='button' onClick={() => modalClose()}><MdClose className='playlistIcon' /></button>}
        <LazyLoadImage src={themeMode?.logoUrl} alt={"logo"} classNameOptional="rounded-3 mx-auto mb-3 signin-logo-images" imageSize="signin-logo-images loaderMain object-fit-cover mx-auto mb-3" />
        <div className="d-flex align-items-center gap-2 mb-3"><div style={{ width: "20px", height: "20px" }} > <FcOk className="" style={{ width: "20px", height: "20px" }} /></div><p className="theme-text-color">Check your Email</p></div>
        <div className="d-flex align-items-center gap-2 mb-3"> <div style={{ width: "20px", height: "20px" }} ><FcOk className="" style={{ width: "20px", height: "20px" }} /></div><p className="theme-text-color"> Click the verification link. The link rediect our website and automatically verify your account!.</p></div>
        <div className="d-flex align-items-center gap-2 mb-3"> <div style={{ width: "20px", height: "20px" }} > <FcOk className="" style={{ width: "20px", height: "20px" }} /></div><p className="theme-text-color"> Then, <Link to={`/${lang}/login`} className="d-inline theme-text-color text-decoration-underline ">Login </Link></p></div>
        {/* <MessageBox text="Please wait few seconds redirect login page" classname="text-center d-block my-3" />
        <span className="theme-button-bg-color mx-auto  d-block my-0 text-center small-lg-heading rounded-circle counter_timing" >{data?.count}</span> */}
      </div>}
    </div>
  );
}

const mapStateToProps = (state, ownProps) => ({
  settings: state.get_setting_Reducer.settings,
  isLoading: state.get_setting_Reducer.isLoading,
  props: ownProps,
  themeMode: state.get_headerIndex_Reducer.themeMode,
});
export default connect(mapStateToProps)(Signup);