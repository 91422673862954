// External Libraries and Packages
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { FaPlay, FaPrescriptionBottleAlt } from 'react-icons/fa'
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
// Internal Styles
import "swiper/css";
import "swiper/css/navigation";
import { useTranslation } from 'react-i18next';
import PriceLabel from '../../../components/Others/PriceLabel';
import CardWithHover from './Theme/CardWithHover';
import CardWithoutHover from './Theme/CardWithoutHover';
import { connect } from 'react-redux';
// Internal Components

const VideoCategoriesAll = ({ value, ThumbnailTitle, themeMode }) => {
  const { t } = useTranslation();
  const { lang } = useParams();
  const swiperConfig = {
    slidesPerView: 2,
    spaceBetween: 15,
    navigation: true,
    lazy: true,
    slidesPerGroup: 2,
    breakpoints: {
      376: { slidesPerView: 2, spaceBetween: 15, slidesPerGroup: 2 },
      576: { slidesPerView: 3, spaceBetween: 15, slidesPerGroup: 3 },
      768: { slidesPerView: 4, spaceBetween: 15, slidesPerGroup: 4 },
      1024: { slidesPerView: 5, spaceBetween: 15, slidesPerGroup: 5 },
      1200: { slidesPerView: 6, spaceBetween: 15, slidesPerGroup: 6 },
      1440: { slidesPerView: 6, spaceBetween: 15, slidesPerGroup: 6 },
    },
    modules: [Navigation],
    className: 'homePageSwiper'
  }
  return (
    <div className='px-3 px-md-4 px-lg-4 pt-1 zIndex'>
      {
        value?.category_videos.length > 0 && (<>
          <Link to={`/${lang}/video-categories/${value.slug}`} className="blocklinetitle  swiperHeading theme-text-color d-inline-block">{t(value?.name)} </Link>
          <Link to={`/${lang}/video-categories/${value.slug}`} className="blocklinetitle float-end  swiperHeading theme-text-color  viewall-homepage">{t('View All')}</Link>
        </>)
      }
      <div className="">
        <Swiper {...swiperConfig}>
          {/* {value?.category_videos?.map((category, index) => (
            <SwiperSlide key={category?.id} className='theme-bg-color-secondary'>
              {themeMode?.hoverCard ? <CardWithHover value={category} segMent="videos" lang={lang} hoverClass="homeSwiperContainer" /> : <CardWithoutHover value={category} segMent="videos" lang={lang} />}
            </SwiperSlide>
          ))} */}
          {value?.category_videos?.sort((a, b) => {
            if (!a.year) return 1;
            if (!b.year) return -1;
            const dateA = new Date(a.year);
            const dateB = new Date(b.year);
            if (isNaN(dateA)) return 1;
            if (isNaN(dateB)) return -1;
            return dateB - dateA;
          }).map((category, index) => (
            <SwiperSlide key={category?.id || index} className='theme-bg-color-secondary'>
              {themeMode?.hoverCard
                ? <CardWithHover value={category} segMent="videos" lang={lang} hoverClass="homeSwiperContainer" />
                : <CardWithoutHover value={category} segMent="videos" lang={lang} />
              }
            </SwiperSlide>
          ))}

        </Swiper>
      </div>
    </div>
  )
}
const mapStateToProps = (state) => ({
  themeMode: state.get_headerIndex_Reducer.themeMode,
});
export default connect(mapStateToProps)(VideoCategoriesAll);
