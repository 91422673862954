import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next';
import LazyLoadImage from '../../Pages/HomePage/HomeSwiperComponents/LazyLoadImage';
import { IconMyCamera, IconMyUpload } from '../../Images/MyIcons';


const CreateMultiprofile = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const profileImageRef = useRef(null);
  const [profileImage, setProfileImage] = useState()

  const access_token = localStorage.getItem("access_token");
  const user_id = localStorage.getItem("user_id");
  const [uploadImage, setUploadImage] = useState(null);


  const handleCoverImageChange = async (event, errorKey) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = () => {
        setProfileImage(selectedFile);
      };
      reader.readAsDataURL(selectedFile);
      // handleProfileImageUpdate()
    }
  };

  const handleImageChange = (event) => {
    setUploadImage(event.target.files[0]);
  };
  const handleFileChange = (event, profileImage, uploadImage) => {
    const file = event.target.files[0];
    setUploadImage(file);
  };

  const [inputField, setInputField] = useState({ user_name: '', user_type: '', image: '' });

  const CheckUsertype = (e) => {
    if (e.target.checked === true) {
      setInputField({ user_type: "Kids" })
    } else {
      setInputField({ user_type: "Normal" })
    }
  }
  const handleChange = (evt) => {
    const value = evt.target.value;
    setInputField({
      ...inputField,
      [evt.target.name]: value
    });
  }
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };


  const [errField, setErrField] = useState({ user_name: "", image: "" });

  const validForm = (e) => {
    e.preventDefault();


    let formIsValid = true;

    if (inputField?.user_name == "") {
      formIsValid = false;
      setErrField((prevState) => ({
        ...prevState,
        username: `${t("Please Enter Name")}*`,
      }));
    }


    if (inputField?.user_name !== "") {
      handleCreate();
    }
    return formIsValid;
  };
  // const createNewProfile = (e) => {

  //   const formData = new FormData();
  //   formData.append("user_name", 'test');
  //   formData.append('user_type', 'Normal');
  //   formData.append('avatar', uploadImage);
  //   formData.append("user_id", user_id);
  //   try {
  //     const response = axios.post(
  //       `${process.env.REACT_APP_Baseurl}/Frontend/Multi-Profile/store`,
  //       formData,
  //       { headers: headers }
  //     );
  //    console.log(response?.data)
  //     if (response?.data?.status == true) {
  //       toast.success(response?.data?.message, {
  //         position: "top-right",
  //         autoClose: 3000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "colored",
  //       });
  //       navigate('/choose-profile')
  //     }
  //     else {
  //       alert("Error creating user");
  //     }
  //   } catch (error) { console.log(error) }
  // }

  const handleCreate = async (e) => {
    //e.preventDefault();

    const formData = new FormData();
    formData.append("user_id", user_id);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .post(`${process.env.REACT_APP_Baseurl}/Multi-Profile/store`, formData, {
        headers: headers,
      })
      .then((response) => {
        //console.log(response.data)
        if (response?.data?.status == "true") {
          toast.success(t(response?.data?.message), {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((error) => {
        alert("Error updating user");
      });
  };
  return (
    <div>
      <form onSubmit={validForm}>
        <section className="editmultiple mt-4 mb-5 paddingTop">
          <div className="container">

            <div className='position-relative profileImageUpdate mx-md-0 mx-auto' >
              <LazyLoadImage key={'logo'} src={profileImage ? URL.createObjectURL(profileImage) : 'https://api.flicknexs.com/assets/img/default_users_image.png'} alt={'logo'} title={'Avatar'} imageSize="overflow-hidden  object-fit-cover" classNameOptional="rounded-circle profileImageUpdate" />
              <span className='profileImageLens position-absolute theme-bg-color-secondary p-2 rounded-circle d-flex align-items-center justify-content-center' >
                <IconMyCamera styled={{ width: "20px", height: "20px", className: "" }} />
              </span>
              <input type="file" onChange={(event) => handleCoverImageChange(event)} className='profileInput w-100 h-100 position-absolute' accept='image/*' />
              <IconMyUpload styled={{ width: "20px", height: "20px", className: "position-absolute uploadIcon" }} />
            </div>
            <div className="d-flex justify-content-center text-center ">
              <div className="box-editmultiple  global-shadow rounded-3 mt-4">
                <h3 className="text-center mt-2 mb-2">{t("New Profile")}</h3>
                <div>
                  <img className="avatar_img " alt="Avatar" src="https://api.flicknexs.com/assets/img/default_users_image.png" />
                  <div
                    className="imagedrop mt-2"
                    onClick={() => profileImage.current.click()}
                  ><br />
                    <input
                      type="file"
                      ref={profileImageRef}
                      onChange={(event) =>
                        handleFileChange(
                          event,
                          profileImage,
                          setUploadImage
                        )
                      }
                      accept="image/*"
                      style={{ display: "none" }}
                      className=''
                    />
                    <button className="btn btn button" >
                      Change
                    </button>
                  </div>

                </div>
                <div>
                  <input
                    type="text"
                    className="mt-4 w-100 p-2 form-control"
                    id="user_name"
                    name="user_name"
                    placeholder={`${t('Enter Name')} *`}
                    onChange={handleChange}
                    value={inputField?.user_name}

                  />
                  {errField?.user_name !== "" && (
                    <span className="profileerror">{t(errField?.user_name)}</span>
                  )}
                </div>
                <div>
                </div>
                <div className="d-flex justify-content-between kid_profile">
                  <div className="">
                    <p>{t("kid's profile")} ?</p>
                  </div>
                  <div className="">
                    <label className="switch">
                      <input
                        id='user_type'
                        name="user_type"
                        onChange={CheckUsertype}
                        value={inputField?.user_type}
                        className="rs-input"
                        type="checkbox"
                      />
                      <span
                        id='user_type'
                        className="slider round"
                        name="user_type"
                        onChange={CheckUsertype}
                        value={inputField?.user_type}

                      ></span>
                    </label>
                  </div>
                </div>
                <div className="kid" >
                  <span>{t("TV shows and movies for ages 12 and under")}</span>
                  <span>{t("Learn More")}</span>
                </div>

                <button type="submit"// onClick={createNewProfile}
                  className="btn btn mt-4 w-75 button">{t("Save Changes")}
                </button>

              </div>
            </div>
          </div>
        </section>
      </form>
    </div>

  );
}

export default CreateMultiprofile;

