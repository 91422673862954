import React, { useEffect, useState } from 'react'
import LazyLoadImage from '../../../Pages/HomePage/HomeSwiperComponents/LazyLoadImage'
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from "react-i18next";
import { LogOutApi, displayTranslateLanguageAction, headerIndexAction, profileDetailsAction, siteThemeAction, userChangeLanguage } from '../../../Redux/Actions/apiActions'
import { getItem, getItemToken } from '../../../Utils/localStorageUtils'
import { IconMyCredit, IconMyDevice, IconMyFile, IconMyLogOut, IconMyMember, IconMyMode, IconMyNextArrow, IconMyProfile, IconMySearch, IconMyUser, IconMyUsers } from '../../../Images/MyIcons'
import DropDown from "../../../components/Images/dropdown.png";
import HeaderLoader from '../../../Layout/HeaderLoader'
import MessageBox from '../../../components/Others/MessageBox';
import { GiHamburgerMenu } from 'react-icons/gi';
import LoginIndex from '../../../Pages/Auth/LoginIndex';
import SignUpIndex from '../../../Pages/Auth/SignUpIndex';
import { themeToggleMethod } from '../../../Pages/CommonMethods/CommonMethods';
import { BsCheck2Circle, BsTranslate } from 'react-icons/bs';
import "./ThemeTwoLayout.css"
import classNames from "classnames";
const ThemeTwoHeader = ({ themeMode, headerMenus, isLoading, profileDetails, languageDetailsData, settings, languages }) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const { lang } = useParams()
    const navigate = useNavigate();
    const [themeData, setThemeData] = useState(null)
    const [modalOpen, setModalOpen] = useState({
        signUpModal: false,
        signInModal: false,
    })

    const [toggle, setToggle] = useState(false);

    const userRoleLocal = getItem('role')
    const userIdLocal = getItem('user_id')
    const userAccessToken = getItemToken('access_token');



    const menuItem = ["Home", "Movies", "TV Shows", "Categories", "Watchlist", "Music"]
    const firstName = profileDetails?.Profile_details?.username || "";
    const lastName = profileDetails?.Profile_details?.last_name || "";

    const initialsname = firstName && lastName ? firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase() : "";


    useEffect(() => {
        dispatch(headerIndexAction());
    }, [themeData]);

    useEffect(() => {
        if (userRoleLocal) {
            dispatch(profileDetailsAction());
        }
    }, [settings, userRoleLocal])

    useEffect(() => {
        dispatch(siteThemeAction());
        dispatch(displayTranslateLanguageAction());
    }, []);

    const renderNavLink = (link) => (
        <NavLink
            to={
                link.url === "site_url"
                    ? `/${lang}${link.select_url.replace(/ /g, "_").toLowerCase()}`
                    : link.url === "custom_url"
                        ? link.custom_url
                        : `/${lang}${link.url}`
            }
            key={link.url}
            onClick={() => setToggle(!toggle)}
            className="homeNavLink d-flex align-items-center gap-3 themeTwoLink"
        >
            <img src={link.menu_image_url} className="homeNavImage" />
            <span className="theme-text-color">{t(link.name)}</span>
        </NavLink>
    );

    const renderDropdown = (links) => (
        <li className="homeNavLink  ">
            <span className="mb-0 d-flex align-items-center gap-3">
                <img src={links.menu_image_url} className="homeNavImage" />
                <span className="theme-text-color">{t(links.name)}</span>
                <IconMyNextArrow styled={{ className: "homeNavImageArrow ms-2" }} />
            </span>
            <ul className="list-unstyled  homeNavDropDown d-none pt-2 pb-3 theme-bg-color">
                {links?.dropdown?.map((link) => (
                    <li onClick={() => setToggle(!toggle)} key={link.slug}>
                        <NavLink
                            to={`${links?.type
                                ?.replace(/_/g, "-")
                                .replace(/\s/g, "-")
                                .toLowerCase()}/${link?.slug}`}
                            key={link.slug}
                            className=" d-block px-3 mt-2 homeNavLink theme-text-color"
                        >
                            {t(link.name)}
                        </NavLink>
                    </li>
                ))}
            </ul>
        </li>
    );

    const handleLogout = () => {
        dispatch(LogOutApi(navigate))
    };
    const handleSignInModal = async () => {
        setModalOpen((prevState) => ({ ...prevState, signInModal: !prevState.signInModal }));
        document.body.classList.remove('overflow-hidden');
    }

    const handleSignUpModal = async () => {
        setModalOpen((prevState) => ({ ...prevState, signUpModal: !prevState.signUpModal }));
        document.body.classList.remove('overflow-hidden');
    }


    const handleSignInSwitchModal = async () => {
        setModalOpen((prevState) => ({ ...prevState, signInModal: !prevState.signInModal, signUpModal: !prevState.signUpModal }));
    }

    const handleSignUpSwitchModal = async () => {
        setModalOpen((prevState) => ({ ...prevState, signUpModal: !prevState.signUpModal, signInModal: !prevState.signInModal, }));
    }

    const handleToggle = () => {
        setToggle(!toggle);
    };
    const toggleTheme = async () => {
        try {
            const data = await themeToggleMethod(themeMode?.currentMode == "dark" ? 1 : 0);
            setThemeData(data)
        } catch (error) {
            console.error("Error toggling theme:", error);
        }
    };
    const themeStyle = {
        left: `${themeMode?.currentMode === "light" ? "3px" : "24px"}`,
        backgroundColor: `${themeMode?.currentMode === "light" ? "#fff" : "#fff"}`,
    };
    useEffect(() => {
        dispatch(headerIndexAction());
    }, [themeData]);

    useEffect(() => {
        const handleClick = () => {
            if (modalOpen?.signInModal || modalOpen?.signUpModal) {
                document.body.classList.add('overflow-hidden'); // Add class when modal is open
            } else {
                document.body.classList.remove('overflow-hidden'); // Remove class when modal is closed
            }
        };

        // Attach event listener
        document.addEventListener('click', handleClick);

        // Cleanup function to remove event listener when component unmounts or modal state changes
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [modalOpen?.signInModal, modalOpen?.signUpModal]);


    return (
        <>
            {modalOpen?.signInModal && <LoginIndex popUpClass="position-fixed popupModal d-flex align-items-center justify-content-center" headerDisable modalClose={handleSignInModal} signUpModal={handleSignUpSwitchModal} signInModal={handleSignInSwitchModal} />}
            {modalOpen?.signUpModal && <SignUpIndex popUpClass="position-fixed h-100 popupModal d-flex align-items-center justify-content-center" headerDisable modalClose={handleSignUpModal} signUpModal={handleSignUpSwitchModal} signInModal={handleSignInSwitchModal} />}

            {isLoading ? <HeaderLoader /> :
                <header className='d-flex align-items-center justify-content-between position-fixed themeTwoHeader w-100'>
                    <button type="button" className='bg-transparent d-block d-md-none position-relative zIndexOne'>
                        <GiHamburgerMenu className="toggleIconHome" onClick={handleToggle} />
                    </button>
                    <Link to="/" className="py-1 position-relative homeNavbarBrand" style={{ width: "171px", height: "42px", objectFit: "contain" }}>
                        <LazyLoadImage src={themeMode?.logoUrl} alt="header-logo" imageSize="loaderMain object-fit-contain " classNameOptional="rounded-2" />
                    </Link>
                    <ul className={`d-flex align-items-center justify-content-between m-0 p-0 gap-5 list-styled themeTwoNavMobileFixed ${toggle && 'active'}`}>
                        {
                            headerMenus?.filter((url) => url?.active).map((url, index) => (
                                <li className={`homeNavList py-2`} key={url.name}>
                                    {url.dropdown.length > 0
                                        ? renderDropdown(url)
                                        : renderNavLink(url)}
                                </li>
                            ))}
                    </ul>
                    <div className='d-flex align-items-center gap-3'>
                        {/* <div className='position-relative d-none d-md-block'>
                            <input type='text' className='theme-bg-color-secondary  border-0 rounded-2 ps-5 pe-2 py-2 searchBarThemeTwoInput' placeholder='Search' onChange={handleSearch} value={searchValue} />
                            <IconMySearch styled={{ width: "20px", height: "20px", className: "searchBarThemeTwo position-absolute " }} />
                        </div> */}
                        <Link to={`/${languageDetailsData?.Language_Code}/search`}>
                            <IconMySearch styled={{ width: "20px", height: "20px", className: "searchBarThemeTwo " }} />
                        </Link>
                        <div className="dropdown">
                            <button
                                className="bg-transparent"
                                type="button"
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <BsTranslate
                                    style={{ width: "25px", height: "25px" }}
                                    className="theme-text-color"
                                />
                            </button>
                            <ul className="dropdown-menu theme-bg-color theme-text-color languageDrop" style={{ maxWidth: "5rem" }} aria-labelledby="dropdownMenuButton1" >
                                {languages.map(({ code, name }) => (
                                    <li key={code} className={classNames("theme-text-color dropdown-item d-flex align-items-center gap-2 ", { disabled: languageDetailsData?.Language_Code === code, })} style={{ cursor: languageDetailsData?.Language_Code === code ? "" : "pointer", opacity: languageDetailsData?.Language_Code === code ? 1 : 0.7 }} onClick={() => dispatch(userChangeLanguage(code, navigate))}>
                                        <span>{name}</span>
                                        {languageDetailsData?.Language_Code === code && (<BsCheck2Circle className=" theme-text-color" style={{ fontSize: "25px" }} />)}
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="position-relative profileImageThemeTwo">
                            {!userRoleLocal ? <div onClick={handleSignInModal} className='theme-text-color'>
                                <IconMyUser styled={{ width: "20px", height: "20px", className: "" }} />
                            </div> :
                                profileDetails?.Profile_details?.user_avatar ? <LazyLoadImage src={profileDetails?.Profile_details?.user_avatar} alt="header-logo" imageSize="rounded-circle object-fit-cover " classNameOptional="rounded-circle themeTwoAvatar" /> : <MessageBox text={initialsname} />}
                            {userRoleLocal ? <div className='popupDetailsThemeTwo rounded-2 position-absolute pt-4'>
                                <ul className='text-center list-unstyled m-0 py-4 px-0 theme-bg-color-secondary'>
                                    <li className='d-flex align-items-center gap-3 profileThemeTwo justify-content-between '  >
                                        <div className='d-flex align-items-center gap-3 '><IconMyMode styled={{ width: "20px", height: "20px", opacity: "0.5" }} /><MessageBox classname='popupDetailLink' text={themeMode?.currentMode == "light" ? t("Light Mode") : t("Dark Mode")}></MessageBox></div>
                                        <div className="position-relative toggleButtonTheme toggleButton-border  cursor-pointer" onClick={toggleTheme}>
                                            <div className="toggleCircleButton bgButton" style={themeStyle} ></div>
                                        </div>
                                    </li>
                                    <li className='d-flex align-items-center gap-3 profileThemeTwo'><IconMyFile styled={{ width: "20px", height: "20px", opacity: "0.5" }} /><NavLink to={`/${languageDetailsData?.Language_Code}/myprofile/info`} className='popupDetailLink'>Account Info</NavLink></li>
                                    <li className='d-flex align-items-center gap-3 profileThemeTwo'><IconMyCredit styled={{ width: "20px", height: "20px", opacity: "0.5" }} /><NavLink to={`/${languageDetailsData?.Language_Code}/myprofile/activeplan`} className='popupDetailLink'>Plan Details</NavLink></li>
                                    <li className='d-flex align-items-center gap-3 profileThemeTwo'><IconMyDevice styled={{ width: "20px", height: "20px", opacity: "0.5" }} /><NavLink to={`/${languageDetailsData?.Language_Code}/myprofile/activedevice`} className='popupDetailLink'>Device Management</NavLink></li>
                                    <li className='d-flex align-items-center gap-3 profileThemeTwo'><IconMyUsers styled={{ width: "20px", height: "20px", opacity: "0.5" }} /><NavLink to={`/${languageDetailsData?.Language_Code}/myprofile/profiles`} className='popupDetailLink'>User Profiles</NavLink></li>
                                    <li className='d-flex align-items-center gap-3 profileThemeTwo'><IconMyUsers styled={{ width: "20px", height: "20px", opacity: "0.5" }} /><NavLink to={`/${languageDetailsData?.Language_Code}/choose-profile`} className='popupDetailLink'>Change Profile</NavLink></li>
                                    <li className='d-flex align-items-center gap-3 profileThemeTwo'><IconMyMember styled={{ width: "20px", height: "20px", opacity: "0.5" }} /><NavLink to={`/${languageDetailsData?.Language_Code}/myprofile/billing`} className='popupDetailLink'>Membership & Billing</NavLink></li>
                                    <li className='d-flex align-items-center gap-3 profileThemeTwo'><IconMyProfile styled={{ width: "20px", height: "20px", opacity: "0.5" }} /><NavLink to={`/${languageDetailsData?.Language_Code}/myprofile/parentalcontrol`} className='popupDetailLink'>Profile & Parental Control</NavLink></li>
                                    <li className='d-flex align-items-center gap-3 profileThemeTwo cursor-pointer' onClick={handleLogout}><IconMyLogOut styled={{ width: "20px", height: "20px", opacity: "0.5" }} /><MessageBox classname='popupDetailLink' text='Log out'></MessageBox></li>
                                </ul>
                            </div> : null}
                        </div>
                    </div>
                </header >}
        </>
    )
}


const mapStateToProps = (state, ownProps) => ({
    props: ownProps,
    settings: state.get_setting_Reducer.settings,
    headerMenus: state.get_headerIndex_Reducer.headerMenus,
    isLoading: state.get_headerIndex_Reducer.isLoading,
    themeMode: state.get_headerIndex_Reducer.themeMode,
    profileDetails: state.get_profileDetails_Reducer.profileDetails,
    siteTheme: state.get_siteTheme_Reducer.siteTheme,
    languages: state.get_displayTranslateLanguage_Reducer.languages,
    languageDetailsData: state.get_getUserTranslateLanguage_Reducer.languageDetailsData
});
export default connect(mapStateToProps)(ThemeTwoHeader);
