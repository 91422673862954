import React, { useEffect, useState } from 'react'
import Signin from '../../components/Signin/Signin'
import SignInThemeTwo from '../../ThemeTwo/Components/Auth/SignInThemeTwo'
import { toast } from 'react-toastify'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { getItem, getItemToken } from '../../Utils/localStorageUtils'
import { connect } from 'react-redux'
import { useDispatch } from 'react-redux'
import { getUserTranslateLanguageAction } from '../../Redux/Actions/apiActions'
import "../../components/Others/Styles/Popup.css"

const LoginIndex = ({ languageDetailsData, popUpClass, headerDisable, modalClose, signInModal, signUpModal, currentTheme, settings }) => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { lang } = useParams()
    const dispatch = useDispatch()

    const [inputField, setInputField] = useState({ email: "", password: "" });
    const [errField, setErrField] = useState({ emailerr: "", passworderr: "" });
    const [loading, setLoading] = useState(false)
    const [passwordType, setPasswordType] = useState(true);

    const userRoleLocal = getItem('role')
    const userIdLocal = getItem('user_id')
    const userAccessToken = getItemToken('access_token');

    const LoginAuth = async () => {
        try {
            setLoading(true);

            const response = await axios.post(`${process.env.REACT_APP_Baseurl}/users/signin`, {
                email: inputField?.email,
                password: inputField?.password
            });

            if (response.status === 200) {
                const result = response?.data;
                const resultUser = result?.user;
                if (result?.status) {
                    if (!resultUser?.active) {
                        navigate(`/${languageDetailsData?.Language_Code}/verify/code`)
                    }
                    else {
                        const response = await axios.post(`${process.env.REACT_APP_Baseurl}/Front-End/user-login-devices`, {
                            Login_user_type: Number(resultUser?.role),
                            Login_device_type: "web",
                            Login_user_id: Number(resultUser?.id),
                        });
                        await localStorage.setItem("access_token", result?.access_token);
                        localStorage.setItem("user_id", resultUser?.id);
                        localStorage.setItem("role", Number(resultUser?.role));
                        localStorage.setItem("username", resultUser?.username);
                        localStorage.setItem("last_name", resultUser?.last_name);
                        localStorage.setItem("image_url", resultUser?.avatar_url);
                        localStorage.setItem("email", resultUser?.email);
                        localStorage.setItem("expires_in", result?.expires_in);

                        toast.success(t(result?.message));
                        dispatch(getUserTranslateLanguageAction(navigate))

                        //#region subscriber
                        // if (settings?.choose_profile_status && resultUser?.subscription_status){
                        if (settings?.choose_profile_status) {
                            navigate(`/${languageDetailsData?.Language_Code}/choose-profile`);
                        } else {
                            navigate(`/${languageDetailsData?.Language_Code}`);
                        }
                    }
                    if (headerDisable) {
                        await modalClose();
                    }

                } else {
                    // Handle unexpected false status
                    throw new Error('Unexpected response status.');
                }
            } else {
                // Handle unexpected status codes
                throw new Error('Login failed. Please try again.');
            }
        } catch (error) {
            console.error("Login error:", error);
            toast.error(t("These credentials do not match our records"));
        } finally {
            setLoading(false);
        }
    };



    const validateForm = () => {
        let formIsValid = true;
        if (!inputField.email) {
            setErrField((prevState) => ({ ...prevState, emailerr: t("Email cannot be empty"), }));
            formIsValid = false;
        } else if (!/\S+@\S+\.\S+/.test(inputField.email)) {
            setErrField((prevState) => ({ ...prevState, emailerr: t("Invalid email address"), }));
            formIsValid = false;
        } else {
            setErrField((prevState) => ({ ...prevState, emailerr: "", }));
        }
        if (!inputField.password) {
            setErrField((prevState) => ({ ...prevState, passworderr: t("Password cannot be empty"), }));
            formIsValid = false;
        } else {
            setErrField((prevState) => ({ ...prevState, passworderr: "", }));
        }
        return formIsValid;
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (validateForm()) {
            LoginAuth()
        } else {
            console.log('Form validation failed');
        }
    };

    const togglePassword = () => {
        setPasswordType(!passwordType)
    };

    const handleChange = (e) => {
        setInputField({ ...inputField, [e.target.name]: e.target.value });
    }

    useEffect(() => {
        if (userRoleLocal) {
            navigate(`/${lang}`)
        }
    }, [])


    return (
        <div>
            {currentTheme == 1 && <Signin authAction={handleSubmit} passAction={togglePassword} error={errField} field={inputField} onchange={handleChange} show={passwordType} data={{ lang: languageDetailsData, load: loading, pop: popUpClass, headerDisable: headerDisable }} modalClose={modalClose} signUpModal={signUpModal} signInModal={signInModal} />}
            {currentTheme == 2 && <SignInThemeTwo authAction={handleSubmit} passAction={togglePassword} error={errField} field={inputField} onchange={handleChange} show={passwordType} data={{ lang: languageDetailsData, load: loading, pop: popUpClass, headerDisable: headerDisable }} modalClose={modalClose} signUpModal={signUpModal} signInModal={signInModal} />}
        </div>
    )
}

const mapStateToProps = (state) => ({
    languageDetailsData: state.get_getUserTranslateLanguage_Reducer.languageDetailsData,
    currentTheme: state.get_headerIndex_Reducer.currentTheme,
    settings: state.get_setting_Reducer.settings,
});
export default connect(mapStateToProps)(LoginIndex);