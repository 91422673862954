import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import "./Contentaddaudioalbum.css";
import { toast } from "react-toastify";
 
import loadings from "../../../components/Images/loading/loading.gif";
import ResponseLoader from "../../../components/Others/ResponseLoader";

function Contentaddaudioalbum() {
  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [editUser, setEditUser] = useState({
    title: "",
    slug: "",
  });
  const [user, setUser] = useState([]);
  const [parent_id, setParent_id] = useState([]);

  const navigate = useNavigate();

  // let access_token, access_token_cpp;

  // if (localStorage.getItem("username")) {
  //   access_token = localStorage.getItem("access_token");
  // } else {
  //   access_token_cpp = localStorage.getItem("access_token_cpp");
  // }

  // const headers = {
  //   "Content-Type": "application/json",
  //   // Authorization: "Bearer " + access_token_cpp,
  //   Accept: "application/json",
  //   "Access-Control-Allow-Origin": "*",
  // };

  // if (access_token) {
  //   headers.Authorization = "Bearer " + access_token;
  // } else if (access_token_cpp) {
  //   headers.Authorization = "Bearer " + access_token_cpp;
  // }

  const access_token_cpp = localStorage.getItem("access_token_cpp");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token_cpp,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const fileInputRef1 = useRef(null);

  const [selectedFile1, setSelectedFile1] = useState(null);

  const handleFileChange = (event, fileInputRef, setSelectedFile) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDelete = (setSelectedFile1) => {
    setSelectedFile1(null);
    if (fileInputRef1.current) {
      fileInputRef1.current.value = null;
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessageslug("");
    setIsValidationHiddenslug(true);
    setValidationMessagetitle("");
    setIsValidationHiddentitle(true);

    setEditUser({ ...editUser, title: e.target.value });
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };

  const [autoFillValuetitle, setAutoFillValuetitle] = useState("");

  const handleInputKeyDowntitle = (event) => {
    if (event.key == "Enter" || event.key == "Tab") {
      generateSlug(editUser?.title);
    }
  };
  const handleBlurtitle = () => {
    generateSlug(editUser?.title);
  };

  const handleAutoFillChangetitle = (event) => {
    // setValidationMessageslug("");
    // setIsValidationHiddenslug(true);
    setAutoFillValuetitle(event.target.value);
  };

  const generateSlug = (title) => {
    title = title.trim();
    const slug = title.toLowerCase().replace(/\s+/g, "-");
    setAutoFillValuetitle(slug);
  };

  const [validationMessagetitle, setValidationMessagetitle] = useState("");
  const [isValidationHiddentitle, setIsValidationHiddentitle] = useState(true);

  const [validationMessageslug, setValidationMessageslug] = useState("");
  const [isValidationHiddenslug, setIsValidationHiddenslug] = useState(true);

  const titleInputRef = useRef(null);

  async function submitalbum() {
    let focusInputRef = null;

    if (editUser?.title == "") {
      setValidationMessagetitle("Title cannot be empty*.");
      setIsValidationHiddentitle(false);
      focusInputRef = titleInputRef;
    }
    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }

    const formData = new FormData();

    formData.append("Content_Partner_id", 1);
    formData.append("albumname", editUser?.title);
    formData.append("slug", autoFillValuetitle);
    formData.append("parent_id", parent_id);

    let firstApiStatus = false;
    let secondApiStatus = false;
    let resultapi = null;
    let resultapifalse = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/Audio-Album/store`,
        formData,
        { headers: headers }
      );

      if (response.data.status == true) {
        firstApiStatus = true;

        resultapi = response?.data;
        var album_id = response?.data?.AudioAlbum_id;

        const imageFormData = new FormData();
        imageFormData.append("audio_album_id", album_id);

        if (selectedFile1) {
          imageFormData.append("album_image", selectedFile1);
        }

        const imageResponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/CPP/Backend/Audio-Album/Image-upload`,
          imageFormData,
          { headers: headers }
        );

        if (imageResponse.data.status == true) {
          secondApiStatus = true;
        }
      } else if (response?.data?.status == false) {
        // Handle the case when the first API response status is false
        resultapifalse = response?.data;
      }
    } catch (error) {}

    if (firstApiStatus && secondApiStatus) {
      setValidationMessagetitle("");
      setValidationMessageslug("");
      // props.setApiresponsealert(resultapi);
      // props.setShowalert(true);
      toast.success(resultapi?.message);
      setTimeout(async () => {
        await navigate("/cpp/audio-manage-albums");
      }, 3000);
    } else if (resultapifalse) {
      // props.setApiresponsealert(resultapifalse);
      // props.setShowalert(true);
      toast.error(resultapifalse?.message);
      setProcessing(false);
      setShowOverlay(false);
      setIsValidationHiddentitle(false);
      setValidationMessageslug(false);
    }
  }

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/CPP/Backend/Audio-Album/create`, {
        headers: headers,
      })
      .then((response) => {
        var result = response?.data?.AudioGenre;
        setUser(result);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className=" m-0 p-0">
      <section className="container-fluid  theme-bg-color-secondary ">
        <div className="col-lg-12 row py-3">
          <div className="col-6 col-sm-6 col-lg-6">
            <h3 className=" theme-text-color">Add Album</h3>
          </div>
          <div className="col-6 col-sm-6 col-lg-6">
            <div className="text-end">
              <button onClick={submitalbum} className="btn btn-primary">
                Save Audio Album
              </button>
            </div>
          </div>
        </div>

        <div>
        {showOverlay && (<ResponseLoader />)}
        </div>

        <div className="row col-lg-12 ">
          <div className=" col-sm-6 col-md-6 col-lg-6 ">
            <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
              <h5 className=" theme-text-color">Create Album</h5>
              <hr className=" theme-text-color"></hr>
              <div className=" text-start">
                <label className="m-0 theme-text-color">
                  Name<span className="mandatory">*</span>
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                    id="title"
                    name="title"
                    placeholder="Enter Name"
                    onChange={handleInput}
                    value={editUser?.title}
                    onBlur={handleBlurtitle}
                    onKeyDown={handleInputKeyDowntitle}
                    ref={titleInputRef}
                  />
                </div>
                <span className="errorred">
                  {!isValidationHiddentitle && <p>{validationMessagetitle}</p>}
                </span>
              </div>
              <div className="mt-2 text-start">
                <label className="m-0 theme-text-color">Slug</label>

                <div className="mt-2">
                  <input
                    type="text"
                    className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                    id="slug"
                    name="slug"
                    placeholder="Enter Slug"
                    value={autoFillValuetitle}
                    onChange={handleAutoFillChangetitle}
                  />
                </div>
              </div>

              <div className="row mt-2 text-start">
                <div className="col-12">
                  <label className="m-0 theme-text-color">Category</label>

                  <div className="mt-2">
                    <select
                      className="form-select  theme-bg-color-secondary  border-0 theme-text-color"
                      name="parent_id"
                      id="parent_id"
                      onChange={(e) => setParent_id(e.target.value)}
                    >
                      <option value="0">Choose a Category </option>
                      {user?.map((item) => (
                        <option value={item?.parent_id}>
                          {item?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="text-start mb-3">
                <button
                  onClick={submitcategory}
                  className="btn btn-primary"
                >
                 Save Audio Category
                </button>
              </div> */}
          </div>

          {/* Second Part of Section  */}

          <div className=" col-sm-6 col-md-6 col-lg-6">
            <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
              <h5 className=" theme-text-color">Thumbnails</h5>
              <hr className=" theme-text-color"></hr>

              <div className=" text-start mt-2">
                <label className="m-0 theme-text-color">Cover Image</label>

                <div className="mt-2">
                  <div className="col-lg-12 row">
                    <div className=" col-sm-6 col-lg-6">
                      <div
                        className="imagedrop"
                        onClick={() => fileInputRef1.current.click()}
                      >
                        <input
                          type="file"
                          ref={fileInputRef1}
                          onChange={(event) =>
                            handleFileChange(
                              event,
                              fileInputRef1,
                              setSelectedFile1
                            )
                          }
                          accept="image/*"
                          style={{ display: "none" }}
                        />
                        <label className=" theme-text-color">
                          <CameraRetroIcon className=" theme-text-color" />
                        </label>
                      </div>
                    </div>
                    <div className=" col-sm-6 col-lg-6">
                      <div>
                        {selectedFile1 && (
                          <div className="imagedropcopy text-end">
                            <div className="col-lg-12 row p-0 m-0">
                              <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                <img
                                  src={URL.createObjectURL(selectedFile1)}
                                  alt="Uploaded"
                                  className="thumbnails-upload-images"
                                />
                              </div>
                              <div
                                className="col-sm-2 col-lg-2 p-0 m-0"
                                style={{ alignSelf: "center" }}
                              >
                                <button
                                  onClick={() => handleDelete(setSelectedFile1)}
                                  type="button"
                                  className="btn-close theme-text-color"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Contentaddaudioalbum;
