// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reelvideo-player {
    /* height: 100%; */
    width: 260px;
    object-fit: cover;
    overflow: hidden;
    border-radius: 10px;
  }
  
  .video-cards {
    position: relative;
    padding: 10px;
}
  
  video {
    width: 100%;
    height:90%;
    min-width: 188px;
    scroll-snap-align: start;
    /* position: relative; */
  }
  /* .video-container {
    position: relative;
    height: 70vh;
    width: 70%;
    max-width: 500px;
    border-radius: 20px;
    background-color: white;
    overflow: scroll;
    scroll-snap-type: y mandatory;
    scrollbar-width: none;
  }
    
  .video-container::-webkit-scrollbar {
    display: none;
  } */
  
  `, "",{"version":3,"sources":["webpack://./src/components/Reels/Reels.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,iBAAiB;IACjB,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;IAClB,aAAa;AACjB;;EAEE;IACE,WAAW;IACX,UAAU;IACV,gBAAgB;IAChB,wBAAwB;IACxB,wBAAwB;EAC1B;EACA;;;;;;;;;;;;;;KAcG","sourcesContent":[".reelvideo-player {\n    /* height: 100%; */\n    width: 260px;\n    object-fit: cover;\n    overflow: hidden;\n    border-radius: 10px;\n  }\n  \n  .video-cards {\n    position: relative;\n    padding: 10px;\n}\n  \n  video {\n    width: 100%;\n    height:90%;\n    min-width: 188px;\n    scroll-snap-align: start;\n    /* position: relative; */\n  }\n  /* .video-container {\n    position: relative;\n    height: 70vh;\n    width: 70%;\n    max-width: 500px;\n    border-radius: 20px;\n    background-color: white;\n    overflow: scroll;\n    scroll-snap-type: y mandatory;\n    scrollbar-width: none;\n  }\n    \n  .video-container::-webkit-scrollbar {\n    display: none;\n  } */\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
