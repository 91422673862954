// External Libraries and Packages
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
// Internal Styles

// Internal Components
import { videoCategoriesPageListAction, viewAllPageCategoryIdAction } from "../../Redux/Actions/apiActions";
import PageLoader from "../Others/PageLoader";
import PageError from "../Others/PageError";
import LayoutViewAll from "./Source/LayoutViewAll";
import { useTranslation } from "react-i18next";

const CategoryBasedVideoViewAll = ({ videoCatPageList, viewallPageCatId, isLoading, error, settings }) => {
  const { source, slug } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const user_role = localStorage.getItem("role");
  const user_id = localStorage.getItem("user_id");
  const access_token = localStorage.getItem("access_token");
  const headers = {
    "Content-Type": "application/json",
    headers: { "Access-Control-Allow-Origin": "*" },
    Authorization: "Bearer " + access_token,
  };

  const [videoCategoriesData, setVideoCategoriesData] = useState([]);
  useEffect(() => {
    const requestApi = `${process.env.REACT_APP_Baseurl}/Front-End/videoCategories-Page-List`;
    const VideoBasedApi = `${process.env.REACT_APP_Baseurl}/Front-End/videos-based-Categories-Page-List`;
    if (settings && Object.keys(settings)?.length > 0) {
      if (!user_role) {
        try {
          dispatch(videoCategoriesPageListAction(null, 9, "web", headers, requestApi))
            .then((data) => {
              const filteredData = data.filter((item) => item.slug == slug);
              const get = filteredData.flatMap((item) => item.slug);
              dispatch(viewAllPageCategoryIdAction(null, 9, "web", get[0], headers, VideoBasedApi));
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        } catch (error) {
          console.error("Error:", error);
          console.log("not found");
        }
      } else {
        try {
          dispatch(videoCategoriesPageListAction(user_id, user_role, "web", headers, requestApi))
            .then((data) => {
              const filteredData = data.filter((item) => item.slug == slug);
              const get = filteredData.flatMap((item) => item.slug);
              dispatch(viewAllPageCategoryIdAction(user_id, user_role, "web", get[0], headers, VideoBasedApi));
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        } catch (error) {
          console.error("Error:", error);
          console.log("not found");
        }
      }
    }
  }, [settings, user_role, dispatch, user_id, location]);

  useEffect(() => {
    setVideoCategoriesData(
      (viewallPageCatId?.data || []).flatMap(
        (item) => item.category_videos || []
      )
    );
  }, [viewallPageCatId]);
  function capitalizeText(text) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }
  return isLoading ? <PageLoader /> : error ? <PageError /> : videoCatPageList?.some((item) => item.slug == slug) && <div className="paddingTop">
    <LayoutViewAll mapData={videoCategoriesData} source={`${t('Video Category')} ${t(capitalizeText(slug))}`} path='videos' headingClass='pb-4 text-center' filterSettings={true}/>
  </div>
};
const mapStateToProps = (state) => ({
  videoCatPageList: state.get_videoCatPageList_Reducer.videoCatPageList,
  viewallPageCatId: state.get_viewAll_CatId_Reducer.viewallPageCatId,
  isLoading: state.get_viewAll_CatId_Reducer.isLoading,
  error: state.get_viewAll_CatId_Reducer.error,
  settings: state.get_setting_Reducer.settings,
});

export default connect(mapStateToProps)(CategoryBasedVideoViewAll);
