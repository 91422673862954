import React, { useState, useCallback, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./Edit_seriesseason.css";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";
 
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import loadings from "../../../components/Images/loading/loading.gif";
import ReactPlayer from "react-player";
import ResponseLoader from "../../../components/Others/ResponseLoader";
import AdminSaveHeader from "../../../components/Others/adminCommonComponents/AdminSaveHeader";
import FormContainer from "../../CommonComponents/FormContainer";
import RightWrapper from "../../CommonComponents/RightWrapper";
import Wrapper from "../../CommonComponents/Wrapper";
import MessageHeading from "../../CommonComponents/MessageHeading";
import MessageLabel from "../../CommonComponents/MessageLabel";
import MessageSubHeading from "../../CommonComponents/MessageSubHeading";
import LeftWrapper from "../../CommonComponents/LeftWrapper";
import { IoClose } from "react-icons/io5";
import MessageBox from "../../../components/Others/MessageBox";

function Edit_seriesseason() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [editUser, setEditUser] = useState({
    id: "",
    user_id: "",
    series_id: "",
    trailer: "",
    image: "",
    access: "",
    ppv_price: "",
    ppv_interval: "",
    trailer_type: "",
    ios_product_id: "",
    uploaded_by: "",
  });
  const access_token_channel = localStorage.getItem("access_token_channel");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token_channel,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const [user_access, setUser_access] = useState([]);
  const [inapppurchase, setInappPurchase] = useState([]);

  const [selectedFile, setSelectedFile] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    setSelectedFile(file);
  }, []);

  const handleFileChangessss = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const fileInputRef1 = useRef(null);
  const [selectedFile1, setSelectedFile1] = useState(null);
  const handleFileChange = (event, fileInputRef, setSelectedFile) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDelete = (setSelectedFile1,) => {
    setSelectedFile1(null);
    if (fileInputRef1.current) {
      fileInputRef1.current.value = null;
    }
  };

  useEffect(() => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/series-season/edit/${id}`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.SeriesSeason[0];
      setEditUser(res);
      setLoading(false);
    };
    getUser();
    const create = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/series-season/create`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var result = resData?.user_access;
      var InappPurchase = resData?.InappPurchase;
      setUser_access(result);
      setInappPurchase(InappPurchase);
      var globelset = resData?.ppv_gobal_price;
      setInputValueAPI(globelset);
    };
    create();
  }, [id]);

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };

  const [showOverlay, setShowOverlay] = useState(false);
  const handleUpdate = async (e) => {
    e.preventDefault();
    const editInputvalue = {
      id: editUser?.id,
      user_id: editUser?.user_id,
      series_id: editUser?.series_id,
      trailer: editUser?.trailer,
      image: editUser?.image,
      access: editUser?.access,
      // ppv_price: editUser?.ppv_price,
      ppv_price: inputValue,
      ppv_interval: editUser?.ppv_interval,
      trailer_type: editUser?.trailer_type,
      ios_product_id: editUser?.ios_product_id,
      uploaded_by: editUser?.uploaded_by,
    };
    let firstApiStatus = false;
    let secondApiStatus = false;
    let seventhApiStatus = false;
    let resultapi = null;
    let resjson = null;
    setShowOverlay(true);
    try {
      const res = await fetch(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/series-season/update/${id}`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(editInputvalue),
        }
      );
      resjson = await res.json();
      if (resjson.status == true) {
        firstApiStatus = true;
        resultapi = resjson;
        const formData = new FormData();
        formData.append("season_id", id);
        if (selectedFile1) {
          formData.append("image", selectedFile1);
        }
        const imageResponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/series-season/Image_upload`,
          formData,
          { headers: headers }
        );

        const formDataseo = new FormData();
        formDataseo.append("season_id", id);
        formDataseo.append("trailer", selectedFile);
        formDataseo.append("trailer_type", editUser?.trailer_type);
        // formDataseo.append("trailer_filename", trailer_filename);
        const formDataseoresponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/series-season/Trailer_upload`,
          formDataseo,
          { headers: headers }
        );
        if (imageResponse.data.status == true) {
          secondApiStatus = true;
        }
        if (formDataseoresponse.data.status == true) {
          seventhApiStatus = true;
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }

    if (firstApiStatus && secondApiStatus && seventhApiStatus) {
      toast.success(resultapi?.message);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resjson.status == false) {
        var resultError = resjson;
        toast.error(resultError?.message);
        setShowOverlay(false);
      }
    }
  };

  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputValueAPI, setInputValueAPI] = useState("");

  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
    if (!checkboxChecked) {
      setInputValue(inputValueAPI);
    } else {
      setInputValue("");
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const [allliveuseraccessseason, setallliveuseraccessseason] = useState("");
  function allseasonuseraccessseason() {
    switch (allliveuseraccessseason) {
      case "guest":
        return <div></div>;
      case "subscriber":
        return <div></div>;
      case "registered":
        return <div></div>;
      case "ppv":
        return (
          <div className="">
            <div className="row m-0">
              <MessageLabel text="PPV Price" className="ps-0" />
              <div className="col-8 ps-0">
                <MessageSubHeading text="Apply PPV Price from Global Settings?" />
              </div>
              <div className="col-4 text-end">
                <label className="switch">
                  <input
                    name="ppv_price"
                    defaultChecked={checkboxChecked}
                    onChange={handleCheckboxChange}
                    type="checkbox"
                    className="rs-input"
                  />
                  <span
                    className="slider round"
                    name="ppv_price"
                    checked={checkboxChecked}
                    onChange={handleCheckboxChange}
                  ></span>
                </label>
              </div>
            </div>
            <div className="panel-body">
              <input
                type="text"
                className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder theme-text-color border-0 mb-3"
                placeholder="PPV Price"
                name="ppv_price"
                id="price"
                value={inputValue}
                onChange={handleInputChange}
              />
            </div>
            <MessageLabel text="IOS PPV Price" />
            <MessageSubHeading text="Apply IOS PPV Price from Global Settings?" />
            <div className="panel-body ppv_price_ios  mt-2">
              <select
                name="ios_product_id"
                onChange={handleInput}
                value={editUser?.ios_product_id}
                id="ios_product_id"
                className="form-select theme-bg-color-secondary custom-placeholder theme-text-color border-0 mb-3"
              >
                {inapppurchase?.map((item, key) => (
                  <option value={item.product_id} className="theme-bg-color theme-text-color">{item.plan_price}</option>
                ))}
              </select>
            </div>
            <MessageLabel text="PPV Interval" />
            <MessageSubHeading text=" Please Mention How Many Episodes are Free" />
            <div className="panel-body ppv_price_ios  mt-2">
              <input
                type="text"
                id="ppv_interval"
                name="ppv_interval"
                onChange={handleInput}
                value={editUser?.ppv_interval}
                className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder theme-text-color border-0"
              />
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  return (
    <>
      {showOverlay && <ResponseLoader />}
      <AdminSaveHeader heading="Edit Season" saveText="Update Season" saveMethod={handleUpdate} />
      <FormContainer>
        <LeftWrapper>
          <Wrapper>
            <MessageHeading text="Access" />
            <MessageLabel text="Choose User Access" className='' />
            <MessageSubHeading text="Who Is Allowed To View This Live Stream ?" />
            <select
              onChange={handleInput}
              name="access"
              id="access"
              className="form-select theme-bg-color-secondary custom-placeholder theme-text-color border-0 mb-3"
              value={editUser?.access}
              onClick={(event) => {
                setallliveuseraccessseason(event.target.value);
              }}
            >
              {user_access?.map((item) => (
                <option value={item?.value} className="theme-bg-color theme-text-color">{item?.name}</option>
              ))}
            </select>
            {allseasonuseraccessseason()}
          </Wrapper>
          <Wrapper>
            <MessageHeading text="Trailer" />
            <MessageLabel text="Season Trailer" className='' />
            {editUser?.trailer ? (<div className="">
              <ReactPlayer
                url={editUser?.trailer}
                controls={true}
                width="100%"
                height="200px"
              /></div>
            ) : null}

            <div className="file-draganddrop theme-border-color">
              <Dropzone accept="video/*" onDrop={onDrop}>
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <MessageBox text="Drag and drop a video file here, or click to select a file"  />
                  </div>
                )}
              </Dropzone>
              {selectedFile && (<MessageLabel text={selectedFile?.name} />)}
              <input
                type="file"
                accept="video/*"
                style={{ display: "none" }}
                onChange={handleFileChangessss}
              />
            </div>
          </Wrapper>
        </LeftWrapper>
        <RightWrapper>
          <Wrapper>
            <MessageHeading text="Thumbnails" />
            <MessageLabel text="Season Thumbnail" className='' />
            <MessageSubHeading text="(16:9 Ratio or 1080 X 1920px)" />
            <div>
              {loading ? (
                <img src={loadings} className="thumbnails-images" />
              ) : (
                <img
                  src={editUser?.image}
                  alt="Image"
                  className="thumbnails-images"
                />
              )}
            </div>
            <div className="mt-2">
              <div className="row my-2">
                <div className="col-6 ">
                  <div className="imagedropseason theme-border-color" onClick={() => fileInputRef1.current.click()} >
                    <input type="file" ref={fileInputRef1} onChange={(event) => handleFileChange(event, fileInputRef1, setSelectedFile1)} accept="image/*" style={{ display: "none" }} />
                    <label className=" theme-text-color">
                      <CameraRetroIcon className=" theme-text-color" />
                    </label>
                  </div>
                </div>
                <div className="col-6 p-0 ">
                  {selectedFile1 && (
                    <div className="imagedropcopyimage theme-border-color text-end">
                      <div className="row m-0">
                        <div className=" col-10 p-0 ">
                          <img src={URL.createObjectURL(selectedFile1)} alt="Uploaded" className="thumbnails-upload-images" />
                        </div>
                        <div className="col-2 p-0 align-self-center">
                          <button
                            onClick={() => handleDelete(setSelectedFile1)}
                            type="button"
                            className="theme-text-color bg-transparent"
                          > <IoClose className="custom-close-btn" /></button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Wrapper>
        </RightWrapper>
      </FormContainer>
    </>
  );
}

export default Edit_seriesseason;
