import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../../Contentportal/Sidebar/ContentSidebar";
import ContentHeader from "../../Contentportal/ContentHeader/ContentlHeader";
import ContentFooter from "../../Contentportal/ContentFooter/ContentFooter";

const ContentCommonLayout = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  return (
    <>
      <Sidebar toggleClassName={isOpen} />
      <div className="adminSideBarRight theme-bg-color-secondary">
        <ContentHeader toggleAction={toggle} />
        <div className="p-md-4 p-sm-2 p-0 adminHeadContainer">
          <Outlet />
        </div>
        <ContentFooter />
      </div>
    </>
  );
};

export default ContentCommonLayout;
