import React from "react";
import Sidebar from "../../Sidebar/Sidebar";
import EditSeriesCategory from "../../Pages/Series&Episode/EditSeriesCategory";
import ChannelHeader from "../../ChannelHeader/ChannelHeader";
import ChannelFooter from "../../Footer/Footer";

function ChannelEditCategorySeries({ setApiresponsealert, setShowalert }) {
  return (
    <>
      {/* <div className="row m-0 p-0">
        <div className="col-lg-3 p-0">
          {" "}
          <Sidebar />
        </div>
        <div className="col-lg-9 p-0">
          <div>
            <ChannelHeader />
          </div>
          <div> */}
            <EditSeriesCategory
              setApiresponsealert={setApiresponsealert} // Pass the props to EditManageVideoCategory
              setShowalert={setShowalert}
            />
          {/* </div>
          <div>
            <ChannelFooter />
          </div>
        </div>
      </div> */}
    </>
  );
}

export default ChannelEditCategorySeries;
