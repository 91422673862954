import React from 'react'
import { IconMyHeart, IconMyPlus, IconMyStar } from '../../../../Images/MyIcons'
import { calculateTotalTime } from '../../../CommonMethods/CommonMethods'
import "./BannerOverlayThemeTwo.css"
import { Link, useParams } from 'react-router-dom'

const BannerOverlayThemeTwo = ({ data, type, className, page }) => {
    const { lang } = useParams()
    return (
        <div className={`h-100 ${className}`}>
            <div className='col-md-8 col-lg-6 col-12 d-flex flex-column h-100'>
                <div>
                    <h1 className='swiperHeading'>{data?.title}</h1>
                    {data?.source_types_id ? <>
                        <div className='d-flex align-items-center gap-4 my-3'>
                            {data?.year && data?.year !== null && <p className='listSub m-0 circleAfter position-relative'>{data?.year?.slice(0, 4)}</p>}
                            {data?.duration && <p className='listSub m-0 circleAfter position-relative'>{calculateTotalTime(data?.duration)}</p>}
                            {data?.Languages?.length > 0 && <div className='position-relative languageWrapper'>
                                <p className='listSub m-0 circleAfter position-relative'> {data?.Languages?.slice(0, 1).map((item, index) => item?.name)}</p>
                                <ul className='list-unstyled m-0 p-0 text-center position-absolute theme-bg-color moreLanguageBox rounded-2 d-none'>
                                    {data?.Languages?.map((item, index) => <li className='px-2 py-3'>{item?.name}</li>)}
                                </ul>
                            </div>}
                        </div>
                        {data?.description && <p className='bannerDescription mb-5'>{data?.description}</p>}
                    </> : null}
                </div>

                <div className='d-flex align-items-center flex-wrap gap-4'>
                    <Link to={`${data?.source_types_id !== null ? `/${lang}/${data?.source_types_id == 1 ? 'videos' : data?.source_types_id == 2 ? 'live' : data?.source_types_id === 3 ? 'shows' : data?.source_types_id == 4 ? `series/${data?.series_slug}` : data?.source_types_id == 5 ? 'music/track' : ''}/${data?.slug}` : data?.link} `} className={`bannerSwiperBtn rounded-2 px-4 py-2 theme-button-bg-color theme-button-text-color ${!data?.source_types_id && 'mt-3'}`}>Watch Now</Link>
                    {data?.source_types_id ? <> <button className='bannerSwiperBtn theme-text-color-button rounded-2 px-4 py-2 border-1 theme-border-button-color'>Watch Trailer</button>
                        <button className='bannerSwiperBtn theme-text-color-button rounded-2 px-3 py-2 border-1 border theme-border-button-color'><IconMyHeart styled={{ width: "20px", height: "20px", className: "" }} /></button>
                        <button className='bannerSwiperBtn theme-text-color-button rounded-2 px-3 py-2 border-1 border theme-border-button-color'><IconMyPlus styled={{ width: "20px", height: "20px", className: "" }} /></button>
                        <button className='bannerSwiperBtn theme-text-color-button rounded-2 px-3 py-2 border-1 border theme-border-button-color'><IconMyStar styled={{ width: "20px", height: "20px", className: "" }} /></button>
                    </> : null}
                </div>
            </div></div >
    )
}

export default BannerOverlayThemeTwo