// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*--------------------------------------------------------------
Channel Sidebar Start
--------------------------------------------------------------*/
.sideMenu {
  padding: 10px 15px;
  cursor: pointer;
}

.sideMenu:active,
.sideMenu:focus,
.sideMenu:hover {
  color: inherit;
  text-decoration: none !important;
}

/*--------------------------------------------------------------
Channel Sidebar End
--------------------------------------------------------------*/`, "",{"version":3,"sources":["webpack://./src/channelportal/Sidebar/Sidebar.css"],"names":[],"mappings":"AAAA;;+DAE+D;AAC/D;EACE,kBAAkB;EAClB,eAAe;AACjB;;AAEA;;;EAGE,cAAc;EACd,gCAAgC;AAClC;;AAEA;;+DAE+D","sourcesContent":["/*--------------------------------------------------------------\nChannel Sidebar Start\n--------------------------------------------------------------*/\n.sideMenu {\n  padding: 10px 15px;\n  cursor: pointer;\n}\n\n.sideMenu:active,\n.sideMenu:focus,\n.sideMenu:hover {\n  color: inherit;\n  text-decoration: none !important;\n}\n\n/*--------------------------------------------------------------\nChannel Sidebar End\n--------------------------------------------------------------*/"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
