import React, { useEffect, useRef, useState } from 'react';
import videojs from 'video.js';

const AudioVideoPlayer = ({ videoDetailsData, audioTracks, options }) => {
    const videoRef = useRef(null);
    const playerRef = useRef(null);

    const audioRef = useRef(null);
    const [isUserInteracted, setIsUserInteracted] = useState(false);

    const handleAudioPlay = () => {
        if (audioRef.current) {
            audioRef.current.play();
        }
    };

    const handleAudioPause = () => {
        if (audioRef.current) {
            audioRef.current.pause();
        }
    }

    useEffect(() => {
        if (videoRef.current && videoDetailsData && isUserInteracted) {
            const player = videojs(videoRef.current, options, () => {
                playerRef.current = player;

                // Adding subtitles
                videoDetailsData?.Subtitle?.forEach((subtitle) => {
                    player.addRemoteTextTrack({
                        kind: 'captions',
                        label: subtitle?.subtitle_language,
                        srclang: subtitle?.shortcode,
                        src: subtitle?.subtitle_url,
                    });
                });

                return () => {
                    player.dispose();
                };
            });
        }
    }, [videoDetailsData, options, isUserInteracted]);



    const switchAudioTrack = (audioTrackUrl) => {
        console.log('Switching to audio track and splitting audio from video:', audioTrackUrl);
        if (!audioTrackUrl) {
            console.error('Invalid audio track URL');
            return;
        }
        if (playerRef.current) {
            const player = playerRef.current;
            if (player.paused()) {
                player.play().catch((error) => {
                    console.error('Error playing video:', error.message);
                });
            }

            if (window.currentAudioElement) {
                console.log('Stopping and removing the previous audio element...');
                window.currentAudioElement.pause();  // Pause the old audio
                window.currentAudioElement.src = '';  // Clear the audio source
                window.currentAudioElement = null;  // Remove the reference
            }

            // Create a separate audio element for the new track
            const audioElement = new Audio(audioTrackUrl);  // Separate audio element
            audioElement.type = 'audio/mpeg';  // Specify the audio type if necessary

            // Play the new audio track
            audioElement.play()
                .then(() => {
                    console.log('New audio track is playing.');
                })
                .catch((error) => {
                    console.error('Error playing new audio track:', error.message);
                });

            // Sync the audio time with the video time (optional)
            const syncAudioWithVideo = () => {
                if (!audioElement.paused) {
                    audioElement.currentTime = player.currentTime();  // Sync audio with video
                }
            };

           
            player.on('timeupdate', syncAudioWithVideo);

           
            const handleVideoEnd = () => {
                audioElement.pause();
                audioElement.currentTime = 0;  // Reset the audio time
                player.off('timeupdate', syncAudioWithVideo);  // Clean up the sync listener
            };

            // Attach the event listener for when the video ends
            player.on('ended', handleVideoEnd);

            // Store the reference to the new audio element globally for cleanup
            window.currentAudioElement = audioElement;
            window.syncAudioWithVideo = syncAudioWithVideo;
            window.handleVideoEnd = handleVideoEnd;

            console.log('Audio is now separated from the video.');
        }
    };
    useEffect(() => {
        if (audioRef.current) {
            console.log(audioRef.current)
          //setIsAudioAvailable(true);
        }
      }, []); 

    // Function to pause the audio but continue playing the video
    const pauseAudioKeepVideoPlaying = () => {
        if (audioRef.current) {
          audioRef.current.pause();  // Pause only the audio
          console.log('Audio paused, video continues to play.');
        } else {
          console.log('Audio element not found.');
        }
      };
    
    



    const handlePlayClick = () => {
        setIsUserInteracted(true);
        if (playerRef.current) {
            playerRef.current.play().catch((error) => {
                console.error('Error playing video:', error.message);
            });
        }
    };

    const handleAudioTrackChange = (language) => {
        const selectedTrack = audioTracks.find((track) => track.language === language);
        if (selectedTrack) {
            switchAudioTrack(selectedTrack.src); // Switch to the selected audio track
        } else {
            console.log('No matching audio track found for language:', language);
        }
    };


    return (
        <div>
            <video ref={videoRef} className="video-js vjs-default-skin" controls={false}/>
            {!isUserInteracted && (
                <button onClick={handlePlayClick}>Play Video</button>
            )}
            <button onClick={pauseAudioKeepVideoPlaying}>Switch to Spanish Audio</button>

        </div>
    );
};

export default AudioVideoPlayer;