import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./ChannelHeader.css";
import { RxHamburgerMenu } from "react-icons/rx";
import { FaRegUser } from "react-icons/fa";
import { BiWorld } from "react-icons/bi";
import { CgProfile } from "react-icons/cg";
import { MdContentPaste } from "react-icons/md"
import { RiLogoutCircleRLine } from "react-icons/ri";
import { LogOut } from "../../Pages/CommonMethods/CommonMethods";
import axios from "axios";
import MessageBox from "../../components/Others/MessageBox"

function ChannelHeader({ toggleAction }) {
  // const access_token_channel = localStorage.getItem("access_token_channel");
  let username, channel_name;
  if (localStorage.getItem("username")) {
    username = localStorage.getItem("username");
  } else {
    channel_name = localStorage.getItem("channel_name");
  }

  const capitalizeFirstLetter = (str) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  };

  // Check if username is available, if not, use channel_name
  const capitalizedUsername = username ? capitalizeFirstLetter(username) : capitalizeFirstLetter(channel_name);
  const toggleChannelNavbar = () => {
    toggleAction()
  }

  const [editcppchannel, setEditcppchannel] = useState({
    cpp_portal: 1,
    channel_portal: 1,
  });

  const [inputFieldcpp, setinputFieldcpp] = useState({
    email: "admin@webnexs.in",
    password: "Webnexs123!@#",
  });

  const handleChangecpp = () => {
    if (localStorage.getItem("email") !== inputFieldcpp?.email) {
      window.location.href = "/cpp/login";
      return;
    } else {
      logincpp();
    }
  }

  // Function to perform login
  async function logincpp() {
    axios
      .post(
        `${process.env.REACT_APP_Baseurl}/CPP-auth/sign-in`,
        { email: inputFieldcpp?.email, password: inputFieldcpp?.password },
        { cors: "no-cors" },
        { credentials: "include" },
        { credentials: "same-origin" },
      )
      .then((res) => {
        // console.log(res)
        let access_token = res.data.access_token;
        let expires_in = res.data.expires_in;
        let username = res.data.CPP_user.username;
        let user_id = res.data.CPP_user.id;
        // let role = res.data.cpp_user.role;
        let cpp_image = res.data.CPP_user.cpp_image;
        localStorage.setItem("access_token_cpp", access_token);
        localStorage.setItem("cpp_Partner_id", user_id);
        // localStorage.setItem("role", role);
        localStorage.setItem("cpp_name", username);
        localStorage.setItem("cpp_image", cpp_image);
        localStorage.setItem("cpp_expires_in", expires_in);
        // localStorage.setItem("user", inputFieldcpp)
        setTimeout(() => {
          window.location.href = "/cpp/home";
        }, 1000);
      })
      .catch((err) => {
        window.location.href = "/cpp/login";
      });
  }
  const channel = localStorage.getItem("theme");


  return (
    <nav className="theme-bg-color  px-2 px-sm-3 channelHeader">
      <ul className="d-flex align-items-center justify-content-between p-0 m-0 list-unstyled">
        <li>
          <button type="button" onClick={toggleChannelNavbar} className="bg-transparent d-inline-flex p-2 toggleAdminButton">
            <RxHamburgerMenu className="adminIcon" />
          </button>
        </li>
        <li>
          <div className="d-flex align-items-center justify-content-center gap-3">
            {editcppchannel?.cpp_portal === 1 ? (
              <Link className="btn btn-primary  rounded-2 border-0 d-flex px-4 px-sm-3  py-1 py-sm-2"> <MdContentPaste className="adminIcon me-2" ></MdContentPaste> <span className="d-none d-sm-block">CPP Portal</span></Link>
            ) : null}
            <Link className="btn btn-primary rounded-2 border-0 d-flex px-4 px-sm-3  py-1 py-sm-2" to="/"> <BiWorld className="adminIcon me-2" /> <span className="d-none d-sm-block">Website</span></Link>
          </div>
        </li>
        <li className="position-relative adminDropDownContainer py-3 theme-bg-color ">
          <button className="d-flex align-items-center px-3 py-2 bg-transparent shadow rightSideButton">
            <FaRegUser style={{ fill: "currentColor", width: "20px", height: "20px" }} />
            <p className="mb-0 userText"><MessageBox text={`${capitalizedUsername}`} classname="theme-text-color"/></p>
          </button>
          <ul className="adminSideDropDown list-unstyled">
            <li>
              <Link className="d-flex align-items-center px-3 py-3 border-bottom theme-text-color" to="/channel/my-profile" >
                <CgProfile className="exportIcon me-2" />My Profile
              </Link>
            </li>
            <li>
              <button type="button" className="bg-transparent border-0  theme-text-color d-flex align-items-center px-3 py-3" to="/" onClick={() => LogOut('/channel/login')}>
                <RiLogoutCircleRLine className="exportIcon me-2" /> Logout
              </button>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  );
}
export default ChannelHeader;