import React from 'react'
import Lyrics from '../Audio/lyrics/lyrics'
import { connect } from 'react-redux'

const LyricsPage = ({ currentToggle }) => {
  return (
    <div className='p-3'>
      <Lyrics />
    </div>
  )
}
const mapStateToProps = state => ({
  currentToggle: state.get_current_Reducers.currentToggle
})

export default connect(mapStateToProps)(LyricsPage)