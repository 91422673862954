import { Link, useNavigate, useParams } from "react-router-dom";
import JoditEditor from "jodit-react";
import React, { useState, useRef, useEffect } from "react";
import axios, { CancelToken, isCancel } from "axios";
import { ProgressBar } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Select from "react-select";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Creatable from "react-select/creatable";
import "vidstack/styles/defaults.css";
import { defineCustomElements } from "vidstack/elements";
import "vidstack/styles/base.css";
import "vidstack/styles/ui/buttons.css";
import "vidstack/styles/ui/buffering.css";
import "vidstack/styles/ui/captions.css";
import "vidstack/styles/ui/tooltips.css";
import "vidstack/styles/ui/live.css";
import "vidstack/styles/ui/sliders.css";
import "vidstack/styles/ui/menus.css";
import "vidstack/define/media-player.js";
import ResponseLoader from "../../../components/Others/ResponseLoader";

const EditNewVideo = () => {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const { id } = useParams();

  const [videoUrl, setVideoUrl] = useState("");

  const [age_restrictdata, setAge_restrictdata] = useState([]);
  const [pre_ads_category, setPre_ads_category] = useState("");
  const [mid_ads_category, setMid_ads_category] = useState("");
  const [post_ads_category, setPost_ads_category] = useState("");
  const [Block_Country, setBlock_Country] = useState([]);
  const [Available_country, setAvailable_country] = useState([]);
  const [trailer, setTrailer] = useState("");
  const [Video_Title_Thumbnail, setVideo_Title_Thumbnail] = useState("");
  const [image, setImage] = useState("");
  const [player_image, setPlayer_image] = useState("");
  const [video_tv_image, setVideo_tv_image] = useState("");
  const [pdf_files, setPdf_files] = useState("");
  const [reels_thumbnail, setReels_thumbnail] = useState("");
  const [reelvideo, setReelvideo] = useState("");
  const [trailer_type, setTrailer_type] = useState("");
  const [video_title_image, setVideo_title_image] = useState("");

  const [editUser, setEditUser] = useState({
    title: "",
    slug: "",
    id: "",
    draft: "",
    user_id: "",
    type: "",
    status: "",
    active: "",
    rating: "",
    duration: "",
    description: "",
    details: "",
    image: "",
    mobile_image: "",
    tablet_image: "",
    player_image: "",
    video_tv_image: "",
    enable_video_title_image: "",
    video_title_image: "",
    android_tv: "",
    video_gif: "",
    search_tags: "",
    access: "",
    global_ppv: "",
    ppv_price: "",
    ios_ppv_price: "",
    featured: "",
    banner: "",
    enable: "",
    embed_code: "",
    mp4_url: "",
    m3u8_url: "",
    webm_url: "",
    ogg_url: "",
    disk: "",
    original_name: "",
    path: "",
    stream_path: "",
    processed_low: "",
    converted_for_streaming_at: "",
    views: "",
    year: "",
    age_restrict: "",
    Recommendation: "",
    country: "",
    publish_status: "",
    publish_type: "",
    publish_time: "",
    skip_recap: "",
    skip_intro: "",
    recap_start_time: "",
    recap_end_time: "",
    intro_start_time: "",
    intro_end_time: "",
    pdf_files: "",
    reels_thumbnail: "",
    reelvideo: "",
    url: "",
    url_link: "",
    url_linktym: "",
    url_linksec: "",
    urlEnd_linksec: "",
    trailer_type: "",
    trailer: "",
    trailer_description: "",
    ads_status: "",
    default_ads: "",
    pre_ads_category: "",
    mid_ads_category: "",
    post_ads_category: "",
    mid_ads: "",
    pre_ads: "",
    post_ads: "",
    ads_tag_url_id: "",
    time_zone: "",
    home_genre: "",
    in_menu: "",
    footer: "",
    uploaded_by: "",
    language: "",
    video_category_id: "",
    Video_reels_video_url: "",
    video_categories: "",
    video_block_countries: "",
    artists: "",
    realted_videos: "",
    Thumbnail: "",
    Player_thumbnail: "",
    TV_Thumbnail: "",
    Video_Title_Thumbnail: "",
    Video_reels_Thumbnail: "",
    Video_upload_url: "",
  });
  const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");
  const [Channel_Partner_id, setChannel_Partner_id] = useState("1");

  // console.log(editUser)
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const [adsPositions, setAdsPositions] = useState([]);
  const [selectedAdsPosition, setSelectedAdsPosition] = useState(null);
  const [secondSelectOptions, setSecondSelectOptions] = useState([]);
  const [ios_ppv_price, setIos_ppv_price] = useState("");
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputValueAPI, setInputValueAPI] = useState("");

  const [user_access, setUser_access] = useState([]);
  const [inapppurchase, setInappPurchase] = useState("");

  const handleAdsPositionChange = (selectedValue5) => {
    setSelectedAdsPosition(selectedValue5);
    console.log(selectedValue5); // Selected value
  };

  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
    if (!checkboxChecked) {
      setInputValue(inputValueAPI);
    } else {
      setInputValue("");
    }
  };

  const handleChangeFirst = (e) => {
    setEditUser({ ...editUser, description: e });
    // console.log(e, "onchnageddddd");
  };
  const handleChangeFirstdetails = (e) => {
    setEditUser({ ...editUser, details: e });
    // console.log(e, "onchnageddddd");
  };
  const handleChangeFirsttraildetails = (e) => {
    setEditUser({ ...editUser, trailer_description: e });
    // console.log(e, "onchnageddddd");
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  // MULTI SELECT NEW
  const [options, setOptions] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);

  const [optionsrelated_video, setOptionsrelated_video] = useState([]);
  const [selectedValuesrelated_video, setSelectedValuesrelated_video] =
    useState([]);

  const [optionsartists, setOptionsartists] = useState([]);
  const [selectedValuesartists, setSelectedValuesartists] = useState([]);

  const [optionscategories, setOptionscategories] = useState([]);
  const [selectedValuescategories, setSelectedValuescategories] = useState([]);

  const [optionslanguages, setOptionslanguages] = useState([]);
  const [selectedValueslanguages, setSelectedValueslanguages] = useState([]);

  const [optionsavailable_country, setOptionsAvailable_country] = useState([]);
  const [selectedValuesavailable_country, setSelectedValuesAvailable_country] =
    useState([]);

  const [optionsAge_Restrict, setOptionsAge_Restrict] = useState([]);
  const [selectedValuesAge_Restrict, setSelectedValuesAge_Restrict] = useState(
    []
  );

  const [formData, setFormData] = useState({
    block_country: [],
    Available_country: [],
    related_video: [],
    artists: [],
    categories: [],
    languages: [],
    Age_Restrict: [],
    // ...editInputvalue,
    // other form data properties...
  });

  // const [languages, setLanguages] = useState([]);
  // const [selectedLanguages, setSelectedLanguages] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/video-edit/${id}`,
        { headers: headers }
      );
      const data = response?.data?.selected_Video_block_countries;
      const dataavailable_country =
        response?.data?.selected_Video_available_countries;
      const dataartists = response?.data?.selected_Video_artists;
      const datacategories = response?.data?.selected_Video_categories;
      const datalanguages = response?.data?.selected_languages;
      const dataAge_Restrict = response?.data?.selected_Age_Restrict;
      const datarelated_video = response?.data?.selected_related_video;

      var result = response?.data?.user_access;
      var InappPurchase = response?.data?.InappPurchase;
      setUser_access(result);
      setInappPurchase(InappPurchase);

      // var res = response?.data?.videos[0];
      // setEditUser(res);

      // var res = response?.data?.videos[0];

      var globelset = response?.data?.ppv_gobal_price;
      var age_restrictdata = response?.data?.Age_Restrict;
      setAge_restrictdata(age_restrictdata);
      setInputValueAPI(globelset);

      const selectedData = data;
      const formattedOptions = selectedData.map((item) => ({
        value: item.id,
        label: item.country_name,
      }));

      const formattedOptionsavailable_country = dataavailable_country.map(
        (item) => ({
          value: item.id,
          label: item.country_name,
        })
      );
      const formattedOptionsartists = dataartists?.map((item) => ({
        value: item.id,
        label: item.id,
      }));
      const formattedOptionscategories = datacategories?.map((item) => ({
        value: item.id,
        label: item.id,
      }));
      const formattedOptionslanguages = datalanguages?.map((item) => ({
        value: item.id,
        label: item.id,
      }));
      const formattedOptionsAge_Restrict = dataAge_Restrict?.map((item) => ({
        value: item.id,
        label: item.id,
      }));
      const formattedOptionsrelated_video = datarelated_video?.map((item) => ({
        value: item.id,
        label: item.id,
      }));

      // Set the initial selected values
      setSelectedValues(formattedOptions);
      setOptions(formattedOptions);

      setSelectedValuesrelated_video(formattedOptionsrelated_video);
      setOptionsrelated_video(formattedOptionsrelated_video);

      setSelectedValuesAvailable_country(formattedOptionsavailable_country);
      setOptionsAvailable_country(formattedOptionsavailable_country);

      setSelectedValuesartists(formattedOptionsartists);
      setOptionsartists(formattedOptionsartists);

      setSelectedValuescategories(formattedOptionscategories);
      setOptionscategories(formattedOptionscategories);

      setSelectedValueslanguages(formattedOptionslanguages);
      setOptionslanguages(formattedOptionslanguages);

      setSelectedValuesAge_Restrict(formattedOptionsAge_Restrict);
      setOptionsAge_Restrict(formattedOptionsAge_Restrict);
      // console.log(formattedOptions);
      // console.log(formattedOptions);
      // console.log(formattedOptions);
    } catch (error) {
      console.error(error);
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/video-create`,
        { headers: headers }
      );
      const data = response?.data?.Block_country;
      const dataavailable_country = response?.data?.Available_country;
      const datarelated_video = response?.data?.Related_video;
      const dataartists = response?.data?.artists;
      const datacategories = response?.data?.audio_categories;
      const datalanguages = response?.data?.languages;
      const dataAge_Restrict = response?.data?.Age_Restrict;
      // setSelectedLanguages(data)
      // console.log(data);

      const globelset = response?.data?.ppv_gobal_price;
      setInputValueAPI(globelset);
      // console.log(data);
      // console.log(data);
      // Extract the selected data from the response
      const selectedData = data;
      const formattedOptions = selectedData.map((item) => ({
        value: item.id,
        label: item.country_name,
      }));
      const formattedOptionsavailable_country = dataavailable_country.map(
        (item) => ({
          value: item.id,
          label: item.country_name,
        })
      );
      const formattedOptionsartists = dataartists.map((item) => ({
        value: item.id,
        label: item.id,
      }));
      const formattedOptionscategories = datacategories.map((item) => ({
        value: item.id,
        label: item.id,
      }));
      const formattedOptionslanguages = datalanguages.map((item) => ({
        value: item.id,
        label: item.id,
      }));
      const formattedOptionsAge_Restrict = dataAge_Restrict.map((item) => ({
        value: item.id,
        label: item.id,
      }));
      const formattedOptionsrelated_video = datarelated_video.map((item) => ({
        value: item.id,
        label: item.id,
      }));

      // Set the options for the multi-select component
      setOptions(formattedOptions);
      setOptionsAvailable_country(formattedOptionsavailable_country);
      setOptionsartists(formattedOptionsartists);
      setOptionscategories(formattedOptionscategories);
      setOptionslanguages(formattedOptionslanguages);
      setOptionsAge_Restrict(formattedOptionsAge_Restrict);
      setOptionsrelated_video(formattedOptionsrelated_video);
      // console.log(formattedOptions);
      // console.log(formattedOptions);
      // console.log(formattedOptions);
    } catch (error) {
      console.error(error);
    }
  };
  // const handleLanguageChange = (event) => {
  //   const selectedOptions = Array.from(event.target.selectedOptions, option => option.value);
  //   setSelectedLanguages(selectedOptions);
  // };

  const handleSelectChange = (selectedOptions) => {
    const block_country = selectedOptions.map((option) => option.value);

    setFormData({
      ...formData,
      // ...editInputvalue,
      block_country: block_country,
    });

    setSelectedValues(selectedOptions);
  };
  const handleSelectChangeavailable_country = (
    selectedOptionsavailable_country
  ) => {
    const available_country = selectedOptionsavailable_country?.map(
      (option) => option.value
    );

    setFormData({
      ...formData,
      // ...editInputvalue,
      available_country: available_country,
    });

    setSelectedValuesAvailable_country(selectedOptionsavailable_country);
  };
  const handleSelectChangeartists = (selectedOptionsartists) => {
    const artists = selectedOptionsartists?.map((option) => option.value);

    setFormData({
      ...formData,
      // ...editInputvalue,
      artists: artists,
    });

    setSelectedValuesartists(selectedOptionsartists);
  };

  const handleSelectChangecategories = (selectedOptionscategories) => {
    const categories = selectedOptionscategories?.map((option) => option.value);

    setFormData({
      ...formData,
      // ...editInputvalue,
      categories: categories,
    });

    setSelectedValuescategories(selectedOptionscategories);
  };

  const handleSelectChangerelated_video = (selectedOptionsrelated_video) => {
    const related_video = selectedOptionsrelated_video?.map(
      (option) => option.value
    );

    setFormData({
      ...formData,
      // ...editInputvalue,
      related_video: related_video,
    });

    setSelectedValuesrelated_video(selectedOptionsrelated_video);
  };

  const handleSelectChangelanguages = (selectedOptionslanguages) => {
    const languages = selectedOptionslanguages?.map((option) => option.value);

    setFormData({
      ...formData,
      // ...editInputvalue,
      languages: languages,
    });

    setSelectedValueslanguages(selectedOptionslanguages);
  };
  const handleSelectChangeAge_Restrict = (selectedOptionsAge_Restrict) => {
    const Age_Restrict = selectedOptionsAge_Restrict?.map(
      (option) => option.value
    );

    setFormData({
      ...formData,
      // ...editInputvalue,
      Age_Restrict: Age_Restrict,
    });

    setSelectedValueslanguages(selectedOptionsAge_Restrict);
  };

  // MULTI SELECT NEW

  useEffect(() => {
    const fetchAdsPositions = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/video-create`,
          {
            headers: headers,
          }
        );

        if (response.ok) {
          const data = await response?.json();
          // console.log(data);

          const adsPositionOptions = data?.ads_position?.map((position) => ({
            value: position?.position,
            label: position?.name,
          }));
          // console.log(adsPositionOptions);
          setAdsPositions(adsPositionOptions);
        } else {
          throw new Error("Request failed with status: " + response?.status);
        }
      } catch (error) {
        console.log("Error fetching ads positions:", error);
      }
    };
    fetchAdsPositions();

    axios
      .get(`${process.env.REACT_APP_Baseurl}/Channel/Backend/video-create`, {
        headers: headers,
      })
      .then((response) => {
        var ads_category = response?.data?.ads_category;
        setPre_ads_category(ads_category);
        setMid_ads_category(ads_category);
        setPost_ads_category(ads_category);
        // console.log(response)
      })
      .catch((error) => console.log(error));
  }, [id]);

  useEffect(() => {
    if (selectedAdsPosition !== "") {
      // console.log(selectedAdsPosition, "adsssssssssss");
      const formData = new FormData();
      formData.append("position", selectedAdsPosition?.value);

      axios

        .post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/video-advertisement-tag-url`,
          formData,
          { headers: headers }
        )

        .then((response) => {
          const data = response.data;
          // console.log(data, "exceptioned");

          const adsNames = data?.Advertisement?.map((position) => ({
            value: position?.id,
            label: position?.ads_name,
          }));

          setSecondSelectOptions(adsNames);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [selectedAdsPosition]);

  const [enable_restreampublic, setEnable_restreampublic] = useState("");
  const [enablestreamvisiblepublic, setEnablestreamvisiblepublic] =
    useState("");

  function proceednextpublic() {
    switch (enable_restreampublic) {
      case "publish_now":
        return <div></div>;
      case "publish_later":
        return (
          <div className="mb-3">
            <label className="m-0">Publish Time</label>
            <input
              type="datetime-local"
              className="form-control"
              id="publish_time"
              name="publish_time"
              onChange={handleInput}
              value={editUser?.publish_time}
            />
          </div>
        );
      default:
        return null;
    }
  }

  const [enable_restream, setEnable_restream] = useState("");
  const [enablestreamvisible, setEnablestreamvisible] = useState("");
  const [enable_restreamtrailer, setEnable_restreamtrailer] = useState("");
  const [enablestreamvisibletrailer, setEnablestreamvisibletrailer] =
    useState("");

  function proceednexttrailer() {
    switch (enable_restreamtrailer) {
      case "0":
        return <div></div>;
      case "1":
        return (
          <div className="col-6">
            <label className="mb-1"> Upload Trailer : </label>
            <div className=" text-center file-drag mt-2">
              <label id="label-file-upload" htmlFor="input-file-upload">
                <div>
                  <p>Drag and drop your file here or</p>
                  <p>
                    <input
                      type="file"
                      className="form-control-file"
                      multiple
                      accept="video/mp4"
                      id="input-file-upload"
                      onChange={(e) => setTrailer(e.target.files[0])}
                    />
                  </p>
                </div>
                <br />
              </label>
            </div>
          </div>
        );
      case "m3u8_url":
        return (
          <div>
            <div className="row mt-3 text-start">
              <div className="col-sm-6 ">
                <div className="">
                  <label className="mb-1">Trailer m3u8 URL: </label>
                  <input
                    type="text"
                    className="form-control"
                    name="mp3_url"
                    onChange={handleInput}
                    value={editUser.mp4_url}
                    placeholder="m3u8 URL"
                  />
                </div>
                {/* <div className='mt-4 text-left'>
                <button className='btn btn-primary' onClick={() => { mp3filesubmit(); handleNext(); }} >
                  Submit </button>
              </div> */}
              </div>
            </div>
          </div>
        );
      case "mp4_url":
        return (
          <div>
            <div className="row mt-3 text-start">
              <div className="col-sm-6 ">
                <div className="">
                  <label className="mb-1"> Trailer Mp4 File URL: </label>
                  <input
                    type="text"
                    className="form-control"
                    name="mp4_url"
                    onChange={handleInput}
                    value={editUser.mp4_url}
                    placeholder="mp4 Trailer"
                  />
                </div>
                {/* <div className='mt-4 text-left'>
                <button className='btn btn-primary' onClick={() => { mp4filesubmit(); }} >
                  Submit </button>
              </div> */}
              </div>
            </div>
          </div>
        );
      case "embed_url":
        return (
          <div>
            <div className="row mt-3 text-start">
              <div className="col-sm-6 ">
                <div className="">
                  <label className="mb-1"> Trailer Embed URL: </label>
                  <input
                    type="text"
                    className="form-control"
                    name="live_mp3_url"
                    placeholder="Embed URL"
                    onChange={handleInput}
                    value={editUser?.embed_code}
                  />
                </div>
              </div>
            </div>
          </div>
        );

      default:
        return null;
    }
  }

  const [selectedValue, setSelectedValue] = useState("");
  const [apiResponse, setApiResponse] = useState("");

  const handleSelectChanges = async (event) => {
    const { value } = event.target;
    setSelectedValue(value);

    const formData = new FormData();

    formData.append("value", value);

    await axios
      .post(
        `${process.env.REACT_APP_Baseurl}/admin/advertisement-category-depends`,
        formData,
        { headers: headers }
      )
      .then((response) => {
        console.log("apiasas", response);
        if (response.data.status == true) {
          var result = response.data;

          console.log("api", result);
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });
  };

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
    // setDescription({ ...description, [e.target.name]: e.target.value });

    if (e.target.checked == true) {
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
      setEditUser({ ...editUser, [e.target.id]: 1 });
      setEditUser({
        ...editUser,
        [e.target.name]: e.target.value,
        [e.target.name]: 1,
      });
    } else {
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
      setEditUser({ ...editUser, [e.target.id]: 0 });
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const editInputvalue = {
      title: editUser.title,
      type: editUser.type,
      slug: editUser.slug,
      id: editUser.id,
      active: editUser.active,
      user_id: editUser.user_id,
      status: editUser.status,
      draft: editUser.draft,
      rating: editUser.rating,
      duration: editUser.duration,
      details: editUser.details,
      description: editUser.description,
      image: editUser.image,
      mobile_image: editUser.mobile_image,
      tablet_image: editUser.tablet_image,
      player_image: editUser.player_image,
      video_tv_image: editUser.video_tv_image,
      enable_video_title_image: editUser.enable_video_title_image,
      video_title_image: editUser.video_title_image,
      android_tv: editUser.android_tv,
      video_gif: editUser.video_gif,
      search_tags: editUser.search_tags,
      access: editUser.access,
      global_ppv: editUser.global_ppv,
      ppv_price: editUser.ppv_price,
      ios_ppv_price: editUser.ios_ppv_price,
      featured: editUser.featured,
      banner: editUser.banner,
      enable: editUser.enable,
      embed_code: editUser.embed_code,
      mp4_url: editUser.mp4_url,
      m3u8_url: editUser.m3u8_url,
      webm_url: editUser.webm_url,
      ogg_url: editUser.ogg_url,
      disk: editUser.disk,
      original_name: editUser.original_name,
      path: editUser.path,
      stream_path: editUser.stream_path,
      processed_low: editUser.processed_low,
      converted_for_streaming_at: editUser.converted_for_streaming_at,
      views: editUser.views,
      year: editUser.year,
      age_restrict: editUser.age_restrict,
      Recommendation: editUser.Recommendation,
      country: editUser.country,
      publish_status: editUser.publish_status,
      publish_type: editUser.publish_type,
      publish_time: editUser.publish_time,
      skip_recap: editUser.skip_recap,
      skip_intro: editUser.skip_intro,
      recap_start_time: editUser.recap_start_time,
      recap_end_time: editUser.recap_end_time,
      intro_start_time: editUser.intro_start_time,
      intro_end_time: editUser.intro_end_time,
      pdf_files: editUser.pdf_files,
      reels_thumbnail: editUser.reels_thumbnail,
      reelvideo: editUser.reelvideo,
      url: editUser.url,
      url_link: editUser.url_link,
      url_linktym: editUser.url_linktym,
      url_linksec: editUser.url_linksec,
      urlEnd_linksec: editUser.urlEnd_linksec,
      trailer_type: editUser.trailer_type,
      trailer: editUser.trailer,
      trailer_description: editUser.trailer_description,
      ads_status: editUser.ads_status,
      default_ads: editUser.default_ads,
      pre_ads_category: editUser.pre_ads_category,
      mid_ads_category: editUser.mid_ads_category,
      post_ads_category: editUser.post_ads_category,
      pre_ads: editUser.pre_ads,
      mid_ads: editUser.mid_ads,
      post_ads: editUser.post_ads,
      ads_tag_url_id: editUser.ads_tag_url_id,
      time_zone: editUser.time_zone,
      home_genre: editUser.home_genre,
      in_menu: editUser.in_menu,
      footer: editUser.footer,
      uploaded_by: editUser.uploaded_by,
      language: editUser.language,
      video_category_id: editUser.video_category_id,
      Thumbnail: editUser.Thumbnail,
      Player_thumbnail: editUser.Player_thumbnail,
      TV_Thumbnail: editUser.TV_Thumbnail,
      Video_Title_Thumbnail: editUser.Video_Title_Thumbnail,
      Video_reels_Thumbnail: editUser.Video_reels_Thumbnail,
      Video_reels_video_url: editUser.Video_reels_video_url,
      Video_upload_url: editUser.Video_upload_url,
      video_category_id: editUser.video_category_id,
      Channel_Partner_id: Channel_Partner_id,
      ...formData,
    };
    // console.log(editInputvalue);
    let res = await fetch(
      `${process.env.REACT_APP_Baseurl}/Channel/Backend/video-update/` + id,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(editInputvalue),
      }
    );

    let resjson = await res.json();
    // console.log(resjson)
    if (resjson.status == true) {
      setMessage(resjson.success);
      setTimeout(() => {}, 2000);
      var result = resjson;
      const formDatas = new FormData();

      formDatas.append("video_id", id);
      formDatas.append("image", image);
      formDatas.append("player_image", player_image);
      formDatas.append("video_tv_image", video_tv_image);
      formDatas.append("video_title_image", Video_Title_Thumbnail);

      axios
        .post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/video-Image-upload`,
          formDatas,
          { headers: headers }
        )
        .then((response) => {
          // console.log('api' , response);
          if (response.data.status == true) {
            var result = response.data;
            // console.log('result', response);
            // navigate('/admin/all-artist')
          }
        });

      const formDatass = new FormData();

      formDatass.append("video_id", id);
      formDatass.append("trailer", trailer);
      formDatass.append("trailer_type", trailer_type);

      axios
        .post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/videos-Trailer-url`,
          formDatass,
          { headers: headers }
        )
        .then((response) => {
          // console.log('api' , response);
          if (response.data.status == true) {
            var result = response.data;
            // console.log('result', response);
            // navigate('/admin/all-artist')
            // alert("Added successfully  !")
          }
        });

      const formD = new FormData();

      formD.append("video_id", id);
      formD.append("trailer", trailer);

      axios
        .post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/videos-Trailer-upload`,
          formD,
          { headers: headers }
        )
        .then((response) => {
          // console.log('api' , response);
          if (response.data.status == true) {
            var result = response.data;
            // console.log('result', response);
            // navigate('/admin/all-artist')
          }
        });

      const formDa = new FormData();

      formDa.append("video_id", id);
      formDa.append("pdf_file", pdf_files);

      axios
        .post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/video-Epaper`,
          formDa,
          {
            headers: headers,
          }
        )
        .then((response) => {
          // console.log('api' , response);
          if (response.data.status == true) {
            var result = response.data;
            // console.log('result', response);
            // navigate('/admin/all-artist')
          }
        });
      alert("Added successfully  !");
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }
  };

  const [livesource, setLivesource] = useState("");

  function live_stream_source() {
    switch (livesource) {
      case "guest":
        return <div></div>;
      case "subscriber":
        return <div></div>;
      case "registered":
        return <div></div>;
      case "ppv":
        return (
          <div className="row mt-3 text-start ">
            <div className="col-sm-6">
              <label className="m-0">PPV Price</label>
              <div className="col-lg-12 row">
                <div className="col-8 col-lg-8">
                  <p className="p1">Apply PPV Price from Global Settings? </p>
                </div>
                <div className="col-4 col-lg-4">
                  <label className="switch">
                    <input
                      name="ppv_price"
                      defaultChecked={checkboxChecked}
                      onChange={handleCheckboxChange}
                      type="checkbox"
                    />
                    <span
                      className="slider round"
                      name="ppv_price"
                      checked={checkboxChecked}
                      onChange={handleCheckboxChange}
                    ></span>
                  </label>
                </div>
              </div>

              <div className="panel-body">
                <input
                  type="text"
                  className="form-control"
                  placeholder="PPV Price"
                  name="ppv_price"
                  id="price"
                  // value={editUser?.ppv_price}
                  value={inputValue}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="col-sm-6">
              <label className="m-0"> IOS PPV Price</label>
              <p className="p1">Apply IOS PPV Price from Global Settings?</p>
              <div className="panel-body ppv_price_ios">
                <select
                  onChange={handleInput}
                  name="ios_ppv_price"
                  id="ios_ppv_price"
                  className="form-control"
                  value={editUser?.ios_ppv_price}
                >
                  {inapppurchase?.map((item, key) => (
                    <option value={item.id}>{item.plan_price}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  const steps = [
    {
      label: "Video ",
      description: (
        <div className="mt-4">
          <h4>Video Information:</h4>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 form-group">
                {/* <video controls className="w-100">
                  <source src={videoUrl} type="video/mp4" />
                </video> */}

                <media-player
                  title="Agent 327: Operation Barbershop"
                  src="https://media-files.vidstack.io/720p.mp4"
                  poster="https://media-files.vidstack.io/poster.png"
                  controls
                >
                  <media-outlet></media-outlet>
                </media-player>
              </div>
            </div>
          </div>
          <div className="text-end mt-3">
            <Button
              variant="contained"
              onClick={handleNext}
              className="btn btn-primary"
            >
              Next
            </Button>
          </div>
        </div>
      ),
    },
    {
      label: "Video Details",
      description: (
        <div className="mt-4">
          <h4>Video Information:</h4>
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6 form-group">
                <label className="m-0">Title :</label>
                <input
                  type="text"
                  className="form-control"
                  value={editUser?.title}
                  name="title"
                  id="title"
                  onChange={handleInput}
                  placeholder="Title"
                />
              </div>

              <div className="col-sm-6 form-group">
                <label className="m-0">Video Slug:</label>

                <input
                  type="text"
                  className="form-control"
                  name="slug"
                  id="slug"
                  onChange={handleInput}
                  value={editUser?.slug}
                  placeholder="Video Slug"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 form-group">
                <label className="m-0">Age Restrict :</label>
                <select
                  value={editUser?.age_restrict}
                  className="form-control"
                  id="age_restrict"
                  name="age_restrict"
                  onChange={handleInput}
                >
                  {/* <option selected value={editUser.ag}>{editUser.Age_Restrict}</option> */}
                  {age_restrictdata?.map((item) => (
                    <option selected value={item?.id}>
                      {item?.slug}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-sm-6 form-group ">
                <label className="m-0">Rating:</label>

                <select
                  value={editUser?.rating}
                  className="js-example-basic-single form-control"
                  name="rating"
                  tags="true"
                  onChange={handleInput}
                >
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 form-group">
                <label className="m-0">Video Description:</label>

                <JoditEditor
                  // ref={editor}
                  value={editUser?.description}
                  //   onChange={(newContent) => setContent(newContent)}
                  // onChange={handleInput}

                  onChange={(e) => {
                    handleChangeFirst(e);
                  }}
                />
              </div>
              <div className="col-12 form-group">
                <label className="m-0">Links &amp; Details:</label>

                <JoditEditor
                  // ref={editor}
                  value={editUser?.details}
                  //   onChange={(newContent) => setContent(newContent)}
                  // onChange={handleInput}

                  onChange={(e) => {
                    handleChangeFirstdetails(e);
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4 form-group">
                <label className="m-0">
                  Skip Intro Time <small>(Please Give In Seconds)</small>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="skip_intro"
                  name="skip_intro"
                  onChange={handleInput}
                  value={editUser?.skip_intro}
                />
                {/* <span>
                  <p className="image_error_msg">* Fill Skip Intro Time </p>
                </span> */}
              </div>
              <div className="col-sm-4 form-group">
                <label className="m-0">
                  Intro Start Time <small>(Please Give In Seconds)</small>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="intro_start_time"
                  name="intro_start_time"
                  onChange={handleInput}
                  value={editUser?.intro_start_time}
                />
                {/* <span>
                  <p className="image_error_msg">* Fill Intro Start Time </p>
                </span> */}
              </div>
              <div className="col-sm-4 form-group">
                <label className="m-0">
                  Intro End Time <small>(Please Give In Seconds)</small>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="intro_end_time"
                  name="intro_end_time"
                  onChange={handleInput}
                  value={editUser?.intro_end_time}
                />
                {/* <span>
                  <p className="image_error_msg">* Fill Intro End Time </p>
                </span> */}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4 form-group">
                <label className="m-0">
                  Skip Recap <small>(Please Give In Seconds)</small>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="skip_recap"
                  name="skip_recap"
                  onChange={handleInput}
                  value={editUser?.skip_recap}
                />
                {/* <span>
                  <p className="image_error_msg">* Fill Skip Recap Time </p>
                </span> */}
              </div>
              <div className="col-sm-4 form-group">
                <label className="m-0">
                  Recap Start Time <small>(Please Give In Seconds)</small>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="recap_start_time"
                  name="recap_start_time"
                  onChange={handleInput}
                  value={editUser?.recap_start_time}
                />
                {/* <span>
                  <p className="image_error_msg">* Fill Recap Start Time </p>
                </span> */}
              </div>
              <div className="col-sm-4 form-group">
                <label className="m-0">
                  Recap End Time <small>(Please Give In Seconds)</small>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="recap_end_time"
                  name="recap_end_time"
                  onChange={handleInput}
                  value={editUser?.recap_end_time}
                />
                {/* <span>
                  <p className="image_error_msg">* Fill Recap End Time </p>
                </span> */}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 form-group">
                <label className="m-0">Video Duration:</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Video Duration"
                  name="duration"
                  id="duration"
                  onChange={handleInput}
                  value={editUser?.duration}
                />
              </div>
              <div className="col-sm-4 form-group">
                <label className="m-0">Year:</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Release Year"
                  name="year"
                  onChange={handleInput}
                  id="year"
                  value={editUser?.year}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 form-group">
                <label className="mb-2">Publish Type</label>
                <br />
                <div
                  onClick={(event) => {
                    setEnable_restreampublic(event.target.value);
                  }}
                >
                  <input
                    type="radio"
                    id="publish_now"
                    name="publish_type"
                    onChange={handleInput}
                    onClick={(event) => {
                      setEnable_restreampublic(event.target.value);
                    }}
                    value="publish_now"
                    checked="checked"
                  />{" "}
                  <span
                    className=""
                    onClick={(event) => {
                      setEnable_restreampublic(event.target.value);
                    }}
                  >
                    Publish Now
                  </span>{" "}
                </div>
                <input
                  type="radio"
                  id="publish_later"
                  value="publish_later"
                  name="publish_type"
                  onChange={handleInput}
                  onClick={(event) => {
                    setEnable_restreampublic(event.target.value);
                  }}
                />{" "}
                <span
                  onClick={(event) => {
                    setEnable_restreampublic(event.target.value);
                  }}
                >
                  Publish Later
                </span>
              </div>

              <div className="col-sm-6 form-group" id="publishlater">
                <div
                  onClick={(event) => {
                    setEnablestreamvisiblepublic(event.target.value);
                  }}
                >
                  {proceednextpublic()}
                </div>
              </div>
            </div>
          </div>
          <div className="text-end mt-3">
            <Button
              variant="contained"
              onClick={handleUpdate}
              className="btn btn-primary ms-2"
            >
              Upload Video
            </Button>
            <Button onClick={handleBack} className="btn btn-dark ms-2">
              Back
            </Button>
            <Button
              variant="contained"
              onClick={handleNext}
              className="btn btn-primary ms-2"
            >
              Next
            </Button>
          </div>
        </div>
      ),
    },
    {
      label: "Category",
      description: (
        <div className="container-fluid mt-4">
          <div className="form-card">
            <div className="row">
              <div className="col-sm-6 form-group">
                <label className="m-0">Select Video Category :</label>
                <Select
                  options={optionscategories}
                  isMulti
                  className="form-control"
                  onChange={handleSelectChangecategories}
                  value={selectedValuescategories}
                />
              </div>
              <div className="col-sm-6 form-group">
                <div className="panel panel-primary" data-collapsed="0">
                  <div className="panel-heading">
                    <div className="panel-title">
                      <label className="m-0">
                        Cast and Crew :{" "}
                        <small>( Add artists for the video below )</small>
                      </label>
                    </div>
                  </div>
                  <div className="panel-body">
                    <Select
                      options={optionsartists}
                      isMulti
                      className="form-control"
                      onChange={handleSelectChangeartists}
                      value={selectedValuesartists}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 form-group">
                <label className="m-0">Choose Language:</label>
                <div>
                  <Select
                    options={optionslanguages}
                    isMulti
                    className="form-control"
                    onChange={handleSelectChangelanguages}
                    value={selectedValueslanguages}
                  />
                </div>
              </div>

              <div className="col-sm-6 form-group">
                <label className="m-0">
                  E-Paper: <small>(Upload your PDF file)</small>
                </label>
                <input
                  type="file"
                  className="form-control"
                  name="pdf_file"
                  accept="application/pdf"
                  onChange={(e) => setPdf_files(e.target.files[0])}
                  multiple
                />
              </div>

              <div className="col-sm-6 form-group">
                <label className="m-0">Reels Videos: </label>
                <div>
                  <media-player
                    title="Reels Videos"
                    src={editUser.Video_upload_url}
                    poster={editUser.title}
                    controls
                  >
                    <media-outlet></media-outlet>
                  </media-player>
                </div>

                <input
                  type="file"
                  className="form-control"
                  name="reels_videos"
                  accept="video/mp4,video/x-m4v,video/*"
                  onChange={(e) => setReelvideo(e.target.files[0])}
                  multiple
                />
              </div>

              <div className="col-sm-6 form-group">
                <label className="m-0">
                  Reels Thumbnail: <small>(9:16 Ratio or 720X1080px)</small>
                </label>
                <div>
                  <img
                    src={editUser?.Video_reels_Thumbnail}
                    width={250}
                    height={250}
                  />
                </div>
                <input
                  type="file"
                  className="form-control"
                  name="reels_thumbnail"
                  onChange={(e) => setReels_thumbnail(e.target.files[0])}
                />
              </div>

              <div className="col-sm-6 form-group">
                <label className="m-0">URL Link </label>
                <input
                  type="text"
                  className="form-control"
                  name="url_link"
                  accept=""
                  value={editUser?.url_link}
                  onChange={handleInput}
                  id="url_link"
                />
              </div>

              <div className="col-sm-6 form-group">
                <label className="m-0">
                  URL Start Time <small>Format (HH:MM:SS)</small>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="url_linktym"
                  accept=""
                  onChange={handleInput}
                  value={editUser?.url_linktym}
                  id="url_linktym"
                />
              </div>
            </div>
          </div>
          <div className="text-end mt-3">
            <Button
              variant="contained"
              onClick={handleUpdate}
              className="btn btn-primary ms-2"
            >
              Upload Video
            </Button>
            <Button onClick={handleBack} className="btn btn-dark ms-2">
              Back
            </Button>
            <Button
              variant="contained"
              onClick={handleNext}
              className="btn btn-primary ms-2"
            >
              Next
            </Button>
          </div>
        </div>
      ),
    },
    {
      label: "User Video Access",
      description: (
        <div className="form-card mt-4">
          <div className="row">
            <div className="col-lg-12">
              <h5 className="fs-title">Geo-location for Videos</h5>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 form-group">
              <label className="m-0">Block Country </label>
              <p className="p1">
                ( Choose the countries for block the videos )
              </p>
              <div>
                <Select
                  options={options}
                  isMulti
                  className="form-control"
                  onChange={handleSelectChange}
                  value={selectedValues}
                />
              </div>
            </div>

            <div className="col-sm-6 form-group">
              <label className="m-0"> Country </label>
              <p className="p1">( Choose the countries videos )</p>

              <Select
                options={optionsavailable_country}
                isMulti
                className="form-control"
                onChange={handleSelectChangeavailable_country}
                value={selectedValuesavailable_country}
              />
            </div>
          </div>

          <div className="row" id="ppv_price">
            <div className="col-sm-6 form-group">
              <label className="m-0">PPV Price:</label>
              <input
                type="text"
                className="form-control"
                placeholder="PPV Price"
                name="ppv_price"
                onChange={handleInput}
                id="price"
                value={editUser?.ppv_price}
              />
            </div>

            <div className="col-sm-6 form-group">
              <label className="m-0">IOS PPV Price:</label>
              <select
                name="ios_ppv_price"
                className="form-control"
                onChange={handleInput}
                id="ios_ppv_price"
              >
                <option value="">Select IOS PPV Price: </option>
              </select>
            </div>
          </div>

          <div className="row align-items-center">
            <div className="col-sm-6 form-group mt-2">
              <label for="">Search Tags </label>
              <input
                type="text"
                id="exist-values"
                className="tagged form-control"
                data-removeBtn="true"
                onChange={handleInput}
                name="searchtags"
                value={editUser?.search_tags}
              />
            </div>

            <div className="col-sm-6 form-group">
              <label className="m-0">Related Videos:</label>
              <div>
                <Select
                  options={optionsrelated_video}
                  isMulti
                  className="form-control"
                  onChange={handleSelectChangerelated_video}
                  value={selectedValuesrelated_video}
                />
              </div>
            </div>

            <div className="col-sm-6 form-group">
              <label className="m-0">User Access</label>
              <select
                onChange={handleInput}
                className="form-control"
                value={editUser?.access}
                onClick={(event) => {
                  setLivesource(event.target.value);
                }}
              >
                {user_access?.map((item, key) => (
                  <option value={item.role}>{item.name}</option>
                ))}
              </select>
            </div>
          </div>

          <div>{live_stream_source()}</div>

          <div className="row">
            <div className="col-sm-6 form-group mt-3" id="ppv_price">
              <div className="col-lg-12 row">
                <div className="col-lg-10 col-6">
                  <label for="global_ppv">Is this video Is Global PPV:</label>
                </div>
                <div className="col-lg-2 col-3">
                  <label className="switch">
                    <input
                      name="global_ppv"
                      onChange={handleInput}
                      defaultChecked={editUser?.global_ppv == 1 ? true : false}
                      checked={editUser?.global_ppv == 1 ? true : false}
                      type="checkbox"
                    />
                    <span
                      className="slider round"
                      name="global_ppv"
                      onChange={handleInput}
                      value={editUser?.global_ppv == 1 ? "1" : "0"}
                    ></span>
                  </label>
                </div>
              </div>
            </div>
            <div className="col-sm-6 mt-3">
              <div className="panel panel-primary" data-collapsed="0">
                <div className="panel-heading">
                  <div className="panel-title">
                    <label>
                      <h3 className="fs-title">Status Settings</h3>
                    </label>
                  </div>
                </div>

                <div className="panel-body">
                  <div className="col-lg-12 row">
                    <div className="col-lg-10 col-6">
                      {" "}
                      <label for="featured">
                        Enable this video as Featured:
                      </label>
                    </div>
                    <div className="col-lg-2 col-2">
                      <label className="switch">
                        <input
                          name="featured"
                          onChange={handleInput}
                          defaultChecked={
                            editUser?.featured == 1 ? true : false
                          }
                          checked={editUser?.featured == 1 ? true : false}
                          type="checkbox"
                        />
                        <span
                          className="slider round"
                          name="featured"
                          onChange={handleInput}
                          value={editUser?.featured == 1 ? "1" : "0"}
                        ></span>
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-12 row">
                    <div className="col-lg-10 col-6">
                      {" "}
                      <label for="active">Enable this Video:</label>
                    </div>
                    <div className="col-lg-2 col-2">
                      <label className="switch">
                        <input
                          name="active"
                          onChange={handleInput}
                          defaultChecked={editUser?.active == 1 ? true : false}
                          checked={editUser?.active == 1 ? true : false}
                          type="checkbox"
                        />
                        <span
                          className="slider round"
                          name="active"
                          onChange={handleInput}
                          value={editUser?.active == 1 ? "1" : "0"}
                        ></span>
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-12 row">
                    <div className="col-lg-10 col-6">
                      {" "}
                      <label for="banner">Enable this Video as Slider:</label>
                    </div>
                    <div className="col-lg-2 col-2">
                      <label className="switch">
                        <input
                          name="banner"
                          onChange={handleInput}
                          defaultChecked={editUser?.banner == 1 ? true : false}
                          checked={editUser?.banner == 1 ? true : false}
                          type="checkbox"
                        />
                        <span
                          className="slider round"
                          name="banner"
                          onChange={handleInput}
                          value={editUser?.banner == 1 ? "1" : "0"}
                        ></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-end mt-3">
            <Button
              variant="contained"
              onClick={handleUpdate}
              className="btn btn-primary ms-2"
            >
              Upload Video
            </Button>
            <Button onClick={handleBack} className="btn btn-dark ms-2">
              Back
            </Button>
            <Button
              variant="contained"
              onClick={handleNext}
              className="btn btn-primary ms-2"
            >
              Next
            </Button>
          </div>
        </div>
      ),
    },
    {
      label: "Upload Image & Trailer",
      description: (
        <div className="form-card mt-4">
          <div className="row">
            <div className="col-7">
              <h3 className="fs-title">Image Upload:</h3>
            </div>
            <div className="col-5"></div>
          </div>

          <div className="row">
            <div className="col-sm-6 form-group">
              <label className="mb-1">
                Video Thumbnail <span>(9:16 Ratio or 1080X1920px)</span>
              </label>
              <input
                className="form-control mt-2"
                type="file"
                name="image"
                id="image"
                onChange={(e) => setImage(e.target.files[0])}
              />
              <img
                src={editUser?.Thumbnail}
                width={300}
                height={300}
                className="video-img w-100 mt-2"
                alt="Thumbnail"
              />
            </div>

            <div className="col-sm-6 form-group">
              <label className="mb-1">
                Player Thumbnail <span>(16:9 Ratio or 1280X720px)</span>
              </label>
              <input
                className="form-control"
                type="file"
                name="player_image"
                id="player_image"
                onChange={(e) => setPlayer_image(e.target.files[0])}
              />
              <img
                src={editUser?.Player_thumbnail}
                width={300}
                height={300}
                className="video-img w-100 mt-2"
                alt="Thumbnail"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6 form-group">
              <label className="mb-1"> Video TV Thumbnail </label>
              <input
                className="form-control"
                type="file"
                name="video_tv_image"
                onChange={(e) => setVideo_tv_image(e.target.files[0])}
                id="video_tv_image"
              />

              <img
                src={editUser?.TV_Thumbnail}
                width={300}
                height={300}
                className="video-img w-100 mt-2"
                alt="Thumbnail"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6 form-group">
              <label className="mb-1"> Video Title Thumbnail </label>
              <input
                type="file"
                className="form-control"
                name="video_title_image"
                id="video_title_image"
                onChange={(e) => setVideo_Title_Thumbnail(e.target.files[0])}
              />
              <img
                src={editUser?.Video_Title_Thumbnail}
                width={300}
                height={300}
                className="video-img w-100 mt-2"
                alt="Thumbnail"
              />
            </div>

            <div className="col-sm-6 form-group">
              <label className="mb-1">Enable Video Title Thumbnail</label>
              <br />
              <div className="mt-1">
                <label className="switch" onChange={handleInput}>
                  <input
                    name="enable_video_title_image"
                    defaultChecked={
                      editUser?.enable_video_title_image == 1 ? true : false
                    }
                    checked={
                      editUser?.enable_video_title_image == 1 ? true : false
                    }
                    type="checkbox"
                  />
                  <span
                    className="slider round"
                    name="enable_video_title_image"
                    value={editUser?.enable_video_title_image == 1 ? "1" : "0"}
                  ></span>
                </label>
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-7">
              <h5 className="fs-title">Trailer Upload:</h5>
            </div>

            <div className="col-sm-6">
              <label className="m-0">Video Trailer Type:</label>
              <select
                className="trailer_type form-control"
                name="trailer_type"
                id="trailer_type"
                onChange={(e) => setTrailer_type(e.target.value)}
                onClick={(event) => {
                  setEnable_restreamtrailer(event.target.value);
                }}
              >
                <option value="null"> Select the Video Trailer Type </option>
                <option value="1"> Video Upload </option>
                <option value="m3u8_url">m3u8 Url </option>
                <option value="mp4_url">mp4 Url</option>
                <option value="embed_url">Embed Code</option>
              </select>
            </div>
          </div>

          <div>
            <div
              onClick={(event) => {
                setEnablestreamvisibletrailer(event.target.value);
              }}
            >
              {proceednexttrailer()}
            </div>
          </div>

          <div className="row">
            <div className="col-sm-8  form-group">
              <label className="m-0">Trailer Description:</label>

              <JoditEditor
                // ref={editor}
                value={editUser?.trailer_description}
                //   onChange={(newContent) => setContent(newContent)}
                // onChange={handleInput}

                onChange={(e) => {
                  handleChangeFirsttraildetails(e);
                }}
              />
            </div>
          </div>

          <div className="text-end mt-3">
            <Button
              variant="contained"
              onClick={handleUpdate}
              className="btn btn-primary ms-2"
            >
              Upload Video
            </Button>
            <Button onClick={handleBack} className="btn btn-dark ms-2">
              Back
            </Button>
            <Button
              variant="contained"
              onClick={handleNext}
              className="btn btn-primary ms-2"
            >
              Next
            </Button>
          </div>
        </div>
      ),
    },
    {
      label: "ADS Management",
      description: (
        <div className="form-card mt-4">
          <div className="row">
            <div className="col-7">
              <h2 className="fs-title">ADS Management:</h2>
            </div>

            <div className="row text-start mt-4">
              <div className="col-sm-6">
                <label className="m-0">Choose Ads Position</label>

                <div className="">
                  <Select
                    options={adsPositions}
                    onChange={(e) => handleAdsPositionChange(e)}
                  />
                </div>
              </div>

              <div className="col-sm-6">
                <label className="m-0">Choose Advertisement</label>

                <div className="">
                  <Select
                    options={secondSelectOptions}
                    onChange={(e) => setSecondSelectOptions(e)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="text-end mt-3">
            <Button onClick={handleBack} className="btn btn-dark">
              Back
            </Button>
            <Button
              variant="contained"
              className="btn btn-primary ms-2"
              onClick={handleUpdate}
            >
              Submit
            </Button>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="m-0 p-0">
      <div className="">
        <section className="section container-fluid ">
          <div className="pt-3">
            <div className="row">
              <div className="col-sm-12">
                <div className="iq-card addvideo">
                  <div className="iq-card-header d-flex justify-content-between">
                    <div className="iq-header-title">
                      <h4 className="card-title">Edit Video</h4>
                    </div>
                  </div>
                  <hr />

                  <Box>
                    <Stepper activeStep={activeStep} nonLinear>
                      {steps?.map((step, index) => (
                        <Step key={step.label}>
                          <StepLabel
                            optional={
                              index == 4 ? (
                                <Typography variant="caption"></Typography>
                              ) : null
                            }
                          >
                            {step?.label}
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>

                    <Stepper
                      className="video"
                      activeStep={activeStep}
                      nonLinear
                    >
                      {steps?.map((step, index) => (
                        <Step key={step?.label}>
                          <StepContent>
                            <Typography>{step?.description}</Typography>
                            <Box sx={{ mb: 2 }}>
                              <div>
                                {/* <Button
                                  variant="contained"
                                  onClick={handleNext}
                                  sx={{ mt: 1, mr: 1 }}
                                  className="btn btn-primary">
                                  {index == steps.length - 1 ? 'Upload Video' : 'Next'}
                                </Button>
                                <Button
                                  disabled={index == 0}
                                  onClick={handleBack}
                                  sx={{ mt: 1, mr: 1 }}
                                  className="btn btn-dark">
                                  Back
                                </Button> */}
                              </div>
                            </Box>
                          </StepContent>
                        </Step>
                      ))}
                    </Stepper>

                    {activeStep == steps.length && (
                      <Paper square elevation={0} sx={{ p: 3 }}>
                        <Typography>
                          All steps completed - you&apos;re finished
                        </Typography>
                        <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                          Reset
                        </Button>
                      </Paper>
                    )}
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default EditNewVideo;
