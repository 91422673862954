// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  /* header 1 start */

  .header1 {
    background: #121212;
  }

  /* header 1 end*/`, "",{"version":3,"sources":["webpack://./src/Contentportal/Signin/ContentHeader.css"],"names":[],"mappings":"EAAE,mBAAmB;;EAEnB;IACE,mBAAmB;EACrB;;EAEA,gBAAgB","sourcesContent":["  /* header 1 start */\n\n  .header1 {\n    background: #121212;\n  }\n\n  /* header 1 end*/"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
