// External Libraries and Packages
import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import HomePageNav from '../CommonComponents/HomePageNav';
import MusicMusicianCard from '../CommonComponents/MusicMusicianCard';
import { connect } from 'react-redux';
const MusicMusician = ({ item, swiperOptions, languageDetailsData }) => {
    return (
        <div className='mb-4'>
            <HomePageNav link={item?.source} headerName={item?.header_name} store={item?.data} seg={`${languageDetailsData?.Language_Code}/music/`} />
            <Swiper {...swiperOptions} className='musicHomePageSwiper'>
                {item?.data?.map((value, index) => (
                    <SwiperSlide key={value?.id} >
                        <MusicMusicianCard store={value} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}
const mapStateToProps = state => ({
    languageDetailsData: state.get_getUserTranslateLanguage_Reducer.languageDetailsData
})
export default connect(mapStateToProps)(MusicMusician)