import React from 'react'
import MessageBox from '../../../components/Others/MessageBox'
const InfoBox = ({ text, className, textClassName, action }) => {
    return (
        <div className={`position-absolute p-2 text-start theme-text-color rounded-2 bg-primary ${className} `}>
            <MessageBox text={text} classname={`text-white ${textClassName} `} />
            <button onClick={action} className=' bg-transparent px-3 py-1 rounded-1  border border-info d-block ms-auto' style={{ fontSize: "11px" }} ><MessageBox text={'Done'} classname={`text-white  `} /></button>
        </div>
    )
}

export default InfoBox