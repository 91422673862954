// External Libraries and Packages
import React, { lazy, useEffect } from "react";
import { ToastContainer } from 'react-toastify';
import { connect, useDispatch } from "react-redux";
import { GlobalStyles } from "./GlobalStyles";
import { ThemeProvider } from "styled-components";
import { useLocation, useNavigate } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import "rsuite/dist/rsuite.min.css";
import "react-toastify/dist/ReactToastify.css";

// Internal Styles
import "./App.css";
import "./Styles/CommonAdmin.css"
import "./Styles/root.css"
import "./Styles/response.css"

// Internal Components
import { allHomePageAction, allThumbnailAction, getUserTranslateLanguageAction, headerIndexAction, settingAction, profileDetailsAction, getFieldEnable, siteThemeAction, displayTranslateLanguageAction, footerAction, homeBannerAction } from "./Redux/Actions/apiActions";
import HelmetDetails from "./components/Others/HelmetDetails";
import { useMyContext } from "./components/Layout/CommonProvider.js";
import PageLoader from "./components/Others/PageLoader.jsx";

function App({ languageDetailsData, siteTheme, settings, themeMode, headerSetting, currentTheme, isTranslateLoading, homepage }) {
  const userData = localStorage.getItem("access_token")
  const user_role = localStorage.getItem("role");
  const user_id = localStorage.getItem("user_id");

  const AllRoutes = lazy(() => import('./Routes/AllRoutes.jsx'));

  const headers = {
    "Content-Type": "application/json",
    headers: { "Access-Control-Allow-Origin": "*" },
    Authorization: "Bearer " + userData,
  };


  const dispatch = useDispatch()
  const location = useLocation();
  const navigation = useNavigate()
  const { isOnline } = useMyContext()


  // Step 1 
  useEffect(async () => {
    if (isOnline) {
      await dispatch(siteThemeAction())
    }
  }, [])

  // translate checkout enable
  useEffect(() => {
    const loadTranslations = async () => {
      if (siteTheme?.translate_checkout) {
        if (!languageDetailsData?.Language_Code) {
          try {
            await dispatch(getUserTranslateLanguageAction(navigation));
            await dispatch(displayTranslateLanguageAction());
          } catch (error) {
            console.error("Failed to load translations:", error);
          }
        }
      }
    };
    loadTranslations();
  }, [siteTheme?.translate_checkout]);


  // When site theme true to render
  useEffect(async () => {
    if (siteTheme && Object.keys(siteTheme).length) {
      await dispatch(settingAction());
    }
  }, [siteTheme])

  // When settings true to render

  useEffect(async () => {
    if (settings && Object.keys(settings).length) {
      await dispatch(headerIndexAction())
      const shouldDispatch = (!user_role && settings.access_free == 1) || user_role;
      if (shouldDispatch) {
        dispatch(allHomePageAction());
      }
    }
  }, [settings, user_role]);

  // When header true to render

  useEffect(async () => {
    if (headerSetting && Object.keys(headerSetting)?.length) {
      await dispatch(getFieldEnable())
      dispatch(homeBannerAction());
    }
  }, [headerSetting])

  // When homepage  true to render

  useEffect(async () => {
    if (homepage?.length) {
      await dispatch(allThumbnailAction(headers))
      await dispatch(footerAction());
    }
  }, [homepage])


  useEffect(() => {
    if (user_role) {
      dispatch(profileDetailsAction());
    }
  }, [user_role])

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [location]);




  return (
    <>
      {siteTheme?.translate_checkout ? isTranslateLoading && <PageLoader className="position-fixed loaderFixed  vicky d-flex align-items-center justify-content-center min-vh-100" /> : null}
      <HelmetDetails pageTitle={headerSetting?.website_name.toUpperCase()} metaTitle={headerSetting?.website_name.toUpperCase()} metaDescription={headerSetting?.website_description?.toString().substring(0, 100)} metaImage={themeMode?.logoUrl} />
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
      <ThemeProvider theme={themeMode}>
        <GlobalStyles />
        <AllRoutes props={{ currentTheme: currentTheme, TranslateCheckOut: siteTheme, lang: languageDetailsData?.Language_Code || "en" }} />
      </ThemeProvider>
    </>
  );
}
const mapStateToProps = state => ({
  settings: state.get_setting_Reducer.settings,
  themeMode: state.get_headerIndex_Reducer.themeMode,
  headerSetting: state.get_headerIndex_Reducer.headerSetting,
  isTranslateLoading: state.get_getUserTranslateLanguage_Reducer.isLoading,
  currentTheme: state.get_siteTheme_Reducer.currentTheme,
  siteTheme: state.get_siteTheme_Reducer.siteTheme,
  homepage: state.get_allHomePage_Reducer.homepage,
  languageDetailsData: state.get_getUserTranslateLanguage_Reducer.languageDetailsData,

});
export default connect(mapStateToProps)(App)