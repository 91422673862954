import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import JoditEditor from "jodit-react";
import Select from "react-select";
import { TagsInput } from "react-tag-input-component";
import "./Contentaddseries.css";
import { toast } from "react-toastify";

import { Link, useNavigate } from "react-router-dom";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import loadings from "../../../components/Images/loading/loading.gif";
import ResponseLoader from "../../../components/Others/ResponseLoader";

function Contentaddseries() {
  const [genre_id, setGenre_id] = useState("");
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [type, setUrl_type] = useState("");
  const [details, setDetails] = useState("");
  const [description, setDescription] = useState("");
  const [active, setActive] = useState("");
  const [ppv_status, setPpv_status] = useState("");
  const [featured, setFeatured] = useState("");
  const [views, setViews] = useState("");
  const [rating, setRating] = useState("");
  const [searchTags, setSearchTags] = useState([]);
  const [image, setImage] = useState("");
  const [player_image, setPlayer_image] = useState("");
  const [tv_image, setTv_image] = useState("");
  const [embed_code, setEmbed_code] = useState("");
  const [mp4_url, setMp4_url] = useState("");
  const [webm_url, setWebm_url] = useState("");
  const [ogg_url, setOgg_url] = useState("");
  const [year, setYear] = useState("");
  const [trailer, setTrailer] = useState("");
  const [banner, setBanner] = useState("");
  const [deleted_at, setDeleted_at] = useState("");
  const [series_trailer, setSeries_trailer] = useState("");
  const [season_trailer, setSeason_trailer] = useState("");
  const [status, setStatus] = useState("");
  const [season, setSeason] = useState("");
  const [uploaded_by, setUploaded_by] = useState("");
  const [url, setUrl] = useState("");

  const [websitetitle, setWebsitetitle] = useState("");
  const [metadescription, setMetadescription] = useState("");
  const [websiteurl, setWebsiteurl] = useState("");

  const [Thumbnail, setThumbnail] = useState("");
  const [Player_thumbnail, setPlayer_thumbnail] = useState("");
  const [TV_Thumbnail, setTV_Thumbnail] = useState("");
  const [series_id, setSeries_id] = useState("");

  const [user_access, setUser_access] = useState([]);
  const [livestream_source, setLivestream_source] = useState([]);
  const [inapppurchase, setInappPurchase] = useState("");
  const [age_restrictdata, setAge_restrictdata] = useState([]);
  const [age_restrict, setAge_restrict] = useState([]);
  const [publish_time, setPublish_time] = useState("");

  const [publish_type, setPublish_type] = useState("");
  const [adsPositions, setAdsPositions] = useState([]);
  const [selectedAdsPosition, setSelectedAdsPosition] = useState(null);
  const [secondSelectOptions, setSecondSelectOptions] = useState([]);

  const [autoFillValuetitle, setAutoFillValuetitle] = useState("");

  // let access_token, access_token_cpp;

  // if (localStorage.getItem("username")) {
  //   access_token = localStorage.getItem("access_token");
  // } else {
  //   access_token_cpp = localStorage.getItem("access_token_cpp");
  // }

  // const headers = {
  //   "Content-Type": "application/json",
  //   // Authorization: "Bearer " + access_token_cpp,
  //   Accept: "application/json",
  //   "Access-Control-Allow-Origin": "*",
  // };

  // if (access_token) {
  //   headers.Authorization = "Bearer " + access_token;
  // } else if (access_token_cpp) {
  //   headers.Authorization = "Bearer " + access_token_cpp;
  // }

  const access_token_cpp = localStorage.getItem("access_token_cpp");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token_cpp,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const handleInputKeyDowntitle = (event) => {
    if (event.key == "Enter" || event.key == "Tab") {
      generateSlug(editUser?.title);
    }
  };
  const handleBlurtitle = () => {
    generateSlug(editUser?.title);
  };

  const handleAutoFillChangetitle = (event) => {
    setValidationMessageslug("");
    setIsValidationHiddenslug(true);
    setAutoFillValuetitle(event.target.value);
  };

  const generateSlug = (title) => {
    title = title.trim();
    const slug = title.toLowerCase().replace(/\s+/g, "-");
    setAutoFillValuetitle(slug);
  };

  const contentFieldChanaged = (data) => {
    setDetails(data);
    // console.log("datasssssss", data);
  };

  // console.log(inputValue, "checkboxChecked");

  const [ios_ppv_price, setIos_ppv_price] = useState("");
  const [userAccesses, setUserAccesses] = useState([]);
  const [userAccessesValue, setUserAccessesValue] = useState([]);
  const handleAccessChange = (e) => {
    setUserAccessesValue(e);
  };
  // console.log(userAccessesValue, "userAccess");

  const handleAdsPositionChange = (selectedValue5) => {
    setSelectedAdsPosition(selectedValue5);
    // console.log(selectedValue5); // Selected value
  };

  const [editUser, setEditUser] = useState({
    title: "",
    slug: "",
    duration: "",
  });

  const navigate = useNavigate();

  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputValueAPI, setInputValueAPI] = useState("");

  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
    if (!checkboxChecked) {
      setInputValue(inputValueAPI);
    } else {
      setInputValue("");
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleTagsChange = (newTags) => {
    // Convert tags to lowercase, remove duplicates, and filter out empty tags
    const cleanedTags = newTags
      .map((tag) => tag.trim().toLowerCase())
      .filter((tag) => tag !== "")
      .filter((value, index, self) => self.indexOf(value) == index);
    setSearchTags(cleanedTags);
  };

  const [publishType, setPublishType] = useState("publish_now");
  const [publishTime, setPublishTime] = useState("");
  // const [formDatastore, setFormData] = useState(new FormData());

  const handlePublishTypeChange = (event) => {
    const selectedValue = event.target.value;
    setPublishType(selectedValue);

    if (selectedValue == "publish_later") {
      setPublishTime(""); // Reset the publish time when switching to "Publish Later"
    }
  };

  const handlePublishTimeChange = (event) => {
    const timeValue = event.target.value;
    setPublishTime(timeValue);
  };

  const [inputValueyear, setInputValueyear] = useState("");

  const handleChangeyear = (event) => {
    const value = event.target.value;
    if (/^\d{0,4}$/.test(value)) {
      setInputValueyear(value);
    }
  };

  const handleInputdata = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;

    // Remove non-digit characters from input
    const formattedInput = inputValue.replace(/\D/g, "");

    // Add colons to maintain the format HH:MM:SS
    const formattedTime = formattedInput
      .replace(/^(\d{2})(\d{2})(\d{2})$/, "$1:$2:$3")
      .substr(0, 8);

    setEditUser({
      ...editUser,
      [inputName]: formattedTime,
    });
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessageslug("");
    setIsValidationHiddenslug(true);
    setValidationMessagetitle("");
    setIsValidationHiddentitle(true);

    setEditUser({ ...editUser, title: e.target.value });
    setEditUser({ ...editUser, [e.target.name]: e.target.value });

    setActive({ [e.target.name]: e.target.value });

    setFeatured({ ...featured, [e.target.name]: e.target.value });
    setStatus({ ...status, [e.target.name]: e.target.value });
    setPpv_status({ ...ppv_status, [e.target.name]: e.target.value });
    setBanner({ ...banner, [e.target.name]: e.target.value });
    // setActive({ ...active, [e.target.name]: e.target.value });
    setSeason_trailer({ ...season_trailer, [e.target.name]: e.target.value });
    setSeries_trailer({ ...series_trailer, [e.target.name]: e.target.value });
    setViews({ ...views, [e.target.name]: e.target.value });
    setTrailer({ ...trailer, [e.target.name]: e.target.value });

    if (e.target.checked == true) {
      setPpv_status({ ...ppv_status, [e.target.name]: 1 });
      setFeatured({ ...featured, [e.target.name]: 1 });
      setStatus({ ...status, [e.target.name]: 1 });
      setBanner({ ...banner, [e.target.name]: 1 });
      setActive({ ...active, [e.target.name]: 1 });
      setSeason_trailer({ ...season_trailer, [e.target.name]: 1 });
      setSeries_trailer({ ...series_trailer, [e.target.name]: 1 });
      setViews({ ...views, [e.target.name]: 1 });
      setTrailer({ ...trailer, [e.target.name]: 1 });
    } else {
      setPpv_status({ ...ppv_status, [e.target.name]: 0 });
      setFeatured({ ...featured, [e.target.name]: 0 });
      setBanner({ ...banner, [e.target.name]: 0 });
      setActive({ ...active, [e.target.name]: 0 });
      setSeason_trailer({ ...season_trailer, [e.target.name]: 0 });
      setSeries_trailer({ ...series_trailer, [e.target.name]: 0 });
      setViews({ ...views, [e.target.name]: 0 });
      setTrailer({ ...trailer, [e.target.name]: 0 });
    }
  };

  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);

  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFile3, setSelectedFile3] = useState(null);

  const handleFileChange = (event, fileInputRef, setSelectedFile) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDelete = (
    setSelectedFile1,
    setSelectedFile2,
    setSelectedFile3
  ) => {
    setSelectedFile1(null);
    if (fileInputRef1.current) {
      fileInputRef1.current.value = null;
    }
    setSelectedFile2(null);
    if (fileInputRef2.current) {
      fileInputRef2.current.value = null;
    }
    setSelectedFile3(null);
    if (fileInputRef3.current) {
      fileInputRef3.current.value = null;
    }
  };

  // Multiselect
  const [block_country, setBlock_Country] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [available_country, setAvailable_country] = useState([]);
  const [
    selectedOptionsAvailable_country,
    setSelectedOptionsAvailable_country,
  ] = useState([]);

  const [language, setLanguage] = useState([]);
  const [selectedOptionslanguage, setSelectedOptionslanguage] = useState([]);

  const [artists, setArtists] = useState([]);
  const [selectedOptionsartists, setSelectedOptionsartists] = useState([]);

  const [category, setCategory] = useState([]);
  const [selectedOptionscategory, setSelectedOptionscategory] = useState([]);

  const [audio_albums, setAudio_albums] = useState([]);
  const [selectedOptionsaudio_albums, setSelectedOptionsaudio_albums] =
    useState([]);

  useEffect(() => {
    fetchOptions();
  }, []);

  const fetchOptions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/series/create`,
        { headers: headers }
      );
      setBlock_Country(response?.data?.Block_Country);
      setAvailable_country(response?.data?.Available_country);
      setLanguage(response?.data?.languages);
      setArtists(response?.data?.artists);
      setUser_access(response?.data?.user_access);
      setInappPurchase(response?.data?.InappPurchase);
      // setLivestream_source(response?.data?.Livestream_source);
      setCategory(response?.data?.series_categories);

      const globelset = response?.data?.ppv_gobal_price;
      setInputValueAPI(globelset);

      // console.log(response?.data?.user_access);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectChange = (selectedValues) => {
    setSelectedOptions(selectedValues);
  };

  const handleSelectChangeAvailable_country = (selectedValues) => {
    setSelectedOptionsAvailable_country(selectedValues);
  };

  const handleSelectChangeaudio_albums = (selectedValues) => {
    setSelectedOptionsaudio_albums(selectedValues);
  };

  const handleSelectChangelanguage = (selectedValues) => {
    setValidationMessagelanguage("");
    setIsValidationHiddenlanguage(true);
    setSelectedOptionslanguage(selectedValues);
  };

  const handleSelectChangeartists = (selectedValues) => {
    setSelectedOptionsartists(selectedValues);
  };

  const handleSelectChangecategory = (selectedValues) => {
    setValidationMessagecategory("");
    setIsValidationHiddencategory(true);
    setSelectedOptionscategory(selectedValues);
  };

  // Multiselect

  const [validationMessagetitle, setValidationMessagetitle] = useState("");
  const [isValidationHiddentitle, setIsValidationHiddentitle] = useState(true);

  const [validationMessageslug, setValidationMessageslug] = useState("");
  const [isValidationHiddenslug, setIsValidationHiddenslug] = useState(true);

  const [validationMessagecategory, setValidationMessagecategory] =
    useState("");
  const [isValidationHiddencategory, setIsValidationHiddencategory] =
    useState(true);

  const [validationMessagelanguage, setValidationMessagelanguage] =
    useState("");
  const [isValidationHiddenlanguage, setIsValidationHiddenlanguage] =
    useState(true);

  const titleInputRef = useRef(null);
  const categoryInputRef = useRef(null);
  const languageInputRef = useRef(null);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  async function series() {
    let focusInputRef = null;

    if (editUser?.title == "") {
      setValidationMessagetitle("Title cannot be empty.");
      setIsValidationHiddentitle(false);
      focusInputRef = titleInputRef;
    }
    if (selectedOptionscategory?.length == 0) {
      setValidationMessagecategory("Please select at least one Category.");
      setIsValidationHiddencategory(false);
      focusInputRef = categoryInputRef;
    }
    if (selectedOptionslanguage?.length == 0) {
      setValidationMessagelanguage("Please select at least one Language.");
      setIsValidationHiddenlanguage(false);
      focusInputRef = languageInputRef;
    }

    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }

    const editInputfeatured = featured?.featured;
    var datafeatured = JSON.stringify(editInputfeatured);
    var featureddata = datafeatured;

    const editInputbanner = banner?.banner;
    var databanner = JSON.stringify(editInputbanner);
    var bannerdata = databanner;

    const editInputvalue = active?.active;
    var data = JSON.stringify(editInputvalue);
    var aactivedata = data;

    const editInputvalueseason_trailer = season_trailer?.season_trailer;
    var data = JSON.stringify(editInputvalueseason_trailer);
    var season_trailerdata = data;

    const editInputvalueviews = views?.views;
    var data = JSON.stringify(editInputvalueviews);
    var viewsdata = data;

    const editInputvalueseries_trailer = series_trailer?.series_trailer;
    var data = JSON.stringify(editInputvalueseries_trailer);
    var series_trailerdata = data;

    // const editInputvalueppvstatus = ppvstatus.ppvstatus;
    // var data = JSON.stringify(editInputvalueppvstatus);
    // var ppvstatusdata = data;

    const formData = new FormData();
    formData.append("Content_Partner_id", 1);
    formData.append("title", editUser?.title);
    formData.append("genre_id", genre_id);
    formData.append("mp4_url", mp4_url);
    formData.append("slug", autoFillValuetitle);
    formData.append("type", type);
    formData.append("access", storeseriesuseraccess);
    formData.append("details", details);
    formData.append("description", description);
    formData.append("active", aactivedata ? parseInt(aactivedata, 10) : 0);
    formData.append("ppv_status", Number.isNaN(parseInt(ppv_status, 10)) ? 0 : parseInt(ppv_status, 10));
    formData.append("status", status);
    formData.append("featured", Number.isNaN(parseInt(featureddata, 10)) ? 0 : parseInt(featureddata, 10));
    formData.append("duration", editUser?.duration);
    formData.append("views", viewsdata ? parseInt(viewsdata, 10) : 0);
    formData.append("rating", rating);
    formData.append("search_tag", searchTags);
    formData.append("ppv_price", inputValue);
    formData.append("ios_ppv_price", ios_ppv_price?.value);

    formData.append(
      "artists",
      JSON.stringify(selectedOptionsartists?.map((option) => option.value))
    );
    formData.append(
      "block_country_id",
      JSON.stringify(selectedOptions?.map((option) => option.value))
    );
    formData.append(
      "genre_id",
      JSON.stringify(selectedOptionscategory?.map((option) => option.value))
    );
    formData.append(
      "languages_id",
      JSON.stringify(selectedOptionslanguage?.map((option) => option.value))
    );
    formData.append(
      "available_countries_id",
      JSON.stringify(
        selectedOptionsAvailable_country?.map((option) => option.value)
      )
    );
    formData.append("embed_code", embed_code);
    formData.append("mp4_url", mp4_url);
    formData.append("webm_url", webm_url);
    formData.append("ogg_url", ogg_url);
    // formData.append("language", language);
    formData.append("year", inputValueyear);
    formData.append("trailer", trailer);
    formData.append("banner", Number.isNaN(parseInt(bannerdata, 10)) ? 0 : parseInt(bannerdata, 10));
    formData.append("series_trailer", series_trailerdata ? parseInt(series_trailerdata, 10) : 0);
    formData.append("season_trailer", season_trailerdata);
    formData.append("uploaded_by", uploaded_by);
    formData.append("url", url);
    formData.append("deleted_at", deleted_at);
    formData.append("Thumbnail", Thumbnail);
    formData.append("Player_thumbnail", Player_thumbnail);
    formData.append("TV_Thumbnail", TV_Thumbnail);

    formData.append("ads_position", selectedAdsPosition?.value);
    formData.append("live_ads", secondSelectOptions?.value);

    let firstApiStatus = false;
    let secondApiStatus = false;
    let seventhApiStatus = false;

    let resultapi = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/series/store`,
        formData,
        { headers: headers }
      );

      if (response.data.status) {
        firstApiStatus = true;

        resultapi = response.data;
        var series_id = response.data.Series_id;
        setSeries_id(series_id);

        const imageFormData = new FormData();
        imageFormData.append("series_id", series_id);
        if (selectedFile1) {
          imageFormData.append("image", selectedFile1);
        }
        if (selectedFile2) {
          imageFormData.append("player_image", selectedFile2);
        }
        if (selectedFile3) {
          imageFormData.append("tv_image", selectedFile3);
        }

        const imageResponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/CPP/Backend/series/Image_upload`,
          imageFormData,
          { headers: headers }
        );
        const formDataseo = new FormData();
        formDataseo.append("series_id", series_id);
        formDataseo.append("website_page_title", websitetitle);
        formDataseo.append("website_URL", websiteurl);
        formDataseo.append("Meta_description", metadescription);

        const formDataseoresponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/CPP/Backend/series/SEO`,
          formDataseo,
          { headers: headers }
        );

        if (imageResponse.data.status == true) {
          secondApiStatus = true;
        }
        if (formDataseoresponse.data.status == true) {
          seventhApiStatus = true;
        }
      }
      else {
        toast.error(response?.data?.message);
        setShowOverlay(false);
      }
    } catch (error) {

    }

    if (firstApiStatus && secondApiStatus && seventhApiStatus) {
      setValidationMessagetitle("");
      setValidationMessageslug("");
      toast.success(resultapi?.message);
      setTimeout(async () => {
        await navigate(`/cpp/edit-series/${series_id}`);
      }, 3000);
    } else {
      if (resultapi == false) {
        var resultError = resultapi;
        toast.error(resultError?.message);
        setValidationMessageslug(false);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  }

  useEffect(async () => {
    const fetchAdsPositions = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_Baseurl}/CPP/Backend/series/create`,
          {
            headers: headers,
          }
        );

        if (response.ok) {
          const data = await response?.json();
          // console.log(data);

          // const adsPositionOptions = data?.ads_position?.map((position) => ({
          //   value: position?.position,
          //   label: position?.name,
          // }));
          // // console.log(adsPositionOptions);
          // setAdsPositions(adsPositionOptions);
        } else {
          throw new Error("Request failed with status: " + response?.status);
        }
      } catch (error) {
        console.log("Error fetching ads positions:", error);
      }
    };
    fetchAdsPositions();
  }, []);

  useEffect(() => {
    // if (selectedAdsPosition !== "") {
    //   const formData = new FormData();
    //   formData.append("position", selectedAdsPosition?.value);
    //   axios.post(
    //     `${process.env.REACT_APP_Baseurl}/CPP/advertisement-tag-url`,
    //     formData,
    //     { headers: headers }
    //   ).then((response) => {
    //     const data = response.data;
    //     const adsNames = data?.Advertisement?.map((position) => ({
    //       value: position?.id,
    //       label: position?.ads_name,
    //     }));
    //     setSecondSelectOptions(adsNames);
    //   })
    //     .catch((error) => {
    //       console.error("Error:", error);
    //     });
    // }
  }, [selectedAdsPosition]);

  const [enable_restreampublic, setEnable_restreampublic] = useState("");
  const [enablestreamvisiblepublic, setEnablestreamvisiblepublic] =
    useState("");

  function proceednextpublic() {
    switch (enable_restreampublic) {
      case "publish_now":
        return <div></div>;
      case "publish_later":
        return (
          <div className="mb-3">
            <label className="m-0">Publish Time</label>
            <input
              type="datetime-local"
              className="rs-input mt-2  form-control-lg"
              id="publish_time"
              name="publish_time"
              onChange={(e) => setPublish_time(e.target.value)}
            />
          </div>
        );
      default:
        return null;
    }
  }


  const [allseriesuseraccess, setallseriesuseraccess] = useState("");
  const [storeseriesuseraccess, setStoreseriesuseraccess] = useState("");

  function allseriesuseruseraccess() {
    switch (allseriesuseraccess) {
      case "guest":
        return <div></div>;
      case "subscriber":
        return <div></div>;
      case "registered":
        return <div></div>;
      case "ppv":
        return (
          <div className="row mt-3 text-start ">
            <div className="col-sm-12">
              <label className="m-0 theme-text-color">PPV Price</label>
              <div className="col-lg-12 row">
                <div className="col-8 col-lg-8">
                  <p className="p1 theme-text-color">
                    Apply PPV Price from Global Settings?{" "}
                  </p>
                </div>
                <div className="col-4 col-lg-4">
                  <label className="switch">
                    <input
                      name="ppv_price"
                      defaultChecked={checkboxChecked}
                      onChange={handleCheckboxChange}
                      type="checkbox"
                      className="rs-input"
                    />
                    <span
                      className="slider round"
                      name="ppv_price"
                      checked={checkboxChecked}
                      onChange={handleCheckboxChange}
                    ></span>
                  </label>
                </div>
              </div>

              <div className="panel-body">
                <input
                  type="number"
                  className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                  placeholder="PPV Price"
                  name="ppv_price"
                  id="price"
                  value={inputValue}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="col-sm-12 mt-2">
              <label className="m-0 theme-text-color"> IOS PPV Price</label>
              <p className="p1 theme-text-color">
                Apply IOS PPV Price from Global Settings?
              </p>
              <div className="panel-body ppv_price_ios  mt-2">
                <select
                  onChange={(e) => setIos_ppv_price(e.target.value)}
                  className="form-select  theme-bg-color theme-text-color"
                  id="ios_ppv_price"
                  name="ios_ppv_price"
                >
                  <option value="">Choose a PPV Price</option>
                  {inapppurchase?.map((item, key) => (
                    <option value={item?.id}>{item?.plan_price}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid  theme-bg-color-secondary  AddChannelLive">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-lg-6">
                <h3 className=" theme-text-color">Add New Series</h3>
              </div>
              <div className="col-6 col-sm-6 col-lg-6">
                <div className="text-end">
                  <button onClick={series} className="btn btn-primary">
                    Save Series
                  </button>
                </div>
              </div>
            </div>

            <div>
              {showOverlay && (<ResponseLoader />)}
            </div>

            <div className="row col-lg-12 ">
              <div className="col-6 col-sm-6 col-md-6 col-lg-6 ">
                <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                  <h5 className=" theme-text-color">Create Series</h5>
                  <hr className="theme-text-color"></hr>
                  <div className=" text-start">
                    <label className="m-0 theme-text-color">
                      Title<span className="mandatory">*</span>
                    </label>
                    <p className="t-1 theme-text-color">
                      Add the series title in the textbox below
                    </p>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="title"
                        name="title"
                        className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                        onChange={handleInput}
                        value={editUser?.title}
                        onBlur={handleBlurtitle}
                        onKeyDown={handleInputKeyDowntitle}
                        placeholder="Title"
                        ref={titleInputRef}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddentitle && (
                        <p>{validationMessagetitle}</p>
                      )}
                    </span>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0 theme-text-color">Slug</label>
                    <p className="t-1 theme-text-color">Add a URL Slug</p>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="slug"
                        name="slug"
                        className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                        value={autoFillValuetitle}
                        onChange={handleAutoFillChangetitle}
                        placeholder="slug"
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddenslug && (
                        <p>{validationMessageslug}</p>
                      )}
                    </span>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0 theme-text-color">
                      Short Description
                    </label>
                    <p className="t-1 theme-text-color">
                      Add a short description of the series below
                    </p>
                    <div className="mt-2">
                      <textarea
                        className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                        id="description"
                        name="description"
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder="Description"
                      />
                    </div>
                  </div>
                  <div className="row mt-2 text-start">
                    <div className="col-12">
                      <label className="m-0 theme-text-color">
                        Series Details, Links, and Info
                      </label>
                      <div className="mt-2">
                        <JoditEditor
                          className="rs-input  theme-bg-color-secondary  theme-text-color border-0"
                          value={details}
                          onChange={(newContent) =>
                            contentFieldChanaged(newContent)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2 text-start">
                    <div className="col-12">
                      <label className="m-0 theme-text-color">Duration</label>
                      <p className="t-1 theme-text-color">
                        Enter The Series Duration In (HH : MM : SS)
                      </p>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="duration"
                          maxLength="8"
                          className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                          onChange={handleInputdata}
                          value={editUser?.duration}
                        // placeholder="duration"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2 text-start">
                    <div className="col-12">
                      <label className="m-0 theme-text-color">Year</label>
                      <p className="t-1 theme-text-color">
                        Enter The Series Year
                      </p>
                      <div className="mt-2">
                        <input
                          type="number"
                          name="number"
                          value={inputValueyear}
                          onChange={handleChangeyear}
                          className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                          placeholder="Year"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-12 ">
                  <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                    <h5 className=" theme-text-color">Organize</h5>
                    <hr className="theme-text-color"></hr>

                    <div className=" text-start">
                      <label className="m-0 theme-text-color">
                        Series Ratings
                      </label>
                      <p className="t-1 theme-text-color">
                        IMDb Ratings 10 out of 10
                      </p>
                      <div className="mt-2">
                        <select
                          className="form-select   theme-bg-color-secondary  theme-text-color border-0"
                          id="rating"
                          name="rating"
                          size="lg"
                          onChange={(e) => setRating(e.target.value)}
                        >
                          {/* <option value="0">0</option> */}
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                        </select>
                      </div>
                    </div>

                    <div className=" text-start mt-2">
                      <label className="m-0 theme-text-color">
                        Cast and Crew
                      </label>
                      <p className="t-1 theme-text-color">
                        Select A Series Artist Below
                      </p>

                      <div className="mt-2">
                        <Select
                          options={artists?.map((option) => ({
                            value: option?.id,
                            label: option?.artist_name,
                          }))}
                          className="  theme-bg-color-secondary  theme-text-color"
                          isMulti
                          value={selectedOptionsartists}
                          onChange={handleSelectChangeartists}
                        />
                      </div>
                    </div>
                    <div className=" text-start mt-2">
                      <label className="m-0 theme-text-color">
                        Category<span className="mandatory">*</span>
                      </label>
                      <p className="t-1 theme-text-color">
                        Select A Series Category Below
                      </p>

                      <div className="mt-2">
                        <Select
                          options={category?.map((option) => ({
                            value: option?.id,
                            label: option?.name,
                          }))}
                          className="  theme-bg-color-secondary  theme-text-color"
                          isMulti
                          value={selectedOptionscategory}
                          onChange={handleSelectChangecategory}
                          ref={categoryInputRef}
                        />
                      </div>
                      <span className="errorred">
                        {!isValidationHiddencategory && (
                          <p>{validationMessagecategory}</p>
                        )}
                      </span>
                    </div>

                    <div className=" text-start mt-2">
                      <label className="m-0 theme-text-color">
                        Language<span className="mandatory">*</span>
                      </label>
                      <p className="t-1 theme-text-color">
                        Select A Series Language Below
                      </p>
                      <div className="text-dark">
                        <Select
                          options={language?.map((option) => ({
                            value: option?.id,
                            label: option?.name,
                          }))}
                          className="  theme-bg-color-secondary  theme-text-color"
                          isMulti
                          value={selectedOptionslanguage}
                          onChange={handleSelectChangelanguage}
                          ref={languageInputRef}
                        />
                      </div>
                      <span className="errorred">
                        {!isValidationHiddenlanguage && (
                          <p>{validationMessagelanguage}</p>
                        )}
                      </span>
                    </div>

                    <div className=" text-start mt-2">
                      <label className="m-0 theme-text-color">
                        Block Country
                      </label>
                      <p className="t-1 theme-text-color">
                        Select A Series country Below
                      </p>
                      <div className="text-dark">
                        <Select
                          options={block_country?.map((option) => ({
                            value: option?.id,
                            label: option?.name,
                          }))}
                          className="  theme-bg-color-secondary  theme-text-color"
                          isMulti
                          value={selectedOptions}
                          onChange={handleSelectChange}
                        />
                      </div>
                    </div>

                    <div className=" text-start mt-2">
                      <label className="m-0 theme-text-color">
                        Available Country
                      </label>
                      <p className="t-1 theme-text-color">
                        Block the Audio for Selected Country
                      </p>
                      <div className="text-dark">
                        <Select
                          options={available_country?.map((option) => ({
                            value: option?.id,
                            label: option?.country_name,
                          }))}
                          className="  theme-bg-color-secondary  theme-text-color"
                          isMulti
                          value={selectedOptionsAvailable_country}
                          onChange={handleSelectChangeAvailable_country}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                  <h5 className=" theme-text-color">Search Tags</h5>
                  <hr className="theme-text-color"></hr>

                  <div className="col-sm-12">
                    <p className="p2 theme-text-color">
                      You don't have any search keywords.
                    </p>

                    <div className="mt-2">
                      <TagsInput
                        value={searchTags}
                        className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                        onChange={handleTagsChange}
                        name="search_tags"
                      />
                    </div>
                  </div>
                </div>

                <div className="text-start mb-3">
                  <button onClick={series} className="btn btn-primary">
                    Save Series
                  </button>
                </div>
              </div>

              {/* Second Part of Section  */}

              <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                  <h5 className=" theme-text-color">Thumbnails</h5>
                  <hr className="theme-text-color"></hr>

                  <div className=" text-start mt-2">
                    <label className="m-0 theme-text-color">
                      Series Image Cover
                    </label>
                    <p className="t-1 theme-text-color">
                      Select the Series image( 16:9 Ratio or 1280X720px )
                    </p>
                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef1.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef1}
                              onChange={(event) =>
                                handleFileChange(
                                  event,
                                  fileInputRef1,
                                  setSelectedFile1
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label className=" theme-text-color">
                              <CameraRetroIcon className=" theme-text-color" />
                            </label>
                          </div>
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div>
                            {selectedFile1 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL.createObjectURL(selectedFile1)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() =>
                                        handleDelete(setSelectedFile1)
                                      }
                                      type="button"
                                      className="btn-close theme-text-color"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0 theme-text-color">
                      Player Image Cover
                    </label>
                    <p className="t-1 theme-text-color">
                      Select the Series image( 16:9 Ratio or 1280X720px )
                    </p>
                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef2.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef2}
                              onChange={(event) =>
                                handleFileChange(
                                  event,
                                  fileInputRef2,
                                  setSelectedFile2
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label className=" theme-text-color">
                              <CameraRetroIcon className=" theme-text-color" />
                            </label>
                          </div>
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div>
                            {selectedFile2 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL.createObjectURL(selectedFile2)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() =>
                                        handleDelete(setSelectedFile2)
                                      }
                                      type="button"
                                      className="btn-close theme-text-color"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0 theme-text-color">
                      TV Image Cover
                    </label>
                    <p className="t-1 theme-text-color">
                      Select the Series image( 16:9 Ratio or 1280X720px )
                    </p>
                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef3.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef3}
                              onChange={(event) =>
                                handleFileChange(
                                  event,
                                  fileInputRef3,
                                  setSelectedFile3
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label className=" theme-text-color">
                              <CameraRetroIcon className=" theme-text-color" />
                            </label>
                          </div>
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div>
                            {selectedFile3 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL.createObjectURL(selectedFile3)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() =>
                                        handleDelete(setSelectedFile3)
                                      }
                                      type="button"
                                      className="btn-close theme-text-color"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                    <h5 className=" theme-text-color">Visibility</h5>
                    <hr className="theme-text-color"></hr>

                    <div className="col-sm-12">
                      <label className="mb-2 theme-text-color">
                        Publish Type
                      </label>
                      <div>
                        <input
                          type="radio"
                          id="publish_now"
                          name="publish_type"
                          value="publish_now"
                          checked={publishType == "publish_now"}
                          onChange={handlePublishTypeChange}
                        />
                        <label
                          htmlFor="publish_now"
                          className="mt-2 ms-1 theme-text-color"
                        >
                          Publish Now
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="publish_later"
                          name="publish_type"
                          value="publish_later"
                          checked={publishType == "publish_later"}
                          onChange={handlePublishTypeChange}
                        />
                        <label
                          htmlFor="publish_later"
                          className="mt-2 ms-1 theme-text-color"
                        >
                          Publish Later
                        </label>
                        {publishType == "publish_later" && (
                          <div className="mb-3">
                            <label className="mt-2 theme-text-color">
                              Publish Time
                            </label>
                            <input
                              type="datetime-local"
                              className=" mt-2 rs-input form-control-lg theme-bg-color theme-text-color"
                              id="publish_time"
                              name="publish_time"
                              value={publishTime}
                              onChange={handlePublishTimeChange}
                            />
                          </div>
                        )}
                      </div>
                      {/* <button onClick={handleFormSubmit}>Submit</button> */}
                    </div>
                  </div>
                  <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                    <h5 className=" theme-text-color">Access</h5>
                    <hr className="theme-text-color"></hr>

                    <div className="col-sm-12">
                      <label className="m-0 theme-text-color">
                        User Access
                      </label>
                      <p className="p1 theme-text-color">
                        Who Is Allowed To View This Series ?
                      </p>
                      <select
                        onChange={(e) =>
                          setStoreseriesuseraccess(e.target.value)
                        }
                        className="form-select   theme-bg-color-secondary  theme-text-color border-0"
                        id="access"
                        name="access"
                        onClick={(event) => {
                          setallseriesuseraccess(event.target.value);
                        }}
                      >
                        {/* <option value={item.role}>{item.name}</option> */}
                        {user_access?.map((item, key) => (
                          <option value={item?.role}>{item?.name}</option>
                        ))}
                      </select>
                    </div>

                    <div>{allseriesuseruseraccess()}</div>
                  </div>

                  <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                    <h5 className=" theme-text-color">Status Settings</h5>
                    <hr className="theme-text-color"></hr>

                    <div className="col-sm-12 row">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <p className="p2 theme-text-color">
                          Is this series Featured
                        </p>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3">
                        <label className="switch">
                          <input
                            name="featured"
                            onChange={handleInput}
                            className="rs-input"
                            defaultChecked={
                              featured?.featured == 1 ? true : false
                            }
                            checked={featured?.featured == 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="featured"
                            onChange={handleInput}
                            value={featured?.featured == 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div>

                    <div className="col-sm-12 row mt-2">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <p className="p2 theme-text-color">
                          Is this series Active
                        </p>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3">
                        <label className="switch">
                          <input
                            name="active"
                            onChange={handleInput}
                            defaultChecked={active?.active == 1 ? true : false}
                            checked={active?.active == 1 ? true : false}
                            className="rs-input"
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="active"
                            onChange={handleInput}
                            value={active?.active == 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-12 row mt-2">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <p className="p2 theme-text-color">
                          Enable this series as Slider
                        </p>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3">
                        <label className="switch">
                          <input
                            name="banner"
                            onChange={handleInput}
                            className="r-input"
                            defaultChecked={banner?.banner == 1 ? true : false}
                            checked={banner?.banner == 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="banner"
                            onChange={handleInput}
                            value={banner?.banner == 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-12 row mt-2">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <p className="p2 theme-text-color">Season Trailer</p>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3">
                        <label className="switch">
                          <input
                            name="series_trailer"
                            id="series_trailer"
                            onChange={handleInput}
                            className="r-input"
                            defaultChecked={
                              series_trailer?.series_trailer == 1 ? true : false
                            }
                            checked={
                              series_trailer?.series_trailer == 1 ? true : false
                            }
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="series_trailer"
                            id="series_trailer"
                            onChange={handleInput}
                            value={
                              series_trailer?.series_trailer == 1 ? "1" : "0"
                            }
                          ></span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                    <h5 className=" theme-text-color">SEO</h5>
                    <hr className="theme-text-color"></hr>

                    <div className="col-sm-12">
                      <label className="m-0 theme-text-color">
                        Website Page Title
                      </label>

                      <div className=" mt-2">
                        <input
                          type="text"
                          className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                          onChange={(e) => setWebsitetitle(e.target.value)}
                        // placeholder="websitetitle"
                        />
                      </div>
                    </div>

                    <div className="col-sm-12 mt-2">
                      <label className="m-0 theme-text-color">
                        Website URL
                      </label>

                      <div className=" mt-2">
                        <input
                          type="text"
                          className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                          onChange={(e) => setWebsiteurl(e.target.value)}
                        // placeholder="websitetitle"
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 mt-2">
                      <label className="m-0 theme-text-color">
                        Meta Description
                      </label>

                      <div className=" mt-2">
                        <textarea
                          type="text"
                          className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                          onChange={(e) => setMetadescription(e.target.value)}
                        // placeholder="metadescription"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Contentaddseries;
