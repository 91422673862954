// External Libraries and Packages
import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
// Internal Styles
import "swiper/css";
import "swiper/css/navigation";
import HomePageNav from '../CommonComponents/HomePageNav';
import MusicCategoryCard from '../CommonComponents/MusicCategoryCard';
import { connect } from 'react-redux';


const MusicCategory = ({ item ,languageDetailsData}) => {
    const swiperConfig = {
        slidesPerView: 1,
        spaceBetween: 15,
        navigation: true,
        lazy: true,
        slidesPerGroup: 1,
        breakpoints: {
            376: { slidesPerView: 1, spaceBetween: 15, slidesPerGroup: 1 },
            576: { slidesPerView: 3, spaceBetween: 15, slidesPerGroup: 3 },
            768: { slidesPerView: 3, spaceBetween: 15, slidesPerGroup: 3 },
            1024: { slidesPerView: 3, spaceBetween: 15, slidesPerGroup: 3 },
            1200: { slidesPerView: 3, spaceBetween: 15, slidesPerGroup: 3 },
        },
        modules: [Navigation],
        className: 'musicCategorySwiper'
    }

    return (
        <div className='mb-4'>
            <HomePageNav link={item?.source} headerName={item?.header_name} store={item?.data} seg={`${languageDetailsData?.Language_Code}/music/`} />
            <Swiper {...swiperConfig}>
                {item?.data?.map((value, index) => (
                    <SwiperSlide key={value?.id} >
                        <MusicCategoryCard store={value} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}
const mapStateToProps = state => ({
    languageDetailsData: state.get_getUserTranslateLanguage_Reducer.languageDetailsData
})
export default connect(mapStateToProps)(MusicCategory)