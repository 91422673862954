// External Libraries and Packages
import { Navigate, Route, Routes } from "react-router-dom";
import React, { Suspense } from "react";
// Internal Styles
// Internal Components
import LoaderPage from '../Layout/LoaderPage'
import AdvertiserCommonLayout from '../AdvertiserPortal/Components/Layout/AdvertiserCommonLayout';
import AdvertiserDashBoard from "../AdvertiserPortal/Components/AdvertiserDashBoard";
import AdvertiserSignUp from "../AdvertiserPortal/Components/SigninAndSignup/AdvertiserSignUp";
import AdvertiserSignIn from "../AdvertiserPortal/Components/SigninAndSignup/AdvertiserSignIn";
import AdvertiserAllAdvertisements from "../AdvertiserPortal/Components/AdvertiserAllAdvertisements";
import AdvertiserAdsAnalyticsCPV from "../AdvertiserPortal/Components/AdvertiserAdAnalyticsCPV";
import AdvertiserAdsAnalyticsCPC from "../AdvertiserPortal/Components/AdvertiserAdsAnalyticsCPC";
import AdvertiserFeaturedAdsHistory from "../AdvertiserPortal/Components/AdvertiserFeaturedAdsHistory";
import AdvertiserPlansHistory from "../AdvertiserPortal/Components/AdvertiserPlansHistory";
import AdvertiserAddNewAds from "../AdvertiserPortal/Components/AdvertiserAddNewAds";
import AdvertiserScheduleAdvertisement from "../AdvertiserPortal/Components/AdvertiserScheduleAdvertisement";
import { connect } from "react-redux";


const AdvertiserAllRoutes = () => {
    const accessToken = true
    return (
        <Suspense fallback={<LoaderPage />} >
            <Routes>
                <Route path='/signin' element={<AdvertiserSignIn />}></Route>
                <Route path='/signup' element={<AdvertiserSignUp />}></Route>
                <Route path='/' element={<AdvertiserCommonLayout />}>
                    <Route index element={<AdvertiserDashBoard />} ></Route>
                    <Route path="/ads-list" element={<AdvertiserAllAdvertisements />}></Route>
                    <Route path="/upload-ads" element={<AdvertiserAddNewAds />}></Route>
                    <Route path="/schedule-ads" element={<AdvertiserScheduleAdvertisement />}></Route>
                    <Route path="/cpc" element={<AdvertiserAdsAnalyticsCPC />}></Route>
                    <Route path="/cpv" element={<AdvertiserAdsAnalyticsCPV />}></Route>
                    <Route path="/featured-history" element={<AdvertiserFeaturedAdsHistory />}></Route>
                    <Route path="/plans-history" element={<AdvertiserPlansHistory />}></Route>
                </Route>
            </Routes>
        </Suspense>
    )
}

export default AdvertiserAllRoutes