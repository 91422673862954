import React from "react";
import { Link } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";

const Breadcrumb = ({ bread1, bread2, bread3, link1, link2, slase ,color}) => {
  return (

    <nav className="py-md-3 py-2">
      <ul className="list-unstyled m-0 p-0 d-flex flex-wrap align-items-center">
        <li className="d-flex align-items-center "><Link to={link1} className="theme-text-color detailsNav  text-break"> {bread1} </Link>  {slase ? <FaAngleRight  className="mx-2"/> : null}</li>
        <li className="d-flex align-items-center "><Link to={link2} className={`theme-text-color  detailsNav text-break ${color ? "opacityColor" : ""}`}>{bread2}</Link> {slase ? <FaAngleRight  className="mx-2"/> : null}</li>
        <li className="d-flex align-items-center"><a className="theme-text-color  detailsNav text-break">{bread3 ? bread3 : null}</a></li>
      </ul>
    </nav>
  );
};

export default Breadcrumb;
