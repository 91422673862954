import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { FiCheckCircle } from "react-icons/fi";
import { connect } from 'react-redux';
import './Iyzico_Sucesspage.css';
import axios from 'axios';

function Iyzico_SuccessPage(videoDetailsData) {
    const [counter, setCounter] = useState(5); // Initialize the countdown timer
    const navigate = useNavigate(); // Hook for navigation
    const { lang } = useParams();

    // useEffect(() => {
    //     const timer = setInterval(() => {
    //         // setCounter((prevCounter) => prevCounter - 1);
    //     }, 1000);

    //     // Clear the interval and redirect after countdown finishes
    //     if (counter === 0) {
    //         clearInterval(timer);
    //         retrievePaymentDetails();
    //     }

    //     return () => clearInterval(timer); // Cleanup on component unmount
    // }, [counter]);

    // const retrievePaymentDetails = async () => {
    //     try {
    //         const response = await axios.get(`http://localhost:7004/retrieve-payment-details`);
    //         console.log(response)
    //         if (videoDetailsData.slug === response.data.successpage) {
    //             navigate(`/${lang}/${response.data.successpage}`);
    //         } else {
    //             // Handle other cases or navigation if needed
    //             navigate(`/`); // Example fallback navigation
    //         }
    //     } catch (error) {
    //         console.error("Failed to retrieve payment details:", error);
    //         // Handle error, maybe navigate to an error page
    //     }
    // };

    useEffect(() => {
        const timer = setInterval(() => {
            setCounter((prevCounter) => prevCounter - 1);
        }, 1000);

        if (counter === 0) {
            clearInterval(timer);
            navigate("/"); // Redirect to the home page after the countdown
        }

        return () => clearInterval(timer); // Clear the timer if the component unmounts
    }, [counter, navigate]);

    return (
        <div className="themeTwoBannerWrappers iyzicopaddingtoptop container">
            <h3 className="text-center theme-text-color priceTableBigHead ">
                <FiCheckCircle className="FiCheckCirclecolor" />
            </h3>
            <h2 className="pb-3 my-3 text-center theme-text-color SuccessfullyPayment">
                Your Transaction was Successful. </h2>
            <div className="col-lg-12 row justify-content-center theme-text-color ">
                <div className="col-lg-3">
                    <div className="text-center">
                        <h1 className=" theme-button-bg-color counter_timing">{counter}</h1>
                    </div>
                </div>
            </div>
            <p className="text-center theme-text-color my-3">
                Don't click back while your payment is processing. If not redirected, <Link to="/" className="theme-text-color-button">Click Here.</Link>
            </p>
        </div>
    );
}

// export default Iyzico_SuccessPage;

const mapStateToProps = state => ({
    videoDetailsData: state.get_videoDetails_Reducer.videoDetailsData,
});
export default connect(mapStateToProps)(Iyzico_SuccessPage)