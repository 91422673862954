import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { getItem, getItemToken } from '../../../../Utils/localStorageUtils'
import axios from 'axios'
import { displayTranslateLanguageAction, headerIndexAction, userChangeLanguage } from '../../../../Redux/Actions/apiActions'
import MessageBox from '../../../../components/Others/MessageBox'
import { useNavigate } from 'react-router-dom'
const MusicSettingPage = ({ languages, languageDetailsData, themeMode }) => {

    const dispatch = useDispatch()
    const userRoleLocal = getItem('role')
    const navigate = useNavigate()


    const changeMode = async (theme) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_Baseurl}/Front-End/theme-mode`, {
                Login_user_type: parseInt(getItem('role'), 10) || 9,
                Login_user_id: parseInt(getItem('user_id'), 10) || null,
                Login_device_type: "web",
                theme_mode: theme
            }, { headers: getItemToken('access_token') || null });
            dispatch(headerIndexAction())
            return response.data;
        } catch (error) {
            console.error("Error in themeToggleMethod:", error);
            throw error;
        }
    }


    useEffect(() => {
        dispatch(displayTranslateLanguageAction())
    }, [])

    
    return (
        <div className='py-5 px-2 col-lg-6 col-md-5 mx-auto'>
            <div className='mx-auto'>
                <div className='d-flex align-items-center justify-content-between w-100'>
                    <div className="col-8">
                        <MessageBox text="Choose Language" />
                    </div>
                    <div className="col-4">
                        <select value={languageDetailsData?.Language_Code} className="form-select  theme-bg-color-secondary border-0 theme-text-color rounded-5 languageOption" aria-label=".form-select example" onChange={(e) => dispatch(userChangeLanguage(e.target.value,navigate))}>
                            {languages?.map((item, index) => {
                                return <option value={item.code} key={item.id} className='theme-text-color textLeft' >{item.name}</option>
                            })}
                        </select>
                    </div>
                </div>
                {userRoleLocal ? <div className='d-flex align-items-center justify-content-between w-100 mt-4'>
                    <div className="col-8">
                        <MessageBox text="Theme Mode" />
                    </div>
                    <div className="col-4">
                        <select value={themeMode?.currentMode == "dark" ? 0 : 1} className="form-select  theme-bg-color-secondary border-0 theme-text-color rounded-5  languageOption" onChange={(e) => changeMode(e.target.value)}>
                            <option value={0} key={'dark'} className='theme-text-color textLeft' >Dark</option>
                            <option value={1} key={'light'} className='theme-text-color textLeft' >Light</option>
                        </select>
                    </div>
                </div>
                    : null}
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    languages: state.get_displayTranslateLanguage_Reducer.languages,
    languageDetailsData: state.get_getUserTranslateLanguage_Reducer.languageDetailsData,
    themeMode: state.get_headerIndex_Reducer.themeMode
})

export default connect(mapStateToProps)(MusicSettingPage)

