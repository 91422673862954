export const setItem = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
};

export const getItem = (key) => {
    const item = localStorage.getItem(key);
    return item ? item : 0;
};

export const removeItem = (key) => {
    localStorage.removeItem(key);
};


export const getItemToken = (key) => {
    const item = localStorage.getItem(key);
    let headerDefault = {
        "Content-Type": "application/json",
        headers: { "Access-Control-Allow-Origin": "*" },
        Authorization: "Bearer " + item,
    };
    return item ? headerDefault : null;
}