import React from "react";
import "./error404.css";
import errorGif from "./error404.gif"
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
function Error404({ languageDetailsData }) {
  const { t } = useTranslation();
  return (
    <section className="w-100 h-100 d-flex align-items-center flex-column justify-content-center p-1 adminHeadContainer">
      <div className="notFoundContainer">
        <h2 className="notFoundHeading text-center theme-text-color pb-3">{t("404")}</h2>
        {/* <img src={errorGif} className="errorImage rounded-2" /> */}
        <h2 className="text-center theme-text-color py-2 m-0">{t("Look like you're lost")}</h2>
        <p className="text-center theme-text-color">{t("The page you are looking for not available!")} </p>
        <div className="d-flex align-items-center justify-content-center gap-3 py-5 ">
          <Link to={`/${languageDetailsData?.Language_Code}`} className="btn btn-primary text-center d-block ">{t("Go Home")}</Link>
          <Link to={`/${languageDetailsData?.Language_Code}/help`} className="btn btn-primary text-center d-block">{t("Contact us")}</Link>
        </div>
      </div>
    </section >
  );
}

const mapStateToProps = (state) => ({
  languageDetailsData: state.get_getUserTranslateLanguage_Reducer.languageDetailsData,
});
export default connect(mapStateToProps)(Error404);

