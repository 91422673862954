const videoURL = [
    {
        url: '/channel/video-lists',
        message: 'All Video'
    }, {
        url: '/channel/add-video',
        message: 'Add Video'
    },
    {
        url: '/channel/video-categories-list',
        message: 'Manage Video Categories'
    }
]

const seriesURL = [
    {
        url: '/channel/series-lists',
        message: 'All Series'
    }, {
        url: '/channel/add-series',
        message: 'Add Series'
    },
    {
        url: '/channel/series-categories-list',
        message: 'Manage Series Categories'
    }
]
const audioURL = [
    {
        url: '/channel/all-audio-lists',
        message: 'Audio List'
    }, {
        url: '/channel/add-new-audios',
        message: 'Add New Audio'
    },
    {
        url: '/channel/manage-audio-category',
        message: 'Manage Audio Categories'
    },
    {
        url: '/channel/audio-manage-albums',
        message: 'Manage Albums'
    }
]
const artistURL = [
    {
        url: '/channel/all-artist',
        message: 'Artist List'
    }, {
        url: '/channel/add-artist',
        message: 'Add New Artist'
    }
]

const liveStreamURL = [
    {
        url: '/channel/all-livevideos',
        message: 'All Live Stream'
    }, {
        url: '/channel/add-livestream',
        message: 'Add New Live Stream'
    },
    {
        url: '/channel/manage-live-category',
        message: 'Manage Live Video Categories'
    },
    // {
    //     url: '/channel/manage-live-event',
    //     message: 'Live Event Create'
    // }
]
const channelSettingsURL = [
    {
        url: '/channel/mychannel-setting',
        message: 'My Channel Setting'
    }
    //  {
    //     url: '/channel/about-my-channel',
    //     message: 'About My Channel'
    // }
]
const analyticsURL = [
    {
        url: '/channel/video-analytics',
        message: 'Video Analytics'
    }, {
        url: '/channel/video-region',
        message: 'Video View By Region'
    },
    {
        url: '/channel/payouts',
        message: 'Payouts'
    }
]



export { videoURL, seriesURL, audioURL, artistURL, liveStreamURL, channelSettingsURL, analyticsURL }