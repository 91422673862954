import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import Typography from "@mui/material/Typography";
import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import axios, { CancelToken, isCancel } from "axios";
import { ProgressBar } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";

import Select from "react-select";

const Edit_SingleAudio = () => {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const { id } = useParams();

  const [file, setFile] = useState("");
  const [audio_id, setAudio_id] = useState("");
  const [type, setType] = useState("");

  const [mp3_url, setMp3_url] = useState("");
  const [mp3_live_url, setMp3_live_url] = useState("");

  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token_channel");
  const [Channel_Partner_id, setChannel_Partner_id] = useState("1");

  const [editUser, setEditUser] = useState({ title: "", duration: "" });

  const [uploadProgress, setUploadProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setSelectedFile(selectedFile);
    uploadFile(selectedFile);
  };

  const uploadFile = (selectedFile) => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("audio_id", id);
      formData.append("Channel_Partner_id", Channel_Partner_id);

      const xhr = new XMLHttpRequest();
      xhr.open(
        "POST",
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/Audios/upload-file-update`,
        { headers: headers }
      );
      xhr.setRequestHeader("Authorization", "Bearer " + access_token);

      xhr.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          const progress = Math.round((event.loaded / event.total) * 100);
          setUploadProgress(progress);
        }
      });

      xhr.onreadystatechange = (res) => {
        if (xhr.readyState == XMLHttpRequest.DONE) {
          if (xhr.status == 200) {
            alert("Added successfully!");
            const responseData = JSON.parse(xhr.responseText);
            var audio_id = responseData.Audio_id;
            setAudio_id(audio_id);
          } else {
            console.error("Request failed with status:", xhr.status);
          }
        }
      };

      xhr.send(formData);
    }
  };

  const getFileSizeInMB = () => {
    if (selectedFile) {
      const fileSizeInBytes = selectedFile.size;
      const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
      return fileSizeInMB.toFixed(2);
    }
    return null;
  };

  const [uploadPercentage, setUploadPercentage] = useState(0);
  const cancelFileUpload = useRef(null);

  const cancelUpload = () => {
    if (cancelFileUpload.current)
      cancelFileUpload.current("User has canceled the file upload.");
  };

  const [uploadPercentagefile, setUploadPercentagefile] = useState(0);
  const cancelFileUploadfile = useRef(null);

  const cancelUploadfile = () => {
    if (cancelFileUploadfile.current)
      cancelFileUploadfile.current("User has canceled the file upload.");
  };

  async function audiofilesubmit() {
    const formData = new FormData();

    formData.append("mp3_url", mp3_url);
    formData.append("audio_id", id);
    formData.append("mp3_live_url", mp3_live_url);
    formData.append("type", type);
    formData.append("Channel_Partner_id", Channel_Partner_id);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    await axios
      .post(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/Audios/upload-url-update`,
        formData,
        {
          headers: headers,
        }
      )
      .then((response) => {
        if (response.data.status == true) {
          var result = response.data.Audio;
          var results = response.data.Audio_id;
          setEditUser(result);
          setAudio_id(results);
          alert("Added successfully  !");
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });
  }

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const [enable_restream, setEnable_restream] = useState("");
  const [enablestreamvisible, setEnablestreamvisible] = useState("");

  const [enable_restreamaddvideo, setEnable_restreamaddvideo] = useState("");
  const [enablestreamvisibleaddvideo, setEnablestreamvisibleaddvideo] =
    useState("");

  function proceednextaddvideo() {
    switch (enable_restreamaddvideo) {
      case "addvideo":
        return (
          <div>
            <div className="mb-12">
              {uploadPercentage > 0 && (
                <div className="row mt-3">
                  <div className="col pt-1">
                    <ProgressBar
                      now={uploadPercentage}
                      striped={true}
                      label={`${uploadPercentage}%`}
                    />
                  </div>
                  <div className="col-auto">
                    <span
                      className="text-primary cursor-pointer"
                      onClick={() => cancelUpload()}
                    >
                      Cancel
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  function proceednext() {
    switch (enable_restream) {
      case "0":
        return <div></div>;
      case "file":
        return (
          <div>
            <label>Upload Audio </label>

            <div className=" text-center file-drag mb-5">
              <label id="label-file-upload" htmlFor="input-file-upload">
                <div
                  name="addvideo"
                  id="addvideo"
                  onChange={(event) => {
                    setEnable_restreamaddvideo(event.target.name);
                  }}
                >
                  <input
                    type="file"
                    accept="audio/mp3"
                    id="input-file-upload"
                    name="addvideo"
                    multiple
                    className="form-control"
                    onChange={handleFileChange}
                    onClick={(event) => {
                      setEnable_restreamaddvideo(event.target.name);
                    }}
                  />

                  {selectedFile && <div>File Size: {getFileSizeInMB()} MB</div>}
                  {selectedFile && (
                    <div>Upload Progress: {uploadProgress}%</div>
                  )}
                </div>
              </label>

              <div
                onClick={(event) => {
                  setEnablestreamvisibleaddvideo(event.target.value);
                }}
              >
                {proceednextaddvideo()}
              </div>
            </div>
          </div>
        );
      case "mp3":
        return (
          <div>
            <div className="row mt-3 text-start">
              <div className="col-sm-12 ">
                <div className="">
                  <label className="mb-1"> Mp3 File URL: </label>
                  <input
                    type="text"
                    className="form-control"
                    name="mp3_url"
                    onChange={(e) => setMp3_url(e.target.value)}
                    placeholder="Mp3 File URL"
                  />
                </div>
                <div className="mt-4 text-center">
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      audiofilesubmit();
                    }}
                  >
                    Submit{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      case "live_mp3":
        return (
          <div>
            <div className="row mt-3 text-start">
              <div className="col-sm-12 ">
                <div className="">
                  <label className="mb-1"> Live Mp3 File URL: </label>
                  <input
                    type="text"
                    className="form-control"
                    name="live_mp3_url"
                    placeholder="Live Mp3 File URL"
                    onChange={(e) => setMp3_live_url(e.target.value)}
                  />
                </div>
                <div className="mt-4 text-center">
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      audiofilesubmit();
                    }}
                  >
                    Submit{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        );

      default:
        return null;
    }
  }

  const steps = [
    {
      label: "Select campaign settings",
      description: (
        <div>
          <div
            onClick={(event) => {
              setEnablestreamvisible(event.target.value);
            }}
          >
            {proceednext()}
          </div>
          <div>
            <div className="row d-flex">
              <div className="col-md-10 offset-md-2 mt-3">
                <input
                  className="form-check-input ms-5"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                  value="file"
                  onClick={(event) => {
                    setEnable_restream(event.target.value);
                  }}
                  onChange={(e) => setType(e.target.value)}
                />
                <label
                  className="form-check-label ms-1"
                  for="flexRadioDefault1"
                >
                  Audio Upload
                </label>

                <input
                  className="form-check-input ms-5"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  value="mp3"
                  onChange={(e) => setType(e.target.value)}
                  onClick={(event) => {
                    setEnable_restream(event.target.value);
                  }}
                />
                <label
                  className="form-check-label ms-1"
                  for="flexRadioDefault2"
                >
                  Audio File
                </label>

                <input
                  className="form-check-input ms-5"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault3"
                  value="live_mp3"
                  onChange={(e) => setType(e.target.value)}
                  onClick={(event) => {
                    setEnable_restream(event.target.value);
                  }}
                />
                <label
                  className="form-check-label ms-1"
                  for="flexRadioDefault3"
                >
                  Live Audio File
                </label>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      label: "Create an ad group",
      description: <div></div>,
    },
  ];

  return (
    <div className=" m-0 p-0">
      <div className="">
        <section className="section container-fluid ">
          <div className="pt-3">
            <div className="row">
              <div className="col-sm-12">
                <div className="iq-card addaudio">
                  <div className="iq-card-header d-flex justify-content-between">
                    <div className="iq-header-title">
                      <h4 className="card-title">Edit Audio</h4>
                    </div>
                  </div>
                  <hr />

                  <Stepper activeStep={activeStep} orientation="horizontal">
                    {steps?.map((step, index) => (
                      <Step key={step.label}>
                        <StepContent>
                          <Typography>{step.description}</Typography>
                          <div>
                            <div className="text-end mt-3"></div>
                          </div>
                        </StepContent>
                      </Step>
                    ))}
                  </Stepper>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Edit_SingleAudio;
