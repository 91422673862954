import React from 'react'
import { Link } from 'react-router-dom'
import { URLGenerater } from '../../CommonMethods/CommonMethods'
import MessageBox from '../../../components/Others/MessageBox'
import { useDispatch } from 'react-redux'

const HomePageNav = ({ link, headerName, store, seg, className }) => {
    const dispatch = useDispatch()
    const handleClear = () => {
        dispatch({ type: "CLEAR_MUSIC_VIEWALLPAGE", payload: [] })
    }
    return (
        <div>
            {store?.length > 0 && (<div className='pb-3 d-flex flex-wrap justify-content-between'>
                <Link to={`/${seg}${URLGenerater(link)}`} className={`text-decoration-none  navHeadingMusic theme-text-color ${className}`}>{<MessageBox text={headerName} />}</Link>
                <Link to={`/${seg}${URLGenerater(link)}`} className="text-decoration-none  theme-text-color  viewAllMusic" onClick={handleClear}><MessageBox text="View all" /></Link>
            </div>)}
        </div>
    )
}

export default HomePageNav