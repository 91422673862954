// External Libraries and Packages
import React from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
// Internal Styles
// Internal Components
import Loader from "../../Images/loaderget.gif"
import Error404 from "../../../Error404/Error404";
import SeriesViewAll from "./SeriesViewAll";
import LiveViewAll from "./LiveViewAll";
import SongViewAll from "./SongViewAll";
import ChannelViewAll from "./ChannelViewAll";
import ArtistViewAll from "./ArtistViewAll";
import ContentViewAll from "./ContentViewAll";
import CategoryViewAll from "./CategoryViewAll";
import LanguageViewAll from "./LanguageViewAll";
import HomeVideoViewAll from "./HomeVideoViewAll";
import ContinueWatchingViewAll from "./ContinueWatchingViewAll";


const ViewAllPage = ({ getSource, isLoading }) => {
  const { source } = useParams();
  // console.log(getSource.includes(source))
  return (
    <div className="paddingTop">
      {isLoading ? <div className='d-flex justify-content-center align-items-center paddingTop'><img src={Loader} width={50} height={50} /></div>
        : source == "latest-videos" || source == "featured-videos" ||
          source == "scheduled-publish-video" || source == "video-schedule" || source == "latest-viewed-videos" ||
          source == "recommended-videos-site" || source == "recommended-videos-country"
          || source == "recommended-videos-users" && getSource.includes(source)
          ? <HomeVideoViewAll />
          : source == "continue-watching" && getSource.includes(source) ? <ContinueWatchingViewAll />
            : source == "latest-series" || source == "single-series" || source == "latest-viewed-episode" && getSource.includes(source)
              ? <SeriesViewAll />
              : source == "livestream-videos" || source == "scheduled-publish-livestream" && getSource.includes(source)
                ? <LiveViewAll />
                : source == "audio-albums" || source == "audios" || source == "latest-viewed-audios" && getSource.includes(source) //
                  ? <SongViewAll />
                  : source == "artists" && getSource.includes(source)
                    ? <ArtistViewAll />
                    : source == "channel-partner" || source == "individual-channelpartner" && getSource.includes(source)
                      ? <ChannelViewAll />
                      : source == "content-partner" || source == "individual-contentpartner" && getSource.includes(source)
                        ? <ContentViewAll />
                        : source == "video-categories" || source == "series-categories" || source == "live-categories" || source == "audios-categories" && getSource.includes(source == "audios-categories" ? 'audio-genre' : source)
                          ? <CategoryViewAll />
                          : source == "alllanguage" ? <LanguageViewAll />
                            : <Error404 />}
    </div>
  );
};
const mapStateToProps = state => ({
  homepage: state.get_allHomePage_Reducer.homepage,
  getSource: state.get_allHomePage_Reducer.getSource,
  isLoading: state.get_allHomePage_Reducer.isLoading,
});

export default connect(mapStateToProps)(ViewAllPage);