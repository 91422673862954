// External Libraries and Packages
import React, { useEffect, useRef, useState } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
// Internal Styles
import "swiper/css";
import "swiper/css/navigation";
import EmptyLoaderCard from './EmptyLoaderCard'
import EmptyLoaderNav from './EmptyLoaderNav';
import { connect } from 'react-redux';

const EmptyLoaderSwiper = ({ playlistShow }) => {
    const containerRef = useRef(null);
    const [containerWidth, setContainerWidth] = useState(1024);

    const calculateSlidesPerView = () => {
        if (containerWidth < 450) { return 2; }
        else if (containerWidth < 650) { return 3; }
        else if (containerWidth < 992) { return 4; }
        else if (containerWidth < 1440) { return 6; }
        else if (containerWidth < 2000) { return 8; }
        else { return 11; }
    };

    const handleResize = () => {
        if (containerRef?.current?.clientWidth) {
            setContainerWidth(containerRef?.current?.clientWidth);
        }
    };

    const swiperConfig = {
        slidesPerView: calculateSlidesPerView(),
        spaceBetween: 15,
        navigation: true,
        lazy: true,
        slidesPerGroup: calculateSlidesPerView(),
        modules: [Navigation],
        className: 'musicHomePageSwiper'
    }

    useEffect(() => {
        handleResize();
        calculateSlidesPerView()
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [playlistShow, containerRef?.current?.clientWidth]);


    useEffect(() => {
        calculateSlidesPerView();
    });

    return (
        <div className='mb-4 w-100 col-12' ref={containerRef}>
            <EmptyLoaderNav />
            <Swiper {...swiperConfig}>
                {Array.from({ length: 15 }).map((value, index) => (
                    <SwiperSlide key={value?.id} >
                        <EmptyLoaderCard />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}

const mapStateToProps = state => ({
    playlistShow: state.get_Playlist_Modal.playlistShow,
});

export default connect(mapStateToProps)(EmptyLoaderSwiper)