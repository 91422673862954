import React, { useState, useEffect, useRef } from "react";
import { Button } from "antd";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "./AccountPage.css";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { MdCancel } from "react-icons/md";

const AccountPage = ({ profileDetails, LogOutApi, }) => {
  const dispatch = useDispatch();
  const expires_in = localStorage.getItem("expires_in");
  const { lang } = useParams();
  const { t } = useTranslation();
  const [profilePage, setProfilePage] = useState();
  const [accountdata, setAccountData] = useState([]);
  const [counter1, setCounter1] = useState(0);
  const [clicked1, setClicked1] = useState(false);
  const [counter2, setCounter2] = useState(0);
  const [clicked2, setClicked2] = useState(false);
  const [counter3, setCounter3] = useState(0);
  const [clicked3, setClicked3] = useState(false);
  const [counter4, setCounter4] = useState(0);
  const [clicked4, setClicked4] = useState(false);
  const [counter5, setCounter5] = useState(0);
  const [clicked5, setClicked5] = useState(false);
  const [counter6, setCounter6] = useState(0);
  const [clicked6, setClicked6] = useState(false);
  const [counter7, setCounter7] = useState(0);
  const [clicked7, setClicked7] = useState(false);
  const [counter8, setCounter8] = useState(0);
  const [clicked8, setClicked8] = useState(false);
  const [category, setCategory] = useState([]);
  const [selectedOptionscategory, setSelectedOptionscategory] = useState([]);
  const [language, setLanguage] = useState([]);
  const [selectedOptionslanguage, setSelectedOptionslanguage] = useState([]);
  const [MultiUserLimit, setMultiUserLimit] = useState([]);
  const [SubcriberUser, setSubcriberUser] = useState([]);
  const [MultiUsers, setMultiUsers] = useState([]);
  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");
  const user_role = localStorage.getItem("role");
  const user_id = localStorage.getItem("user_id");
  const [newImage, setNewImage] = useState(null);
  const fileInputRef1 = useRef(null);

  const [selectedFile1, setSelectedFile1] = useState(null);

  const headers = {
    "Content-Type": "application/json", Authorization: "Bearer " + access_token,
    Accept: "application/json",
  };

  useEffect(async () => {
    setProfilePage(0);
  }, []);

  const handleClick1 = () => {
    setCounter1(counter1 + 1); // Continue incrementing the counter if necessary
    setClicked1(true); // Set clicked1 to true to show the input box
  };



  const handleClick2 = () => {
    setCounter2(counter2 + 1);
    setClicked2(true);
  };

  const handleClick3 = () => {
    setCounter3(counter3 + 1);
    setClicked3(true);
  };
  const handleClick4 = () => {
    setCounter4(counter4 + 1);
    setClicked4(true);
  };
  const handleClick5 = () => {
    setCounter5(counter5 + 1);
    setClicked5(true);
  };

  const handleClick6 = () => {
    setCounter6(counter6 + 1);
    setClicked6(true);
  };
  const handleClick7 = () => {
    setCounter7(counter7 + 1);
    setClicked7(true);
  };
  const handleClick8 = () => {
    setCounter8(counter8 + 1);
    setClicked8(true);
  };

  useEffect(async () => {
    setAccountData(profileDetails?.Profile_details);
  }, [profileDetails]);

  useEffect(() => {
    fetchProfileData();
  }, []);

  const data = {
    // Login_user_type: user_role,
    // Login_user_id: user_id,
    Login_device_type: "web",
    id: accountdata?.id,
    name: accountdata?.name,
    last_name: accountdata?.last_name,
    email: accountdata?.email,
    ccode: accountdata?.ccode,
    mobile: accountdata?.mobile,
    avatar: newImage,
    DOB: accountdata?.DOB,
    active: accountdata?.active,
    role: accountdata?.role,
    package: accountdata?.package,
    package_ends: accountdata?.package_ends,
    trial_in: accountdata?.trial_in,
    trial_starts_at: accountdata?.trial_starts_at,
    trial_ends_at: accountdata?.trial_ends_at,
    plan_name: accountdata?.plan_name,
    payment_status: accountdata?.payment_status,
    payment_type: accountdata?.payment_type,
    payment_gateway: accountdata?.payment_gateway,
    subscription_start: accountdata?.subscription_start,
    subscription_ends_at: accountdata?.subscription_ends_at,
    Subscription_trail_status: accountdata?.Subscription_trail_status,
    Subscription_trail_tilldate: accountdata?.Subscription_trail_tilldate,
    stripe_subscription: accountdata?.stripe_subscription,
    stripe_id: accountdata?.stripe_id,
    stripe_plan: accountdata?.stripe_plan,
    activation_code: accountdata?.activation_code,
    card_type: accountdata?.card_type,
    last_four: accountdata?.last_four,
    stripe_active: accountdata?.stripe_active,
    card_brand: accountdata?.card_brand,
    // card_last_four: accountdata?.card_last_four,
    coupon_used: accountdata?.coupon_used,
    paypal: accountdata?.paypal,
    paypal_id: accountdata?.paypal_id,
    paypal_agreement_id: accountdata?.paypal_agreement_id,
    paypal_end_at: accountdata?.paypal_end_at,
    provider_id: accountdata?.provider_id,
    provider: accountdata?.provider,
    user_type: accountdata?.user_type,
    fav_category: accountdata?.fav_category,
    FamilyMode: accountdata?.FamilyMode,
    Kidsmode: accountdata?.Kidsmode,
    preference_genres: accountdata?.preference_genres,
    preference_language: accountdata?.preference_language,
    referral_token: accountdata?.referral_token,
    token: accountdata?.token,
    // otp: accountdata?.otp,
    // referrer_id: accountdata?.referrer_id,
    session_id: accountdata?.session_id,
    coupon_expired: accountdata?.coupon_expired,
    // terms: accountdata?.terms,
    unhashed_password: accountdata?.unhashed_password,
    mobile_verified_at: accountdata?.mobile_verified_at,
    // email_verified_at: accountdata?.email_verified_at,
    remember_token: accountdata?.remember_token,
    created_at: accountdata?.created_at,
    updated_at: accountdata?.updated_at
  }

  const handleUpdate = async (e) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/Front-End/Profile-details-update`, data,
        { headers: headers })
      if (response) {
        if (response?.data?.status === true) {
          toast.success(t("Account Updated Successfully"));
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
        else if (response?.data?.status === false) {
          toast.error(t("Request failed with status: " + response?.status));
        }
      }
      else {
        // throw new Error("Request failed with status: " + response.status);
        toast.error(t("Request failed with status: " + response?.status));
      }
    } catch (error) {
      // alert("Error updating user");
      // console.log("Error fetching user accesses:", error);
    }
  };


  // Fetch transaction data

  const [loading, setLoading] = useState(true);
  const [datatranscation, setDatatranscation] = useState(
    {
      id: "",
      user_id: "",
      user_name: "",
      PaymentGateway: "",
      PaymentGateway_id: "",
      Payment_type: "",
      subscription_id: "",
      subscription_starts_at: "",
      subscriptions_ends_at: "",
      subscriptions_days: "",
      subscription_cancel_at: "",
      subscription_coupon_code: "",
      subscription_promo_code: "",
      subscription_currency: "",
      subscription_price: "",
      subscription_price_paid: "",
      trails_start_at: "",
      trails_ends_at: "",
      plans_id: "",
      payment_link: "",
      customer_id: "",
      quantity: "",
      current_status: "",
      ios_product_id: "",
      payment_in: "",
      card_type: "",
      card_brand: "",
      card_last_four: "",
      countryname: "",
      regionname: "",
      cityname: "",
      created_at: "",
      updated_at: "",
      currency: {
        id: "",
        country: "",
        currencies: "",
        code: "",
        symbol: "",
      }
    }
  );

  const formattedDate = new Date(datatranscation?.subscriptions_ends_at).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  });

  const calculateSubscriptionDuration = (days) => {
    if (days >= 365) {
      const years = Math.floor(days / 365);
      return years > 1 ? `${years} Years` : `${years} Year`;
    } else if (days >= 30) {
      const months = Math.floor(days / 30);
      return months > 1 ? `${months} Months` : `${months} Month`;
    } else {
      return `${days} Days : ${days} Day`;
    }
  };

  const subscriptionDuration = calculateSubscriptionDuration(datatranscation?.subscriptions_days);

  useEffect(() => {

    const fetchDatanextpretranscation = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${process.env.REACT_APP_Baseurl}/Front-End/user-details`, {
          method: "POST",
          headers: headers,
        });
        const result = await response.json();
        setDatatranscation(result.transaction_details?.current_Subscription);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching transaction data", error);
        setLoading(false);
      }
    };

    fetchDatanextpretranscation();
  });



  // const handleFileChange = (event) => {
  //   setSelectedFile1(event.target.files[0]);
  // };
  const handleFileChange = (event, fileInputRef, setSelectedFile) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    if (selectedFile1) {
      // formData.append("Login_user_type", user_role);
      // formData.append("Login_user_id", user_id);
      formData.append("Login_device_type", 'web');
      formData.append("email", accountdata?.email);
      formData.append("avatar ", selectedFile1);
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/Front-End/Profile-details-update`, formData,
        { headers: headers })
      if (response) {
        if (response?.data?.status == true) {
          toast.success(t("Profile Image Updated Successfully"));
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      }
      //  console.log("Success:", response.data);

    } catch (error) {
      console.error("Error:", error);
    }
  };




  function AccountUpdate(evt) {
    const value = evt.target.value;
    setAccountData({
      ...accountdata,
      [evt.target.name]: value,
    });
  }

  async function Subscribe() {
    await navigate("/plan");
  }

  const fetchOptions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/videos-create`,
        { headers: headers }
      );
      setCategory(response?.data?.video_category);
      setLanguage(response?.data?.video_language);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(async () => {
    fetchOptions();

  }, []);
  const handleSelectChangecategory = (selectedValues) => {
    setSelectedOptionscategory(selectedValues);
  };

  const handleSelectChangelanguage = (selectedValues) => {
    setSelectedOptionslanguage(selectedValues);
  };
  // Multiselect

  async function videoupload() {
    const formDatastore = new FormData();

    formDatastore.append(
      "category_id",
      JSON.stringify(selectedOptionscategory?.map((option) => option?.value))
    );
    formDatastore.append(
      "languages_id",
      JSON.stringify(selectedOptionslanguage?.map((option) => option?.value))
    );
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    let firstApiStatus = false;
    let resultapi = null;
    let resultapifalse = null;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/video-storedata`,
        formDatastore,
        { headers: headers }
      ); if (response?.data?.status === true) {
        firstApiStatus = true;
        resultapi = response?.data;
        toast.success(t(response?.data?.message), {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (response?.data?.status === false) {
        resultapifalse = response?.data;
        toast.failer(t(response?.data?.message), {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) { }
  }


  const fetchProfileData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_Baseurl}/Multi-Profile/index/?user_id=${user_id}`,
        { headers: headers }
      );
      const data = await response.json();
      var Multi_User_Limit = data.Multi_User_Limit;
      var subcriber_user = data.subcriber_user;
      var Multi_users = data.Multi_users;
      setMultiUserLimit(Multi_User_Limit);
      setSubcriberUser(subcriber_user);
      setMultiUsers(Multi_users);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const toggleProfile = (profileId) => {
    if (selectedProfiles.includes(profileId)) {
      setSelectedProfiles(selectedProfiles.filter((id) => id !== profileId));
    } else {
      setSelectedProfiles([...selectedProfiles, profileId]);
    }
  };

  const chooseProfile = (data) => {
    localStorage.setItem("MultiProfile", true);
    localStorage.setItem("MultiProfileId", data.login_user_id);
    navigate("/");
  };

  const handleCreate = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("user_id", user_id);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .post(`${process.env.REACT_APP_Baseurl}/Multi-Profile/store`, formData, {
        headers: headers,
      })
      .then((response) => {
        //console.log(response.data)
        if (response?.data?.status == "true") {
          alert("Profile Added successfully");

          toast.success(t(response?.data?.message), {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }

        fetchProfileData();
      })
      .catch((error) => {
        alert("Error updating user");
      });
  };
  const videorating = [
    {
      id: 15,
      user_id: 4,
      rating: "9",
      video: "video 1",
      category: "Drama",
    },
    {
      id: 16,
      user_id: 2,
      rating: "6",
      video: "video 2",
      category: "Triller",
    },
  ];
  const tvlogged = [
    {
      id: 15,
      user_id: 4,
      email: "admin@admin.com",
      tv_code: "8979879878",
    },

  ];
  const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled ? 'red' : 'white',
        color: 'black',
        cursor: isDisabled ? 'not-allowed' : 'default',
      };
    }
  }



  const logout = async () => {
    try {
      const access_token = localStorage.getItem("access_token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
        Accept: "application/json",
      };

      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/users/logout`,
        { headers: headers }
      );

      if (response.data.status === true) {
        toast.success(response.data.message);
        localStorage.clear();
        // navigate("/login");
        window.location.href = '/';
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error('Logout error:', error);
      // toast.error('Logout failed due to an error.');
    } finally {
      // setProcessing(false);
      // setShowOverlay(false);
    }
  };

  const handleLogout = async () => {
    await logout();
  };

  useEffect(() => {
    const loginTime = localStorage.getItem('loginTime');
    let remainingTime = expires_in;

    if (loginTime) {
      const elapsedTime = Date.now() - new Date(loginTime).getTime();
      remainingTime = expires_in - elapsedTime;
    } else {
      localStorage.setItem('loginTime', new Date().toISOString());
    }

    if (remainingTime <= 0) {
      logout();
    } else {
      const expirationTimeout = setTimeout(() => {
        logout();
      }, remainingTime);

      return () => clearTimeout(expirationTimeout);
    }
  }, []);

  // function handleLogout() {
  //   localStorage.removeItem('expires_in');
  //   localStorage.clear(); // Clear expiry time on logout
  //   window.location.href = '/';
  // }

  // const handleLogout = () => {
  //   dispatch(LogOutApi(navigate))
  // };

  // useEffect(() => {
  //   const loginTime = localStorage.getItem('loginTime');
  //   let remainingTime = expires_in * 1000; // Convert expires_in to milliseconds if in seconds

  //   if (loginTime) {
  //     const elapsedTime = Date.now() - new Date(loginTime).getTime();
  //     remainingTime = remainingTime - elapsedTime; // Calculate remaining time based on elapsed time

  //     // If the token has already expired
  //     if (remainingTime <= 0) {
  //       dispatch(LogOutApi(navigate));
  //       // navigate('/'); // Log out immediately if token expired
  //     } else {
  //       // Set a timeout to log the user out when the token expires
  //       const expirationTimeout = setTimeout(() => {
  //         dispatch(LogOutApi(navigate));
  //         // navigate('/'); // Log out when remaining time ends
  //       }, remainingTime);

  //       // Clear the timeout if the component unmounts
  //       return () => clearTimeout(expirationTimeout);
  //     }
  //   } else {
  //     // Store the current time as the login time if not already stored
  //     localStorage.setItem('loginTime', new Date().toISOString());
  //   }
  // }, [expires_in, dispatch, navigate]);


  const handleCancelname = () => {
    setClicked1(false); // Hide the input and buttons on cancel
    setCounter1(counter1 - 1);
  };
  const handleCancellast_name = () => {
    setClicked8(false); // Hide the input and buttons on cancel
    setCounter8(counter8 - 1);
  };
  const handleCancelmobile = () => {
    setClicked2(false); // Hide the input and buttons on cancel
    setCounter2(counter2 - 1);
  };
  const handleCancelemail = () => {
    setClicked3(false); // Hide the input and buttons on cancel
    setCounter3(counter3 - 1);
  };
  const handleCancelPassword = () => {
    setClicked4(false); // Hide the input and buttons on cancel
    setCounter4(counter4 - 1);
  };
  const handleCancelDOB = () => {
    setClicked5(false); // Hide the input and buttons on cancel
    setCounter5(counter5 - 1);
  };
  const handleCanceluser_avatar = () => {
    setClicked6(false); // Hide the input and buttons on cancel
    setCounter6(counter6 - 1);
  };
  const handleCancelactivation_code = () => {
    setClicked7(false); // Hide the input and buttons on cancel
    setCounter7(counter7 - 1);
  };

  return (
    <>
      <section className="">
        <div className="container">
          <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title rent_policsh_color" id="staticBackdropLabel">Update Profile </h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div>
                <div className="modal-body">
                  <div className="form-group p-2">
                    <label for="name" className="accountupdate"> User Name :</label>
                    <input type="text" className="form-control" id="name" name="name"
                      placeholder="Name" value={accountdata?.name} onChange={AccountUpdate} />
                  </div>
                  <div className="form-group p-1">
                    <label for="name" className="accountupdate">Email:</label>
                    <input type="text" className="form-control" style={{ top: "30%" }}
                      id="email" name="email" placeholder="Email" value={accountdata?.email}
                      onChange={AccountUpdate} />
                  </div>
                  <div className="form-group p-1">
                    <label for="name" className="accountupdate">Password :</label>
                    <input type="text" className="form-control" style={{ top: "30%" }}
                      id="unhashed_password" name="unhashed_password" placeholder="Password"
                      value={accountdata?.unhashed_password} onChange={AccountUpdate} />
                  </div>
                  <div className="form-group p-1">
                    <label for="name" className="accountupdate">Mobile No :</label>
                    <input type="text" className="form-control" id="mobile" name="mobile" placeholder="Mobile"
                      value={accountdata?.mobile} onChange={AccountUpdate} />
                  </div>
                  <div className="form-group p-1">
                    <label for="name" className="accountupdate">Date Of Birth:</label>
                    <input type="date" className="form-control" id="DOB" name="DOB"
                      placeholder="DOB" value={accountdata?.DOB} onChange={AccountUpdate} />
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" data-bs-dismiss="modal" style={{ "borderRadius": "5px" }}><label className="accountDetailsText">Close</label></button>
                  <button type="button" style={{ "borderRadius": "5px" }}><label className="accountDetailsText" onClick={handleUpdate}>Save Changes</label></button>
                </div>
              </div>
            </div>
          </div>
          {/* {profilePage == 1
            ? <div className="account_main_page p-3 " style={{ borderRadius: "8px" }}>
              <div><div className="row col-md-12 p-2">
                <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                    <h2 className="accountNameText mt-2 theme-text-color" style={{ "paddingLeft": "10px" }} >Account Settings</h2>
                  </div></div>
              </div>
              <div className="row col-lg-12 ">
                <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                  <div className="nav flex-column nav-pills me-3"
                    id="v-pills-tab"role="tablist"aria-orientation="vertical">
                    <button className="nav-link active theme-text-color"
                      id="v-pills-home-tab"data-bs-toggle="pill"
                      data-bs-target="#manage-profile"type="button"role="tab"
                      aria-controls="v-pills-home"aria-selected="true">
                      Manage Profile
                    </button>
                    <button id="v-pills-profile-tab"data-bs-toggle="pill"
                      className="nav-link theme-text-color"
                      aria-selected="false"
                      data-bs-target="#Plan-details"
                      type="button" role="tab" aria-controls="v-pills-profile">
                      Plan details
                    </button>
                    <button id="v-pills-messages-tab" data-bs-toggle="pill"
                     className="nav-link theme-text-color"data-bs-target="#preference-for-video"
                     type="button"role="tab"aria-controls="v-pills-messages"aria-selected="false">
                      Preference for Videos
                    </button>
                    <button id="v-pills-settings-tab" data-bs-toggle="pill"data-bs-target="#Profile"
                    className="nav-link theme-text-color"type="button"role="tab"
                     aria-controls="v-pills-settings"aria-selected="false">
                     Profile
                    </button>
                    <button data-bs-toggle="pill"className="nav-link theme-text-color"
                      id="v-pills-settings-tab"data-bs-target="#recent-viewed"type="button"role="tab"
                     aria-controls="v-pills-settings"aria-selected="false">
                      Recent Viewed Items
                    </button>
                    <button className="nav-link theme-text-color"id="v-pills-settings-tab"
                    data-bs-toggle="pill"data-bs-target="#tv-code"type="button"role="tab"
                    aria-controls="v-pills-settings"aria-selected="false">
                    Tv Activation Code
                    </button>
                    <button className="nav-link theme-text-color"id="v-pills-settings-tab"
                      data-bs-toggle="pill"data-bs-target="#tv-user-list"type="button"role="tab"
                      aria-controls="v-pills-settings"aria-selected="false">
                      Tv Logged User List
                    </button>
                  </div>
                </div>
                <div className="col-8 col-sm-8 col-md-8 col-lg-8">
                  <div className="tab-content" id="v-pills-tabContent">
                    <div className="tab-pane fade show active"id="manage-profile"role="tabpanel"
                      aria-labelledby="v-pills-home-tab">
                      <div>
                        <div className="col-lg-12 row">
                          <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                            <img
                              className="w-25"
                              src={accountdata?.user_avatar}/>
                          </div>
                          <div className="col-6 col-sm-6 col-md-6 col-lg-6 text-end">
                            <h2 style={{ "paddingRight": "30px" }}>{accountdata?.name}</h2>
                          </div>
                          <div>
                            <div className="col-lg-12 row my-4">
                              <div className="col-6 col-sm-6 col-md-6 col-lg-6 accountDetailsText">
                                Personal Details
                              </div>
                              <div className="col-6 col-sm-6 col-md-6 col-lg-6 text-end accountDetailsText">
                                <button className="p-2 rounded-1" type="button" data-bs-toggle="modal"
                                  data-bs-target="#staticBackdrop">
                                  <i
                                    className="fa fa-plus-circle accountDetailsText"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  <label>Change</label>
                                </button>
                              </div>
                            </div>
                          </div>
                          <hr className="m-0"></hr>
                          <div className="col-lg-12 row my-2">
                            <div className="col-4 col-sm-4 col-md-4 col-lg-4 accountDetailsText" >
                              Account Details
                            </div>
                            <div className="col-4 col-sm-2 col-md-4 col-lg-4 ">
                              <div>
                                <label className="accountDetails p-2">Email</label>
                              </div>
                              <div>
                                <label className="accountDetails p-2">Username</label>
                              </div>
                              <div>
                                <label className="accountDetails p-2 ">Password</label>
                              </div>
                            </div>
                            <div className="col-4 col-sm-2 col-md-4 col-lg-4" style={{ "textAlign": "end" }}>
                              <div>
                                <label className="accountDetails p-2">{accountdata?.email}</label>
                              </div>
                              <div>
                                <label className="accountDetails p-2">{accountdata?.name}</label>
                              </div>
                              <div>
                                <label className="accountDetails p-2">*************</label>
                              </div>
                            </div>
                          </div>
                          <hr className="m-0"></hr>
                          <div className="col-lg-12 row my-2">
                            <div className="col-4 col-sm-4 col-md-4 col-lg-4 accountDetailsText">
                              </div>
                            <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                              <div>
                                <label className="accountDetails p-2">Mobile No</label>
                              </div>
                              <div>
                                <label className="accountDetails p-2">Date Of Birth</label>
                              </div>
                             </div>
                            <div className="col-4 col-sm-4 col-md-4 col-lg-4" style={{ "textAlign": "end" }}>
                              <div>
                                <label className="accountDetails p-2">9393562007</label>
                              </div>
                              <div>
                                <label className="accountDetails p-2">2024-12-12</label>
                              </div>
                              </div>
                          </div>
                      <hr className="m-0"></hr>
                          <div>
                            <div className="col-lg-12 row my-3 align-items-center">
                              <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                                <div className="accountDetailsText">Update Profile</div>
                              </div>
                              <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                <input type="file"className="form-control rs-input ml-3"
                                  onChange={AccountUpdate}/>
                              </div>
                              <div className="col-3 col-sm-3 col-md-3 col-lg-3 text-end">
                                <button className="p-2 rounded-1">
                                  <label className="accountDetailsText">Update Profile</label>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade"id="Plan-details"
                      role="tabpanel"aria-labelledby="v-pills-profile-tab">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center">
                        <img style={{ width: "120px", height: "120px" }}
                          src="https://api.flicknexs.com/assets/img/default_users_image.png"/>
                        <div className="p-2">
                          <label className="labelText">Admin</label>
                        </div>
                        <div className="p-3">
                          <label className="labelText">admin as on 2020-08-20 20:28:40</label>
                        </div>
                        <Link to="/transcation-list">
                          <button className="p-2 rounded-1 labelText">
                            View Transcation Details
                          </button>
                        </Link>
                      </div>
                    </div>
                    <div className="tab-pane fade"
                      id="preference-for-video"role="tabpanel"
                      aria-labelledby="v-pills-messages-tab">
                      <div className="text-start">
                        <h4>Preference for videos</h4>
                        <div className="col-8">
                          <div className="mt-4">
                            <label>Preference Language</label>
                            <Select options={language?.map((option) => ({
                              value: option?.id,label: option?.name}))}
                              isMulti value={selectedOptionslanguage}
                              onChange={handleSelectChangelanguage}
                              styles={colourStyles} />
                          </div>
                        </div>
                        <div className="col-8">
                          <div className="mt-4">
                            <label>Preference Genres</label>
                            <Select styles={colourStyles}
                              options={category?.map((option) => ({
                                value: option?.id,label: option?.name}))}
                                onChange={handleSelectChangecategory}
                              className="mt-2"value={selectedOptionscategory}
                              isMulti/>
                          </div>
                        </div>
                        <div className="mt-4">
                          <button className="p-2 rounded-1" onClick={videoupload}>
                            Update Profile
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade"id="Profile"
                      role="tabpanel"aria-labelledby="v-pills-settings-tab">
                      <div className="d-flex align-items-center px-3 w-100 mx-auto">
                        <div className="profile-chooser-container p-3">
                          <h2 className="profile-chooser-title mb-4 text-start theme-text-color">
                            Profile
                          </h2>
                         <div className="d-flex align-items-center flex-wrap justify-content-center">
                        {MultiUsers?.map(
                              (profile, index) =>
                                index < MultiUserLimit && (
                                  <div><div key={profile?.id} className="profile-item2"
                                      style={{ transform: "none" }} 
                                      onClick={() => chooseProfile(profile)}>
                                      <div className="profile-Avatar">
                                        <p className="theme-text-color">{profile?.user_name}</p>
                                        <span className="Avatar-image">
                                          <img className="Avatar3"src={profile?.image_url}
                                          alt={`${profile?.user_name}'s Avatar`}/>
                                        </span>
                                        <div>
                                <div className="d-flex justify-content-center mt-2 gap-2">
                                            <FaPen className="theme-text-color" style={{
                                              fontSize: "16px",fontWeight: "100"}}/>
                                            <FaRegTrashAlt className="theme-text-color"
                                              style={{fontSize: "16px", fontWeight: "100"}}
                                            />
                                          </div>
                                          </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                   {MultiUsers?.length <= MultiUserLimit ? (
                              <Link to={"/multi-profile/create"} className="add ms-1">
                                <BsPlusCircle style={{ "fontSize": "20px", "color": "white" }} />
                              </Link>
                            ) : null}
                          </div>
                        </div>
                     </div></div>
                    <div className="tab-pane fade"id="recent-viewed"
                      role="tabpanel"aria-labelledby="v-pills-settings-tab">
                      <div>
                        <h4>Recently Viewed Items</h4>
                        <hr></hr>
                        <Table className="recentView">
                          <thead className="p-1">
                            <tr className="title">
                              <th className="theme-text-color">Video</th>
                              <th className="theme-text-color">Rating</th>
                              <th className="theme-text-color">Category</th>
                            </tr>
                          </thead>
                          {videorating?.map((item, key) => (
                            <tbody>
                              <tr>
                                <td className="theme-text-color">{item?.video}</td>
                                <td className="theme-text-color">
                                  <FaStar style={{color: "gold"}} />
                                  <i className="fa fa-star-o theme-text-color" 
                                  style={{color: "gold"}} aria-hidden="true"></i>{item?.rating}
                                </td>
                                <td className="theme-text-color">{item?.category}</td>
                              </tr>
                            </tbody>
                          ))}
                        </Table>
                    </div>
                    </div>
                    <div className="tab-pane fade"id="tv-code"
                      role="tabpanel"aria-labelledby="v-pills-settings-tab">
                      <div>
                        <h5 className="">Enter TV Activation Code</h5>
                        <div className="col-lg-12 row py-3 align-items-center">
                          <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                         <input type="text" className="rs-input form-control-lg" />
                          </div>
                          <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                            <button className="p-2 rounded-1">Submit</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade"id="tv-user-list"
                      role="tabpanel"aria-labelledby="v-pills-settings-tab">
                      <div>
                        <h4>Tv Logged User List</h4>
                        <hr></hr>
                        <Table className="recentView">
                          <thead className="p-1">
                            <tr className="title">
                              <th className="theme-text-color">S.No</th>
                              <th className="theme-text-color">Email</th>
                              <th className="theme-text-color">TV Code</th>
                              <th className="theme-text-color">Action</th>
                            </tr>
                          </thead>
                          {tvlogged?.map((item, key) => (
                            <tbody>
                              <tr>
                                <td className="p-2 theme-text-color">{key + 1}</td>
                                <td className="p-2 theme-text-color">{item?.email}</td>
                                <td className="p-2 theme-text-color">{item?.tv_code}</td>
                                <td>
                                  <div className="d-flex">
                                    <button className="p-1" style={{ borderRadius: "5px" }}> Remove</button>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          ))}
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            :  */}
          <div className="row container-fluid">
            <div class=" text-center align-items-center">
              <h2 class="text-center theme-text-color">My Account</h2>
            </div>

            <div className="row col-lg-12 py-3 mb-5">

              <div className="py-3">
                <div>
                  <h5 className="form-control1 theme-text-color p-0">{t("Personal Details")}</h5>
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-6">

                <div>
                  {/* Name */}
                  <label for="name" className="account-label theme-text-color">{t("First Name")}</label>
                  {clicked1 == true ? (<div className="field-group pb-4">
                    <label for="name" className="input-label theme-text-color">
                      {t(accountdata?.name)}
                    </label>
                  </div>
                  ) : (
                    <div className="field-group">
                      <label for="name" className="input-label theme-text-color ">
                        {t(accountdata?.name)}
                      </label>
                    </div>
                  )}
                  <div className="field-underline">
                    {Array.from(Array(counter1)).map((c, index) => {
                      return (
                        <>
                          <input key={c} type="text" id="name" name="name"
                            className="input-field2 theme-text-color rs-input form-control-lg"
                            value={accountdata?.name} placeholder="Name"
                            onChange={AccountUpdate} />
                        </>)
                    })}
                    {clicked1 == true ? (
                      <>
                        <Button style={{ color: "white", fontSize: "13px", borderRadius: "5px" }}
                          size="large" className="input-but bgButton" onClick={handleUpdate}>
                          {t("Update")}
                        </Button>
                        {/* <Button style={{ color: "white", fontSize: "13px", borderRadius: "5px" }}
                          size="large" className="input-but bgButton" onClick={handleCancel}>
                          {t("Cancel")}
                        </Button> */}
                        <Button className="mt-1 bgButton" onClick={handleCancelname}><MdCancel /></Button>

                      </>
                    ) : (
                      <label
                        for="name" style={{ cursor: "pointer" }}
                        className="input-label1 theme-text-color"
                        onClick={handleClick1}>{t("Edit")}
                      </label>
                    )}
                    {clicked1 == true ? (
                      <hr className="input-field pb-2 theme-border-border-color-all " />
                    ) : (
                      <hr className="input-field pb-2 theme-border-border-color-all " />
                    )}
                  </div>
                </div>


                <div>
                  {/* Name */}
                  <label for="name" className="account-label theme-text-color">{t("Last Name")}</label>
                  {clicked8 == true ? (<div className="field-group pb-4">
                    <label for="name" className="input-label theme-text-color">
                      {t(accountdata?.last_name)}
                    </label>
                  </div>
                  ) : (
                    <div className="field-group">
                      <label for="name" className="input-label theme-text-color ">
                        {t(accountdata?.last_name)}
                      </label>
                    </div>
                  )}
                  <div className="field-underline">
                    {Array.from(Array(counter8)).map((c, index) => {
                      return (
                        <>
                          <input key={c} type="text" id="last_name" name="last_name"
                            className="input-field2 theme-text-color rs-input form-control-lg"
                            value={accountdata?.last_name} placeholder="Last Name"
                            onChange={AccountUpdate} />
                        </>)
                    })}
                    {clicked8 == true ? (
                      <>
                        <Button style={{ color: "white", fontSize: "13px", borderRadius: "5px" }}
                          size="large" className="input-but bgButton" onClick={handleUpdate}>
                          {t("Update")}
                        </Button>

                        <Button className="mt-1 bgButton" onClick={handleCancellast_name}><MdCancel /></Button>
                      </>

                    ) : (
                      <label
                        for="name" style={{ cursor: "pointer" }}
                        className="input-label1 theme-text-color"
                        onClick={handleClick8}>{t("Edit")}
                      </label>
                    )}
                    {clicked8 == true ? (
                      <hr className="input-field pb-2 theme-border-border-color-all " />
                    ) : (
                      <hr className="input-field pb-2 theme-border-border-color-all " />
                    )}
                  </div>
                </div>

                <div>
                  {/* Mobile No */}
                  <label for="name" className="account-label  theme-text-color ">
                    {t("Mobile No")}
                  </label>
                  {clicked2 == true ? (
                    <div className="field-group pb-4">
                      <label for="name" className="input-label theme-text-color">
                        {accountdata?.mobile}
                      </label>
                    </div>
                  ) : (
                    <div className="field-group">
                      <label for="name" className="input-label theme-text-color">
                        {accountdata?.mobile}
                      </label>
                    </div>
                  )}

                  <div className="field-underline">
                    {Array.from(Array(counter2)).map((c, index) => {
                      return (
                        <><input key={c} type="text" id="mobile" name="mobile" placeholder="Mobile"
                          className="input-field2 theme-text-color rs-input form-control-lg"
                          value={accountdata?.mobile} onChange={AccountUpdate} />
                        </>
                      );
                    })}

                    {clicked2 == true ? (
                      <>
                        <Button style={{ fontSize: "13px", borderRadius: "5px" }} size="large"
                          className="input-but bgButton" onClick={handleUpdate}>
                          {t("Update")}</Button>
                        <Button className="mt-1 bgButton" onClick={handleCancelmobile}><MdCancel /></Button>
                      </>
                    ) : (
                      <label for="name" className="input-label1 theme-text-color"
                        onClick={handleClick2} style={{ cursor: "pointer" }}>
                        {t("Edit")}
                      </label>
                    )}
                    {clicked2 == true ? (
                      <hr className="input-field pb-2 theme-border-border-color-all" />
                    ) : (
                      <hr className="input-field pb-2 theme-border-border-color-all" />
                    )}
                  </div>
                </div>

                <div>
                  {/* Email */}
                  <label for="name" className="account-label theme-text-color">{t("Email")}</label>
                  {clicked3 === true ? (
                    <div className="field-group pb-4">
                      <label for="name" className="input-label theme-text-color">
                        {accountdata?.email}</label>
                    </div>
                  ) : (
                    <div className="field-group ">
                      <label for="name" className="input-label theme-text-color">
                        {accountdata?.email} </label>
                    </div>
                  )}
                  <div className="field-underline">
                    {Array.from(Array(counter3)).map((c, index) => {
                      return (
                        <><input key={c} type="text"
                          className="input-field2 theme-text-color rs-input form-control-lg"
                          style={{ top: "30%" }} id="email" name="email" placeholder="Email"
                          value={accountdata?.email} onChange={AccountUpdate} />
                        </>
                      )
                    })}
                    {clicked3 == true ? (
                      <>
                        <Button style={{ fontSize: "13px", borderRadius: "5px" }}
                          size="large" className="input-but bgButton" onClick={handleUpdate}>
                          {t("Update")}
                        </Button>
                        <Button className="mt-1 bgButton" onClick={handleCancelemail}><MdCancel /></Button>
                      </>
                    ) : (
                      <label
                        for="name" className="input-label1 theme-text-color" onClick={handleClick3}
                        style={{ cursor: "pointer" }}>{t("Edit")}</label>
                    )}
                    {clicked3 == true ? (
                      <hr className="input-field pb-2 theme-border-border-color-all" />
                    ) : (
                      <hr className="input-field pb-2 theme-border-border-color-all" />
                    )}
                  </div>
                </div>

                <div>
                  {/* Password */}
                  <label for="name" className="account-label theme-text-color">{t("Password")}</label>
                  {clicked4 == true ? (
                    <div className="field-group pb-4">
                      <label for="name" className="input-label theme-text-color">
                        {accountdata?.unhashed_password}</label>
                    </div>
                  ) : (
                    <div className="field-group ">
                      <label for="name" className="input-label theme-text-color">
                        {/* {accountdata?.unhashed_password}    */}
                        {"**************"}
                      </label>
                    </div>
                  )}
                  <div className="field-underline">
                    {Array.from(Array(counter4)).map((c, index) => {
                      return (
                        <><input key={c} type="text" style={{ top: "30%" }}
                          className="input-field2 theme-text-color rs-input form-control-lg"
                          id="unhashed_password" name="unhashed_password" placeholder="Password"
                          value={"**************"} onChange={AccountUpdate} />
                        </>
                      );
                    })}
                    {clicked4 == true ? (
                      <>
                        <Button style={{ fontSize: "13px", borderRadius: "5px" }} size="large"
                          className="input-but bgButton" onClick={handleUpdate}>
                          {t("Update")}
                        </Button>
                        <Button className="mt-1 bgButton" onClick={handleCancelPassword}><MdCancel /></Button>
                      </>
                    ) : (
                      <label for="name" className="input-label1 theme-text-color"
                        onClick={handleClick4} style={{ cursor: "pointer" }}>{t("Edit")}</label>
                    )}
                    {clicked4 === true ? (
                      <hr className="input-field pb-2 theme-border-border-color-all" />
                    ) : (
                      <hr className="input-field pb-2 theme-border-border-color-all" />
                    )}
                  </div>
                </div>


                <div>
                  <label for="name" className="account-label theme-text-color">
                    {t("Date of Birth")}
                  </label>
                  {clicked5 == true ? (
                    <div className="field-group pb-4">
                      <label for="name" className="input-label theme-text-color">
                        {accountdata?.DOB}</label>
                    </div>
                  ) : (
                    <div className="field-group">
                      <label for="name" className="input-label theme-text-color">
                        {accountdata?.DOB}
                      </label>
                    </div>
                  )}
                  <div className="field-underline">
                    {Array.from(Array(counter5)).map((c, index) => {
                      return (
                        <>
                          <input key={c} type="date" id="DOB" name="DOB"
                            className="input-field2 theme-text-color rs-input form-control-lg"
                            placeholder="DOB" value={accountdata?.DOB}
                            onChange={AccountUpdate} />
                        </>);
                    })}
                    {clicked5 == true ? (
                      <>
                        <Button style={{ fontSize: "13px", borderRadius: "5px" }}
                          size="large" className="input-but bgButton"
                          onClick={handleUpdate}>{t("Update")}</Button>
                        <Button className="mt-1 bgButton" onClick={handleCancelDOB}><MdCancel /></Button>
                      </>

                    ) : (
                      <label for="name" className="input-label1 theme-text-color"
                        onClick={handleClick5} style={{ cursor: "pointer" }}>
                        {t("Edit")}
                      </label>
                    )}
                    {clicked5 == true ? (
                      <hr className="input-field pb-2 theme-border-border-color-all" />
                    ) : (
                      <hr className="input-field pb-2 theme-border-border-color-all" />
                    )}
                  </div>
                </div>

                <div className="mb-3">
                  <Button
                    style={{ color: "white", fontSize: "13px", borderRadius: "5px", }}
                    size="large"
                    className="bgButton"
                    onClick={handleLogout}
                  >
                    {t("Logout")}
                  </Button>

                </div>

              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div>
                  <label for="name" className="account-label theme-text-color">
                    {t("Display Picture")}
                  </label>
                  <div className="field-underline p-2">

                    {!clicked6 && (
                      <img
                        src={accountdata?.user_avatar}
                        alt="Profile"
                        className="displayProfile"
                      />
                    )}
                    <div className="image-uploader-container">
                      {clicked6 ? (
                        <div className="upload-section">
                          <div
                            className="form-control mt-2 truncate"
                            onClick={() => fileInputRef1.current.click()}
                            style={{ width: '60%', cursor: 'pointer' }}
                          >
                            {selectedFile1 ? selectedFile1.name : 'Click to select a file'}
                          </div>
                          <input
                            type="file"
                            ref={fileInputRef1}
                            onChange={(event) => handleFileChange(event, fileInputRef1, setSelectedFile1)}
                            accept="image/*"
                            style={{ display: 'none' }}
                            id="formFileMd"
                          />

                          {/* <input
                          type="file"
                          accept="image/*"
                          className="form-control mt-2"
                          id="formFileMd"
                          onChange={handleFileChange}
                          style={{ width: "60%" }}
                        /> */}
                          <Button
                            style={{ color: "white", fontSize: "13px", borderRadius: "5px", top: "14px" }}
                            size="large"
                            className="input-but bgButton"
                            onClick={handleSubmit}
                          >
                            {t("Update")}
                          </Button>

                          <Button className="mt-1 bgButton" onClick={handleCanceluser_avatar}><MdCancel /></Button>


                        </div>
                      ) : (
                        <label
                          htmlFor="name"
                          className="input-label1 theme-text-color"
                          onClick={handleClick6}
                          style={{ cursor: "pointer", top: "18px" }}
                        >
                          {t("Edit")}
                        </label>
                      )}
                    </div>
                    {clicked6 == true ? (
                      <hr className="input-field pb-2 theme-border-border-color-all" />
                    ) : (
                      <hr className="input-field pb-2 theme-border-border-color-all" />
                    )}

                  </div>
                </div>

                <div>
                  <div className="py-3">
                    <div>
                      <h5 className="form-control1 theme-text-color p-0">{t("Tv Activation Code")}</h5>
                    </div>
                  </div>
                  {/* Name */}
                  <label for="name" className="account-label theme-text-color">{t("TV Code")}</label>
                  {clicked7 == true ? (<div className="field-group pb-4">
                    <label for="name" className="input-label theme-text-color">
                      {t(accountdata?.activation_code)}
                    </label>
                  </div>
                  ) : (
                    <div className="field-group">
                      <label for="name" className="input-label theme-text-color ">
                        {t(accountdata?.activation_code)}
                      </label>
                    </div>
                  )}
                  <div className="field-underline">
                    {Array.from(Array(counter7)).map((c, index) => {
                      return (
                        <>
                          <input key={c} type="text" id="activation_code" name="activation_code"
                            className="input-field2 theme-text-color rs-input form-control-lg"
                            value={accountdata?.activation_code} placeholder="Activation Code"
                            onChange={AccountUpdate} />
                        </>)
                    })}
                    {clicked7 == true ? (
                      <>
                        <Button style={{ color: "white", fontSize: "13px", borderRadius: "5px" }}
                          size="large" className="input-but bgButton" onClick={handleUpdate}>
                          {t("Update")}
                        </Button>

                        <Button className="mt-1 bgButton" onClick={handleCancelactivation_code}><MdCancel /></Button>

                      </>
                    ) : (
                      <label
                        for="name" style={{ cursor: "pointer" }}
                        className="input-label1 theme-text-color"
                        onClick={handleClick7}>{t("Edit")}
                      </label>
                    )}
                    {clicked7 == true ? (
                      <hr className="input-field pb-2 theme-border-border-color-all " />
                    ) : (
                      <hr className="input-field pb-2 theme-border-border-color-all " />
                    )}
                  </div>
                </div>


                <div>
                  {/* Admin */}
                  {accountdata?.role == 1 ? (
                    <div>
                      <div className="pb-2 pt-2">
                        <div className="bg-secondary">
                          <h5 className="form-control1 theme-text-color p-0 m-0">{t("Membership Settings")}</h5>
                        </div>
                      </div>

                      <div className="field-group">
                        <label for="name" className=" theme-text-color ">
                          {t("Current Membership")} - {t(accountdata?.package)}
                        </label>
                      </div>
                    </div>
                  ) : null}
                  {/* Register */}
                  {accountdata?.role === 2 ? (
                    <div><div className="py-1">
                      <div className="bg-secondary">
                        {/* <input required type="text"
                      className="form-control theme-text-color"
                      value="Subscriptions"
                      style={{"background":"ffffff1a"}}
                    /> */}
                        <h5 className="form-control1 theme-text-color">{t("Subscriptions")}</h5>
                      </div>
                    </div>
                      <div className="field-group">
                        <label className=" theme-text-color">{t("Why Wait When You Can Enjoy All Rounder")}</label>
                      </div>
                      <div className="field-group ">
                        <label className=" theme-text-color">{t("Entertainment")}!</label>
                      </div>
                      <div className="tsub pt-2">
                        <button className="subscribe bgButton" onClick={Subscribe}>
                          <b>{t("Become a Subscriber")}</b>
                        </button>
                      </div>
                    </div>) : null}

                  {/* Subscriber       */}
                  {accountdata?.role == 3 ? (
                    <div><div className="py-1">
                      <div className="bg-secondary">
                        {/* <input required type="text" value="Subscriptions"
                      className="form-control1 theme-text-color" /> */}
                        <h5 className="form-control1 theme-text-color p-0 fw-bold">{t("YOUR MEMBERSHIP")}</h5>
                      </div>
                    </div>
                      <div className="field-group">
                        <label className="theme-text-color ">
                          {t(`Price`)} {t(`: ${datatranscation?.currency?.symbol} ${datatranscation?.subscription_price} / ${subscriptionDuration}`)} {t(`( after your free trail ends)`)}
                        </label>
                      </div>
                      <div className="field-group pb-2">
                        <label className="theme-text-color"><span className="fw-medium">{t("Next Billing Date : ")}</span> {t(`${formattedDate}`)}.</label>
                      </div>

                      <div className="field-group ">
                        <label className="theme-text-color">
                          {t("Starting on ")} {t(`${formattedDate}`)} {t("your card will automatically")}
                        </label>
                      </div>
                      <div className="field-group">
                        <label className=" theme-text-color">
                          {t("be Charged")} {(`${datatranscation?.currency?.symbol} ${datatranscation?.subscription_price} / ${subscriptionDuration}`)}.
                          {/* {t("You can cancel anytime")}. */}
                        </label>
                      </div>
                      <div className="field-group py-3">
                        <Link onClick={() => window.location.href = `/${lang}/transcation-list`}>
                          <Button
                            style={{ color: "white", fontSize: "13px", borderRadius: "5px", }}
                            size="large"
                            className="input-butt bgButton"
                          >
                            {t("Transaction History")}
                          </Button>
                        </Link>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>


            {/* 
            <div className="col-md-6">
              <div className="row-account align-items-center justify-content-between theme-bg-color p-3"> */}

            {/* Admin */}
            {/* {accountdata?.role == 1 ? (
                    <div>
                      <div className="pb-2 pt-2">
                        <div>
                          <input
                            required
                            type="text"
                            className="form-control1 theme-text-color rs-input form-control-lg pl-0"
                            value="Membership Settings"
                          />
                        </div>
                      </div>

                      <div className="field-group">
                        <label for="name" className=" theme-text-color ">
                          Current Membership - {accountdata?.name}
                        </label>
                      </div>
                    </div>
                  ) : null}
                  {accountdata?.role == 2 ? (
                    <div>
                      <div className="pb-3 pt-2">
                        <div>
                          <input
                            required
                            type="text"
                            className="form-control1 theme-text-color rs-input form-control-lg"
                            value="Subscriptions"
                          />
                        </div>
                      </div>
                      <div className="field-group">
                        <label className=" theme-text-color ">Why Wait When You Can Enjoy All Rounder</label>
                      </div>
                      <div className="field-group ">
                        <label className=" theme-text-color ">Entertainment !</label>
                      </div>
                      <div className="tsub pt-2">
                        <button className="subscribe bgButton" onClick={Subscribe}>
                          <b>Become a Subscriber</b>
                        </button>
                      </div>
                    </div>
                  ) : null}

                  {accountdata?.role == 3 ? (
                    <div>
                      <div className="pb-3 pt-2">
                        <div>
                          <input
                            required
                            type="text"
                            className="form-control1 theme-text-color rs-input form-control-lg"
                            value="Subscriptions"
                          />
                        </div>
                      </div>

                      <div className="field-group">
                        <label className=" theme-text-color ">
                          Price:₹ 1999 / Year ( after your free trail ends )
                        </label>
                      </div>
                      <div className="field-group pb-2">
                        <label className=" theme-text-color ">Next Billing Date: Nov 25 2024.</label>
                      </div>

                      <div className="field-group ">
                        <label className=" theme-text-color ">
                          Starting on June 25 2024 your card will automatically
                        </label>
                      </div>
                      <div className="field-group">
                        <label className=" theme-text-color ">
                          be Charged ₹ 999 / Month. You can cancel anytime.
                        </label>
                      </div>
                    </div>
                  ) : null} */}
            {/* </div>
            </div> */}

            {/* <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="row-account mt-4 align-items-center justify-content-between theme-bg-color p-3"> */}

            {/* Tv Activation */}

            {/* <label for="name" className="account-label  theme-text-color ">
                  {t("Tv Activation Code")}
                </label>
                <hr className="input-field py-3 theme-border-border-color-all" /> */}

            {/* {clicked6 == true ? (
                  <div className="field-group pb-4">
                    <label for="name" className="input-label theme-text-color">
                      {accountdata?.DOB}
                    </label>
                  </div>
                ) : (
                  <div className="field-group">
                    <label for="name" className="input-label theme-text-color">
                      {accountdata?.DOB}
                    </label>
                  </div>
                )} */}
            {/* <div className="field-underline">
                  {Array.from(Array(counter6)).map((c, index) => {
                    return (
                      <><input key={c} type="text"
                        className="input-field2 theme-text-color rs-input form-control-lg"
                        id="DOB" name="DOB" placeholder="DOB"
                        value={accountdata?.DOB} onChange={AccountUpdate}
                      />
                      </>
                    );
                  })}
                  {clicked6 == true ? (
                    <Button style={{ fontSize: "13px", borderRadius: "5px" }} size="large"
                      className="input-but bgButton" onClick={handleUpdate}>{t("Update")}</Button>
                  ) : (
                    <label for="name" className="input-label1 theme-text-color"
                      onClick={handleClick6} style={{ cursor: "pointer" }}>
                      {t("Edit")}
                    </label>
                  )}
                  {clicked6 == true ? (
                    <hr className="input-field pb-2 theme-border-border-color-all" />
                  ) : (
                    <hr className="input-field pb-2 theme-border-border-color-all" />
                  )}
                </div> */}

            {/* </div>
            </div> */}
            {/* } */}
          </div>
        </div>
      </section >
    </>
  );
};
const mapStateToProps = state => ({
  profileDetails: state.get_profileDetails_Reducer.profileDetails,
});

export default connect(mapStateToProps)(AccountPage);
